import React, { useState } from 'react'
import { View, ScrollView } from 'react-native'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  updateTableProductOptionBox,
  useTableProductOptionBoxes,
} from 'models/TableProductOptionBox'
import TableProductOptionBox from 'components/Restaurant/TableProductOptionBox'
import { useToken } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function TableProductsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const token = useToken()
  const [currentPage, setCurrentPage] = useState(1)

  const { tableProductOptionBoxes, isLoading, headerData } =
    useTableProductOptionBoxes(restaurantId, {
      status: 'archived',
      page: currentPage,
    })

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      <View style={{ marginTop: 20 }}>
        <ShadowBox
          style={[
            {
              paddingHorizontal: 0,
              paddingVertical: 8,
              marginTop: 24,
            },
          ]}
        >
          {isLoading && (
            <View style={{ padding: 24 }}>
              <Loading />
            </View>
          )}
          {!isLoading &&
            tableProductOptionBoxes.map((productOptionBox, i) => (
              <View
                key={productOptionBox.id}
                style={[
                  tableProductOptionBoxes.length - 1 !== i && {
                    borderBottomColor: Colors.border,
                    borderBottomWidth: 0.5,
                    borderStyle: 'solid',
                  },
                ]}
              >
                <TableProductOptionBox
                  key={productOptionBox.id}
                  productOptionBox={productOptionBox}
                  onChangeStatus={async ({
                    tableProductOptionBoxId,
                    params,
                  }) => {
                    await updateTableProductOptionBox({
                      token: token ?? '',
                      restaurantId,
                      productOptionBoxId: tableProductOptionBoxId,
                      params,
                    })
                  }}
                />
              </View>
            ))}
          {!isLoading && tableProductOptionBoxes.length === 0 && (
            <Text
              style={{
                paddingTop: 16,
                marginBottom: 24,
                paddingHorizontal: 24,
                fontSize: 22,
                fontWeight: '600',
                color: Colors.disabledBlack,
                textAlign: 'center',
              }}
            >
              {t('該当するカテゴリーはありません')}
            </Text>
          )}
          <View
            style={{
              borderTopWidth: 0.5,
              borderColor: Colors.border,
              paddingTop: 24,
              marginBottom: 16,
            }}
          >
            <Pagination
              currentPage={currentPage}
              totalPage={headerData?.totalPages ?? 0}
              setPage={(page) => setCurrentPage(page)}
            />
          </View>
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
