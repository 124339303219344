import React from 'react'
import { FlatList, View, ScrollView } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useTableMenuPages } from 'models/TableMenuPage'

import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

import TableMenuPage from 'components/Restaurant/TableMenuPage'
import GroupCautionMessage from 'components/Menus/GroupCautionMessage'
import RoundedActionButton from 'components/Shared/RoundedActionButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function MenusScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const { tableMenuPages } = useTableMenuPages({ restaurantId })

  const hasGroupManagedItem = (tableMenuPages ?? []).some((item) => {
    return item.group_managed
  })

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      {hasGroupManagedItem && (
        <View
          style={{
            marginBottom: 40,
          }}
        >
          <GroupCautionMessage />
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <RoundedActionButton
          onPress={() =>
            history.push(`/restaurants/${restaurantId}/menu_pages/new`)
          }
        >
          {t('カテゴリーを追加')}
        </RoundedActionButton>
      </View>
      <View style={{ marginTop: 40 }}>
        <ShadowBox
          style={[
            {
              padding: 0,
            },
          ]}
        >
          <FlatList
            data={tableMenuPages}
            keyExtractor={(item) => item.id}
            renderItem={({ item: tableMenuPage, index }) => {
              return (
                <View
                  key={tableMenuPage.id}
                  style={{
                    borderTopWidth: index === 0 ? 0 : 0.5,
                    borderColor: Colors.border,
                  }}
                >
                  <TableMenuPage
                    locked
                    tableMenuPage={tableMenuPage}
                    onPress={(tableMenuPageId: string) =>
                      history.push(
                        `/restaurants/${restaurantId}/menu_pages/${tableMenuPageId}/edit`
                      )
                    }
                  />
                </View>
              )
            }}
            ListEmptyComponent={
              tableMenuPages != null ? ( // ロード中もListEmptyComponentの表示になるので隠す
                <Text
                  style={{
                    paddingVertical: 20,
                    paddingHorizontal: 24,
                    color: Colors.disabledBlack,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}
                >
                  {t('カテゴリがありません')}
                </Text>
              ) : null
            }
          />
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
