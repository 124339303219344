import React, {
  Fragment,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Text,
  View,
  Image,
  Alert,
  Platform,
  TextInput as RNTextInput,
} from 'react-native'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import {
  TableProduct as TableProductModel,
  TableProductPricesPriceType,
  UpdateTableProductFuncType,
  UpdateTableProductStockFuncType,
} from '../../../models/TableProduct'
import { DEFAULT_TAX_RATE, REDUCED_TAX_RATE } from '../../../constants/TaxRates'
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { TableMenuPage } from '@hello-ai/ar_shared/src/types/ForR/TableMenuPage'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { AlertMethods } from '@hello-ai/ar_shared/src/components/Alert'
import { AsyncSwitch } from '@hello-ai/ar_shared/src/components/Switch'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

function treeTableMenuPages(tableMenuPages?: TableMenuPage[]) {
  if (tableMenuPages == null) return []
  const tree = tableMenuPages.reduce(
    (acc, tableMenuPage) => {
      if (tableMenuPage.parent_table_menu_page == null) {
        // NOTE: 親がいないとき、自身が親として扱う
        const parentTableMenuPageId = tableMenuPage.id
        if (!acc.has(parentTableMenuPageId)) {
          acc.set(parentTableMenuPageId, {
            parent: tableMenuPage,
            children: [],
          })
        }
      } else {
        // NOTE: 親がいるとき、自分を子として追加
        const parentTableMenuPageId = tableMenuPage.parent_table_menu_page.id
        if (!acc.has(parentTableMenuPageId)) {
          acc.set(parentTableMenuPageId, {
            parent: tableMenuPage.parent_table_menu_page,
            children: [],
          })
        }
        acc.get(parentTableMenuPageId)?.children.push(tableMenuPage)
      }
      return acc
    },
    new Map<
      string,
      {
        parent: TableMenuPage
        children: TableMenuPage[]
      }
    >(),
  )

  return Array.from(tree.values()).map((value) => ({
    ...value.parent,
    children: value.children,
  }))
}

function RestoreToggle({
  tableProduct,
  onChangeStatus,
  alertRef,
}: {
  tableProduct: TableProductModel
  onChangeStatus: UpdateTableProductFuncType
  alertRef?: RefObject<AlertMethods>
}) {
  const alert = Platform.select({
    web: alertRef?.current?.alert,
    default: Alert.alert,
  })

  const alertPublish = () => {
    const params = {
      status: 'published',
    }
    alert?.(t('本当に公開状態に戻しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => {
          onChangeStatus(tableProduct.id, params)
        },
      },
    ])
  }

  return (
    <Button
      textStyle={{
        color: Colors.caution,
        fontWeight: 'normal',
      }}
      style={{
        borderColor: Colors.caution,
        backgroundColor: 'transparent',
        height: 48,
        width: 124,
      }}
      onPress={() => alertPublish()}
    >
      {t('元に戻す')}
    </Button>
  )
}

function getTaxRateText(price: TableProductPricesPriceType) {
  return `${price.tax_included ? t('税込') : t('税抜')}${
    price.tax_type === 'default' ? DEFAULT_TAX_RATE : REDUCED_TAX_RATE
  }%`
}

function getStockTypeText(stockType?: 'none' | 'manual' | 'daily_reset') {
  switch (stockType) {
    case 'manual':
      return t('手動')
    case 'daily_reset':
      return t('リセット')
    case 'none':
    default:
      return t('なし')
  }
}

export default function TableProductListItem({
  restaurantId,
  onPressListItem,
  tableProduct,
  right,
  onChangeInStock,
  onChangeStatus,
  isArchived,
  restaurantCurrency,
  alertRef,
  stockValue,
  onUpdateStockValue,
}: {
  restaurantId: number
  onPressListItem: (tableProduct: TableProductModel) => void
  right?: React.ReactNode
  tableProduct: TableProductModel
  onChangeInStock?: UpdateTableProductStockFuncType
  onChangeStatus?: UpdateTableProductFuncType
  isArchived?: boolean
  restaurantCurrency: {
    priceUnit: string
    currencyWithDelimiter: (price: number) => string
  }
  alertRef?: RefObject<AlertMethods>
  stockValue?: number | null
  onUpdateStockValue?: (value: number) => void
}) {
  const { width, sm } = useResponsive()
  const { priceUnit, currencyWithDelimiter } = restaurantCurrency
  const [inStock, setInStock] = useFormState(tableProduct?.in_stock)

  // focus が外れたときのみ onUpdateStockValue を呼び出したいので別途管理する
  const [stock, setStock, reset] = useFormState(stockValue)

  const shouldShowStockInput = useMemo(() => {
    if (tableProduct.table_product_stock_setting == null) return false
    return (
      tableProduct.table_product_stock_setting.stock_type === 'manual' ||
      tableProduct.table_product_stock_setting.stock_type === 'daily_reset'
    )
  }, [tableProduct])

  return (
    <TouchableOpacity
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          gap: 20,
        },
      ]}
      disabled={isArchived || tableProduct.group_managed}
      onPress={() => onPressListItem(tableProduct)}
    >
      {width >= sm && onChangeInStock && (
        <View
          style={{
            width: 80,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Switch へのタップが親へのタップに吸われるのを防ぐ */}
          <TouchableOpacity onPressMinInterval={0}>
            <AsyncSwitch
              value={inStock}
              disabled={tableProduct.group_managed}
              onValueChange={async (value) => {
                if (value === inStock) {
                  return
                }
                setInStock(value)
                await onChangeInStock(tableProduct.id, {
                  restaurant_id: restaurantId,
                  out_of_stock_at: value ? null : dayjs().tz().toISOString(),
                })
              }}
            />
          </TouchableOpacity>
        </View>
      )}
      {width >= sm && (
        <View style={{ width: 120 }}>
          {treeTableMenuPages(tableProduct.table_menu_pages).map((page) => (
            <Fragment key={page.id}>
              <Text style={{ fontSize: 16, fontWeight: '300' }}>
                {page.name}
              </Text>
              {page.children.map((child) => (
                <Text
                  key={child.id}
                  style={{
                    fontSize: 14,
                    fontWeight: '300',
                    color: Colors.black60,
                  }}
                >
                  {child.name}
                </Text>
              ))}
            </Fragment>
          ))}
        </View>
      )}
      <View
        style={[
          width >= sm
            ? {
                alignItems: 'center',
                flex: 1,
                flexDirection: 'row',
              }
            : {
                gap: 16,
                flex: 1,
                flexDirection: 'column',
              },
        ]}
      >
        {(tableProduct.image || width >= sm) && (
          <Image
            source={{ uri: tableProduct.image?.sm }}
            defaultSource={require('../../../assets/images/no_image_product.png')}
            style={{
              borderRadius: 4,
              width: 64,
              height: 64,
              marginRight: 16,
            }}
          />
        )}
        <View
          style={[
            {
              flex: 1,
              gap: width < sm ? 8 : 4,
            },
          ]}
        >
          <Text style={[{ fontWeight: '600', fontSize: 18 }]}>
            {tableProduct.name}
          </Text>
          {width < sm && (
            <View>
              {treeTableMenuPages(tableProduct.table_menu_pages).map((page) => (
                <Fragment key={page.id}>
                  <Text style={{ fontSize: 16, fontWeight: '300' }}>
                    {page.name}
                    {page.children.length > 0 &&
                      `(${page.children
                        .map((child) => child.name)
                        .join(', ')})`}
                  </Text>
                </Fragment>
              ))}
            </View>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={width < sm && { fontSize: 16 }}>
              {tableProduct.prices.table?.price_type === 'fixed'
                ? `${priceUnit} ${currencyWithDelimiter(
                    tableProduct.prices.table.price,
                  )}`
                : 'ASK'}
            </Text>
            <View
              style={{
                marginLeft: 8,
                padding: 4,
                backgroundColor: Colors.bgBlack,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 2,
              }}
            >
              <Text
                style={{
                  fontWeight: '300',
                  fontSize: 12,
                }}
              >
                {tableProduct.prices.table &&
                  getTaxRateText(tableProduct.prices.table)}
              </Text>
            </View>
          </View>
        </View>
        {tableProduct.group_managed && (
          <View
            style={{
              marginRight: 8,
            }}
          >
            <FontAwesomeIcon
              icon={faLockAlt}
              color={Colors.black60}
              size={16}
            />
          </View>
        )}
        {width < sm && (
          <View style={{ width: '100%' }}>
            {onChangeInStock && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 16,
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ fontSize: 14, fontWeight: '600' }}>
                    {shouldShowStockInput ? t('在庫数') : ''}
                  </Text>
                  <View style={{ width: 80 }}>
                    {shouldShowStockInput && (
                      // TextInput へのタップが親へのタップに吸われるのを防ぐ
                      <TouchableOpacity onPressMinInterval={0}>
                        <TextInput
                          // clearTextOnFocus では onChangeText が呼ばれず state を更新できないので
                          // onFocus で state から入力値を空にする
                          onFocus={() => {
                            if (stock === 0) {
                              setStock(null)
                            }
                          }}
                          style={{ textAlign: 'center', height: 48 }}
                          keyboardType="number-pad"
                          value={`${stock ?? ''}`}
                          onBlur={() => {
                            if (Platform.OS === 'web') {
                              onUpdateStockValue?.(stock ?? 0)
                              setStock(stock ?? 0)
                              reset()
                            }
                          }}
                          onEndEditing={() => {
                            onUpdateStockValue?.(stock ?? 0)
                            setStock(stock ?? 0)
                            reset()
                          }}
                          onChangeText={(text) => {
                            if (/^[\d]+$/.test(text)) {
                              setStock(Number(text))
                            } else {
                              setStock(null)
                            }
                          }}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 16,
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ fontSize: 14, fontWeight: '600' }}>
                    {t('注文可能')}
                  </Text>
                  {/* Switch へのタップが親へのタップに吸われるのを防ぐ */}
                  <TouchableOpacity onPressMinInterval={0}>
                    <AsyncSwitch
                      value={inStock}
                      disabled={tableProduct.group_managed}
                      onValueChange={async (value) => {
                        if (value === inStock) {
                          return
                        }
                        setInStock(value)
                        await onChangeInStock(tableProduct.id, {
                          restaurant_id: restaurantId,
                          out_of_stock_at: value
                            ? null
                            : dayjs().tz().toISOString(),
                        })
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            )}
            {onChangeStatus && (
              <RestoreToggle
                alertRef={alertRef}
                onChangeStatus={onChangeStatus}
                tableProduct={tableProduct}
              />
            )}
          </View>
        )}
      </View>
      {width >= sm && onChangeStatus && (
        <View
          style={{
            flex: 1,
            gap: width < sm ? 16 : 24,
            flexDirection: 'row',
            justifyContent: width >= sm ? 'center' : 'flex-end',
            alignItems: 'center',
          }}
        >
          <RestoreToggle
            onChangeStatus={onChangeStatus}
            tableProduct={tableProduct}
            alertRef={alertRef}
          />
        </View>
      )}
      {!isArchived && width >= sm && (
        <View style={{ width: 64 }}>
          <Text style={{ textAlign: 'center' }}>
            {getStockTypeText(
              tableProduct.table_product_stock_setting?.stock_type
            )}
          </Text>
        </View>
      )}
      {!isArchived && width >= sm && (
        <View style={{ width: 80 }}>
          {shouldShowStockInput && (
            // TextInput へのタップが親へのタップに吸われるのを防ぐ
            <TouchableOpacity onPressMinInterval={0}>
              <TextInput
                // clearTextOnFocus では onChangeText が呼ばれず state を更新できないので
                // onFocus で state から入力値を空にする
                onFocus={() => {
                  if (stock === 0) {
                    setStock(null)
                  }
                }}
                style={{ textAlign: 'center' }}
                keyboardType="number-pad"
                value={`${stock ?? ''}`}
                onBlur={() => {
                  if (Platform.OS === 'web') {
                    onUpdateStockValue?.(stock ?? 0)
                    setStock(stock ?? 0)
                    reset()
                  }
                }}
                onEndEditing={() => {
                  onUpdateStockValue?.(stock ?? 0)
                  setStock(stock ?? 0)
                  reset()
                }}
                onChangeText={(text) => {
                  if (/^[\d]+$/.test(text)) {
                    setStock(Number(text))
                  } else {
                    setStock(null)
                  }
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
      {right}
    </TouchableOpacity>
  )
}
