import { t } from '../../modules/i18n'

export default [
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
    name_i18n: t('イスラエル'),
  },
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    name_i18n: t('アフガニスタン'),
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
    name_i18n: t('アルバニア'),
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    name_i18n: t('アルジェリア'),
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1',
    code: 'AS',
    name_i18n: t('アメリカ領サモア'),
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    name_i18n: t('アンドラ'),
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
    name_i18n: t('アンゴラ'),
  },
  {
    name: 'Anguilla',
    dial_code: '+1',
    code: 'AI',
    name_i18n: t('アンギラ'),
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
    name_i18n: t('アンティグア・バーブーダ'),
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    name_i18n: t('アルゼンチン'),
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    name_i18n: t('アルメニア'),
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    name_i18n: t('アルバ'),
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    name_i18n: t('オーストラリア'),
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    name_i18n: t('オーストリア'),
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    name_i18n: t('アゼルバイジャン'),
  },
  {
    name: 'Bahamas',
    dial_code: '+1',
    code: 'BS',
    name_i18n: t('バハマ'),
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    name_i18n: t('バーレーン'),
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    name_i18n: t('バングラデシュ'),
  },
  {
    name: 'Barbados',
    dial_code: '+1',
    code: 'BB',
    name_i18n: t('バルバドス'),
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    name_i18n: t('ベラルーシ'),
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    name_i18n: t('ベルギー'),
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    name_i18n: t('ベリーズ'),
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    name_i18n: t('ベナン'),
  },
  {
    name: 'Bermuda',
    dial_code: '+1',
    code: 'BM',
    name_i18n: t('バミューダ'),
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    name_i18n: t('ブータン'),
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    name_i18n: t('ボスニア・ヘルツェゴビナ'),
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    name_i18n: t('ボツワナ'),
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    name_i18n: t('ブラジル'),
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
    name_i18n: t('イギリス領インド洋地域'),
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    name_i18n: t('ブルガリア'),
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    name_i18n: t('ブルキナファソ'),
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    name_i18n: t('ブルンジ'),
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    name_i18n: t('カンボジア'),
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    name_i18n: t('カメルーン'),
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    name_i18n: t('カナダ'),
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    name_i18n: t('カーボベルデ'),
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY',
    name_i18n: t('ケイマン諸島'),
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    name_i18n: t('中央アフリカ共和国'),
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
    name_i18n: t('チャド'),
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
    name_i18n: t('チリ'),
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    name_i18n: t('中華人民共和国'),
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
    name_i18n: t('クリスマス島'),
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    name_i18n: t('コロンビア'),
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    name_i18n: t('コモロ連合'),
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
    name_i18n: t('コンゴ共和国'),
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    name_i18n: t('クック諸島'),
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    name_i18n: t('コスタリカ'),
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    name_i18n: t('クロアチア'),
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    name_i18n: t('キューバ'),
  },
  {
    name: 'Cyprus',
    dial_code: '+537',
    code: 'CY',
    name_i18n: t('キプロス'),
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    name_i18n: t('チェコ'),
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    name_i18n: t('デンマーク'),
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    name_i18n: t('ジブチ'),
  },
  {
    name: 'Dominica',
    dial_code: '+1',
    code: 'DM',
    name_i18n: t('ドミニカ国'),
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1',
    code: 'DO',
    name_i18n: t('ドミニカ共和国'),
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    name_i18n: t('エクアドル'),
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    name_i18n: t('エジプト'),
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    name_i18n: t('エルサルバドル'),
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    name_i18n: t('赤道ギニア'),
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    name_i18n: t('エリトリア'),
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    name_i18n: t('エストニア'),
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    name_i18n: t('エチオピア'),
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    name_i18n: t('フェロー諸島'),
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    name_i18n: t('フィジー'),
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    name_i18n: t('フィンランド'),
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    name_i18n: t('フランス'),
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    name_i18n: t('フランス領ギアナ'),
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    name_i18n: t('フランス領ポリネシア'),
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    name_i18n: t('ガボン'),
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    name_i18n: t('ガンビア'),
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    name_i18n: t('ジョージア'),
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    name_i18n: t('ドイツ'),
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    name_i18n: t('ガーナ'),
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    name_i18n: t('ジブラルタル'),
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    name_i18n: t('ギリシャ'),
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    name_i18n: t('グリーンランド'),
  },
  {
    name: 'Grenada',
    dial_code: '+1',
    code: 'GD',
    name_i18n: t('グレナダ'),
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    name_i18n: t('グアドループ'),
  },
  {
    name: 'Guam',
    dial_code: '+1',
    code: 'GU',
    name_i18n: t('グアム'),
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    name_i18n: t('グアテマラ'),
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    name_i18n: t('ギニア'),
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    name_i18n: t('ギニアビサウ'),
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'GY',
    name_i18n: t('ガイアナ'),
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    name_i18n: t('ハイチ'),
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    name_i18n: t('ホンジュラス'),
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    name_i18n: t('ハンガリー'),
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    name_i18n: t('アイスランド'),
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    name_i18n: t('インド'),
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    name_i18n: t('インドネシア'),
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    name_i18n: t('イラク'),
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    name_i18n: t('アイルランド'),
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    name_i18n: t('イタリア'),
  },
  {
    name: 'Jamaica',
    dial_code: '+1',
    code: 'JM',
    name_i18n: t('ジャマイカ'),
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    name_i18n: t('日本'),
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    name_i18n: t('ヨルダン'),
  },
  {
    name: 'Kazakhstan',
    dial_code: '+7 7',
    code: 'KZ',
    name_i18n: t('カザフスタン'),
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    name_i18n: t('ケニア'),
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    name_i18n: t('キリバス'),
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    name_i18n: t('クウェート'),
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    name_i18n: t('キルギス'),
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    name_i18n: t('ラトビア'),
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    name_i18n: t('レバノン'),
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    name_i18n: t('レソト'),
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    name_i18n: t('リベリア'),
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    name_i18n: t('リヒテンシュタイン'),
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    name_i18n: t('リトアニア'),
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    name_i18n: t('ルクセンブルク'),
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    name_i18n: t('マダガスカル'),
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    name_i18n: t('マラウイ'),
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    name_i18n: t('マレーシア'),
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    name_i18n: t('モルディブ'),
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
    name_i18n: t('マリ'),
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    name_i18n: t('マルタ'),
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    name_i18n: t('マーシャル諸島'),
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    name_i18n: t('マルティニーク'),
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    name_i18n: t('モーリタニア'),
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    name_i18n: t('モーリシャス'),
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    name_i18n: t('マヨット'),
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    name_i18n: t('メキシコ'),
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    name_i18n: t('モナコ'),
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    name_i18n: t('モンゴル'),
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    name_i18n: t('モンテネグロ'),
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
    name_i18n: t('モントセラト'),
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    name_i18n: t('モロッコ'),
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
    name_i18n: t('ミャンマー'),
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    name_i18n: t('ナミビア'),
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    name_i18n: t('ナウル'),
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    name_i18n: t('ネパール'),
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    name_i18n: t('オランダ'),
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN',
    name_i18n: t('オランダ領アンティル'),
    //
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    name_i18n: t('ニューカレドニア'),
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    name_i18n: t('ニュージーランド'),
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    name_i18n: t('ニカラグア'),
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
    name_i18n: t('ニジェール'),
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    name_i18n: t('ナイジェリア'),
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
    name_i18n: t('ニウエ'),
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
    name_i18n: t('ノーフォーク島'),
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1',
    code: 'MP',
    name_i18n: t('北マリアナ諸島'),
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
    name_i18n: t('ノルウェー'),
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
    name_i18n: t('オマーン'),
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    name_i18n: t('パキスタン'),
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
    name_i18n: t('パラオ'),
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
    name_i18n: t('パナマ'),
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    name_i18n: t('パプアニューギニア'),
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    name_i18n: t('パラグアイ'),
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
    name_i18n: t('ペルー'),
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    name_i18n: t('フィリピン'),
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    name_i18n: t('ポーランド'),
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    name_i18n: t('ポルトガル'),
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1',
    code: 'PR',
    name_i18n: t('プエルトリコ'),
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    name_i18n: t('カタール'),
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    name_i18n: t('ルーマニア'),
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    name_i18n: t('ルワンダ'),
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    name_i18n: t('サモア'),
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    name_i18n: t('サンマリノ'),
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    name_i18n: t('サウジアラビア'),
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    name_i18n: t('セネガル'),
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    name_i18n: t('セルビア'),
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    name_i18n: t('セーシェル'),
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    name_i18n: t('シエラレオネ'),
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    name_i18n: t('シンガポール'),
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    name_i18n: t('スロバキア'),
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    name_i18n: t('スロベニア'),
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    name_i18n: t('ソロモン諸島'),
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    name_i18n: t('南アフリカ'),
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    name_i18n: t('サウスジョージア・サウスサンドウィッチ諸島'),
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    name_i18n: t('スペイン'),
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    name_i18n: t('スリランカ'),
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    name_i18n: t('スーダン'),
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    name_i18n: t('スリナム'),
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    name_i18n: t('スワジランド'),
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    name_i18n: t('スウェーデン'),
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    name_i18n: t('スイス'),
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    name_i18n: t('タジキスタン'),
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    name_i18n: t('タイ'),
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
    name_i18n: t('トーゴ'),
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    name_i18n: t('トケラウ'),
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
    name_i18n: t('トンガ'),
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1',
    code: 'TT',
    name_i18n: t('トリニダード・トバゴ'),
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    name_i18n: t('チュニジア'),
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    name_i18n: t('トルコ'),
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    name_i18n: t('トルクメニスタン'),
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1',
    code: 'TC',
    name_i18n: t('タークス・カイコス諸島'),
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    name_i18n: t('ツバル'),
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    name_i18n: t('ウガンダ'),
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    name_i18n: t('ウクライナ'),
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    name_i18n: t('アラブ首長国連邦'),
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    name_i18n: t('イギリス'),
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    name_i18n: t('アメリカ合衆国'),
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    name_i18n: t('ウルグアイ'),
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    name_i18n: t('ウズベキスタン'),
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    name_i18n: t('バヌアツ'),
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
    name_i18n: t('ウォリス・フツナ'),
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    name_i18n: t('イエメン'),
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    name_i18n: t('ザンビア'),
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    name_i18n: t('ジンバブエ'),
  },
  {
    name: 'land Islands',
    dial_code: '+358',
    code: 'AX',
    name_i18n: t('オーランド諸島'),
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO',
    name_i18n: t('ボリビア多民族国'),
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
    name_i18n: t('ブルネイ・ダルサラーム'),
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
    name_i18n: t('ココス（キーリング）諸島'),
  },
  {
    name: 'Congo, The Democratic Republic of the',
    dial_code: '+243',
    code: 'CD',
    name_i18n: t('コンゴ民主共和国'),
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI',
    name_i18n: t('コートジボワール'),
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
    name_i18n: t('フォークランド（マルビナス）諸島'),
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
    name_i18n: t('ガーンジー'),
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA',
    name_i18n: t('バチカン市国'),
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    name_i18n: t('香港'),
  },
  {
    name: 'Iran, Islamic Republic of',
    dial_code: '+98',
    code: 'IR',
    name_i18n: t('イラン・イスラム共和国'),
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
    name_i18n: t('マン島'),
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
    name_i18n: t('ジャージー'),
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: '+850',
    code: 'KP',
    name_i18n: t('朝鮮民主主義人民共和国'),
  },
  {
    name: 'Korea, Republic of',
    dial_code: '+82',
    code: 'KR',
    name_i18n: t('大韓民国'),
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: '+856',
    code: 'LA',
    name_i18n: t('ラオス人民民主共和国'),
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
    name_i18n: t('リビア'),
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
    name_i18n: t('マカオ'),
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    dial_code: '+389',
    code: 'MK',
    name_i18n: t('マケドニア旧ユーゴスラビア共和国'),
  },
  {
    name: 'Micronesia, Federated States of',
    dial_code: '+691',
    code: 'FM',
    name_i18n: t('ミクロネシア連邦'),
  },
  {
    name: 'Moldova, Republic of',
    dial_code: '+373',
    code: 'MD',
    name_i18n: t('モルドバ共和国'),
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    name_i18n: t('モザンビーク'),
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS',
    name_i18n: t('パレスチナ'),
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN',
    name_i18n: t('ピトケアン'),
  },
  {
    name: 'Réunion',
    dial_code: '+262',
    code: 'RE',
    name_i18n: t('レユニオン'),
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    name_i18n: t('ロシア連邦'),
  },
  {
    name: 'Saint Barthélemy',
    dial_code: '+590',
    code: 'BL',
    name_i18n: t('サン・バルテルミー'),
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
    name_i18n: t('セントヘレナ・アセンションおよびトリスタンダクーニャ'),
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1',
    code: 'KN',
    name_i18n: t('セントクリストファー・ネイビス'),
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1',
    code: 'LC',
    name_i18n: t('セントルシア'),
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
    name_i18n: t('サン・マルタン（フランス領）'),
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    name_i18n: t('サンピエール島・ミクロン島'),
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1',
    code: 'VC',
    name_i18n: t('セントビンセントおよびグレナディーン諸島'),
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
    name_i18n: t('サントメ・プリンシペ'),
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    name_i18n: t('ソマリア'),
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
    name_i18n: t('スヴァールバル諸島およびヤンマイエン島'),
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY',
    name_i18n: t('シリア・アラブ共和国'),
  },
  {
    name: 'Taiwan, Province of China',
    dial_code: '+886',
    code: 'TW',
    name_i18n: t('台湾'),
  },
  {
    name: 'Tanzania, United Republic of',
    dial_code: '+255',
    code: 'TZ',
    name_i18n: t('タンザニア'),
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
    name_i18n: t('東ティモール'),
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    dial_code: '+58',
    code: 'VE',
    name_i18n: t('ベネズエラ・ボリバル共和国'),
  },
  {
    name: 'Viet Nam',
    dial_code: '+84',
    code: 'VN',
    name_i18n: t('ベトナム'),
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1',
    code: 'VG',
    name_i18n: t('イギリス領ヴァージン諸島'),
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1',
    code: 'VI',
    name_i18n: t('アメリカ領ヴァージン諸島'),
  },
]
