import React from 'react'
import { View } from 'react-native'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'
import { t } from '../../../../modules/i18n'

export default function OutboundSettingTitle() {
  return (
    <View
      style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center' }}
    >
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
          lineHeight: 33,
          color: Colors.black,
          marginRight: 16,
        }}
      >
        {t('連携設定')}
      </Text>
    </View>
  )
}
