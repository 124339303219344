// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course/restaurant_course_service.proto" (package "auto_reserve.restaurants.restaurant_course", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantCourse } from "./restaurant_course_service";
import type { ArchiveRequest } from "./restaurant_course_service";
import type { UpdateRequest } from "./restaurant_course_service";
import type { CreateRequest } from "./restaurant_course_service";
import type { RestaurantCourseResource } from "./restaurant_course_resource";
import type { GetRequest } from "./restaurant_course_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./restaurant_course_service";
import type { ListRequest } from "./restaurant_course_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_course.RestaurantCourse
 */
export interface IRestaurantCourseClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.restaurant_course.ListRequest) returns (auto_reserve.restaurants.restaurant_course.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.restaurant_course.GetRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantCourseResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.restaurant_course.CreateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantCourseResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.restaurant_course.UpdateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantCourseResource>;
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.restaurant_course.ArchiveRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, RestaurantCourseResource>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_course.RestaurantCourse
 */
export class RestaurantCourseClient
  implements IRestaurantCourseClient, ServiceInfo
{
  typeName = RestaurantCourse.typeName;
  methods = RestaurantCourse.methods;
  options = RestaurantCourse.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.restaurant_course.ListRequest) returns (auto_reserve.restaurants.restaurant_course.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.restaurant_course.GetRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantCourseResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, RestaurantCourseResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.restaurant_course.CreateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantCourseResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, RestaurantCourseResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.restaurant_course.UpdateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantCourseResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, RestaurantCourseResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.restaurant_course.ArchiveRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseResource);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, RestaurantCourseResource> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ArchiveRequest, RestaurantCourseResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
