import React, { RefObject, useMemo, useRef } from 'react'
import {
  TouchableOpacity as RNTouchableOpacity,
  ScrollView,
  View,
} from 'react-native'

import { faGripLines } from '@fortawesome/pro-solid-svg-icons/faGripLines'
import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import numberWithDelimiter from 'modules/numberWithDelimiter'

import { useRestaurantTakeoutMenus } from 'models/TakeoutMenu'
import {
  createTakeoutMenuPage,
  updateTakeoutMenuPage,
  useTakeoutMenuPage,
} from 'models/TakeoutMenuPage'

import { useToken } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router-dom'

import { TakeoutProduct as TakeoutProductModel } from 'models/TakeoutProduct'
import { goBack, history } from 'modules/history'

import { toDragHandlers } from '@hello-ai/ar_shared/src/modules/drag'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import TakeoutView from 'components/Restaurant/TakeoutView'
import DragList from 'react-native-draglist'

const space = 40

function TakeoutProduct({
  takeoutProduct,
  index,
  onPress,
  onDragStart,
  onDragEnd,
  isActive,
  isSortable,
  scrollRef,
}: {
  takeoutProduct?: TakeoutProductModel
  index?: number
  onPress: () => void
  onDragStart: () => void
  onDragEnd: () => void
  isActive: boolean
  isSortable: boolean
  scrollRef: RefObject<HTMLDivElement>
}) {
  if (!takeoutProduct) {
    return null
  }

  return (
    <RNTouchableOpacity
      style={{
        paddingVertical: 24,
        borderTopWidth: index === 0 ? 0 : 0.5,
        borderColor: Colors.border,
        backgroundColor: isActive ? Colors.primaryBg : 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onPress={!isSortable ? onPress : undefined}
      {...(isSortable ? toDragHandlers(onDragStart, onDragEnd, scrollRef) : {})}
    >
      <View style={{ flex: 1, marginRight: 12 }}>
        <Text style={{ fontWeight: '600' }}>{takeoutProduct.name}</Text>
        <Text style={{ marginTop: 6 }}>
          {t('{{price}}円', {
            price: numberWithDelimiter(takeoutProduct.price),
          })}
        </Text>
      </View>
      {isSortable && (
        <FontAwesomeIcon
          icon={faGripLines}
          size={20}
          color={Colors.disabledBlack}
        />
      )}
    </RNTouchableOpacity>
  )
}

function TakeoutMenuPageFormScreenContent({
  takeoutMenuPage,
}: {
  takeoutMenuPage?: ReturnType<typeof useTakeoutMenuPage>['takeoutMenuPage']
}) {
  const scrollRef = useRef(null)
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const token = useToken()
  const takeoutMenuPageId = takeoutMenuPage?.id

  const { takeoutMenus, isLoading: isLoadingMenus } = useRestaurantTakeoutMenus(
    {
      restaurantId,
    }
  )

  const [name, setName] = useFormState(takeoutMenuPage?.name ?? '')
  const [description, setDescription] = useFormState(
    takeoutMenuPage?.description ?? ''
  )

  const [menus, setMenus] = useFormState(
    takeoutMenuPage?.takeout_menus
      ? takeoutMenuPage.takeout_menus.map((menu) => menu.id)
      : []
  )
  const [data, setData] = useFormState(
    takeoutMenuPage?.takeout_products
      ? takeoutMenuPage.takeout_products.map((product) => product.id)
      : []
  )

  const [isSortable, setIsSortable] = useFormState(false)

  const hasItems = data != null && data.length > 0

  const [tabName, setTabName] = useFormState('item')

  const tabItems = useMemo(() => {
    const items = []
    if (hasItems) {
      items.push({
        label: t('商品'),
        value: 'item',
      })
    }
    return items
  }, [hasItems])

  const onPress = async () => {
    if (name === '') {
      Alert.alert(t('カテゴリー名を入力してください'))
      return
    }
    if (token == null) {
      return
    }
    const params = {
      name,
      description,
      takeout_menu_ids: menus,
      takeout_product_ids: data,
    }
    if (takeoutMenuPageId != null) {
      await updateTakeoutMenuPage({
        token,
        takeoutMenuPageId,
        params,
      })
      goBack('..')
    } else {
      await createTakeoutMenuPage({
        token,
        restaurantId,
        params,
      })
      goBack('..')
    }
  }

  const onPressUpdateItemOrder = async () => {
    if (token == null) {
      return
    }
    if (takeoutMenuPageId != null) {
      const params = {
        table_product_ids: data,
      }
      await updateTakeoutMenuPage({
        token,
        takeoutMenuPageId,
        params,
      })
    }
  }

  const alertArchive = () => {
    const params = {
      status: 'archived',
    }
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (token == null || takeoutMenuPageId == null) {
            return
          }
          await updateTakeoutMenuPage({
            token,
            takeoutMenuPageId,
            params,
          })
          goBack('..')
        },
      },
    ])
  }

  const paddingHorizontal = width < sm ? 16 : 36

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        overflow: 'scroll',
        height: '100%',
      }}
      contentContainerStyle={{
        paddingVertical: space,
      }}
    >
      <DragList
        key={data.join('')}
        data={data}
        keyExtractor={(item) => item}
        renderItem={({ item, index, isActive, onDragStart, onDragEnd }) => (
          <View style={{ paddingHorizontal }}>
            {tabName === 'item' && (
              <TakeoutProduct
                takeoutProduct={takeoutMenuPage?.takeout_products.find(
                  (product) => product.id === item
                )}
                index={index}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onPress={() =>
                  history.push(
                    `/restaurants/${restaurantId}/takeout/products/${item}/edit`
                  )
                }
                isActive={isActive}
                isSortable={isSortable}
                scrollRef={scrollRef}
              />
            )}
          </View>
        )}
        ListHeaderComponent={
          <View style={{ paddingHorizontal }}>
            <TextInput
              placeholder={t('カテゴリー名')}
              value={name}
              onChangeText={(text) => setName(text)}
              autoCapitalize="none"
            />
            <TextInput
              placeholder={t('カテゴリー説明')}
              value={description}
              onChangeText={(text) => setDescription(text)}
              autoCapitalize="none"
              style={{ marginTop: space }}
              multiline
            />
            <View style={{ marginTop: space }}>
              <Text style={{ fontWeight: '600' }}>{t('メニュー')}</Text>
              <View style={{ marginTop: 20 }}>
                {isLoadingMenus ? (
                  <Loading />
                ) : (
                  <CheckboxGroup value={menus} onChange={setMenus}>
                    {takeoutMenus?.map((takeoutMenu) => {
                      return (
                        <Checkbox
                          key={takeoutMenu.id}
                          value={takeoutMenu.id}
                          checkboxLabel={
                            <CheckboxLabel value={takeoutMenu.name} />
                          }
                        />
                      )
                    })}
                  </CheckboxGroup>
                )}
              </View>
            </View>
            {tabItems.length === 1 && (
              <View style={{ marginTop: space }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ fontWeight: '600' }}>
                    {t('{{text}}の並び替え', { text: tabItems[0].label })}
                  </Text>
                  <Button
                    width={140}
                    height={48}
                    style={[
                      {
                        backgroundColor: isSortable ? Colors.primary : 'white',
                      },
                    ]}
                    onPress={() => {
                      if (isSortable) onPressUpdateItemOrder()
                      setIsSortable((prev) => !prev)
                    }}
                  >
                    <Text
                      style={{
                        color: !isSortable ? Colors.primary : 'white',
                        fontWeight: '600',
                      }}
                    >
                      {isSortable ? t('更新') : t('並び替え')}
                    </Text>
                  </Button>
                </View>
              </View>
            )}

            {tabItems.length > 1 && (
              <View>
                <View style={{ marginTop: space, flexDirection: 'column' }}>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: width < sm ? 'column' : 'row',
                      justifyContent: 'space-between',
                      alignItems: width < sm ? 'flex-start' : 'center',
                    }}
                  >
                    <View
                      style={[
                        {
                          flex: 0.5,
                          flexDirection: width < sm ? 'column' : 'row',
                          justifyContent: 'space-between',
                          alignItems: width < sm ? 'flex-start' : 'center',
                        },
                      ]}
                    >
                      <Text style={{ fontWeight: '600' }}>{t('並び替え')}</Text>
                      <View
                        style={[
                          width >= sm && { flex: 1, marginLeft: 12 },
                          width < sm && {
                            width: 240,
                            marginTop: 12,
                          },
                        ]}
                      >
                        <SegmentedControl
                          buttons={tabItems.map((item) => {
                            return {
                              text: item.label,
                              selected: item.value === tabName,
                              onPress: () => {
                                setTabName(item.value)
                              },
                            }
                          })}
                        />
                      </View>
                    </View>
                    <Button
                      width={140}
                      height={48}
                      style={[
                        {
                          backgroundColor: isSortable
                            ? Colors.primary
                            : 'white',
                        },
                      ]}
                      onPress={() => {
                        if (isSortable) onPressUpdateItemOrder()
                        setIsSortable((prev) => !prev)
                      }}
                    >
                      <Text
                        style={{
                          color: !isSortable ? Colors.primary : 'white',
                          fontWeight: '600',
                        }}
                      >
                        {isSortable ? t('更新') : t('並び替え')}
                      </Text>
                    </Button>
                  </View>
                </View>
              </View>
            )}
          </View>
        }
        ListFooterComponent={
          <View style={{ paddingHorizontal }}>
            <View style={{ flexDirection: 'row', marginTop: space }}>
              {takeoutMenuPageId != null && (
                <Button
                  height={56}
                  mode="outline"
                  variant="danger-secondary"
                  style={{
                    flex: 1,
                    marginRight: 32,
                  }}
                  onPress={() => alertArchive()}
                >
                  {t('アーカイブ')}
                </Button>
              )}
              <Button
                style={{
                  height: 56,
                  flex: 1,
                }}
                onPress={() => onPress()}
              >
                {takeoutMenuPageId != null ? t('更新する') : t('追加する')}
              </Button>
            </View>
          </View>
        }
        onReordered={(fromIndex, toIndex) => {
          setData((prev) => {
            const removed = prev[fromIndex]
            return prev.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, removed)
          })
        }}
      />
    </ScrollView>
  )
}

export default function TakeoutMenuPageFormScreen() {
  const { takeout_menu_page_id: takeoutMenuPageId } = useParams<{
    takeout_menu_page_id?: string
  }>()
  const { takeoutMenuPage, isLoading } = useTakeoutMenuPage({
    takeoutMenuPageId,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <TakeoutView>
      <TakeoutMenuPageFormScreenContent takeoutMenuPage={takeoutMenuPage} />
    </TakeoutView>
  )
}
