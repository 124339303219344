import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TableSeatChargePolicyResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_resource'
import { useTableSeatChargePolicies } from 'models/TableSeatChargePolicy'
import { useRestaurantId } from 'modules/useRestaurantId'
import React from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigate } from 'react-router'

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function TableSeatChargePolicy({
  tableSeatChargePolicy,
  onPress,
}: {
  tableSeatChargePolicy: TableSeatChargePolicyResource
  onPress: () => void
}) {
  return (
    <TouchableOpacity onPress={onPress}>
      <ShadowBox
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          backgroundColor: 'white',
        }}
      >
        <View>
          <Text style={{ fontSize: 18, fontWeight: '600' }}>
            {tableSeatChargePolicy.description}
          </Text>
          <Text
            style={{
              marginTop: 10,
              color: Colors.secondaryBlack,
            }}
          >
            {tableSeatChargePolicy.tableSeats.length > 0
              ? tableSeatChargePolicy.tableSeats
                  .filter((tableSeat) => tableSeat.status === 0)
                  .map((tableSeat) => tableSeat.name)
                  .join(', ')
              : t('なし')}
          </Text>
          {tableSeatChargePolicy.tableMenus.length > 0 && (
            <Text
              style={{
                marginTop: 4,
                color: Colors.secondaryBlack,
              }}
            >
              {tableSeatChargePolicy.tableMenus
                .map((tableMenu) => tableMenu.name)
                .join(', ')}
            </Text>
          )}
        </View>
        <View>
          <FontAwesomeIcon
            size={18}
            color={Colors.black}
            icon={faChevronRight}
          />
        </View>
      </ShadowBox>
    </TouchableOpacity>
  )
}

export default function TableSeatCharges() {
  const navigate = useNavigate()
  const restaurantId = useRestaurantId()
  const { tableSeatChargePolicies } = useTableSeatChargePolicies(restaurantId)
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      {tableSeatChargePolicies?.map((tableSeatChargePolicy, index) => (
        <View
          key={tableSeatChargePolicy.id}
          style={{ marginTop: index === 0 ? 0 : 24 }}
        >
          <TableSeatChargePolicy
            tableSeatChargePolicy={tableSeatChargePolicy}
            onPress={() =>
              navigate(
                `/restaurants/${restaurantId}/table_seats/table_seat_charge_policies/${tableSeatChargePolicy.id}/edit`
              )
            }
          />
        </View>
      ))}
    </ScrollView>
  )
}
