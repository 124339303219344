import { useEffect, useState } from 'react'
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteHook,
} from 'swr/infinite'

import { Arguments, Fetcher } from 'swr'
import { useStickyResult } from './useStickyResult'

export * from 'swr/infinite'
export { default } from 'swr/infinite'

export const useStickySWRInfinite = ((...args: Parameters<SWRInfiniteHook>) => {
  const { data, ...rest } = useSWRInfinite(...args)

  const stickyData = useStickyResult(data)

  return {
    ...rest,
    data: stickyData,
  }
}) as SWRInfiniteHook

export function useSWRInfiniteBatch<Data, Error = unknown>(
  getKey: (pageIndex: number) => Arguments,
  fetcher: Fetcher<Data>,
  config?: {
    batchSize?: number
  } & SWRInfiniteConfiguration<Data[], Error>
) {
  const {
    batchSize = 6,
    initialSize = 1,
    persistSize: _persistSize, // ignore
    ...configInfinite
  } = config ?? {}

  const [size, setSize] = useState(initialSize)

  const {
    data,
    error,
    isValidating,
    size: sizeInfinite,
    setSize: setSizeInfinite,
    mutate,
  } = useSWRInfinite<Data[], Error>(
    (batchPageIndex) => {
      const keys: Arguments[] = []

      for (let i = 0; i < batchSize; i++) {
        const pageIndex = batchPageIndex * batchSize + i

        if (pageIndex >= size) break
        const key = getKey(pageIndex)
        keys.push(Array.isArray(key) ? key : [key])
      }

      return [`$inf_batch-${size}$`, batchPageIndex, JSON.stringify(keys)]
    },
    async ([_prefix, batchPageIndex, serializedKeys]: [
      _prefix: string,
      batchPageIndex: number,
      serializedKeys: string
    ]) => {
      const keys = JSON.parse(serializedKeys)

      const promises: Array<Data | Promise<Data>> = []
      for (let i = 0; i < batchSize; i++) {
        const pageIndex = batchPageIndex * batchSize + i
        if (pageIndex >= size) break

        promises.push(fetcher(keys[i]))
      }
      return await Promise.all(promises)
    },
    configInfinite
  )

  useEffect(() => {
    setSizeInfinite(Math.ceil(size / batchSize))
  }, [batchSize, setSizeInfinite, size])

  const isLoading = data === undefined || data.length < sizeInfinite

  return { data, error, isValidating, isLoading, size, setSize, mutate }
}
