import React, { useState, useRef, useEffect } from 'react'
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  FormDivider,
  FormGroup,
  FormLabel,
} from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { SelectItem } from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useToken } from 'models/Auth'
import {
  useOwnerInvoiceSetting,
  updateOwnerInvoiceSetting,
  OwnerInvoiceSetting,
} from 'models/OwnerInvoiceSetting'
import { onError } from 'modules/swr'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import FormPayments from 'components/Shared/FormPayments'
import { Elements } from '@stripe/react-stripe-js'
import { getStripe } from 'modules/stripe'
import { getLocale } from 'modules/locale'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const buttonHeight = 48
const buttonWidth = 248

export default function PaymentEdit() {
  const { data: invoiceSetting, mutate } = useOwnerInvoiceSetting()
  const token = useToken()
  const [method, setMethod] = useFormState<
    OwnerInvoiceSetting['payment_method']
  >(invoiceSetting?.payment_method ?? '')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const disabled = method === 'bill' || isSubmitting
  const [isEditingCard, setIsEditingCard] = useState(false)
  const formPaymentsRef = useRef<{ addCreditCard: () => void }>(null)
  const stripePromise: any = getStripe()
  const methods: Array<SelectItem<OwnerInvoiceSetting['payment_method']>> = [
    {
      label: t('請求書払い'),
      value: 'bill',
    },
    {
      label: t('クレジットカード払い'),
      value: 'card',
    },
  ]

  useEffect(() => {
    if (invoiceSetting && invoiceSetting.default_card == null) {
      setIsEditingCard(true)
    }
  }, [invoiceSetting])

  function FormSection({
    style,
    children,
  }: {
    style?: StyleProp<ViewStyle>
    children?: React.ReactNode
  }) {
    return (
      <View style={style}>
        <ShadowBox style={{ marginTop: 22 }}>{children}</ShadowBox>
      </View>
    )
  }

  const onPress = async () => {
    if (token === null) return
    if (invoiceSetting == null) return
    const stripeToken =
      method === 'card' ? await formPaymentsRef.current?.addCreditCard() : null

    setIsSubmitting(true)

    try {
      const { error } = await updateOwnerInvoiceSetting(
        token,
        invoiceSetting.id,
        {
          owner_invoice_setting: {
            id: invoiceSetting.id,
            payment_method: method,
            stripe_token: stripeToken,
          },
        }
      )

      if (error != null) {
        onError(error)
      } else {
        mutate()
        setIsEditingCard(false)
        displayToastInfo(t('登録しました'))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  if (invoiceSetting === undefined) {
    return <Loading />
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: getLocale() as any,
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          padding: 40,
        }}
      >
        <FormSection>
          <FormGroup
            style={{ alignItems: 'baseline' }}
            formLabel={<FormLabel value={t('支払い方法')} />}
          >
            <RadioGroup value={method} onChange={setMethod}>
              {methods.map((m) => (
                <Radio
                  key={m.value}
                  value={m.value}
                  radioLabel={<RadioLabel value={m.label} />}
                />
              ))}
            </RadioGroup>
          </FormGroup>

          <FormDivider style={{ marginTop: 24 }} />

          {method === 'card' && isEditingCard && (
            <FormPayments ref={formPaymentsRef} />
          )}

          {method === 'card' &&
            invoiceSetting.payment_method === 'card' &&
            !isEditingCard && (
              <FormGroup
                style={{ alignItems: 'baseline', marginTop: 42 }}
                formLabel={<FormLabel value={t('カード番号')} />}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text>
                    **** **** **** {invoiceSetting.default_card.last4}
                  </Text>
                  <TouchableOpacity
                    style={{ marginLeft: 8 }}
                    onPress={() => setIsEditingCard(true)}
                  >
                    <Text style={{ color: Colors.primary }}>
                      {t('変更する')}
                    </Text>
                  </TouchableOpacity>
                </View>
              </FormGroup>
            )}

          {method === 'bill' && (
            <Text
              style={{
                marginTop: 24,
                fontSize: 14,
                lineHeight: 18,
              }}
            >
              {t(
                '※送付先メールアドレスの変更を行いたい場合はお問い合わせください。'
              )}
            </Text>
          )}
        </FormSection>
        {method === 'card' && isEditingCard && (
          <View
            style={{
              marginTop: 48,
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              disabled={disabled}
              onPress={onPress}
              height={buttonHeight}
              width={buttonWidth}
            >
              {t('登録する')}
            </Button>
          </View>
        )}
      </ScrollView>
    </Elements>
  )
}
