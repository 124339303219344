import React from 'react'
import { View } from 'react-native'
import { Text } from '../../../Text'
import SectionTitle from '../Shared/SectionTitle'
import { t } from '../../../../modules/i18n'
import { Colors } from '../../../../constants/Colors'
import { FormGroup, FormLabel } from '../../../Form'
import { SelectInput, SelectItem } from '../../../SelectInput'
import { TableSeat } from '../../../../types/ForR/SiteControllerSeat'

export type TableSeatStatus = 'enabled' | 'stop'

const TableSeatStatusList: SelectItem<TableSeatStatus>[] = [
  { label: t('連携中'), value: 'enabled' },
  { label: t('連携停止'), value: 'stop' },
]

interface SelectTableSeatViewProps {
  tableSeats?: TableSeat[]
  selectedTableSeatId?: TableSeat['id']
  onChangeSelectTableSeatId: (tableSeatId: TableSeat['id']) => void
  tableSeatStatus: TableSeatStatus
  onChangeTableSeatStatus: (tableSeatStatus: TableSeatStatus) => void
}

export default function SelectTableSeatView({
  tableSeats,
  selectedTableSeatId,
  onChangeSelectTableSeatId,
  tableSeatStatus,
  onChangeTableSeatStatus,
}: SelectTableSeatViewProps) {
  return (
    <View>
      <SectionTitle
        title={t('1.1.当サービスの席を選択')}
        helperText={t(
          '他社グルメサイトの席と連携ができるのは当サービスで作成された席です。連携ステータスを連携中に設定していると席が連携されます'
        )}
      />
      <Text
        style={{
          marginBottom: 16,
        }}
      >
        {t(
          '当サービスで作成された席を選択し、連携ステータスを設定してください'
        )}
      </Text>

      <View
        style={{
          backgroundColor: Colors.white,
          padding: 24,
          rowGap: 24,
          borderRadius: 8,
        }}
      >
        <FormGroup formLabel={<FormLabel value={t('当サービスの席')} />}>
          <SelectInput
            items={
              tableSeats?.map((item) => ({
                label: item.name,
                value: item.id,
              })) ?? []
            }
            setValue={onChangeSelectTableSeatId}
            selectedValue={selectedTableSeatId}
          />
        </FormGroup>
        <FormGroup formLabel={<FormLabel value={t('連携ステータス')} />}>
          <SelectInput
            items={TableSeatStatusList}
            setValue={onChangeTableSeatStatus}
            selectedValue={tableSeatStatus}
          />
        </FormGroup>
      </View>
    </View>
  )
}
