import React, { ReactNode, useRef, useState } from 'react'
import { Alert, ScrollView, View, Image, Linking, Platform } from 'react-native'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Loading from '../../components/Shared/Loading'
import {
  Customer as CustomerModel,
  deleteCustomer,
  useCustomer,
  useCustomerReservationCards,
  useCustomerTableOrders,
  useCustomerTakeoutOrders,
} from '@hello-ai/for_r_app/src/models/Customer'
import { TableOrder as TableOrderModel } from '../../models/TableOrder'
import { TakeoutOrder as TakeoutOrderModel } from '../../models/TakeoutOrder'
import { CustomerLarge } from '@hello-ai/for_r_app/src/components/Shared/Customer'
import { getCustomerDisplayName } from '@hello-ai/for_r_app/src/components/Customers/Customer'
import { ReservationCard as ReservationCardModel } from '@hello-ai/ar_shared/src/types/ForR/ReservationCard'
import {
  getStatusLabelText as getReservationStatusLabelText,
  getStatusLabelColor as getReservationStatusLabelColor,
} from '@hello-ai/for_r_app/src/components/Reservation/common'
import {
  getStatusLabelText as getReservationListStatusLabelText,
  getStatusLabelColor as getReservationListStatusLabelColor,
} from '@hello-ai/for_r_app/src/components/ReservationList/common'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { useRestaurant } from '@hello-ai/for_r_app/src/models/Restaurant'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useNavigation } from '../../modules/navigation/useNavigation'
import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { Trans } from 'react-i18next'

const chevronWidth = 12

const maxRows = 8

function TopHeader({
  title,
  onPress,
}: {
  title: string
  onPress?: () => void
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text style={{ fontSize: 22, fontWeight: '600' }}>{title}</Text>
      {onPress != null && (
        <TouchableOpacity
          onPress={onPress}
          style={{
            width: 140,
            height: 36,
            borderRadius: 18,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              color: Colors.secondaryBlack,
            }}
          >
            {t('全て見る')}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

function Table({ children }: { children?: React.ReactNode }) {
  return (
    <View
      style={[
        {
          borderRadius: 8,
          shadowRadius: 4,
          elevation: 4,
          shadowColor: '#000',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 2 },
          backgroundColor: 'white',
          padding: 0,
        },
      ]}
    >
      {children}
    </View>
  )
}

function Row({
  children,
  onPress,
}: {
  children?: React.ReactNode
  onPress?: () => void
}) {
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        padding: 20,
      }}
    >
      {children}
    </TouchableOpacity>
  )
}
function Cell({
  isLead = false,
  flex,
  children,
}: {
  isLead?: boolean
  flex: number
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flex,
          justifyContent: 'center',
        },
        !isLead && {
          marginLeft: 12,
        },
      ]}
    >
      {children}
    </View>
  )
}

function FixedCell({
  width,
  children,
}: {
  width: number
  children?: React.ReactNode
}) {
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width,
      }}
    >
      {children}
    </View>
  )
}

function Header({ children }: { children?: React.ReactNode }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        paddingHorizontal: 20,
        paddingVertical: 16,
        borderBottomWidth: 0.5,
        borderBottomColor: Colors.border,
      }}
    >
      {children}
    </View>
  )
}

function HeaderCell({
  isLead = false,
  flex,
  children,
}: {
  isLead?: boolean
  flex: number
  children: string
}) {
  const { width, sm } = useResponsive()
  return (
    <Cell isLead={isLead} flex={flex}>
      <Text
        style={{
          fontWeight: '600',
          fontSize: width < sm ? 12 : 14,
        }}
      >
        {children}
      </Text>
    </Cell>
  )
}

function ReservationCard({
  restaurantId,
  reservationCard,
}: {
  restaurantId: number
  reservationCard: ReservationCardModel
}) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const fontSize = width < sm ? 14 : 18

  let partySize = ''
  let statusColor = ''
  let statusLabel = ''
  if (reservationCard.type === 'Reservation') {
    const reservation = reservationCard.reservation
    partySize = String(reservation.party_size)
    statusColor = getReservationStatusLabelColor({
      status: reservation.status,
      past: reservation.past,
    })
    statusLabel = getReservationStatusLabelText({
      status: reservation.status,
      past: reservation.past,
    })
  } else if (reservationCard.type === 'ReservationList') {
    const reservationList = reservationCard.reservation_list
    partySize = String(reservationList.party_size)
    statusColor = getReservationListStatusLabelColor({
      status: reservationList.status,
      completeStatus: reservationList.complete_status,
    })
    statusLabel = getReservationListStatusLabelText({
      status: reservationList.status,
      completeStatus: reservationList.complete_status,
    })
  }

  return (
    <Row
      onPress={() => {
        if (!reservationCard.reservation) {
          return
        }
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations/${reservationCard.reservation.uuid}`
          )
        } else {
          navigation.navigate('ReservationsShow', {
            restaurantReservationId: reservationCard.reservation.uuid,
          })
        }
      }}
    >
      <Cell isLead flex={3}>
        <Text
          style={{
            fontSize,
          }}
        >
          {dayjs(reservationCard.reserved_at).format(
            // eslint-disable-next-line ar-i18n/require-translation-ja
            'YYYY年M月DD日(ddd) H時mm分'
          )}
        </Text>
      </Cell>
      <Cell flex={1}>
        <Text
          style={{
            fontSize,
          }}
        >
          {partySize}
        </Text>
      </Cell>
      <Cell flex={1}>
        <Text
          style={{
            fontSize,
            color: statusColor,
          }}
        >
          {statusLabel}
        </Text>
      </Cell>
      {false && (
        <FixedCell width={chevronWidth}>
          <FontAwesomeIcon
            icon={faChevronRight}
            size={12}
            color={Colors.black}
          />
        </FixedCell>
      )}
    </Row>
  )
}

type TableOrderStatus = 'open' | 'complete' | 'canceled'
function getTableOrderStatusText(status: TableOrderStatus) {
  switch (status) {
    case 'open':
      return t('未会計')
    case 'complete':
      return t('会計済み')
    case 'canceled':
      return t('キャンセル済み')
    default:
      return ''
  }
}

function getTableOrderStatusColor(status: TableOrderStatus) {
  switch (status) {
    case 'open':
      return Colors.black
    case 'complete':
      return Colors.accent
    case 'canceled':
      return Colors.secondaryBlack
    default:
      return Colors.black
  }
}

function TableOrder({ tableOrder }: { tableOrder: TableOrderModel }) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()

  const fontSize = width < sm ? 14 : 18

  return (
    <Row
      onPress={() => {
        if (Platform.OS === 'web') {
          // TODO: Implement navigation for web
        } else {
          navigation.navigate('TableOrder', {
            tableOrderId: tableOrder.id,
          })
        }
      }}
    >
      <Cell isLead flex={3}>
        <Text
          style={{
            fontSize,
          }}
        >
          {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
          {dayjs(tableOrder.completed_at).format('YYYY年M月DD日(ddd) H時mm分')}
        </Text>
      </Cell>
      <Cell flex={1}>
        <Text
          style={{
            fontSize,
          }}
        >
          {tableOrder.party_size ?? t('未指定')}
        </Text>
      </Cell>
      <Cell flex={1}>
        <Text
          style={{
            fontSize,
            color: getTableOrderStatusColor(tableOrder.status),
          }}
        >
          {getTableOrderStatusText(tableOrder.status)}
        </Text>
      </Cell>
      {false && (
        <FixedCell width={chevronWidth}>
          <FontAwesomeIcon
            icon={faChevronRight}
            size={12}
            color={Colors.black}
          />
        </FixedCell>
      )}
    </Row>
  )
}
type TakeoutOrderStatus =
  | 'cart'
  | 'requested'
  | 'confirmed'
  | 'complete'
  | 'canceled'
type TakeoutOrderPreparationStatus = 'pending' | 'progress' | 'complete'
function getTakeoutOrderStatusText(
  status: TakeoutOrderStatus,
  preparationStatus: TakeoutOrderPreparationStatus
) {
  if (status === 'cart') return t('未会計')
  if (status === 'requested') return t('準備中')

  if (status === 'confirmed') {
    if (preparationStatus === 'progress') {
      return t('準備中')
    }
    if (preparationStatus === 'complete') {
      return t('受取済み')
    }
  }
  if (status === 'complete') return t('受取済み')
  if (status === 'canceled') return t('キャンセル済')

  return ''
}

function getTakeoutOrderStatusColor(
  status: TakeoutOrderStatus,
  preparationStatus: TakeoutOrderPreparationStatus
) {
  if (status === 'cart') return Colors.black
  if (status === 'requested') return Colors.black

  if (status === 'confirmed') {
    if (preparationStatus === 'progress') {
      return Colors.black
    }
    if (preparationStatus === 'complete') {
      return Colors.accent
    }
  }
  if (status === 'complete') return Colors.accent
  if (status === 'canceled') return Colors.secondaryBlack

  return Colors.black
}

function Divider() {
  return (
    <View
      style={{ width: 0.5, backgroundColor: Colors.border, height: '100%' }}
    />
  )
}

function SectionTitle({
  title,
  helperText,
}: {
  title: string
  helperText: string
}) {
  const { width, sm } = useResponsive()
  const [isOpenPop, setIsOpenPop] = useState(false)
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text style={{ marginRight: 4, fontSize: 18, fontWeight: '600' }}>
        {title}
      </Text>
      <Popover
        isOpen={isOpenPop}
        onClose={() => setIsOpenPop(false)}
        placement="bottomEnd"
        anchor={
          <TouchableOpacity onPress={() => setIsOpenPop(true)}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size={16}
              color={Colors.primary}
            />
          </TouchableOpacity>
        }
      >
        <Text
          style={{
            width: width >= sm ? 400 : 150,
            fontWeight: '300',
            color: Colors.black,
            padding: 24,
            backgroundColor: Colors.white,
          }}
        >
          {helperText}
        </Text>
      </Popover>
    </View>
  )
}

function LabeledView({
  label,
  children,
}: {
  label: string
  children: ReactNode
}) {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ rowGap: 8 }}>
        <Text style={{ fontSize: 10, fontWeight: '300' }}>{label}</Text>
        {children}
      </View>
    </View>
  )
}

function LabeledTextWithUnit({
  label,
  value,
  unit,
}: {
  label: string
  value: string
  unit: string
}) {
  return (
    <LabeledView label={label}>
      <View
        style={{ flexDirection: 'row', columnGap: 4, alignItems: 'flex-end' }}
      >
        <Text style={{ fontSize: 18, fontWeight: '600' }}>{value}</Text>
        <Text style={{ fontSize: 12, fontWeight: '300' }}>{unit}</Text>
      </View>
    </LabeledView>
  )
}

function TakeoutOrder({ takeoutOrder }: { takeoutOrder: TakeoutOrderModel }) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()

  const fontSize = width < sm ? 14 : 18

  return (
    <Row
      onPress={() => {
        if (Platform.OS === 'web') {
          // TODO: Implement navigation for web
        } else {
          navigation.navigate('TakeoutOrder', {
            takeoutOrderId: takeoutOrder.id,
          })
        }
      }}
    >
      <Cell isLead flex={3}>
        <Text
          style={{
            fontSize,
          }}
        >
          {dayjs(takeoutOrder.requested_at).format(
            // eslint-disable-next-line ar-i18n/require-translation-ja
            'YYYY年M月DD日(ddd) H時mm分'
          )}
        </Text>
      </Cell>
      <Cell flex={1}>
        <Text
          style={{
            fontSize,
          }}
        >
          {takeoutOrder.order_number}
        </Text>
      </Cell>
      <Cell flex={1}>
        <Text
          style={{
            fontSize,
            color: getTakeoutOrderStatusColor(
              takeoutOrder.status,
              takeoutOrder.preparation_status
            ),
          }}
        >
          {getTakeoutOrderStatusText(
            takeoutOrder.status,
            takeoutOrder.preparation_status
          )}
        </Text>
      </Cell>
      {false && (
        <FixedCell width={chevronWidth}>
          <FontAwesomeIcon
            icon={faChevronRight}
            size={12}
            color={Colors.black}
          />
        </FixedCell>
      )}
    </Row>
  )
}

function TakeoutOrders({
  restaurantId,
  customerId,
}: {
  restaurantId: number
  customerId: CustomerModel['id']
}) {
  const { takeoutOrders } = useCustomerTakeoutOrders(restaurantId, customerId)

  if (takeoutOrders != null && takeoutOrders.length === 0) {
    return null
  }

  return (
    <View style={{ marginTop: 40 }}>
      <TopHeader title={t('テイクアウト')} />
      <View style={{ marginTop: 16 }}>
        {takeoutOrders == null ? (
          <Loading />
        ) : (
          <Table>
            <Header>
              <HeaderCell isLead flex={3}>
                {t('注文日時')}
              </HeaderCell>
              <HeaderCell flex={1}>{t('注文番号')}</HeaderCell>
              <HeaderCell flex={1}>{t('ステータス')}</HeaderCell>
              {false && <FixedCell width={chevronWidth} />}
            </Header>
            {takeoutOrders.slice(0, maxRows).map((takeoutOrder) => (
              <TakeoutOrder key={takeoutOrder.id} takeoutOrder={takeoutOrder} />
            ))}
          </Table>
        )}
      </View>
    </View>
  )
}

function ReservationCards({
  restaurantId,
  customerId,
  customer,
}: {
  restaurantId: number
  customerId: CustomerModel['id']
  customer: CustomerModel
}) {
  const { width, sm } = useResponsive()
  const [isOpenPop, setIsOpenPop] = useState(false)
  const [page, setPage] = useState(1)
  const { reservationCards, headerData } = useCustomerReservationCards(
    restaurantId,
    customerId,
    {
      page,
      per_page: maxRows,
    }
  )

  if (reservationCards != null && page <= 1 && reservationCards.length === 0) {
    return null
  }

  return (
    <View style={{ marginTop: 40 }}>
      <TopHeader title={t('予約')} />
      <View
        style={{
          flexDirection: width >= sm ? 'row' : 'column',
          marginTop: 24,
        }}
      >
        <View
          style={{
            backgroundColor: Colors.white,
            flexDirection: width >= sm ? 'row' : 'column',
            alignItems: 'center',
            paddingVertical: width >= sm ? 24 : 16,
            paddingHorizontal: 16,
            columnGap: 10,
            rowGap: 16,
            flex: 1,
            borderRadius: 8,
            shadowColor: '#000',
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: 2 },
          }}
        >
          <SectionTitle
            title={t('予約回数')}
            helperText={t(
              '予約回数は「予約成立」のうち、予約時間が現在時刻を過ぎているものを算出しています。'
            )}
          />
          <LabeledTextWithUnit
            label={t('当店')}
            value={customer.visit_count.toString()}
            unit={t('回')}
          />
          {width >= sm && (
            <>
              <Divider />
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  columnGap: 4,
                  flex: 1,
                }}
              >
                <TouchableOpacity
                  onPress={() => setIsOpenPop(true)}
                  style={{ flex: 1 }}
                >
                  <LabeledTextWithUnit
                    label={t('他店')}
                    value={customer.restaurant_visits
                      .reduce((acc, visit) => acc + visit.visit_count, 0)
                      .toString()}
                    unit={t('回')}
                  />
                </TouchableOpacity>
                <Popover
                  isOpen={isOpenPop}
                  onClose={() => setIsOpenPop(false)}
                  placement="bottomEnd"
                  showArrowElement={false}
                  anchor={
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      size={16}
                      color={Colors.primary}
                      style={{ marginBottom: 2, marginLeft: -12 }}
                    />
                  }
                >
                  <ScrollView
                    style={{ height: 200 }}
                    contentContainerStyle={{
                      paddingVertical: 12,
                      paddingHorizontal: 24,
                      rowGap: 12,
                    }}
                  >
                    {customer.restaurant_visits.map((visit) => (
                      <View
                        key={visit.restaurant_id}
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          borderBottomWidth: 0.5,
                          borderColor: Colors.border,
                          paddingBottom: 12,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: '300',
                            paddingRight: 12,
                          }}
                        >
                          {visit.restaurant_name}
                        </Text>
                        <Text style={{ fontSize: 18, fontWeight: '600' }}>
                          {visit.visit_count}
                          <Text style={{ fontSize: 12, fontWeight: '300' }}>
                            {t('回')}
                          </Text>
                        </Text>
                      </View>
                    ))}
                  </ScrollView>
                </Popover>
              </View>
              <Divider />
              <LabeledTextWithUnit
                label={t('合計')}
                value={(
                  customer.visit_count +
                  customer.restaurant_visits.reduce(
                    (acc, visit) => acc + visit.visit_count,
                    0
                  )
                ).toString()}
                unit={t('回')}
              />
            </>
          )}
        </View>
        <View style={{ flex: 1 }} />
      </View>
      <View style={{ marginTop: 16 }}>
        {reservationCards == null ? (
          <Loading />
        ) : (
          <Table>
            <Header>
              <HeaderCell isLead flex={3}>
                {t('予約日時')}
              </HeaderCell>
              <HeaderCell flex={1}>{t('人数')}</HeaderCell>
              <HeaderCell flex={1}>{t('ステータス')}</HeaderCell>
              {false && <FixedCell width={chevronWidth} />}
            </Header>
            {reservationCards.map((reservationCard) => (
              <ReservationCard
                key={reservationCard.id}
                restaurantId={restaurantId}
                reservationCard={reservationCard}
              />
            ))}
            <View
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <Pagination
                currentPage={page}
                setPage={setPage}
                totalPage={headerData?.totalPages ?? 0}
              />
            </View>
          </Table>
        )}
      </View>
    </View>
  )
}

function TableOrders({
  restaurantId,
  customerId,
}: {
  restaurantId: number
  customerId: CustomerModel['id']
}) {
  const [page, setPage] = useState(1)
  const { width, sm } = useResponsive()
  const { tableOrders, headerData } = useCustomerTableOrders(
    restaurantId,
    customerId,
    {
      page,
      per_page: maxRows,
      order_type: 'all',
    }
  )

  if (tableOrders != null && page <= 1 && tableOrders.length === 0) {
    return null
  }

  return (
    <View style={{ marginTop: 40 }}>
      <TopHeader title={t('来店')} />
      <View
        style={{
          flexDirection: width >= sm ? 'row' : 'column',
          marginTop: 24,
        }}
      >
        <View
          style={{
            backgroundColor: Colors.white,
            flexDirection: width >= sm ? 'row' : 'column',
            alignItems: 'center',
            paddingVertical: width >= sm ? 24 : 16,
            paddingLeft: 16,
            paddingRight: 24,
            columnGap: 10,
            rowGap: 16,
            flex: 1,
            borderRadius: 8,
            shadowColor: '#000',
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: 2 },
            justifyContent: 'space-between',
          }}
        >
          <SectionTitle
            title={t('来店回数')}
            helperText={t(
              '来店回数は、店内でお食事(イートイン)をした回数で算出しています。'
            )}
          />
          <View>
            <Text style={{ fontSize: 10, color: Colors.black60 }}>
              {t('当店')}
            </Text>
            {/* eslint-disable ar-i18n/require-translation-ja */}
            <Trans
              i18nKey={'<0><0>{{count}}<0><1>回</1></0>'}
              values={{
                count: `${headerData?.totalCount ?? 0}`,
              }}
            >
              <Text>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: '600',
                    color: Colors.black,
                  }}
                >
                  {`${headerData?.totalCount ?? 0}`}
                </Text>
                <Text style={{ fontSize: 12, color: Colors.black60 }}>
                  {'回'}
                </Text>
              </Text>
            </Trans>
            {/* eslint-enable ar-i18n/require-translation-ja */}
          </View>
        </View>
        <View style={{ flex: 1 }} />
      </View>
      <View style={{ marginTop: 16 }}>
        {tableOrders == null ? (
          <Loading />
        ) : (
          <Table>
            <Header>
              <HeaderCell isLead flex={3}>
                {t('注文日時')}
              </HeaderCell>
              <HeaderCell flex={1}>{t('人数')}</HeaderCell>
              <HeaderCell flex={1}>{t('ステータス')}</HeaderCell>
              {false && <FixedCell width={chevronWidth} />}
            </Header>
            {tableOrders.map((tableOrder) => (
              <TableOrder key={tableOrder.id} tableOrder={tableOrder} />
            ))}
            <View
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <Pagination
                currentPage={page}
                setPage={setPage}
                totalPage={headerData?.totalPages ?? 0}
              />
            </View>
          </Table>
        )}
      </View>
    </View>
  )
}

export function Customer({
  restaurantId,
  customerId,
}: {
  restaurantId: number
  customerId: string
}) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const alertRef = useRef<AlertMethods>(null)

  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null || restaurant.reservation_book_plan_type === 'free'

  const { customer, mutate } = useCustomer(restaurantId, customerId)

  React.useLayoutEffect(() => {
    if (customer) {
      navigation.setOptions({
        headerTitle: getCustomerDisplayName(customer, t('さん')),
      })
    }
  }, [customer, navigation])

  if (customer === undefined) {
    return <Loading />
  }

  const onDelete = async () => {
    deleteCustomer(restaurantId, customerId).then(() => {
      if (Platform.OS === 'web') {
        navigate(`/restaurants/${restaurantId}/customers`)
      } else {
        navigation.navigate('Customers', {
          customerId,
        })
      }
    })
  }

  const handlePress = () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    const alertText = t(
      '本当に削除しますか？\n※一度削除したデータは元に戻せません。'
    )
    alert(alertText, undefined, [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: onDelete,
      },
    ])
  }

  return (
    <>
      <ScrollView
        style={[
          Platform.OS === 'web' && {
            backgroundColor: Colors.bgBlack,
          },
        ]}
        contentContainerStyle={[
          width < sm
            ? {
                paddingHorizontal: 16,
                paddingVertical: 20,
              }
            : {
                paddingHorizontal: 32,
                paddingVertical: 40,
              },
        ]}
      >
        {isFreePlan && (
          <View
            style={{
              flexDirection: 'row',
              padding: 16,
              backgroundColor: Colors.white,
              borderRadius: 8,
              borderColor: Colors.primary,
              borderWidth: 1,
              marginBottom: 40,
              gap: 12,
              alignItems: 'center',
            }}
          >
            <Image
              style={{ height: 16, width: 16, marginRight: 4 }}
              source={require('../../assets/images/plan-icon.png')}
            />
            <Text style={{ flex: 1, color: Colors.black }}>
              {t(
                'お客様属性、予約回数の表示はスタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
              )}
            </Text>
            <TouchableOpacity
              style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}
              onPress={() =>
                Linking.openURL(
                  'https://autoreserve.com/for_restaurants/contact'
                )
              }
            >
              <Text style={{ color: Colors.primary }}>
                {t('お問い合わせへ')}
              </Text>
              <FontAwesomeIcon icon={faChevronRight} color={Colors.primary} />
            </TouchableOpacity>
          </View>
        )}
        <CustomerLarge
          customer={customer}
          onCustomerUpdated={mutate}
          restaurantId={restaurantId}
        />
        <TableOrders restaurantId={restaurantId} customerId={customerId} />
        <ReservationCards
          restaurantId={restaurantId}
          customerId={customerId}
          customer={customer}
        />
        <TakeoutOrders restaurantId={restaurantId} customerId={customerId} />
        <View>
          <Button
            style={[
              {
                borderColor: Colors.caution,
                height: 44,
                backgroundColor: 'transparent',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: width < sm ? 48 : 40,
                paddingHorizontal: 24,
              },
            ]}
            textStyle={{
              color: Colors.caution,
              fontWeight: 'normal',
            }}
            disabled={customer.user != null}
            onPress={handlePress}
          >
            {t('この顧客を削除する')}
          </Button>
          {customer.user != null && (
            <Text
              style={{
                marginTop: 16,
                fontSize: 14,
                color: Colors.black60,
                textAlign: 'center',
              }}
            >
              {t('※AutoReserveから登録した顧客のため、削除できません')}
            </Text>
          )}
        </View>
      </ScrollView>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </>
  )
}
