import React from 'react'
import { View } from 'react-native'
import { ModalCenter, ModalHeader } from '../ModalCenter'
import { Colors } from '../../constants/Colors'
import { Text } from '../Text'
import { TouchableOpacity } from '../Touchables'

type DialogProps = {
  mode?: 'default' | 'vertical'
  isVisible: boolean
  onClose: () => void
  children?: React.ReactNode
}

type DialogActionProps = {
  variant?: 'primary' | 'secondary' | 'danger'
  onPress?: () => void
  disabled?: boolean
  children: string
}

const dialogMaxWidth = 320

const DialogPropsContext = React.createContext<DialogProps | undefined>(
  undefined
)

export function DialogTitle({ children }: { children: string }) {
  return (
    <ModalHeader
      style={{
        // No insets should be applied to the header in a dialog
        paddingTop: 16,
      }}
    >
      {children}
    </ModalHeader>
  )
}

export function DialogContent({ children }: { children?: React.ReactNode }) {
  return (
    <View
      style={{
        paddingTop: 0,
        paddingBottom: 16,
        paddingHorizontal: 16,
      }}
    >
      {children}
    </View>
  )
}

export function DialogActions({ children }: { children?: React.ReactNode }) {
  const context = React.useContext(DialogPropsContext)
  if (context === undefined) {
    throw new Error('DialogActions must be used within a Dialog')
  }

  const { mode = 'default' } = context

  return (
    <View
      style={{
        flexDirection: mode === 'default' ? 'row' : 'column',
      }}
    >
      {children}
    </View>
  )
}

export function DialogAction({
  variant = 'primary',
  onPress,
  disabled,
  children,
}: DialogActionProps) {
  const context = React.useContext(DialogPropsContext)
  if (context === undefined) {
    throw new Error('DialogActions must be used within a Dialog')
  }

  const { mode = 'default' } = context

  return (
    <View
      style={{
        borderTopWidth: 0.5,
        borderTopColor: Colors.border,
        flex: mode === 'default' ? 1 : undefined,
        minHeight: 44,
      }}
    >
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={[
          {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          },
          disabled && {
            opacity: 0.4,
          },
        ]}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: '600',
            color:
              variant === 'primary'
                ? Colors.primary
                : variant === 'secondary'
                ? Colors.secondaryBlack
                : Colors.caution,
          }}
        >
          {children}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export function Dialog(props: DialogProps) {
  const { mode: _mode, isVisible, onClose, children } = props
  return (
    <DialogPropsContext.Provider value={props}>
      <ModalCenter
        isVisible={isVisible}
        onClose={onClose}
        style={{
          maxWidth: dialogMaxWidth,
        }}
      >
        <View style={{ backgroundColor: 'white', borderRadius: 4 }}>
          {children}
        </View>
      </ModalCenter>
    </DialogPropsContext.Provider>
  )
}
