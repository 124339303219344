import { useCallback } from 'react'
import { Middleware } from 'swr'
import { useFocusEffect } from '@react-navigation/core'

// フォーカスの当たっている画面内のみ全てrevalidateするmiddleware
const activeQueries: Array<() => void> = []
export async function revalidateAll() {
  const allRevalidations = activeQueries.map((mutate) => mutate())

  await Promise.all(allRevalidations)
}
export const revalidateAllMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config)
    useFocusEffect(
      useCallback(() => {
        activeQueries.push(swr.mutate)

        return () => {
          const i = activeQueries.indexOf(swr.mutate)
          if (i > -1) {
            activeQueries.splice(i, 1)
          }
        }
      }, [swr.mutate])
    )

    return swr
  }
}
