// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/menu_restaurant_group/menu_restaurant_group_resource.proto" (package "auto_reserve.restaurants.menu_restaurant_group", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource
 */
export interface MenuRestaurantGroupResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource restaurants = 8;
   */
  restaurants: MenuRestaurantGroupResource_RestaurantResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource
 */
export interface MenuRestaurantGroupResource_RestaurantResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource.Logo logo = 3;
   */
  logo?: MenuRestaurantGroupResource_RestaurantResource_Logo;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource.Logo
 */
export interface MenuRestaurantGroupResource_RestaurantResource_Logo {
  /**
   * @generated from protobuf field: string xl = 1;
   */
  xl: string;
  /**
   * @generated from protobuf field: string lg = 2;
   */
  lg: string;
  /**
   * @generated from protobuf field: string md = 3;
   */
  md: string;
  /**
   * @generated from protobuf field: string sm = 4;
   */
  sm: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class MenuRestaurantGroupResource$Type extends MessageType<MenuRestaurantGroupResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 3,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 5, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 8,
          name: "restaurants",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => MenuRestaurantGroupResource_RestaurantResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<MenuRestaurantGroupResource>,
  ): MenuRestaurantGroupResource {
    const message = { id: "", name: "", description: "", restaurants: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<MenuRestaurantGroupResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MenuRestaurantGroupResource,
  ): MenuRestaurantGroupResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* string description */ 3:
          message.description = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 4:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 5:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* repeated auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource restaurants */ 8:
          message.restaurants.push(
            MenuRestaurantGroupResource_RestaurantResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: MenuRestaurantGroupResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* string description = 3; */
    if (message.description !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.description);
    /* google.protobuf.Timestamp created_at = 4; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 5; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource restaurants = 8; */
    for (let i = 0; i < message.restaurants.length; i++)
      MenuRestaurantGroupResource_RestaurantResource.internalBinaryWrite(
        message.restaurants[i],
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource
 */
export const MenuRestaurantGroupResource =
  new MenuRestaurantGroupResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuRestaurantGroupResource_RestaurantResource$Type extends MessageType<MenuRestaurantGroupResource_RestaurantResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource",
      [
        {
          no: 1,
          name: "id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 3,
          name: "logo",
          kind: "message",
          T: () => MenuRestaurantGroupResource_RestaurantResource_Logo,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<MenuRestaurantGroupResource_RestaurantResource>,
  ): MenuRestaurantGroupResource_RestaurantResource {
    const message = { id: 0, name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<MenuRestaurantGroupResource_RestaurantResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MenuRestaurantGroupResource_RestaurantResource,
  ): MenuRestaurantGroupResource_RestaurantResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource.Logo logo */ 3:
          message.logo =
            MenuRestaurantGroupResource_RestaurantResource_Logo.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.logo,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: MenuRestaurantGroupResource_RestaurantResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource.Logo logo = 3; */
    if (message.logo)
      MenuRestaurantGroupResource_RestaurantResource_Logo.internalBinaryWrite(
        message.logo,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource
 */
export const MenuRestaurantGroupResource_RestaurantResource =
  new MenuRestaurantGroupResource_RestaurantResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuRestaurantGroupResource_RestaurantResource_Logo$Type extends MessageType<MenuRestaurantGroupResource_RestaurantResource_Logo> {
  constructor() {
    super(
      "auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource.Logo",
      [
        { no: 1, name: "xl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "lg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "md", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 4, name: "sm", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<MenuRestaurantGroupResource_RestaurantResource_Logo>,
  ): MenuRestaurantGroupResource_RestaurantResource_Logo {
    const message = { xl: "", lg: "", md: "", sm: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<MenuRestaurantGroupResource_RestaurantResource_Logo>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MenuRestaurantGroupResource_RestaurantResource_Logo,
  ): MenuRestaurantGroupResource_RestaurantResource_Logo {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string xl */ 1:
          message.xl = reader.string();
          break;
        case /* string lg */ 2:
          message.lg = reader.string();
          break;
        case /* string md */ 3:
          message.md = reader.string();
          break;
        case /* string sm */ 4:
          message.sm = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: MenuRestaurantGroupResource_RestaurantResource_Logo,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string xl = 1; */
    if (message.xl !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.xl);
    /* string lg = 2; */
    if (message.lg !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.lg);
    /* string md = 3; */
    if (message.md !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.md);
    /* string sm = 4; */
    if (message.sm !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.sm);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource.RestaurantResource.Logo
 */
export const MenuRestaurantGroupResource_RestaurantResource_Logo =
  new MenuRestaurantGroupResource_RestaurantResource_Logo$Type();
