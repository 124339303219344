import React, { ReactNode } from 'react'
import { range } from 'lodash'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Grid } from '@hello-ai/ar_shared/src/components/Grid'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import SelectDateTimeInput from '../../Shared/SelectDateTimeInput'
import { ScrollView, View, Platform } from 'react-native'
import { useTableSeatsBulk } from '../../../models/TableSeat'
import { displayToastSuccess } from '../../Shared/Toast'
import {
  createRestaurantReservation,
  RestaurantReservationParams,
  updateRestaurantReservation,
  useRestaurantReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import { toSeconds } from '../../../modules/time'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useNavigate } from '../../../modules/navigation/useNavigate'

const MIN_MANUAL_INPUT_PARTY_SIZE = 10

function PartySizeButton({
  onPress,
  text,
  selected,
}: {
  onPress: () => void
  text: string
  selected: boolean
}) {
  return (
    <TouchableOpacity
      onPressMinInterval={0}
      onPress={onPress}
      style={{
        flex: 1,
        height: 48,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 24,
        borderWidth: 0.5,
        borderColor: Colors.primary,
        backgroundColor: selected ? Colors.primary : 'white',
        paddingVertical: 12,
      }}
    >
      <Text
        style={{
          color: selected ? 'white' : Colors.primary,
          fontSize: 16,
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}

function PartySizeButtonList({ children }: { children: ReactNode }) {
  const { width, sm } = useResponsive()

  return (
    <Grid numOfColumns={width < sm ? 3 : 5} horizontalGap={8} verticalGap={12}>
      {children}
    </Grid>
  )
}

export function ReservationWalkinsForm({
  restaurantId,
  restaurantReservationId,
  date,
  startTime,
  endTime,
  selectedSeatIds,
}: {
  restaurantId: number
  restaurantReservationId?: string
  date?: dayjs.Dayjs
  startTime?: number
  endTime?: number
  selectedSeatIds?: string[]
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const token = useToken()

  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  const [selectedPartySize, setSelectedPartySize] = React.useState(2)
  const [inputtedPartySize, setInputtedPartySize] = React.useState('10')

  const [startDate] = useFormState<dayjs.Dayjs>(
    restaurantReservation === undefined
      ? date ?? dayjs()
      : dayjs(restaurantReservation.start_at)
  )
  const [endDate] = useFormState<dayjs.Dayjs>(
    restaurantReservation === undefined
      ? date ?? dayjs()
      : dayjs(restaurantReservation.end_at)
  )

  const [_startTime, setStartTime] = useFormState<number>(startTime ?? 0)

  const [_endTime, setEndTime] = useFormState<number>(endTime ?? 0)

  const _selectedSeatIds =
    selectedSeatIds ??
    restaurantReservation?.table_seats.map((seat) => seat.id) ??
    []

  const { tableSeats } = useTableSeatsBulk(restaurantId, {
    ids: _selectedSeatIds,
  })

  const { mutate } = useRestaurantReservations(
    restaurantId,
    {
      date: startDate?.format('YYYY-MM-DD'),
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
    }
  )

  async function onPressSave() {
    const params: RestaurantReservationParams = {
      start_at: startDate
        .startOf('day')
        .add(_startTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      end_at: endDate
        .startOf('day')
        .add(_endTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      adult_party_size: selectedPartySize,
      table_seat_ids: tableSeats?.map((tableSeat) => tableSeat.id),
      kind: 'walkin',
      visit_status: 'all_visited',
    }

    const { error } = await createRestaurantReservation(
      token,
      restaurantId,
      params
    )
    if (error != null) return

    await mutate()
    displayToastSuccess(
      t('ウォークインを追加しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?m=chart&date=${startDate.format(
          'YYYY-MM-DD'
        )}`
      )
    } else {
      navigation.goBack()
    }
  }

  async function onPressUpdate() {
    if (restaurantReservationId == null) return
    const params: RestaurantReservationParams = {
      start_at: startDate
        .startOf('day')
        .add(_startTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      end_at: endDate
        .startOf('day')
        .add(_endTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      adult_party_size: selectedPartySize,
      table_seat_ids: tableSeats?.map((tableSeat) => tableSeat.id),
    }

    const { error } = await updateRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      params
    )
    if (error != null) return

    await mutate()
    displayToastSuccess(t('ウォークインを編集しました'))
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/walkins/${restaurantReservationId}`
      )
    } else {
      navigation.goBack()
    }
  }

  React.useEffect(() => {
    if (restaurantReservation) {
      setSelectedPartySize(restaurantReservation.party_size)
      const startAt = dayjs(restaurantReservation.start_at)
      const endAt = dayjs(restaurantReservation.end_at)
      const startTimeInSeconds = toSeconds(startAt.hour(), startAt.minute())
      const endTimeInSeconds = toSeconds(endAt.hour(), endAt.minute())
      setStartTime(startTimeInSeconds)
      setEndTime(endTimeInSeconds)
    }
  }, [restaurantReservation, setEndTime, setStartTime])

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: 'white',
        paddingHorizontal: 48,
        paddingVertical: 40,
      }}
    >
      <Text
        style={{
          marginTop: 24,
          fontSize: 18,
          fontWeight: '600',
        }}
      >
        {t('人数を選択')}
      </Text>
      <View
        style={{
          marginTop: 16,
        }}
      >
        <PartySizeButtonList>
          {range(1, MIN_MANUAL_INPUT_PARTY_SIZE).map((partySize, index) => {
            return (
              <PartySizeButton
                key={index.toString()}
                onPress={() => {
                  setSelectedPartySize(partySize)
                }}
                text={
                  partySize === MIN_MANUAL_INPUT_PARTY_SIZE
                    ? t('{{text}}人〜', { text: partySize })
                    : t('{{text}}人', { text: partySize })
                }
                selected={partySize === selectedPartySize}
              />
            )
          })}
          <PartySizeButton
            onPress={() => {
              // default party size when pressing button
              // text input for when you need more than the max for buttons
              setSelectedPartySize(MIN_MANUAL_INPUT_PARTY_SIZE)
              setInputtedPartySize(MIN_MANUAL_INPUT_PARTY_SIZE.toString())
            }}
            text={t('{{text}}人〜', { text: MIN_MANUAL_INPUT_PARTY_SIZE })}
            selected={selectedPartySize >= MIN_MANUAL_INPUT_PARTY_SIZE}
          />
        </PartySizeButtonList>
        {selectedPartySize >= MIN_MANUAL_INPUT_PARTY_SIZE && (
          <View
            style={{
              marginTop: 24,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              {t('人数を入力してください')}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextInput
                placeholder="10"
                value={String(inputtedPartySize)}
                onChangeText={setInputtedPartySize}
                onBlur={() => {
                  const validatedPartySize: number | null =
                    parseInt(inputtedPartySize) || null // we'd rather cast NaN to null for sanity's sake

                  // only update selectedPartySize if the inputted value is in
                  // valid range (1~Number.MAX_VALUE)
                  if (validatedPartySize != null) {
                    setSelectedPartySize(validatedPartySize)
                  }
                }}
                autoCapitalize="none"
                keyboardType="number-pad"
                style={{
                  width: 200,
                  height: 64,
                }}
              />
              <Text
                style={{
                  marginLeft: 20,
                  fontSize: 16,
                  fontWeight: '600',
                }}
              >
                人
              </Text>
            </View>
          </View>
        )}
      </View>
      <FormGroup
        style={{ marginTop: 24 }}
        formLabel={<FormLabel value={t('時間')} />}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <SelectDateTimeInput
            style={{ flex: 1 }}
            mode="time"
            dateTime={startDate
              .hour(0)
              .minute(0)
              .second(_startTime)
              .millisecond(0)}
            onChangeDateTime={(dateTime) => {
              const time =
                dateTime.hour() * 3600 +
                dateTime.minute() * 60 +
                dateTime.second()
              setStartTime(time)
            }}
          />
          <Text
            style={{
              marginHorizontal: 8,
            }}
          >
            〜
          </Text>
          <SelectDateTimeInput
            style={{
              flex: 1,
            }}
            mode="time"
            dateTime={startDate
              .hour(0)
              .minute(0)
              .second(_endTime)
              .millisecond(0)}
            onChangeDateTime={(dateTime) => {
              const time =
                dateTime.hour() * 3600 +
                dateTime.minute() * 60 +
                dateTime.second()
              setEndTime(time)
            }}
          />
        </View>
      </FormGroup>
      <FormGroup
        style={{
          marginTop: 24,
        }}
        formLabel={<FormLabel value={t('席')} />}
      >
        <Text
          style={{
            fontSize: 18,
          }}
        >
          {tableSeats?.map((tableSeat) => tableSeat.name).join(', ') ?? ''}
        </Text>
      </FormGroup>
      <Button
        onPress={restaurantReservationId == null ? onPressSave : onPressUpdate}
        style={{
          marginTop: 48,
          height: 48,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        {t('保存する')}
      </Button>
    </ScrollView>
  )
}
