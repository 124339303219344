// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/business_user/business_user_resource.proto" (package "auto_reserve.restaurants.business_user", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BusinessUserRoleResource } from "../business_user_role/business_user_role_resource";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.BusinessUserResource
 */
export interface BusinessUserResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource.Status status = 4;
   */
  status: BusinessUserResource_Status;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource.Role role = 5;
   */
  role: BusinessUserResource_Role;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user_role.BusinessUserRoleResource business_user_role = 8;
   */
  businessUserRole?: BusinessUserRoleResource;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource permitted_restaurants = 9;
   */
  permittedRestaurants: BusinessUserResource_RestaurantResource[];
  /**
   * @generated from protobuf field: bool reservation_email_notifications_enabled = 10;
   */
  reservationEmailNotificationsEnabled: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource
 */
export interface BusinessUserResource_RestaurantResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource.Logo logo = 3;
   */
  logo?: BusinessUserResource_RestaurantResource_Logo;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource.Logo
 */
export interface BusinessUserResource_RestaurantResource_Logo {
  /**
   * @generated from protobuf field: string xl = 1;
   */
  xl: string;
  /**
   * @generated from protobuf field: string lg = 2;
   */
  lg: string;
  /**
   * @generated from protobuf field: string md = 3;
   */
  md: string;
  /**
   * @generated from protobuf field: string sm = 4;
   */
  sm: string;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.business_user.BusinessUserResource.Status
 */
export enum BusinessUserResource_Status {
  /**
   * @generated from protobuf enum value: INVITED = 0;
   */
  INVITED = 0,
  /**
   * @generated from protobuf enum value: REGISTERED = 1;
   */
  REGISTERED = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.business_user.BusinessUserResource.Role
 */
export enum BusinessUserResource_Role {
  /**
   * @generated from protobuf enum value: ADMIN = 0;
   */
  ADMIN = 0,
  /**
   * @generated from protobuf enum value: NORMAL = 1;
   */
  NORMAL = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class BusinessUserResource$Type extends MessageType<BusinessUserResource> {
  constructor() {
    super("auto_reserve.restaurants.business_user.BusinessUserResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.business_user.BusinessUserResource.Status",
          BusinessUserResource_Status,
        ],
      },
      {
        no: 5,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.business_user.BusinessUserResource.Role",
          BusinessUserResource_Role,
        ],
      },
      { no: 6, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 7, name: "updated_at", kind: "message", T: () => Timestamp },
      {
        no: 8,
        name: "business_user_role",
        kind: "message",
        T: () => BusinessUserRoleResource,
      },
      {
        no: 9,
        name: "permitted_restaurants",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BusinessUserResource_RestaurantResource,
      },
      {
        no: 10,
        name: "reservation_email_notifications_enabled",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
  create(value?: PartialMessage<BusinessUserResource>): BusinessUserResource {
    const message = {
      id: "",
      email: "",
      name: "",
      status: 0,
      role: 0,
      permittedRestaurants: [],
      reservationEmailNotificationsEnabled: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessUserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessUserResource,
  ): BusinessUserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string email */ 2:
          message.email = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource.Status status */ 4:
          message.status = reader.int32();
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource.Role role */ 5:
          message.role = reader.int32();
          break;
        case /* google.protobuf.Timestamp created_at */ 6:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 7:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* auto_reserve.restaurants.business_user_role.BusinessUserRoleResource business_user_role */ 8:
          message.businessUserRole =
            BusinessUserRoleResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.businessUserRole,
            );
          break;
        case /* repeated auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource permitted_restaurants */ 9:
          message.permittedRestaurants.push(
            BusinessUserResource_RestaurantResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* bool reservation_email_notifications_enabled */ 10:
          message.reservationEmailNotificationsEnabled = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessUserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string email = 2; */
    if (message.email !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.email);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.business_user.BusinessUserResource.Status status = 4; */
    if (message.status !== 0)
      writer.tag(4, WireType.Varint).int32(message.status);
    /* auto_reserve.restaurants.business_user.BusinessUserResource.Role role = 5; */
    if (message.role !== 0) writer.tag(5, WireType.Varint).int32(message.role);
    /* google.protobuf.Timestamp created_at = 6; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 7; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.business_user_role.BusinessUserRoleResource business_user_role = 8; */
    if (message.businessUserRole)
      BusinessUserRoleResource.internalBinaryWrite(
        message.businessUserRole,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource permitted_restaurants = 9; */
    for (let i = 0; i < message.permittedRestaurants.length; i++)
      BusinessUserResource_RestaurantResource.internalBinaryWrite(
        message.permittedRestaurants[i],
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool reservation_email_notifications_enabled = 10; */
    if (message.reservationEmailNotificationsEnabled !== false)
      writer
        .tag(10, WireType.Varint)
        .bool(message.reservationEmailNotificationsEnabled);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.BusinessUserResource
 */
export const BusinessUserResource = new BusinessUserResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessUserResource_RestaurantResource$Type extends MessageType<BusinessUserResource_RestaurantResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource",
      [
        {
          no: 1,
          name: "id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 3,
          name: "logo",
          kind: "message",
          T: () => BusinessUserResource_RestaurantResource_Logo,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<BusinessUserResource_RestaurantResource>,
  ): BusinessUserResource_RestaurantResource {
    const message = { id: 0, name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessUserResource_RestaurantResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessUserResource_RestaurantResource,
  ): BusinessUserResource_RestaurantResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource.Logo logo */ 3:
          message.logo =
            BusinessUserResource_RestaurantResource_Logo.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.logo,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessUserResource_RestaurantResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource.Logo logo = 3; */
    if (message.logo)
      BusinessUserResource_RestaurantResource_Logo.internalBinaryWrite(
        message.logo,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource
 */
export const BusinessUserResource_RestaurantResource =
  new BusinessUserResource_RestaurantResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessUserResource_RestaurantResource_Logo$Type extends MessageType<BusinessUserResource_RestaurantResource_Logo> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource.Logo",
      [
        { no: 1, name: "xl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "lg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "md", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 4, name: "sm", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<BusinessUserResource_RestaurantResource_Logo>,
  ): BusinessUserResource_RestaurantResource_Logo {
    const message = { xl: "", lg: "", md: "", sm: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessUserResource_RestaurantResource_Logo>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessUserResource_RestaurantResource_Logo,
  ): BusinessUserResource_RestaurantResource_Logo {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string xl */ 1:
          message.xl = reader.string();
          break;
        case /* string lg */ 2:
          message.lg = reader.string();
          break;
        case /* string md */ 3:
          message.md = reader.string();
          break;
        case /* string sm */ 4:
          message.sm = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessUserResource_RestaurantResource_Logo,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string xl = 1; */
    if (message.xl !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.xl);
    /* string lg = 2; */
    if (message.lg !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.lg);
    /* string md = 3; */
    if (message.md !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.md);
    /* string sm = 4; */
    if (message.sm !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.sm);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.BusinessUserResource.RestaurantResource.Logo
 */
export const BusinessUserResource_RestaurantResource_Logo =
  new BusinessUserResource_RestaurantResource_Logo$Type();
