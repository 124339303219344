// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_business_time/restaurant_business_time_service.proto" (package "auto_reserve.restaurants.restaurant_business_time", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarRequest
 */
export interface GetCalendarRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string gte = 2;
   */
  gte: string;
  /**
   * @generated from protobuf field: string lte = 3;
   */
  lte: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse
 */
export interface GetCalendarResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.DateResource dates = 1;
   */
  dates: GetCalendarResponse_DateResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.DateResource
 */
export interface GetCalendarResponse_DateResource {
  /**
   * @generated from protobuf field: string date = 1;
   */
  date: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.BusinessTimeResource business_times = 2;
   */
  businessTimes: GetCalendarResponse_BusinessTimeResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.BusinessTimeResource
 */
export interface GetCalendarResponse_BusinessTimeResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_at = 3;
   */
  startAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_at = 4;
   */
  endAt?: Timestamp;
  /**
   * @generated from protobuf field: bool open = 5;
   */
  open: boolean;
  /**
   * @generated from protobuf field: bool irregular_close = 6;
   */
  irregularClose: boolean;
  /**
   * @generated from protobuf field: bool irregular_open = 7;
   */
  irregularOpen: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetCalendarRequest$Type extends MessageType<GetCalendarRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_business_time.GetCalendarRequest",
      [
        {
          no: 1,
          name: "restaurant_id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 2, name: "gte", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "lte", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(value?: PartialMessage<GetCalendarRequest>): GetCalendarRequest {
    const message = { restaurantId: 0, gte: "", lte: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetCalendarRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetCalendarRequest,
  ): GetCalendarRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string gte */ 2:
          message.gte = reader.string();
          break;
        case /* string lte */ 3:
          message.lte = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetCalendarRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string gte = 2; */
    if (message.gte !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.gte);
    /* string lte = 3; */
    if (message.lte !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.lte);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarRequest
 */
export const GetCalendarRequest = new GetCalendarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCalendarResponse$Type extends MessageType<GetCalendarResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse",
      [
        {
          no: 1,
          name: "dates",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => GetCalendarResponse_DateResource,
        },
      ],
    );
  }
  create(value?: PartialMessage<GetCalendarResponse>): GetCalendarResponse {
    const message = { dates: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetCalendarResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetCalendarResponse,
  ): GetCalendarResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.DateResource dates */ 1:
          message.dates.push(
            GetCalendarResponse_DateResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetCalendarResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.DateResource dates = 1; */
    for (let i = 0; i < message.dates.length; i++)
      GetCalendarResponse_DateResource.internalBinaryWrite(
        message.dates[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse
 */
export const GetCalendarResponse = new GetCalendarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCalendarResponse_DateResource$Type extends MessageType<GetCalendarResponse_DateResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.DateResource",
      [
        { no: 1, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "business_times",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => GetCalendarResponse_BusinessTimeResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<GetCalendarResponse_DateResource>,
  ): GetCalendarResponse_DateResource {
    const message = { date: "", businessTimes: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetCalendarResponse_DateResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetCalendarResponse_DateResource,
  ): GetCalendarResponse_DateResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string date */ 1:
          message.date = reader.string();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.BusinessTimeResource business_times */ 2:
          message.businessTimes.push(
            GetCalendarResponse_BusinessTimeResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetCalendarResponse_DateResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string date = 1; */
    if (message.date !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.date);
    /* repeated auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.BusinessTimeResource business_times = 2; */
    for (let i = 0; i < message.businessTimes.length; i++)
      GetCalendarResponse_BusinessTimeResource.internalBinaryWrite(
        message.businessTimes[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.DateResource
 */
export const GetCalendarResponse_DateResource =
  new GetCalendarResponse_DateResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCalendarResponse_BusinessTimeResource$Type extends MessageType<GetCalendarResponse_BusinessTimeResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.BusinessTimeResource",
      [
        {
          no: 1,
          name: "id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "start_at", kind: "message", T: () => Timestamp },
        { no: 4, name: "end_at", kind: "message", T: () => Timestamp },
        { no: 5, name: "open", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
        {
          no: 6,
          name: "irregular_close",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 7,
          name: "irregular_open",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<GetCalendarResponse_BusinessTimeResource>,
  ): GetCalendarResponse_BusinessTimeResource {
    const message = {
      id: 0,
      name: "",
      open: false,
      irregularClose: false,
      irregularOpen: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetCalendarResponse_BusinessTimeResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetCalendarResponse_BusinessTimeResource,
  ): GetCalendarResponse_BusinessTimeResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* google.protobuf.Timestamp start_at */ 3:
          message.startAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.startAt,
          );
          break;
        case /* google.protobuf.Timestamp end_at */ 4:
          message.endAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.endAt,
          );
          break;
        case /* bool open */ 5:
          message.open = reader.bool();
          break;
        case /* bool irregular_close */ 6:
          message.irregularClose = reader.bool();
          break;
        case /* bool irregular_open */ 7:
          message.irregularOpen = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetCalendarResponse_BusinessTimeResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* google.protobuf.Timestamp start_at = 3; */
    if (message.startAt)
      Timestamp.internalBinaryWrite(
        message.startAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp end_at = 4; */
    if (message.endAt)
      Timestamp.internalBinaryWrite(
        message.endAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool open = 5; */
    if (message.open !== false)
      writer.tag(5, WireType.Varint).bool(message.open);
    /* bool irregular_close = 6; */
    if (message.irregularClose !== false)
      writer.tag(6, WireType.Varint).bool(message.irregularClose);
    /* bool irregular_open = 7; */
    if (message.irregularOpen !== false)
      writer.tag(7, WireType.Varint).bool(message.irregularOpen);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse.BusinessTimeResource
 */
export const GetCalendarResponse_BusinessTimeResource =
  new GetCalendarResponse_BusinessTimeResource$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.restaurant_business_time.RestaurantBusinessTime
 */
export const RestaurantBusinessTime = new ServiceType(
  "auto_reserve.restaurants.restaurant_business_time.RestaurantBusinessTime",
  [
    {
      name: "GetCalendar",
      options: {},
      I: GetCalendarRequest,
      O: GetCalendarResponse,
    },
  ],
);
