// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_resource.proto" (package "auto_reserve.restaurants.table_seat_charge_policy", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Int32Value } from "../../../google/protobuf/wrappers";
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource
 */
export interface TableSeatChargePolicyResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value charge_interval = 2;
   */
  chargeInterval?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit = 3;
   */
  chargeUnit: TableSeatChargePolicyResource_ChargeUnit;
  /**
   * @generated from protobuf field: float price = 4;
   */
  price: number;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.Status status = 5;
   */
  status: TableSeatChargePolicyResource_Status;
  /**
   * @generated from protobuf field: bool tax_included = 6;
   */
  taxIncluded: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type = 7;
   */
  taxType: TableSeatChargePolicyResource_TaxType;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 8;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 9;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource table_seats = 10;
   */
  tableSeats: TableSeatChargePolicyResource_TableSeatResource[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableMenuResource table_menus = 11;
   */
  tableMenus: TableSeatChargePolicyResource_TableMenuResource[];
  /**
   * @generated from protobuf field: string description = 12;
   */
  description: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource
 */
export interface TableSeatChargePolicyResource_TableSeatResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource.Status status = 3;
   */
  status: TableSeatChargePolicyResource_TableSeatResource_Status;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource.Status
 */
export enum TableSeatChargePolicyResource_TableSeatResource_Status {
  /**
   * @generated from protobuf enum value: PUBLISHED = 0;
   */
  PUBLISHED = 0,
  /**
   * @generated from protobuf enum value: ARCHIVED = 1;
   */
  ARCHIVED = 1,
  /**
   * @generated from protobuf enum value: DELETED = 2;
   */
  DELETED = 2,
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableMenuResource
 */
export interface TableSeatChargePolicyResource_TableMenuResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit
 */
export enum TableSeatChargePolicyResource_ChargeUnit {
  /**
   * @generated from protobuf enum value: TABLE = 0;
   */
  TABLE = 0,
  /**
   * @generated from protobuf enum value: PERSON = 1;
   */
  PERSON = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.Status
 */
export enum TableSeatChargePolicyResource_Status {
  /**
   * @generated from protobuf enum value: ENABLED = 0;
   */
  ENABLED = 0,
  /**
   * @generated from protobuf enum value: ARCHIVED = 1;
   */
  ARCHIVED = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType
 */
export enum TableSeatChargePolicyResource_TaxType {
  /**
   * @generated from protobuf enum value: DEFAULT = 0;
   */
  DEFAULT = 0,
  /**
   * @generated from protobuf enum value: REDUCED = 1;
   */
  REDUCED = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatChargePolicyResource$Type extends MessageType<TableSeatChargePolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "charge_interval",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 3,
          name: "charge_unit",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit",
            TableSeatChargePolicyResource_ChargeUnit,
          ],
        },
        { no: 4, name: "price", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
        {
          no: 5,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.Status",
            TableSeatChargePolicyResource_Status,
          ],
        },
        {
          no: 6,
          name: "tax_included",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 7,
          name: "tax_type",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType",
            TableSeatChargePolicyResource_TaxType,
          ],
        },
        { no: 8, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 9, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 10,
          name: "table_seats",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TableSeatChargePolicyResource_TableSeatResource,
        },
        {
          no: 11,
          name: "table_menus",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TableSeatChargePolicyResource_TableMenuResource,
        },
        {
          no: 12,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatChargePolicyResource>,
  ): TableSeatChargePolicyResource {
    const message = {
      id: "",
      chargeUnit: 0,
      price: 0,
      status: 0,
      taxIncluded: false,
      taxType: 0,
      tableSeats: [],
      tableMenus: [],
      description: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatChargePolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatChargePolicyResource,
  ): TableSeatChargePolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* google.protobuf.Int32Value charge_interval */ 2:
          message.chargeInterval = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.chargeInterval,
          );
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit */ 3:
          message.chargeUnit = reader.int32();
          break;
        case /* float price */ 4:
          message.price = reader.float();
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.Status status */ 5:
          message.status = reader.int32();
          break;
        case /* bool tax_included */ 6:
          message.taxIncluded = reader.bool();
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type */ 7:
          message.taxType = reader.int32();
          break;
        case /* google.protobuf.Timestamp created_at */ 8:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 9:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource table_seats */ 10:
          message.tableSeats.push(
            TableSeatChargePolicyResource_TableSeatResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableMenuResource table_menus */ 11:
          message.tableMenus.push(
            TableSeatChargePolicyResource_TableMenuResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string description */ 12:
          message.description = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatChargePolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* google.protobuf.Int32Value charge_interval = 2; */
    if (message.chargeInterval)
      Int32Value.internalBinaryWrite(
        message.chargeInterval,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit = 3; */
    if (message.chargeUnit !== 0)
      writer.tag(3, WireType.Varint).int32(message.chargeUnit);
    /* float price = 4; */
    if (message.price !== 0) writer.tag(4, WireType.Bit32).float(message.price);
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.Status status = 5; */
    if (message.status !== 0)
      writer.tag(5, WireType.Varint).int32(message.status);
    /* bool tax_included = 6; */
    if (message.taxIncluded !== false)
      writer.tag(6, WireType.Varint).bool(message.taxIncluded);
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type = 7; */
    if (message.taxType !== 0)
      writer.tag(7, WireType.Varint).int32(message.taxType);
    /* google.protobuf.Timestamp created_at = 8; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 9; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource table_seats = 10; */
    for (let i = 0; i < message.tableSeats.length; i++)
      TableSeatChargePolicyResource_TableSeatResource.internalBinaryWrite(
        message.tableSeats[i],
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableMenuResource table_menus = 11; */
    for (let i = 0; i < message.tableMenus.length; i++)
      TableSeatChargePolicyResource_TableMenuResource.internalBinaryWrite(
        message.tableMenus[i],
        writer.tag(11, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description = 12; */
    if (message.description !== "")
      writer.tag(12, WireType.LengthDelimited).string(message.description);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource
 */
export const TableSeatChargePolicyResource =
  new TableSeatChargePolicyResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatChargePolicyResource_TableSeatResource$Type extends MessageType<TableSeatChargePolicyResource_TableSeatResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 3,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource.Status",
            TableSeatChargePolicyResource_TableSeatResource_Status,
          ],
        },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatChargePolicyResource_TableSeatResource>,
  ): TableSeatChargePolicyResource_TableSeatResource {
    const message = { id: "", name: "", status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatChargePolicyResource_TableSeatResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatChargePolicyResource_TableSeatResource,
  ): TableSeatChargePolicyResource_TableSeatResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource.Status status */ 3:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatChargePolicyResource_TableSeatResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource.Status status = 3; */
    if (message.status !== 0)
      writer.tag(3, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableSeatResource
 */
export const TableSeatChargePolicyResource_TableSeatResource =
  new TableSeatChargePolicyResource_TableSeatResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatChargePolicyResource_TableMenuResource$Type extends MessageType<TableSeatChargePolicyResource_TableMenuResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableMenuResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatChargePolicyResource_TableMenuResource>,
  ): TableSeatChargePolicyResource_TableMenuResource {
    const message = { id: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatChargePolicyResource_TableMenuResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatChargePolicyResource_TableMenuResource,
  ): TableSeatChargePolicyResource_TableMenuResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatChargePolicyResource_TableMenuResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TableMenuResource
 */
export const TableSeatChargePolicyResource_TableMenuResource =
  new TableSeatChargePolicyResource_TableMenuResource$Type();
