import React, { useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useRestaurant } from 'models/Restaurant'
import { useRestaurantCurrency } from '@hello-ai/for_r_app/src/modules/useRestaurantCurrency'

import {
  useRestaurantTableProducts,
  updateTableProduct,
} from 'models/TableProduct'
import { useToken } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'

import { history } from 'modules/history'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import TableProductListItem from '@hello-ai/for_r_app/src/components/Restaurant/TableProduct/TableProductListItem'
import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'

export default function TableProductsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const token = useToken()
  const [productsCurrentPage, setProductsCurrentPage] = useState(1)
  const { restaurant } = useRestaurant(restaurantId)
  const restaurantCurrency = useRestaurantCurrency(restaurant)
  const alertRef = useRef<AlertMethods>(null)

  const { tableProducts, headerData, isLoading, mutate } =
    useRestaurantTableProducts({
      restaurantId,
      params: {
        page: productsCurrentPage,
        status: 'archived',
      },
    })

  return (
    <>
      <ScrollView
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
      >
        <View style={{ marginTop: 24 }}>
          <ShadowBox
            style={[
              {
                paddingHorizontal: 0,
                paddingVertical: 0,
                marginTop: 0,
              },
            ]}
          >
            {isLoading && (
              <View style={{ padding: 24 }}>
                <Loading />
              </View>
            )}
            {!isLoading &&
              tableProducts != null &&
              tableProducts.length > 0 &&
              tableProducts.map((tableProduct, index) => {
                return (
                  <View
                    key={tableProduct.id}
                    style={{
                      borderTopWidth: index === 0 ? 0 : 0.5,
                      borderColor: Colors.border,
                      paddingTop: 16,
                      marginBottom: 16,
                      paddingHorizontal: 24,
                    }}
                  >
                    <TableProductListItem
                      isArchived
                      restaurantId={restaurantId}
                      restaurantCurrency={restaurantCurrency}
                      alertRef={alertRef}
                      onChangeStatus={async (tableProductId, params) => {
                        if (token == null || headerData == null) {
                          return
                        }
                        await updateTableProduct({
                          token,
                          tableProductId,
                          params,
                        })
                        mutate({
                          tableProducts: tableProducts.filter(
                            (product) => product.id !== tableProductId,
                          ),
                          headerData,
                        })
                      }}
                      tableProduct={tableProduct}
                      onPressListItem={() =>
                        history.push(
                          `/restaurants/${restaurantId}/products/${tableProduct.id}/edit`,
                        )
                      }
                    />
                  </View>
                )
              })}
            {!isLoading &&
              tableProducts != null &&
              tableProducts.length === 0 && (
                <Text
                  style={{
                    paddingTop: 16,
                    marginBottom: 24,
                    paddingHorizontal: 24,
                    fontSize: 22,
                    fontWeight: '600',
                    color: Colors.disabledBlack,
                    textAlign: 'center',
                  }}
                >
                  {t('該当する商品はありません')}
                </Text>
              )}
            <View
              style={{
                borderTopWidth: 0.5,
                borderColor: Colors.border,
                paddingTop: 24,
                marginBottom: 16,
              }}
            >
              <Pagination
                currentPage={productsCurrentPage}
                totalPage={headerData?.totalPages ?? 0}
                setPage={(page) => setProductsCurrentPage(page)}
              />
            </View>
          </ShadowBox>
        </View>
      </ScrollView>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </>
  )
}
