// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/business_user_role/business_user_role_service.proto" (package "auto_reserve.restaurants.business_user_role", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BusinessFeatureResource } from "./business_feature_resource";
import { BusinessUserRolePermissionResource_Level } from "./business_user_role_permission_resource";
import { BusinessFeatureResource_FeatureKey } from "./business_feature_resource";
import { BusinessUserRoleResource } from "./business_user_role_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.ListRequest
 */
export interface ListRequest {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.business_user_role.BusinessUserRoleResource business_user_roles = 1;
   */
  businessUserRoles: BusinessUserRoleResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.BusinessUserRolePermission
 */
export interface BusinessUserRolePermission {
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey feature_key = 1;
   */
  featureKey: BusinessFeatureResource_FeatureKey;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user_role.BusinessUserRolePermissionResource.Level level = 2;
   */
  level: BusinessUserRolePermissionResource_Level;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.business_user_role.BusinessUserRolePermission business_user_role_permissions = 2;
   */
  businessUserRolePermissions: BusinessUserRolePermission[];
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.business_user_role.BusinessUserRolePermission business_user_role_permissions = 3;
   */
  businessUserRolePermissions: BusinessUserRolePermission[];
  /**
   * @generated from protobuf field: string description = 4;
   */
  description: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.ListBusinessFeaturesResponse
 */
export interface ListBusinessFeaturesResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.business_user_role.BusinessFeatureResource business_features = 1;
   */
  businessFeatures: BusinessFeatureResource[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user_role.ListRequest", []);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.restaurants.business_user_role.ListResponse", [
      {
        no: 1,
        name: "business_user_roles",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BusinessUserRoleResource,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { businessUserRoles: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.business_user_role.BusinessUserRoleResource business_user_roles */ 1:
          message.businessUserRoles.push(
            BusinessUserRoleResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.business_user_role.BusinessUserRoleResource business_user_roles = 1; */
    for (let i = 0; i < message.businessUserRoles.length; i++)
      BusinessUserRoleResource.internalBinaryWrite(
        message.businessUserRoles[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user_role.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessUserRolePermission$Type extends MessageType<BusinessUserRolePermission> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user_role.BusinessUserRolePermission",
      [
        {
          no: 1,
          name: "feature_key",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey",
            BusinessFeatureResource_FeatureKey,
          ],
        },
        {
          no: 2,
          name: "level",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.business_user_role.BusinessUserRolePermissionResource.Level",
            BusinessUserRolePermissionResource_Level,
          ],
        },
      ],
    );
  }
  create(
    value?: PartialMessage<BusinessUserRolePermission>,
  ): BusinessUserRolePermission {
    const message = { featureKey: 0, level: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessUserRolePermission>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessUserRolePermission,
  ): BusinessUserRolePermission {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey feature_key */ 1:
          message.featureKey = reader.int32();
          break;
        case /* auto_reserve.restaurants.business_user_role.BusinessUserRolePermissionResource.Level level */ 2:
          message.level = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessUserRolePermission,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey feature_key = 1; */
    if (message.featureKey !== 0)
      writer.tag(1, WireType.Varint).int32(message.featureKey);
    /* auto_reserve.restaurants.business_user_role.BusinessUserRolePermissionResource.Level level = 2; */
    if (message.level !== 0)
      writer.tag(2, WireType.Varint).int32(message.level);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.BusinessUserRolePermission
 */
export const BusinessUserRolePermission = new BusinessUserRolePermission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user_role.CreateRequest", [
      { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "business_user_role_permissions",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BusinessUserRolePermission,
      },
      {
        no: 3,
        name: "description",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      name: "",
      businessUserRolePermissions: [],
      description: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string();
          break;
        case /* repeated auto_reserve.restaurants.business_user_role.BusinessUserRolePermission business_user_role_permissions */ 2:
          message.businessUserRolePermissions.push(
            BusinessUserRolePermission.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string description */ 3:
          message.description = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.name);
    /* repeated auto_reserve.restaurants.business_user_role.BusinessUserRolePermission business_user_role_permissions = 2; */
    for (let i = 0; i < message.businessUserRolePermissions.length; i++)
      BusinessUserRolePermission.internalBinaryWrite(
        message.businessUserRolePermissions[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description = 3; */
    if (message.description !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.description);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user_role.UpdateRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "business_user_role_permissions",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BusinessUserRolePermission,
      },
      {
        no: 4,
        name: "description",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      name: "",
      businessUserRolePermissions: [],
      description: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* repeated auto_reserve.restaurants.business_user_role.BusinessUserRolePermission business_user_role_permissions */ 3:
          message.businessUserRolePermissions.push(
            BusinessUserRolePermission.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string description */ 4:
          message.description = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* repeated auto_reserve.restaurants.business_user_role.BusinessUserRolePermission business_user_role_permissions = 3; */
    for (let i = 0; i < message.businessUserRolePermissions.length; i++)
      BusinessUserRolePermission.internalBinaryWrite(
        message.businessUserRolePermissions[i],
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description = 4; */
    if (message.description !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.description);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user_role.DestroyRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBusinessFeaturesResponse$Type extends MessageType<ListBusinessFeaturesResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user_role.ListBusinessFeaturesResponse",
      [
        {
          no: 1,
          name: "business_features",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => BusinessFeatureResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<ListBusinessFeaturesResponse>,
  ): ListBusinessFeaturesResponse {
    const message = { businessFeatures: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListBusinessFeaturesResponse>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListBusinessFeaturesResponse,
  ): ListBusinessFeaturesResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.business_user_role.BusinessFeatureResource business_features */ 1:
          message.businessFeatures.push(
            BusinessFeatureResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListBusinessFeaturesResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.business_user_role.BusinessFeatureResource business_features = 1; */
    for (let i = 0; i < message.businessFeatures.length; i++)
      BusinessFeatureResource.internalBinaryWrite(
        message.businessFeatures[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.ListBusinessFeaturesResponse
 */
export const ListBusinessFeaturesResponse =
  new ListBusinessFeaturesResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.business_user_role.BusinessUserRole
 */
export const BusinessUserRole = new ServiceType(
  "auto_reserve.restaurants.business_user_role.BusinessUserRole",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: BusinessUserRoleResource },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: BusinessUserRoleResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: BusinessUserRoleResource,
    },
    { name: "Destroy", options: {}, I: DestroyRequest, O: Empty },
    {
      name: "ListBusinessFeatures",
      options: {},
      I: Empty,
      O: ListBusinessFeaturesResponse,
    },
  ],
);
