import React from 'react'
import { CalendarProps } from 'react-native-calendars'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { View, StyleSheet } from 'react-native'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { YearMonthPicker } from '../../Shared/YearMonthPicker'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

const convertMonth = (day: dayjs.Dayjs) => ({
  year: day.year(),
  month: day.month(),
  day: day.day(),
  timestamp: day.unix(),
  dateString: day.format('YYYY-MM-DD'),
})

const changeDate = (
  amount: number,
  unit: 'month' | 'year',
  visibleMonth?: string,
  onVisibleMonthsChange?: (date: any) => void
) => {
  if (visibleMonth == null || onVisibleMonthsChange == null) return
  const newDate = dayjs(visibleMonth).add(amount, unit)
  onVisibleMonthsChange([convertMonth(newDate)])
}

interface ReservationCalendarHeaderProps {
  visibleMonth: string
  onVisibleMonthsChange?: CalendarProps['onVisibleMonthsChange']
}

export function ReservationCalendarHeader({
  visibleMonth,
  onVisibleMonthsChange,
}: ReservationCalendarHeaderProps) {
  const { width, sm } = useResponsive()

  const nextMonth = () =>
    changeDate(1, 'month', visibleMonth, onVisibleMonthsChange)
  const prevMonth = () =>
    changeDate(-1, 'month', visibleMonth, onVisibleMonthsChange)

  return (
    <View style={style.container}>
      <View style={style.middleSection}>
        <TouchableOpacity
          style={style.monthChangingSection}
          onPress={prevMonth}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            size={24}
            color={Colors.primary}
          />
          {width >= sm && <Text style={style.monthChangingText}>前月</Text>}
        </TouchableOpacity>
        <YearMonthPicker
          minYear={dayjs().year() - 10}
          maxYear={dayjs().year() + 1}
          style={[
            style.dateTextSection,
            { marginHorizontal: width >= sm ? 32 : 16 },
          ]}
          labelStyle={[
            style.dateText,
            {
              paddingHorizontal: width < sm ? 20 : 16,
            },
          ]}
          value={dayjs(visibleMonth)}
          onChange={(newDate) => {
            onVisibleMonthsChange &&
              onVisibleMonthsChange([
                {
                  year: newDate.year(),
                  month: newDate.month(),
                  day: newDate.day(),
                  timestamp: newDate.unix(),
                  dateString: newDate.format('YYYY-MM-DD'),
                },
              ])
          }}
          renderLabel={(value) => (
            <View
              style={{
                flexDirection: 'row',
                columnGap: 4,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={[style.dateText]}>
                {value.format(t('YYYY年MMMM'))}
              </Text>
              <FontAwesomeIcon
                icon={faSortDown}
                size={12}
                color={Colors.primary}
                style={{
                  transform: [{ translateY: -3 }],
                }}
              />
            </View>
          )}
        />
        <TouchableOpacity
          style={style.monthChangingSection}
          onPress={nextMonth}
        >
          {width >= sm && <Text style={style.monthChangingText}>翌月</Text>}
          <FontAwesomeIcon
            icon={faAngleRight}
            size={24}
            color={Colors.primary}
          />
        </TouchableOpacity>
        <Button
          mode="outline"
          style={[
            style.todayButton,
            {
              right: width < sm ? -70 : -84,
            },
          ]}
          textStyle={{
            fontSize: 14,
            fontWeight: '300',
            lineHeight: 24,
          }}
          onPress={() => {
            if (onVisibleMonthsChange == null) return
            const today = dayjs()
            onVisibleMonthsChange([convertMonth(today)])
          }}
        >
          {t('今月')}
        </Button>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
    backgroundColor: Colors.white,
  },
  todayButton: {
    position: 'absolute',
    top: 0,
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  middleSection: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateTextSection: {
    borderRadius: 19,
    height: 42,
    backgroundColor: Colors.primaryBg,
    justifyContent: 'center',
  },
  dateText: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.primary,
  },
  monthChangingSection: {
    flexDirection: 'row',
    columnGap: 4,
  },
  monthChangingText: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.primary,
  },
})
