import React from 'react'

import { SWRConfig, defaultConfig as swrDefaultConfig } from 'modules/swr'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import {
  Config as ArSharedConfig,
  ConfigProvider as ArSharedConfigProvider,
} from '@hello-ai/ar_shared/src/components/Config'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { ResponsiveProvider } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  AlertProvider,
  setAlertRef,
} from '@hello-ai/ar_shared/src/components/Alert'
import { HelmetProvider } from 'react-helmet-async'

import { ToastProvider } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { Modal } from './Modal'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

const theme = {
  ...DefaultTheme,
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.primary,
    accent: Colors.accent,
    text: Colors.black,
  },
}

const config: ArSharedConfig = {
  Text: {
    fontSize: 18,
    fontFamily: '"Hiragino Sans", sans-serif',
  },
  TextInput: {
    fontSize: 18,
    height: 64,
  },
  ModalComponent: Modal,
}

export function AppProvider({ children }: { children?: React.ReactNode }) {
  return (
    <SWRConfig value={swrDefaultConfig}>
      <HelmetProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <ArSharedConfigProvider value={config}>
            <ResponsiveProvider>
              <ToastProvider>
                <PaperProvider theme={theme}>
                  <AlertProvider
                    ref={(ref) => {
                      setAlertRef(ref)
                    }}
                  >
                    {children}
                  </AlertProvider>
                </PaperProvider>
              </ToastProvider>
            </ResponsiveProvider>
          </ArSharedConfigProvider>
        </GestureHandlerRootView>
      </HelmetProvider>
    </SWRConfig>
  )
}
