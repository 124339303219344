import React from 'react'
import { View } from 'react-native'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'
import {
  OutboundSettingListItem,
  OutboundSettingListItemType,
} from './OutboundSettingListItem'
import { t } from '../../../../modules/i18n'
import { GourmetSiteProvider } from '../../../../types/ForR/GourmetSiteSetting'

interface OutboundSettingListProps {
  outboundSettings?: OutboundSettingListItemType[]
  onPressItem: (source: GourmetSiteProvider) => void
}

export function OutboundSettingList({
  outboundSettings,
  onPressItem,
}: OutboundSettingListProps) {
  return (
    <View
      style={{
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        borderRadius: 8,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 16,
          alignItems: 'center',
        }}
      >
        <Text style={{ flex: 1, fontWeight: '600', fontSize: 14 }}>
          {t('グルメサイト名')}
        </Text>
        <Text style={{ flex: 1, fontWeight: '600', fontSize: 14 }}>
          {t('連携')}
        </Text>
        <Text style={{ flex: 2, fontWeight: '600', fontSize: 14 }}>
          {t('日時')}
        </Text>
        <Text style={{ width: 96, fontWeight: '600', fontSize: 14 }}>
          {t('設定')}
        </Text>
      </View>
      {outboundSettings?.map((outboundSetting) => {
        return (
          <View
            key={outboundSetting.site}
            style={{ borderTopWidth: 0.5, borderColor: Colors.border }}
          >
            <OutboundSettingListItem
              outboundSetting={outboundSetting}
              onPressSetting={() => onPressItem(outboundSetting.site)}
            />
          </View>
        )
      })}
    </View>
  )
}
