// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/business_user/business_user_service.proto" (package "auto_reserve.restaurants.business_user", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BusinessUserInvitationResource } from "./business_user_invitation_resource";
import { StringValue } from "../../../google/protobuf/wrappers";
import { BusinessUserResource } from "./business_user_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.IndexRequest
 */
export interface IndexRequest {
  /**
   * @generated from protobuf field: int32 page = 1;
   */
  page: number; // (default 1)
  /**
   * @generated from protobuf field: int32 per_page = 2;
   */
  perPage: number; // (default 20)
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.IndexResponse
 */
export interface IndexResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.business_user.BusinessUserResource business_users = 1;
   */
  businessUsers: BusinessUserResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.InviteRequest
 */
export interface InviteRequest {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.InviteRequest.Role role = 3;
   */
  role: InviteRequest_Role;
  /**
   * @generated from protobuf field: google.protobuf.StringValue business_user_role_id = 4;
   */
  businessUserRoleId?: StringValue;
  /**
   * @generated from protobuf field: repeated int64 permitted_restaurant_ids = 5;
   */
  permittedRestaurantIds: number[];
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.business_user.InviteRequest.Role
 */
export enum InviteRequest_Role {
  /**
   * @generated from protobuf enum value: ADMIN = 0;
   */
  ADMIN = 0,
  /**
   * @generated from protobuf enum value: NORMAL = 1;
   */
  NORMAL = 1,
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.InviteResponse
 */
export interface InviteResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.ResendInvitationRequest
 */
export interface ResendInvitationRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.ResendInvitationResponse
 */
export interface ResendInvitationResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.RegisterRequest
 */
export interface RegisterRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string invitation_token = 2;
   */
  invitationToken: string;
  /**
   * @generated from protobuf field: string password = 3;
   */
  password: string;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.RegisterResponse
 */
export interface RegisterResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.GetInvitationRequest
 */
export interface GetInvitationRequest {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.GetInvitationResponse
 */
export interface GetInvitationResponse {
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserInvitationResource invitation = 1;
   */
  invitation?: BusinessUserInvitationResource;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.GetInvitationResponse.OwnerResource owner = 2;
   */
  owner?: GetInvitationResponse_OwnerResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.GetInvitationResponse.OwnerResource
 */
export interface GetInvitationResponse_OwnerResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.GetBusinessUserRequest
 */
export interface GetBusinessUserRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.GetBusinessUserResponse
 */
export interface GetBusinessUserResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.UpdateBusinessUserRequest
 */
export interface UpdateBusinessUserRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.UpdateBusinessUserRequest.Role role = 3;
   */
  role: UpdateBusinessUserRequest_Role;
  /**
   * @generated from protobuf field: google.protobuf.StringValue business_user_role_id = 4;
   */
  businessUserRoleId?: StringValue;
  /**
   * @generated from protobuf field: repeated int64 permitted_restaurant_ids = 5;
   */
  permittedRestaurantIds: number[];
  /**
   * @generated from protobuf field: bool reservation_email_notifications_enabled = 6;
   */
  reservationEmailNotificationsEnabled: boolean;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.business_user.UpdateBusinessUserRequest.Role
 */
export enum UpdateBusinessUserRequest_Role {
  /**
   * @generated from protobuf enum value: ADMIN = 0;
   */
  ADMIN = 0,
  /**
   * @generated from protobuf enum value: NORMAL = 1;
   */
  NORMAL = 1,
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.DestroyBusinessUserRequest
 */
export interface DestroyBusinessUserRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.CreateChangeEmailRequestRequest
 */
export interface CreateChangeEmailRequestRequest {
  /**
   * @generated from protobuf field: string new_email = 1;
   */
  newEmail: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.CreateChangeEmailRequestResponse
 */
export interface CreateChangeEmailRequestResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestRequest
 */
export interface ConfirmChangeEmailRequestRequest {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestResponse
 */
export interface ConfirmChangeEmailRequestResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserRequest
 */
export interface UpdateCurrentBusinessUserRequest {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserResponse
 */
export interface UpdateCurrentBusinessUserResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserPasswordRequest
 */
export interface UpdateCurrentBusinessUserPasswordRequest {
  /**
   * @generated from protobuf field: string password = 1;
   */
  password: string;
  /**
   * @generated from protobuf field: string new_password = 2;
   */
  newPassword: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class IndexRequest$Type extends MessageType<IndexRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.IndexRequest", [
      { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: "per_page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
    ]);
  }
  create(value?: PartialMessage<IndexRequest>): IndexRequest {
    const message = { page: 0, perPage: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<IndexRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: IndexRequest,
  ): IndexRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int32 page */ 1:
          message.page = reader.int32();
          break;
        case /* int32 per_page */ 2:
          message.perPage = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: IndexRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int32(message.page);
    /* int32 per_page = 2; */
    if (message.perPage !== 0)
      writer.tag(2, WireType.Varint).int32(message.perPage);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.IndexRequest
 */
export const IndexRequest = new IndexRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndexResponse$Type extends MessageType<IndexResponse> {
  constructor() {
    super("auto_reserve.restaurants.business_user.IndexResponse", [
      {
        no: 1,
        name: "business_users",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BusinessUserResource,
      },
    ]);
  }
  create(value?: PartialMessage<IndexResponse>): IndexResponse {
    const message = { businessUsers: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<IndexResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: IndexResponse,
  ): IndexResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.business_user.BusinessUserResource business_users */ 1:
          message.businessUsers.push(
            BusinessUserResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: IndexResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.business_user.BusinessUserResource business_users = 1; */
    for (let i = 0; i < message.businessUsers.length; i++)
      BusinessUserResource.internalBinaryWrite(
        message.businessUsers[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.IndexResponse
 */
export const IndexResponse = new IndexResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteRequest$Type extends MessageType<InviteRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.InviteRequest", [
      { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.business_user.InviteRequest.Role",
          InviteRequest_Role,
        ],
      },
      {
        no: 4,
        name: "business_user_role_id",
        kind: "message",
        T: () => StringValue,
      },
      {
        no: 5,
        name: "permitted_restaurant_ids",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<InviteRequest>): InviteRequest {
    const message = {
      email: "",
      name: "",
      role: 0,
      permittedRestaurantIds: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<InviteRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: InviteRequest,
  ): InviteRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user.InviteRequest.Role role */ 3:
          message.role = reader.int32();
          break;
        case /* google.protobuf.StringValue business_user_role_id */ 4:
          message.businessUserRoleId = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.businessUserRoleId,
          );
          break;
        case /* repeated int64 permitted_restaurant_ids */ 5:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.permittedRestaurantIds.push(reader.int64().toNumber());
          else message.permittedRestaurantIds.push(reader.int64().toNumber());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: InviteRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.email);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.business_user.InviteRequest.Role role = 3; */
    if (message.role !== 0) writer.tag(3, WireType.Varint).int32(message.role);
    /* google.protobuf.StringValue business_user_role_id = 4; */
    if (message.businessUserRoleId)
      StringValue.internalBinaryWrite(
        message.businessUserRoleId,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated int64 permitted_restaurant_ids = 5; */
    if (message.permittedRestaurantIds.length) {
      writer.tag(5, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.permittedRestaurantIds.length; i++)
        writer.int64(message.permittedRestaurantIds[i]);
      writer.join();
    }
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.InviteRequest
 */
export const InviteRequest = new InviteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteResponse$Type extends MessageType<InviteResponse> {
  constructor() {
    super("auto_reserve.restaurants.business_user.InviteResponse", []);
  }
  create(value?: PartialMessage<InviteResponse>): InviteResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<InviteResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: InviteResponse,
  ): InviteResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: InviteResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.InviteResponse
 */
export const InviteResponse = new InviteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResendInvitationRequest$Type extends MessageType<ResendInvitationRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.ResendInvitationRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(
    value?: PartialMessage<ResendInvitationRequest>,
  ): ResendInvitationRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ResendInvitationRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ResendInvitationRequest,
  ): ResendInvitationRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ResendInvitationRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.ResendInvitationRequest
 */
export const ResendInvitationRequest = new ResendInvitationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResendInvitationResponse$Type extends MessageType<ResendInvitationResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.ResendInvitationResponse",
      [],
    );
  }
  create(
    value?: PartialMessage<ResendInvitationResponse>,
  ): ResendInvitationResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ResendInvitationResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ResendInvitationResponse,
  ): ResendInvitationResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: ResendInvitationResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.ResendInvitationResponse
 */
export const ResendInvitationResponse = new ResendInvitationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterRequest$Type extends MessageType<RegisterRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.RegisterRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "invitation_token",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<RegisterRequest>): RegisterRequest {
    const message = { id: "", invitationToken: "", password: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RegisterRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RegisterRequest,
  ): RegisterRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string invitation_token */ 2:
          message.invitationToken = reader.string();
          break;
        case /* string password */ 3:
          message.password = reader.string();
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RegisterRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string invitation_token = 2; */
    if (message.invitationToken !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.invitationToken);
    /* string password = 3; */
    if (message.password !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.password);
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.RegisterRequest
 */
export const RegisterRequest = new RegisterRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterResponse$Type extends MessageType<RegisterResponse> {
  constructor() {
    super("auto_reserve.restaurants.business_user.RegisterResponse", []);
  }
  create(value?: PartialMessage<RegisterResponse>): RegisterResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RegisterResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RegisterResponse,
  ): RegisterResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: RegisterResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.RegisterResponse
 */
export const RegisterResponse = new RegisterResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationRequest$Type extends MessageType<GetInvitationRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.GetInvitationRequest", [
      { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetInvitationRequest>): GetInvitationRequest {
    const message = { token: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetInvitationRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetInvitationRequest,
  ): GetInvitationRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string token */ 1:
          message.token = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetInvitationRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string token = 1; */
    if (message.token !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.token);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.GetInvitationRequest
 */
export const GetInvitationRequest = new GetInvitationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationResponse$Type extends MessageType<GetInvitationResponse> {
  constructor() {
    super("auto_reserve.restaurants.business_user.GetInvitationResponse", [
      {
        no: 1,
        name: "invitation",
        kind: "message",
        T: () => BusinessUserInvitationResource,
      },
      {
        no: 2,
        name: "owner",
        kind: "message",
        T: () => GetInvitationResponse_OwnerResource,
      },
    ]);
  }
  create(value?: PartialMessage<GetInvitationResponse>): GetInvitationResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetInvitationResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetInvitationResponse,
  ): GetInvitationResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* auto_reserve.restaurants.business_user.BusinessUserInvitationResource invitation */ 1:
          message.invitation =
            BusinessUserInvitationResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.invitation,
            );
          break;
        case /* auto_reserve.restaurants.business_user.GetInvitationResponse.OwnerResource owner */ 2:
          message.owner =
            GetInvitationResponse_OwnerResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.owner,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetInvitationResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* auto_reserve.restaurants.business_user.BusinessUserInvitationResource invitation = 1; */
    if (message.invitation)
      BusinessUserInvitationResource.internalBinaryWrite(
        message.invitation,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.business_user.GetInvitationResponse.OwnerResource owner = 2; */
    if (message.owner)
      GetInvitationResponse_OwnerResource.internalBinaryWrite(
        message.owner,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.GetInvitationResponse
 */
export const GetInvitationResponse = new GetInvitationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationResponse_OwnerResource$Type extends MessageType<GetInvitationResponse_OwnerResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.GetInvitationResponse.OwnerResource",
      [
        {
          no: 1,
          name: "id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<GetInvitationResponse_OwnerResource>,
  ): GetInvitationResponse_OwnerResource {
    const message = { id: 0, email: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetInvitationResponse_OwnerResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetInvitationResponse_OwnerResource,
  ): GetInvitationResponse_OwnerResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string email */ 2:
          message.email = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetInvitationResponse_OwnerResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string email = 2; */
    if (message.email !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.email);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.GetInvitationResponse.OwnerResource
 */
export const GetInvitationResponse_OwnerResource =
  new GetInvitationResponse_OwnerResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBusinessUserRequest$Type extends MessageType<GetBusinessUserRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.GetBusinessUserRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(
    value?: PartialMessage<GetBusinessUserRequest>,
  ): GetBusinessUserRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetBusinessUserRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetBusinessUserRequest,
  ): GetBusinessUserRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetBusinessUserRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.GetBusinessUserRequest
 */
export const GetBusinessUserRequest = new GetBusinessUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBusinessUserResponse$Type extends MessageType<GetBusinessUserResponse> {
  constructor() {
    super("auto_reserve.restaurants.business_user.GetBusinessUserResponse", []);
  }
  create(
    value?: PartialMessage<GetBusinessUserResponse>,
  ): GetBusinessUserResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetBusinessUserResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetBusinessUserResponse,
  ): GetBusinessUserResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: GetBusinessUserResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.GetBusinessUserResponse
 */
export const GetBusinessUserResponse = new GetBusinessUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBusinessUserRequest$Type extends MessageType<UpdateBusinessUserRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.UpdateBusinessUserRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.business_user.UpdateBusinessUserRequest.Role",
          UpdateBusinessUserRequest_Role,
        ],
      },
      {
        no: 4,
        name: "business_user_role_id",
        kind: "message",
        T: () => StringValue,
      },
      {
        no: 5,
        name: "permitted_restaurant_ids",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 6,
        name: "reservation_email_notifications_enabled",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
  create(
    value?: PartialMessage<UpdateBusinessUserRequest>,
  ): UpdateBusinessUserRequest {
    const message = {
      id: "",
      name: "",
      role: 0,
      permittedRestaurantIds: [],
      reservationEmailNotificationsEnabled: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateBusinessUserRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateBusinessUserRequest,
  ): UpdateBusinessUserRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user.UpdateBusinessUserRequest.Role role */ 3:
          message.role = reader.int32();
          break;
        case /* google.protobuf.StringValue business_user_role_id */ 4:
          message.businessUserRoleId = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.businessUserRoleId,
          );
          break;
        case /* repeated int64 permitted_restaurant_ids */ 5:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.permittedRestaurantIds.push(reader.int64().toNumber());
          else message.permittedRestaurantIds.push(reader.int64().toNumber());
          break;
        case /* bool reservation_email_notifications_enabled */ 6:
          message.reservationEmailNotificationsEnabled = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateBusinessUserRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.business_user.UpdateBusinessUserRequest.Role role = 3; */
    if (message.role !== 0) writer.tag(3, WireType.Varint).int32(message.role);
    /* google.protobuf.StringValue business_user_role_id = 4; */
    if (message.businessUserRoleId)
      StringValue.internalBinaryWrite(
        message.businessUserRoleId,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated int64 permitted_restaurant_ids = 5; */
    if (message.permittedRestaurantIds.length) {
      writer.tag(5, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.permittedRestaurantIds.length; i++)
        writer.int64(message.permittedRestaurantIds[i]);
      writer.join();
    }
    /* bool reservation_email_notifications_enabled = 6; */
    if (message.reservationEmailNotificationsEnabled !== false)
      writer
        .tag(6, WireType.Varint)
        .bool(message.reservationEmailNotificationsEnabled);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.UpdateBusinessUserRequest
 */
export const UpdateBusinessUserRequest = new UpdateBusinessUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyBusinessUserRequest$Type extends MessageType<DestroyBusinessUserRequest> {
  constructor() {
    super("auto_reserve.restaurants.business_user.DestroyBusinessUserRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(
    value?: PartialMessage<DestroyBusinessUserRequest>,
  ): DestroyBusinessUserRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyBusinessUserRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyBusinessUserRequest,
  ): DestroyBusinessUserRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyBusinessUserRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.DestroyBusinessUserRequest
 */
export const DestroyBusinessUserRequest = new DestroyBusinessUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateChangeEmailRequestRequest$Type extends MessageType<CreateChangeEmailRequestRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.CreateChangeEmailRequestRequest",
      [
        {
          no: 1,
          name: "new_email",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateChangeEmailRequestRequest>,
  ): CreateChangeEmailRequestRequest {
    const message = { newEmail: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateChangeEmailRequestRequest>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateChangeEmailRequestRequest,
  ): CreateChangeEmailRequestRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string new_email */ 1:
          message.newEmail = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateChangeEmailRequestRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string new_email = 1; */
    if (message.newEmail !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.newEmail);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.CreateChangeEmailRequestRequest
 */
export const CreateChangeEmailRequestRequest =
  new CreateChangeEmailRequestRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateChangeEmailRequestResponse$Type extends MessageType<CreateChangeEmailRequestResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.CreateChangeEmailRequestResponse",
      [],
    );
  }
  create(
    value?: PartialMessage<CreateChangeEmailRequestResponse>,
  ): CreateChangeEmailRequestResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateChangeEmailRequestResponse>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateChangeEmailRequestResponse,
  ): CreateChangeEmailRequestResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: CreateChangeEmailRequestResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.CreateChangeEmailRequestResponse
 */
export const CreateChangeEmailRequestResponse =
  new CreateChangeEmailRequestResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmChangeEmailRequestRequest$Type extends MessageType<ConfirmChangeEmailRequestRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestRequest",
      [{ no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }],
    );
  }
  create(
    value?: PartialMessage<ConfirmChangeEmailRequestRequest>,
  ): ConfirmChangeEmailRequestRequest {
    const message = { token: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ConfirmChangeEmailRequestRequest>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ConfirmChangeEmailRequestRequest,
  ): ConfirmChangeEmailRequestRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string token */ 1:
          message.token = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ConfirmChangeEmailRequestRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string token = 1; */
    if (message.token !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.token);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestRequest
 */
export const ConfirmChangeEmailRequestRequest =
  new ConfirmChangeEmailRequestRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmChangeEmailRequestResponse$Type extends MessageType<ConfirmChangeEmailRequestResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestResponse",
      [],
    );
  }
  create(
    value?: PartialMessage<ConfirmChangeEmailRequestResponse>,
  ): ConfirmChangeEmailRequestResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ConfirmChangeEmailRequestResponse>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ConfirmChangeEmailRequestResponse,
  ): ConfirmChangeEmailRequestResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: ConfirmChangeEmailRequestResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestResponse
 */
export const ConfirmChangeEmailRequestResponse =
  new ConfirmChangeEmailRequestResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCurrentBusinessUserRequest$Type extends MessageType<UpdateCurrentBusinessUserRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserRequest",
      [{ no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }],
    );
  }
  create(
    value?: PartialMessage<UpdateCurrentBusinessUserRequest>,
  ): UpdateCurrentBusinessUserRequest {
    const message = { name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateCurrentBusinessUserRequest>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateCurrentBusinessUserRequest,
  ): UpdateCurrentBusinessUserRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateCurrentBusinessUserRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserRequest
 */
export const UpdateCurrentBusinessUserRequest =
  new UpdateCurrentBusinessUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCurrentBusinessUserResponse$Type extends MessageType<UpdateCurrentBusinessUserResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserResponse",
      [],
    );
  }
  create(
    value?: PartialMessage<UpdateCurrentBusinessUserResponse>,
  ): UpdateCurrentBusinessUserResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateCurrentBusinessUserResponse>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateCurrentBusinessUserResponse,
  ): UpdateCurrentBusinessUserResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: UpdateCurrentBusinessUserResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserResponse
 */
export const UpdateCurrentBusinessUserResponse =
  new UpdateCurrentBusinessUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCurrentBusinessUserPasswordRequest$Type extends MessageType<UpdateCurrentBusinessUserPasswordRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserPasswordRequest",
      [
        { no: 1, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "new_password",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateCurrentBusinessUserPasswordRequest>,
  ): UpdateCurrentBusinessUserPasswordRequest {
    const message = { password: "", newPassword: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateCurrentBusinessUserPasswordRequest>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateCurrentBusinessUserPasswordRequest,
  ): UpdateCurrentBusinessUserPasswordRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string password */ 1:
          message.password = reader.string();
          break;
        case /* string new_password */ 2:
          message.newPassword = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateCurrentBusinessUserPasswordRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string password = 1; */
    if (message.password !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.password);
    /* string new_password = 2; */
    if (message.newPassword !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.newPassword);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserPasswordRequest
 */
export const UpdateCurrentBusinessUserPasswordRequest =
  new UpdateCurrentBusinessUserPasswordRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.business_user.BusinessUser
 */
export const BusinessUser = new ServiceType(
  "auto_reserve.restaurants.business_user.BusinessUser",
  [
    { name: "Index", options: {}, I: IndexRequest, O: IndexResponse },
    {
      name: "GetBusinessUser",
      options: {},
      I: GetBusinessUserRequest,
      O: BusinessUserResource,
    },
    {
      name: "UpdateBusinessUser",
      options: {},
      I: UpdateBusinessUserRequest,
      O: BusinessUserResource,
    },
    {
      name: "DestroyBusinessUser",
      options: {},
      I: DestroyBusinessUserRequest,
      O: Empty,
    },
    { name: "Invite", options: {}, I: InviteRequest, O: InviteResponse },
    {
      name: "ResendInvitation",
      options: {},
      I: ResendInvitationRequest,
      O: ResendInvitationResponse,
    },
    {
      name: "GetInvitation",
      options: {},
      I: GetInvitationRequest,
      O: GetInvitationResponse,
    },
    { name: "Register", options: {}, I: RegisterRequest, O: RegisterResponse },
    {
      name: "CreateChangeEmailRequest",
      options: {},
      I: CreateChangeEmailRequestRequest,
      O: CreateChangeEmailRequestResponse,
    },
    {
      name: "ConfirmChangeEmailRequest",
      options: {},
      I: ConfirmChangeEmailRequestRequest,
      O: ConfirmChangeEmailRequestResponse,
    },
    {
      name: "UpdateCurrentBusinessUser",
      options: {},
      I: UpdateCurrentBusinessUserRequest,
      O: BusinessUserResource,
    },
    {
      name: "UpdateCurrentBusinessUserPassword",
      options: {},
      I: UpdateCurrentBusinessUserPasswordRequest,
      O: Empty,
    },
  ],
);
