import axios, { setHeader, wrapResponse } from '../modules/axios'
import useSWR, { fetcher, mutate, onError, swrKey } from '../modules/swr'

import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { RestaurantBusinessTime } from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTime'
import { RestaurantBusinessTimeClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_business_time/restaurant_business_time_service.client'
import { useMemo } from 'react'
import { createRpcService } from '../modules/rpc'
export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTime'

export const restaurantBusinessTimeService = createRpcService(
  RestaurantBusinessTimeClient
)

export function useRestaurantBusinessTimes(restaurantId: number) {
  const token = useToken()

  const {
    data: restaurantBusinessTimes,
    error,
    mutate,
  } = useSWR<RestaurantBusinessTime[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/business_times`),
    fetcher
  )

  return {
    restaurantBusinessTimes,
    error,
    mutate,
  }
}

export function useRestaurantBusinessTimesByDate(
  restaurantId: number,
  params: {
    date: string
  }
) {
  const token = useToken()

  const {
    data: restaurantBusinessTimes,
    error,
    mutate,
    isLoading,
  } = useSWR<RestaurantBusinessTime[]>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/business_times/by_date`,
      params
    ),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher<RestaurantBusinessTime[]>([token, url, params]),
    undefined
  )

  return {
    restaurantBusinessTimes: restaurantBusinessTimes ?? [],
    error,
    mutate,
    isLoading,
  }
}

export function useRestaurantBusinessTime(
  restaurantId: number,
  restaurantBusinessTimeId: RestaurantBusinessTime['id']
) {
  const token = useToken()

  const {
    data: restaurantBusinessTime,
    error,
    mutate,
  } = useSWR<RestaurantBusinessTime, any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`
    ),
    fetcher
  )

  return {
    restaurantBusinessTime,
    isLoading: restaurantBusinessTime == null && error == null,
    error,
    mutate,
  }
}

export async function createRestaurantBusinessTime(
  token: string,
  restaurantId: number,
  params: {
    name: string
    start_time: number
    last_order_time: number
    end_time: number
    wdays: number[]
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<RestaurantBusinessTime>(
      `/restaurants/${restaurantId}/business_times`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/business_times/${response.data.id}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantBusinessTime: response?.data,
    error,
  }
}

export async function updateRestaurantBusinessTime(
  token: string,
  restaurantId: number,
  restaurantBusinessTimeId: RestaurantBusinessTime['id'],
  params: {
    name: string
    start_time: number
    last_order_time: number
    end_time: number
    staying_time: number
    wdays: number[]
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<RestaurantBusinessTime>(
      `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantBusinessTime: response?.data,
    error,
  }
}

export async function destroyRestaurantBusinessTime(
  token: string,
  restaurantId: number,
  restaurantBusinessTimeId: RestaurantBusinessTime['id']
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.delete<RestaurantBusinessTime>(
      `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
  }
}

export function useBusinessTimesFromGetCalendar(
  restaurantId: number,
  date: dayjs.Dayjs
) {
  const { data, mutate } = restaurantBusinessTimeService.useGetCalendar({
    restaurantId,
    gte: date.startOf('month').format('YYYY-MM-DD'),
    lte: date.endOf('month').format('YYYY-MM-DD'),
  })

  const dates = data?.dates

  const formatDate = date.format('YYYY-MM-DD')

  return useMemo(
    () => ({
      businessTimes:
        dates?.find((d) => d.date === formatDate)?.businessTimes ?? [],
      mutate,
    }),
    [dates, formatDate, mutate]
  )
}
