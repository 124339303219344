import config from '@hello-ai/for_r_app/src/modules/config'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Helmet } from 'react-helmet-async'

const GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.NODE_ENV === 'production' && !config.isStaging
    ? '6LfYxxskAAAAABrCWRaaJ8raUNEg9KskkKf8_If-'
    : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

const RECAPTCHA_SCRIPT_SRC_URL =
  'https://www.google.com/recaptcha/api.js?render=explicit'

interface ReCaptchaProps {
  containerId?: string
  size: 'normal' | 'compact' | 'invisible'
  theme: 'light' | 'dark'
  badge: 'bottomright' | 'bottomleft' | 'inline'
  successCallback: (response: any) => void
  expiredCallback: () => void
  errorCallback: () => void
}

interface Window {
  grecaptcha: any
}
declare let window: Window

export default function useReCaptcha({
  containerId,
  size,
  theme,
  badge,
  successCallback,
  expiredCallback,
  errorCallback,
}: ReCaptchaProps) {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(
    window?.grecaptcha?.render != null
  )
  const [recaptchaWidget, setRecaptchaWidget] = useState(null)
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const recaptchaHasRenderedRef = useRef(false)

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (window?.grecaptcha?.render != null) {
        setRecaptchaLoaded(true)
      }
    }, 500)

    return () => {
      if (timerRef.current != null) clearInterval(timerRef.current as any)
    }
  }, [])

  useEffect(() => {
    if (recaptchaLoaded && recaptchaWidget === null) {
      if (timerRef.current != null) clearInterval(timerRef.current as any)
      if (!recaptchaHasRenderedRef.current) {
        recaptchaHasRenderedRef.current = true
        const widget = window.grecaptcha.render(containerId, {
          sitekey: GOOGLE_RECAPTCHA_SITE_KEY,
          size,
          theme,
          badge,
          callback: successCallback,
          'expired-callback': expiredCallback,
          'error-callback': errorCallback,
        })
        setRecaptchaWidget(widget)
      }
    }
  }, [
    recaptchaLoaded,
    successCallback,
    expiredCallback,
    errorCallback,
    recaptchaWidget,
    containerId,
    badge,
    size,
    theme,
    recaptchaHasRenderedRef,
  ])

  const execute = useCallback(() => {
    if (recaptchaWidget != null) {
      window.grecaptcha.execute(recaptchaWidget)
    }
  }, [recaptchaWidget])

  const reset = useCallback(() => {
    if (recaptchaWidget != null) {
      window.grecaptcha.reset(recaptchaWidget)
    }
  }, [recaptchaWidget])

  return {
    execute,
    recaptchaLoaded,
    recaptchaWidget,
    reset,
  }
}

// recaptchaのscript読み込み
export function GoogleReCaptchaScript() {
  return (
    <Helmet>
      <script async src={RECAPTCHA_SCRIPT_SRC_URL} />
    </Helmet>
  )
}
