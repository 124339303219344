import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, { fetcher, onError, swrKey, useStickySWR } from 'modules/swr'
import { mutate } from 'swr'
import { useToken } from './Auth'

import { TakeoutMenuPage } from '@hello-ai/ar_shared/src/types/ForR/TakeoutMenuPage'
export * from '@hello-ai/ar_shared/src/types/ForR/TakeoutMenuPage'

export function useTakeoutMenuPages({
  restaurantId,
  params,
}: {
  restaurantId: number
  params?: { per_page?: number }
}) {
  const token = useToken()
  const {
    data: takeoutMenuPages,
    error,
    mutate,
  } = useStickySWR<TakeoutMenuPage[], any, ReturnType<typeof swrKey>>( // あとで検索入れた時用にstickyにしておく
    swrKey(token, `/takeout/restaurants/${restaurantId}/menu_pages`, params),
    ([token, url]) => fetcher<TakeoutMenuPage[]>([token, url, params])
  )

  return {
    takeoutMenuPages,
    isLoading: takeoutMenuPages == null,
    error,
    mutate,
  }
}

export function useTakeoutMenuPage({
  takeoutMenuPageId,
}: {
  takeoutMenuPageId?: string
}) {
  const token = useToken()
  const {
    data: takeoutMenuPage,
    error,
    mutate,
  } = useSWR<TakeoutMenuPage, any, ReturnType<typeof swrKey> | null>(
    takeoutMenuPageId != null
      ? swrKey(token, `/takeout/menu_pages/${takeoutMenuPageId}`)
      : null,
    fetcher
  )

  if (takeoutMenuPageId == null) {
    return {
      tableMenuPage: null,
      isLoading: false,
      mutate,
    }
  }

  return {
    takeoutMenuPage,
    isLoading: takeoutMenuPage == null,
    error,
    mutate,
  }
}

export async function createTakeoutMenuPage({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(`/takeout/restaurants/${restaurantId}/menu_pages`, params)
  )

  if (error != null) {
    onError(error)
  }

  return {
    takeoutMenuPage: response?.data,
  }
}

export async function updateTakeoutMenuPage({
  token,
  takeoutMenuPageId,
  params,
}: {
  token: string
  takeoutMenuPageId: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(`/takeout/menu_pages/${takeoutMenuPageId}`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/takeout/menu_pages/${takeoutMenuPageId}`),
      response.data,
      false
    )
  }

  return {
    takeoutMenuPage: response?.data,
  }
}
