import React from 'react'
import { View } from 'react-native'
import { Colors } from '../../../../constants/Colors'
import { OutboundUpdateHistory } from '../../../../types/ForR/OutboundSetting'
import dayjs from '../../../../modules/dayjs'
import UpdateHistoryTitle from './UpdateHistoryTitle'
import { t } from '../../../../modules/i18n'
import { Text } from '../../../Text'
import { Pagination } from '../../../Pagination'

function getActionLabel(action: OutboundUpdateHistory['action']) {
  switch (action) {
    case 'create':
      return t('新規連携')
    case 'update':
      return t('連携の更新')
  }
}

const PerPage = 10

interface UpdateHistoryListProps {
  histories?: OutboundUpdateHistory[]
}

export default function UpdateHistoryList({
  histories,
}: UpdateHistoryListProps) {
  const [currentPage, setCurrentPage] = React.useState(1)
  const totalPage = Math.ceil((histories?.length ?? 0) / PerPage)

  return (
    <View>
      <UpdateHistoryTitle latestHistory={histories?.[0]} />
      <View
        style={{
          backgroundColor: Colors.white,
          paddingHorizontal: 24,
          borderRadius: 8,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            borderBottomWidth: 0.5,
            borderColor: Colors.border,
            paddingVertical: 16,
          }}
        >
          <Text style={{ flex: 1, fontWeight: '600', fontSize: 14 }}>
            更新者
          </Text>
          <Text style={{ flex: 1, fontWeight: '600', fontSize: 14 }}>内容</Text>
          <Text style={{ flex: 1, fontWeight: '600', fontSize: 14 }}>
            更新履歴
          </Text>
        </View>
        {histories?.length === 0 && (
          <Text
            style={{
              fontSize: 16,
              fontWeight: '300',
              color: Colors.black60,
              paddingVertical: 80,
              textAlign: 'center',
            }}
          >
            更新履歴はありません
          </Text>
        )}
        {histories?.map((history, index) => (
          <View
            key={index}
            style={{
              flexDirection: 'row',
              paddingVertical: 20,
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
            }}
          >
            <Text style={{ flex: 1, fontWeight: '600', fontSize: 18 }}>
              {history.name}
            </Text>
            <Text style={{ flex: 1, fontWeight: '300', fontSize: 16 }}>
              {getActionLabel(history.action)}
            </Text>
            <Text style={{ flex: 1, fontWeight: '300', fontSize: 16 }}>
              {dayjs(history.created_at)
                .tz(dayjs.tz.guess())
                .format('YYYY/MM/DD HH:mm')}
            </Text>
          </View>
        ))}
        <View style={{ paddingVertical: 16 }}>
          <Pagination
            currentPage={currentPage}
            totalPage={totalPage}
            setPage={setCurrentPage}
          />
        </View>
      </View>
    </View>
  )
}
