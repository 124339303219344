import React from 'react'
import { View, ScrollView } from 'react-native'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Button } from 'react-native-paper'

import {
  TakeoutMenu as TakeoutMenuModel,
  useRestaurantTakeoutMenus,
} from 'models/TakeoutMenu'

import { useRestaurantId } from 'modules/useRestaurantId'
import { history } from 'modules/history'

import TakeoutView from 'components/Restaurant/TakeoutView'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function TakeoutMenu({ takeoutMenu }: { takeoutMenu: TakeoutMenuModel }) {
  const restaurantId = useRestaurantId()
  return (
    <TouchableOpacity
      onPress={() =>
        history.push(
          `/restaurants/${restaurantId}/takeout/menus/${takeoutMenu.id}/edit`
        )
      }
    >
      <Text style={{ fontWeight: '600' }}>{takeoutMenu.name}</Text>
      <Text style={{ marginTop: 6 }}>{takeoutMenu.time_range_description}</Text>
    </TouchableOpacity>
  )
}

export default function TakeoutMenusScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()

  const { takeoutMenus, isLoading } = useRestaurantTakeoutMenus({
    restaurantId,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <TakeoutView>
      <ScrollView
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
      >
        <View style={{ flexDirection: 'row', marginLeft: -20 }}>
          <Button
            mode="outlined"
            style={{
              marginLeft: 20,
              backgroundColor: 'white',
              flex: 1,
              borderRadius: 24,
            }}
            contentStyle={{ height: 44 }}
            onPress={() =>
              history.push(`/restaurants/${restaurantId}/takeout/menus/new`)
            }
          >
            {t('メニューを追加')}
          </Button>
        </View>
        {takeoutMenus && takeoutMenus.length > 0 && (
          <View style={{ marginTop: 40 }}>
            <ShadowBox
              style={[
                {
                  paddingHorizontal: 0,
                  paddingVertical: 8,
                },
              ]}
            >
              {takeoutMenus.map((takeoutMenu, index) => {
                return (
                  <View
                    key={takeoutMenu.id}
                    style={{
                      borderTopWidth: index === 0 ? 0 : 0.5,
                      borderColor: Colors.border,
                      paddingTop: 16,
                      marginBottom: 16,
                      paddingHorizontal: 24,
                    }}
                  >
                    <TakeoutMenu takeoutMenu={takeoutMenu} />
                  </View>
                )
              })}
            </ShadowBox>
          </View>
        )}
      </ScrollView>
    </TakeoutView>
  )
}
