import {
  TableSeatResource_Category,
  TableSeatResource_SmokingStatus,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import { assertNever } from '@hello-ai/ar_shared/src/modules/assert'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export function getCategoryName(value: TableSeatResource_Category) {
  switch (value) {
    case TableSeatResource_Category.TABLE_SEAT:
      return t('テーブル')
    case TableSeatResource_Category.COUNTER_SEAT:
      return t('カウンター')
    case TableSeatResource_Category.TERRACE:
      return t('テラス')
    case TableSeatResource_Category.SEMI_PRIVATE_ROOM:
      return t('半個室')
    case TableSeatResource_Category.PRIVATE_ROOM:
      return t('個室')
    case TableSeatResource_Category.TATAMI:
      return t('畳')
    default: {
      assertNever(value)
    }
  }
}

export function getSmokingStatusName(value: TableSeatResource_SmokingStatus) {
  switch (value) {
    case TableSeatResource_SmokingStatus.NO_SMOKING:
      return t('禁煙')
    case TableSeatResource_SmokingStatus.SMOKING:
      return t('喫煙')
    default: {
      assertNever(value)
    }
  }
}
