import React from 'react'
import { ChangeRequestForm } from '@hello-ai/for_r_app/src/components/Reservation/ChangeRequestForm'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Redirect } from 'App'
import { useRestaurantReservation } from '@hello-ai/for_r_app/src/models/RestaurantReservation'

export default function ChangeRequestFormPage() {
  const restaurantId = useRestaurantId()
  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()
  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  if (restaurantReservation == null) return <Loading />
  if (restaurantReservation.reservation_change_request == null) {
    return <Redirect to={`/restaurants/${restaurantId}/reservations`} />
  }

  return (
    <ChangeRequestForm
      restaurantId={restaurantId}
      data={restaurantReservation.reservation_change_request}
      customer={restaurantReservation.customers[0]}
      restaurantReservation={restaurantReservation}
    />
  )
}
