import { Platform, type GestureResponderEvent } from 'react-native'

function lockScrollHandler(e: Event) {
  if (e.cancelable) {
    e.preventDefault()
  }
}

export function toDragHandlers(
  onDragStart: (event: GestureResponderEvent) => void,
  onDragEnd: (event: GestureResponderEvent) => void,
  scrollRef?: React.RefObject<HTMLDivElement>
) {
  if (Platform.OS !== 'web') {
    return {
      onPressIn: onDragStart,
      onPressOut: onDragEnd,
    }
  }

  // Webでスマホの時のスクロールを防ぐ
  const opts: AddEventListenerOptions & EventListenerOptions = {
    passive: false,
  }
  function addLockScroll() {
    ;(scrollRef?.current ?? document).addEventListener(
      'touchmove',
      lockScrollHandler,
      opts
    )
  }
  function removeLockScroll() {
    ;(scrollRef?.current ?? document).removeEventListener(
      'touchmove',
      lockScrollHandler,
      opts
    )
  }

  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
    ? {
        onTouchStart: (e: GestureResponderEvent) => {
          addLockScroll()
          onDragStart(e)
        },
        onTouchEnd: (e: GestureResponderEvent) => {
          removeLockScroll()
          onDragEnd(e)
        },
        onTouchCancel: () => {
          removeLockScroll()
        },
      }
    : {
        onMouseDown: (e: GestureResponderEvent) => {
          addLockScroll()
          onDragStart(e)
        },
        onMouseUp: (e: GestureResponderEvent) => {
          removeLockScroll()
          onDragEnd(e)
        },
      }
}
