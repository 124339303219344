import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import React, { RefObject, useRef } from 'react'
import {
  TouchableOpacity as RNTouchableOpacity,
  ScrollView,
  View,
} from 'react-native'

import { filter, times } from 'lodash'
import { toDoubleDigits } from 'modules/number'
import { splitHourAndMinute } from 'modules/time'

import { faGripLines } from '@fortawesome/pro-solid-svg-icons/faGripLines'

import {
  TakeoutMenu as TakeoutMenuModel,
  createTakeoutMenu,
  updateTakeoutMenu,
  useTakeoutMenu,
} from 'models/TakeoutMenu'
import DragList from 'react-native-draglist'

import { useToken } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router-dom'

import { goBack } from 'modules/history'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { toDragHandlers } from '@hello-ai/ar_shared/src/modules/drag'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import TakeoutView from 'components/Restaurant/TakeoutView'

const hours = times(30, (i) => {
  return {
    label: toDoubleDigits(i),
    value: i,
  }
})

const mins = times(60, (i) => {
  return {
    label: toDoubleDigits(i),
    value: i,
  }
})

function initWdays(wdays: number[]) {
  return {
    0: {
      label: dayjs.weekdays()[0],
      checked: wdays.includes(0),
      value: 0,
    },
    1: {
      label: dayjs.weekdays()[1],
      checked: wdays.includes(1),
      value: 1,
    },
    2: {
      label: dayjs.weekdays()[2],
      checked: wdays.includes(2),
      value: 2,
    },
    3: {
      label: dayjs.weekdays()[3],
      checked: wdays.includes(3),
      value: 3,
    },
    4: {
      label: dayjs.weekdays()[4],
      checked: wdays.includes(4),
      value: 4,
    },
    5: {
      label: dayjs.weekdays()[5],
      checked: wdays.includes(5),
      value: 5,
    },
    6: {
      label: dayjs.weekdays()[6],
      checked: wdays.includes(6),
      value: 6,
    },
    7: {
      label: t('祝日'),
      checked: wdays.includes(7),
      value: 7,
    },
  }
}

function TakeoutMenuPage({
  name,
  index,
  isActive,
  scrollRef,
  onDragStart,
  onDragEnd,
}: {
  name?: string
  index?: number
  isActive: boolean
  scrollRef: RefObject<HTMLDivElement>
  onDragStart: () => void
  onDragEnd: () => void
}) {
  if (name === null) {
    return null
  }

  return (
    <RNTouchableOpacity
      style={{
        paddingVertical: 24,
        borderTopWidth: index === 0 ? 0 : 0.5,
        borderColor: Colors.border,
        backgroundColor: isActive ? Colors.primaryBg : 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      {...toDragHandlers(onDragStart, onDragEnd, scrollRef)}
    >
      <Text>{name}</Text>
      <FontAwesomeIcon
        icon={faGripLines}
        size={20}
        color={Colors.disabledBlack}
      />
    </RNTouchableOpacity>
  )
}

function TakeoutMenuFormScreenContent({
  takeoutMenu,
}: {
  takeoutMenu?: TakeoutMenuModel
}) {
  const scrollRef = useRef(null)
  const { width, sm } = useResponsive()
  const space = width < sm ? 20 : 40
  const restaurantId = useRestaurantId()
  const token = useToken()
  const takeoutMenuId = takeoutMenu?.id

  const [name, setName] = useFormState(takeoutMenu?.name ?? '')
  const [startHour, setStartHour] = useFormState(
    takeoutMenu?.start_time != null
      ? splitHourAndMinute(takeoutMenu.start_time).hour
      : 0
  )
  const [startMin, setStartMin] = useFormState(
    takeoutMenu?.start_time != null
      ? splitHourAndMinute(takeoutMenu.start_time).minute
      : 0
  )
  const [endHour, setEndHour] = useFormState(
    takeoutMenu?.end_time != null
      ? splitHourAndMinute(takeoutMenu.end_time).hour
      : 0
  )
  const [endMin, setEndMin] = useFormState(
    takeoutMenu?.end_time != null
      ? splitHourAndMinute(takeoutMenu.end_time).minute
      : 0
  )
  const [wdays, setWdays] = useFormState(
    initWdays(takeoutMenu?.wdays ?? [0, 1, 2, 3, 4, 5, 6, 7])
  )
  const [data, setData] = useFormState(
    takeoutMenu?.takeout_menu_pages
      ? takeoutMenu.takeout_menu_pages.map((menuPage) => menuPage.id)
      : []
  )

  const onPress = async () => {
    if (name === '') {
      Alert.alert(t('メニュー名を入力してください'))
      return
    }

    if (token == null) {
      return
    }

    const startTime = startHour * 3600 + startMin * 60
    const endTime = endHour * 3600 + endMin * 60
    const params = {
      name,
      start_time: startTime,
      end_time: endTime,
      wdays: filter(wdays, { checked: true }).map((wday) => {
        return wday.value
      }),
      takeout_menu_page_ids: data,
    }
    if (takeoutMenuId != null) {
      await updateTakeoutMenu({
        token,
        takeoutMenuId,
        params,
      })
      goBack('..')
    } else {
      await createTakeoutMenu({
        token,
        restaurantId,
        params,
      })
      goBack('..')
    }
  }

  const alertArchive = () => {
    const params = {
      status: 'archived',
    }
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (token == null || takeoutMenuId == null) {
            return
          }
          await updateTakeoutMenu({
            token,
            takeoutMenuId,
            params,
          })
          goBack('..')
        },
      },
    ])
  }

  const paddingHorizontal = width < sm ? 16 : 36

  return (
    <ScrollView
      ref={scrollRef}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        overflow: 'scroll',
        height: '100%',
      }}
      contentContainerStyle={{
        paddingVertical: space,
      }}
    >
      <DragList
        key={data.join()}
        data={data}
        keyExtractor={(item) => item}
        renderItem={({ item, index, isActive, onDragStart, onDragEnd }) => (
          <View style={{ paddingHorizontal }}>
            <TakeoutMenuPage
              name={
                takeoutMenu?.takeout_menu_pages.find(
                  (menuPage) => menuPage.id === item
                )?.name
              }
              index={index}
              isActive={isActive}
              scrollRef={scrollRef}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
            />
          </View>
        )}
        ListHeaderComponent={
          <View
            style={{
              paddingHorizontal,
            }}
          >
            <TextInput
              placeholder={t('メニュー名')}
              value={name}
              onChangeText={(text) => setName(text)}
              autoCapitalize="none"
            />
            <View style={{ flexDirection: 'row', marginTop: space }}>
              <View style={{ flex: 1, marginRight: 12 }}>
                <Text style={{ fontWeight: '600' }}>{t('開始時間')}</Text>
                <View
                  style={{
                    marginTop: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SelectInput
                    selectedValue={startHour}
                    setValue={setStartHour}
                    items={hours}
                    style={{ flex: 1 }}
                  />
                  <Text style={{ marginHorizontal: 8 }}>:</Text>
                  <SelectInput
                    selectedValue={startMin}
                    setValue={setStartMin}
                    items={mins}
                    style={{ flex: 1 }}
                  />
                </View>
              </View>
              <View style={{ flex: 1, marginLeft: 12 }}>
                <Text style={{ fontWeight: '600' }}>{t('終了時間')}</Text>
                <View
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SelectInput
                    selectedValue={endHour}
                    setValue={setEndHour}
                    items={hours}
                    style={{ flex: 1 }}
                  />
                  <Text style={{ marginHorizontal: 8 }}>:</Text>
                  <SelectInput
                    selectedValue={endMin}
                    setValue={setEndMin}
                    items={mins}
                    style={{ flex: 1 }}
                  />
                </View>
              </View>
            </View>
            <View style={{ marginTop: space }}>
              <Text style={{ fontWeight: '600' }}>{t('曜日')}</Text>
              <View style={{ marginTop: 20 }}>
                <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
                  {Object.entries(wdays).map(([key, wday]) => {
                    return (
                      <View key={key}>
                        <Checkbox
                          checked={wday.checked}
                          onChange={(value) =>
                            setWdays((state) => ({
                              ...state,
                              [key]: {
                                label: wday.label,
                                checked: value,
                                value: key,
                              },
                            }))
                          }
                          checkboxLabel={<CheckboxLabel value={wday.label} />}
                        />
                      </View>
                    )
                  })}
                </Wrap>
              </View>
              <Text style={{ marginTop: 16, color: Colors.secondaryBlack }}>
                {t('休日が祝日の場合は休日の設定が優先されます')}
              </Text>
            </View>
            {data != null && data.length > 0 && (
              <Text style={{ fontWeight: '600', marginTop: space }}>
                {t('カテゴリーの並び替え')}
              </Text>
            )}
          </View>
        }
        ListFooterComponent={
          <View style={{ paddingHorizontal }}>
            <View style={{ flexDirection: 'row', marginTop: space }}>
              {takeoutMenuId != null && (
                <Button
                  mode="outline"
                  variant="danger-secondary"
                  height={56}
                  style={{
                    flex: 1,
                    marginRight: 32,
                  }}
                  onPress={() => alertArchive()}
                >
                  {t('アーカイブ')}
                </Button>
              )}
              <Button
                height={56}
                style={{
                  flex: 1,
                }}
                onPress={() => onPress()}
              >
                {takeoutMenuId != null ? t('更新する') : t('追加する')}
              </Button>
            </View>
          </View>
        }
        onReordered={async (fromIndex, toIndex) => {
          setData((prev) => {
            const removed = prev[fromIndex]
            return prev.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, removed)
          })
        }}
      />
    </ScrollView>
  )
}

export default function TakeoutMenuFormScreen() {
  const { takeout_menu_id: takeoutMenuId } = useParams<{
    takeout_menu_id?: string
  }>()
  const { takeoutMenu, isLoading } = useTakeoutMenu({
    takeoutMenuId,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <TakeoutView>
      <TakeoutMenuFormScreenContent takeoutMenu={takeoutMenu} />
    </TakeoutView>
  )
}
