import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'
import { useToken } from 'models/Auth'
import { businessUserClient } from 'models/BusinessUser'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function Confirm() {
  const token = useToken()
  const navigate = useNavigate()
  const { changeEmailRequestToken } = useParams<{
    businessUserId: string
    changeEmailRequestToken: string
  }>()

  useEffect(() => {
    async function confirm() {
      const { error } = await callRpc(
        businessUserClient.confirmChangeEmailRequest(
          {
            token: changeEmailRequestToken!,
          },
          rpcOptions({ token })
        )
      )
      if (error != null) {
        onError(error)
        return
      }
      displayToastInfo(t('メールアドレスを変更しました'))
      navigate('/', { replace: true })
    }
    confirm()
  }, [changeEmailRequestToken, navigate, token])

  return <Loading />
}
