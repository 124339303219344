// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_service.proto" (package "auto_reserve.restaurants.table_seat_charge_policy", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TableSeatChargePolicyResource_TaxType } from "./table_seat_charge_policy_resource";
import { TableSeatChargePolicyResource_ChargeUnit } from "./table_seat_charge_policy_resource";
import { Int32Value } from "../../../google/protobuf/wrappers";
import { TableSeatChargePolicyResource } from "./table_seat_charge_policy_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource table_seat_charge_policies = 1;
   */
  tableSeatChargePolicies: TableSeatChargePolicyResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value charge_interval = 3;
   */
  chargeInterval?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit = 4;
   */
  chargeUnit: TableSeatChargePolicyResource_ChargeUnit;
  /**
   * @generated from protobuf field: float price = 5;
   */
  price: number;
  /**
   * @generated from protobuf field: bool tax_included = 7;
   */
  taxIncluded: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type = 8;
   */
  taxType: TableSeatChargePolicyResource_TaxType;
  /**
   * @generated from protobuf field: repeated string table_seat_ids = 9;
   */
  tableSeatIds: string[];
  /**
   * @generated from protobuf field: repeated string table_menu_ids = 10;
   */
  tableMenuIds: string[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value charge_interval = 3;
   */
  chargeInterval?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit = 4;
   */
  chargeUnit: TableSeatChargePolicyResource_ChargeUnit;
  /**
   * @generated from protobuf field: float price = 5;
   */
  price: number;
  /**
   * @generated from protobuf field: bool tax_included = 7;
   */
  taxIncluded: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type = 8;
   */
  taxType: TableSeatChargePolicyResource_TaxType;
  /**
   * @generated from protobuf field: repeated string table_seat_ids = 9;
   */
  tableSeatIds: string[];
  /**
   * @generated from protobuf field: repeated string table_menu_ids = 10;
   */
  tableMenuIds: string[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_charge_policy.ArchiveRequest
 */
export interface ArchiveRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_charge_policy.ListRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { restaurantId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_charge_policy.ListResponse", [
      {
        no: 1,
        name: "table_seat_charge_policies",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TableSeatChargePolicyResource,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { tableSeatChargePolicies: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource table_seat_charge_policies */ 1:
          message.tableSeatChargePolicies.push(
            TableSeatChargePolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource table_seat_charge_policies = 1; */
    for (let i = 0; i < message.tableSeatChargePolicies.length; i++)
      TableSeatChargePolicyResource.internalBinaryWrite(
        message.tableSeatChargePolicies[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_charge_policy.GetRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { restaurantId: 0, id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_charge_policy.CreateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "charge_interval", kind: "message", T: () => Int32Value },
      {
        no: 4,
        name: "charge_unit",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit",
          TableSeatChargePolicyResource_ChargeUnit,
        ],
      },
      { no: 5, name: "price", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
      { no: 7, name: "tax_included", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: "tax_type",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType",
          TableSeatChargePolicyResource_TaxType,
        ],
      },
      {
        no: 9,
        name: "table_seat_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: "table_menu_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      chargeUnit: 0,
      price: 0,
      taxIncluded: false,
      taxType: 0,
      tableSeatIds: [],
      tableMenuIds: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* google.protobuf.Int32Value charge_interval */ 3:
          message.chargeInterval = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.chargeInterval,
          );
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit */ 4:
          message.chargeUnit = reader.int32();
          break;
        case /* float price */ 5:
          message.price = reader.float();
          break;
        case /* bool tax_included */ 7:
          message.taxIncluded = reader.bool();
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type */ 8:
          message.taxType = reader.int32();
          break;
        case /* repeated string table_seat_ids */ 9:
          message.tableSeatIds.push(reader.string());
          break;
        case /* repeated string table_menu_ids */ 10:
          message.tableMenuIds.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* google.protobuf.Int32Value charge_interval = 3; */
    if (message.chargeInterval)
      Int32Value.internalBinaryWrite(
        message.chargeInterval,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit = 4; */
    if (message.chargeUnit !== 0)
      writer.tag(4, WireType.Varint).int32(message.chargeUnit);
    /* float price = 5; */
    if (message.price !== 0) writer.tag(5, WireType.Bit32).float(message.price);
    /* bool tax_included = 7; */
    if (message.taxIncluded !== false)
      writer.tag(7, WireType.Varint).bool(message.taxIncluded);
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type = 8; */
    if (message.taxType !== 0)
      writer.tag(8, WireType.Varint).int32(message.taxType);
    /* repeated string table_seat_ids = 9; */
    for (let i = 0; i < message.tableSeatIds.length; i++)
      writer.tag(9, WireType.LengthDelimited).string(message.tableSeatIds[i]);
    /* repeated string table_menu_ids = 10; */
    for (let i = 0; i < message.tableMenuIds.length; i++)
      writer.tag(10, WireType.LengthDelimited).string(message.tableMenuIds[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_charge_policy.UpdateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "charge_interval", kind: "message", T: () => Int32Value },
      {
        no: 4,
        name: "charge_unit",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit",
          TableSeatChargePolicyResource_ChargeUnit,
        ],
      },
      { no: 5, name: "price", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
      { no: 7, name: "tax_included", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: "tax_type",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType",
          TableSeatChargePolicyResource_TaxType,
        ],
      },
      {
        no: 9,
        name: "table_seat_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: "table_menu_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      restaurantId: 0,
      id: "",
      chargeUnit: 0,
      price: 0,
      taxIncluded: false,
      taxType: 0,
      tableSeatIds: [],
      tableMenuIds: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        case /* google.protobuf.Int32Value charge_interval */ 3:
          message.chargeInterval = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.chargeInterval,
          );
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit */ 4:
          message.chargeUnit = reader.int32();
          break;
        case /* float price */ 5:
          message.price = reader.float();
          break;
        case /* bool tax_included */ 7:
          message.taxIncluded = reader.bool();
          break;
        case /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type */ 8:
          message.taxType = reader.int32();
          break;
        case /* repeated string table_seat_ids */ 9:
          message.tableSeatIds.push(reader.string());
          break;
        case /* repeated string table_menu_ids */ 10:
          message.tableMenuIds.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    /* google.protobuf.Int32Value charge_interval = 3; */
    if (message.chargeInterval)
      Int32Value.internalBinaryWrite(
        message.chargeInterval,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.ChargeUnit charge_unit = 4; */
    if (message.chargeUnit !== 0)
      writer.tag(4, WireType.Varint).int32(message.chargeUnit);
    /* float price = 5; */
    if (message.price !== 0) writer.tag(5, WireType.Bit32).float(message.price);
    /* bool tax_included = 7; */
    if (message.taxIncluded !== false)
      writer.tag(7, WireType.Varint).bool(message.taxIncluded);
    /* auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource.TaxType tax_type = 8; */
    if (message.taxType !== 0)
      writer.tag(8, WireType.Varint).int32(message.taxType);
    /* repeated string table_seat_ids = 9; */
    for (let i = 0; i < message.tableSeatIds.length; i++)
      writer.tag(9, WireType.LengthDelimited).string(message.tableSeatIds[i]);
    /* repeated string table_menu_ids = 10; */
    for (let i = 0; i < message.tableMenuIds.length; i++)
      writer.tag(10, WireType.LengthDelimited).string(message.tableMenuIds[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ArchiveRequest$Type extends MessageType<ArchiveRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_charge_policy.ArchiveRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ArchiveRequest>): ArchiveRequest {
    const message = { restaurantId: 0, id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ArchiveRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ArchiveRequest,
  ): ArchiveRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ArchiveRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_charge_policy.ArchiveRequest
 */
export const ArchiveRequest = new ArchiveRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicy
 */
export const TableSeatChargePolicy = new ServiceType(
  "auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicy",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    {
      name: "Get",
      options: {},
      I: GetRequest,
      O: TableSeatChargePolicyResource,
    },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: TableSeatChargePolicyResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: TableSeatChargePolicyResource,
    },
    {
      name: "Archive",
      options: {},
      I: ArchiveRequest,
      O: TableSeatChargePolicyResource,
    },
  ],
);
