import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import {
  displayToastError,
  displayToastInfo,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { onError } from 'modules/swr'
import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { RestaurantCourseQuestionForm } from '@hello-ai/for_r_app/src/components/RestaurantCourseQuestions'
import {
  ArchiveRequest,
  CreateRequest,
  UpdateRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service'
import { useNavigation } from 'components/Shared/Navigation/Screen'
import { useToken } from 'models/Auth'
import { restaurantCourseQuestionService } from 'models/RestaurantCourseQuestion'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useNavigate, useParams } from 'react-router'

export default function RestaurantCourseQuestionsFormScreen() {
  const restaurantId = useRestaurantId()
  const token = useToken()
  const params = useParams<{
    restaurantCourseQuestionId?: string
  }>()
  const navigate = useNavigate()
  const navigation = useNavigation()

  const restaurantCourseQuestionId = params?.restaurantCourseQuestionId

  const { data: restaurantCourseQuestion, mutate } =
    restaurantCourseQuestionService.useGet(
      restaurantCourseQuestionId == null
        ? undefined
        : {
            id: restaurantCourseQuestionId,
          }
    )

  const onCreate = async (params: CreateRequest) => {
    const { error } = await restaurantCourseQuestionService.create(
      token,
      params
    )

    if (error != null) {
      onError(error)
      return
    }

    navigate(`/restaurants/${restaurantId}/courses`)

    displayToastInfo(t('お客様への質問を作成しました'))
  }

  const onUpdate = async (params: UpdateRequest) => {
    if (restaurantCourseQuestionId === undefined) return

    const { error } = await restaurantCourseQuestionService.update(
      token,
      params
    )

    if (error != null) {
      onError(error)
      return
    }

    navigate(`/restaurants/${restaurantId}/courses`)

    displayToastInfo(t('更新しました'))
    mutate()
  }

  const onArchive = (params: ArchiveRequest) => {
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => archive(params),
      },
    ])
  }

  const archive = async (params: ArchiveRequest) => {
    if (restaurantCourseQuestionId === undefined) return

    const { error } = await restaurantCourseQuestionService.archive(
      token,
      params
    )

    if (error != null) {
      onError(error)
      return
    }
    navigate(`/restaurants/${restaurantId}/courses`)

    displayToastInfo(t('アーカイブしました'))
  }

  useEffect(() => {
    if (restaurantCourseQuestionId != null) {
      navigation.setOptions({
        title: t('お客様への質問を編集'),
      })
    } else {
      navigation.setOptions({
        title: t('お客様への質問を作成'),
      })
    }
  }, [navigation, restaurantCourseQuestionId])

  if (restaurantCourseQuestionId != null && restaurantCourseQuestion == null) {
    return <Loading />
  }

  return (
    <ScrollView
      contentContainerStyle={{
        paddingVertical: 48,
        paddingHorizontal: 32,
      }}
      style={{ backgroundColor: Colors.bgBlack }}
    >
      <RestaurantCourseQuestionForm
        restaurantId={restaurantId}
        restaurantCourseQuestionId={restaurantCourseQuestionId}
        restaurantCourseQuestion={restaurantCourseQuestion}
        onCreate={onCreate}
        onCreateError={(errors) => displayToastError(errors[0])}
        onUpdate={onUpdate}
        onUpdateError={(errors) => displayToastError(errors[0])}
        onArchive={onArchive}
      />
    </ScrollView>
  )
}
