import AsyncStorage from '@react-native-async-storage/async-storage'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

const STORAGE_KEY_NAME = 'selected-reservation-date'

export const persistReservationDate = async (date: dayjs.Dayjs) => {
  const dateString = date.format('YYYY-MM-DD')
  await AsyncStorage.setItem(STORAGE_KEY_NAME, dateString)
}

export const getPersistedReservationDate =
  async (): Promise<dayjs.Dayjs | null> => {
    const dateString = await AsyncStorage.getItem(STORAGE_KEY_NAME)
    return dateString != null && dateString !== '' ? dayjs(dateString) : null
  }
