import React, { ReactNode } from 'react'
import { View, GestureResponderEvent } from 'react-native'

import { StepperErrors } from './Errors'
import { StepperBackButton } from './BackButton'
import { StepperNextButton } from './NextButton'
import { StepperList } from './List'
import { Step } from './Type'

type Props = {
  steps?: Step[]
  currentStepNumber?: number
  errors: string[]
  isDisabledNextPress: boolean
  skipStepNumbers?: number[]
  children: ReactNode
  onPressNext: (event: GestureResponderEvent) => void
  onPressGoBack?: (event: GestureResponderEvent) => void
}

export function Stepper({
  steps,
  currentStepNumber,
  errors,
  isDisabledNextPress,
  skipStepNumbers,
  children,
  onPressNext,
  onPressGoBack,
}: Props) {
  const isListExist = currentStepNumber !== undefined && steps !== undefined
  return (
    <View style={{ flex: 1, position: 'relative' }}>
      {children}
      {isListExist && (
        <StepperList
          steps={steps}
          currentStepNumber={currentStepNumber}
          skipStepNumbers={skipStepNumbers}
        />
      )}
      {errors.length > 0 && (
        <StepperErrors errors={errors} isListExist={isListExist} />
      )}
      {onPressGoBack != null && (
        <StepperBackButton
          onPressGoBack={onPressGoBack}
          isListExist={isListExist}
        />
      )}
      <StepperNextButton
        isLastStep={
          currentStepNumber === steps?.length || currentStepNumber === undefined
        }
        isDisabledNextPress={isDisabledNextPress}
        onPressNext={onPressNext}
        isListExist={isListExist}
      />
    </View>
  )
}
