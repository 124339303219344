import React, { useMemo, useState } from 'react'
import { View, Platform } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import Button from '../../Shared/Button'
import Loading from '../../Shared/Loading'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import {
  Attribute,
  LabelColor,
  UpdateAttributesParams,
  saveRestaurantAttributes,
  useRestaurantAttributes,
} from '../../../models/CustomerAttributes'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { displayToastError, displayToastSuccess } from '../../Shared/Toast'
import { Unavailable } from '../../Restaurant/Unavailable'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { useRestaurant } from '../../../models/Restaurant'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'

interface EditingAttribute {
  id?: string
  name: string
  display_in_list: boolean
  label_color: LabelColor
}

export const labelColors = {
  gray: { text: Colors.black80, backgroundColor: Colors.bgBlack },
  red: { text: Colors.caution, backgroundColor: Colors.cautionBg },
  green: { text: Colors.accent, backgroundColor: Colors.accentBg },
  orange: { text: Colors.orange, backgroundColor: Colors.orangeBg },
  blue: { text: Colors.blue, backgroundColor: Colors.blueBg },
} satisfies { [key in LabelColor]: { text: string; backgroundColor: string } }

const labelColorItems = [
  {
    label: t('グレー'),
    value: 'gray',
  },
  {
    label: t('レッド'),
    value: 'red',
  },
  {
    label: t('グリーン'),
    value: 'green',
  },
  {
    label: t('オレンジ'),
    value: 'orange',
  },
  {
    label: t('ブルー'),
    value: 'blue',
  },
] satisfies { label: string; value: LabelColor }[]

function AttributeTableRow({
  attribute,
  onUpdateAttribute,
  onDeleteAttribute,
}: {
  attribute: EditingAttribute
  onUpdateAttribute: (attribute: EditingAttribute) => void
  onDeleteAttribute: () => void
}) {
  const { width, sm, md } = useResponsive()

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          gap: width > sm ? 16 : 8,
        }}
      >
        <View
          style={{
            flex: 5,
          }}
        >
          <TextInput
            onChangeText={(value) =>
              onUpdateAttribute({ ...attribute, name: value })
            }
            autoCapitalize="none"
            value={attribute.name}
          />
        </View>
        <View style={{ flex: 3, display: 'flex', position: 'relative' }}>
          {width > sm && (
            <View
              style={{
                position: 'absolute',
                backgroundColor: labelColors[attribute.label_color].text,
                borderRadius: 10,
                height: 20,
                width: 20,
                zIndex: 10,
                left: 12,
                top: 22,
              }}
            />
          )}
          <SelectInput
            style={{ flex: 1 }}
            selectedValue={attribute.label_color}
            setValue={(value) => {
              onUpdateAttribute({ ...attribute, label_color: value })
            }}
            labelStyle={{ paddingLeft: width > sm ? 28 : 0 }}
            items={labelColorItems}
          />
        </View>
        <View
          style={{
            display: 'flex',
            flex: 2,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Switch
            onValueChange={() =>
              onUpdateAttribute({
                ...attribute,
                display_in_list: !attribute.display_in_list,
              })
            }
            value={attribute.display_in_list}
          />
        </View>
        {width > md && (
          <View
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              buttonStyle={{
                padding: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
              }}
              icon={
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  size={24}
                  color={Colors.caution}
                />
              }
              text=""
              onPress={onDeleteAttribute}
            />
          </View>
        )}
      </View>
      {width < md && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 8,
          }}
        >
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={onDeleteAttribute}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              size={16}
              color={Colors.caution}
            />
            <Text
              style={{
                color: Colors.caution,
                marginLeft: 8,
                fontSize: 14,
              }}
            >
              {t('この項目を削除')}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

export function CustomerAttributes({
  restaurantId,
  onClose,
}: {
  restaurantId: number
  onClose?: () => void
}) {
  const { width, sm, md } = useResponsive()
  const navigation = useNavigation()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null || restaurant.reservation_book_plan_type === 'free'

  const token = useToken()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data, mutate } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const [isHelp, setIsHelp] = useState<NodeJS.Timeout | undefined>()

  const [attributes, setAttributes] = useFormState<EditingAttribute[]>(
    data ?? []
  )
  const [deletedAttributes, setDeletedAttributes] = useState<Attribute[]>([])
  const isValid = useMemo(
    () => !(attributes ?? []).map((a) => a.name !== '').includes(false),
    [attributes]
  )

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: t('お客様属性'),
    })
  }, [navigation])

  const handleClose = () => {
    setIsHelp(undefined)
    clearTimeout(isHelp)
  }

  const handleOpen = () => {
    const timeId = setTimeout(() => handleClose(), 3000)
    setIsHelp(timeId)
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    const params: UpdateAttributesParams = {
      restaurant: {
        customer_custom_attributes_attributes: [
          ...attributes.map((attribute) => {
            return {
              id: attribute.id,
              name: attribute.name,
              display_in_list: attribute.display_in_list,
              label_color: attribute.label_color,
            }
          }),
          ...deletedAttributes.map((attribute) => ({
            id: attribute.id,
            name: attribute.name,
            display_in_list: attribute.display_in_list,
            label_color: attribute.label_color,
            _destroy: true,
          })),
        ],
      },
    }
    try {
      await saveRestaurantAttributes(token, restaurantId, params)
      await mutate()
      displayToastSuccess(t('お客様属性を保存しました'))
      onClose?.()
    } catch (error) {
      displayToastError(t('保存に失敗しました'))
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isFreePlan || !restaurant.reservation_book_available) {
    return (
      <Unavailable
        description={t(
          'この機能は、予約台帳のスタンダードプラン以上でご利用いただけます。ご利用を希望の方は、お問い合わせフォームからご連絡ください。'
        )}
      />
    )
  }

  if (data === undefined) {
    return <Loading />
  }

  return (
    <>
      <KeyboardAwareScrollView
        contentContainerStyle={[
          { padding: width < sm ? 16 : 32 },
          Platform.OS === 'web'
            ? { backgroundColor: Colors.bgBlack }
            : undefined,
        ]}
      >
        {isHelp && (
          <TouchableOpacity
            onPress={handleClose}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          />
        )}
        {isHelp && (
          <View
            style={{
              position: 'absolute',
              top: 120,
              right: width > sm ? 120 : 16,
              width: width > sm ? 400 : 'auto',
              padding: 16,
              backgroundColor: Colors.white,
              borderRadius: 8,
              shadowColor: Colors.black,
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.1,
              zIndex: 2,
            }}
          >
            <Text>
              {t(
                '「顧客一覧に表示」をオンにすると、お客様属性が顧客一覧画面に表示されます。'
              )}
            </Text>
          </View>
        )}
        <View style={{ marginTop: 40 }}>
          <View
            style={[
              {
                borderRadius: 8,
                shadowRadius: 4,
                elevation: 4,
                shadowColor: '#000',
                shadowOpacity: 0.1,
                shadowOffset: { width: 0, height: 2 },
                backgroundColor: 'white',
                paddingHorizontal: 0,
                paddingVertical: 0,
              },
            ]}
          >
            <View style={{ paddingHorizontal: width > md ? 24 : 16 }}>
              <View
                style={{
                  flexDirection: 'row',
                  gap: width > sm ? 16 : 8,
                  borderBottomWidth: 1,
                  borderColor: Colors.border,
                  paddingVertical: 16,
                }}
              >
                <Text style={{ flex: 5, fontWeight: '600', fontSize: 14 }}>
                  {t('お客様属性')}
                </Text>
                <Text style={{ flex: 3, fontWeight: '600', fontSize: 14 }}>
                  {t('ラベルカラー')}
                </Text>
                <View
                  style={{
                    position: 'relative',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: width < sm ? 'flex-end' : 'center',
                    gap: 4,
                    flex: 2,
                  }}
                >
                  <Text style={{ fontWeight: '600', fontSize: 14 }}>
                    {width > sm ? t('顧客一覧に表示') : t('一覧表示')}
                  </Text>
                  <TouchableOpacity onPress={handleOpen}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      size={16}
                      color={Colors.primary}
                    />
                  </TouchableOpacity>
                </View>
                {width > md && (
                  <Text
                    style={{
                      flex: 1,
                      fontWeight: '600',
                      fontSize: 14,
                      textAlign: 'center',
                    }}
                  >
                    {t('削除')}
                  </Text>
                )}
              </View>
            </View>
            {attributes != null &&
              attributes.map((attribute, index) => (
                <View
                  key={index}
                  style={{
                    borderTopWidth: index === 0 ? 0 : 0.5,
                    borderColor: Colors.border,
                    paddingTop: 16,
                    marginBottom: 16,
                    paddingHorizontal: width > md ? 24 : 16,
                  }}
                >
                  <AttributeTableRow
                    attribute={attribute}
                    onUpdateAttribute={(attribute) => {
                      const newAttributes = [...attributes]
                      newAttributes[index] = attribute
                      setAttributes(newAttributes)
                    }}
                    onDeleteAttribute={() => {
                      const newAttributes = [...attributes]
                      const deletedAttribute = newAttributes[index]
                      if (deletedAttribute.id != null) {
                        setDeletedAttributes([
                          ...deletedAttributes,
                          deletedAttribute as Attribute,
                        ])
                      }
                      newAttributes.splice(index, 1)
                      setAttributes(newAttributes)
                    }}
                  />
                </View>
              ))}
            <View
              style={{
                paddingHorizontal: 24,
                marginBottom: 24,
              }}
            >
              <Button
                buttonStyle={{
                  marginTop: 32,
                  height: 48,
                  borderColor: Colors.primary,
                  backgroundColor: 'transparent',
                }}
                textStyle={{
                  color: Colors.primary,
                  fontWeight: 'normal',
                }}
                onPress={() => {
                  setAttributes([
                    ...attributes,
                    {
                      display_in_list: true,
                      name: '',
                      label_color: 'gray',
                    },
                  ])
                }}
                text={t('新しいお客様属性を追加する')}
              />
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
      <View
        style={{
          backgroundColor: Colors.white,
          borderTopWidth: 1,
          borderTopColor: Colors.border,
          height: 96,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={!isValid}
          loading={isSubmitting}
          buttonStyle={{
            marginTop: 16,
            width: 320,
            height: 48,
          }}
          onPress={() => onSubmit()}
          text={t('保存する')}
        />
      </View>
    </>
  )
}
