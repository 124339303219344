import useSWR, { fetcher, onError, swrKey } from 'modules/swr'
import dayjs from 'dayjs'
import { useToken } from './Auth'
import { Restaurant } from './Restaurant'
import { useMemo } from 'react'
import { TableOrder } from '@hello-ai/for_r_app/src/models/TableOrder'
import { flatten } from 'lodash'
import { useSWRInfiniteBatch } from 'modules/swr/infinite'

import {
  SalesByMenu,
  SalesByCategory,
  SalesByItem,
  SalesParams,
  SalesByPayment,
} from '@hello-ai/ar_shared/src/types/ForR/Sales'
export * from '@hello-ai/ar_shared/src/types/ForR/Sales'

export function getFetchPeriodParams({
  start,
  end,
}: {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
}): { gte: string; lte: string } {
  return {
    gte: start.toISOString(),
    lte: end.toISOString(),
  }
}

export interface SalesDashboardData {
  total: {
    amount: number
  }
  segment_totals: Array<{ id: string; amount: number }>
  data: Array<{
    date: string
    time: string
    amount: number
    application_fee_amount: number
    gross_profit_amount: number
    gross_profit_ratio: number
    amount_delta: number
    amount_per_customer: number
  }>
}

interface SalesDashboardParams {
  completed_at: {
    gte: string
    lte: string
  }
  segments: string[] | null
}

type TableOrderSummary = Pick<
  TableOrder,
  | 'id'
  | 'completed_at'
  | 'recorded_at'
  | 'total_amount'
  | 'payment_method'
  | 'users'
  | 'status'
  | 'current_status_changed_at'
> & {
  table_seat: Pick<TableOrder['table_seat'], 'id' | 'name'>
}

export interface Sale {
  date: string
  total_amount: number
  total_amount_before_tax: number
  transfer_amount: number
  total_amount_components: Array<{
    name: string
    amount: number
    amount_before_tax: number
  }>
  table_orders: TableOrderSummary[]
}

export const useRestaurantSales = (
  restaurantId: Restaurant['id'],
  date: dayjs.Dayjs
) => {
  const token = useToken()
  const { data, error, size, setSize, isValidating, isLoading, mutate } =
    useSWRInfiniteBatch<Sale[]>(
      (pageIndex) => {
        return swrKey(token, `/restaurants/${restaurantId}/sales`, {
          completed_at: getFetchPeriodParams({
            start: date.subtract(pageIndex, 'day'),
            end: date.subtract(pageIndex, 'day'),
          }),
        })
      },
      (([token, url, serializedParams]: [
        token: string,
        url: string,
        serializedParams: string,
      ]) => fetcher([token, url, JSON.parse(serializedParams)])) as any
    )

  const sales = useMemo(() => data && flatten(flatten(data)), [data])

  return {
    sales,
    size,
    setSize,
    error,
    isValidating,
    isLoading,
    mutate,
  }
}

export interface SaleByPaymentMethod {
  table_order: {
    id: TableOrder['id']
    recorded_at: NonNullable<TableOrder['recorded_at']>
    completed_at: NonNullable<TableOrder['completed_at']>
  }
  payment_method: string
  amount: number
}

export const useRestaurantSalesByPaymentMethod = (
  restaurantId: Restaurant['id'],
  date: dayjs.Dayjs
) => {
  const token = useToken()
  const { data, error, size, setSize, isValidating, isLoading, mutate } =
    useSWRInfiniteBatch<SaleByPaymentMethod[]>(
      (pageIndex) => {
        return swrKey(
          token,
          `/restaurants/${restaurantId}/sales/by_payment_method`,
          {
            completed_at: getFetchPeriodParams({
              start: date.subtract(pageIndex, 'day'),
              end: date.subtract(pageIndex, 'day'),
            }),
          }
        )
      },
      (([token, url, serializedParams]: [
        token: string,
        url: string,
        serializedParams: string,
      ]) => fetcher([token, url, JSON.parse(serializedParams)])) as any
    )

  const salesByPaymentMethod = useMemo(
    () => data && flatten(flatten(data)),
    [data]
  )

  return {
    salesByPaymentMethod,
    size,
    setSize,
    error,
    isValidating,
    isLoading,
    mutate,
  }
}

export function useRestaurantSalesDashboard(
  restaurantId: Restaurant['id'],
  type: string,
  params: SalesDashboardParams
) {
  const token = useToken()
  const {
    data: salesData,
    error,
    mutate,
  } = useSWR<SalesDashboardData, any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/sales_dashboard/${type}`,
      params
    ),
    ([token, url]) => fetcher<SalesDashboardData>([token, url, params])
  )

  return {
    salesData,
    error,
    mutate,
  }
}

export function useRestaurantSalesByItem(
  restaurantId: number,
  params: SalesParams
) {
  const token = useToken()
  const {
    data: salesByItem,
    error,
    mutate,
  } = useSWR<SalesByItem[], any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/sales_dashboard/product_sales`,
      params
    ),
    ([token, url]) => fetcher<SalesByItem[]>([token, url, params])
  )

  if (error != null) {
    onError(error)
  }

  return {
    salesByItem: salesByItem || [],
    error,
    mutate,
  }
}

export function useRestaurantSalesByCategory(
  restaurantId: number,
  params: SalesParams
) {
  const token = useToken()
  const {
    data: salesByCategory,
    mutate,
    error,
  } = useSWR<SalesByCategory[], any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/sales_dashboard/menu_pages`,
      params
    ),
    ([token, url]) => fetcher<SalesByCategory[]>([token, url, params])
  )

  if (error != null) {
    onError(error)
  }

  return {
    salesByCategory: salesByCategory || [],
    error,
    mutate,
  }
}

export function useRestaurantSalesByMenu(
  restaurantId: number,
  params: SalesParams
) {
  const token = useToken()
  const {
    data: salesByMenu,
    mutate,
    error,
  } = useSWR<SalesByMenu[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/sales_dashboard/menus`, params),
    ([token, url]) => fetcher<SalesByMenu[]>([token, url, params])
  )

  if (error != null) {
    onError(error)
  }

  return {
    salesByMenu: salesByMenu || [],
    error,
    mutate,
  }
}

export function useRestaurantSalesByPayment(
  restaurantId: number,
  params: SalesParams
) {
  const token = useToken()
  const {
    data: salesByPayment,
    error,
    mutate,
  } = useSWR<SalesByPayment[], any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/sales_dashboard/payment_methods`,
      params
    ),
    ([token, url]) => fetcher<SalesByPayment[]>([token, url, params])
  )

  return {
    salesByPayment: useMemo(() => salesByPayment ?? [], [salesByPayment]),
    error,
    mutate,
  }
}
