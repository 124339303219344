import React, { useMemo } from 'react'
import { View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs, { Dayjs } from '@hello-ai/ar_shared/src/modules/dayjs'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { weekDayNames } from 'react-native-calendars/src/dateutils'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { useCalendarContext } from './CalendarContext'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

function CheckboxIcon({ status }: { status: 'nothing' | 'some' | 'all' }) {
  return (
    <View
      style={{
        width: 16,
        height: 16,
        backgroundColor: status === 'all' ? Colors.accent : 'white',
        borderRadius: 2,
        borderWidth: 2,
        borderColor: status === 'all' ? Colors.accent : Colors.black60,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {status === 'all' && (
        <FontAwesomeIcon icon={faCheck} size={12} color="white" />
      )}
      {status === 'some' && (
        <View
          style={{ width: 10, height: 4, backgroundColor: Colors.accent }}
        />
      )}
    </View>
  )
}

/**
 * 提供しない日にちを曜日ごとに分類する
 * @param selectedDays
 */
function getUnpublishedDayOfWeek(
  visibleMonth: Dayjs,
  unpublishedDays: Dayjs[]
) {
  const selectedDaysOfWeek = unpublishedDays.reduce((acc, day) => {
    if (visibleMonth.month() !== day.month()) return acc
    const dayOfWeek = day.day()
    if (!acc.has(dayOfWeek)) {
      acc.set(dayOfWeek, new Set())
    }
    acc.get(dayOfWeek)!.add(day)
    return acc
  }, new Map<number, Set<Dayjs>>())
  return selectedDaysOfWeek
}

/**
 * 指定した月にそれぞれの曜日が何週あるかを取得する
 */
function getWeekCountInMonth(visibleMonth: Dayjs) {
  let currentDate = visibleMonth.startOf('month').date()
  const lastDate = visibleMonth.endOf('month').date()
  const result = Array.from({ length: 7 }, () => 0)
  while (currentDate <= lastDate) {
    const dayOfWeek = dayjs(visibleMonth).date(currentDate).day()
    result[dayOfWeek]++
    currentDate++
  }
  return result
}

/**
 * 指定した曜日に提供しない日が含まれているか
 * @param selectedDaysOfWeek
 * @returns
 */
function isContainUnpublishedOfWeek(
  unpublishedDayOfWeek: Map<number, Set<Dayjs>>,
  dayOfWeek: number
) {
  const unpublishedDays = unpublishedDayOfWeek.get(dayOfWeek)
  return (unpublishedDays?.size ?? 0) > 0
}

function SelectAll() {
  const { visibleMonth, unpublishedDays, onPressAllInMonth } =
    useCalendarContext()
  const unpublishedDaysInMonth = useMemo(() => {
    return unpublishedDays.filter((d) => d.isSame(visibleMonth, 'month'))
  }, [unpublishedDays, visibleMonth])

  const status =
    unpublishedDaysInMonth.length === 0
      ? 'all'
      : unpublishedDaysInMonth.length === visibleMonth.daysInMonth()
        ? 'nothing'
        : 'some'

  return (
    <TouchableOpacity
      style={{ flexDirection: 'row', alignItems: 'center', columnGap: 4 }}
      onPress={() => {
        const nextState = unpublishedDaysInMonth.length !== 0
        onPressAllInMonth(nextState)
      }}
    >
      <CheckboxIcon status={status} />
      <Text style={{ fontSize: 18 }}>{t('すべて選択')}</Text>
    </TouchableOpacity>
  )
}

function SelectRestaurantCourse() {
  const {
    restaurantCourses,
    selectedRestaurantCourse,
    setSelectedRestaurantCourse,
  } = useCalendarContext()

  const items = restaurantCourses.map((course) => ({
    label: course.title,
    value: course.id,
  }))

  return (
    <SelectInput
      items={items}
      selectedValue={selectedRestaurantCourse.id}
      setValue={(courseId) => {
        const course = restaurantCourses.find(
          (course) => course.id === courseId
        )
        if (course != null) {
          setSelectedRestaurantCourse(course)
        }
      }}
    />
  )
}

const WeekDayNames = [
  t('日'),
  t('月'),
  t('火'),
  t('水'),
  t('木'),
  t('金'),
  t('土'),
]

export default function CalendarHeader() {
  const { width, sm } = useResponsive()
  const { unpublishedDays, visibleMonth, setVisibleMonth, onPressWeek } =
    useCalendarContext()
  const weekCountInMonth = getWeekCountInMonth(visibleMonth)
  const unpublishedDayOfWeek = getUnpublishedDayOfWeek(
    visibleMonth,
    unpublishedDays
  )

  const disablePrevMonth = visibleMonth.isSame(dayjs(), 'month')
  const disableNextMonth = dayjs().add(6, 'month').isSame(visibleMonth, 'month')

  const onPressPrevMonth = () => {
    setVisibleMonth(visibleMonth.subtract(1, 'month'))
  }
  const onPressNextMonth = () => {
    setVisibleMonth(visibleMonth.add(1, 'month'))
  }

  return (
    <View style={{ height: width >= sm ? 112 : 172 }}>
      <View
        style={{
          paddingVertical: width >= sm ? 14 : 12,
          paddingHorizontal: width >= sm ? 16 : 12,
          flexDirection: width >= sm ? 'row' : 'column-reverse',
          columnGap: 12,
          rowGap: 24,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            columnGap: width >= sm ? 16 : 4,
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={onPressPrevMonth}
            disabled={disablePrevMonth}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              opacity: disablePrevMonth ? 0.4 : 1,
            }}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              size={24}
              color={Colors.primary}
            />
            {width >= sm && (
              <Text style={{ color: Colors.primary, fontSize: 14 }}>
                {t('先月')}
              </Text>
            )}
          </TouchableOpacity>
          <View
            style={{
              width: 180,
              height: 46,
              borderRadius: 24,
              backgroundColor: Colors.primaryBg,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
                color: Colors.primary,
              }}
            >
              {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
              {visibleMonth.tz().format('YYYY年MM月')}
            </Text>
          </View>
          <TouchableOpacity
            onPress={onPressNextMonth}
            disabled={disableNextMonth}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              opacity: disableNextMonth ? 0.4 : 1,
            }}
          >
            {width >= sm && (
              <Text style={{ color: Colors.primary, fontSize: 14 }}>
                {t('翌月')}
              </Text>
            )}
            <FontAwesomeIcon
              icon={faChevronRight}
              size={24}
              color={Colors.primary}
            />
          </TouchableOpacity>
          {width < sm && (
            <View
              style={{
                marginLeft: 8,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <SelectAll />
            </View>
          )}
        </View>
        {width >= sm && <View style={{ flex: 1 }} />}
        <View style={{ width: width >= sm ? 360 : undefined, height: 48 }}>
          <SelectRestaurantCourse />
        </View>
        {width >= sm && <SelectAll />}
      </View>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        {WeekDayNames.map((day: string, index: number) => {
          const weekSelected = !isContainUnpublishedOfWeek(
            unpublishedDayOfWeek,
            index
          )
          const weekCount = weekCountInMonth[index]
          const unpublishedDaysOfWeek = unpublishedDayOfWeek.get(index)
          const unpublishedDayOfWeekSize = unpublishedDaysOfWeek?.size ?? 0

          const status =
            unpublishedDayOfWeekSize === 0
              ? 'all'
              : unpublishedDayOfWeekSize === weekCount
                ? 'nothing'
                : 'some'
          return (
            <TouchableOpacity
              key={index}
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 8,
                flex: 1,
                backgroundColor: width >= sm ? Colors.bgBlack : undefined,
              }}
              onPress={() => {
                onPressWeek(index, !weekSelected)
              }}
            >
              <CheckboxIcon status={status} />
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontWeight: '600',
                    lineHeight: 27,
                  },
                  index === 0 && { color: Colors.caution },
                  index === 6 && { color: '#2C67C0' },
                ]}
              >
                {day}
              </Text>
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
}
