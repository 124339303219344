import React, { useEffect, useState } from 'react'
import { UpdateProductOptionStockFuncType } from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'
import {
  ToggleGroup,
  ToggleItem,
} from '@hello-ai/ar_shared/src/components/Toggle'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

export function StockToggle({
  productOptionId,
  productOptionInStock,
  onChangeInStock,
}: {
  productOptionId: string
  productOptionInStock: boolean
  onChangeInStock: UpdateProductOptionStockFuncType
}) {
  const prevInStock = usePrevious(productOptionInStock)

  const [inStock, setInStock] = useState(productOptionInStock)

  const { width, sm } = useResponsive()

  useEffect(() => {
    if (prevInStock !== productOptionInStock) {
      setInStock(productOptionInStock)
    }
  }, [prevInStock, productOptionInStock])

  return (
    <ToggleGroup
      value={inStock}
      onValueChange={async (value: boolean) => {
        if (value === inStock) {
          return
        }
        setInStock(value)
        onChangeInStock(productOptionId, value)
      }}
      style={[{ height: 48 }]}
    >
      <ToggleItem
        value
        style={[
          {
            width: 134,
            paddingHorizontal: 0,
          },
        ]}
        textStyle={{ fontSize: 16 }}
        borderColor={Colors.accent}
        color="white"
        textColor={Colors.black}
        selectedBorderColor={Colors.accent}
        selectedColor={Colors.accent}
        selectedTextColor="white"
        text={t('注文可能')}
      />
      <ToggleItem
        value={false}
        style={[
          {
            width: 134,
            paddingHorizontal: 0,
          },
        ]}
        textStyle={{ fontSize: 16 }}
        borderColor={Colors.caution}
        color="white"
        textColor={Colors.caution}
        selectedBorderColor={Colors.caution}
        selectedColor={Colors.caution}
        selectedTextColor="white"
        text={t('本日売り切れ')}
      />
    </ToggleGroup>
  )
}
