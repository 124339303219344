import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { setHeader, wrapAspidaResponse } from '../modules/axios'
import useSWR from '../modules/swr'
import { client } from '../modules/api'
import { createAspidaSWRParameters } from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { Methods as CreateMethods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/restaurant_courses/_restaurant_course_id@number/restaurant_course_business_times/bulk_create'
import { Methods as DeleteMethods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/restaurant_courses/_restaurant_course_id@number/restaurant_course_business_times/bulk_delete'

type CreateParam = CreateMethods['post']['reqBody']
type DeleteParam = DeleteMethods['delete']['reqBody']

export function useRestaurantCourseBusinessTime(
  restaurantId: number,
  courseId: number | undefined
) {
  const token = useToken()
  setHeader({ token })
  const { data, mutate, error } = useSWR(
    ...createAspidaSWRParameters(
      courseId != null
        ? client.restaurants
            ._restaurant_id(restaurantId)
            .restaurant_courses._restaurant_course_id(courseId)
            .restaurant_course_business_times
        : null
    )
  )

  return {
    restaurantCourseBusinessTime: data,
    mutate,
    error,
  }
}

export async function createRestaurantCourseBusinessTime(
  token: string,
  restaurantId: number,
  courseId: number,
  param: CreateParam
) {
  setHeader({ token })
  return await wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .restaurant_courses._restaurant_course_id(courseId)
      .restaurant_course_business_times.bulk_create.post({ body: param })
  )
}

export async function deleteRestaurantCourseBusinessTime(
  token: string,
  restaurantId: number,
  courseId: number,
  param: DeleteParam
) {
  setHeader({ token })
  return await wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .restaurant_courses._restaurant_course_id(courseId)
      .restaurant_course_business_times.bulk_delete.delete({ body: param })
  )
}
