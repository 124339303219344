import {
  faAngleRight,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { LinkOpacity } from '@hello-ai/ar_shared/src/components/Link'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import logo from 'assets/images/logo.png'
import { StepperList } from 'components/SignUp/Stepper'
import config from '@hello-ai/for_r_app/src/modules/config'
import React, { Fragment, useRef, useState } from 'react'
import { View } from 'react-native'
import { useHover } from 'react-native-web-hooks'

interface SignUpHeaderProps {
  currentStep: number
}

export function SingUpHeader({ currentStep }: SignUpHeaderProps) {
  const { width, md, sm } = useResponsive()

  return (
    <View
      style={[
        {
          flexDirection: width < sm ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: width > md ? 24 : 8,
          paddingVertical: width < sm ? 8 : 14,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowRadius: 8,
          shadowOpacity: 0.06,
          elevation: 8,
          zIndex: 2,
        },
        width < sm && {
          gap: 12,
        },
      ]}
    >
      <LinkOpacity
        to="https://autoreserve.com/ja/for_restaurants"
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
          gap: 16,
        }}
      >
        <Image
          style={{
            width: (1995 / 768) * 21,
            height: 21,
          }}
          source={{ uri: logo }}
        />
        <Text
          style={{
            color: Colors.black60,
            verticalAlign: 'bottom',
            fontSize: width < sm ? 12 : 14,
            fontWeight: '600',
            lineHeight: 21,
          }}
        >
          {t('アカウント開設')}
        </Text>
      </LinkOpacity>
      <StepperList
        steps={[
          {
            stepNumber: 1,
            text: t('ログイン情報の登録'),
          },
          {
            stepNumber: 2,
            text: t('電話番号の登録'),
          },
          {
            stepNumber: 3,
            text: t('オーナー情報の登録'),
          },
        ]}
        currentStepNumber={currentStep}
      />
    </View>
  )
}

export default function SignInHeader() {
  const arWebOrigin = config.isStaging
    ? 'https://staging.autoreserve.com'
    : 'https://autoreserve.com'

  const serviceViewRef = useRef<View>(null)
  const isHoveredServiceView = useHover(serviceViewRef)

  const [isVisibleSpHeaderMenu, setIsVisibleSpHeaderMenu] =
    useState<boolean>(false)

  const { width, md } = useResponsive()

  return (
    <View
      style={{
        zIndex: 2,
      }}
    >
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: width > md ? 24 : 8,
            height: width > md ? 76 : 64,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowRadius: 8,
            shadowOpacity: 0.06,
            elevation: 8,
            zIndex: 3,
          },
          isVisibleSpHeaderMenu && { paddingBottom: 0 },
          width <= md && { paddingLeft: 16 },
        ]}
      >
        <LinkOpacity to="https://autoreserve.com/ja/for_restaurants">
          <Image
            style={{
              width: (1995 / 768) * 21,
              height: 21,
            }}
            source={{ uri: logo }}
          />
        </LinkOpacity>

        {width >= md ? (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <View
              ref={serviceViewRef}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                height: '100%',
                cursor: 'pointer',
              }}
            >
              <Text style={{ fontWeight: '600', fontSize: 14, marginRight: 4 }}>
                {t('サービス')}
              </Text>
              <FontAwesomeIcon
                icon={isHoveredServiceView ? faChevronUp : faChevronDown}
                size={10}
                color={Colors.black}
              />
              <View
                style={{
                  display: isHoveredServiceView ? 'flex' : 'none',
                  flexDirection: 'column',
                  rowGap: 16,
                  position: 'absolute',
                  top: 42,
                  width: 190,
                  padding: 24,
                  backgroundColor: 'white',
                  borderRadius: 8,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 0,
                  },
                  shadowRadius: 16,
                  shadowOpacity: 0.06,
                  elevation: 8,
                }}
              >
                <LinkOpacity
                  to={`${arWebOrigin}/for_restaurants/pos`}
                  target={
                    window.location.origin === arWebOrigin
                      ? undefined
                      : '_blank'
                  }
                >
                  <Text style={{ fontWeight: '600', fontSize: 14 }}>
                    {t('POSレジ')}
                  </Text>
                </LinkOpacity>
                <LinkOpacity
                  to={`${arWebOrigin}/for_restaurants/qr_order`}
                  target={
                    window.location.origin === arWebOrigin
                      ? undefined
                      : '_blank'
                  }
                >
                  <Text style={{ fontWeight: '600', fontSize: 14 }}>
                    {t('セルフオーダー')}
                  </Text>
                </LinkOpacity>
                <LinkOpacity
                  to={`${arWebOrigin}/for_restaurants/reservation_book`}
                  target={
                    window.location.origin === arWebOrigin
                      ? undefined
                      : '_blank'
                  }
                >
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: 14,
                    }}
                  >
                    {t('予約台帳')}
                  </Text>
                </LinkOpacity>
                <LinkOpacity
                  to={`${arWebOrigin}/online_store`}
                  target={
                    window.location.origin === arWebOrigin
                      ? undefined
                      : '_blank'
                  }
                >
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: 14,
                    }}
                  >
                    {t('オンラインストア')}
                  </Text>
                </LinkOpacity>
                <LinkOpacity
                  to={`${arWebOrigin}/for_restaurants/about`}
                  target={
                    window.location.origin === arWebOrigin
                      ? undefined
                      : '_blank'
                  }
                >
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: 14,
                    }}
                  >
                    {t('ネット予約')}
                  </Text>
                </LinkOpacity>
              </View>
            </View>
            <LinkOpacity
              to={`${arWebOrigin}/for_restaurants/cases`}
              target={
                window.location.origin === arWebOrigin ? undefined : '_blank'
              }
              style={{ marginLeft: 24 }}
            >
              <Text style={{ fontWeight: '600', fontSize: 14 }}>
                {t('導入事例')}
              </Text>
            </LinkOpacity>
            <LinkOpacity
              to="https://help-for-restaurants.autoreserve.com"
              target="_blank"
              style={{ marginLeft: 24 }}
            >
              <Text style={{ fontWeight: '600', fontSize: 14 }}>
                {t('ヘルプセンター')}
              </Text>
            </LinkOpacity>
            <LinkOpacity
              to={`${arWebOrigin}/for_restaurants/document_request`}
              style={{ marginLeft: 24 }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 14,
                }}
              >
                {t('資料請求')}
              </Text>
            </LinkOpacity>
            <LinkOpacity
              to={`${arWebOrigin}/for_restaurants/contact`}
              style={{ marginLeft: 24 }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 14,
                }}
              >
                {t('お問い合わせ')}
              </Text>
            </LinkOpacity>
            <LinkOpacity
              to="/sign_in"
              style={{
                marginLeft: 32,
                borderRadius: 22,
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderWidth: 1,
                borderColor: Colors.primary,
              }}
            >
              <Text
                style={{
                  color: Colors.primary,
                  fontSize: 14,
                  fontWeight: '600',
                }}
              >
                {t('ログイン')}
              </Text>
            </LinkOpacity>
            <LinkOpacity
              to="/sign_up"
              style={{
                marginLeft: 16,
                backgroundColor: Colors.primary,
                borderRadius: 22,
                paddingVertical: 10,
                paddingHorizontal: 20,
              }}
            >
              <Text
                style={{
                  color: Colors.white,
                  fontSize: 14,
                  fontWeight: '600',
                }}
              >
                {t('無料アカウント開設')}
              </Text>
            </LinkOpacity>
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              columnGap: 10,
              alignItems: 'center',
            }}
          >
            <TouchableOpacity
              hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
              onPress={() => setIsVisibleSpHeaderMenu(!isVisibleSpHeaderMenu)}
            >
              <View style={{ marginRight: 16 }}>
                <FontAwesomeIcon
                  icon={faBars}
                  size={20}
                  color={Colors.black40}
                />
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
      {isVisibleSpHeaderMenu && (
        <View style={{ backgroundColor: 'white' }}>
          <View
            style={{ paddingHorizontal: 16, paddingVertical: 24, rowGap: 24 }}
          >
            <Button
              href="/sign_up"
              style={{ paddingVertical: 12 }}
              textStyle={{ fontSize: 14, lineHeight: 21 }}
            >
              {t('無料アカウント開設')}
            </Button>
            <Button
              href="/sign_in"
              mode="outline"
              style={{ paddingVertical: 12 }}
              textStyle={{ fontSize: 14, lineHeight: 21 }}
            >
              {t('ログイン')}
            </Button>
          </View>
          {[
            {
              subTitle: t('サービス'),
              links: [
                {
                  url: `${arWebOrigin}/for_restaurants/pos`,
                  text: t('POS'),
                },
                {
                  url: `${arWebOrigin}/for_restaurants/qr_order`,
                  text: t('セルフオーダー'),
                },
                {
                  url: `${arWebOrigin}/for_restaurants/reservation_book`,
                  text: t('予約台帳'),
                },
                {
                  url: `${arWebOrigin}/for_restaurants/contact`,
                  text: t('テイクアウト'),
                },
                { url: `${arWebOrigin}/online_store`, text: t('お取り寄せ') },
              ],
            },
            {
              subTitle: t('その他'),
              links: [
                {
                  url: `${arWebOrigin}/for_restaurants/cases`,
                  text: t('導入事例'),
                },
                {
                  url: 'https://help-for-restaurants.autoreserve.com',
                  text: t('ヘルプセンター'),
                  target: '_blank',
                },
                {
                  url: `${arWebOrigin}/for_restaurants/document_request`,
                  text: t('資料請求'),
                },
                {
                  url: `${arWebOrigin}/for_restaurants/contact`,
                  text: t('お問い合わせ'),
                },
              ],
            },
          ].map(({ subTitle, links }, index) => (
            <Fragment key={index}>
              <Text
                style={{
                  backgroundColor: Colors.bgBlack,
                  fontSize: 14,
                  fontWeight: '600',
                  color: Colors.black,
                  lineHeight: 21,
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  borderTopWidth: 0.5,
                  borderColor: Colors.border,
                }}
              >
                {subTitle}
              </Text>
              {links.map(({ url, text, target }, index) => (
                <LinkOpacity
                  key={index}
                  to={url}
                  target={target}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTopWidth: 0.5,
                    borderColor: Colors.border,
                    paddingRight: 16,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '600',
                      paddingVertical: 24,
                      paddingHorizontal: 24,
                    }}
                  >
                    {text}
                  </Text>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    color={Colors.black40}
                    size={16}
                  />
                </LinkOpacity>
              ))}
            </Fragment>
          ))}
        </View>
      )}
    </View>
  )
}
