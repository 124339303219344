import { fetcher, swrKey, useStickySWR } from '../modules/swr'

import { TableMenuPage } from '@hello-ai/ar_shared/src/types/ForR/TableMenuPage'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { setHeader } from '../modules/axios'
import useSWR from 'swr'
export * from '@hello-ai/ar_shared/src/types/ForR/TableMenuPage'

export function useTableMenuPages({
  restaurantId,
  params,
}: {
  restaurantId: number
  params?: { per_page?: number; status?: string }
}) {
  const token = useToken()
  const {
    data: tableMenuPages,
    error,
    mutate,
  } = useStickySWR<TableMenuPage[], any, ReturnType<typeof swrKey>>( // あとで検索入れた時用にstickyにしておく
    swrKey(token, `/restaurants/${restaurantId}/menu_pages`, params),
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string,
    ]) => fetcher([token, url, JSON.parse(params)])
  )

  return {
    tableMenuPages: tableMenuPages ?? [],
    isLoading: tableMenuPages == null && error == null,
    error,
    mutate,
  }
}

type ProductsStocks = {
  id: string
  in_stock: boolean
  current_stock_quantity: number
}[]

export function useTableMenuPageProductsStocks(tableMenuPageId: string) {
  const token = useToken()
  setHeader({ token })

  const { data, error } = useSWR<
    ProductsStocks,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/menu_pages/${tableMenuPageId}/products_stocks`), fetcher)
  return {
    data,
    error,
  }
}
