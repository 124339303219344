import React from 'react'
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

import { ownerService } from 'models/Owner'
import { useAuth, useToken } from 'models/Auth'
import { onError } from 'modules/swr'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const buttonHeight = 48
const buttonWidth = 248
function useCurrentOwner() {
  const { auth } = useAuth()
  return ownerService.useGetOwner(
    auth === undefined
      ? undefined
      : {
          id: auth.id,
        }
  )
}

function FormSection({
  style,
  title,
  children,
}: {
  style?: StyleProp<ViewStyle>
  title: String
  children?: React.ReactNode
}) {
  return (
    <View style={style}>
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
      <ShadowBox style={{ marginTop: 22 }}>{children}</ShadowBox>
    </View>
  )
}

const formGroupMargin = 28
export default function AccountEdit() {
  const token = useToken()
  const { data: owner } = useCurrentOwner()
  const [name, setName] = useFormState(owner?.name ?? '')
  const [username, setUsername] = useFormState(owner?.username ?? '')

  if (owner === undefined) {
    return <Loading />
  }

  const onPress = async () => {
    if (owner.name !== name) {
      const { error } = await ownerService.updateOwner(token, {
        name: { value: name },
      })
      if (error != null) {
        onError(error)
        return
      }
    }
    if (owner.username !== username) {
      const { error } = await ownerService.changeUsername(token, {
        username,
      })
      if (error != null) {
        onError(error)
        return
      }
    }
    displayToastInfo(t('オーナー情報を更新しました'))
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <FormSection title={t('オーナー情報')}>
        <FormGroup formLabel={<FormLabel value={t('オーナー名')} required />}>
          <TextInput onChangeText={setName} value={name} />
        </FormGroup>
        <FormGroup
          style={{ marginTop: formGroupMargin }}
          formLabel={<FormLabel value={t('オーナーID')} required />}
        >
          <TextInput onChangeText={setUsername} value={username} />
          <Text
            style={{
              marginTop: 16,
              fontSize: 14,
              lineHeight: 18,
            }}
          >
            {t(
              '※オーナーIDは、所属するメンバー全員がログインに使用します。頻繁な変更はおすすめしません。'
            )}
          </Text>
        </FormGroup>
      </FormSection>
      <View
        style={{
          marginTop: 48,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button onPress={onPress} height={buttonHeight} width={buttonWidth}>
          {t('保存する')}
        </Button>
      </View>
    </ScrollView>
  )
}
