import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import React from 'react'
import PaymentIndex from 'pages/Settings/Payment/index'
import PaymentEdit from 'pages/Settings/Payment/Edit'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  PaymentIndex: {
    title: t('支払い方法'),
    fullPath: '/settings/payment',
    relativePath: '/',
    exact: true,
    element: <PaymentIndex />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
  PaymentEdit: {
    title: t('支払い方法の変更'),
    fullPath: '/settings/payment/edit',
    relativePath: '/edit',
    exact: true,
    element: <PaymentEdit />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
}

export default function SettingsStack() {
  return <Stack routes={routes} />
}
