import React, { useRef } from 'react'
import { createLinkComponent } from './Link'
import { useHover } from 'react-native-web-hooks'

import { InjectedProps } from './types'
import { Text } from '../Text'
import { Colors } from '../../constants/Colors'
import { mergeRefs } from '../../modules/mergeRefs'

type LinkTextProps = React.ComponentPropsWithoutRef<typeof Text> & InjectedProps

const TextLink = createLinkComponent<Text, LinkTextProps>(Text)

export const LinkText = React.forwardRef<Text, LinkTextProps>(
  ({ style, ...props }: LinkTextProps, hostRef) => {
    const ref = useRef<Text>(null)
    const isHovered = useHover(ref)
    return (
      <TextLink
        ref={mergeRefs([ref, hostRef])}
        style={[
          {
            // @ts-ignore
            cursor: 'pointer',
            color: Colors.black,
            fontSize: 14,
            textDecorationLine: 'none',
          },
          isHovered && {
            textDecorationLine: 'underline',
          },
          style,
        ]}
        {...props}
      />
    )
  }
)
