import React from 'react'
import { useRestaurantId } from 'modules/useRestaurantId'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { useNavigate } from 'react-router'
import SyncFailedReservationView from '@hello-ai/for_r_app/src/components/GourmetSiteSyncFailedReservationView'

export default function SyncFailedReservations() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  return (
    <>
      <SyncFailedReservationView
        restaurantId={restaurantId}
        displayToastInfo={displayToastInfo}
        onPressPromptOutboundSetting={() => {
          navigate(`/restaurants/${restaurantId}/gourmet_site/account_settings`)
        }}
      />
    </>
  )
}
