import { OwnerClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/owner/owner_service.client'
import { isAxiosError } from 'modules/axios'
import { createRpcService } from 'modules/rpc'
import useSWR, { fetcher, swrKey } from 'modules/swr'
import { useEffect } from 'react'
import { signOut, useAuth, useToken } from './Auth'

import { Owner } from '@hello-ai/ar_shared/src/types/ForR/Owner'
export * from '@hello-ai/ar_shared/src/types/ForR/Owner'

export const ownerService = createRpcService(OwnerClient)

export function useOwner(ownerId: Owner['id'] | null) {
  const token = useToken()

  const {
    data: owner,
    error,
    mutate,
  } = useSWR<Owner, any, ReturnType<typeof swrKey> | null>(
    ownerId === null ? null : swrKey(token, `/owners/${ownerId}`),
    fetcher
  )

  return {
    owner,
    error,
    mutate,
  }
}

export function useCurrentOwner() {
  const { auth } = useAuth()
  const { owner, error, mutate } = useOwner(auth === undefined ? null : auth.id)

  useEffect(() => {
    if (error != null && isAxiosError(error) && error?.response != null) {
      const status = error.response.status

      if (status >= 400 && status < 500) {
        signOut()
      }
    }
  }, [error])
  return { owner, error, mutate }
}
