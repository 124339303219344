// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service.proto" (package "auto_reserve.restaurants.restaurant_reservation_block", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantReservationBlock } from "./restaurant_reservation_block_service";
import type { CreateExceptionRequest } from "./restaurant_reservation_block_service";
import type { Empty } from "../../../google/protobuf/empty";
import type { DestroyRequest } from "./restaurant_reservation_block_service";
import type { GetRequest } from "./restaurant_reservation_block_service";
import type { UpdateRequest } from "./restaurant_reservation_block_service";
import type { RestaurantReservationBlockResource } from "./restaurant_reservation_block_resource";
import type { CreateRequest } from "./restaurant_reservation_block_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListPeriodResponse } from "./restaurant_reservation_block_service";
import type { ListPeriodRequest } from "./restaurant_reservation_block_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlock
 */
export interface IRestaurantReservationBlockClient {
  /**
   * @generated from protobuf rpc: ListPeriod(auto_reserve.restaurants.restaurant_reservation_block.ListPeriodRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse);
   */
  listPeriod(
    input: ListPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<ListPeriodRequest, ListPeriodResponse>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.restaurant_reservation_block.CreateRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantReservationBlockResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.restaurant_reservation_block.UpdateRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantReservationBlockResource>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.restaurant_reservation_block.GetRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantReservationBlockResource>;
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.restaurants.restaurant_reservation_block.DestroyRequest) returns (google.protobuf.Empty);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, Empty>;
  /**
   * @generated from protobuf rpc: CreateException(auto_reserve.restaurants.restaurant_reservation_block.CreateExceptionRequest) returns (google.protobuf.Empty);
   */
  createException(
    input: CreateExceptionRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateExceptionRequest, Empty>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlock
 */
export class RestaurantReservationBlockClient
  implements IRestaurantReservationBlockClient, ServiceInfo
{
  typeName = RestaurantReservationBlock.typeName;
  methods = RestaurantReservationBlock.methods;
  options = RestaurantReservationBlock.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListPeriod(auto_reserve.restaurants.restaurant_reservation_block.ListPeriodRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse);
   */
  listPeriod(
    input: ListPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<ListPeriodRequest, ListPeriodResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListPeriodRequest, ListPeriodResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.restaurant_reservation_block.CreateRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantReservationBlockResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, RestaurantReservationBlockResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.restaurant_reservation_block.UpdateRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantReservationBlockResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, RestaurantReservationBlockResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.restaurant_reservation_block.GetRequest) returns (auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantReservationBlockResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, RestaurantReservationBlockResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.restaurants.restaurant_reservation_block.DestroyRequest) returns (google.protobuf.Empty);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: CreateException(auto_reserve.restaurants.restaurant_reservation_block.CreateExceptionRequest) returns (google.protobuf.Empty);
   */
  createException(
    input: CreateExceptionRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateExceptionRequest, Empty> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateExceptionRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
