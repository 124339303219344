import React from 'react'
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native'
import { TouchableOpacity } from './Touchables'
import { Text } from './Text'
import { Colors } from '../constants/Colors'

interface LegacyButtonOwnProps {
  text: string
  textStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<ViewStyle>
  icon?: React.ReactNode
  onPress?: (event: GestureResponderEvent) => void
  disabled?: boolean
  hitSlop?: {
    top?: number
    left?: number
    bottom?: number
    right?: number
  }
}

type ButtonProps = LegacyButtonOwnProps &
  Omit<TouchableOpacityProps, 'onPress' | 'disabled' | 'style' | 'hitSlop'>

/**
 * @deprecated use Button
 */
export function LegacyButton({
  text,
  textStyle,
  buttonStyle,
  icon,
  onPress,
  disabled = false,
  ...props
}: ButtonProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[
        {
          borderWidth: 1,
          borderColor: Colors.primary,
          borderRadius: 4,
          height: 40,
          backgroundColor: Colors.primary,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: disabled ? 0.4 : 1,
        },
        buttonStyle,
      ]}
      {...props}
    >
      {icon}
      <Text
        style={[
          {
            color: 'white',
            fontWeight: '600',
            fontSize: 16,
            marginLeft: icon != null ? 8 : 0,
          },
          textStyle,
        ]}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
