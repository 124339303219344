import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import React from 'react'

import TableOrdersPast from 'pages/Restaurants/TableOrders/Past'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('注文'),
    fullPath: `/restaurants/:id/table_orders`,
    relativePath: '/',
    exact: true,
    element: <TableOrdersPast />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
}

export default function TableOrdersStack() {
  return <Stack routes={routes} />
}
