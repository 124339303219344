import React, { useState } from 'react'
import { View, ScrollView } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import {
  deleteFailedReservation,
  syncFailedReservations,
  useSyncFailedReservations,
} from '../models/GourmetSiteSyncReservations'
import StatusView from '@hello-ai/ar_shared/src/components/ForR/SiteController/SyncFailedReservations/StatusView'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import FailedReservationListView from '@hello-ai/ar_shared/src/components/ForR/SiteController/SyncFailedReservations/FailedListView'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useImportMailSettings } from '../models/ImportMailSettings'
import UnContractedView from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/UnContractedView'
import PromptOutboundView from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/PromptOutboundView'
import { useSupportedOutboundSettings } from '@hello-ai/for_r_app/src/models/SiteControllerOutboundSettings'
import {
  ModalCenter,
  ModalHeader,
} from '@hello-ai/ar_shared/src/components/ModalCenter'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import type { SyncFailedReservation as ISyncFailedReservation } from '@hello-ai/ar_shared/src/types/ForR/SiteControllerSeat'

interface ConfirmDeleteModalProps {
  isVisible: boolean
  onCancel: () => void
  onConfirm: () => Promise<void>
}

function ConfirmDeleteModal({
  isVisible,
  onCancel,
  onConfirm,
}: ConfirmDeleteModalProps) {
  return (
    <ModalCenter isVisible={isVisible} onClose={onCancel}>
      <View style={{ backgroundColor: Colors.white, borderRadius: 8 }}>
        <ModalHeader onClose={onCancel}>
          {t('このエラーを再連携せず無視します')}
        </ModalHeader>
        <View style={{}}>
          <Text
            style={{
              padding: 24,
              width: 552,
              fontSize: 18,
              fontWeight: '300',
              lineHeight: 27,
              textAlign: 'left',
            }}
          >
            {t(
              '一度無視した連携エラーを再び再連携することはできません。よろしいですか？',
            )}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 16,
              justifyContent: 'center',
              padding: 24,
              borderTopColor: Colors.border,
              borderTopWidth: 0.5,
            }}
          >
            <Button mode="outline" onPress={onCancel} height={48}>
              {t('キャンセル')}
            </Button>
            <AsyncButton variant="danger" onPress={onConfirm} height={48}>
              {t('無視する')}
            </AsyncButton>
          </View>
        </View>
      </View>
    </ModalCenter>
  )
}

interface SyncFailedReservationProps {
  restaurantId: number
  displayToastInfo: (message: string) => void
}

function SyncFailedReservation({
  restaurantId,
  displayToastInfo,
}: SyncFailedReservationProps) {
  const token = useToken()
  const { data, pagination, mutate } = useSyncFailedReservations(restaurantId)
  const [deleteTargetFailedReservation, setDeleteTargetFailedReservation] =
    useState<ISyncFailedReservation | null>(null)

  return (
    <>
      <ScrollView
        style={{
          backgroundColor: Colors.bgBlack,
        }}
        contentContainerStyle={{ padding: 40 }}
      >
        {data != null && (
          <View
            style={{
              padding: 16,
              backgroundColor: Colors.white,
              borderRadius: 8,
            }}
          >
            <StatusView
              failedCount={pagination.totalCount ?? 0}
              onRetry={async () => {
                if (token == null) return
                const { error } = await syncFailedReservations(
                  token,
                  restaurantId,
                )
                if (error != null) {
                  onError(error)
                  return
                }
                displayToastInfo(
                  t(
                    'データの再連携には数十秒ほどかかります。後ほどご確認ください。',
                  ),
                )
              }}
            />
            <FailedReservationListView
              failedReservations={data}
              paginationProps={{
                currentPage: pagination.currentPage ?? 0,
                totalPage: pagination.totalPages ?? 0,
                setPage: pagination.setPage,
              }}
              onPressDeleteFailedReservation={async (failedReservation) => {
                setDeleteTargetFailedReservation(failedReservation)
              }}
            />
          </View>
        )}
      </ScrollView>
      <ConfirmDeleteModal
        isVisible={deleteTargetFailedReservation != null}
        onCancel={() => setDeleteTargetFailedReservation(null)}
        onConfirm={async () => {
          if (deleteTargetFailedReservation == null) return
          if (token == null) return
          const { error } = await deleteFailedReservation(
            token,
            restaurantId,
            deleteTargetFailedReservation.id,
          )
          if (error != null) {
            onError(error)
            return
          }
          await mutate()
          setDeleteTargetFailedReservation(null)
        }}
      />
    </>
  )
}

const containerStyle = {
  flex: 1,
  paddingTop: 40,
  paddingHorizontal: 56,
  paddingBottom: 80,
}

interface ContractedViewProps extends SyncFailedReservationProps {
  onPressPromptOutboundSetting: () => void
}

function ContractedView(props: ContractedViewProps) {
  const { restaurantId, onPressPromptOutboundSetting } = props
  const { isExistOutboundSettings } = useSupportedOutboundSettings(restaurantId)

  if (isExistOutboundSettings == null) return null

  if (!isExistOutboundSettings)
    return (
      <View style={{ backgroundColor: Colors.bgBlack, height: '100%' }}>
        <View style={[containerStyle]}>
          <PromptOutboundView
            onPressOutboundSetting={onPressPromptOutboundSetting}
          />
        </View>
      </View>
    )

  return <SyncFailedReservation {...props} />
}

type SyncFailedReservationViewProps = ContractedViewProps

export default function SyncFailedReservationView(
  props: SyncFailedReservationViewProps,
) {
  const { importMailSettingsData } = useImportMailSettings(props.restaurantId)
  if (importMailSettingsData == null) return

  return (
    <>
      {importMailSettingsData.site_controller_enabled ? (
        <ContractedView {...props} />
      ) : (
        <View style={[containerStyle, { backgroundColor: Colors.bgBlack }]}>
          <UnContractedView />
        </View>
      )}
    </>
  )
}
