import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'

import { useCurrentOwner } from 'models/Owner'

import { InviteRequest_Role } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_service'

import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { useToken } from 'models/Auth'
import { businessUserClient } from 'models/BusinessUser'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'
import { useNavigate } from 'react-router'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { BusinessUserResource_RestaurantResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'
import { BusinessUserRoleResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_resource'
import { PermittedRestaurantsFormGroup } from 'components/Top/BusinessUsers/Form'
import { useBusinessUserRoles } from 'models/BusinessUserRole'
import { useOwnerRestaurants } from 'models/Restaurant'

const FORM_BUTTON_WITH = 278
const FORM_BUTTON_HEIGHT = 48

export default function BusinessUsersNew() {
  const token = useToken()
  const { owner } = useCurrentOwner()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()

  const [email, setEmail] = useState('')

  const [isAdmin, setIsAdmin] = useState(true)

  const [businessUserRoleId, setBusinessUserRoleId] = useState<
    BusinessUserRoleResource['id'] | null
  >(null)

  const [permittedRestaurantIds, setPermittedRestaurantIds] = useState<
    Array<BusinessUserResource_RestaurantResource['id']>
  >([])

  const { data } = useOwnerRestaurants()

  const { businessUserRoles } = useBusinessUserRoles()

  if (
    owner === undefined ||
    data === undefined ||
    businessUserRoles === undefined
  ) {
    return <Loading />
  }

  const onChangeIsAdmin = (value: boolean) => {
    setIsAdmin(value)
    setBusinessUserRoleId(null)
  }

  const disabled = !(email !== '' && (isAdmin || businessUserRoleId !== null))

  const onSubmit = async () => {
    if (disabled) return
    const role = isAdmin ? InviteRequest_Role.ADMIN : InviteRequest_Role.NORMAL
    const { error } = await callRpc(
      businessUserClient.invite(
        {
          email,
          name: '',
          role,
          businessUserRoleId:
            businessUserRoleId === null
              ? undefined
              : { value: businessUserRoleId },
          permittedRestaurantIds,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }
    displayToastInfo(t('招待メールを送信しました'))
    navigate('/business_users')
  }

  const businessUserRoleItems: Array<
    SelectItem<BusinessUserRoleResource['id'] | null>
  > = [
    {
      label: t('未選択'),
      value: null,
    },
    ...businessUserRoles.map(({ id, name }) => {
      return { label: name, value: id }
    }),
  ]

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          padding: width < sm ? 24 : 48,
          flexGrow: 1,
        }}
      >
        <Text
          style={{
            fontSize: 18,
          }}
        >
          {t(
            '{{name}}に追加したいメンバーに招待メールを送信します。必要な情報を入力してください。',
            { name: owner.name }
          )}
        </Text>

        <ShadowBox
          style={{
            marginTop: 24,
            padding: 32,
          }}
        >
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('メールアドレス')} required />}
          >
            <TextInput
              style={{
                flex: 1,
                paddingVertical: 16,
              }}
              placeholder="xxx@autoreserve.com"
              onChangeText={setEmail}
              value={email}
            />
          </FormGroup>
          <FormGroup
            style={{
              marginTop: 22,
            }}
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('管理者権限')} />}
          >
            <Switch value={isAdmin} onValueChange={onChangeIsAdmin} />
          </FormGroup>
          <FormGroup
            style={{
              marginTop: 28,
            }}
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('役割')} required={!isAdmin} />}
          >
            <SelectInput
              disabled={isAdmin}
              style={{ width: '50%' }}
              selectedValue={businessUserRoleId}
              setValue={setBusinessUserRoleId}
              items={businessUserRoleItems}
            />
          </FormGroup>
          <PermittedRestaurantsFormGroup
            style={{
              marginTop: 32,
            }}
            restaurants={data.restaurants}
            permittedRestaurantIds={permittedRestaurantIds}
            setPermittedRestaurantIds={setPermittedRestaurantIds}
            isAdmin={isAdmin}
          />
        </ShadowBox>
      </ScrollView>
      <View
        style={{
          backgroundColor: Colors.white,
          alignItems: 'center',
          paddingVertical: 12,
        }}
      >
        <Button
          style={{
            width: FORM_BUTTON_WITH,
            height: FORM_BUTTON_HEIGHT,
          }}
          disabled={disabled}
          onPress={onSubmit}
        >
          {t('招待を送信')}
        </Button>
      </View>
    </View>
  )
}
