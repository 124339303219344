import React from 'react'
import { ScrollView, View } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

import { useMenuRestaurantGroups } from 'models/MenuRestaurantGroup'

import MenuRestaurantGroup from 'components/Groups/MenuRestaurantGroup'
import GroupLabel from 'components/Groups/GroupLabel'
import { useNavigate } from 'react-router-dom'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const headerNames = [t('グループ名'), t('説明'), t('店舗')]

export default function MenuGroups() {
  const { data } = useMenuRestaurantGroups()
  const navigate = useNavigate()
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <View
        style={{
          marginTop: 40,
        }}
      >
        <GroupLabel
          label={t('登録メニュー')}
          totalCount={data?.tableMenuRestaurantGroups?.length ?? 0}
        />
        <ShadowBox
          style={{
            marginTop: 24,
            padding: 0,
          }}
        >
          {!data ||
            (data.tableMenuRestaurantGroups.length === 0 && (
              <View
                style={{
                  margin: 32,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: '600',
                    }}
                  >
                    {t('グループはありません')}
                  </Text>
                  <Text
                    style={{
                      fontSize: 18,
                      color: Colors.black60,
                    }}
                  >
                    {t(
                      'グループを追加して、複数レストランのメニューを管理しましょう'
                    )}
                  </Text>
                </View>
                <View>
                  <Button
                    onPress={() => {
                      navigate(`/groups/restaurants/menu_groups/new`)
                    }}
                    mode="outline"
                    style={{
                      height: 44,
                      width: 188,
                    }}
                  >
                    {t('追加する')}
                  </Button>
                </View>
              </View>
            ))}
          {/* Header */}
          {data !== undefined && data.tableMenuRestaurantGroups.length > 0 && (
            <>
              <View
                style={{
                  height: 52,
                  paddingHorizontal: 32,
                  borderBottomColor: Colors.border,
                  borderBottomWidth: 0.5,
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                {headerNames.map((name) => (
                  <View key={name} style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: '600',
                      }}
                    >
                      {name}
                    </Text>
                  </View>
                ))}
              </View>
              {/* Row */}
              {data.tableMenuRestaurantGroups.map((group) => {
                return (
                  <MenuRestaurantGroup
                    key={group.id}
                    group={group}
                    onPress={() => {
                      navigate(`/menu_restaurant_groups/${group.id}`)
                    }}
                  />
                )
              })}
            </>
          )}
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
