import { getPathnameWithLocale } from 'modules/locale'
import { useCallback } from 'react'
import {
  NavigateFunction,
  NavigateOptions,
  To,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useReactRouterNavigate,
} from 'react-router-dom'

function normalize(to: To) {
  if (typeof to === 'string') {
    const { pathname, search } = new URL(to, document.baseURI)
    return { pathname, search }
  }

  return {
    pathname: to.pathname ?? '',
    search: to.search != null ? new URLSearchParams(to.search).toString() : '',
    hash: to.hash ?? '',
  }
}

// 言語pathを含んだurlにnavigateする
export function useNavigate() {
  const reactRouterNavigate = useReactRouterNavigate()

  const navigate: NavigateFunction = useCallback(
    (...args) => {
      if (typeof args[0] === 'number') {
        const [delta] = args as [delta: number]
        return reactRouterNavigate(delta)
      }
      const [to, options] = args as [to: To, options?: NavigateOptions]

      const { pathname, search, hash } = normalize(to)

      return reactRouterNavigate(
        {
          pathname: getPathnameWithLocale(pathname),
          search,
          hash,
        },
        options
      )
    },
    [reactRouterNavigate]
  )

  return navigate
}
