// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_service.proto" (package "auto_reserve.restaurants.table_seat_charge_policy", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TableSeatChargePolicy } from "./table_seat_charge_policy_service";
import type { ArchiveRequest } from "./table_seat_charge_policy_service";
import type { UpdateRequest } from "./table_seat_charge_policy_service";
import type { CreateRequest } from "./table_seat_charge_policy_service";
import type { TableSeatChargePolicyResource } from "./table_seat_charge_policy_resource";
import type { GetRequest } from "./table_seat_charge_policy_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./table_seat_charge_policy_service";
import type { ListRequest } from "./table_seat_charge_policy_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicy
 */
export interface ITableSeatChargePolicyClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.table_seat_charge_policy.ListRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.table_seat_charge_policy.GetRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, TableSeatChargePolicyResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.table_seat_charge_policy.CreateRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, TableSeatChargePolicyResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.table_seat_charge_policy.UpdateRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, TableSeatChargePolicyResource>;
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.table_seat_charge_policy.ArchiveRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, TableSeatChargePolicyResource>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicy
 */
export class TableSeatChargePolicyClient
  implements ITableSeatChargePolicyClient, ServiceInfo
{
  typeName = TableSeatChargePolicy.typeName;
  methods = TableSeatChargePolicy.methods;
  options = TableSeatChargePolicy.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.table_seat_charge_policy.ListRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.table_seat_charge_policy.GetRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, TableSeatChargePolicyResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, TableSeatChargePolicyResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.table_seat_charge_policy.CreateRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, TableSeatChargePolicyResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, TableSeatChargePolicyResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.table_seat_charge_policy.UpdateRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, TableSeatChargePolicyResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, TableSeatChargePolicyResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.table_seat_charge_policy.ArchiveRequest) returns (auto_reserve.restaurants.table_seat_charge_policy.TableSeatChargePolicyResource);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, TableSeatChargePolicyResource> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ArchiveRequest, TableSeatChargePolicyResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
