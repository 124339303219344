import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { Accordion } from '@hello-ai/ar_shared/src/components/Accordion'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import React, { ReactNode, useContext, useRef, useState } from 'react'
import { ScrollView, View, TextStyle } from 'react-native'
import BadgeCount from '@hello-ai/for_r_app/src/components/Shared/BadgeCount'
import LinkOpacity from '../LinkOpacity'

import {
  generatePath,
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router'
import useClickOutside, { isClickInsideModal } from 'modules/useClickOutside'
import { Screen, ScreenOptions } from './Screen'
import { HeaderButton } from './Header'
import { getLocale } from 'modules/locale'

export type DrawerRouteItem = {
  title: string | ReactNode
  /**
   * ナビゲーションDrawerでReactNodeを描画したいときに使う。
   * @default title 未指定でtitleと同じものが描画される
   */
  DrawerLabel?: React.ComponentType<
    React.PropsWithChildren<{ textStyle: TextStyle }>
  >
  fullPath: string
  relativePath: string
  matchPath?: string | string[]
  exact?: boolean
  hidden?: boolean
  screenOptions?: ScreenOptions
} & (
  | {
      element: React.ReactNode
      action?: undefined
    }
  | {
      element?: undefined
      action: () => void
    }
)

export type DrawerSectionKey = string
export type DrawerRouteKey = string

export interface DrawerSection {
  title: string
  routes: { [K in DrawerSectionKey]: DrawerRoute }
}

export interface DrawerRoute {
  title: string
  routes: (DrawerRouteItem | undefined)[] // hiddenでないDrawerRouteItemが必ず一つ以上あることを前提としている, undefined は要素一つのアコーディオンのために使う
  useAccordionForSingleRoute?: boolean
  hidden?: boolean
}

export type DrawerRoutes = Record<DrawerRouteKey, DrawerRoute>

const drawerItemHeight = 48

function DrawerItemLink({
  focused,
  icon,
  label,
  badgeCount,
  to,
  onClick,
}: {
  focused: boolean
  icon: React.ReactNode
  label: string | ReactNode
  badgeCount: null | number
  to: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}) {
  return (
    <LinkOpacity
      style={{
        marginHorizontal: 12,
        borderRadius: 24,
        paddingLeft: 12,
        flexDirection: 'row',
        alignItems: 'center',
        height: drawerItemHeight,
        backgroundColor: focused ? Colors.primary10 : 'white',
      }}
      to={to}
      onClick={onClick}
    >
      <View
        style={{
          position: 'relative',
        }}
      >
        {icon}
        {badgeCount != null && badgeCount > 0 && (
          <BadgeCount badgeCount={badgeCount} />
        )}
      </View>
      <Text
        style={{
          marginLeft: 24,
          fontSize: 14,
          fontWeight: focused ? '600' : '400',
          color: focused ? Colors.primary : Colors.black,
        }}
      >
        {label}
      </Text>
    </LinkOpacity>
  )
}

function DrawerItemAccordion({
  focused,
  icon,
  label,
  onPress,
  children,
  badgeCount,
}: {
  focused: boolean
  icon: React.ReactNode
  label: string | React.ReactNode
  onPress: () => void
  children?: React.ReactNode
  badgeCount: null | number
}) {
  return (
    <View>
      <TouchableOpacity
        style={{
          marginHorizontal: 12,
          paddingLeft: 12,
          paddingRight: 20,
          flexDirection: 'row',
          alignItems: 'center',
          height: drawerItemHeight,
        }}
        onPress={onPress}
        onPressMinInterval={0}
      >
        <View
          style={{
            position: 'relative',
          }}
        >
          {icon}
          {badgeCount != null && badgeCount > 0 && (
            <BadgeCount badgeCount={badgeCount} />
          )}
        </View>
        <Text
          style={{
            marginLeft: 24,
            fontSize: 14,
            fontWeight: focused ? '600' : '400',
            color: focused ? Colors.primary : Colors.black,
          }}
        >
          {label}
        </Text>
        <View
          style={{
            marginLeft: 'auto',
            transition: 'transform 0.3s ease-in-out',
            transform: [
              {
                rotateZ: focused ? '180deg' : '0deg',
              },
            ],
          }}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            size={10}
            color={focused ? Colors.primary : Colors.secondaryBlack}
          />
        </View>
      </TouchableOpacity>
      <Accordion open={focused}>{children}</Accordion>
    </View>
  )
}

const drawerSubItemHeight = 48
function DrawerSubItemLink({
  selected,
  label,
  to,
  onClick,
  DrawerLabel,
}: {
  selected: boolean
  label: string | ReactNode
  to: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  /**
   * ナビゲーションDrawerでReactNodeを描画したいときに使う。
   * @default title 未指定でtitleと同じものが描画される
   */
  DrawerLabel?: React.ComponentType<
    React.PropsWithChildren<{ textStyle: TextStyle }>
  >
}) {
  const textStyle: TextStyle = {
    fontSize: 14,
    fontWeight: selected ? '600' : '400',
    color: selected ? Colors.primary : Colors.black,
    display: 'flex',
    alignItems: 'center',
  }
  return (
    <LinkOpacity
      style={{
        marginLeft: 50,
        marginRight: 12,
        paddingHorizontal: 22,
        borderRadius: 24,
        justifyContent: 'center',
        height: drawerSubItemHeight,
        backgroundColor: selected ? Colors.primary10 : 'white',
      }}
      to={to}
      onClick={onClick}
    >
      {DrawerLabel ? (
        <DrawerLabel textStyle={textStyle} />
      ) : (
        <Text style={textStyle}>{label}</Text>
      )}
    </LinkOpacity>
  )
}

export function DrawerHeaderIconRight({
  icon = faAngleDown,
}: {
  icon?: IconDefinition
}) {
  const { width, md } = useResponsive()
  return (
    <FontAwesomeIcon
      icon={icon}
      size={width < md ? 14 : 18}
      color={Colors.disabledBlack}
      style={{ marginLeft: 4 }}
    />
  )
}

export function useCurrentRoute({
  drawerRoutes,
}: {
  drawerRoutes: Record<DrawerSectionKey, DrawerSection>
}) {
  const locale = getLocale()
  const location = useLocation()
  const createPath = (path: string, exact?: boolean) =>
    exact ? path : `${path}/*`

  for (const drawerSection of Object.values(drawerRoutes)) {
    for (const [drawerRouteKey, drawerRoute] of Object.entries(
      drawerSection.routes
    )) {
      for (const route of drawerRoute.routes) {
        if (route === undefined) continue
        let match: ReturnType<typeof matchPath>
        if (route.matchPath !== undefined) {
          if (typeof route.matchPath === 'string') {
            match = matchPath(
              `/${locale}${createPath(route.matchPath, route.exact)}`,
              location.pathname
            )
          } else {
            match = route.matchPath.reduce<ReturnType<typeof matchPath>>(
              (p, c) =>
                p ??
                matchPath(
                  `/${locale}${createPath(c, route.exact)}`,
                  location.pathname
                ),
              null
            )
          }
        } else {
          match = matchPath(
            createPath(`/${locale}${route.fullPath}`, route.exact),
            location.pathname
          )
        }

        if (match != null) {
          return [drawerRouteKey, route] as const
        }
      }
    }
  }
}

const drawerWidth = 220

export interface DrawerProps {
  isDrawerOpen: boolean
  drawerType: 'front' | 'permanent'
  drawerRoutes: Record<DrawerSectionKey, DrawerSection>
  drawerHeader: React.ReactNode
  drawerFooter?: React.ReactNode
  renderDrawerItemIcon: ({
    routeKey,
    focused,
  }: {
    routeKey: DrawerRouteKey
    focused: boolean
  }) => React.ReactNode
}

function excludeHiddenDrawerRoutes(drawerRoutes: DrawerRoutes): DrawerRoutes {
  return Object.fromEntries(
    Object.entries(drawerRoutes).flatMap(([key, drawerRoute]) => {
      if (drawerRoute.hidden != null && drawerRoute.hidden) return []

      const filteredRoute: DrawerRoute = {
        ...drawerRoute,
        routes: drawerRoute.routes.filter((r) => {
          return r?.hidden == null || !r.hidden
        }),
      }
      return [[key, filteredRoute]]
    })
  )
}

export const DrawerContent = React.forwardRef<View, DrawerProps>(
  (
    {
      isDrawerOpen,
      drawerType,
      drawerRoutes,
      drawerHeader,
      drawerFooter,
      renderDrawerItemIcon,
    }: DrawerProps,
    ref
  ) => {
    const locale = getLocale()
    const location = useLocation()
    const params = useParams()

    const selectedKey = useCurrentRoute({ drawerRoutes })?.[0]
    const [expandedKey, setExpandedKey] = useState<DrawerRouteKey | null>(
      Object.keys(drawerRoutes)[0] ?? null
    )

    const [state, setDrawerState] = useDrawerState()

    function getPath(path: DrawerRouteItem['fullPath']) {
      return generatePath(`/${locale}${path}`, params)
    }

    function closeDrawer() {
      setDrawerState({ ...state, isOpen: false })
    }

    return (
      <View
        ref={ref}
        style={[
          {
            flex: 1,
            width: drawerWidth,
            maxWidth: drawerWidth,
            height: '100%',
            zIndex: 1,
          },
          drawerType === 'front' && {
            position: 'absolute',
            transition: 'transform 0.3s ease-in-out',
            transform: [
              {
                translateX: isDrawerOpen ? 0 : -drawerWidth,
              },
            ],
          },
        ]}
      >
        <View
          style={{
            flex: 1,
            shadowColor: '#000',
            backgroundColor: 'white',
            shadowOpacity: 0.1,
            shadowRadius: 10,
            shadowOffset: {
              width: 0,
              height: 2,
            },
          }}
        >
          {drawerHeader}
          <ScrollView showsVerticalScrollIndicator={false}>
            {Object.entries(drawerRoutes).map(([sectionKey, section]) => (
              <View
                key={sectionKey}
                style={
                  Object.keys(drawerRoutes).length > 1 && {
                    borderBottomWidth: 0.5,
                    borderBottomColor: Colors.border,
                  }
                }
              >
                {section.title !== '' && (
                  <View
                    style={{
                      paddingLeft: 32,
                      height: 42,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        color: Colors.black40,
                        fontSize: 12,
                      }}
                    >
                      {section.title}
                    </Text>
                  </View>
                )}
                {Object.entries(excludeHiddenDrawerRoutes(section.routes)).map(
                  (
                    [
                      key,
                      { title, routes, useAccordionForSingleRoute = false },
                    ],
                    index
                  ) => {
                    const badgeCount = null
                    if (routes.length === 1 && !useAccordionForSingleRoute) {
                      const route = routes[0]
                      if (route === undefined) return null
                      return (
                        <View
                          key={key}
                          style={
                            section.title === '' &&
                            index === 0 && {
                              marginTop: 12,
                            }
                          }
                        >
                          <DrawerItemLink
                            focused={key === selectedKey}
                            icon={renderDrawerItemIcon({
                              routeKey: key,
                              focused: key === selectedKey,
                            })}
                            label={title}
                            badgeCount={badgeCount}
                            to={getPath(route.fullPath)}
                            onClick={(event) => {
                              closeDrawer()
                              if (route.action === undefined) return
                              event.preventDefault()
                              route.action()
                            }}
                          />
                        </View>
                      )
                    }

                    return (
                      <DrawerItemAccordion
                        key={key}
                        focused={key === expandedKey}
                        icon={renderDrawerItemIcon({
                          routeKey: key,
                          focused: key === expandedKey,
                        })}
                        label={title}
                        onPress={() => {
                          if (key === expandedKey) {
                            setExpandedKey(null)
                          } else {
                            setExpandedKey(key)
                          }
                        }}
                        badgeCount={badgeCount}
                      >
                        {routes.map((route, index) => {
                          if (route === undefined) return null
                          const {
                            title,
                            fullPath,
                            exact,
                            action,
                            DrawerLabel,
                          } = route
                          return (
                            <DrawerSubItemLink
                              key={index.toString()}
                              selected={
                                key === selectedKey &&
                                matchPath(
                                  exact
                                    ? `/${locale}${route.fullPath}`
                                    : `/${locale}${route.fullPath}/*`,
                                  location.pathname
                                ) != null
                              }
                              label={title}
                              to={getPath(fullPath)}
                              onClick={(event) => {
                                closeDrawer()
                                if (action === undefined) return
                                event.preventDefault()
                                action()
                              }}
                              DrawerLabel={DrawerLabel}
                            />
                          )
                        })}
                      </DrawerItemAccordion>
                    )
                  }
                )}
              </View>
            ))}
          </ScrollView>
          <View
            style={{
              borderTopColor: Colors.border,
              borderTopWidth: 0.5,
            }}
          >
            {drawerFooter}
          </View>
        </View>
      </View>
    )
  }
)

function useFirstRoutePath({
  drawerRoutes,
}: {
  drawerRoutes: Record<DrawerSectionKey, DrawerSection>
}) {
  const firstRoute = Object.values(Object.values(drawerRoutes)[0].routes)[0]
    .routes[0]
  const locale = getLocale()
  const params = useParams()
  return generatePath(`/${locale}${firstRoute?.fullPath}`, params)
}

type DrawerType = 'permanent' | 'front'

interface DrawerState {
  isOpen: boolean
}

const DrawerContext = React.createContext<
  [state: DrawerState, setState: (value: DrawerState) => void] | undefined
>(undefined)

function useDrawerType() {
  const { width, md } = useResponsive()
  const drawerType: DrawerType = width < md ? 'front' : 'permanent'
  return drawerType
}

function useDrawerState() {
  const context = useContext(DrawerContext)
  if (context === undefined) {
    throw new Error('useDrawerState() must be used inside a <Drawer> component')
  }
  return context
}

export function DrawerHeaderToggle() {
  const drawerType = useDrawerType()
  const [state, setDrawerState] = useDrawerState()
  return drawerType === 'permanent' ? null : (
    <HeaderButton
      icon={faBars}
      onPressMinInterval={0}
      onPress={() => {
        setDrawerState({ ...state, isOpen: !state.isOpen })
      }}
    />
  )
}

export function Drawer({
  drawerRoutes,
  drawerHeader,
  drawerFooter,
  renderDrawerItemIcon,
}: {
  drawerRoutes: Record<DrawerSectionKey, DrawerSection>
  drawerHeader: React.ReactNode
  drawerFooter?: React.ReactNode
  renderDrawerItemIcon: ({
    routeKey,
    focused,
  }: {
    routeKey: DrawerRouteKey
    focused: boolean
  }) => React.ReactNode
}) {
  const drawerType = useDrawerType()

  const route = useCurrentRoute({ drawerRoutes })?.[1]

  const [drawerState, setDrawerState] = useState<DrawerState>({
    isOpen: false,
  })

  const drawerRef = useRef<React.ElementRef<typeof DrawerContent>>(null)

  useClickOutside(
    drawerRef as unknown as React.RefObject<HTMLDivElement>,
    (event) => {
      if (drawerType === 'permanent' || !drawerState.isOpen) return
      if (isClickInsideModal(event)) return
      setDrawerState((prevState) => ({ ...prevState, isOpen: false }))
    }
  )

  const firstRoutePath = useFirstRoutePath({ drawerRoutes })

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
        }}
      >
        <DrawerContent
          ref={drawerRef}
          isDrawerOpen={drawerType === 'permanent' || drawerState.isOpen}
          drawerType={drawerType}
          drawerRoutes={drawerRoutes}
          drawerHeader={drawerHeader}
          drawerFooter={drawerFooter}
          renderDrawerItemIcon={renderDrawerItemIcon}
        />
        <View style={{ flex: 1 }}>
          <Routes>
            {Object.values(drawerRoutes).map((sections) =>
              Object.values(sections.routes).map((routes) =>
                routes.routes.map(
                  (route, index) =>
                    route !== undefined &&
                    route.element !== undefined && (
                      <Route
                        key={index.toString()}
                        path={
                          route.exact
                            ? route.relativePath
                            : `${route.relativePath}/*`
                        }
                        element={
                          <Screen
                            options={{
                              title: route.title,
                              headerLeft: <DrawerHeaderToggle />,
                              ...route.screenOptions,
                            }}
                          >
                            {route.element}
                          </Screen>
                        }
                      />
                    )
                )
              )
            )}
          </Routes>
          {route === undefined && <Navigate to={firstRoutePath} />}
        </View>
      </View>
    </DrawerContext.Provider>
  )
}
