import React from 'react'
import { Text } from 'react-native'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'

import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

type Props = {
  onPress: () => void
}

export function AddReservationFloatButton({ onPress }: Props) {
  const { width, sm } = useResponsive()

  return (
    // <TouchableOpacity
    //   style={{
    //     position: 'absolute',
    //     bottom: width < sm ? 16 : 32,
    //     right: width < sm ? 16 : 32,
    //     width: width < sm ? 60 : 96,
    //     height: width < sm ? 60 : 96,
    //     flex: 1,
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     backgroundColor: Colors.primary,
    //     borderRadius: 100,
    //     shadowColor: '#000',
    //     shadowOffset: {
    //       width: 0,
    //       height: 4,
    //     },
    //     shadowRadius: 4,
    //     shadowOpacity: 0.4,
    //     elevation: 4,
    //   }}
    //   onPress={() => {
    //     if (width >= sm) {
    //       Transaction.start(Transaction.items.switchToReservationFormFromChart)
    //     }
    //     navigation.navigate('ReservationsForm', {
    //       dateString,
    //     })
    //   }}
    // >
    //   <FontAwesomeIcon
    //     icon={faPlus}
    //     size={width < sm ? 24 : 30}
    //     color={Colors.field}
    //   />
    //   <Text
    //     style={{
    //       color: Colors.field,
    //       fontWeight: '600',
    //       fontSize: width < sm ? 12 : 14,
    //     }}
    <TouchableOpacity
      style={{
        position: 'absolute',
        bottom: width < sm ? 16 : 32,
        right: width < sm ? 16 : 32,
        width: width < sm ? 60 : 96,
        height: width < sm ? 60 : 96,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.primary,
        borderRadius: 100,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowRadius: 4,
        shadowOpacity: 0.4,
        elevation: 4,
      }}
      onPress={onPress}
    >
      <FontAwesomeIcon
        icon={faPlus}
        size={width < sm ? 24 : 30}
        color={Colors.field}
      />
      <Text
        style={{
          color: Colors.field,
          fontWeight: '600',
          fontSize: width < sm ? 12 : 14,
        }}
      >
        {width < sm ? t('追加') : t('予約追加')}
      </Text>
    </TouchableOpacity>
  )
}
