import React, { FC } from 'react'
import { View, GestureResponderEvent } from 'react-native'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Colors } from '../../constants/Colors'
import { hexWithOpacity } from '../../modules/hexWithOpacity'
import { useResponsive } from '../../modules/useResponsive'
import { Text } from '../Text'
import { FontAwesomeIcon } from '../FontAwesomeIcon'
import { TouchableOpacity } from '../Touchables'

type Props = {
  isLastStep: boolean
  isDisabledNextPress: boolean
  onPressNext: (event: GestureResponderEvent) => void
  isListExist?: boolean
}

export function StepperNextButton({
  isLastStep,
  isDisabledNextPress,
  onPressNext,
  isListExist,
}: Props) {
  const { width, sm } = useResponsive()
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        position: 'absolute',
        right: width < sm ? 16 : 24,
        bottom:
          width < sm
            ? !isListExist
              ? insets.bottom + 8
              : insets.bottom + 65
            : 16,
      }}
    >
      <TouchableOpacity
        style={{
          width: width < sm ? 60 : 96,
          height: width < sm ? 60 : 96,
          borderRadius: width < sm ? 30 : 48,
          backgroundColor: isDisabledNextPress
            ? hexWithOpacity(0.4, Colors.primary)
            : Colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowRadius: 4,
          shadowOpacity: 0.4,
          elevation: 4,
          opacity: isDisabledNextPress ? 0.4 : 1,
        }}
        disabled={isDisabledNextPress}
        onPress={onPressNext}
      >
        {isLastStep ? (
          <Text
            style={{
              color: 'white',
              fontSize: width < sm ? 18 : 22,
              fontWeight: '600',
            }}
          >
            確定
          </Text>
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size={width < sm ? 24 : 40}
            color="white"
          />
        )}
      </TouchableOpacity>
    </View>
  )
}
