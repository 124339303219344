import React, { useContext } from 'react'
import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TextStyle,
  ColorValue,
  GestureResponderEvent,
} from 'react-native'
import { Colors } from '../constants/Colors'
import { TouchableOpacity } from './Touchables'
import { Text } from './Text'

const ToggleGroupContext = React.createContext<
  | {
      onValueChange: (value: any) => void
      value: any
      disabled?: boolean
    }
  | undefined
>(undefined)

interface ToggleGroupProps<T = any> {
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  onValueChange: (value: T) => void
  value: T
  children: React.ReactNode
}

interface ToggleItemProps<T> {
  style?: StyleProp<ViewStyle>

  textStyle?: StyleProp<TextStyle>
  value?: any
  disabled?: boolean
  selected?: boolean
  onPress?: (event: GestureResponderEvent) => void
  icon?: (options: { selected?: boolean; color: ColorValue }) => React.ReactNode
  text?: string | null | undefined
  color?: ColorValue
  selectedColor?: ColorValue
  borderColor?: ColorValue
  selectedBorderColor?: ColorValue
  textColor?: ColorValue
  selectedTextColor?: ColorValue
}
export function ToggleGroup<T = any>({
  style,
  onValueChange,
  value,
  disabled,
  children,
}: ToggleGroupProps<T>) {
  const elements = React.Children.toArray(children).filter(
    (element) => element != null
  )

  return (
    <ToggleGroupContext.Provider value={{ onValueChange, value, disabled }}>
      <View
        style={[
          {
            flexDirection: 'row',
            height: 56,
          },
          style,
        ]}
      >
        {elements.map((child: any, index: number) => {
          const isLeftMost: boolean = index === 0
          const isRightMost: boolean = index === elements.length - 1

          return React.cloneElement(child, {
            style: [
              [
                isLeftMost && {
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                },
                isRightMost && {
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                },
              ],
              child.props.style,
            ],
          })
        })}
      </View>
    </ToggleGroupContext.Provider>
  )
}

export function ToggleItem<T = any>({
  style,
  textStyle,
  value,
  disabled = false,
  selected = false,
  onPress,
  icon,
  text,
  color = 'white',
  selectedColor = Colors.accent,
  borderColor = Colors.accent,
  selectedBorderColor = Colors.accent,
  textColor = Colors.black,
  selectedTextColor = 'white',
}: ToggleItemProps<T>) {
  const context = useContext(ToggleGroupContext)

  if (context && context.value === value) {
    selected = true
  }
  if (context && context.disabled) {
    disabled = context.disabled
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      onPressMinInterval={0}
      style={[
        {
          paddingHorizontal: 24,
          alignItems: 'center',
          justifyContent: 'center',
          borderColor,
          borderWidth: 1,
          backgroundColor: color,
        },
        selected && {
          borderColor: selectedBorderColor,
        },
        selected && {
          backgroundColor: selectedColor,
        },
        disabled && {
          opacity: 0.3,
        },
        style,
      ]}
      onPress={(event) => {
        if (onPress) {
          onPress(event)
        }
        if (context !== undefined) {
          context.onValueChange(value)
        }
      }}
    >
      <>
        {icon != null && typeof icon === 'function'
          ? icon({ selected, color: selected ? selectedTextColor : textColor })
          : icon}
        {text != null && (
          <Text
            style={[
              [
                {
                  fontSize: 18,
                  fontWeight: selected ? '600' : 'normal',
                  color: textColor,
                },
                selected && {
                  color: selectedTextColor,
                },
              ],
              textStyle,
            ]}
          >
            {text}
          </Text>
        )}
      </>
    </TouchableOpacity>
  )
}
