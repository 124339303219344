import React from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from '../../../../components/Touchables'
import { Colors } from '../../../../constants/Colors'
import { FontAwesomeIcon } from '../../../../components/FontAwesomeIcon'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { Text } from '../../../../components/Text'
import { t } from '../../../../modules/i18n'

interface NewSeatSettingFabProps {
  onPress: () => void
}

export default function NewSeatSettingFab({ onPress }: NewSeatSettingFabProps) {
  return (
    <View
      style={{
        position: 'absolute',
        right: 32,
        bottom: 32,
      }}
    >
      <TouchableOpacity
        style={{
          width: 96,
          height: 96,
          borderRadius: 60,
          backgroundColor: Colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
          shadowColor: '#000',
          rowGap: 8,
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowRadius: 4,
          shadowOpacity: 0.4,
          elevation: 4,
        }}
        onPress={onPress}
      >
        <FontAwesomeIcon icon={faPlus} size={30} color={Colors.white} />
        <Text
          style={{
            color: Colors.white,
            fontSize: 14,
            fontWeight: '600',
            textAlign: 'center',
            lineHeight: 14,
          }}
        >
          {t('連携席設定\nを作成')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
