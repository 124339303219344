// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course/restaurant_course_resource.proto" (package "auto_reserve.restaurants.restaurant_course", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantCourseReservationPeriodPolicyResource } from "./restaurant_course_reservation_period_policy_resource";
import { Int32Value } from "../../../google/protobuf/wrappers";
import { RestaurantCourseCancelPolicyResource } from "./restaurant_course_cancel_policy_resource";
import { RestaurantCourseQuestionResource } from "./restaurant_course_question_resource";
import { TableMenuResource } from "../table_menu/table_menu_resource";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseResource
 */
export interface RestaurantCourseResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: float price = 2;
   */
  price: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: bool tax_included = 5;
   */
  taxIncluded: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type = 6;
   */
  taxType: RestaurantCourseResource_TaxType;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.Status status = 7;
   */
  status: RestaurantCourseResource_Status;
  /**
   * @generated from protobuf field: string title = 8;
   */
  title: string;
  /**
   * @generated from protobuf field: string content = 9;
   */
  content: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_menu.TableMenuResource table_menus = 10;
   */
  tableMenus: TableMenuResource[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource questions = 11;
   */
  questions: RestaurantCourseQuestionResource[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies = 12;
   */
  restaurantCourseCancelPolicies: RestaurantCourseCancelPolicyResource[];
  /**
   * @generated from protobuf field: string title_i18n_ja = 13 [json_name = "titleI18nJa"];
   */
  titleI18NJa: string;
  /**
   * @generated from protobuf field: string title_i18n_en = 14 [json_name = "titleI18nEn"];
   */
  titleI18NEn: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_cn = 15 [json_name = "titleI18nZhCn"];
   */
  titleI18NZhCn: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_tw = 16 [json_name = "titleI18nZhTw"];
   */
  titleI18NZhTw: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_hk = 17 [json_name = "titleI18nZhHk"];
   */
  titleI18NZhHk: string;
  /**
   * @generated from protobuf field: string title_i18n_ko = 18 [json_name = "titleI18nKo"];
   */
  titleI18NKo: string;
  /**
   * @generated from protobuf field: string content_i18n_ja = 19 [json_name = "contentI18nJa"];
   */
  contentI18NJa: string;
  /**
   * @generated from protobuf field: string content_i18n_en = 20 [json_name = "contentI18nEn"];
   */
  contentI18NEn: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_cn = 21 [json_name = "contentI18nZhCn"];
   */
  contentI18NZhCn: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_tw = 22 [json_name = "contentI18nZhTw"];
   */
  contentI18NZhTw: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_hk = 23 [json_name = "contentI18nZhHk"];
   */
  contentI18NZhHk: string;
  /**
   * @generated from protobuf field: string content_i18n_ko = 24 [json_name = "contentI18nKo"];
   */
  contentI18NKo: string;
  /**
   * @generated from protobuf field: string price_str = 25;
   */
  priceStr: string;
  /**
   * @generated from protobuf field: string title_i18n_th = 26 [json_name = "titleI18nTh"];
   */
  titleI18NTh: string;
  /**
   * @generated from protobuf field: string content_i18n_th = 27 [json_name = "contentI18nTh"];
   */
  contentI18NTh: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.ImageUrls image_urls = 28;
   */
  imageUrls?: RestaurantCourseResource_ImageUrls;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value staying_time = 29;
   */
  stayingTime?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy = 30;
   */
  restaurantCourseReservationPeriodPolicy?: RestaurantCourseReservationPeriodPolicyResource;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type = 31;
   */
  customerRegionRestrictionType: RestaurantCourseResource_CustomerRegionRestrictionType;
  /**
   * @generated from protobuf field: int64 position = 32;
   */
  position: number;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value min_party_size = 33;
   */
  minPartySize?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value max_party_size = 34;
   */
  maxPartySize?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.RestaurantCourseStockSettingResource restaurant_course_stock_setting = 35;
   */
  restaurantCourseStockSetting?: RestaurantCourseResource_RestaurantCourseStockSettingResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.ImageUrls
 */
export interface RestaurantCourseResource_ImageUrls {
  /**
   * @generated from protobuf field: string xl = 1;
   */
  xl: string;
  /**
   * @generated from protobuf field: string lg = 2;
   */
  lg: string;
  /**
   * @generated from protobuf field: string md = 3;
   */
  md: string;
  /**
   * @generated from protobuf field: string sm = 4;
   */
  sm: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.RestaurantCourseStockSettingResource
 */
export interface RestaurantCourseResource_RestaurantCourseStockSettingResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int32 quantity = 2;
   */
  quantity: number;
  /**
   * @generated from protobuf field: string stock_type = 3;
   */
  stockType: string;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType
 */
export enum RestaurantCourseResource_TaxType {
  /**
   * @generated from protobuf enum value: DEFAULT = 0;
   */
  DEFAULT = 0,
  /**
   * @generated from protobuf enum value: REDUCED = 1;
   */
  REDUCED = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.Status
 */
export enum RestaurantCourseResource_Status {
  /**
   * @generated from protobuf enum value: PUBLISHED = 0;
   */
  PUBLISHED = 0,
  /**
   * @generated from protobuf enum value: UNPUBLISHED = 1;
   */
  UNPUBLISHED = 1,
  /**
   * @generated from protobuf enum value: ARCHIVED = 2;
   */
  ARCHIVED = 2,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType
 */
export enum RestaurantCourseResource_CustomerRegionRestrictionType {
  /**
   * @generated from protobuf enum value: NO_RESTRICTION = 0;
   */
  NO_RESTRICTION = 0,
  /**
   * @generated from protobuf enum value: DOMESTIC_ONLY = 1;
   */
  DOMESTIC_ONLY = 1,
  /**
   * @generated from protobuf enum value: OVERSEAS_ONLY = 2;
   */
  OVERSEAS_ONLY = 2,
  /**
   * @generated from protobuf enum value: SPECIFIC_COUNTRIES_ONLY = 3;
   */
  SPECIFIC_COUNTRIES_ONLY = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseResource$Type extends MessageType<RestaurantCourseResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource",
      [
        {
          no: 1,
          name: "id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 2, name: "price", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
        { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 5,
          name: "tax_included",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 6,
          name: "tax_type",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType",
            RestaurantCourseResource_TaxType,
          ],
        },
        {
          no: 7,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.Status",
            RestaurantCourseResource_Status,
          ],
        },
        { no: 8, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 9, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 10,
          name: "table_menus",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TableMenuResource,
        },
        {
          no: 11,
          name: "questions",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => RestaurantCourseQuestionResource,
        },
        {
          no: 12,
          name: "restaurant_course_cancel_policies",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => RestaurantCourseCancelPolicyResource,
        },
        {
          no: 13,
          name: "title_i18n_ja",
          kind: "scalar",
          jsonName: "titleI18nJa",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 14,
          name: "title_i18n_en",
          kind: "scalar",
          jsonName: "titleI18nEn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 15,
          name: "title_i18n_zh_cn",
          kind: "scalar",
          jsonName: "titleI18nZhCn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 16,
          name: "title_i18n_zh_tw",
          kind: "scalar",
          jsonName: "titleI18nZhTw",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 17,
          name: "title_i18n_zh_hk",
          kind: "scalar",
          jsonName: "titleI18nZhHk",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 18,
          name: "title_i18n_ko",
          kind: "scalar",
          jsonName: "titleI18nKo",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 19,
          name: "content_i18n_ja",
          kind: "scalar",
          jsonName: "contentI18nJa",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 20,
          name: "content_i18n_en",
          kind: "scalar",
          jsonName: "contentI18nEn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 21,
          name: "content_i18n_zh_cn",
          kind: "scalar",
          jsonName: "contentI18nZhCn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 22,
          name: "content_i18n_zh_tw",
          kind: "scalar",
          jsonName: "contentI18nZhTw",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 23,
          name: "content_i18n_zh_hk",
          kind: "scalar",
          jsonName: "contentI18nZhHk",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 24,
          name: "content_i18n_ko",
          kind: "scalar",
          jsonName: "contentI18nKo",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 25,
          name: "price_str",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 26,
          name: "title_i18n_th",
          kind: "scalar",
          jsonName: "titleI18nTh",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 27,
          name: "content_i18n_th",
          kind: "scalar",
          jsonName: "contentI18nTh",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 28,
          name: "image_urls",
          kind: "message",
          T: () => RestaurantCourseResource_ImageUrls,
        },
        { no: 29, name: "staying_time", kind: "message", T: () => Int32Value },
        {
          no: 30,
          name: "restaurant_course_reservation_period_policy",
          kind: "message",
          T: () => RestaurantCourseReservationPeriodPolicyResource,
        },
        {
          no: 31,
          name: "customer_region_restriction_type",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType",
            RestaurantCourseResource_CustomerRegionRestrictionType,
          ],
        },
        {
          no: 32,
          name: "position",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 33,
          name: "min_party_size",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 34,
          name: "max_party_size",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 35,
          name: "restaurant_course_stock_setting",
          kind: "message",
          T: () =>
            RestaurantCourseResource_RestaurantCourseStockSettingResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseResource>,
  ): RestaurantCourseResource {
    const message = {
      id: 0,
      price: 0,
      taxIncluded: false,
      taxType: 0,
      status: 0,
      title: "",
      content: "",
      tableMenus: [],
      questions: [],
      restaurantCourseCancelPolicies: [],
      titleI18NJa: "",
      titleI18NEn: "",
      titleI18NZhCn: "",
      titleI18NZhTw: "",
      titleI18NZhHk: "",
      titleI18NKo: "",
      contentI18NJa: "",
      contentI18NEn: "",
      contentI18NZhCn: "",
      contentI18NZhTw: "",
      contentI18NZhHk: "",
      contentI18NKo: "",
      priceStr: "",
      titleI18NTh: "",
      contentI18NTh: "",
      customerRegionRestrictionType: 0,
      position: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseResource,
  ): RestaurantCourseResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* float price */ 2:
          message.price = reader.float();
          break;
        case /* google.protobuf.Timestamp created_at */ 3:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 4:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* bool tax_included */ 5:
          message.taxIncluded = reader.bool();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type */ 6:
          message.taxType = reader.int32();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.Status status */ 7:
          message.status = reader.int32();
          break;
        case /* string title */ 8:
          message.title = reader.string();
          break;
        case /* string content */ 9:
          message.content = reader.string();
          break;
        case /* repeated auto_reserve.restaurants.table_menu.TableMenuResource table_menus */ 10:
          message.tableMenus.push(
            TableMenuResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource questions */ 11:
          message.questions.push(
            RestaurantCourseQuestionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies */ 12:
          message.restaurantCourseCancelPolicies.push(
            RestaurantCourseCancelPolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string title_i18n_ja = 13 [json_name = "titleI18nJa"];*/ 13:
          message.titleI18NJa = reader.string();
          break;
        case /* string title_i18n_en = 14 [json_name = "titleI18nEn"];*/ 14:
          message.titleI18NEn = reader.string();
          break;
        case /* string title_i18n_zh_cn = 15 [json_name = "titleI18nZhCn"];*/ 15:
          message.titleI18NZhCn = reader.string();
          break;
        case /* string title_i18n_zh_tw = 16 [json_name = "titleI18nZhTw"];*/ 16:
          message.titleI18NZhTw = reader.string();
          break;
        case /* string title_i18n_zh_hk = 17 [json_name = "titleI18nZhHk"];*/ 17:
          message.titleI18NZhHk = reader.string();
          break;
        case /* string title_i18n_ko = 18 [json_name = "titleI18nKo"];*/ 18:
          message.titleI18NKo = reader.string();
          break;
        case /* string content_i18n_ja = 19 [json_name = "contentI18nJa"];*/ 19:
          message.contentI18NJa = reader.string();
          break;
        case /* string content_i18n_en = 20 [json_name = "contentI18nEn"];*/ 20:
          message.contentI18NEn = reader.string();
          break;
        case /* string content_i18n_zh_cn = 21 [json_name = "contentI18nZhCn"];*/ 21:
          message.contentI18NZhCn = reader.string();
          break;
        case /* string content_i18n_zh_tw = 22 [json_name = "contentI18nZhTw"];*/ 22:
          message.contentI18NZhTw = reader.string();
          break;
        case /* string content_i18n_zh_hk = 23 [json_name = "contentI18nZhHk"];*/ 23:
          message.contentI18NZhHk = reader.string();
          break;
        case /* string content_i18n_ko = 24 [json_name = "contentI18nKo"];*/ 24:
          message.contentI18NKo = reader.string();
          break;
        case /* string price_str */ 25:
          message.priceStr = reader.string();
          break;
        case /* string title_i18n_th = 26 [json_name = "titleI18nTh"];*/ 26:
          message.titleI18NTh = reader.string();
          break;
        case /* string content_i18n_th = 27 [json_name = "contentI18nTh"];*/ 27:
          message.contentI18NTh = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.ImageUrls image_urls */ 28:
          message.imageUrls =
            RestaurantCourseResource_ImageUrls.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.imageUrls,
            );
          break;
        case /* google.protobuf.Int32Value staying_time */ 29:
          message.stayingTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.stayingTime,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy */ 30:
          message.restaurantCourseReservationPeriodPolicy =
            RestaurantCourseReservationPeriodPolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantCourseReservationPeriodPolicy,
            );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type */ 31:
          message.customerRegionRestrictionType = reader.int32();
          break;
        case /* int64 position */ 32:
          message.position = reader.int64().toNumber();
          break;
        case /* google.protobuf.Int32Value min_party_size */ 33:
          message.minPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.minPartySize,
          );
          break;
        case /* google.protobuf.Int32Value max_party_size */ 34:
          message.maxPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.maxPartySize,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.RestaurantCourseStockSettingResource restaurant_course_stock_setting */ 35:
          message.restaurantCourseStockSetting =
            RestaurantCourseResource_RestaurantCourseStockSettingResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantCourseStockSetting,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* float price = 2; */
    if (message.price !== 0) writer.tag(2, WireType.Bit32).float(message.price);
    /* google.protobuf.Timestamp created_at = 3; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 4; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool tax_included = 5; */
    if (message.taxIncluded !== false)
      writer.tag(5, WireType.Varint).bool(message.taxIncluded);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type = 6; */
    if (message.taxType !== 0)
      writer.tag(6, WireType.Varint).int32(message.taxType);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.Status status = 7; */
    if (message.status !== 0)
      writer.tag(7, WireType.Varint).int32(message.status);
    /* string title = 8; */
    if (message.title !== "")
      writer.tag(8, WireType.LengthDelimited).string(message.title);
    /* string content = 9; */
    if (message.content !== "")
      writer.tag(9, WireType.LengthDelimited).string(message.content);
    /* repeated auto_reserve.restaurants.table_menu.TableMenuResource table_menus = 10; */
    for (let i = 0; i < message.tableMenus.length; i++)
      TableMenuResource.internalBinaryWrite(
        message.tableMenus[i],
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource questions = 11; */
    for (let i = 0; i < message.questions.length; i++)
      RestaurantCourseQuestionResource.internalBinaryWrite(
        message.questions[i],
        writer.tag(11, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies = 12; */
    for (let i = 0; i < message.restaurantCourseCancelPolicies.length; i++)
      RestaurantCourseCancelPolicyResource.internalBinaryWrite(
        message.restaurantCourseCancelPolicies[i],
        writer.tag(12, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string title_i18n_ja = 13 [json_name = "titleI18nJa"]; */
    if (message.titleI18NJa !== "")
      writer.tag(13, WireType.LengthDelimited).string(message.titleI18NJa);
    /* string title_i18n_en = 14 [json_name = "titleI18nEn"]; */
    if (message.titleI18NEn !== "")
      writer.tag(14, WireType.LengthDelimited).string(message.titleI18NEn);
    /* string title_i18n_zh_cn = 15 [json_name = "titleI18nZhCn"]; */
    if (message.titleI18NZhCn !== "")
      writer.tag(15, WireType.LengthDelimited).string(message.titleI18NZhCn);
    /* string title_i18n_zh_tw = 16 [json_name = "titleI18nZhTw"]; */
    if (message.titleI18NZhTw !== "")
      writer.tag(16, WireType.LengthDelimited).string(message.titleI18NZhTw);
    /* string title_i18n_zh_hk = 17 [json_name = "titleI18nZhHk"]; */
    if (message.titleI18NZhHk !== "")
      writer.tag(17, WireType.LengthDelimited).string(message.titleI18NZhHk);
    /* string title_i18n_ko = 18 [json_name = "titleI18nKo"]; */
    if (message.titleI18NKo !== "")
      writer.tag(18, WireType.LengthDelimited).string(message.titleI18NKo);
    /* string content_i18n_ja = 19 [json_name = "contentI18nJa"]; */
    if (message.contentI18NJa !== "")
      writer.tag(19, WireType.LengthDelimited).string(message.contentI18NJa);
    /* string content_i18n_en = 20 [json_name = "contentI18nEn"]; */
    if (message.contentI18NEn !== "")
      writer.tag(20, WireType.LengthDelimited).string(message.contentI18NEn);
    /* string content_i18n_zh_cn = 21 [json_name = "contentI18nZhCn"]; */
    if (message.contentI18NZhCn !== "")
      writer.tag(21, WireType.LengthDelimited).string(message.contentI18NZhCn);
    /* string content_i18n_zh_tw = 22 [json_name = "contentI18nZhTw"]; */
    if (message.contentI18NZhTw !== "")
      writer.tag(22, WireType.LengthDelimited).string(message.contentI18NZhTw);
    /* string content_i18n_zh_hk = 23 [json_name = "contentI18nZhHk"]; */
    if (message.contentI18NZhHk !== "")
      writer.tag(23, WireType.LengthDelimited).string(message.contentI18NZhHk);
    /* string content_i18n_ko = 24 [json_name = "contentI18nKo"]; */
    if (message.contentI18NKo !== "")
      writer.tag(24, WireType.LengthDelimited).string(message.contentI18NKo);
    /* string price_str = 25; */
    if (message.priceStr !== "")
      writer.tag(25, WireType.LengthDelimited).string(message.priceStr);
    /* string title_i18n_th = 26 [json_name = "titleI18nTh"]; */
    if (message.titleI18NTh !== "")
      writer.tag(26, WireType.LengthDelimited).string(message.titleI18NTh);
    /* string content_i18n_th = 27 [json_name = "contentI18nTh"]; */
    if (message.contentI18NTh !== "")
      writer.tag(27, WireType.LengthDelimited).string(message.contentI18NTh);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.ImageUrls image_urls = 28; */
    if (message.imageUrls)
      RestaurantCourseResource_ImageUrls.internalBinaryWrite(
        message.imageUrls,
        writer.tag(28, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value staying_time = 29; */
    if (message.stayingTime)
      Int32Value.internalBinaryWrite(
        message.stayingTime,
        writer.tag(29, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy = 30; */
    if (message.restaurantCourseReservationPeriodPolicy)
      RestaurantCourseReservationPeriodPolicyResource.internalBinaryWrite(
        message.restaurantCourseReservationPeriodPolicy,
        writer.tag(30, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type = 31; */
    if (message.customerRegionRestrictionType !== 0)
      writer
        .tag(31, WireType.Varint)
        .int32(message.customerRegionRestrictionType);
    /* int64 position = 32; */
    if (message.position !== 0)
      writer.tag(32, WireType.Varint).int64(message.position);
    /* google.protobuf.Int32Value min_party_size = 33; */
    if (message.minPartySize)
      Int32Value.internalBinaryWrite(
        message.minPartySize,
        writer.tag(33, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value max_party_size = 34; */
    if (message.maxPartySize)
      Int32Value.internalBinaryWrite(
        message.maxPartySize,
        writer.tag(34, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.RestaurantCourseStockSettingResource restaurant_course_stock_setting = 35; */
    if (message.restaurantCourseStockSetting)
      RestaurantCourseResource_RestaurantCourseStockSettingResource.internalBinaryWrite(
        message.restaurantCourseStockSetting,
        writer.tag(35, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseResource
 */
export const RestaurantCourseResource = new RestaurantCourseResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseResource_ImageUrls$Type extends MessageType<RestaurantCourseResource_ImageUrls> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.ImageUrls",
      [
        { no: 1, name: "xl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "lg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "md", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 4, name: "sm", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseResource_ImageUrls>,
  ): RestaurantCourseResource_ImageUrls {
    const message = { xl: "", lg: "", md: "", sm: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseResource_ImageUrls>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseResource_ImageUrls,
  ): RestaurantCourseResource_ImageUrls {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string xl */ 1:
          message.xl = reader.string();
          break;
        case /* string lg */ 2:
          message.lg = reader.string();
          break;
        case /* string md */ 3:
          message.md = reader.string();
          break;
        case /* string sm */ 4:
          message.sm = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseResource_ImageUrls,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string xl = 1; */
    if (message.xl !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.xl);
    /* string lg = 2; */
    if (message.lg !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.lg);
    /* string md = 3; */
    if (message.md !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.md);
    /* string sm = 4; */
    if (message.sm !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.sm);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.ImageUrls
 */
export const RestaurantCourseResource_ImageUrls =
  new RestaurantCourseResource_ImageUrls$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseResource_RestaurantCourseStockSettingResource$Type extends MessageType<RestaurantCourseResource_RestaurantCourseStockSettingResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.RestaurantCourseStockSettingResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
        {
          no: 3,
          name: "stock_type",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseResource_RestaurantCourseStockSettingResource>,
  ): RestaurantCourseResource_RestaurantCourseStockSettingResource {
    const message = { id: "", quantity: 0, stockType: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseResource_RestaurantCourseStockSettingResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseResource_RestaurantCourseStockSettingResource,
  ): RestaurantCourseResource_RestaurantCourseStockSettingResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int32 quantity */ 2:
          message.quantity = reader.int32();
          break;
        case /* string stock_type */ 3:
          message.stockType = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseResource_RestaurantCourseStockSettingResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int32 quantity = 2; */
    if (message.quantity !== 0)
      writer.tag(2, WireType.Varint).int32(message.quantity);
    /* string stock_type = 3; */
    if (message.stockType !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.stockType);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.RestaurantCourseStockSettingResource
 */
export const RestaurantCourseResource_RestaurantCourseStockSettingResource =
  new RestaurantCourseResource_RestaurantCourseStockSettingResource$Type();
