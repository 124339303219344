import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import { createRpcService } from '../modules/rpc'
import useSWR, { fetcher, SWRConfiguration, swrKey } from '../modules/swr'

import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { TableSeatClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_service.client'

export * from '@hello-ai/ar_shared/src/types/ForR/TableSeat'

export const tableSeatService = createRpcService(TableSeatClient)

export function useTableSeat(
  tableSeatId: TableSeat['id'],
  params: {},
  config?: SWRConfiguration<TableSeat>
) {
  const token = useToken()

  const {
    data: tableSeat,
    error,
    mutate,
  } = useSWR<TableSeat>(
    swrKey(token, `/seats/${tableSeatId}`, params),
    ([token, url]: [token: string, url: string]) =>
      fetcher<TableSeat>([token, url, params]),
    config
  )

  return {
    tableSeat,
    error,
    mutate,
  }
}

export function useTableSeats(
  restaurantId: number,
  params: {},
  config?: SWRConfiguration<TableSeat[]>
) {
  const token = useToken()

  const {
    data: tableSeats,
    error,
    mutate,
    isLoading,
  } = useSWR<TableSeat[]>(
    swrKey(token, `/restaurants/${restaurantId}/seats`, params),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher<TableSeat[]>([token, url, params]),
    config
  )

  return {
    tableSeats: tableSeats ?? [],
    error,
    mutate,
    isLoading,
  }
}

export function useTableSeatsBulk(
  restaurantId: number,
  params: {
    ids: string[]
  }
) {
  const token = useToken()

  const {
    data: tableSeats,
    error,
    mutate,
  } = useSWR<TableSeat[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/seats/bulk`, params),
    ([token, url]) => fetcher<TableSeat[]>([token, url, params])
  )

  return {
    tableSeats,
    error,
    mutate,
  }
}
