import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import React from 'react'
import OwnersResetPassword from 'pages/Owners/ResetPassword'
import OwnersSignIn from 'pages/Owners/SignIn'

const routes: StackRoutes = {
  ResetPassword: {
    title: '',
    fullPath: '/owners/:id/reset_password',
    relativePath: '/reset_password',
    exact: true,
    element: <OwnersResetPassword />,
    screenOptions: {
      headerShown: false,
    },
  },
  SignIn: {
    title: '',
    fullPath: '/owners/:id/sign_in',
    relativePath: '/sign_in',
    exact: true,
    element: <OwnersSignIn />,
    screenOptions: {
      headerShown: false,
    },
  },
}

export default function OwnersStack() {
  return <Stack routes={routes} />
}
