import React from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'

import RNModal, {
  ModalProps,
  Orientation,
  ReactNativeModalRef,
} from '@tkow/react-native-modal'

const supportedOrientations: Orientation[] = [
  'portrait',
  'portrait-upside-down',
  'landscape',
  'landscape-left',
  'landscape-right',
]

export type ModalBaseRef = ReactNativeModalRef

function ModalBase(
  {
    isVisible,

    children,
    style,
    ...props
  }: {
    children?: React.ReactNode
    style?: StyleProp<ViewStyle>
    isVisible?: boolean
  } & Partial<ModalProps>,
  ref: React.Ref<ReactNativeModalRef>
) {
  return (
    <>
      {Platform.OS === 'web' && (
        <style>
          {/* modal表示時はscrollをprevent */}
          {`
        ${
          isVisible &&
          `html, body {
              overflow: hidden;
            }`
        }
        `}
        </style>
      )}
      <RNModal
        isVisible={isVisible}
        supportedOrientations={supportedOrientations}
        style={style}
        {...props}
        ref={ref}
      >
        {children}
      </RNModal>
    </>
  )
}

const Modal = React.forwardRef(ModalBase)

export { Modal as ModalBase }
