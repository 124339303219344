import React, { useCallback, useMemo, useState } from 'react'
import { FlatList, Platform, StyleProp, View, ViewStyle } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import {
  Customer as CustomerModel,
  useCustomersBulk,
  useCustomerSearch,
} from '../../../models/Customer'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Stepper } from '@hello-ai/ar_shared/src/components/Stepper'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Customer } from '../../Customers/ReservationCustomer'
import { CustomerLarge } from '../../Shared/Customer'
import Loading from '../../Shared/Loading'
import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'

import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import {
  ReservationFormState,
  ReservationFormStepProps,
  ReservationRepState,
} from './types'

import ModalCenter from '../../Shared/ModalCenter'
import { displayToastSuccess } from '../../Shared/Toast'
import { useReservationBookRestaurantCourses } from '../../../models/RestaurantCourse'
import produce from 'immer'
import QuantityButton from '../../Shared/QuantityButton'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { RestaurantReservationCourse as RestaurantReservationCourseModel } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservationCourse'
import { PhoneNumberInput } from '../../Shared/PhoneNumberInput'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { RestaurantCourseResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_resource'
import { useAllRestaurantCrewMembers } from '../../..//models/RestaurantCrewMembers'
import { useRestaurantAttributes } from '../../../models/CustomerAttributes'
import { useRestaurant } from '../../../models/Restaurant'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { getDefaultDialCode } from '../../../modules/phoneNumber'
import { useRestaurantCurrency } from '../../../modules/useRestaurantCurrency'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

function NumberInput({
  value,
  disabledLeft,
  disabledRight,
  onChangeValue,
  style,
  children,
}: {
  value: number
  disabledLeft?: boolean
  disabledRight?: boolean
  onChangeValue: (value: number) => void
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
        style,
      ]}
    >
      <QuantityButton
        disabled={disabledLeft}
        icon={faMinus}
        onPress={() => {
          onChangeValue(value - 1)
        }}
      />
      <View
        style={{
          marginHorizontal: 8,
        }}
      >
        {children}
      </View>
      <QuantityButton
        disabled={disabledRight}
        icon={faPlus}
        onPress={() => {
          onChangeValue(value + 1)
        }}
      />
    </View>
  )
}

function SelectReservationRepModal({
  restaurantId,
  isModalVisible,
  onClose,
  onPressNext,
  state,
}: {
  restaurantId: number
  isModalVisible: boolean
  onClose: () => void
  onPressNext: (value: Partial<ReservationFormState>) => void
  state: ReservationFormState
}) {
  const { width, sm } = useResponsive()
  const [reservationRep, setReservationRep] = useState<
    ReservationRepState | null | undefined
  >(state.reservationRep)
  const { restaurantCrewMembers } = useAllRestaurantCrewMembers(restaurantId)
  const items: SelectItem[] = restaurantCrewMembers
    ? restaurantCrewMembers.map((restaurantCrewMember) => {
        return {
          label: restaurantCrewMember.name,
          value: restaurantCrewMember.id,
        }
      })
    : []
  items.unshift({
    label: t('選択してください'),
    value: null,
  })
  const onPress = () => {
    onPressNext({ reservationRep })
  }
  return (
    <ModalCenter
      isModalVisible={isModalVisible}
      onClose={onClose}
      title={t('予約担当者を選択')}
      width="70%"
    >
      <ShadowBox>
        <FormGroup formLabel={<FormLabel value={t('担当者')} required />}>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <SelectInput
              style={width < sm && { marginLeft: 20 }}
              items={items}
              selectedValue={reservationRep ? reservationRep.id : null}
              setValue={(value) => {
                const businessUser = restaurantCrewMembers?.find(
                  (bu) => bu.id === value
                )
                setReservationRep(
                  businessUser
                    ? {
                        id: businessUser.id,
                        name: businessUser.name,
                      }
                    : null
                )
              }}
            />
          </View>
        </FormGroup>
      </ShadowBox>
      <View
        style={{
          borderTopWidth: 1,
          borderColor: Colors.border,
        }}
      >
        <Button
          style={{
            alignSelf: 'center',
            marginTop: 24,
          }}
          onPress={onPress}
          disabled={reservationRep == null}
        >
          {t('決定して確認に進む')}
        </Button>
      </View>
    </ModalCenter>
  )
}

function FormSection({
  style,
  title,
  children,
}: {
  style?: StyleProp<ViewStyle>
  title: String
  children?: React.ReactNode
}) {
  const { width, sm } = useResponsive()
  return (
    <View style={style}>
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
      <View style={{ marginTop: width < sm ? 0 : 24 }}>{children}</View>
    </View>
  )
}

const formGroupMargin = 28

export function Details({
  restaurantId,
  currentStep,
  skipSteps,
  state,
  onPressGoBack,
  onPressNext,
  additionalModal,
  setAdditionalModal,
  onPressNextForReservationRep,
}: ReservationFormStepProps & { restaurantId: number }) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null || restaurant.reservation_book_plan_type === 'free'
  const { width, sm } = useResponsive()
  const [currentPage, setCurrentPage] = useState(1)
  const [firstNameKana, setFirstNameKana] = useFormState('')
  const [lastNameKana, setLastNameKana] = useFormState('')
  const [firstName, setFirstName] = useFormState('')
  const [lastName, setLastName] = useFormState('')
  const [dialCode, setDialCode] = useFormState(getDefaultDialCode())
  const [phoneNumber, setPhoneNumber] = useFormState('')
  const [selectedCustomerIds, setSelectedCustomerIds] = useFormState<
    Array<CustomerModel['id']>
  >(state.selectedCustomerIds)
  const [allergy, setAllergy] = useFormState(state.allergy)
  const [memo, setMemo] = useFormState(state.memo)
  const { formatCurrency } = useRestaurantCurrency(restaurant)
  const { data } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const attributes = useMemo(
    () => data?.filter((e) => e.display_in_list) ?? [],
    [data]
  )

  const [selectedReservationCourses, setSelectedReservationCourses] =
    useFormState<
      Array<{
        id?: RestaurantReservationCourseModel['id'] | null
        partySize: number
        restaurantCourseId: RestaurantCourseResource['id'] | null
        remainingQuantity?: number | null
      }>
    >(() => {
      const selectedReservationCourses = state.selectedReservationCourses
      if (
        selectedReservationCourses.length === 0 ||
        selectedReservationCourses[selectedReservationCourses.length - 1]
          .restaurantCourseId != null
      ) {
        return [
          ...state.selectedReservationCourses,
          {
            id: null,
            partySize: state.adultPartySize + state.childPartySize,
            restaurantCourseId: null,
          },
        ]
      }
      return selectedReservationCourses
    })

  const reservedAt = dayjs(state.dateString, 'YYYY-MM-DD')
    .add(state.startTime, 'seconds')
    .toISOString()
  const { restaurantCourses } = useReservationBookRestaurantCourses(
    restaurantId,
    state.adultPartySize + state.childPartySize,
    reservedAt
  )

  const courseItems = useMemo(() => {
    const items: SelectItem[] = [
      {
        label: t('コースを選択'),
        value: null,
      },
    ]

    if (restaurantCourses != null) {
      items.push(
        ...restaurantCourses.map(({ id, title, price, stock_status }) => ({
          label: `${title} ${formatCurrency(price)}${stock_status?.remaining_quantity != null ? t('（残り在庫：{{quantity}}個）', { quantity: stock_status.remaining_quantity }) : ''}`,
          value: id,
        }))
      )
    }

    return items
  }, [restaurantCourses, formatCurrency])

  const {
    customers: customersSearch,
    mutate: mutateSearch,
    setSearchParams,
    headerData,
  } = useCustomerSearch(restaurantId, currentPage, 5)

  const { customers, mutate } = useCustomersBulk(restaurantId, {
    ids: selectedCustomerIds,
  })

  const onPressAddNewCustomer = () => {
    if (Platform.OS === 'web') {
      navigate({
        pathname: `/restaurants/${restaurantId}/customers/new`,
        search: new URLSearchParams({
          firstNameKana,
          lastNameKana,
          firstName,
          lastName,
          dialCode,
          phoneNumber,
          footerText: t('登録する'),
        }).toString(),
      })
    } else {
      navigation.navigate('AddNewCustomerModal', {
        onCustomerAdded,
        defaultProps: {
          firstNameKana,
          lastNameKana,
          firstName,
          lastName,
          dialCode,
          phoneNumber,
        },
        footerText: t('登録して予約に追加する'),
      })
    }
  }

  const onCustomerUpdated = () => {
    mutateSearch()
    mutate()
  }

  const onCustomerAdded = async (customerId: CustomerModel['id']) => {
    setSelectedCustomerIds((selectedCustomerIds) =>
      selectedCustomerIds.includes(customerId)
        ? selectedCustomerIds
        : [...selectedCustomerIds, customerId]
    )
    onCustomerUpdated()
    displayToastSuccess(t('新規顧客を登録し予約に追加しました'))
  }

  const onChangeSelectedReservationCourseRestaurantCourseId = (
    index: number,
    value: RestaurantCourseResource['id'] | null
  ) => {
    setSelectedReservationCourses((selectedRestaurantCourses) =>
      produce(selectedRestaurantCourses, (draft) => {
        draft[index].restaurantCourseId = value
        draft[index].remainingQuantity = restaurantCourses?.find(
          (course) => course.id === value
        )?.stock_status?.remaining_quantity
        if (value != null && index === draft.length - 1) {
          draft.push({
            partySize: 0,
            restaurantCourseId: null,
          })
        }
      })
    )
  }

  const onChangeSelectedReservationCoursePartySize = (
    index: number,
    value: number
  ) => {
    setSelectedReservationCourses((selectedRestaurantCourses) =>
      produce(selectedRestaurantCourses, (draft) => {
        draft[index].partySize = value
      })
    )
  }

  const onPressRemoveCustomer = (customerId: CustomerModel['id']) => {
    setSelectedCustomerIds((selectedCustomerIds) =>
      selectedCustomerIds.filter((id) => id !== customerId)
    )
    displayToastSuccess(t('予約からお客様を削除しました'))
  }

  const search = useCallback(() => {
    /**
     * 検索条件を設定すると、検索が実行されます。
     */
    setSearchParams({
      phoneNumber,
      dialCode,
      name: `${lastName} ${firstName}`.trim(),
      reservationLastName: lastNameKana,
      reservationFirstName: firstNameKana,
      lastName,
      firstName,
    })
    setCurrentPage(1)
  }, [
    phoneNumber,
    dialCode,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    setSearchParams,
    setCurrentPage,
  ])

  return (
    <Stepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={() => {
        // コースの人数の初期値を予約人数とする仕様があるが、予約idがnullの場合にもPartySizeを予約人数で送るとエラーになってしまう
        // 予約人数の仕様: https://github.com/hello-ai/auto_reserve/issues/19760
        // Form側で予約idがnullかつPatrySizeが0の場合はリクエストに含めない処理が入っているため、予約idがnullの場合はPartySizeを0にする
        // https://github.com/hello-ai/auto_reserve_front/blob/master/apps/for_r_native/app/screens/Restaurant/Reservations/Form.tsx#L191
        const selectedReservationCoursesValue = selectedReservationCourses.map(
          (selectedReservationCourse) =>
            selectedReservationCourse.restaurantCourseId == null
              ? {
                  id: selectedReservationCourse.id,
                  partySize: 0,
                  restaurantCourseId: null,
                  remainingQuantity: undefined,
                }
              : selectedReservationCourse
        )
        onPressNext({
          selectedCustomerIds,
          selectedReservationCourses: selectedReservationCoursesValue,
          allergy,
          memo,
        })
      }}
      isDisabledNextPress={false}
    >
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          paddingHorizontal: width < sm ? 20 : 32,
          paddingVertical: width < sm ? 24 : 48,
        }}
      >
        <FormSection title={t('お客様を検索')}>
          <ShadowBox
            style={{
              marginTop: width < sm ? 16 : 0,
              flexDirection: width < sm ? 'column' : 'row',
              padding: 0,
            }}
          >
            <View
              style={[
                { padding: width < sm ? 16 : 24 },
                Platform.OS === 'web' ? {} : { flex: 1, maxWidth: 360 },
              ]}
            >
              <FormGroup
                mode={'vertical'}
                formLabel={
                  <FormLabel
                    labelStyle={{
                      fontWeight: '600',
                      fontSize: 14,
                      lineHeight: 22,
                    }}
                    value={t('名前')}
                  />
                }
                containerStyle={{ marginTop: 8 }}
              >
                <View style={{ flexDirection: 'row', height: 48 }}>
                  <TextInput
                    placeholder={t('せい')}
                    style={{ flex: 1, height: 48 }}
                    value={lastNameKana}
                    onChangeText={(text) => setLastNameKana(text)}
                    returnKeyType={'done'}
                    onEndEditing={search}
                    onBlur={() => {
                      if (Platform.OS === 'web') {
                        search()
                      }
                    }}
                  />
                  <TextInput
                    placeholder={t('めい')}
                    style={{ flex: 1, marginLeft: 8, height: 48 }}
                    value={firstNameKana}
                    onChangeText={(text) => setFirstNameKana(text)}
                    returnKeyType={'done'}
                    onEndEditing={search}
                    onBlur={() => {
                      if (Platform.OS === 'web') {
                        search()
                      }
                    }}
                  />
                </View>
                <View
                  style={{
                    marginTop: 8,
                    flexDirection: 'row',
                    height: 48,
                  }}
                >
                  <TextInput
                    placeholder={t('姓')}
                    style={{ flex: 1, height: 48 }}
                    value={lastName}
                    onChangeText={(text) => setLastName(text)}
                    returnKeyType={'done'}
                    onEndEditing={search}
                    onBlur={() => {
                      if (Platform.OS === 'web') {
                        search()
                      }
                    }}
                  />
                  <TextInput
                    placeholder={t('名')}
                    style={{ flex: 1, marginLeft: 8, height: 48 }}
                    value={firstName}
                    onChangeText={(text) => setFirstName(text)}
                    returnKeyType={'done'}
                    onEndEditing={search}
                    onBlur={() => {
                      if (Platform.OS === 'web') {
                        search()
                      }
                    }}
                  />
                </View>
              </FormGroup>
              <FormGroup
                mode={'vertical'}
                formLabel={
                  <FormLabel
                    labelStyle={{
                      fontWeight: '600',
                      fontSize: 14,
                      lineHeight: 22,
                    }}
                    value={t('電話番号')}
                  />
                }
                style={{ marginTop: 24 }}
                containerStyle={{ marginTop: 8 }}
              >
                <PhoneNumberInput
                  dialCode={dialCode}
                  onChangeDialCode={setDialCode}
                >
                  <TextInput
                    style={[{ flex: 1 }, width < sm && { height: 48 }]}
                    placeholder={t('ハイフンなしで入力')}
                    value={phoneNumber}
                    onChangeText={(text) => setPhoneNumber(text)}
                    keyboardType="phone-pad"
                    textContentType="telephoneNumber"
                    clearButtonMode="always"
                    returnKeyType={'done'}
                    onEndEditing={search}
                    onBlur={() => {
                      if (Platform.OS === 'web') {
                        search()
                      }
                    }}
                  />
                </PhoneNumberInput>
              </FormGroup>
              <View
                style={{
                  display: 'flex',
                  flexDirection: width < sm ? 'row' : 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: width < sm ? 24 : 45,
                  width: '100%',
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: '300',
                    lineHeight: 18,
                    color: Colors.black60,
                    marginBottom: width < sm ? 0 : 8,
                  }}
                >
                  お客様情報が見つかりませんか？
                </Text>
                <Button mode="outline" onPress={onPressAddNewCustomer}>
                  {width >= sm ? t('新規顧客として登録する') : t('新規登録')}
                </Button>
              </View>
            </View>
            <View
              style={[
                width < sm
                  ? {
                      borderTopWidth: 0.5,
                      borderTopColor: Colors.border,
                    }
                  : {
                      borderLeftWidth: 0.5,
                      borderLeftColor: Colors.border,
                    },
                {
                  flex: 2,
                },
              ]}
            >
              {customersSearch === undefined ? (
                <Loading />
              ) : (
                <FlatList
                  scrollEnabled={false}
                  data={customersSearch}
                  keyExtractor={(item) => item.id}
                  ListEmptyComponent={() => (
                    <View
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 330,
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.secondaryBlack,
                          fontSize: 18,
                          lineHeight: 27,
                          marginBottom: width >= sm ? 24 : 16,
                        }}
                      >
                        お客様が見つかりません
                      </Text>
                      <Button mode="outline" onPress={onPressAddNewCustomer}>
                        新規顧客情報を登録する
                      </Button>
                    </View>
                  )}
                  renderItem={({ item: customer, index }) => {
                    return (
                      <View
                        key={customer.id}
                        style={{
                          borderTopWidth: index === 0 ? 0 : 0.5,
                          borderTopColor: Colors.border,
                          paddingHorizontal: width >= sm ? 24 : 16,
                        }}
                      >
                        <Customer
                          customer={customer}
                          onPressInfo={() => {
                            if (Platform.OS === 'web') {
                              navigate(
                                `/restaurants/${restaurantId}/customers/${customer.id}`
                              )
                            } else {
                              navigation.navigate('CustomerModal', {
                                customerId: customer.id,
                              })
                            }
                          }}
                          restaurant={restaurant}
                          selection={{
                            selected: selectedCustomerIds.includes(customer.id),
                            onPress: () => {
                              setSelectedCustomerIds(
                                (prevSelectedCustomerIds) => {
                                  if (
                                    prevSelectedCustomerIds.includes(
                                      customer.id
                                    )
                                  ) {
                                    return prevSelectedCustomerIds.filter(
                                      (id) => id !== customer.id
                                    )
                                  }
                                  return [
                                    ...prevSelectedCustomerIds,
                                    customer.id,
                                  ]
                                }
                              )
                              displayToastSuccess(
                                selectedCustomerIds.includes(customer.id)
                                  ? t('予約からお客様を削除しました')
                                  : t('予約にお客様を追加しました')
                              )
                            },
                          }}
                          attributes={attributes}
                        />
                      </View>
                    )
                  }}
                />
              )}
              <View
                style={{
                  borderTopWidth: 0.5,
                  borderColor: Colors.border,
                  paddingTop: 24,
                  marginBottom: 16,
                }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPage={headerData?.totalPages ?? 0}
                  setPage={(page) => setCurrentPage(page)}
                />
              </View>
            </View>
          </ShadowBox>
        </FormSection>

        <FormSection
          style={{
            marginTop: width < sm ? 24 : 48,
          }}
          title={t('予約のお客様')}
        >
          {customers === undefined ? (
            <Loading />
          ) : (
            <FlatList
              data={customers}
              keyExtractor={(item) => item.id}
              style={{
                marginHorizontal: -8,
              }}
              contentContainerStyle={{
                paddingTop: 32 / 2,
                paddingHorizontal: 8,
              }}
              renderItem={({ item: customer, index }) => {
                return (
                  <ShadowBox
                    key={customer.id}
                    style={{
                      marginTop: index === 0 ? 0 : width < sm ? 16 : 24,
                      padding: 0,
                    }}
                  >
                    <CustomerLarge
                      restaurantId={restaurantId}
                      customer={customer}
                      onCustomerUpdated={onCustomerUpdated}
                    />
                    <TouchableOpacity
                      onPress={() => onPressRemoveCustomer(customer.id)}
                      style={{
                        position: 'absolute',
                        top: -16,
                        right: -8,
                        width: 32,
                        height: 32,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color={Colors.black50}
                        size={26}
                      />
                    </TouchableOpacity>
                  </ShadowBox>
                )
              }}
              ListEmptyComponent={
                <Text
                  style={{
                    color: Colors.secondaryBlack,
                  }}
                >
                  {t('お客様が選択されていません')}
                </Text>
              }
            />
          )}
        </FormSection>
        <FormSection
          style={{
            marginTop: width < sm ? 24 : 48,
          }}
          title={t('予約の詳細')}
        >
          <ShadowBox
            style={{
              marginTop: width < sm ? 16 : 0,
              padding: width < sm ? 16 : 32,
              paddingBottom: width < sm ? 24 : 32,
            }}
          >
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={
                <FormLabel
                  icon={
                    <FontAwesomeIcon
                      icon={faUtensils}
                      color={Colors.black50}
                      size={16}
                    />
                  }
                  value={t('コース')}
                />
              }
            >
              {selectedReservationCourses.map(
                (
                  {
                    partySize,
                    restaurantCourseId: tableCourseId,
                    remainingQuantity,
                  },
                  index
                ) => {
                  return (
                    <View key={`${index}`}>
                      <View
                        key={`${index}`}
                        style={[
                          width < sm
                            ? {
                                flexDirection: 'column',
                                gap: 12,
                              }
                            : {
                                flexDirection: 'row',
                              },
                          index > 0 && {
                            marginTop: 16,
                          },
                        ]}
                      >
                        <SelectInput
                          items={courseItems}
                          selectedValue={tableCourseId}
                          setValue={(value) =>
                            onChangeSelectedReservationCourseRestaurantCourseId(
                              index,
                              value
                            )
                          }
                          style={{ height: 64 }}
                        />
                        {width < sm &&
                          remainingQuantity != null &&
                          remainingQuantity < partySize && (
                            <Text
                              style={{
                                fontSize: 14,
                                color: Colors.caution,
                              }}
                            >
                              {t(
                                'コースの在庫数を超える人数が登録されています'
                              )}
                            </Text>
                          )}
                        <NumberInput
                          style={[
                            width < sm
                              ? {
                                  alignSelf: 'flex-end',
                                }
                              : {
                                  marginLeft: 12,
                                },
                          ]}
                          value={partySize}
                          disabledLeft={partySize <= 0}
                          onChangeValue={(value) =>
                            onChangeSelectedReservationCoursePartySize(
                              index,
                              value
                            )
                          }
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              backgroundColor: Colors.field,
                              borderRadius: 8,
                              height: 48,
                              paddingHorizontal: 16,
                              minWidth: 74,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 18,
                                }}
                              >
                                {partySize}
                              </Text>
                              <Text
                                style={{
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: Colors.secondaryBlack,
                                }}
                              >
                                /{state.adultPartySize + state.childPartySize}
                              </Text>
                            </View>
                          </View>
                        </NumberInput>
                      </View>
                      {width >= sm &&
                        remainingQuantity != null &&
                        remainingQuantity < partySize && (
                          <Text
                            style={{
                              fontSize: 14,
                              color: Colors.caution,
                              marginTop: 8,
                            }}
                          >
                            {t('コースの在庫数を超える人数が登録されています')}
                          </Text>
                        )}
                    </View>
                  )
                }
              )}
            </FormGroup>

            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              style={{
                marginTop: formGroupMargin,
              }}
              formLabel={
                <FormLabel
                  icon={
                    <FontAwesomeIcon
                      icon={faFrown}
                      color={Colors.black50}
                      size={16}
                    />
                  }
                  value={t('アレルギー')}
                />
              }
            >
              <TextInput
                value={allergy}
                onChangeText={setAllergy}
                placeholder={t('アレルギー、苦手なもの')}
              />
            </FormGroup>

            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              style={{
                marginTop: formGroupMargin,
              }}
              formLabel={
                <FormLabel
                  icon={
                    <FontAwesomeIcon
                      icon={faEdit}
                      color={Colors.black50}
                      size={16}
                    />
                  }
                  value={t('メモ')}
                />
              }
            >
              <TextInput
                value={memo ?? ''}
                onChangeText={setMemo}
                placeholder={t('メモ')}
                multiline
                numberOfLines={3}
                style={{
                  minHeight: width < sm ? 156 : 88,
                }}
              />
            </FormGroup>
          </ShadowBox>
        </FormSection>
      </KeyboardAwareScrollView>
      <SelectReservationRepModal
        restaurantId={restaurantId}
        isModalVisible={additionalModal === 'select_reservation_rep'}
        onClose={() => setAdditionalModal?.(null)}
        onPressNext={(v) => onPressNextForReservationRep?.(v)}
        state={state}
      />
    </Stepper>
  )
}
