import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, {
  fetcher,
  mutate,
  onError,
  swrKey,
  useStickySWR,
} from 'modules/swr'
import { TableMenu } from './TableMenu'
import { useToken } from './Auth'

import { TableUnlimitedOrderPlan } from '@hello-ai/ar_shared/src/types/ForR/TableUnlimitedOrderPlan'
export * from '@hello-ai/ar_shared/src/types/ForR/TableUnlimitedOrderPlan'

export async function getTableMenuUnlimitedOrderPlans(
  token: string,
  tableMenuId: TableMenu['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.get<TableUnlimitedOrderPlan[]>(
      `/menus/${tableMenuId}/unlimited_order_plans`
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(token, `/menus/${tableMenuId}/unlimited_order_plans`),
      response.data,
      false
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableUnlimitedOrderPlans: response?.data,
    error,
  }
}

export function useTableMenuUnlimitedOrderPlans(
  tableMenuId: TableMenu['id'] | null
) {
  const token = useToken()

  const {
    data: tableUnlimitedOrderPlans,
    error,
    mutate,
  } = useSWR<TableUnlimitedOrderPlan[], any, ReturnType<typeof swrKey> | null>(
    tableMenuId == null
      ? null
      : swrKey(token, `/menus/${tableMenuId}/unlimited_order_plans`),
    fetcher
  )

  return {
    tableUnlimitedOrderPlans,
    isLoading: tableUnlimitedOrderPlans == null && error == null,
    error,
    mutate,
  }
}

export function useRestaurantTableUnlimitedOrderPlans(restaurantId: number) {
  const token = useToken()
  const {
    data: tableUnlimitedOrderPlans,
    error,
    mutate,
  } = useStickySWR<TableUnlimitedOrderPlan[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `restaurants/${restaurantId}/unlimited_order_plans`),
    fetcher
  )

  return {
    tableUnlimitedOrderPlans,
    isLoading: tableUnlimitedOrderPlans == null && error == null,
    mutate,
  }
}

export function useMenuRestaurantGroupUnlimitedOrderPlans(
  restaurantGroupId: string
) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    TableUnlimitedOrderPlan[],
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/menu_restaurant_groups/${restaurantGroupId}/unlimited_order_plans`
    ),
    fetcher
  )

  return {
    data: data ?? [],
    isLoading: data == null && error == null,
    error,
    mutate,
  }
}

export async function createMenuRestaurantGroupUnlimitedOrderPlan({
  restaurantGroupId,
  token,
  params,
}: {
  restaurantGroupId: string
  token: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableUnlimitedOrderPlan>(
      `/menu_restaurant_groups/${restaurantGroupId}/unlimited_order_plans`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/menu_restaurant_groups/${restaurantGroupId}/unlimited_order_plans/${response?.data?.id}`,
        params
      ),
      response.data,
      false
    )
  }

  return {
    TableUnlimitedOrderPlan: response?.data,
  }
}

export function useTableUnlimitedOrderPlan({
  tableUnlimitedOrderPlanId,
}: {
  tableUnlimitedOrderPlanId?: string
}) {
  const token = useToken()
  const {
    data: tableUnlimitedOrderPlan,
    error,
    mutate,
  } = useSWR<TableUnlimitedOrderPlan, any, ReturnType<typeof swrKey> | null>(
    tableUnlimitedOrderPlanId != null
      ? swrKey(token, `/unlimited_order_plans/${tableUnlimitedOrderPlanId}`)
      : null,
    fetcher
  )

  if (tableUnlimitedOrderPlanId == null) {
    return { tableUnlimitedOrderPlan, isLoading: false, mutate }
  }

  return {
    tableUnlimitedOrderPlan,
    isLoading: tableUnlimitedOrderPlan == null && error == null,
    mutate,
  }
}

export async function updateTableUnlimitedOrderPlan({
  token,
  tableUnlimitedOrderPlanId,
  params,
}: {
  token: string
  tableUnlimitedOrderPlanId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<TableUnlimitedOrderPlan>(
      `/unlimited_order_plans/${tableUnlimitedOrderPlanId}`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/unlimited_order_plans/${tableUnlimitedOrderPlanId}`),
      response.data,
      false
    )
  }

  return {
    tableUnlimitedOrderPlan: response?.data,
  }
}

export async function createTableUnlimitedOrderPlan({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableUnlimitedOrderPlan>(
      `/restaurants/${restaurantId}/unlimited_order_plans`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/unlimited_order_plans/${response?.data?.id}`),
      response.data,
      false
    )
  }

  return {
    tableUnlimitedOrderPlan: response?.data,
  }
}
