import React from 'react'
import { Customers } from '@hello-ai/for_r_app/src/components/Customers'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router-dom'

function CustomersPage() {
  const restaurantId = useRestaurantId()
  const { customerId } = useParams<{
    customerId: string
  }>()

  return <Customers restaurantId={restaurantId} customerId={customerId} />
}

export default CustomersPage
