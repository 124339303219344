import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import BusinessUsers from 'pages/BusinessUsers'
import BusinessUsersEdit from 'pages/BusinessUsers/Edit'
import BusinessUsersNew from 'pages/BusinessUsers/New'
import BusinessUsersChangeEmailRequestsConfirm from 'pages/BusinessUsers/ChangeEmailRequests/Confirm'
import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('メンバー'),
    fullPath: '/business_users',
    relativePath: '/',
    exact: true,
    element: <BusinessUsers />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  New: {
    title: t('メンバーを追加'),
    fullPath: '/business_users/new',
    relativePath: '/new',
    exact: true,
    element: <BusinessUsersNew />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('メンバーを編集'),
    fullPath: '/business_users/:businessUserId',
    relativePath: '/:businessUserId',
    exact: true,
    element: <BusinessUsersEdit />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  ChangeEmailRequestsConfirm: {
    title: t('メールアドレスの変更を確認'),
    fullPath:
      '/business_users/:businessUserId/change_email_requests/:changeEmailRequestToken/confirm',
    relativePath:
      '/:businessUserId/change_email_requests/:changeEmailRequestToken/confirm',
    exact: true,
    element: <BusinessUsersChangeEmailRequestsConfirm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function BusinessUsersStack() {
  return <Stack routes={routes} />
}
