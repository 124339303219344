// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course/restaurant_course_question_resource.proto" (package "auto_reserve.restaurants.restaurant_course", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource
 */
export interface RestaurantCourseQuestionResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 5;
   */
  category: RestaurantCourseQuestionResource_Category;
  /**
   * @generated from protobuf field: bool is_required = 6;
   */
  isRequired: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status status = 7;
   */
  status: RestaurantCourseQuestionResource_Status;
  /**
   * @generated from protobuf field: string name = 8;
   */
  name: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource selections = 10;
   */
  selections: RestaurantCourseQuestionSelectionResource[];
  /**
   * @generated from protobuf field: string description_i18n_ja = 12 [json_name = "descriptionI18nJa"];
   */
  descriptionI18NJa: string;
  /**
   * @generated from protobuf field: string description_i18n_en = 13 [json_name = "descriptionI18nEn"];
   */
  descriptionI18NEn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_cn = 14 [json_name = "descriptionI18nZhCn"];
   */
  descriptionI18NZhCn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_tw = 15 [json_name = "descriptionI18nZhTw"];
   */
  descriptionI18NZhTw: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_hk = 16 [json_name = "descriptionI18nZhHk"];
   */
  descriptionI18NZhHk: string;
  /**
   * @generated from protobuf field: string description_i18n_ko = 17 [json_name = "descriptionI18nKo"];
   */
  descriptionI18NKo: string;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status
 */
export enum RestaurantCourseQuestionResource_Status {
  /**
   * @generated from protobuf enum value: PUBLISHED = 0;
   */
  PUBLISHED = 0,
  /**
   * @generated from protobuf enum value: ARCHIVED = 1;
   */
  ARCHIVED = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category
 */
export enum RestaurantCourseQuestionResource_Category {
  /**
   * @generated from protobuf enum value: TEXT = 0;
   */
  TEXT = 0,
  /**
   * @generated from protobuf enum value: CHECKBOX = 1;
   */
  CHECKBOX = 1,
  /**
   * @generated from protobuf enum value: RADIO = 2;
   */
  RADIO = 2,
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource
 */
export interface RestaurantCourseQuestionSelectionResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status = 5;
   */
  status: RestaurantCourseQuestionSelectionResource_Status;
  /**
   * @generated from protobuf field: string description_i18n_ja = 6 [json_name = "descriptionI18nJa"];
   */
  descriptionI18NJa: string;
  /**
   * @generated from protobuf field: string description_i18n_en = 7 [json_name = "descriptionI18nEn"];
   */
  descriptionI18NEn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_cn = 8 [json_name = "descriptionI18nZhCn"];
   */
  descriptionI18NZhCn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_tw = 9 [json_name = "descriptionI18nZhTw"];
   */
  descriptionI18NZhTw: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_hk = 10 [json_name = "descriptionI18nZhHk"];
   */
  descriptionI18NZhHk: string;
  /**
   * @generated from protobuf field: string description_i18n_ko = 11 [json_name = "descriptionI18nKo"];
   */
  descriptionI18NKo: string;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status
 */
export enum RestaurantCourseQuestionSelectionResource_Status {
  /**
   * @generated from protobuf enum value: PUBLISHED = 0;
   */
  PUBLISHED = 0,
  /**
   * @generated from protobuf enum value: ARCHIVED = 1;
   */
  ARCHIVED = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseQuestionResource$Type extends MessageType<RestaurantCourseQuestionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 5,
          name: "category",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category",
            RestaurantCourseQuestionResource_Category,
          ],
        },
        {
          no: 6,
          name: "is_required",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 7,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status",
            RestaurantCourseQuestionResource_Status,
          ],
        },
        { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 10,
          name: "selections",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => RestaurantCourseQuestionSelectionResource,
        },
        {
          no: 12,
          name: "description_i18n_ja",
          kind: "scalar",
          jsonName: "descriptionI18nJa",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 13,
          name: "description_i18n_en",
          kind: "scalar",
          jsonName: "descriptionI18nEn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 14,
          name: "description_i18n_zh_cn",
          kind: "scalar",
          jsonName: "descriptionI18nZhCn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 15,
          name: "description_i18n_zh_tw",
          kind: "scalar",
          jsonName: "descriptionI18nZhTw",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 16,
          name: "description_i18n_zh_hk",
          kind: "scalar",
          jsonName: "descriptionI18nZhHk",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 17,
          name: "description_i18n_ko",
          kind: "scalar",
          jsonName: "descriptionI18nKo",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseQuestionResource>,
  ): RestaurantCourseQuestionResource {
    const message = {
      id: "",
      description: "",
      category: 0,
      isRequired: false,
      status: 0,
      name: "",
      selections: [],
      descriptionI18NJa: "",
      descriptionI18NEn: "",
      descriptionI18NZhCn: "",
      descriptionI18NZhTw: "",
      descriptionI18NZhHk: "",
      descriptionI18NKo: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseQuestionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseQuestionResource,
  ): RestaurantCourseQuestionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 3:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 4:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category */ 5:
          message.category = reader.int32();
          break;
        case /* bool is_required */ 6:
          message.isRequired = reader.bool();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status status */ 7:
          message.status = reader.int32();
          break;
        case /* string name */ 8:
          message.name = reader.string();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource selections */ 10:
          message.selections.push(
            RestaurantCourseQuestionSelectionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string description_i18n_ja = 12 [json_name = "descriptionI18nJa"];*/ 12:
          message.descriptionI18NJa = reader.string();
          break;
        case /* string description_i18n_en = 13 [json_name = "descriptionI18nEn"];*/ 13:
          message.descriptionI18NEn = reader.string();
          break;
        case /* string description_i18n_zh_cn = 14 [json_name = "descriptionI18nZhCn"];*/ 14:
          message.descriptionI18NZhCn = reader.string();
          break;
        case /* string description_i18n_zh_tw = 15 [json_name = "descriptionI18nZhTw"];*/ 15:
          message.descriptionI18NZhTw = reader.string();
          break;
        case /* string description_i18n_zh_hk = 16 [json_name = "descriptionI18nZhHk"];*/ 16:
          message.descriptionI18NZhHk = reader.string();
          break;
        case /* string description_i18n_ko = 17 [json_name = "descriptionI18nKo"];*/ 17:
          message.descriptionI18NKo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseQuestionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* google.protobuf.Timestamp created_at = 3; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 4; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 5; */
    if (message.category !== 0)
      writer.tag(5, WireType.Varint).int32(message.category);
    /* bool is_required = 6; */
    if (message.isRequired !== false)
      writer.tag(6, WireType.Varint).bool(message.isRequired);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status status = 7; */
    if (message.status !== 0)
      writer.tag(7, WireType.Varint).int32(message.status);
    /* string name = 8; */
    if (message.name !== "")
      writer.tag(8, WireType.LengthDelimited).string(message.name);
    /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource selections = 10; */
    for (let i = 0; i < message.selections.length; i++)
      RestaurantCourseQuestionSelectionResource.internalBinaryWrite(
        message.selections[i],
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description_i18n_ja = 12 [json_name = "descriptionI18nJa"]; */
    if (message.descriptionI18NJa !== "")
      writer
        .tag(12, WireType.LengthDelimited)
        .string(message.descriptionI18NJa);
    /* string description_i18n_en = 13 [json_name = "descriptionI18nEn"]; */
    if (message.descriptionI18NEn !== "")
      writer
        .tag(13, WireType.LengthDelimited)
        .string(message.descriptionI18NEn);
    /* string description_i18n_zh_cn = 14 [json_name = "descriptionI18nZhCn"]; */
    if (message.descriptionI18NZhCn !== "")
      writer
        .tag(14, WireType.LengthDelimited)
        .string(message.descriptionI18NZhCn);
    /* string description_i18n_zh_tw = 15 [json_name = "descriptionI18nZhTw"]; */
    if (message.descriptionI18NZhTw !== "")
      writer
        .tag(15, WireType.LengthDelimited)
        .string(message.descriptionI18NZhTw);
    /* string description_i18n_zh_hk = 16 [json_name = "descriptionI18nZhHk"]; */
    if (message.descriptionI18NZhHk !== "")
      writer
        .tag(16, WireType.LengthDelimited)
        .string(message.descriptionI18NZhHk);
    /* string description_i18n_ko = 17 [json_name = "descriptionI18nKo"]; */
    if (message.descriptionI18NKo !== "")
      writer
        .tag(17, WireType.LengthDelimited)
        .string(message.descriptionI18NKo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource
 */
export const RestaurantCourseQuestionResource =
  new RestaurantCourseQuestionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseQuestionSelectionResource$Type extends MessageType<RestaurantCourseQuestionSelectionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 5,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status",
            RestaurantCourseQuestionSelectionResource_Status,
          ],
        },
        {
          no: 6,
          name: "description_i18n_ja",
          kind: "scalar",
          jsonName: "descriptionI18nJa",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: "description_i18n_en",
          kind: "scalar",
          jsonName: "descriptionI18nEn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 8,
          name: "description_i18n_zh_cn",
          kind: "scalar",
          jsonName: "descriptionI18nZhCn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 9,
          name: "description_i18n_zh_tw",
          kind: "scalar",
          jsonName: "descriptionI18nZhTw",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 10,
          name: "description_i18n_zh_hk",
          kind: "scalar",
          jsonName: "descriptionI18nZhHk",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 11,
          name: "description_i18n_ko",
          kind: "scalar",
          jsonName: "descriptionI18nKo",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseQuestionSelectionResource>,
  ): RestaurantCourseQuestionSelectionResource {
    const message = {
      id: "",
      description: "",
      status: 0,
      descriptionI18NJa: "",
      descriptionI18NEn: "",
      descriptionI18NZhCn: "",
      descriptionI18NZhTw: "",
      descriptionI18NZhHk: "",
      descriptionI18NKo: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseQuestionSelectionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseQuestionSelectionResource,
  ): RestaurantCourseQuestionSelectionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 3:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 4:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status */ 5:
          message.status = reader.int32();
          break;
        case /* string description_i18n_ja = 6 [json_name = "descriptionI18nJa"];*/ 6:
          message.descriptionI18NJa = reader.string();
          break;
        case /* string description_i18n_en = 7 [json_name = "descriptionI18nEn"];*/ 7:
          message.descriptionI18NEn = reader.string();
          break;
        case /* string description_i18n_zh_cn = 8 [json_name = "descriptionI18nZhCn"];*/ 8:
          message.descriptionI18NZhCn = reader.string();
          break;
        case /* string description_i18n_zh_tw = 9 [json_name = "descriptionI18nZhTw"];*/ 9:
          message.descriptionI18NZhTw = reader.string();
          break;
        case /* string description_i18n_zh_hk = 10 [json_name = "descriptionI18nZhHk"];*/ 10:
          message.descriptionI18NZhHk = reader.string();
          break;
        case /* string description_i18n_ko = 11 [json_name = "descriptionI18nKo"];*/ 11:
          message.descriptionI18NKo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseQuestionSelectionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* google.protobuf.Timestamp created_at = 3; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 4; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status = 5; */
    if (message.status !== 0)
      writer.tag(5, WireType.Varint).int32(message.status);
    /* string description_i18n_ja = 6 [json_name = "descriptionI18nJa"]; */
    if (message.descriptionI18NJa !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.descriptionI18NJa);
    /* string description_i18n_en = 7 [json_name = "descriptionI18nEn"]; */
    if (message.descriptionI18NEn !== "")
      writer.tag(7, WireType.LengthDelimited).string(message.descriptionI18NEn);
    /* string description_i18n_zh_cn = 8 [json_name = "descriptionI18nZhCn"]; */
    if (message.descriptionI18NZhCn !== "")
      writer
        .tag(8, WireType.LengthDelimited)
        .string(message.descriptionI18NZhCn);
    /* string description_i18n_zh_tw = 9 [json_name = "descriptionI18nZhTw"]; */
    if (message.descriptionI18NZhTw !== "")
      writer
        .tag(9, WireType.LengthDelimited)
        .string(message.descriptionI18NZhTw);
    /* string description_i18n_zh_hk = 10 [json_name = "descriptionI18nZhHk"]; */
    if (message.descriptionI18NZhHk !== "")
      writer
        .tag(10, WireType.LengthDelimited)
        .string(message.descriptionI18NZhHk);
    /* string description_i18n_ko = 11 [json_name = "descriptionI18nKo"]; */
    if (message.descriptionI18NKo !== "")
      writer
        .tag(11, WireType.LengthDelimited)
        .string(message.descriptionI18NKo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource
 */
export const RestaurantCourseQuestionSelectionResource =
  new RestaurantCourseQuestionSelectionResource$Type();
