import React from 'react'
import { View } from 'react-native'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'

export function VariationIcon({
  amountDelta,
  size,
}: {
  amountDelta: number
  size: number
}) {
  if (amountDelta === 0) {
    return <View style={{ width: size }} />
  } else {
    return (
      <FontAwesomeIcon
        size={size}
        color={amountDelta > 0 ? Colors.success : Colors.caution}
        icon={amountDelta > 0 ? faCaretUp : faCaretDown}
      />
    )
  }
}
