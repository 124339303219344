import useSWR, { fetcher, swrKey } from 'modules/swr'
import { useAuth, useToken } from './Auth'
import { Restaurant } from './Restaurant'

import {
  Report,
  CanceledReport,
} from '@hello-ai/ar_shared/src/types/ForR/Report'

export type ReportType = 'daily' | 'monthly'
export * from '@hello-ai/ar_shared/src/types/ForR/Report'

export function useRestaurantReportsDaily(
  restaurantId: Restaurant['id'],
  date: string
) {
  const token = useToken()
  const {
    data: reportsDaily,
    error,
    mutate,
  } = useSWR<[Report, CanceledReport], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/reports/daily/${date}`),
    fetcher
  )

  return {
    reportsDaily,
    error,
    mutate,
  }
}

export function useRestaurantReport(
  restaurantId: number,
  reportType: ReportType,
  date: string
) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    [Report, CanceledReport],
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/restaurants/${restaurantId}/reports/${reportType}/${date}`),
    fetcher
  )

  return {
    data: data ?? [],
    isLoading: data == null && error != null,
    error,
    mutate,
  }
}

export function useRestaurantsReport(
  restaurant_ids: Restaurant['id'][],
  reportType: ReportType,
  date: string
) {
  const token = useToken()
  const { auth } = useAuth()
  const { data, error, mutate, isValidating } = useSWR<
    [Report, CanceledReport],
    any,
    ReturnType<typeof swrKey> | null
  >(
    auth != null && restaurant_ids.length > 0
      ? swrKey(token, `/owner/${auth?.id}/reports/${reportType}/${date}`, {
          restaurant_ids,
        })
      : null,
    ([token, url]) => fetcher([token, url, { restaurant_ids }])
  )

  return {
    data,
    error,
    mutate,
    isValidating,
  }
}
