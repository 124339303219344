import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { View } from 'react-native'
import React, { useState } from 'react'
import SendCodeForm from './SendCodeForm'
import VerifySMSCodeForm from './VerifySMSCodeForm'
import VerifyCallCodeForm from './VerifyCallCodeForm'
import DraftOwnerInfo from '../DraftOwnerInfo'
import {
  registerPhoneNumberForDraftOwner,
  sendPhoneNumberCodeForDraftOwner,
  sendTelephoneCodeForDraftOwner,
  verifyPhoneNumberForDraftOwner,
} from 'models/Auth'
import { isAxiosError } from 'modules/axios'

interface SendCodeState {
  type: 'sendCode'
  phoneNumber?: string
  dialCode?: string
}

interface VerifyCodeState {
  type: 'verifySMSCode' | 'verifyCallCode'
  phoneNumber: string
  dialCode: string
}

type States = SendCodeState | VerifyCodeState

interface VerifyPhoneFormProps {
  draftOwnerInfo: DraftOwnerInfo
  onPressNext: () => void
}

export default function VerifyPhoneForm({
  draftOwnerInfo,
  onPressNext,
}: VerifyPhoneFormProps) {
  const [state, setState] = useState<States>({
    type: 'sendCode',
  })
  const { width, sm } = useResponsive()
  const [, setErrors] = useState<Record<string, string>>({})

  const onPressSubmit = async (code: string) => {
    const { error } = await verifyPhoneNumberForDraftOwner(
      draftOwnerInfo.draftOwnerId,
      code
    )
    if (error != null) return
    onPressNext()
  }

  return (
    <View
      style={[
        { width: 600, marginTop: width < sm ? 40 : 80, alignItems: 'center' },
        width < sm && { width: '100%', paddingHorizontal: 12 },
      ]}
    >
      {state.type === 'sendCode' && (
        <SendCodeForm
          defaultValues={state}
          onPressSubmit={async (type, params) => {
            setErrors({})
            const convertedPhoneNumber = params.phoneNumber.replace(
              /^0/,
              `+${params.dialCode.slice(1)}`
            )
            const { error } = await registerPhoneNumberForDraftOwner(
              draftOwnerInfo.draftOwnerId,
              convertedPhoneNumber
            )
            if (error != null) {
              if (isAxiosError(error) && error.response?.status === 422) {
                setErrors(error.response?.data as Record<string, string>)
              }
              return
            }
            if (type === 'sms') {
              await sendPhoneNumberCodeForDraftOwner(
                draftOwnerInfo.draftOwnerId,
                convertedPhoneNumber,
                params.captchaToken
              )
            } else if (type === 'call') {
              await sendTelephoneCodeForDraftOwner(
                draftOwnerInfo.draftOwnerId,
                convertedPhoneNumber,
                params.captchaToken
              )
            }

            setState({
              type: type === 'sms' ? 'verifySMSCode' : 'verifyCallCode',
              dialCode: params.dialCode,
              phoneNumber: params.phoneNumber,
            })
          }}
        />
      )}
      {state.type === 'verifySMSCode' && (
        <VerifySMSCodeForm
          dialCode={state.dialCode}
          phoneNumber={state.phoneNumber}
          onPressBack={() => {
            setState({ type: 'sendCode' })
          }}
          onPressSubmit={onPressSubmit}
          onPressReSendCode={() => {
            setState((prev) => ({ ...prev, type: 'sendCode' }))
          }}
        />
      )}
      {state.type === 'verifyCallCode' && (
        <VerifyCallCodeForm
          dialCode={state.dialCode}
          phoneNumber={state.phoneNumber}
          onPressBack={() => {
            setState({ type: 'sendCode' })
          }}
          onPressSubmit={onPressSubmit}
          onPressResendCode={() => {
            setState((prev) => ({ ...prev, type: 'sendCode' }))
          }}
        />
      )}
    </View>
  )
}
