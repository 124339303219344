import useSWR, { swrKey, fetcher, mutate, onError } from 'modules/swr'
import { useToken } from './Auth'
import axios, { wrapResponse, setHeader } from 'modules/axios'

import { RestaurantBusinessTime } from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTime'
export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTime'

export function useRestaurantBusinessTimes(restaurantId: number) {
  const token = useToken()

  const {
    data: restaurantBusinessTimes,
    error,
    mutate,
  } = useSWR<RestaurantBusinessTime[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/business_times`),
    fetcher
  )

  return {
    restaurantBusinessTimes,
    error,
    mutate,
  }
}

export function useRestaurantBusinessTime(
  restaurantId: number,
  restaurantBusinessTimeId: RestaurantBusinessTime['id'] | undefined
) {
  const token = useToken()

  const {
    data: restaurantBusinessTime,
    error,
    mutate,
  } = useSWR<RestaurantBusinessTime, any, ReturnType<typeof swrKey> | null>(
    restaurantBusinessTimeId === undefined
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`
        ),
    fetcher
  )

  return {
    restaurantBusinessTime,
    error,
    mutate,
  }
}

export async function createRestaurantBusinessTime(
  token: string,
  restaurantId: number,
  params: {
    name: string
    start_time: number
    last_order_time: number
    end_time: number
    wdays: number[]
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<RestaurantBusinessTime>(
      `/restaurants/${restaurantId}/business_times`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/business_times/${response.data.id}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantBusinessTime: response?.data,
    error,
  }
}

export async function updateRestaurantBusinessTime(
  token: string,
  restaurantId: number,
  restaurantBusinessTimeId: RestaurantBusinessTime['id'],
  params: {
    name: string
    start_time: number
    last_order_time: number
    end_time: number
    wdays: number[]
  }
) {
  setHeader({ token })

  const { response, error } = await wrapResponse(
    axios.patch<RestaurantBusinessTime>(
      `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantBusinessTime: response?.data,
    error,
  }
}

export async function destroyRestaurantBusinessTime(
  token: string,
  restaurantId: number,
  restaurantBusinessTimeId: RestaurantBusinessTime['id']
) {
  setHeader({ token })

  const { error } = await wrapResponse(
    axios.delete<RestaurantBusinessTime>(
      `/restaurants/${restaurantId}/business_times/${restaurantBusinessTimeId}`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
  }
}
