import React, { useEffect } from 'react'

export function isClickInsideModal(event: MouseEvent) {
  return (
    event.target != null &&
    event.target instanceof HTMLElement &&
    event.target.closest('[aria-modal="true"]') !== null
  )
}

export default function useClickOutside<T extends HTMLElement>(
  ref: React.RefObject<T>,
  callback: (event: MouseEvent) => void
) {
  useEffect(() => {
    function onClickOutside(event: MouseEvent) {
      if (ref.current != null && !ref.current.contains(event.target as T)) {
        callback(event)
      }
    }

    document.addEventListener('mousedown', onClickOutside)
    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [callback, ref])
}
