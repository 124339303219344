import React, { useLayoutEffect, useState } from 'react'
import { View } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useToken } from 'models/Auth'
import { useNavigate, useLocation } from 'react-router-dom'

import { ownerService } from 'models/Owner'
import { onError } from 'modules/swr'
import invariant from 'tiny-invariant'
import { displayToastError } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import LinkText from 'components/Shared/LinkText'

import { useNavigation } from 'components/Shared/Navigation/Screen'
import SignInHeader from 'components/Shared/SignInHeader'
import { redirectApp } from 'modules/redirectApp'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Trans } from 'react-i18next'

export default function SignIn() {
  const { width, sm } = useResponsive()
  const token = useToken()

  const navigation = useNavigation()

  const [username, setUsername] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from

  const disabled = username === ''

  const onPressNext = async () => {
    if (disabled) return
    const { response, error } = await ownerService.getOwnerByUsername(token, {
      username,
    })

    if (error) {
      if (error.code === 'not_found') {
        displayToastError(t('オーナーIDが正しくありません'))
      } else {
        onError(error)
      }
      return
    }
    invariant(response !== undefined, 'response cannot be undefined')

    navigate(`/owners/${response.id}/sign_in`, {
      state: { from },
    })
  }

  useLayoutEffect(() => {
    if (token != null) {
      navigate(from ?? '/', { replace: true })
    }
  }, [navigate, from, token])

  useLayoutEffect(() => {
    navigation.setOptions({
      documentTitle: t('管理画面ログイン'),
    })
  }, [navigation])

  return (
    <View style={[{ flexGrow: 1 }]}>
      <SignInHeader />
      <View
        style={[
          {
            alignItems: 'center',
            marginTop: 80,
          },
          width < sm && {
            paddingHorizontal: 12,
          },
        ]}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
          }}
        >
          {t('アカウントにログインする')}
        </Text>
        <View style={{ marginTop: 40, justifyContent: 'center' }}>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('オーナーID')} />}
            style={{ justifyContent: 'center' }}
            formLabelContainerStyle={{
              width: 162,
              marginRight: 24,
            }}
            containerStyle={{
              width: width < sm ? '100%' : 420,
            }}
          >
            <TextInput
              onSubmitEditing={onPressNext}
              value={username}
              onChangeText={setUsername}
              clearButtonMode="always"
              autoCapitalize="none"
              placeholder="helloinc"
            />
          </FormGroup>
        </View>
        <Button
          disabled={username === ''}
          onPress={onPressNext}
          style={{
            marginTop: 48,
            width: width < sm ? '100%' : 278,
            height: 48,
          }}
        >
          {t('次へ')}
        </Button>
        <LinkText
          to="/sign_in/send_links"
          style={{
            marginTop: 24,
            color: Colors.primary,
          }}
        >
          {t('オーナーIDを忘れた方はこちら')}
        </LinkText>

        <TouchableOpacity
          onPress={() => {
            redirectApp(`sign_in`)
          }}
        >
          <Text style={{ marginTop: 24, color: Colors.primary, fontSize: 16 }}>
            {t('アプリでログインする')}
          </Text>
        </TouchableOpacity>
        <View
          style={{ marginTop: 18, flexDirection: 'row', alignItems: 'center' }}
        >
          {/* eslint-disable ar-i18n/require-translation-ja */}
          <Trans i18nKey={'<0>アカウントをお持ちでない方は</0><1>作成する</1>'}>
            <Text style={{ fontSize: 14 }}>
              {'アカウントをお持ちでない方は'}
            </Text>
            <LinkText
              style={{
                fontSize: 16,
                marginLeft: 8,
                color: Colors.primary,
              }}
              to="/sign_up"
            >
              {'作成する'}
            </LinkText>
          </Trans>
          {/* eslint-enable ar-i18n/require-translation-ja */}
        </View>
      </View>
    </View>
  )
}
