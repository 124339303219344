import { Text } from '../Text'
import React, { useImperativeHandle, useState } from 'react'
import type { AlertButton } from 'react-native'
// @ts-ignore
import { Portal } from 'react-native-paper'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from '../Dialog'

import invariant from 'tiny-invariant'
import { AlertMethods, AlertState } from './types'

export * from './types'

let alertRef: AlertMethods | null = null

const alertQueue: AlertState = []

export function setAlertRef(ref: AlertMethods | null) {
  alertRef = ref
  if (ref !== null) {
    while (alertQueue.length > 0) {
      const alertItem = alertQueue.shift()
      invariant(alertItem !== undefined, 'alertItem cannot be undefined')
      const { title, message, buttons } = alertItem
      ref.alert(title, message, buttons)
    }
  }
}

const AlertContext = React.createContext<
  [state: AlertState, setState: (value: AlertState) => void] | undefined
>(undefined)

function AlertModal({
  isVisible,
  onClose,
  title,
  message,
  buttons,
}: {
  isVisible: boolean
  onClose: () => void
  title: string
  message?: string
  buttons?: AlertButton[] // max 3
}) {
  const confirm = buttons?.find(({ style }) => style !== 'cancel')
  const cancel = buttons?.find(({ style }) => style === 'cancel')

  const onConfirm = () => {
    confirm?.onPress?.()
    onClose()
  }

  const onCancel = () => {
    cancel?.onPress?.()
    onClose()
  }

  return (
    <Portal>
      <Dialog isVisible={isVisible} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        {message !== undefined && (
          <DialogContent>
            <Text
              style={{
                textAlign: 'center',
              }}
            >
              {message}
            </Text>
          </DialogContent>
        )}
        <DialogActions>
          {cancel !== undefined && (
            <DialogAction variant="secondary" onPress={onCancel}>
              {cancel?.text ?? 'Cancel'}
            </DialogAction>
          )}
          <DialogAction onPress={onConfirm}>
            {confirm?.text ?? 'OK'}
          </DialogAction>
        </DialogActions>
      </Dialog>
    </Portal>
  )
}

export const AlertProvider = React.forwardRef<
  AlertMethods,
  {
    children?: React.ReactNode
  }
>(({ children }: { children?: React.ReactNode }, ref) => {
  const [state, setState] = useState<AlertState>([])

  useImperativeHandle(
    ref,
    () => {
      function alert(title: string, message?: string, buttons?: AlertButton[]) {
        setState((prevState) => [
          ...prevState,
          {
            title,
            message,
            buttons,
          },
        ])
      }

      return {
        alert,
      }
    },
    []
  )

  return (
    <AlertContext.Provider value={[state, setState]}>
      <Portal.Host>
        {children}
        {state.length > 0 && (
          <AlertModal
            isVisible
            onClose={() => {
              setState((prevState) => prevState.slice(1))
            }}
            title={state[0].title}
            message={state[0].message}
            buttons={state[0].buttons}
          />
        )}
      </Portal.Host>
    </AlertContext.Provider>
  )
})

function alert(title: string, message?: string, buttons?: AlertButton[]) {
  if (alertRef === null) {
    alertQueue.push({ title, message, buttons })
    return
  }
  alertRef?.alert(title, message, buttons)
}

export const Alert: AlertMethods = { alert }
