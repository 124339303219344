// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/owner/owner_service.proto" (package "auto_reserve.restaurants.owner", syntax proto3)
// tslint:disable
import { OwnerResource } from "./owner_resource";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "../../../google/protobuf/wrappers";
import { BusinessUserResource } from "../business_user/business_user_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.owner.GetOwnerRequest
 */
export interface GetOwnerRequest {
  /**
   * @generated from protobuf field: int32 id = 1;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.owner.GetOwnerByUsernameRequest
 */
export interface GetOwnerByUsernameRequest {
  /**
   * @generated from protobuf field: string username = 1;
   */
  username: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.owner.SignUpRequest
 */
export interface SignUpRequest {
  /**
   * @generated from protobuf field: string username = 1;
   */
  username: string;
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string;
  /**
   * @generated from protobuf field: string password = 3;
   */
  password: string;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.owner.SignUpResponse
 */
export interface SignUpResponse {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource business_user = 2;
   */
  businessUser?: BusinessUserResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.owner.ChangeUsernameRequest
 */
export interface ChangeUsernameRequest {
  /**
   * @generated from protobuf field: string username = 1;
   */
  username: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.owner.UpdateOwnerRequest
 */
export interface UpdateOwnerRequest {
  /**
   * @generated from protobuf field: google.protobuf.StringValue name = 1;
   */
  name?: StringValue;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetOwnerRequest$Type extends MessageType<GetOwnerRequest> {
  constructor() {
    super("auto_reserve.restaurants.owner.GetOwnerRequest", [
      { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
    ]);
  }
  create(value?: PartialMessage<GetOwnerRequest>): GetOwnerRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetOwnerRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetOwnerRequest,
  ): GetOwnerRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int32 id */ 1:
          message.id = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetOwnerRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int32(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.owner.GetOwnerRequest
 */
export const GetOwnerRequest = new GetOwnerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOwnerByUsernameRequest$Type extends MessageType<GetOwnerByUsernameRequest> {
  constructor() {
    super("auto_reserve.restaurants.owner.GetOwnerByUsernameRequest", [
      { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(
    value?: PartialMessage<GetOwnerByUsernameRequest>,
  ): GetOwnerByUsernameRequest {
    const message = { username: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetOwnerByUsernameRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetOwnerByUsernameRequest,
  ): GetOwnerByUsernameRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string username */ 1:
          message.username = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetOwnerByUsernameRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string username = 1; */
    if (message.username !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.username);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.owner.GetOwnerByUsernameRequest
 */
export const GetOwnerByUsernameRequest = new GetOwnerByUsernameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignUpRequest$Type extends MessageType<SignUpRequest> {
  constructor() {
    super("auto_reserve.restaurants.owner.SignUpRequest", [
      { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<SignUpRequest>): SignUpRequest {
    const message = { username: "", email: "", password: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SignUpRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SignUpRequest,
  ): SignUpRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string username */ 1:
          message.username = reader.string();
          break;
        case /* string email */ 2:
          message.email = reader.string();
          break;
        case /* string password */ 3:
          message.password = reader.string();
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SignUpRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string username = 1; */
    if (message.username !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.username);
    /* string email = 2; */
    if (message.email !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.email);
    /* string password = 3; */
    if (message.password !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.password);
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.owner.SignUpRequest
 */
export const SignUpRequest = new SignUpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignUpResponse$Type extends MessageType<SignUpResponse> {
  constructor() {
    super("auto_reserve.restaurants.owner.SignUpResponse", [
      { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "business_user",
        kind: "message",
        T: () => BusinessUserResource,
      },
    ]);
  }
  create(value?: PartialMessage<SignUpResponse>): SignUpResponse {
    const message = { token: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SignUpResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SignUpResponse,
  ): SignUpResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string token */ 1:
          message.token = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource business_user */ 2:
          message.businessUser = BusinessUserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.businessUser,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SignUpResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string token = 1; */
    if (message.token !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.token);
    /* auto_reserve.restaurants.business_user.BusinessUserResource business_user = 2; */
    if (message.businessUser)
      BusinessUserResource.internalBinaryWrite(
        message.businessUser,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.owner.SignUpResponse
 */
export const SignUpResponse = new SignUpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeUsernameRequest$Type extends MessageType<ChangeUsernameRequest> {
  constructor() {
    super("auto_reserve.restaurants.owner.ChangeUsernameRequest", [
      { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ChangeUsernameRequest>): ChangeUsernameRequest {
    const message = { username: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ChangeUsernameRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ChangeUsernameRequest,
  ): ChangeUsernameRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string username */ 1:
          message.username = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ChangeUsernameRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string username = 1; */
    if (message.username !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.username);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.owner.ChangeUsernameRequest
 */
export const ChangeUsernameRequest = new ChangeUsernameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOwnerRequest$Type extends MessageType<UpdateOwnerRequest> {
  constructor() {
    super("auto_reserve.restaurants.owner.UpdateOwnerRequest", [
      { no: 1, name: "name", kind: "message", T: () => StringValue },
    ]);
  }
  create(value?: PartialMessage<UpdateOwnerRequest>): UpdateOwnerRequest {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateOwnerRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateOwnerRequest,
  ): UpdateOwnerRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue name */ 1:
          message.name = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.name,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateOwnerRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue name = 1; */
    if (message.name)
      StringValue.internalBinaryWrite(
        message.name,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.owner.UpdateOwnerRequest
 */
export const UpdateOwnerRequest = new UpdateOwnerRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.owner.Owner
 */
export const Owner = new ServiceType("auto_reserve.restaurants.owner.Owner", [
  { name: "GetOwner", options: {}, I: GetOwnerRequest, O: OwnerResource },
  {
    name: "GetOwnerByUsername",
    options: {},
    I: GetOwnerByUsernameRequest,
    O: OwnerResource,
  },
  { name: "SignUp", options: {}, I: SignUpRequest, O: SignUpResponse },
  {
    name: "ChangeUsername",
    options: {},
    I: ChangeUsernameRequest,
    O: OwnerResource,
  },
  { name: "UpdateOwner", options: {}, I: UpdateOwnerRequest, O: OwnerResource },
]);
