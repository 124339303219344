import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
export default function Row({
  mode = 'inline',
  style,
  icon,
  children,
}: {
  mode?: 'inline' | 'inline-expanded'
  icon: IconDefinition
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: mode === 'inline' ? 'center' : 'flex-start',
        },
        style,
      ]}
    >
      <FontAwesomeIcon
        style={{ marginRight: 14 }}
        icon={icon}
        size={16}
        color={Colors.black50}
      />
      {children}
    </View>
  )
}
