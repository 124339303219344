import React, { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import CreateAccountForm from 'components/SignUp/CreateAccountForm'
import { signInFromDraftOwnerInfo } from 'models/Auth'
import { SingUpHeader } from 'components/Shared/SignInHeader'
import VerifyEmailForm from 'components/SignUp/VerifyEmailForm'
import VerifyPhoneForm from 'components/SignUp/VerifyPhoneForm'
import DraftOwnerInfo from 'components/SignUp/DraftOwnerInfo'
import { useNavigate } from 'react-router'
import OwnerAccountForm from 'components/SignUp/OwnerAccountForm'

export default function SignUp() {
  const navigate = useNavigate()
  const [_currentStepNumber, setCurrentStepNumber] = useState(1)
  const [draftOwnerInfo, setDraftOwnerInfo] = useState<DraftOwnerInfo | null>(
    null
  )

  const onPressNext = useCallback(() => {
    setCurrentStepNumber((prev) => prev + 1)
  }, [])

  const currentStepNumber = useMemo(() => {
    if (draftOwnerInfo == null) {
      return 1
    }
    return _currentStepNumber
  }, [draftOwnerInfo, _currentStepNumber])

  const component = useMemo(() => {
    if (draftOwnerInfo == null) {
      return (
        <CreateAccountForm
          onPressNext={(draftOwnerInfo) => {
            setDraftOwnerInfo(draftOwnerInfo)
          }}
        />
      )
    } else {
      switch (currentStepNumber) {
        case 1: {
          return (
            <VerifyEmailForm
              draftOwnerInfo={draftOwnerInfo}
              onPressNext={() => {
                onPressNext()
              }}
              onPressBack={() => {
                setDraftOwnerInfo(null)
              }}
            />
          )
        }
        case 2: {
          return (
            <VerifyPhoneForm
              draftOwnerInfo={draftOwnerInfo}
              onPressNext={async () => {
                onPressNext()
              }}
            />
          )
        }
        case 3: {
          return (
            <OwnerAccountForm
              draftOwnerInfo={draftOwnerInfo}
              onPressNext={async ({ username }) => {
                await signInFromDraftOwnerInfo(username, draftOwnerInfo)
                navigate('/', { replace: true })
              }}
            />
          )
        }
      }
    }
  }, [currentStepNumber, draftOwnerInfo, onPressNext, navigate])

  return (
    <View>
      <SingUpHeader currentStep={currentStepNumber} />
      <View style={{ alignItems: 'center', paddingBottom: 40 }}>
        {component}
      </View>
    </View>
  )
}
