import React, { ComponentProps } from 'react'
import { View } from 'react-native'
import { Pagination } from '../../../Pagination'
import { Text } from '../../../Text'
import { t } from '../../../../modules/i18n'
import { SyncFailedReservation } from '../../../../types/ForR/SiteControllerSeat'
import FailedListItem from './FailedListItemView'
import { Colors } from '../../../../constants/Colors'

interface FailedListViewProps {
  failedReservations: SyncFailedReservation[]
  paginationProps: ComponentProps<typeof Pagination>
  onPressDeleteFailedReservation: (
    failedReservation: SyncFailedReservation
  ) => Promise<void>
}

export default function FailedReservationListView({
  failedReservations,
  paginationProps,
  onPressDeleteFailedReservation,
}: FailedListViewProps) {
  return (
    <View>
      <View
        style={{
          paddingVertical: 16,
          flexDirection: 'row',
          columnGap: 16,
        }}
      >
        <Text
          style={{ fontSize: 14, fontWeight: '600', lineHeight: 22, flex: 1 }}
        >
          {t('問題がある予約データ')}
        </Text>
        <Text
          style={{ fontSize: 14, fontWeight: '600', lineHeight: 22, width: 90 }}
        >
          {t('ステータス')}
        </Text>
        <Text
          style={{ fontSize: 14, fontWeight: '600', lineHeight: 22, width: 90 }}
        >
          {t('アクション')}
        </Text>
      </View>
      <View
        style={{
          borderTopWidth: 0.6,
          borderBottomWidth: 0.6,
          borderColor: Colors.border,
        }}
      >
        {failedReservations.map((reservation, index) => (
          <FailedListItem
            index={index}
            key={reservation.id}
            failedReservation={reservation}
            onPressDelete={async () => {
              await onPressDeleteFailedReservation(reservation)
            }}
          />
        ))}
        {failedReservations.length === 0 && (
          <View style={{ paddingVertical: 80 }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '300',
                lineHeight: 24,
                color: Colors.black60,
                textAlign: 'center',
              }}
            >
              {t('問題がある予約データはありません')}
            </Text>
          </View>
        )}
      </View>
      <View style={{ paddingTop: 16 }}>
        <Pagination {...paginationProps} />
      </View>
    </View>
  )
}
