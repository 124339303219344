import React from 'react'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import AccountSettings from 'pages/Restaurants/GourmetSiteAccount/GourmetSiteAccountSetting'
import StatusLabel from 'components/GourmetSite/AccountSettings/StatusLabel'
import AccountSettingDetail from 'pages/Restaurants/GourmetSiteAccount/GourmetSiteAccountSettingDetail'
import AccountSettingInitForm from 'pages/Restaurants/GourmetSiteAccount/GourmetSiteAccountSettingInitForm'
import AccountEditLoginForm from 'pages/Restaurants/GourmetSiteAccount/GourmetSiteAccountSettingEditLoginInfo'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('アカウント連携'),
    fullPath: `/restaurants/:id/gourmet_site/account_settings`,
    relativePath: `/`,
    exact: true,
    element: <AccountSettings />,
    screenOptions: {
      headerRight: <StatusLabel />,
    },
  },
  Detail: {
    title: t('連携設定'),
    fullPath: `/restaurants/:id/gourmet_site/account_settings/:source`,
    relativePath: `/:source`,
    element: <AccountSettingDetail />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
  InitForm: {
    title: t('連携設定'),
    fullPath: `/restaurants/:id/gourmet_site/account_settings/:source/init_form`,
    relativePath: `/:source/init_form`,
    element: <AccountSettingInitForm />,
  },
  EditLoginForm: {
    title: t('設定中のログイン情報を編集'),
    fullPath: `/restaurants/:id/gourmet_site/account_settings/:source/edit_login_info/:outboundSettingId`,
    relativePath: `/:source/edit_login_info/:outboundSettingId`,
    element: <AccountEditLoginForm />,
  },
}

export default function GourmetSiteAccountSettingStack() {
  return <Stack routes={routes} />
}
