import { View, FlatList } from 'react-native'
import { Text } from '../Text'

import { TouchableOpacity } from '../Touchables'
import { Colors } from '../..//constants/Colors'
import { TextInput } from '../TextInput'
import CountryCodes from './CountryCodes'
import React, { useState } from 'react'
import { Radio } from '../Radio'
import { t } from '../../modules/i18n'
import {
  HeaderBackButton,
  ModalCenter,
  ModalCenterProps,
  ModalHeader,
} from '../ModalCenter'

interface CountryItemProps {
  dial_code: string
  name_i18n: string
  defaultChecked: boolean
  onPress: () => void
}

function CountryItem({
  name_i18n,
  dial_code,
  defaultChecked,
  onPress,
}: CountryItemProps) {
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 12,
      }}
      onPress={onPress}
    >
      <Text style={{ fontSize: 12, fontWeight: '300', lineHeight: 18 }}>
        {name_i18n}（{dial_code}）
      </Text>
      <View style={{ pointerEvents: 'none' }} pointerEvents="none">
        <Radio
          value={undefined}
          selected={defaultChecked}
          onChange={() => {
            onPress()
          }}
        />
      </View>
    </TouchableOpacity>
  )
}

type Country = (typeof CountryCodes)[number]

interface CountryModalProps extends ModalCenterProps {
  defaultSelectedCountry: Country
  onPressCountry: (country: Country) => void
}

export default function CountryModal({
  defaultSelectedCountry,
  onPressCountry,
  ...rest
}: CountryModalProps) {
  const [filteredCountries, setFilteredCountries] = useState(CountryCodes)

  const [searchText, setSearchText] = useState('')
  return (
    <ModalCenter mode="fullScreen" {...rest}>
      <View
        style={{
          shadowRadius: 8,
          shadowColor: 'rgba(0, 0, 0, 0.08)',
          shadowOffset: { width: 0, height: 8 },
          shadowOpacity: 1,
        }}
      >
        <ModalHeader
          variant="primary"
          headerLeft={<HeaderBackButton onPress={rest.onClose} />}
        >
          {t('国・地域番号')}
        </ModalHeader>
        <View
          style={{
            paddingHorizontal: 16,
            paddingVertical: 12,
          }}
        >
          <TextInput
            style={{
              height: 'auto',
              backgroundColor: Colors.field,
              padding: 16,
              fontSize: 14,
              fontWeight: '300',
            }}
            placeholder={t('国・地域名または番号を検索')}
            value={searchText}
            onChangeText={(text) => {
              setSearchText(text)
              const lowerText = text.toLowerCase()
              const result = CountryCodes.filter((country) => {
                return (
                  country.name.toLocaleLowerCase().includes(lowerText) ||
                  country.name_i18n.toLocaleLowerCase().includes(text) ||
                  country.dial_code.includes(text)
                )
              })
              setFilteredCountries(result)
            }}
          />
        </View>
      </View>
      <FlatList
        data={filteredCountries}
        keyExtractor={(item) => item.name}
        maxToRenderPerBatch={10}
        renderItem={({ item }) => (
          <CountryItem
            key={item.code}
            {...item}
            defaultChecked={item.name === defaultSelectedCountry.name}
            onPress={() => {
              onPressCountry(item)
            }}
          />
        )}
        style={{
          width: '100%',
          flex: 1,
          paddingHorizontal: 16,
          paddingVertical: 8,
        }}
      />
    </ModalCenter>
  )
}

export { CountryCodes }
