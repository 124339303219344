import React, { useMemo } from 'react'
import { Text, View } from 'react-native'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { DetailRowView as BalancingDetailRowView } from '@hello-ai/for_r_app/src/components/Restaurant/TableBalancingCashDrawer/DetailRowView'
import { HistoryTable } from './TableBalancingCashDrawer/HistoryTable'
import {
  useBalancingCashDrawers,
  TableBalancingCashDrawer,
} from 'models/TableBalancingCashDrawer'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

// 当日最新の締め点検
function LatestBalancingCashDrawerOnTheDay({
  balancingCashDrawers,
  date,
  restaurantId,
}: {
  balancingCashDrawers: TableBalancingCashDrawer[]
  date: dayjs.Dayjs
  restaurantId: number
}) {
  const { width, sm } = useResponsive()

  const latestBalancingCashDrawer = useMemo(
    () => balancingCashDrawers.sort((a, b) => b.order - a.order).at(0),
    [balancingCashDrawers]
  )

  if (latestBalancingCashDrawer == null) return null

  return (
    <View>
      <BalancingDetailRowView
        balancingCashDrawer={latestBalancingCashDrawer}
        showTotalOverAndShortAmount
      />
      <View
        style={[
          {
            marginVertical: 40,
            paddingHorizontal: 32,
          },
          width < sm && { paddingHorizontal: 14 },
        ]}
      >
        <HistoryTable
          balancingCashDrawers={balancingCashDrawers}
          date={date}
          restaurantId={restaurantId}
        />
      </View>
    </View>
  )
}

export function CashDrawerManagement({
  date,
  restaurantId,
}: {
  date: dayjs.Dayjs
  restaurantId: number
}) {
  const { balancingCashDrawers } = useBalancingCashDrawers(
    restaurantId,
    date,
    date
  )

  if (balancingCashDrawers == null) return null
  if (balancingCashDrawers.length === 0) return null

  return (
    <View>
      <View>
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
            marginTop: 48,
          }}
        >
          {t('現金管理')}
        </Text>
      </View>
      <ShadowBox
        style={[
          {
            padding: 0,
            overflow: 'hidden',
            marginTop: 24,
          },
        ]}
      >
        <LatestBalancingCashDrawerOnTheDay
          balancingCashDrawers={balancingCashDrawers}
          date={date}
          restaurantId={restaurantId}
        />
      </ShadowBox>
    </View>
  )
}
