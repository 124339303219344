import React, { useState } from 'react'
import { Colors } from '../../../../constants/Colors'
import { t } from '../../../../modules/i18n'
import { View } from 'react-native'
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo'
import { ExecuteHistory } from '../../../../types/ForR/SiteController'
import dayjs from '../../../../modules/dayjs'
import SectionTitle from '../Shared/SectionTitle'
import { TouchableOpacity } from '../../../Touchables'
import { FontAwesomeIcon } from '../../../FontAwesomeIcon'
import { Text } from '../../../Text'

interface SeatSettingSyncProps {
  onPressSync: () => Promise<void>
  latestExecutionHistory?: ExecuteHistory
}

export default function SeatSettingSync({
  onPressSync,
  latestExecutionHistory,
}: SeatSettingSyncProps) {
  const [isSyncing, setIsSyncing] = useState(false)
  return (
    <View>
      <SectionTitle
        title={t('グルメサイトの席情報の取得')}
        helperText={t(
          '席情報を更新します。席情報を最新の状態にするためには、席情報の取得を押してください'
        )}
      />
      <View
        style={{
          backgroundColor: Colors.white,
          paddingVertical: 24,
          paddingHorizontal: 16,
          position: 'relative',
          borderRadius: 8,
          borderWidth: 0.5,
          borderColor: Colors.border,
        }}
      >
        <View style={{ flexDirection: 'column' }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              color: Colors.black,
            }}
          >
            {t('最終更新者：{{name}}', {
              name: latestExecutionHistory?.business_user?.name ?? '-',
            })}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              color: Colors.black,
            }}
          >
            {t('最終更新日：{{date}}', {
              date:
                latestExecutionHistory != null
                  ? dayjs(latestExecutionHistory.executed_at)
                      .tz()
                      .format('YYYY/MM/DD HH:mm')
                  : '-',
            })}
          </Text>
        </View>
        <TouchableOpacity
          onPress={async () => {
            try {
              setIsSyncing(true)
              await onPressSync()
            } finally {
              setIsSyncing(false)
            }
          }}
          style={{
            flexDirection: 'row',
            columnGap: 8,
            paddingHorizontal: 24,
            paddingVertical: 12,
            borderRadius: 24,
            backgroundColor: Colors.primaryBg,
            position: 'absolute',
            right: 16,
            top: 24,
          }}
          disabled={isSyncing}
        >
          <FontAwesomeIcon
            icon={faUndo}
            size={24}
            color={isSyncing ? Colors.disabledPrimary : Colors.primary}
          />
          <Text
            style={{
              color: isSyncing ? Colors.disabledPrimary : Colors.primary,
              fontWeight: '600',
              fontSize: 16,
            }}
          >
            {isSyncing ? t('取得中です...') : t('席情報の取得')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
