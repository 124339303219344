import React, { useEffect, useState } from 'react'
import { View, ScrollView } from 'react-native'
import {
  SalesByPeriodForm,
  WrappedSelectedInput,
  getDateFormat,
  getPeriodTypeLabel,
  getSalesTypeLabel,
} from '@hello-ai/for_r_app/src/components/Sales/SalesByPeriodForm'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useOwnerRestaurants } from 'models/Restaurant'
import {
  Row,
  Header,
  HeaderCell,
  TextCell,
  FixedCell,
  Cell,
  SalesChart,
  VariationIcon,
  smallCellTextStyle as cellTextStyle,
} from '@hello-ai/for_r_app/src/components/Sales'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'
import { useNavigate } from 'react-router-dom'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  PeriodType,
  SalesType,
  ValueType,
  getFetchPeriodParams,
  getFetchParamLabel,
  getCSVHeaders,
  generateChartData,
} from '@hello-ai/for_r_app/src/modules/sales'
import { useComponentSize } from '@hello-ai/for_r_app/src/modules/useComponentSize'
import Loading from '@hello-ai/for_r_app/src/components/Shared/Loading'
import {
  SalesDashboardData,
  fetchRestaurantsSalesDashboard,
} from '@hello-ai/for_r_app/src/models/Sale'
import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import { onError, useAuth } from 'models/Auth'

function convertDataFormat(
  rows: SalesDashboardData['data'],
  periodType: PeriodType,
  salesType: SalesType
): Array<Record<string, unknown>> {
  return rows.map((row) => {
    const salesTypeLabel = getSalesTypeLabel(salesType)
    const date = getDateFormat(row.time, periodType)
    const gross = numberWithDelimiter(row.amount ?? 0)
    const net = numberWithDelimiter(
      row.amount - row.application_fee_amount ?? 0
    )
    const fee = numberWithDelimiter(row.application_fee_amount ?? 0)
    const amountDelta = (row.amount_delta ?? 0).toFixed(1)
    return {
      salesType: salesTypeLabel,
      date,
      gross,
      net,
      fee,
      amountDelta,
    }
  })
}

function SalesByPeriod() {
  const navigate = useNavigate()
  const { data: restaurants } = useOwnerRestaurants()
  const [fetchedParameters, setFetchedParameters] = useState<{
    restaurantIds: number[]
    periodType: PeriodType
    salesType: SalesType
    year: number
    month: number
    day: number
  }>({
    restaurantIds: [],
    periodType: 'monthly',
    salesType: 'all',
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  })

  function parametersIsChanged(
    prev: typeof fetchedParameters,
    next: typeof fetchedParameters
  ) {
    return (
      prev.restaurantIds.toSorted().join(',') !==
        next.restaurantIds.toSorted().join(',') ||
      prev.periodType !== next.periodType ||
      prev.salesType !== next.salesType ||
      prev.year !== next.year ||
      prev.month !== next.month ||
      prev.day !== next.day
    )
  }

  const period = getFetchPeriodParams(fetchedParameters)
  const [valueType, setValueType] = useState<ValueType>('amount')
  const [size, isReady, onLayout] = useComponentSize()
  const { auth } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [salesData, setSalesData] = useState<SalesDashboardData>({
    data: [],
    segment_totals: [],
    total: {
      amount: 0,
    },
  })

  useEffect(() => {
    if (auth?.id == null) return
    if (restaurants == null) return
    setIsLoading(true)
    const params: {
      restaurantIds: number[]
      periodType: PeriodType
      salesType: SalesType
      year: number
      month: number
      day: number
    } = {
      restaurantIds: restaurants.restaurants.map((r) => r.id),
      periodType: 'monthly',
      salesType: 'all',
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    }
    fetchRestaurantsSalesDashboard(auth.id, 'monthly', {
      completed_at: getFetchPeriodParams(params),
      restaurant_ids: restaurants.restaurants.map((r) => r.id),
    })
      .then((res) => {
        setFetchedParameters(params)
        setSalesData(res.body)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        onError(error)
      })
  }, [auth?.id, restaurants])

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <ScrollView
        style={{
          paddingTop: 24,
          paddingBottom: 36,
          paddingHorizontal: 48,
        }}
      >
        <View onLayout={onLayout}>
          {isReady ? (
            <>
              <SalesByPeriodForm
                restaurants={restaurants?.restaurants ?? []}
                disabled={isLoading}
                onPressApply={(params) => {
                  if (auth?.id == null) return
                  if (!parametersIsChanged(fetchedParameters, params)) return
                  setIsLoading(true)
                  fetchRestaurantsSalesDashboard(auth.id, params.periodType, {
                    completed_at: getFetchPeriodParams(params),
                    segments:
                      params.salesType !== 'all'
                        ? [params.salesType]
                        : undefined,
                    restaurant_ids: params.restaurantIds,
                  })
                    .then((res) => {
                      setFetchedParameters(params)
                      setSalesData(res.body)
                      setIsLoading(false)
                    })
                    .catch((error) => {
                      setIsLoading(false)
                      onError(error)
                    })
                }}
              />
              <View
                style={{
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                  marginTop: 32,
                  zIndex: -1,
                }}
              />
              {!isLoading ? (
                <View style={{ zIndex: -1 }}>
                  <View
                    style={{
                      marginTop: 38,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: 18,
                          fontWeight: '600',
                          color: Colors.black,
                        }}
                      >
                        {getSalesTypeLabel(fetchedParameters.salesType)}
                      </Text>
                      <View
                        style={{
                          marginTop: 6,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 24,
                            fontWeight: '600',
                            color: Colors.black,
                          }}
                        >
                          {getFetchParamLabel(fetchedParameters) +
                            ' ' +
                            getPeriodTypeLabel(fetchedParameters.periodType)}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <CSVDownloadButton
                        data={convertDataFormat(
                          salesData.data,
                          fetchedParameters.periodType,
                          fetchedParameters.salesType
                        )}
                        headers={getCSVHeaders(fetchedParameters.periodType)}
                        fileName={`sales_${fetchedParameters.periodType}`}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 20,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <View
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <Text style={{ fontSize: 18, fontWeight: '600' }}>
                        {t('表示する値')}
                      </Text>
                    </View>
                    <View
                      style={{
                        minWidth: 80,
                      }}
                    >
                      <WrappedSelectedInput
                        selectedValue={valueType}
                        setValue={setValueType}
                        items={[
                          {
                            label: t('金額'),
                            value: 'amount',
                          },
                          {
                            label: t('率'),
                            value: 'ratio',
                          },
                        ]}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 38,
                      zIndex: -1,
                    }}
                  >
                    <SalesChart
                      totalAmount={salesData.total.amount}
                      startDate={dayjs(period.gte)}
                      endDate={dayjs(period.lte)}
                      data={generateChartData(
                        salesData.data,
                        fetchedParameters.periodType,
                        valueType
                      )}
                      valueType={valueType}
                      width={size.width}
                      height={370}
                    />
                  </View>
                  <ShadowBox
                    style={[
                      {
                        marginTop: 36,
                        padding: 0,
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    ]}
                  >
                    <Header>
                      <HeaderCell value={t('日付')} isLead />
                      <HeaderCell value={t('総売上')} />
                      <HeaderCell value={t('純売上')} />
                      <HeaderCell value={t('手数料')} />
                      <HeaderCell value={t('粗利益')} />
                      <HeaderCell value={t('客単価')} />
                      <FixedCell width={30} height={60} />
                    </Header>
                    <Row>
                      <Cell isLead height={24} />
                    </Row>
                    {salesData.data.map((row, i) => {
                      const date = getDateFormat(
                        row.time,
                        fetchedParameters.periodType
                      )
                      const gross = numberWithDelimiter(row.amount ?? 0)
                      const net = numberWithDelimiter(
                        row.amount - row.application_fee_amount ?? 0
                      )
                      const fee = numberWithDelimiter(
                        row.application_fee_amount ?? 0
                      )
                      const grossProfit = numberWithDelimiter(
                        row.gross_profit_amount ?? 0
                      )
                      const amountPerCustomer = numberWithDelimiter(
                        row.amount_per_customer ?? 0
                      )
                      const disabled =
                        fetchedParameters.periodType !== 'monthly'
                      const onPressCell = !disabled
                        ? () => {
                            const searchParams = new URLSearchParams({
                              start_date: row.date,
                              restaurant_ids:
                                fetchedParameters.restaurantIds.join(','),
                            })
                            const url = `/sales/details?${searchParams.toString()}`
                            navigate(url)
                          }
                        : undefined
                      return (
                        <Row key={i}>
                          <Cell isLead onPress={onPressCell}>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Text style={{ marginRight: 10, fontSize: 14 }}>
                                {date}
                              </Text>
                              <VariationIcon
                                amountDelta={row.amount_delta}
                                size={20}
                              />
                            </View>
                          </Cell>
                          <TextCell
                            style={cellTextStyle}
                            value={t('{{price}}円', { price: gross })}
                            onPress={onPressCell}
                          />
                          <TextCell
                            style={cellTextStyle}
                            value={t('{{price}}円', { price: net })}
                            onPress={onPressCell}
                          />
                          <TextCell
                            style={cellTextStyle}
                            value={t('{{price}}円', { price: fee })}
                            onPress={onPressCell}
                          />
                          <TextCell
                            style={cellTextStyle}
                            value={t('{{price}}円', { price: grossProfit })}
                            onPress={onPressCell}
                          />
                          <TextCell
                            style={cellTextStyle}
                            value={t('{{price}}円', {
                              price: amountPerCustomer,
                            })}
                            onPress={onPressCell}
                          />
                          <FixedCell width={30} height={60}>
                            {!disabled && (
                              <TouchableOpacity onPress={onPressCell}>
                                <FontAwesomeIcon
                                  style={{ marginRight: 30 }}
                                  size={20}
                                  color={Colors.black}
                                  icon={faAngleRight}
                                />
                              </TouchableOpacity>
                            )}
                          </FixedCell>
                        </Row>
                      )
                    })}
                  </ShadowBox>
                </View>
              ) : (
                <Loading />
              )}
            </>
          ) : (
            <Loading />
          )}
        </View>
      </ScrollView>
    </View>
  )
}

export default SalesByPeriod
