import { SupportedLocale } from '@hello-ai/ar_shared/src/modules/locale'
import { useRestaurantId } from './useRestaurantId'
import { useRestaurant } from '../models/Restaurant'

export function useRestaurantCountryLanguage(
  fallbackLanguage: SupportedLocale = 'ja'
) {
  const restaurantId = useRestaurantId()
  const { restaurant } = useRestaurant(restaurantId)

  return (restaurant?.country?.langs[0] ?? fallbackLanguage) as SupportedLocale
}
