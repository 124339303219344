import React, { useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  SelectInput,
  SelectInputProps,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'

import {
  Row,
  Header,
  HeaderCell,
  TextCell,
  FixedCell,
  Cell,
  SalesChart,
  VariationIcon,
  smallCellTextStyle as cellTextStyle,
} from 'components/Sales'
import { useComponentSize } from 'modules/useComponentSize'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import { getDays, getYears, months } from 'components/Sales/SelectPeriodInput'

import { SalesDashboardData, useRestaurantSalesDashboard } from 'models/Sales'
import { useRestaurantId } from 'modules/useRestaurantId'

import { useNavigate } from 'react-router-dom'
import { generatePath, useParams } from 'react-router'
import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  getPeriodTypeLabel,
  getSalesTypeLabel,
} from '@hello-ai/for_r_app/src/components/Sales/SalesByPeriodForm'

type PeriodType = keyof typeof periodTypeLabels
export type ValueType = 'amount' | 'ratio'
type SalesType = 'all' | 'table' | 'shop'

interface FetchParameters {
  salesType: string
  periodType: PeriodType
  year: number
  month: number
  day: number
}

const salesTypes: Array<SelectItem<SalesType>> = [
  {
    label: t('全ての販売方法'),
    value: 'all',
  },
  {
    label: t('イートイン'),
    value: 'table',
  },
  {
    label: t('オンラインストア'),
    value: 'shop',
  },
]

const periodTypes: Array<SelectItem<PeriodType>> = [
  {
    label: t('月別'),
    value: 'monthly',
  },
  {
    label: t('日別'),
    value: 'daily',
  },
  {
    label: t('時間別'),
    value: 'hourly',
  },
]

const periodTypeLabels = {
  monthly: t('月'),
  daily: t('日'),
  hourly: t('時'),
}

function getCSVHeaders(periodType: keyof typeof periodTypeLabels): Array<{
  value: string
  label: string
}> {
  return [
    { value: 'salesType', label: t('販売方法') },
    { value: 'date', label: t('日付') },
    { value: 'gross', label: t('総売上') },
    { value: 'net', label: t('純売上') },
    { value: 'fee', label: t('手数料') },
    {
      value: 'amountDelta',
      label: t('前{{period}}差(総売上)', {
        period: periodTypeLabels[periodType],
      }),
    },
  ]
}

export function getDateFormat(date: string, periodType: PeriodType): string {
  switch (periodType) {
    case 'monthly':
      return dayjs(date).format('YYYY/MM')
    case 'daily': {
      const dow = dayjs(date).format('d')
      const label = dayjs(date).format('MM/DD')

      return `${label}(${dayjs.weekdaysShort()[Number(dow)]})`
    }
    case 'hourly':
      // eslint-disable-next-line ar-i18n/require-translation-ja
      return dayjs(date).format('HH時')
  }
}

function getChartLabel(date: string, periodType: PeriodType): string {
  switch (periodType) {
    case 'monthly':
      // eslint-disable-next-line ar-i18n/require-translation-ja
      return dayjs(date).format('M月')
    case 'daily':
      return dayjs(date).format('DD') // 数が多く、軸が潰れる可能性があるので、日を省略
    case 'hourly':
      // eslint-disable-next-line ar-i18n/require-translation-ja
      return dayjs(date).format('HH時')
  }
}

function getFetchParamLabel({
  periodType,
  year,
  month,
  day,
}: FetchParameters): string {
  switch (periodType) {
    case 'monthly':
      return t('{{year}}年', { year })
    case 'daily':
      return t('{{year}}年{{month}}月', { year, month })
    case 'hourly':
      return t('{{year}}年{{month}}月{{day}}日', {
        year,
        month,
        day: day.toString().padStart(2, '0'),
      })
  }
}

function convertDataFormat(
  rows: SalesDashboardData['data'],
  periodType: PeriodType,
  salesType: SalesType
): Array<Record<string, unknown>> {
  return rows.map((row) => {
    const salesTypeLabel = getSalesTypeLabel(salesType)
    const date = getDateFormat(row.time, periodType)
    const gross = numberWithDelimiter(row.amount ?? 0)
    const net = numberWithDelimiter(
      row.amount - row.application_fee_amount ?? 0
    )
    const fee = numberWithDelimiter(row.application_fee_amount ?? 0)
    const amountDelta = (row.amount_delta ?? 0).toFixed(1)
    return {
      salesType: salesTypeLabel,
      date,
      gross,
      net,
      fee,
      amountDelta,
    }
  })
}

export function getFetchPeriodParams({
  periodType,
  year,
  month,
  day,
}: FetchParameters): {
  gte: string
  lte: string
} {
  switch (periodType) {
    case 'monthly': {
      const base = dayjs().year(year)
      const gte = base.startOf('year').toISOString()
      const lte = base.endOf('year').toISOString()
      return {
        gte,
        lte,
      }
    }
    case 'daily': {
      const base = dayjs()
        .year(year)
        .month(month - 1)
      const gte = base.startOf('month').toISOString()
      const lte = base.endOf('month').toISOString()
      return {
        gte,
        lte,
      }
    }
    case 'hourly': {
      const base = dayjs()
        .year(year)
        .month(month - 1)
        .date(day)
      const gte = base.startOf('day').toISOString()
      const lte = base.endOf('day').toISOString()
      return {
        gte,
        lte,
      }
    }
  }
}

function validateFetchParameters(params: FetchParameters): boolean {
  const { gte } = getFetchPeriodParams(params)
  const gteDate = dayjs(gte)
  const now = dayjs()
  return now.isSame(gteDate) || now.isAfter(gteDate)
}

function generateChartData(
  data: SalesDashboardData['data'],
  periodType: PeriodType,
  valueType: ValueType
) {
  const newData = [...data]
  newData.sort((a, b) => (a.time > b.time ? 1 : a.time === b.time ? 0 : -1))
  const labels = newData.map((d) => getChartLabel(d.time, periodType))
  if (valueType === 'amount') {
    const grossSales = newData.map((d, i) => ({
      x: labels[i],
      y: d.amount,
      name: t('総売上'),
    }))
    const netSales = newData.map((d, i) => ({
      x: labels[i],
      y: d.amount - d.application_fee_amount,
      name: t('純売上'),
    }))

    const grossProfitAmounts = newData.map((d, i) => ({
      x: labels[i],
      y: d.gross_profit_amount,
      name: t('粗利益'),
    }))
    const datasets = [
      {
        data: grossSales,
        color: '#AB9355',
      },
      {
        data: netSales,
        color: '#009688',
      },
      { data: grossProfitAmounts, color: '#9B45B9' },
    ]
    return {
      datasets,
      legends: [t('総売上'), t('純売上'), t('粗利益')],
    }
  }

  const grossProfitRatio = newData.map((d, i) => ({
    x: labels[i],
    y: d.gross_profit_ratio * 100,
    name: t('粗利率'),
  }))
  const datasets = [{ data: grossProfitRatio, color: '#9B45B9', type: 'line' }]
  return {
    datasets,
    legends: [t('粗利率')],
  }
}

export function WrappedSelectedInput<T>({
  disabled,
  selectedValue,
  setValue,
  items,
}: SelectInputProps<T>) {
  return (
    <SelectInput
      disabled={disabled}
      selectedValue={selectedValue}
      setValue={setValue}
      items={items}
      labelStyle={{
        fontSize: 16,
        fontWeight: '300',
      }}
      style={{
        backgroundColor: 'white',
      }}
      indicatorIcon={
        <FontAwesomeIcon icon={faChevronDown} size={10} color={Colors.black} />
      }
    />
  )
}

export default function SalesByPeriod() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const today = dayjs()

  const years = useMemo(() => {
    return getYears()
  }, [])

  const [selectedYear, setSelectedYear] = useState(today.year())
  const [selectedMonth, setSelectedMonth] = useState(today.month() + 1)
  const [selectedDay, setSelectedDay] = useState(today.date())
  const [selectedPeriodType, setSelectedPeriodType] = useState<PeriodType>(
    periodTypes[0].value
  )
  const [selectedSaleType, setSelectedSaleType] = useState<SalesType>(
    salesTypes[0].value
  )
  const [valueType, setValueType] = useState<ValueType>('amount')
  const [fetchParameters, setFetchParameters] = useState({
    salesType: selectedSaleType,
    periodType: selectedPeriodType,
    year: selectedYear,
    month: selectedMonth,
    day: selectedDay,
  })

  const period = getFetchPeriodParams(fetchParameters)

  const { salesData } = useRestaurantSalesDashboard(
    restaurantId,
    fetchParameters.periodType,
    {
      completed_at: getFetchPeriodParams(fetchParameters),
      segments:
        fetchParameters.salesType !== 'all'
          ? [fetchParameters.salesType]
          : null,
    }
  )

  const isFetching = salesData === undefined

  const onChangeFetchParameters = () => {
    setFetchParameters({
      salesType: selectedSaleType,
      periodType: selectedPeriodType,
      year: selectedYear,
      month: selectedMonth,
      day: selectedDay,
    })
  }

  const onChangeMonth = (month: number) => {
    const isValid = validateFetchParameters({
      salesType: selectedSaleType,
      periodType: selectedPeriodType,
      year: selectedYear,
      month,
      day: selectedDay,
    })
    if (isValid) {
      setSelectedMonth(month)
    }
  }

  const onChangeDay = (day: number) => {
    const isValid = validateFetchParameters({
      salesType: selectedSaleType,
      periodType: selectedPeriodType,
      year: selectedYear,
      month: selectedMonth,
      day,
    })
    if (isValid) {
      setSelectedDay(day)
    }
  }

  const days = useMemo(() => {
    const newDays = getDays(selectedYear, selectedMonth)
    const maxDay = Math.max(...newDays.map((day) => day.value))
    // 選択している日付が存在しない月になった場合、選択した月の最大値にしておく
    // eslint-disable-next-line react-compiler/react-compiler
    setSelectedDay((prevState) => {
      if (prevState > maxDay) {
        setSelectedDay(maxDay)
      }
      return prevState
    })
    return newDays
  }, [selectedYear, selectedMonth])

  const [size, isReady, onLayout] = useComponentSize()

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        marginTop: 41,
        marginLeft: 48,
        marginRight: 48,
        paddingBottom: 100,
      }}
    >
      <View onLayout={onLayout}>
        {isReady ? (
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flex: 1,
                  flexWrap: 'wrap',
                }}
              >
                <View
                  style={{
                    minWidth: 200,
                    marginRight: 16,
                    marginBottom: 10,
                  }}
                >
                  <WrappedSelectedInput
                    disabled={isFetching}
                    selectedValue={selectedSaleType}
                    setValue={setSelectedSaleType}
                    items={salesTypes}
                  />
                </View>
                <View
                  style={{
                    minWidth: 140,
                    marginRight: 16,
                    marginBottom: 10,
                  }}
                >
                  <WrappedSelectedInput
                    disabled={isFetching}
                    selectedValue={selectedPeriodType}
                    setValue={setSelectedPeriodType}
                    items={periodTypes}
                  />
                </View>
                <View
                  style={{
                    minWidth: 120,
                    marginRight: 16,
                    marginBottom: 10,
                  }}
                >
                  <WrappedSelectedInput
                    disabled={isFetching}
                    selectedValue={selectedYear}
                    setValue={setSelectedYear}
                    items={years}
                  />
                </View>
                <View
                  style={{
                    minWidth: 100,
                    marginRight: 16,
                    marginBottom: 10,
                  }}
                >
                  {selectedPeriodType !== 'monthly' && (
                    <WrappedSelectedInput
                      disabled={isFetching}
                      selectedValue={selectedMonth}
                      setValue={onChangeMonth}
                      items={months}
                    />
                  )}
                </View>
                <View
                  style={{
                    minWidth: 80,
                    marginRight: 16,
                    marginBottom: 10,
                  }}
                >
                  {selectedPeriodType === 'hourly' && (
                    <WrappedSelectedInput
                      disabled={isFetching}
                      selectedValue={selectedDay}
                      setValue={onChangeDay}
                      items={days}
                    />
                  )}
                </View>
              </View>
              <Button
                mode="contained"
                variant="secondary"
                disabled={isFetching}
                width={160}
                height={48}
                roundness={24}
                onPress={onChangeFetchParameters}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    color: Colors.primary,
                  }}
                >
                  {t('表示する')}
                </Text>
              </Button>
            </View>
            <View
              style={{
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
                marginTop: 32,
              }}
            />
            {salesData === undefined ? (
              <View
                style={{
                  marginTop: 38,
                }}
              >
                <Loading />
              </View>
            ) : (
              <>
                <View
                  style={{
                    marginTop: 38,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: '600',
                        color: Colors.black,
                      }}
                    >
                      {getSalesTypeLabel(selectedSaleType)}
                    </Text>
                    <View
                      style={{
                        marginTop: 6,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 24,
                          fontWeight: '600',
                          color: Colors.black,
                        }}
                      >
                        {getFetchParamLabel(fetchParameters) +
                          ' ' +
                          getPeriodTypeLabel(selectedPeriodType)}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <CSVDownloadButton
                      data={convertDataFormat(
                        salesData.data,
                        fetchParameters.periodType,
                        fetchParameters.salesType
                      )}
                      headers={getCSVHeaders(fetchParameters.periodType)}
                      fileName={`sales_${fetchParameters.periodType}`}
                    />
                  </View>
                </View>
                <View
                  style={{
                    marginTop: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <View
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <Text style={{ fontSize: 18, fontWeight: '600' }}>
                      {t('表示する値')}
                    </Text>
                  </View>
                  <View
                    style={{
                      minWidth: 80,
                    }}
                  >
                    <WrappedSelectedInput
                      selectedValue={valueType}
                      setValue={setValueType}
                      items={[
                        {
                          label: t('金額'),
                          value: 'amount',
                        },
                        {
                          label: t('率'),
                          value: 'ratio',
                        },
                      ]}
                    />
                  </View>
                </View>
                <View
                  style={{
                    marginTop: 38,
                  }}
                >
                  <SalesChart
                    totalAmount={salesData.total.amount}
                    startDate={dayjs(period.gte)}
                    endDate={dayjs(period.lte)}
                    data={generateChartData(
                      salesData.data,
                      fetchParameters.periodType,
                      valueType
                    )}
                    valueType={valueType}
                    width={size.width}
                    height={370}
                  />
                </View>
                <ShadowBox
                  style={[
                    {
                      marginTop: 36,
                      padding: 0,
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  ]}
                >
                  <Header>
                    <HeaderCell value={t('日付')} isLead />
                    <HeaderCell value={t('総売上')} />
                    <HeaderCell value={t('純売上')} />
                    <HeaderCell value={t('手数料')} />
                    <HeaderCell value={t('粗利益')} />
                    <HeaderCell value={t('客単価')} />
                    <FixedCell width={30} height={60} />
                  </Header>
                  <Row>
                    <Cell isLead height={24} />
                  </Row>
                  {salesData.data.map((row, i) => {
                    const date = getDateFormat(
                      row.time,
                      fetchParameters.periodType
                    )
                    const gross = numberWithDelimiter(row.amount ?? 0)
                    const net = numberWithDelimiter(
                      row.amount - row.application_fee_amount ?? 0
                    )
                    const fee = numberWithDelimiter(
                      row.application_fee_amount ?? 0
                    )
                    const grossProfit = numberWithDelimiter(
                      row.gross_profit_amount ?? 0
                    )
                    const amountPerCustomer = numberWithDelimiter(
                      row.amount_per_customer ?? 0
                    )
                    const disabled = fetchParameters.periodType !== 'monthly'
                    const onPressCell = !disabled
                      ? () => {
                          const path = generatePath(
                            '/restaurants/:id/sales/details',
                            { id: params.id! }
                          )
                          const searchParams = new URLSearchParams({
                            start_date: row.date,
                          })
                          const url = `${path}?${searchParams.toString()}`
                          navigate(url)
                        }
                      : undefined
                    return (
                      <Row key={i}>
                        <Cell isLead onPress={onPressCell}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Text style={{ marginRight: 10, fontSize: 14 }}>
                              {date}
                            </Text>
                            <VariationIcon
                              amountDelta={row.amount_delta}
                              size={20}
                            />
                          </View>
                        </Cell>
                        <TextCell
                          style={cellTextStyle}
                          value={t('{{price}}円', { price: gross })}
                          onPress={onPressCell}
                        />
                        <TextCell
                          style={cellTextStyle}
                          value={t('{{price}}円', { price: net })}
                          onPress={onPressCell}
                        />
                        <TextCell
                          style={cellTextStyle}
                          value={t('{{price}}円', { price: fee })}
                          onPress={onPressCell}
                        />
                        <TextCell
                          style={cellTextStyle}
                          value={t('{{price}}円', { price: grossProfit })}
                          onPress={onPressCell}
                        />
                        <TextCell
                          style={cellTextStyle}
                          value={t('{{price}}円', { price: amountPerCustomer })}
                          onPress={onPressCell}
                        />
                        <FixedCell width={30} height={60}>
                          {!disabled && (
                            <TouchableOpacity onPress={onPressCell}>
                              <FontAwesomeIcon
                                style={{ marginRight: 30 }}
                                size={20}
                                color={Colors.black}
                                icon={faAngleRight}
                              />
                            </TouchableOpacity>
                          )}
                        </FixedCell>
                      </Row>
                    )
                  })}
                </ShadowBox>
              </>
            )}
          </View>
        ) : (
          <Loading />
        )}
      </View>
    </ScrollView>
  )
}
