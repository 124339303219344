/* eslint-disable ar-i18n/require-translation-ja */
export enum Gender {
  male = '男性',
  female = '女性',
  other = 'その他',
}

export interface RestaurantCustomerProfile {
  id: number
  anniversaries: {
    date: Date
    name: string
  }[]
  birthday: Date
  company: string
  company_address: string
  company_postal_code: string
  dislikes: string
  facebook_url: string
  feature: string
  gender?: 'male' | 'female' | 'other'
  home_address: string
  home_postal_code: string
  likes: string
  opt_in_dm: boolean
  post: string
  receipt_title: string
  section: string
  smoking: string
  updater: string
  wedding_anniversary: Date
  x_url: string
  custom_attributes?: string
}

interface RestaurantCustomerProfileUpdateParams {
  id: number
  anniversaries: {
    date: Date
    name: string
  }[]
  birthday: Date | null
  company: string
  company_address: string
  company_postal_code: string
  dislikes: string
  facebook_url: string
  feature: string
  gender: 'male' | 'female' | 'other'
  home_address: string
  home_postal_code: string
  likes: string
  opt_in_dm: boolean
  post: string
  receipt_title: string
  section: string
  smoking: number
  updater: string
  wedding_anniversary: Date
  x_url: string
}

export type UpdateRestaurantCustomerProfile =
  Partial<RestaurantCustomerProfileUpdateParams>
