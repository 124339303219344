import React, { FC } from 'react'
import { View, ScrollView } from 'react-native'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo'
import { t } from '../../../../modules/i18n'
import Hyperlink from 'react-native-hyperlink'

import { LastImportedMail } from '../../../../types/ForR/ImportMailSettings'

import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons/faCircleChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Button } from '../../../Button'
import { Step, Stepper } from '../../../Stepper'
import { TouchableOpacity } from '../../../Touchables'

type Props = {
  steps: Step[]
  currentStepNumber: number
  sourceLabel: string
  lastImportedMailData?: LastImportedMail
  handleOnPressReload: () => void
  onPressNext: () => void
  onPressGoBack: () => void
  onPressAdminUrl: () => void
}

export function AuthenticateEmailStepForm({
  steps,
  currentStepNumber,
  sourceLabel,
  lastImportedMailData,
  handleOnPressReload,
  onPressNext,
  onPressGoBack,
  onPressAdminUrl,
}: Props) {
  return (
    <Stepper
      steps={steps}
      currentStepNumber={currentStepNumber}
      errors={[]}
      isDisabledNextPress={false}
      onPressNext={onPressNext}
      onPressGoBack={onPressGoBack}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: '#fff' }}
        contentContainerStyle={{
          paddingHorizontal: '5%',
          paddingVertical: 40,
        }}
      >
        <View>
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
            }}
          >
            {t('次に、メールアドレスの認証を行います')}
          </Text>
          <Text style={{ marginTop: 40 }}>
            {t('1,メール本便に記載されている認証URLをクリックしてください')}
          </Text>
          <View
            style={{ backgroundColor: '#4444440F', marginTop: 16, padding: 18 }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center' }}
                onPress={handleOnPressReload}
              >
                <FontAwesomeIcon
                  color={Colors.primary}
                  icon={faUndo}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
                <Text
                  style={{
                    color: Colors.primary,
                    fontSize: 14,
                    marginLeft: 10,
                    fontWeight: '600',
                  }}
                >
                  {t('メールの再読み込み')}
                </Text>
              </TouchableOpacity>
              <Text style={{ marginLeft: 16, fontSize: 12 }}>
                {t(
                  '※メールが正しく表示されない場合、再読み込みを実行してください。'
                )}
              </Text>
            </View>
            <View
              style={{
                borderBottomWidth: 0.5,
                borderColor: '#00000029',
                marginTop: 10,
              }}
            />
            <View style={{ paddingTop: 17, alignSelf: 'center' }}>
              {lastImportedMailData?.body.text != null && (
                <Hyperlink linkDefault linkStyle={{ color: Colors.primary }}>
                  <Text style={{ color: '#44444499' }}>
                    {lastImportedMailData?.body.text}
                  </Text>
                </Hyperlink>
              )}
              {lastImportedMailData?.body.text == null && (
                <Text style={{ color: '#44444499', fontWeight: '300' }}>
                  {t(
                    'グルメサイトからの認証メール受信に時間がかかっています。'
                  )}
                  {'\n'}
                  {t(
                    'しばらく時間を置いてからメールの再読み込みを実施してください'
                  )}
                </Text>
              )}
            </View>
          </View>
          <View style={{ alignItems: 'center', marginTop: 40 }}>
            <Button
              style={{ minWidth: 256, height: 48 }}
              textStyle={{ fontSize: 16 }}
              onPress={onPressAdminUrl}
            >
              {`${sourceLabel}${t('の管理画面を開く')}`}
            </Button>
            <Text style={{ marginTop: 8, fontSize: 12, color: Colors.black60 }}>
              {t('※規定のブラウザが開きます')}
            </Text>
          </View>
        </View>
        <Text
          style={{
            fontSize: 18,
            fontWeight: '300',
            lineHeight: 27,
            marginTop: 64,
          }}
        >
          <Text>{t('2.完了後、この画面に戻り右下の')}</Text>
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            size={24}
            color={Colors.primary}
          />
          <Text>{t('を押して次へ進んでください。')}</Text>
        </Text>
      </ScrollView>
    </Stepper>
  )
}
