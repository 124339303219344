import React from 'react'
import ModalCenter from '../../Shared/ModalCenter'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { RejectModalContent } from './RejectModalContent'

interface RejectModalProps {
  isModalVisible: boolean
  onClose: () => void
  onPressRejection: (param: {
    cancelReason: string
    otherCancelReason: string
  }) => void
}

export default function RejectModal({
  isModalVisible,
  onPressRejection,
  onClose,
}: RejectModalProps) {
  return (
    <ModalCenter
      isModalVisible={isModalVisible}
      title={t('リクエスト受付予約')}
      width="60%"
      height="100%"
      onClose={onClose}
    >
      <RejectModalContent onPressRejection={onPressRejection} />
    </ModalCenter>
  )
}
