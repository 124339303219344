// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service.proto" (package "auto_reserve.restaurants.restaurant_reservation_block", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantReservationBlockResource } from "./restaurant_reservation_block_resource";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodRequest
 */
export interface ListPeriodRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string end_date = 3;
   */
  endDate: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse
 */
export interface ListPeriodResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod restaurant_reservation_block_periods = 1;
   */
  restaurantReservationBlockPeriods: ListPeriodResponse_RestaurantReservationBlockPeriod[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod
 */
export interface ListPeriodResponse_RestaurantReservationBlockPeriod {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_at = 2;
   */
  startAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_at = 3;
   */
  endAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod.TableSeat table_seats = 4;
   */
  tableSeats: ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat[];
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource restaurant_reservation_block = 5;
   */
  restaurantReservationBlock?: RestaurantReservationBlockResource;
  /**
   * @generated from protobuf field: bool is_repeat = 6;
   */
  isRepeat: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod.TableSeat
 */
export interface ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string end_date = 3;
   */
  endDate: string;
  /**
   * @generated from protobuf field: int32 start_time = 4;
   */
  startTime: number;
  /**
   * @generated from protobuf field: int32 end_time = 5;
   */
  endTime: number;
  /**
   * @generated from protobuf field: repeated int32 wdays = 6;
   */
  wdays: number[];
  /**
   * @generated from protobuf field: repeated string table_seat_ids = 7;
   */
  tableSeatIds: string[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string end_date = 3;
   */
  endDate: string;
  /**
   * @generated from protobuf field: int32 start_time = 4;
   */
  startTime: number;
  /**
   * @generated from protobuf field: int32 end_time = 5;
   */
  endTime: number;
  /**
   * @generated from protobuf field: repeated int32 wdays = 6;
   */
  wdays: number[];
  /**
   * @generated from protobuf field: repeated string table_seat_ids = 7;
   */
  tableSeatIds: string[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.CreateExceptionRequest
 */
export interface CreateExceptionRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string end_date = 3;
   */
  endDate: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListPeriodRequest$Type extends MessageType<ListPeriodRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.ListPeriodRequest",
      [
        {
          no: 1,
          name: "restaurant_id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 2,
          name: "start_date",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(value?: PartialMessage<ListPeriodRequest>): ListPeriodRequest {
    const message = { restaurantId: 0, startDate: "", endDate: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListPeriodRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListPeriodRequest,
  ): ListPeriodRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* string end_date */ 3:
          message.endDate = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListPeriodRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* string end_date = 3; */
    if (message.endDate !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.endDate);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodRequest
 */
export const ListPeriodRequest = new ListPeriodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPeriodResponse$Type extends MessageType<ListPeriodResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse",
      [
        {
          no: 1,
          name: "restaurant_reservation_block_periods",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ListPeriodResponse_RestaurantReservationBlockPeriod,
        },
      ],
    );
  }
  create(value?: PartialMessage<ListPeriodResponse>): ListPeriodResponse {
    const message = { restaurantReservationBlockPeriods: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListPeriodResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListPeriodResponse,
  ): ListPeriodResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod restaurant_reservation_block_periods */ 1:
          message.restaurantReservationBlockPeriods.push(
            ListPeriodResponse_RestaurantReservationBlockPeriod.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListPeriodResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod restaurant_reservation_block_periods = 1; */
    for (let i = 0; i < message.restaurantReservationBlockPeriods.length; i++)
      ListPeriodResponse_RestaurantReservationBlockPeriod.internalBinaryWrite(
        message.restaurantReservationBlockPeriods[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse
 */
export const ListPeriodResponse = new ListPeriodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPeriodResponse_RestaurantReservationBlockPeriod$Type extends MessageType<ListPeriodResponse_RestaurantReservationBlockPeriod> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "start_at", kind: "message", T: () => Timestamp },
        { no: 3, name: "end_at", kind: "message", T: () => Timestamp },
        {
          no: 4,
          name: "table_seats",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () =>
            ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat,
        },
        {
          no: 5,
          name: "restaurant_reservation_block",
          kind: "message",
          T: () => RestaurantReservationBlockResource,
        },
        { no: 6, name: "is_repeat", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<ListPeriodResponse_RestaurantReservationBlockPeriod>,
  ): ListPeriodResponse_RestaurantReservationBlockPeriod {
    const message = { id: "", tableSeats: [], isRepeat: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListPeriodResponse_RestaurantReservationBlockPeriod>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListPeriodResponse_RestaurantReservationBlockPeriod,
  ): ListPeriodResponse_RestaurantReservationBlockPeriod {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* google.protobuf.Timestamp start_at */ 2:
          message.startAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.startAt,
          );
          break;
        case /* google.protobuf.Timestamp end_at */ 3:
          message.endAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.endAt,
          );
          break;
        case /* repeated auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod.TableSeat table_seats */ 4:
          message.tableSeats.push(
            ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource restaurant_reservation_block */ 5:
          message.restaurantReservationBlock =
            RestaurantReservationBlockResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantReservationBlock,
            );
          break;
        case /* bool is_repeat */ 6:
          message.isRepeat = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListPeriodResponse_RestaurantReservationBlockPeriod,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* google.protobuf.Timestamp start_at = 2; */
    if (message.startAt)
      Timestamp.internalBinaryWrite(
        message.startAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp end_at = 3; */
    if (message.endAt)
      Timestamp.internalBinaryWrite(
        message.endAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod.TableSeat table_seats = 4; */
    for (let i = 0; i < message.tableSeats.length; i++)
      ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat.internalBinaryWrite(
        message.tableSeats[i],
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource restaurant_reservation_block = 5; */
    if (message.restaurantReservationBlock)
      RestaurantReservationBlockResource.internalBinaryWrite(
        message.restaurantReservationBlock,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool is_repeat = 6; */
    if (message.isRepeat !== false)
      writer.tag(6, WireType.Varint).bool(message.isRepeat);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod
 */
export const ListPeriodResponse_RestaurantReservationBlockPeriod =
  new ListPeriodResponse_RestaurantReservationBlockPeriod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat$Type extends MessageType<ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod.TableSeat",
      [{ no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }],
    );
  }
  create(
    value?: PartialMessage<ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat>,
  ): ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat,
  ): ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.ListPeriodResponse.RestaurantReservationBlockPeriod.TableSeat
 */
export const ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat =
  new ListPeriodResponse_RestaurantReservationBlockPeriod_TableSeat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.CreateRequest",
      [
        {
          no: 1,
          name: "restaurant_id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 2,
          name: "start_date",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 4,
          name: "start_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 5, name: "end_time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
        {
          no: 6,
          name: "wdays",
          kind: "scalar",
          repeat: 1 /*RepeatType.PACKED*/,
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 7,
          name: "table_seat_ids",
          kind: "scalar",
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      startDate: "",
      endDate: "",
      startTime: 0,
      endTime: 0,
      wdays: [],
      tableSeatIds: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* string end_date */ 3:
          message.endDate = reader.string();
          break;
        case /* int32 start_time */ 4:
          message.startTime = reader.int32();
          break;
        case /* int32 end_time */ 5:
          message.endTime = reader.int32();
          break;
        case /* repeated int32 wdays */ 6:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.wdays.push(reader.int32());
          else message.wdays.push(reader.int32());
          break;
        case /* repeated string table_seat_ids */ 7:
          message.tableSeatIds.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* string end_date = 3; */
    if (message.endDate !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.endDate);
    /* int32 start_time = 4; */
    if (message.startTime !== 0)
      writer.tag(4, WireType.Varint).int32(message.startTime);
    /* int32 end_time = 5; */
    if (message.endTime !== 0)
      writer.tag(5, WireType.Varint).int32(message.endTime);
    /* repeated int32 wdays = 6; */
    if (message.wdays.length) {
      writer.tag(6, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.wdays.length; i++)
        writer.int32(message.wdays[i]);
      writer.join();
    }
    /* repeated string table_seat_ids = 7; */
    for (let i = 0; i < message.tableSeatIds.length; i++)
      writer.tag(7, WireType.LengthDelimited).string(message.tableSeatIds[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.UpdateRequest",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "start_date",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 4,
          name: "start_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 5, name: "end_time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
        {
          no: 6,
          name: "wdays",
          kind: "scalar",
          repeat: 1 /*RepeatType.PACKED*/,
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 7,
          name: "table_seat_ids",
          kind: "scalar",
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      startDate: "",
      endDate: "",
      startTime: 0,
      endTime: 0,
      wdays: [],
      tableSeatIds: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* string end_date */ 3:
          message.endDate = reader.string();
          break;
        case /* int32 start_time */ 4:
          message.startTime = reader.int32();
          break;
        case /* int32 end_time */ 5:
          message.endTime = reader.int32();
          break;
        case /* repeated int32 wdays */ 6:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.wdays.push(reader.int32());
          else message.wdays.push(reader.int32());
          break;
        case /* repeated string table_seat_ids */ 7:
          message.tableSeatIds.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* string end_date = 3; */
    if (message.endDate !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.endDate);
    /* int32 start_time = 4; */
    if (message.startTime !== 0)
      writer.tag(4, WireType.Varint).int32(message.startTime);
    /* int32 end_time = 5; */
    if (message.endTime !== 0)
      writer.tag(5, WireType.Varint).int32(message.endTime);
    /* repeated int32 wdays = 6; */
    if (message.wdays.length) {
      writer.tag(6, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.wdays.length; i++)
        writer.int32(message.wdays[i]);
      writer.join();
    }
    /* repeated string table_seat_ids = 7; */
    for (let i = 0; i < message.tableSeatIds.length; i++)
      writer.tag(7, WireType.LengthDelimited).string(message.tableSeatIds[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_reservation_block.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.DestroyRequest",
      [{ no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }],
    );
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateExceptionRequest$Type extends MessageType<CreateExceptionRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.CreateExceptionRequest",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "start_date",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateExceptionRequest>,
  ): CreateExceptionRequest {
    const message = { id: "", startDate: "", endDate: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateExceptionRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateExceptionRequest,
  ): CreateExceptionRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* string end_date */ 3:
          message.endDate = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateExceptionRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* string end_date = 3; */
    if (message.endDate !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.endDate);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.CreateExceptionRequest
 */
export const CreateExceptionRequest = new CreateExceptionRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlock
 */
export const RestaurantReservationBlock = new ServiceType(
  "auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlock",
  [
    {
      name: "ListPeriod",
      options: {},
      I: ListPeriodRequest,
      O: ListPeriodResponse,
    },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: RestaurantReservationBlockResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: RestaurantReservationBlockResource,
    },
    {
      name: "Get",
      options: {},
      I: GetRequest,
      O: RestaurantReservationBlockResource,
    },
    { name: "Destroy", options: {}, I: DestroyRequest, O: Empty },
    {
      name: "CreateException",
      options: {},
      I: CreateExceptionRequest,
      O: Empty,
    },
  ],
);
