import { isEmpty } from 'lodash'
import React, {
  useCallback,
  useMemo,
  useState as useFormState,
  useState,
  useEffect,
} from 'react'
import { View, ScrollView } from 'react-native'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { Stepper } from '@hello-ai/ar_shared/src/components/Stepper'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { CalendarModal } from '../Calendar'

import QuantityButton from '../../Shared/QuantityButton'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import SelectDateTimeInput from '../../Shared/SelectDateTimeInput'
import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'
import { ReservationFormStepProps } from './types'
import { getFormatTime, toSeconds } from '../../../modules/time'
import {
  RestaurantBusinessTime as RestaurantBusinessTimeModel,
  useRestaurantBusinessTimesByDate,
} from '../../../models/RestaurantBusinessTime'
import {
  DateQuestionTitle,
  DateTimeQuestionTitle,
  PartySizeQuestionTitle,
} from '../FormCommon/Title'
import { displayToastError } from '../../Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useEffectEvent } from '@hello-ai/ar_shared/src/modules/useEffectEvent'

const inputHeight = 64
const inputPaddingHorizontal = 16

function PartySizeInput({
  partySize,
  minPartySize = 0,
  maxPartySize = 100,
  onChangePartySize,
}: {
  partySize: string
  minPartySize?: number
  maxPartySize?: number
  onChangePartySize: (value: string) => void
}) {
  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        },
      ]}
    >
      <QuantityButton
        disabled={Number(partySize || '0') <= minPartySize}
        icon={faMinus}
        onPress={() => {
          const newPartySize = Number(partySize || '0') - 1
          if (newPartySize < minPartySize) {
            onChangePartySize(`${minPartySize}`)
          }
          onChangePartySize(`${newPartySize}`)
        }}
      />
      <TextInput
        value={partySize}
        onChangeText={(text) => onChangePartySize(text)}
        keyboardType="number-pad"
        style={{
          flex: 1,
          marginHorizontal: 8,
          height: inputHeight,
          paddingHorizontal: inputPaddingHorizontal,
        }}
      />
      <QuantityButton
        disabled={Number(partySize || '0') >= maxPartySize}
        icon={faPlus}
        onPress={() => {
          const newPartySize = Number(partySize || '0') + 1
          if (newPartySize > maxPartySize) {
            onChangePartySize(`${maxPartySize}`)
          }
          onChangePartySize(`${newPartySize}`)
        }}
      />
    </View>
  )
}

function validate({
  dateTime,
  adultPartySize,
  childPartySize,
  restaurantBusinessTimes,
}: {
  dateTime: dayjs.Dayjs
  adultPartySize: string
  childPartySize: string
  restaurantBusinessTimes: RestaurantBusinessTimeModel[] | undefined
}) {
  let disabled = false
  const errors: string[] = []

  const startTime = toSeconds(dateTime.hour(), dateTime.minute())

  if (isEmpty(adultPartySize) && isEmpty(childPartySize)) {
    disabled = true
    errors.push(t('人数を入力してください'))
  }

  if (restaurantBusinessTimes != null) {
    const businessTimesWithinOpeningHours = restaurantBusinessTimes.filter(
      (restaurantBusinessTime) =>
        startTime >= restaurantBusinessTime.start_time &&
        startTime <= restaurantBusinessTime.end_time
    )

    if (businessTimesWithinOpeningHours.length === 0) {
      errors.push(t('営業時間外です'))
    }
    const businessTimesPastLastOrder = businessTimesWithinOpeningHours.filter(
      (restaurantBusinessTime) => {
        if (restaurantBusinessTime.last_order_time == null) return false
        return startTime > restaurantBusinessTime.last_order_time
      }
    )

    if (businessTimesPastLastOrder.length > 0) {
      errors.push(t('ラストオーダーの時間を超えています'))
    }
  }

  return { disabled, errors }
}

export function DateTimeAndPartySize({
  restaurantId,
  currentStep,
  skipSteps,
  state,
  onPressGoBack,
  onPressNext,
  onLoad,
}: ReservationFormStepProps & { restaurantId: number; onLoad?: () => void }) {
  const { width, sm } = useResponsive()
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [dateTime, setDateTime] = useFormState(
    dayjs(`${state.dateString} ${getFormatTime(state.startTime)}`)
  )
  const [adultPartySize, setAdultPartySize] = useFormState(
    `${state.adultPartySize}`
  )
  const [childPartySize, setChildPartySize] = useFormState(
    `${state.childPartySize}`
  )

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: dateTime.format('YYYY-MM-DD'),
    }
  )

  const { disabled, errors } = useMemo(
    () =>
      validate({
        dateTime,
        adultPartySize,
        childPartySize,
        restaurantBusinessTimes,
      }),
    [adultPartySize, childPartySize, dateTime, restaurantBusinessTimes]
  )

  const onCalendarModalPress = useCallback(
    (d: dayjs.Dayjs) => {
      setDateTime((dateTime) =>
        dateTime.year(d.year()).month(d.month()).date(d.date())
      )
    },
    [setDateTime]
  )

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  const handleOnLoad = useEffectEvent(() => {
    onLoad?.()
  })
  useEffect(() => {
    handleOnLoad?.()
  }, [handleOnLoad])

  return (
    <Stepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={() => {
        const dateString = dateTime.format('YYYY-MM-DD')
        const startTime = dateTime.hour() * 3600 + dateTime.minute() * 60

        // 日付や時間を変更した場合、既に選択されていた時刻や席が選択できない状態になる場合があるため、終了時刻および選択している席の情報をリセットする
        const shouldResetSeatInfo =
          dateString === state.dateString && startTime === state.startTime

        const endTime = shouldResetSeatInfo ? state.endTime : undefined
        const selectedSeatIds = shouldResetSeatInfo ? state.selectedSeatIds : []

        onPressNext({
          dateString,
          startTime,
          endTime,
          selectedSeatIds,
          adultPartySize: Number(adultPartySize || '0'),
          childPartySize: Number(childPartySize || '0'),
        })
      }}
      isDisabledNextPress={disabled}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: 'white' }}
        contentContainerStyle={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingHorizontal: 32,
          paddingVertical: 48,
        }}
      >
        <View style={{ width: width < sm ? '100%' : '64%' }}>
          <View>
            <DateQuestionTitle />
            <View style={{ marginTop: 24 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: inputHeight,
                  paddingHorizontal: inputPaddingHorizontal,
                  justifyContent: 'center',
                }}
                onPress={() => setCalendarVisible(true)}
              >
                {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
                <Text>{dateTime.format('YYYY年M月DD日(ddd)')}</Text>
              </TouchableOpacity>
              <CalendarModal
                restaurantId={restaurantId}
                title={t('日付を選択')}
                isModalVisible={isCalendarVisible}
                onClose={() => setCalendarVisible(false)}
                selectedDate={dateTime}
                onPress={onCalendarModalPress}
              />
            </View>
          </View>
          <View style={{ marginTop: 40 }}>
            <DateTimeQuestionTitle />
            <View
              style={{
                marginTop: 24,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <SelectDateTimeInput
                mode="time"
                dateTime={dateTime}
                minuteInterval={15}
                onChangeDateTime={(d) => {
                  setDateTime((dateTime) =>
                    dateTime.hour(d.hour()).minute(d.minute())
                  )
                }}
                style={{
                  height: inputHeight,
                  paddingHorizontal: inputPaddingHorizontal,
                  width: '100%',
                }}
              />
            </View>
          </View>
          <View style={{ marginTop: 40 }}>
            <PartySizeQuestionTitle />
            <View style={{ marginTop: 24 }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text style={{ marginRight: 16, width: 64 }}>{t('大人')}:</Text>
                <PartySizeInput
                  partySize={adultPartySize}
                  onChangePartySize={setAdultPartySize}
                />
              </View>
              <View style={{ marginTop: 16 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ marginRight: 16, width: 64 }}>
                    {t('お子様')}:
                  </Text>
                  <PartySizeInput
                    partySize={childPartySize}
                    onChangePartySize={setChildPartySize}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Stepper>
  )
}
