import { ConfigObject, ConfigInterface, ConfigListener } from './types'

const isStaging =
  window.location.hostname.includes('staging') ||
  window.location.hostname.endsWith('.vercel.app')

const isProduction = window.location.hostname === 'autoreserve.com'

function getDefaultApiUrl() {
  if (
    ['localhost', 'lvh.me', '10.0.2.2'].some((str) =>
      location.hostname.endsWith(str)
    )
  ) {
    return 'http://api-table.lvh.me:5100'
  }

  if (isStaging) {
    return 'https://api-table.staging.autoreserve.com'
  }

  return 'https://api-table.autoreserve.com'
}

export class Config implements ConfigInterface {
  static defaultConfig: Required<ConfigObject> = {
    apiUrl: getDefaultApiUrl(),
    printPreviewEnabled: false,
  }

  config: ConfigObject = {}
  listeners: ConfigListener[]

  static STORAGE_KEY = 'ForRWeb:config'

  constructor() {
    this.config = {}
    this.listeners = []
  }

  load() {
    let str
    try {
      str = localStorage.getItem(Config.STORAGE_KEY)
    } catch (e) {
      console.error(e)
    }

    if (str == null) return
    try {
      this.config = JSON.parse(str)
    } catch (e) {
      console.error(e)
    }
  }

  setItem<T extends keyof ConfigObject>(key: T, value: ConfigObject[T]): void {
    this.config[key] = value
    this.save()
  }

  save() {
    const value = JSON.stringify(this.config)
    localStorage.setItem(Config.STORAGE_KEY, value)
  }

  apply() {
    this.listeners.forEach((listener) => {
      listener(this.config)
    })
  }

  subscribe(listener: ConfigListener) {
    this.listeners.push(listener)
  }

  get defaultConfig() {
    return Config.defaultConfig
  }

  get apiUrl() {
    return this.config.apiUrl ?? this.defaultConfig.apiUrl
  }

  get printPreviewEnabled() {
    return (
      this.config.printPreviewEnabled ?? this.defaultConfig.printPreviewEnabled
    )
  }

  get isDevOnly() {
    return process.env.NODE_ENV === 'development' || isStaging
  }

  get isTest() {
    // @ts-expect-error
    return typeof window.Cypress !== 'undefined'
  }

  get isStaging() {
    return isStaging
  }

  getWebSocketUrl(token: string) {
    // if (__DEV__ && !Constants.isDevice) {
    //   return `ws://lvh.me:5100/cable?token=${token}`
    // }
    return `wss://ws.autoreserve.com/cable?token=${token}`
  }

  get isProduction() {
    return isProduction
  }

  restaurantV2BaseUrl: string

  get webBaseUrl() {
    if (
      ['localhost', 'lvh.me', '10.0.2.2'].some((str) =>
        location.hostname.endsWith(str)
      )
    ) {
      return 'https://localhost:3000'
    }
    if (this.isStaging) {
      return 'https://staging.autoreserve.com'
    }
    return 'https://autoreserve.com'
  }

  get nativeUrlScheme() {
    if (
      ['localhost', 'lvh.me', '10.0.2.2'].some((str) =>
        location.hostname.endsWith(str)
      )
    ) {
      return 'autoreserveforrestaurants.dev'
    }
    if (this.isStaging) {
      return 'autoreserveforrestaurants.beta'
    }
    return 'autoreserveforrestaurants'
  }
}

const config = new Config()
config.load()

export default config
