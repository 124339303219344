import React, { useState } from 'react'
import { NewCustomer } from '@hello-ai/for_r_app/src/components/Customers/New'
import { CustomerAttributes } from '@hello-ai/for_r_app/src/components/Customers/Attributes'
import { EditCustomerNoteTemplate } from '@hello-ai/for_r_app/src/components/Customers/EditCustomerNoteTemplate'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useSearchParams } from 'react-router-dom'
import ModalCenter from '@hello-ai/for_r_app/src/components/Shared/ModalCenter'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export default function AddNewCustomerPage() {
  const restaurantId = useRestaurantId()
  const [searchParams] = useSearchParams()
  const footerText = searchParams.get('footerText')
  const redirect_to = searchParams.get('redirect_to')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const firstNameKana = searchParams.get('firstNameKana')
  const lastNameKana = searchParams.get('lastNameKana')
  const dialCode = searchParams.get('dialCode')
  const phoneNumber = searchParams.get('phoneNumber')

  const [isShowAttributesModal, setIsShowAttributesModal] = useState(false)
  const [isShowNoteTemplateModal, setIsShowNoteTemplateModal] = useState(false)

  return (
    <>
      <NewCustomer
        restaurantId={restaurantId}
        defaultProps={{
          firstName: firstName ?? undefined,
          lastName: lastName ?? undefined,
          firstNameKana: firstNameKana ?? undefined,
          lastNameKana: lastNameKana ?? undefined,
          dialCode: dialCode ?? '+81',
          phoneNumber: phoneNumber ?? undefined,
        }}
        footerText={footerText ?? undefined}
        webRedirectTo={redirect_to ?? undefined}
        onPressCustomerAttributes={() => {
          setIsShowNoteTemplateModal(false)
          setIsShowAttributesModal(true)
        }}
        onPressNoteTemplateEdit={() => {
          setIsShowAttributesModal(false)
          setIsShowNoteTemplateModal(true)
        }}
      />
      <ModalCenter
        height="80vh"
        isModalVisible={isShowAttributesModal}
        onClose={() => setIsShowAttributesModal(false)}
        title={t('お客様属性')}
      >
        <CustomerAttributes
          restaurantId={restaurantId}
          onClose={() => setIsShowAttributesModal(false)}
        />
      </ModalCenter>
      <ModalCenter
        height="80vh"
        isModalVisible={isShowNoteTemplateModal}
        onClose={() => setIsShowNoteTemplateModal(false)}
        title={t('テンプレートを編集')}
      >
        <EditCustomerNoteTemplate
          restaurantId={restaurantId}
          onComplete={() => setIsShowNoteTemplateModal(false)}
        />
      </ModalCenter>
    </>
  )
}
