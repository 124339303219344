// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/auth/auth_service.proto" (package "auto_reserve.restaurants.auth", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BusinessUserResource_Role } from "../business_user/business_user_resource";
import { BusinessUserResource_Status } from "../business_user/business_user_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.GetMeResponse
 */
export interface GetMeResponse {
  /**
   * @generated from protobuf field: auto_reserve.restaurants.auth.OwnerResource owner = 1;
   */
  owner?: OwnerResource;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.auth.BusinessUserResource business_user = 2;
   */
  businessUser?: BusinessUserResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.OwnerResource
 */
export interface OwnerResource {
  /**
   * @generated from protobuf field: int32 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.BusinessUserResource
 */
export interface BusinessUserResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string email = 2;
   */
  email: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource.Status status = 4;
   */
  status: BusinessUserResource_Status;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource.Role role = 5;
   */
  role: BusinessUserResource_Role;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.SignInRequest
 */
export interface SignInRequest {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string;
  /**
   * @generated from protobuf field: string password = 2;
   */
  password: string;
  /**
   * @generated from protobuf field: int64 owner_id = 3;
   */
  ownerId: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.SignInResponse
 */
export interface SignInResponse {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.auth.OwnerResource owner = 2;
   */
  owner?: OwnerResource;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.auth.BusinessUserResource business_user = 3;
   */
  businessUser?: BusinessUserResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.SendSignInLinksRequest
 */
export interface SendSignInLinksRequest {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.SendSignInLinksResponse
 */
export interface SendSignInLinksResponse {}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.CreateResetPasswordRequestRequest
 */
export interface CreateResetPasswordRequestRequest {
  /**
   * @generated from protobuf field: string email = 1;
   */
  email: string;
  /**
   * @generated from protobuf field: int64 owner_id = 2;
   */
  ownerId: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.auth.ConfirmResetPasswordRequestRequest
 */
export interface ConfirmResetPasswordRequestRequest {
  /**
   * @generated from protobuf field: string token = 1;
   */
  token: string;
  /**
   * @generated from protobuf field: string new_password = 2;
   */
  newPassword: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetMeResponse$Type extends MessageType<GetMeResponse> {
  constructor() {
    super("auto_reserve.restaurants.auth.GetMeResponse", [
      { no: 1, name: "owner", kind: "message", T: () => OwnerResource },
      {
        no: 2,
        name: "business_user",
        kind: "message",
        T: () => BusinessUserResource,
      },
    ]);
  }
  create(value?: PartialMessage<GetMeResponse>): GetMeResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetMeResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetMeResponse,
  ): GetMeResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* auto_reserve.restaurants.auth.OwnerResource owner */ 1:
          message.owner = OwnerResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.owner,
          );
          break;
        case /* auto_reserve.restaurants.auth.BusinessUserResource business_user */ 2:
          message.businessUser = BusinessUserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.businessUser,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetMeResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* auto_reserve.restaurants.auth.OwnerResource owner = 1; */
    if (message.owner)
      OwnerResource.internalBinaryWrite(
        message.owner,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.auth.BusinessUserResource business_user = 2; */
    if (message.businessUser)
      BusinessUserResource.internalBinaryWrite(
        message.businessUser,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.GetMeResponse
 */
export const GetMeResponse = new GetMeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OwnerResource$Type extends MessageType<OwnerResource> {
  constructor() {
    super("auto_reserve.restaurants.auth.OwnerResource", [
      { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<OwnerResource>): OwnerResource {
    const message = { id: 0, email: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<OwnerResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OwnerResource,
  ): OwnerResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int32 id */ 1:
          message.id = reader.int32();
          break;
        case /* string email */ 2:
          message.email = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: OwnerResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int32(message.id);
    /* string email = 2; */
    if (message.email !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.email);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.OwnerResource
 */
export const OwnerResource = new OwnerResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessUserResource$Type extends MessageType<BusinessUserResource> {
  constructor() {
    super("auto_reserve.restaurants.auth.BusinessUserResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.business_user.BusinessUserResource.Status",
          BusinessUserResource_Status,
        ],
      },
      {
        no: 5,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.business_user.BusinessUserResource.Role",
          BusinessUserResource_Role,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<BusinessUserResource>): BusinessUserResource {
    const message = { id: "", email: "", name: "", status: 0, role: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessUserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessUserResource,
  ): BusinessUserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string email */ 2:
          message.email = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource.Status status */ 4:
          message.status = reader.int32();
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource.Role role */ 5:
          message.role = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessUserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string email = 2; */
    if (message.email !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.email);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.business_user.BusinessUserResource.Status status = 4; */
    if (message.status !== 0)
      writer.tag(4, WireType.Varint).int32(message.status);
    /* auto_reserve.restaurants.business_user.BusinessUserResource.Role role = 5; */
    if (message.role !== 0) writer.tag(5, WireType.Varint).int32(message.role);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.BusinessUserResource
 */
export const BusinessUserResource = new BusinessUserResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignInRequest$Type extends MessageType<SignInRequest> {
  constructor() {
    super("auto_reserve.restaurants.auth.SignInRequest", [
      { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "owner_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<SignInRequest>): SignInRequest {
    const message = { email: "", password: "", ownerId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SignInRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SignInRequest,
  ): SignInRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string();
          break;
        case /* string password */ 2:
          message.password = reader.string();
          break;
        case /* int64 owner_id */ 3:
          message.ownerId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SignInRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.email);
    /* string password = 2; */
    if (message.password !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.password);
    /* int64 owner_id = 3; */
    if (message.ownerId !== 0)
      writer.tag(3, WireType.Varint).int64(message.ownerId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.SignInRequest
 */
export const SignInRequest = new SignInRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignInResponse$Type extends MessageType<SignInResponse> {
  constructor() {
    super("auto_reserve.restaurants.auth.SignInResponse", [
      { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "owner", kind: "message", T: () => OwnerResource },
      {
        no: 3,
        name: "business_user",
        kind: "message",
        T: () => BusinessUserResource,
      },
    ]);
  }
  create(value?: PartialMessage<SignInResponse>): SignInResponse {
    const message = { token: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SignInResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SignInResponse,
  ): SignInResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string token */ 1:
          message.token = reader.string();
          break;
        case /* auto_reserve.restaurants.auth.OwnerResource owner */ 2:
          message.owner = OwnerResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.owner,
          );
          break;
        case /* auto_reserve.restaurants.auth.BusinessUserResource business_user */ 3:
          message.businessUser = BusinessUserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.businessUser,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SignInResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string token = 1; */
    if (message.token !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.token);
    /* auto_reserve.restaurants.auth.OwnerResource owner = 2; */
    if (message.owner)
      OwnerResource.internalBinaryWrite(
        message.owner,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.auth.BusinessUserResource business_user = 3; */
    if (message.businessUser)
      BusinessUserResource.internalBinaryWrite(
        message.businessUser,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.SignInResponse
 */
export const SignInResponse = new SignInResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendSignInLinksRequest$Type extends MessageType<SendSignInLinksRequest> {
  constructor() {
    super("auto_reserve.restaurants.auth.SendSignInLinksRequest", [
      { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(
    value?: PartialMessage<SendSignInLinksRequest>,
  ): SendSignInLinksRequest {
    const message = { email: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SendSignInLinksRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SendSignInLinksRequest,
  ): SendSignInLinksRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SendSignInLinksRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.email);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.SendSignInLinksRequest
 */
export const SendSignInLinksRequest = new SendSignInLinksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendSignInLinksResponse$Type extends MessageType<SendSignInLinksResponse> {
  constructor() {
    super("auto_reserve.restaurants.auth.SendSignInLinksResponse", []);
  }
  create(
    value?: PartialMessage<SendSignInLinksResponse>,
  ): SendSignInLinksResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SendSignInLinksResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SendSignInLinksResponse,
  ): SendSignInLinksResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: SendSignInLinksResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.SendSignInLinksResponse
 */
export const SendSignInLinksResponse = new SendSignInLinksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResetPasswordRequestRequest$Type extends MessageType<CreateResetPasswordRequestRequest> {
  constructor() {
    super("auto_reserve.restaurants.auth.CreateResetPasswordRequestRequest", [
      { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "owner_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(
    value?: PartialMessage<CreateResetPasswordRequestRequest>,
  ): CreateResetPasswordRequestRequest {
    const message = { email: "", ownerId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateResetPasswordRequestRequest>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateResetPasswordRequestRequest,
  ): CreateResetPasswordRequestRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string email */ 1:
          message.email = reader.string();
          break;
        case /* int64 owner_id */ 2:
          message.ownerId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateResetPasswordRequestRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string email = 1; */
    if (message.email !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.email);
    /* int64 owner_id = 2; */
    if (message.ownerId !== 0)
      writer.tag(2, WireType.Varint).int64(message.ownerId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.CreateResetPasswordRequestRequest
 */
export const CreateResetPasswordRequestRequest =
  new CreateResetPasswordRequestRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmResetPasswordRequestRequest$Type extends MessageType<ConfirmResetPasswordRequestRequest> {
  constructor() {
    super("auto_reserve.restaurants.auth.ConfirmResetPasswordRequestRequest", [
      { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "new_password",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(
    value?: PartialMessage<ConfirmResetPasswordRequestRequest>,
  ): ConfirmResetPasswordRequestRequest {
    const message = { token: "", newPassword: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ConfirmResetPasswordRequestRequest>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ConfirmResetPasswordRequestRequest,
  ): ConfirmResetPasswordRequestRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string token */ 1:
          message.token = reader.string();
          break;
        case /* string new_password */ 2:
          message.newPassword = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ConfirmResetPasswordRequestRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string token = 1; */
    if (message.token !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.token);
    /* string new_password = 2; */
    if (message.newPassword !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.newPassword);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.auth.ConfirmResetPasswordRequestRequest
 */
export const ConfirmResetPasswordRequestRequest =
  new ConfirmResetPasswordRequestRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.auth.Auth
 */
export const Auth = new ServiceType("auto_reserve.restaurants.auth.Auth", [
  { name: "GetMe", options: {}, I: Empty, O: GetMeResponse },
  { name: "SignIn", options: {}, I: SignInRequest, O: SignInResponse },
  {
    name: "SendSignInLinks",
    options: {},
    I: SendSignInLinksRequest,
    O: SendSignInLinksResponse,
  },
  {
    name: "CreateResetPasswordRequest",
    options: {},
    I: CreateResetPasswordRequestRequest,
    O: Empty,
  },
  {
    name: "ConfirmResetPasswordRequest",
    options: {},
    I: ConfirmResetPasswordRequestRequest,
    O: Empty,
  },
]);
