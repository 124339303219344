import React from 'react'
import dayjs from 'dayjs'

import RequestReservationListView from '@hello-ai/for_r_app/src/components/Restaurant/RequestReservation/ListView'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function RequestContent({ date }: { date: dayjs.Dayjs }) {
  const restaurantId = useRestaurantId()

  return <RequestReservationListView restaurantId={restaurantId} date={date} />
}
