// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/business_user_role/business_feature_resource.proto" (package "auto_reserve.restaurants.business_user_role", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user_role.BusinessFeatureResource
 */
export interface BusinessFeatureResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey feature_key = 3;
   */
  featureKey: BusinessFeatureResource_FeatureKey;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey
 */
export enum BusinessFeatureResource_FeatureKey {
  /**
   * @generated from protobuf enum value: TABLE_SALES = 0;
   */
  TABLE_SALES = 0,
  /**
   * @generated from protobuf enum value: TABLE_REPORTS = 1;
   */
  TABLE_REPORTS = 1,
  /**
   * @generated from protobuf enum value: TABLE_CUSTOMERS = 2;
   */
  TABLE_CUSTOMERS = 2,
  /**
   * @generated from protobuf enum value: TABLE_MENUS = 3;
   */
  TABLE_MENUS = 3,
  /**
   * @generated from protobuf enum value: TABLE_PAST_ORDERS = 4;
   */
  TABLE_PAST_ORDERS = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class BusinessFeatureResource$Type extends MessageType<BusinessFeatureResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user_role.BusinessFeatureResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 3,
          name: "feature_key",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey",
            BusinessFeatureResource_FeatureKey,
          ],
        },
      ],
    );
  }
  create(
    value?: PartialMessage<BusinessFeatureResource>,
  ): BusinessFeatureResource {
    const message = { id: "", name: "", featureKey: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessFeatureResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessFeatureResource,
  ): BusinessFeatureResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey feature_key */ 3:
          message.featureKey = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessFeatureResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.business_user_role.BusinessFeatureResource.FeatureKey feature_key = 3; */
    if (message.featureKey !== 0)
      writer.tag(3, WireType.Varint).int32(message.featureKey);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user_role.BusinessFeatureResource
 */
export const BusinessFeatureResource = new BusinessFeatureResource$Type();
