type AspidaPathClientMethodType =
  | '$get'
  | '$post'
  | '$put'
  | '$patch'
  | '$delete'

/**
 * @Usage
 * function useUser() {
 *   return useSWR(...createAspidaSWRparameters(client.user))
 * }
 * function useUser() {
 *   const [key, fether] = createAspidaSWRparameters(client.user, params)
 *   return useSWR(key, fetcher)
 * }
 * function useUser() {
 *   const [key, fether] = createAspidaSWRparameters(client.user, body, '$post')
 *   return useSWR(key, fetcher)
 * }
 */
export const createAspidaSWRParameters = <
  AspidaPathClientApi extends Record<string, any> & {
    $path: (option?: any) => string
  } & Record<AspidaPathClientMethod, (option?: any) => Promise<any>>,
  AspidaPathClientMethod extends AspidaPathClientMethodType = '$get'
>(
  api: AspidaPathClientApi | null,
  parameters: Parameters<AspidaPathClientApi[AspidaPathClientMethod]>[0] = {},
  method: AspidaPathClientMethod = '$get' as AspidaPathClientMethod
): [
  null | [string, AspidaPathClientMethod],
  ([apiPath, method]: [
    apiPath: string,
    method: AspidaPathClientMethod
  ]) => ReturnType<AspidaPathClientApi[AspidaPathClientMethod]>
] => {
  return [
    api &&
      (getAspidaSWRKey(api, parameters, method) as [
        string,
        AspidaPathClientMethod
      ]),
    ([_, method]) => api?.[method](parameters),
  ]
}

const getAspidaSWRKey = <
  AspidaPathClientApi extends {
    $path: (option?: any) => string
  } & Record<AspidaPathClientMethod, (option?: any) => any>,
  AspidaPathClientMethod extends AspidaPathClientMethodType = '$get'
>(
  api: AspidaPathClientApi,
  parameters: Parameters<AspidaPathClientApi[AspidaPathClientMethod]>[0] = {},
  method: AspidaPathClientMethod = '$get' as AspidaPathClientMethod
): [string, string] => {
  // NOTE: 型推論でAspidaPathClientMethodがあるとmutateのkeyに直接
  // 引数を取った時に型エラーになるので[string, string]で返す。
  // parametersはapiのconfigを含む可能性があるのでkeyにしない。
  // $pathはparametersのurlとqueryのみをkeyにする。
  return [api.$path(parameters), method]
}

type UnwrapArg<T extends (option?: Record<string, any>) => Promise<any>> =
  Parameters<T>[0] extends object
    ? Parameters<T>[0] & { config?: Parameters<T>[0]['config'] }
    : { config?: any }

export const createAspidaSWRInfiniteParameters = <
  AspidaPathClientApi extends Record<string, any> & {
    $path: (option?: any) => string
  } & Record<AspidaPathClientMethod, (option: any) => Promise<any>>,
  AspidaPathClientMethod extends AspidaPathClientMethodType = '$get'
>(
  api: AspidaPathClientApi,
  getParameters: (
    index: number,
    prevData?: Awaited<ReturnType<AspidaPathClientApi[AspidaPathClientMethod]>>
  ) => Omit<
    UnwrapArg<AspidaPathClientApi[AspidaPathClientMethod]>,
    'config'
  > | null,
  options?: {
    method?: AspidaPathClientMethod
    config?: Pick<
      UnwrapArg<AspidaPathClientApi[AspidaPathClientMethod]>,
      'config'
    >
  }
): [
  (
    index: number,
    prevData?: Awaited<ReturnType<AspidaPathClientApi[AspidaPathClientMethod]>>
  ) =>
    | [
        string,
        AspidaPathClientMethod,
        Omit<UnwrapArg<AspidaPathClientApi[AspidaPathClientMethod]>, 'config'>
      ]
    | null,
  ([apiPath, method, params]: [
    apiPath: string,
    method: AspidaPathClientMethod,
    params: Omit<
      UnwrapArg<AspidaPathClientApi[AspidaPathClientMethod]>,
      'config'
    >
  ]) => ReturnType<AspidaPathClientApi[AspidaPathClientMethod]>
] => {
  const { config, method = '$get' as AspidaPathClientMethod } = options || {}
  // NOTE: cacheKeyの拡散を防ぐため、getParametersの戻り値はconfigを含めないようにしている。
  // $pathはgetParametersのurlとqueryのみをkeyにする。
  // useSWRInfiniteではparameterを動的に取得する必要があるので、configが
  // 必要な場合はoption経由で設定できるようにしている。
  // config以外にもkeyに絡まないparameterが出てきた場合は同じ要領で除外する。
  return [
    (index, prevData) => {
      const params = getParameters(index, prevData)
      return params && [api.$path(params), method, params]
    },
    ([_, method, params]) =>
      api[method](
        config
          ? {
              ...params,
              config,
            }
          : params
      ),
  ]
}

export const createAspidaSWRMutationParameters = <
  AspidaPathClientApi extends Record<string, any> & {
    $path: (option?: any) => string
  } & Record<AspidaPathClientMethod, (option?: any) => Promise<any>>,
  AspidaPathClientMethod extends AspidaPathClientMethodType = '$get'
>(
  api: AspidaPathClientApi | null,
  method: AspidaPathClientMethod = '$get' as AspidaPathClientMethod
): [
  null | [string, AspidaPathClientMethod],
  (
    [apiPath, method]: [apiPath: string, method: AspidaPathClientMethod],
    {
      arg,
    }: {
      arg: Parameters<AspidaPathClientApi[AspidaPathClientMethod]>[0]
    }
  ) => ReturnType<AspidaPathClientApi[AspidaPathClientMethod]>
] => {
  return [
    api &&
      (getAspidaSWRKey(api, {}, method) as [string, AspidaPathClientMethod]),
    ([_, method], { arg }) => api?.[method](arg),
  ]
}
