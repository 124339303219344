import {
  ReservationListCompleteStatus,
  ReservationListStatus,
} from '@hello-ai/ar_shared/src/types/ForR/ReservationList'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export function getStatusLabelText({
  status,
  completeStatus,
}: {
  status: ReservationListStatus
  completeStatus: ReservationListCompleteStatus | null
}) {
  switch (status) {
    case 'complete':
      switch (completeStatus) {
        case 'canceled':
          return t('リクエストキャンセル')
        case 'not_reserved':
          return t('リクエスト不成立')
      }
  }
  return ''
}

export function getStatusLabelColor({
  status,
  completeStatus,
}: {
  status: ReservationListStatus
  completeStatus: ReservationListCompleteStatus | null
}) {
  switch (status) {
    case 'complete':
      switch (completeStatus) {
        case 'canceled':
          return Colors.secondaryBlack
        case 'not_reserved':
          return Colors.secondaryBlack
      }
  }
  return 'transparent'
}
