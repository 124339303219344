import React from 'react'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import TakeoutMenus from 'pages/Menus/TakeoutMenus'
import TakeoutMenusFrom from 'pages/Menus/TakeoutMenus/Form'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('メニュー'),
    fullPath: `/restaurants/:id/takeout/menus`,
    relativePath: `/`,
    exact: true,
    element: <TakeoutMenus />,
  },
  Show: {
    title: t('メニューを追加'),
    fullPath: `/restaurants/:id/takeout/menus/new`,
    relativePath: `/new`,
    element: <TakeoutMenusFrom />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('メニューを編集'),
    fullPath: `/restaurants/:id/takeout/menus/:takeout_menu_id/edit`,
    relativePath: `/:takeout_menu_id/edit`,
    element: <TakeoutMenusFrom />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function TakeoutMenusStack() {
  return <Stack routes={routes} />
}
