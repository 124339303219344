import useSWR, { swrKey } from 'modules/swr'

import {
  GetRequest,
  ListRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/menu_restaurant_group/menu_restaurant_group_service'
import { MenuRestaurantGroupClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/menu_restaurant_group/menu_restaurant_group_service.client'

import { createRpcService, rpcOptions } from 'modules/rpc'
import { useToken } from './Auth'

const menuRestaurantGroupService = createRpcService(MenuRestaurantGroupClient)
export const menuRestaurantGroupClient = menuRestaurantGroupService.client
export function useMenuRestaurantGroups() {
  const token = useToken()
  const params: ListRequest = {}

  const { data, mutate, error } = useSWR(
    swrKey(token, 'MenuRestaurantGroup/List'),
    () => menuRestaurantGroupClient.list(params, rpcOptions({ token })).response
  )

  return {
    data,
    mutate,
    error,
  }
}

export function useMenuRestaurantGroup(menuGroupId?: string) {
  const token = useToken()
  const params: GetRequest = {
    id: menuGroupId ?? '',
  }
  const { data, mutate, error } = useSWR(
    menuGroupId !== undefined
      ? swrKey(token, 'MenuRestaurantGroup/Get', { id: menuGroupId })
      : null,
    () => menuRestaurantGroupClient.get(params, rpcOptions({ token })).response
  )

  return {
    data,
    mutate,
    error,
  }
}
