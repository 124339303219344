import useSWR, { fetcher, onError, swrKey, useStickySWR } from 'modules/swr'
import { TableMenu } from './TableMenu'
import axios, { setHeader, wrapResponse } from 'modules/axios'
import { mutate } from 'swr'
import { useToken } from './Auth'

import { TableMenuPage } from '@hello-ai/ar_shared/src/types/ForR/TableMenuPage'
export * from '@hello-ai/ar_shared/src/types/ForR/TableMenuPage'

export function useTableMenuPages({
  restaurantId,
  params,
}: {
  restaurantId: number
  params?: { per_page?: number; status?: string }
}) {
  const token = useToken()
  const {
    data: tableMenuPages,
    error,
    mutate,
  } = useStickySWR<TableMenuPage[], any, ReturnType<typeof swrKey>>( // あとで検索入れた時用にstickyにしておく
    swrKey(token, `/restaurants/${restaurantId}/menu_pages`, params),
    ([token, url]) => fetcher<TableMenuPage[]>([token, url, params])
  )

  return {
    tableMenuPages: tableMenuPages ?? [],
    isLoading: tableMenuPages == null && error == null,
    error,
    mutate,
  }
}

export function useTableMenuPage({
  tableMenuPageId,
}: {
  tableMenuPageId?: string
}) {
  const token = useToken()
  const {
    data: tableMenuPage,
    error,
    mutate,
  } = useSWR<TableMenuPage, any, ReturnType<typeof swrKey> | null>(
    tableMenuPageId != null
      ? swrKey(token, `/menu_pages/${tableMenuPageId}`)
      : null,
    fetcher
  )

  if (tableMenuPageId == null) {
    return {
      tableMenuPage: undefined,
      isLoading: false,
      mutate,
    }
  }

  return {
    tableMenuPage,
    isLoading: tableMenuPage == null && error == null,
    error,
    mutate,
  }
}

export async function createTableMenuPage({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableMenu>(`/restaurants/${restaurantId}/menu_pages`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/menu_pages/${response?.data?.id}/menu_pages`, params),
      response.data,
      false
    )
  }

  return {
    tableMenuPage: response?.data,
  }
}

export async function updateTableMenuPage({
  token,
  tableMenuPageId,
  params,
}: {
  token: string
  tableMenuPageId: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<TableMenu>(`/menu_pages/${tableMenuPageId}`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/menu_pages/${tableMenuPageId}`),
      response.data,
      false
    )
  }

  return {
    tableMenuPage: response?.data,
  }
}

export function useMenuRestaurantGroupMenuPages(restaurantGroupId: string) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    TableMenuPage[],
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/menu_restaurant_groups/${restaurantGroupId}/menu_pages`),
    fetcher
  )

  return {
    data: data ?? [],
    isLoading: data == null && error == null,
    error,
    mutate,
  }
}

export async function createMenuRestaurantGroupMenuPage({
  restaurantGroupId,
  token,
  params,
}: {
  restaurantGroupId: string
  token: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableMenu>(
      `/menu_restaurant_groups/${restaurantGroupId}/menu_pages`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/menu_restaurant_groups/${restaurantGroupId}/menu_pages/${response?.data?.id}`,
        params
      ),
      response.data,
      false
    )
  }

  return {
    tableMenu: response?.data,
  }
}
