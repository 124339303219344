import useSWR, { fetcher, mutate, onError, swrKey } from '../modules/swr'
import axios, { wrapResponse } from '../modules/axios'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  Restaurant,
  RestaurantImage,
  RestaurantMichelinAwardStatus,
  RestaurantTabelogAwardStatus,
} from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { UpdateRestaurantParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantUpdateParams'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'

export * from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

export const useRestaurant = (restaurantId: number) => {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    Restaurant,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}`), fetcher)

  return {
    data,
    error,
    mutate,
  }
}

export interface RestaurantContract {
  id: 'order' | 'takeout' | 'reservation_book' | 'pos'
  enabled: boolean
  service_name: string
  status: string
  term: string
  fee: string
  monthly_fee: string
  notes: string
  plan_type?: string
}

export function useRestaurantContracts(restaurantId: number) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    Array<RestaurantContract>,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/contracts`), fetcher)

  return {
    data,
    error,
    mutate,
  }
}

interface RestaurantCustomerNoteTemplate {
  id: string
  content: string
}

export function useRestaurantCustomerNoteTemplate(restaurantId: number) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    RestaurantCustomerNoteTemplate,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/restaurant_customer_note_template`,
    ),
    fetcher,
  )

  return {
    data,
    error,
    mutate,
  }
}

export async function updateRestaurantCustomerNoteTemplate(
  token: string | null,
  restaurantId: number,
  params: {
    content: string
  },
) {
  const { response, error } = await wrapResponse(
    axios.patch<{ content: string }>(
      `/restaurants/${restaurantId}/restaurant_customer_note_template`,
      params,
    ),
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/restaurant_customer_note_template`,
      ),
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    customer_note_template: response?.data,
    error,
  }
}

interface RestaurantDailyReservationNote {
  id: string
  content: string
}

const DailyFormat = 'YYYY-MM-DD'

export function useRestaurantDailyReservationNote(
  restaurantId: number,
  date: dayjs.Dayjs,
) {
  const token = useToken()
  const dailyId = date.format(DailyFormat)

  const { data, mutate, error } = useSWR<
    RestaurantDailyReservationNote,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `restaurants/${restaurantId}/restaurant_daily_reservation_notes/${dailyId}`,
    ),
    fetcher,
  )

  return {
    data,
    mutate,
    error,
  }
}

export async function updateRestaurantDailyReservationNote(
  token: string | null,
  restaurantId: number,
  date: dayjs.Dayjs,
  params: {
    content: string
  },
) {
  const dailyId = date.format(DailyFormat)

  const { response, error } = await wrapResponse(
    axios.patch<{ content: string }>(
      `/restaurants/${restaurantId}/restaurant_daily_reservation_notes/${dailyId}`,
      params,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    daily_reservation_note: response?.data,
    error,
  }
}

export async function updateRestaurant(
  restaurantId: number,
  params: UpdateRestaurantParams,
) {
  const { response, error } = await wrapResponse(
    axios.patch<Restaurant>(`/restaurants/${restaurantId}`, params),
  )

  if (error != null) {
    onError(error)
  }

  return {
    restaurant: response?.data,
    error,
  }
}

export async function createInformationChangeRequest(
  restaurantId: number,
  params: {
    changed_name?: string
    changed_phone_number?: string
    changed_address?: string
    changed_open_at?: string
  },
) {
  const { response, error } = await wrapResponse(
    axios.post<Restaurant>(
      `/restaurants/${restaurantId}/information_change_requests`,
      params,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    restaurant: response?.data,
    error,
  }
}

export function useRestaurantInformation(restaurantId: Restaurant['id']) {
  const token = useToken()

  const {
    data: restaurant,
    error,
    mutate,
  } = useSWR<Restaurant, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/information`),
    fetcher,
  )
  return {
    restaurant,
    error,
    mutate,
  }
}

export function useRestaurantMichelinAwards(restaurantId: Restaurant['id']) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    {
      michelin_awards: RestaurantMichelinAwardStatus[]
    },
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/michelin_awards`), fetcher)

  return {
    michelinAwards: data?.michelin_awards,
    error,
    mutate,
  }
}

export async function createRestaurantMichelinAwardsAddRequest(
  restaurantId: number,
  params: { michelin_awards: Omit<RestaurantMichelinAwardStatus, 'status'>[] },
) {
  const { response, error } = await wrapResponse(
    axios.post<{ michelin_awards: RestaurantMichelinAwardStatus[] }>(
      `/restaurants/${restaurantId}/michelin_awards/requests`,
      params,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    requestedMichelinAwards: response?.data.michelin_awards,
    error,
  }
}

export function useRestaurantTabelogAawrds(restaurantId: Restaurant['id']) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    {
      tabelog_awards: RestaurantTabelogAwardStatus[]
    },
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/tabelog_awards`), fetcher)

  if (error != null) {
    onError(error)
  }

  return {
    tabelogAwards: data?.tabelog_awards,
    error,
    mutate,
  }
}

export async function createRestaurantTabelogAwardsRequest(
  restaurantId: number,
  params: { tabelog_awards: Omit<RestaurantTabelogAwardStatus, 'status'>[] },
) {
  const { response, error } = await wrapResponse(
    axios.post<{ tabelog_awards: RestaurantTabelogAwardStatus[] }>(
      `/restaurants/${restaurantId}/tabelog_awards/requests`,
      params,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    requestedTabelogAwards: response?.data.tabelog_awards,
    error,
  }
}

export async function createRestaurantImages(
  restaurantId: Restaurant['id'],
  params: { image: string; category: 'meal' | 'atmosphere' },
) {
  const { response, error } = await wrapResponse(
    axios.post<RestaurantImage>(
      `/owner/restaurants/${restaurantId}/restaurant_images`,
      params,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    createdImage: response,
    error,
  }
}

export async function destroyRestaurantImages(
  restaurantId: Restaurant['id'],
  restaurantImageId: RestaurantImage['id'],
) {
  const { response, error } = await wrapResponse(
    axios.delete(
      `/owner/restaurants/${restaurantId}/restaurant_images/${restaurantImageId}`,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    response,
    error,
  }
}

export const isAvailableShowVisitCount = (
  restaurant?: Pick<Restaurant, 'reservation_book_plan_type'>,
) => {
  if (restaurant == null || restaurant.reservation_book_plan_type == null) {
    return false
  }
  return restaurant.reservation_book_plan_type !== 'free'
}
