import Config from '@hello-ai/for_r_app/src/modules/config'

import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'

export {
  getCountry,
  isZh,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'

export function getLocaleFromPathname(pathname: string) {
  return supportedLocales.find((locale) => {
    return (
      pathname.toLowerCase() === `/${locale.toLowerCase()}` ||
      pathname.toLowerCase().startsWith(`/${locale.toLowerCase()}/`)
    )
  })
}

export function getPathnameWithLocale(pathname: string) {
  const locale = getLocale()

  // Don't append locale to these:
  // autoreserve://
  // https://
  // tel:
  if (/^[a-zA-Z]+:/.test(pathname)) {
    return pathname
  }

  if (getLocaleFromPathname(pathname) !== undefined) {
    return pathname
  }

  return `/${locale.toLowerCase()}${pathname}`
}

export function getLocale() {
  const url = new URL(window.location.href)

  const localeFromPath = getLocaleFromPathname(url.pathname)

  if (localeFromPath !== undefined) {
    return localeFromPath
  }

  if (Config.isTest) {
    return 'ja'
  }

  // https://hello-ai.slack.com/archives/CBBEJRMPY/p1669872548113939?thread_ts=1669792066.501329&cid=CBBEJRMPY
  // 言語pathがない場合、元の日本語ページを新しいjaのページに301リダイレクトさせたいため、jaとして扱う
  return 'ja'
}
