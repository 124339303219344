import React from 'react'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useGourmetSource } from 'modules/useGourmetSiteSource'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import GourmetSiteAccountSettingInitView from '@hello-ai/for_r_app/src/components/GourmetSiteAccount/GourmetSiteAccountSettingInitView'
import { useNavigate } from 'modules/router/useNavigate'
import { ModalHeader } from 'components/Shared/Modal'
import { Modal } from '@hello-ai/ar_shared/src/components/Modal'
import { Colors } from 'react-native-paper'
import { PopoverHost } from '@hello-ai/ar_shared/src/components/Popover'

export default function GourmetSiteAccountSettingInitForm() {
  const restaurantId = useRestaurantId()
  const initialSource = useGourmetSource()
  const navigate = useNavigate()

  return (
    <Modal isVisible style={{ margin: 0 }}>
      <PopoverHost value={{ topBias: 0, leftBias: 0 }}>
        <ModalHeader
          onClose={() => navigate(-1)}
          style={{ backgroundColor: Colors.white }}
          title={t('連携の初期設定')}
        />
        <GourmetSiteAccountSettingInitView
          restaurantId={restaurantId}
          initialSource={initialSource}
          handleOnOpenAdminUrl={(url) => {
            window.open(url, '_blank')
          }}
          onCompleted={({ site }) => {
            displayToastSuccess(t('連携が完了しました'))
            navigate(
              `/restaurants/${restaurantId}/gourmet_site/account_settings/${site}`,
              {
                replace: true,
              }
            )
          }}
        />
      </PopoverHost>
    </Modal>
  )
}
