import React, { useState } from 'react'

import { View } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { authService, useToken } from 'models/Auth'
import { useNavigate, useParams } from 'react-router-dom'
import LinkText from 'components/Shared/LinkText'

import { onError } from 'modules/swr'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import SignInHeader from 'components/Shared/SignInHeader'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const submitButtonWidth = 278
const inputWidth = 420

export default function ResetPassword() {
  const { width, sm } = useResponsive()
  const token = useToken()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const [email, setEmail] = useState('')

  const disabled = email.length === 0

  const onSubmit = async () => {
    if (disabled) return

    const { error } = await authService.createResetPasswordRequest(token, {
      email,
      ownerId: Number(id),
    })
    if (error != null) {
      onError(error)
    }
    displayToastInfo(t('パスワードリセットの確認メールを送信しました'))
    navigate('/sign_in', { replace: true })
  }

  return (
    <View>
      <View
        style={{
          flexGrow: 1,
        }}
      >
        <SignInHeader />
        <View
          style={[
            {
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 80,
            },
            width < sm && {
              paddingHorizontal: 12,
            },
          ]}
        >
          <Text
            style={{
              marginTop: 24,
              fontSize: 18,
              lineHeight: 22,
            }}
          >
            {t(
              'ご登録のメールアドレスを入力してください。パスワード再設定用のリンクを送信します。'
            )}
          </Text>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{
              marginTop: 40,
              width: '100%',
              justifyContent: 'center',
            }}
            formLabel={<FormLabel value={t('メールアドレス')} />}
            formLabelContainerStyle={{
              width: 162,
              marginRight: 24,
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto',
            }}
            containerStyle={{
              width: width < sm ? '100%' : inputWidth,
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto',
            }}
          >
            <TextInput
              value={email}
              onChangeText={setEmail}
              placeholder="information@hello.ai"
            />
          </FormGroup>
          <Button
            disabled={disabled}
            onPress={onSubmit}
            style={{
              marginTop: 48,
              width: width < sm ? '100%' : submitButtonWidth,
            }}
            height={48}
          >
            {t('送信する')}
          </Button>
          <LinkText
            to={`/owners/${id}/sign_in`}
            style={{
              marginTop: 24,
              color: Colors.primary,
            }}
          >
            {t('ログイン画面に戻る')}
          </LinkText>
        </View>
      </View>
    </View>
  )
}
