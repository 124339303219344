// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat/table_seat_service.proto" (package "auto_reserve.restaurants.table_seat", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TableSeat } from "./table_seat_service";
import type { BulkDeleteRequest } from "./table_seat_service";
import type { DeleteRequest } from "./table_seat_service";
import type { PublishRequest } from "./table_seat_service";
import type { ArchiveRequest } from "./table_seat_service";
import type { UpdateRequest } from "./table_seat_service";
import type { CreateRequest } from "./table_seat_service";
import type { TableSeatResource } from "./table_seat_resource";
import type { GetRequest } from "./table_seat_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./table_seat_service";
import type { ListRequest } from "./table_seat_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.table_seat.TableSeat
 */
export interface ITableSeatClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.table_seat.ListRequest) returns (auto_reserve.restaurants.table_seat.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.table_seat.GetRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, TableSeatResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.table_seat.CreateRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, TableSeatResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.table_seat.UpdateRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, TableSeatResource>;
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.table_seat.ArchiveRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, TableSeatResource>;
  /**
   * @generated from protobuf rpc: Publish(auto_reserve.restaurants.table_seat.PublishRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  publish(
    input: PublishRequest,
    options?: RpcOptions,
  ): UnaryCall<PublishRequest, TableSeatResource>;
  /**
   * @generated from protobuf rpc: Delete(auto_reserve.restaurants.table_seat.DeleteRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  delete(
    input: DeleteRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteRequest, TableSeatResource>;
  /**
   * @generated from protobuf rpc: BulkDelete(auto_reserve.restaurants.table_seat.BulkDeleteRequest) returns (auto_reserve.restaurants.table_seat.ListResponse);
   */
  bulkDelete(
    input: BulkDeleteRequest,
    options?: RpcOptions,
  ): UnaryCall<BulkDeleteRequest, ListResponse>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.table_seat.TableSeat
 */
export class TableSeatClient implements ITableSeatClient, ServiceInfo {
  typeName = TableSeat.typeName;
  methods = TableSeat.methods;
  options = TableSeat.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.table_seat.ListRequest) returns (auto_reserve.restaurants.table_seat.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.table_seat.GetRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, TableSeatResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, TableSeatResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.table_seat.CreateRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, TableSeatResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, TableSeatResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.table_seat.UpdateRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, TableSeatResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, TableSeatResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.table_seat.ArchiveRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, TableSeatResource> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ArchiveRequest, TableSeatResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Publish(auto_reserve.restaurants.table_seat.PublishRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  publish(
    input: PublishRequest,
    options?: RpcOptions,
  ): UnaryCall<PublishRequest, TableSeatResource> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<PublishRequest, TableSeatResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Delete(auto_reserve.restaurants.table_seat.DeleteRequest) returns (auto_reserve.restaurants.table_seat.TableSeatResource);
   */
  delete(
    input: DeleteRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteRequest, TableSeatResource> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteRequest, TableSeatResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: BulkDelete(auto_reserve.restaurants.table_seat.BulkDeleteRequest) returns (auto_reserve.restaurants.table_seat.ListResponse);
   */
  bulkDelete(
    input: BulkDeleteRequest,
    options?: RpcOptions,
  ): UnaryCall<BulkDeleteRequest, ListResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<BulkDeleteRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
