import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Header } from '../../Shared/Header'
import { Modal } from '../../Shared/Modal'
import SelectRestaurantCrewMember from '../../Shared/SelectRestaurantCrewMember'
import React, { useState } from 'react'
import { Dimensions, Keyboard, Pressable, View, Platform } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function SelectRevisionCasherModal({
  restaurantId,
  defaultRestaurantCrewMemberId,
  onPressSubmit,
  onPressCancel,
}: {
  restaurantId: number
  defaultRestaurantCrewMemberId: string | null
  onPressSubmit: (param: {
    restaurantCrewMemberId: string
    reason: string
  }) => void
  onPressCancel: () => void
}) {
  const { width, sm } = useResponsive()
  const [selectedRestaurantCrewMemberId, setSelectedRestaurantCrewMemberId] =
    useState<string | null>(defaultRestaurantCrewMemberId)
  const [reason, setReason] = useState('')

  return (
    <Modal
      isVisible
      onBackdropPress={onPressCancel}
      style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        margin: 0,
        backgroundColor: Colors.white,
      }}
      contentContainerStyle={{
        flex: 1,
      }}
    >
      <Header title={t('金額の修正担当者を選択')} onClose={onPressCancel} />
      <Pressable
        onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
        style={{
          marginTop: width >= sm ? 56 : 26,
          rowGap: 40,
          alignItems: 'center',
          paddingHorizontal: 40,
        }}
      >
        <>
          <Text
            style={{
              fontWeight: '600',
              fontSize: 18,
              lineHeight: 27,
              textAlign: 'center',
            }}
          >
            {t('会計の担当者を記録します。\nあなたの名前を選択してください')}
          </Text>
          <SelectRestaurantCrewMember
            restaurantId={restaurantId}
            style={{
              width: width >= sm ? 562 : undefined,
            }}
            restaurantCrewMemberId={selectedRestaurantCrewMemberId}
            setRestaurantCrewMemberId={setSelectedRestaurantCrewMemberId}
          />
          <View
            style={{
              flexDirection: width >= sm ? 'row' : 'column',
              alignItems: width >= sm ? 'center' : 'flex-start',
              justifyContent: 'center',
              gap: 8,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 70,
              }}
            >
              <Text
                style={{
                  fontSize: width < sm ? 14 : 18,
                  marginRight: 8,
                  fontWeight: '600',
                }}
              >
                {t('修正理由')}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: '400',
                  color: '#FFFFFF',
                  backgroundColor: '#E84733',
                  paddingHorizontal: 3,
                  borderRadius: 2,
                }}
              >
                {t('必須')}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: width >= sm ? 400 : undefined,
              }}
            >
              <TextInput
                style={{ flex: 1 }}
                height={48}
                value={reason}
                onChangeText={setReason}
              />
            </View>
          </View>
        </>
      </Pressable>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 16,
          paddingBottom: 32,
          borderTopWidth: 0.5,
          borderTopColor: Colors.border,
        }}
      >
        <Button
          width={280}
          height={48}
          style={{
            alignSelf: 'center',
          }}
          disabled={selectedRestaurantCrewMemberId === null}
          onPress={() => {
            if (selectedRestaurantCrewMemberId == null) return
            onPressSubmit({
              restaurantCrewMemberId: selectedRestaurantCrewMemberId,
              reason,
            })
          }}
        >
          {t('修正に進む')}
        </Button>
      </View>
    </Modal>
  )
}
