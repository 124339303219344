import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { MenuRestaurantGroupResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/menu_restaurant_group/menu_restaurant_group_resource'
import React from 'react'

import { View } from 'react-native'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'

export default function MenuRestaurantGroup({
  onPress,
  group,
}: {
  group: MenuRestaurantGroupResource
  onPress: () => void
}) {
  return (
    <TouchableOpacity
      key={group.id}
      onPress={onPress}
      style={{
        height: 100,
        paddingHorizontal: 32,
        borderBottomColor: Colors.border,
        borderBottomWidth: 0.5,
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Text
        numberOfLines={3}
        style={{
          flex: 1,
          fontSize: 16,
        }}
      >
        {group.name}
      </Text>
      <Text
        numberOfLines={3}
        style={{
          flex: 1,
          fontSize: 16,
        }}
      >
        {group.description}
      </Text>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text
          numberOfLines={3}
          style={{
            fontSize: 16,
          }}
        >
          {group.restaurants
            .map((restaurant) => {
              return restaurant.name
            })
            .join(', ')}
        </Text>
        <View
          style={{
            marginLeft: 8,
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} size={16} />
        </View>
      </View>
    </TouchableOpacity>
  )
}
