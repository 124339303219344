import { assertNever } from '../../modules/assert'
import { TableCashInDrawerSnapshot } from '../../types/ForR/TableBalancingCashDrawer'

import { t } from '../../modules/i18n'

export * from '../../types/ForR/TableBalancingCashDrawer'

export type CashInDrawerSnapshotKey = Exclude<
  keyof TableCashInDrawerSnapshot,
  'id'
>

export function convertCashInDrawerSnapshotKeyToNumber(
  cashInDrawerSnapshotKey: CashInDrawerSnapshotKey
) {
  switch (cashInDrawerSnapshotKey) {
    case 'amount_of_10000_yen_note':
      return 10000
    case 'amount_of_1000_yen_note':
      return 1000
    case 'amount_of_100_yen_coin':
      return 100
    case 'amount_of_10_yen_coin':
      return 10
    case 'amount_of_1_yen_coin':
      return 1
    case 'amount_of_2000_yen_note':
      return 2000
    case 'amount_of_5000_yen_note':
      return 5000
    case 'amount_of_500_yen_coin':
      return 500
    case 'amount_of_50_yen_coin':
      return 50
    case 'amount_of_5_yen_coin':
      return 5
    default:
      assertNever(cashInDrawerSnapshotKey)
  }
}

export function convertCashInDrawerSnapshotKeyToName(
  cashInDrawerSnapshotKey: CashInDrawerSnapshotKey
) {
  switch (cashInDrawerSnapshotKey) {
    case 'amount_of_10000_yen_note':
      return t('一万円札')
    case 'amount_of_1000_yen_note':
      return t('千円札')
    case 'amount_of_100_yen_coin':
      return t('100円硬貨')
    case 'amount_of_10_yen_coin':
      return t('10円硬貨')
    case 'amount_of_1_yen_coin':
      return t('1円硬貨')
    case 'amount_of_2000_yen_note':
      return t('二千円札')
    case 'amount_of_5000_yen_note':
      return t('五千円札')
    case 'amount_of_500_yen_coin':
      return t('500円硬貨')
    case 'amount_of_50_yen_coin':
      return t('50円硬貨')
    case 'amount_of_5_yen_coin':
      return t('5円硬貨')
    default:
      assertNever(cashInDrawerSnapshotKey)
  }
}
