import React, { useCallback } from 'react'
import { View, Text, Platform } from 'react-native'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { PhoneNumberInput } from '@hello-ai/for_r_app/src/components/Shared/PhoneNumberInput'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

import { useNavigation } from '../../modules/navigation/useNavigation'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useNavigate } from '../../modules/navigation/useNavigate'

const defaultDialCode = '+81' // JP

interface FormValues {
  dialCode: string
  phoneNumber: string
  lastNameKana: string
  firstNameKana: string
  lastName: string
  firstName: string
}

export type SearchHandler = (values: FormValues) => void
interface Props {
  restaurantId: number
  onSearch: SearchHandler
  onCustomerAdded: () => void
}
export function CustomerSearchForm({
  restaurantId,
  onSearch,
  onCustomerAdded,
}: Props) {
  const [firstNameKana, setFirstNameKana] = useFormState('')
  const [lastNameKana, setLastNameKana] = useFormState('')
  const [firstName, setFirstName] = useFormState('')
  const [lastName, setLastName] = useFormState('')
  const [dialCode, setDialCode] = useFormState(defaultDialCode)
  const [phoneNumber, setPhoneNumber] = useFormState('')

  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const handleSearch = useCallback(() => {
    onSearch({
      dialCode,
      phoneNumber,
      lastNameKana,
      firstNameKana,
      lastName,
      firstName,
    })
  }, [
    dialCode,
    phoneNumber,
    lastNameKana,
    firstNameKana,
    lastName,
    firstName,
    onSearch,
  ])

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          height: 36,
          marginBottom: 8,
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
            flexGrow: 1,
          }}
        >
          {t('顧客一覧')}
        </Text>
        <Button
          mode={'outline'}
          onPress={() => {
            if (Platform.OS === 'web') {
              navigate({
                pathname: `/restaurants/${restaurantId}/customers/new`,
                search: new URLSearchParams({
                  firstNameKana,
                  lastNameKana,
                  firstName,
                  lastName,
                  dialCode,
                  phoneNumber,
                  footerText: t('登録する'),
                }).toString(),
              })
            } else {
              navigation.navigate('AddNewCustomerModal', {
                onCustomerAdded,
                defaultProps: {
                  firstNameKana,
                  lastNameKana,
                  firstName,
                  lastName,
                  dialCode,
                  phoneNumber,
                },
                footerText: t('登録する'),
              })
            }
          }}
          style={{
            backgroundColor: 'transparent',
            height: 36,
            paddingHorizontal: 16,
          }}
        >
          {width >= sm ? t('新規顧客情報を登録する') : t('新規登録')}
        </Button>
      </View>
      <ShadowBox
        style={{
          flexDirection: 'row',
          padding: width >= sm ? 24 : 16,
        }}
      >
        <View style={{ flex: 2 }}>
          <FormGroup
            mode={'vertical'}
            formLabel={
              <FormLabel
                value={t('名前')}
                labelStyle={{
                  fontSize: 14,
                  lineHeight: 22,
                  fontWeight: '600',
                }}
              />
            }
            containerStyle={{ marginTop: 8 }}
          >
            <View style={{ flexDirection: 'row', height: 48 }}>
              <TextInput
                placeholder={t('せい')}
                style={{ flex: 1, height: 48 }}
                value={lastNameKana}
                onChangeText={(text) => setLastNameKana(text)}
                onEndEditing={handleSearch}
                onBlur={() => {
                  if (Platform.OS === 'web') {
                    handleSearch()
                  }
                }}
              />
              <TextInput
                placeholder={t('めい')}
                style={{ flex: 1, marginLeft: 8, height: 48 }}
                value={firstNameKana}
                onChangeText={(text) => setFirstNameKana(text)}
                onEndEditing={handleSearch}
                onBlur={() => {
                  if (Platform.OS === 'web') {
                    handleSearch()
                  }
                }}
              />
            </View>
            <View
              style={{
                marginTop: 8,
                flexDirection: 'row',
                height: 48,
              }}
            >
              <TextInput
                placeholder={t('姓')}
                style={{ flex: 1, height: 48 }}
                value={lastName}
                onChangeText={(text) => setLastName(text)}
                onEndEditing={handleSearch}
                onBlur={() => {
                  if (Platform.OS === 'web') {
                    handleSearch()
                  }
                }}
              />
              <TextInput
                placeholder={t('名')}
                style={{ flex: 1, marginLeft: 8, height: 48 }}
                value={firstName}
                onChangeText={(text) => setFirstName(text)}
                onEndEditing={handleSearch}
                onBlur={() => {
                  if (Platform.OS === 'web') {
                    handleSearch()
                  }
                }}
              />
            </View>
          </FormGroup>
          <FormGroup
            mode={'vertical'}
            formLabel={
              <FormLabel
                value={t('電話番号')}
                labelStyle={{
                  fontSize: 14,
                  lineHeight: 22,
                  fontWeight: '600',
                }}
              />
            }
            style={{ marginTop: 24 }}
            containerStyle={{ marginTop: 8 }}
          >
            <PhoneNumberInput
              dialCode={dialCode}
              onChangeDialCode={setDialCode}
              style={width < sm && { height: 48 }}
            >
              <TextInput
                style={[{ flex: 1 }, width < sm && { height: 48 }]}
                placeholder={t('ハイフンなしで入力')}
                value={phoneNumber}
                onChangeText={(text) => setPhoneNumber(text)}
                onEndEditing={handleSearch}
                onBlur={() => {
                  if (Platform.OS === 'web') {
                    handleSearch()
                  }
                }}
                keyboardType="phone-pad"
                textContentType="telephoneNumber"
                clearButtonMode="always"
              />
            </PhoneNumberInput>
          </FormGroup>
        </View>
      </ShadowBox>
    </View>
  )
}
