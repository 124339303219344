import { useBusinessUserRoles } from 'models/BusinessUserRole'
import React from 'react'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { View } from 'react-native'

import { BusinessRolePermissionList } from 'components/Top/BusinessRolePermission'
import { Table } from 'components/Top/Table'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useNavigate } from 'modules/router/useNavigate'
import Title from '@hello-ai/ar_shared/src/components/ForR/Shared/Title'
import FloatingButton from 'components/Shared/FloatingButton'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

export default function BusinessUserRoles() {
  const navigate = useNavigate()
  const { businessUserRoles } = useBusinessUserRoles()
  const { width, sm } = useResponsive()

  if (businessUserRoles === undefined) {
    return <Loading />
  }

  return (
    <View
      style={{
        backgroundColor: Colors.bgBlack,
        padding: width < sm ? 24 : 48,
        flex: 1,
        flexGrow: 1,
      }}
    >
      <Title title={t('役割')} />
      <Table
        headers={[
          { label: t('役割名'), key: 'name', hiddenSP: true },
          { label: t('説明'), key: 'description' },
          { label: t('権限'), key: 'permissions' },
        ]}
        data={businessUserRoles}
        keyExtractor={(role) => role.id}
        toExtractor={(role) => `/business_user_roles/${role.id}`}
        cellRender={(key, item) => {
          switch (key) {
            case 'name':
              return (
                <Text
                  style={{ fontSize: width < sm ? 18 : 16, fontWeight: '600' }}
                >
                  {item.name}
                </Text>
              )
            case 'description':
              return <Text>{item.description}</Text>
            case 'permissions': {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <BusinessRolePermissionList
                    businessUserRolePermissions={
                      item.businessUserRolePermissions
                    }
                  />
                </View>
              )
            }
          }
        }}
      />
      <FloatingButton
        onPress={() => {
          navigate('/business_user_roles/new')
        }}
        style={{
          position: 'absolute',
          bottom: 32,
          right: 32,
        }}
        label={t('追加')}
      />
    </View>
  )
}
