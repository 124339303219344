import React, { useMemo } from 'react'
import { ReservationForm } from '@hello-ai/for_r_app/src/components/Reservation/Form'
import { useParams } from 'react-router'
import { useRestaurant } from '@hello-ai/for_r_app/src/models/Restaurant'
import { useSearchParams } from 'react-router-dom'

const useTime = (value: string | null) => {
  return useMemo(() => {
    if (value == null) return undefined
    const parsed = parseInt(value)
    if (isNaN(parsed)) return undefined
    if (parsed < 0) return undefined
    if (parsed > 24 * 3600) return undefined
    return parsed
  }, [value])
}

function ReservationFormPage() {
  const { id: restaurantId, reservationId } = useParams<{
    id: string
    reservationId: string
  }>()
  const [params] = useSearchParams()
  const { data: restaurant } = useRestaurant(parseInt(restaurantId!))
  const startTime = useTime(params.get('startTime'))
  const endTime = useTime(params.get('endTime'))
  const selectedSeatIds = params.get('selectedSeatIds')?.split(',')

  return (
    <ReservationForm
      restaurantId={parseInt(restaurantId!)}
      restaurantReservationId={reservationId}
      tableRestaurantSetting={restaurant?.table_restaurant_setting!}
      dateString={params.get('dateString') ?? undefined}
      startTime={startTime}
      // startTime が null でない場合は endTime も渡す
      endTime={startTime != null ? endTime : undefined}
      selectedSeatIds={selectedSeatIds}
    />
  )
}

export default ReservationFormPage
