import {
  RestaurantTabelogAwardStatus,
  Restaurant,
  RestaurantMichelinAwardStatus,
} from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export function getOpenStatusColor(
  restaurantOpenStatus: Restaurant['open_status']
): string {
  switch (restaurantOpenStatus) {
    case 'open':
      return Colors.success
    case 'temporarily_closed':
      return Colors.caution
    case 'permanently_closed':
      return Colors.caution
  }
}

export function getOpenStatusLabel(
  restaurantOpenStatus: Restaurant['open_status']
): string {
  switch (restaurantOpenStatus) {
    case 'open':
      return t('営業中')
    case 'temporarily_closed':
      return t('休業')
    case 'permanently_closed':
      return t('閉店')
  }
}

export function getMichelinAwardLabel(
  restaurantOpenStatus: RestaurantMichelinAwardStatus['category']
): string {
  switch (restaurantOpenStatus) {
    case 'one_star':
      return t('一つ星')
    case 'two_stars':
      return t('二つ星')
    case 'three_stars':
      return t('三つ星')
    case 'bibgourmand':
      return t('ビブグルマン')
  }
}

export function getMichelinAwardStatusLabel(
  restaurantOpenStatus: RestaurantMichelinAwardStatus['status']
): string {
  switch (restaurantOpenStatus) {
    case 'approved':
      return t('登録済')
    case 'requested':
      return t('審査中')
    case 'rejected':
      return ''
  }
}

export function getMichelinAwardStatusColor(
  restaurantOpenStatus: RestaurantMichelinAwardStatus['status']
): string {
  switch (restaurantOpenStatus) {
    case 'approved':
      return Colors.success
    case 'requested':
      return Colors.black40
    case 'rejected':
      return Colors.black40
  }
}

export function getTabelogAwardLabel(
  restaurantOpenStatus: RestaurantTabelogAwardStatus['category']
): string {
  switch (restaurantOpenStatus) {
    case 'bronze':
      return t('ブロンズ')
    case 'silver':
      return t('シルバー')
    case 'gold':
      return t('ゴールド')
  }
}

export function getTabelogAwardStatusColor(
  restaurantOpenStatus: RestaurantTabelogAwardStatus['status']
): string {
  switch (restaurantOpenStatus) {
    case 'approved':
      return Colors.success
    case 'requested':
      return Colors.black40
    case 'rejected':
      return Colors.black40
  }
}

export function getTabelogAwardStatusLabel(
  restaurantOpenStatus: RestaurantTabelogAwardStatus['status']
): string {
  switch (restaurantOpenStatus) {
    case 'approved':
      return t('登録済')
    case 'requested':
      return t('審査中')
    case 'rejected':
      return ''
  }
}
