// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service.proto" (package "auto_reserve.restaurants.restaurant_reservation", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarRequest
 */
export interface ByCalendarRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string gte = 2;
   */
  gte: string;
  /**
   * @generated from protobuf field: string lte = 3;
   */
  lte: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse
 */
export interface ByCalendarResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource dates = 1;
   */
  dates: ByCalendarResponse_DateResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource
 */
export interface ByCalendarResponse_DateResource {
  /**
   * @generated from protobuf field: string date = 1;
   */
  date: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource periods = 2;
   */
  periods: ByCalendarResponse_DateResource_PeriodResource[];
  /**
   * @generated from protobuf field: bool is_holiday = 3;
   */
  isHoliday: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource
 */
export interface ByCalendarResponse_DateResource_PeriodResource {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource.Type type = 3;
   */
  type: ByCalendarResponse_DateResource_PeriodResource_Type;
  /**
   * @generated from protobuf field: bool vacant = 4;
   */
  vacant: boolean;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource.Type
 */
export enum ByCalendarResponse_DateResource_PeriodResource_Type {
  /**
   * @generated from protobuf enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: BUSINESS_TIME = 1;
   */
  BUSINESS_TIME = 1,
  /**
   * @generated from protobuf enum value: OTHER = 2;
   */
  OTHER = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class ByCalendarRequest$Type extends MessageType<ByCalendarRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_reservation.ByCalendarRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "gte", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "lte", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ByCalendarRequest>): ByCalendarRequest {
    const message = { restaurantId: 0, gte: "", lte: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ByCalendarRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ByCalendarRequest,
  ): ByCalendarRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string gte */ 2:
          message.gte = reader.string();
          break;
        case /* string lte */ 3:
          message.lte = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ByCalendarRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string gte = 2; */
    if (message.gte !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.gte);
    /* string lte = 3; */
    if (message.lte !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.lte);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarRequest
 */
export const ByCalendarRequest = new ByCalendarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ByCalendarResponse$Type extends MessageType<ByCalendarResponse> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse",
      [
        {
          no: 1,
          name: "dates",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ByCalendarResponse_DateResource,
        },
      ],
    );
  }
  create(value?: PartialMessage<ByCalendarResponse>): ByCalendarResponse {
    const message = { dates: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ByCalendarResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ByCalendarResponse,
  ): ByCalendarResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource dates */ 1:
          message.dates.push(
            ByCalendarResponse_DateResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ByCalendarResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource dates = 1; */
    for (let i = 0; i < message.dates.length; i++)
      ByCalendarResponse_DateResource.internalBinaryWrite(
        message.dates[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse
 */
export const ByCalendarResponse = new ByCalendarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ByCalendarResponse_DateResource$Type extends MessageType<ByCalendarResponse_DateResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource",
      [
        { no: 1, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "periods",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ByCalendarResponse_DateResource_PeriodResource,
        },
        { no: 3, name: "is_holiday", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<ByCalendarResponse_DateResource>,
  ): ByCalendarResponse_DateResource {
    const message = { date: "", periods: [], isHoliday: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ByCalendarResponse_DateResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ByCalendarResponse_DateResource,
  ): ByCalendarResponse_DateResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string date */ 1:
          message.date = reader.string();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource periods */ 2:
          message.periods.push(
            ByCalendarResponse_DateResource_PeriodResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* bool is_holiday */ 3:
          message.isHoliday = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ByCalendarResponse_DateResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string date = 1; */
    if (message.date !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.date);
    /* repeated auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource periods = 2; */
    for (let i = 0; i < message.periods.length; i++)
      ByCalendarResponse_DateResource_PeriodResource.internalBinaryWrite(
        message.periods[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool is_holiday = 3; */
    if (message.isHoliday !== false)
      writer.tag(3, WireType.Varint).bool(message.isHoliday);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource
 */
export const ByCalendarResponse_DateResource =
  new ByCalendarResponse_DateResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ByCalendarResponse_DateResource_PeriodResource$Type extends MessageType<ByCalendarResponse_DateResource_PeriodResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource",
      [
        { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: "type",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource.Type",
            ByCalendarResponse_DateResource_PeriodResource_Type,
          ],
        },
        { no: 4, name: "vacant", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<ByCalendarResponse_DateResource_PeriodResource>,
  ): ByCalendarResponse_DateResource_PeriodResource {
    const message = { name: "", description: "", type: 0, vacant: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ByCalendarResponse_DateResource_PeriodResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ByCalendarResponse_DateResource_PeriodResource,
  ): ByCalendarResponse_DateResource_PeriodResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string();
          break;
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource.Type type */ 3:
          message.type = reader.int32();
          break;
        case /* bool vacant */ 4:
          message.vacant = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ByCalendarResponse_DateResource_PeriodResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.name);
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource.Type type = 3; */
    if (message.type !== 0) writer.tag(3, WireType.Varint).int32(message.type);
    /* bool vacant = 4; */
    if (message.vacant !== false)
      writer.tag(4, WireType.Varint).bool(message.vacant);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse.DateResource.PeriodResource
 */
export const ByCalendarResponse_DateResource_PeriodResource =
  new ByCalendarResponse_DateResource_PeriodResource$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.restaurant_reservation.RestaurantReservation
 */
export const RestaurantReservation = new ServiceType(
  "auto_reserve.restaurants.restaurant_reservation.RestaurantReservation",
  [
    {
      name: "ByCalendar",
      options: {},
      I: ByCalendarRequest,
      O: ByCalendarResponse,
    },
  ],
);
