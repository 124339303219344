import React from 'react'
import { View, ScrollView } from 'react-native'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInputWithIcon } from '@hello-ai/ar_shared/src/components/TextInputWithIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  useRestaurantRecommendedProductCandidates,
  RecommendedProductCandidate,
} from '../../../models/RecommendedProductCandidate'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  ModalCenter,
  ModalHeader,
} from '@hello-ai/ar_shared/src/components/ModalCenter'
import { useSearchAndPagination } from '../../../modules/useSearchAndPagination'

interface RecommendedProductCandidatesSearchModalProps {
  isVisible: boolean
  onClose: () => void
  onPress?: (value: RecommendedProductCandidate) => void
  restaurantId: number
}

export function RecommendedProductCandidatesSearchModal({
  isVisible,
  onClose,
  onPress,
  restaurantId,
}: RecommendedProductCandidatesSearchModalProps) {
  const { width, sm, md } = useResponsive()

  const { keyword, currentPage, onChangeSearchText, onChangeCurrentPage } =
    useSearchAndPagination()

  const { data } = useRestaurantRecommendedProductCandidates(restaurantId, {
    keyword,
    page: currentPage,
  })

  return (
    <ModalCenter isVisible={isVisible} onClose={onClose} mode="fullScreen">
      <View
        style={[
          {
            // WORKAROUND: ModalCenterの実装がfor_r_web, for_r_native, ar_sharedで統一されていないため
            // 一旦手動でスタイルを調整している
            backgroundColor: Colors.white,
            borderRadius: width < md ? 0 : 8,
            overflow: 'hidden',
          },
          width < md && {
            flex: 1,
          },
        ]}
      >
        <ModalHeader onClose={onClose}>
          {t('商品またはコースを選択')}
        </ModalHeader>
        <View>
          <View
            style={{
              margin: 16,
              height: 48,
            }}
          >
            <TextInputWithIcon
              onChangeText={(text) => onChangeSearchText(text)}
              autoCapitalize="none"
              placeholder={t('商品名、コース名で検索')}
              icon={faSearch}
              style={[
                width < sm && {
                  fontSize: 12,
                  paddingLeft: 40,
                  height: '100%',
                  paddingVertical: 16,
                },
                {
                  color: Colors.black,
                  paddingTop: 12,
                  paddingBottom: 12,
                },
              ]}
            />
          </View>
          <ScrollView style={{ marginTop: 24, maxHeight: '400px' }}>
            {data == null && (
              <View style={{ padding: 24 }}>
                <Loading />
              </View>
            )}
            {data?.data != null && data.data.length === 0 && (
              <View
                style={{
                  height: 100,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>{t('検索結果がありません')}</Text>
              </View>
            )}
            {(data?.data ?? []).map((recommendedProductCandidate, i) => {
              return (
                <TouchableOpacity
                  key={recommendedProductCandidate.id}
                  style={[
                    {
                      padding: 16,
                    },
                    (data?.data ?? []).length - 1 !== i && {
                      borderBottomColor: Colors.border,
                      borderBottomWidth: 0.5,
                      borderStyle: 'solid',
                    },
                  ]}
                  onPress={() => {
                    onPress && onPress(recommendedProductCandidate)
                    onClose()
                  }}
                >
                  <Text>{recommendedProductCandidate.name}</Text>
                </TouchableOpacity>
              )
            })}
          </ScrollView>
          <View
            style={{
              borderTopWidth: 0.5,
              borderColor: Colors.border,
              paddingTop: 24,
              marginBottom: 16,
            }}
          >
            <Pagination
              currentPage={currentPage}
              totalPage={data?.headerData?.totalPages ?? 0}
              setPage={(page) => onChangeCurrentPage(page)}
            />
          </View>
        </View>
      </View>
    </ModalCenter>
  )
}
