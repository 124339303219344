import React from 'react'
import { View, TextStyle, ViewStyle, StyleProp } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'

export const smallCellTextStyle: StyleProp<TextStyle> = {
  fontSize: 14,
}

interface CellProps {
  children?: React.ReactNode
  isLead?: boolean
  alignItems?: ViewStyle['alignItems']
  onPress?: () => void
  height?: number
  style?: StyleProp<ViewStyle>
}
export function Cell({
  children,
  isLead = false,
  alignItems,
  onPress,
  height = 60,
  style,
}: CellProps) {
  return (
    <View
      style={[
        {
          height,
          paddingTop: 10,
          paddingBottom: 10,
          justifyContent: 'center',
          alignItems,
        },
        isLead && {
          borderRightColor: 'rgba(68, 68, 68, 0.16)',
          borderRightWidth: 1,
          width: 100,
          marginRight: 32,
        },
        !isLead && {
          flex: 1,
          alignSelf: 'stretch',
        },
        style,
      ]}
    >
      <TouchableOpacity disabled={onPress == null} onPress={onPress}>
        {children}
      </TouchableOpacity>
    </View>
  )
}

interface TextCellProps {
  value: string
  isLead?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  numberOfLines?: number
  onPress?: () => void
  style?: StyleProp<TextStyle>
  cellStyle?: StyleProp<ViewStyle>
}
export function TextCell({
  value,
  isLead,
  onPress,
  style,
  cellStyle,
  numberOfLines,
}: TextCellProps) {
  return (
    <Cell style={cellStyle} isLead={isLead} onPress={onPress}>
      <Text
        numberOfLines={numberOfLines}
        selectable
        style={[
          {
            fontSize: 18,
            lineHeight: 22,
            color: Colors.black,
          },
          style,
        ]}
      >
        {value}
      </Text>
    </Cell>
  )
}

export function TextCellWithDetail({
  value,
  detailValue,
  isLead,
  style,
  cellStyle,
  onPress,
  isTextBold = false,
}: TextCellProps & {
  detailValue: string
  isTextBold?: boolean
}) {
  return (
    <Cell style={cellStyle} isLead={isLead} onPress={onPress}>
      <View>
        <Text
          selectable
          style={[
            { fontSize: 18, color: Colors.black },
            isTextBold && { fontWeight: '600' },
            style,
          ]}
        >
          {value}
        </Text>
        <Text
          selectable
          style={[
            {
              fontSize: 16,
              fontWeight: '300',
              color: Colors.secondaryBlack,
            },
            isTextBold && {
              fontWeight: '600',
            },
          ]}
        >
          {detailValue}
        </Text>
      </View>
    </Cell>
  )
}

export function HeaderText({ value }: { value: string }) {
  return (
    <Text
      style={{
        fontSize: 16,
        fontWeight: '600',
        color: Colors.black,
      }}
      selectable
    >
      {value}
    </Text>
  )
}

interface HeaderCellProps {
  value: string
  isLead?: boolean
  cellStyle?: StyleProp<ViewStyle>
}
export function HeaderCell({ value, isLead, cellStyle }: HeaderCellProps) {
  return (
    <Cell isLead={isLead} height={60} style={cellStyle}>
      <HeaderText value={value} />
    </Cell>
  )
}
interface FixedCellProps {
  children?: React.ReactNode
  height: number
  width: number
}
export function FixedCell({ children, height, width }: FixedCellProps) {
  return (
    <View
      style={[
        {
          height,
          width,
          justifyContent: 'center',
        },
      ]}
    >
      {children}
    </View>
  )
}

interface RowProps {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}

export function Row({ children, style }: RowProps) {
  return (
    <View
      style={[
        {
          flex: 1,
          alignSelf: 'stretch',
          flexDirection: 'row',
          marginLeft: 32,
          marginRight: 8,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

interface HeaderProps {
  children?: React.ReactNode
}
export function Header({ children }: HeaderProps) {
  return (
    <View
      style={{
        borderBottomColor: Colors.border,
        borderBottomWidth: 1,
        flex: 1,
        paddingLeft: 32,
        paddingRight: 8,
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {children}
    </View>
  )
}
