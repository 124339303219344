// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/menu_restaurant_group/menu_restaurant_group_service.proto" (package "auto_reserve.restaurants.menu_restaurant_group", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MenuRestaurantGroup } from "./menu_restaurant_group_service";
import type { Empty } from "../../../google/protobuf/empty";
import type { DestroyRequest } from "./menu_restaurant_group_service";
import type { UpdateRequest } from "./menu_restaurant_group_service";
import type { CreateRequest } from "./menu_restaurant_group_service";
import type { MenuRestaurantGroupResource } from "./menu_restaurant_group_resource";
import type { GetRequest } from "./menu_restaurant_group_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./menu_restaurant_group_service";
import type { ListRequest } from "./menu_restaurant_group_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroup
 */
export interface IMenuRestaurantGroupClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.menu_restaurant_group.ListRequest) returns (auto_reserve.restaurants.menu_restaurant_group.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.menu_restaurant_group.GetRequest) returns (auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, MenuRestaurantGroupResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.menu_restaurant_group.CreateRequest) returns (auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, MenuRestaurantGroupResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.menu_restaurant_group.UpdateRequest) returns (auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, MenuRestaurantGroupResource>;
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.restaurants.menu_restaurant_group.DestroyRequest) returns (google.protobuf.Empty);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, Empty>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroup
 */
export class MenuRestaurantGroupClient
  implements IMenuRestaurantGroupClient, ServiceInfo
{
  typeName = MenuRestaurantGroup.typeName;
  methods = MenuRestaurantGroup.methods;
  options = MenuRestaurantGroup.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.menu_restaurant_group.ListRequest) returns (auto_reserve.restaurants.menu_restaurant_group.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.menu_restaurant_group.GetRequest) returns (auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, MenuRestaurantGroupResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, MenuRestaurantGroupResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.menu_restaurant_group.CreateRequest) returns (auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, MenuRestaurantGroupResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, MenuRestaurantGroupResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.menu_restaurant_group.UpdateRequest) returns (auto_reserve.restaurants.menu_restaurant_group.MenuRestaurantGroupResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, MenuRestaurantGroupResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, MenuRestaurantGroupResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.restaurants.menu_restaurant_group.DestroyRequest) returns (google.protobuf.Empty);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
