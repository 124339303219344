import React, { useState } from 'react'

import { View, ScrollView } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import Config, { ConfigObject } from '@hello-ai/for_r_app/src/modules/config'

export default function DevSettings() {
  const { width, sm } = useResponsive()
  const [state, setState] = useState<Required<ConfigObject>>({
    apiUrl: Config.apiUrl,
    printPreviewEnabled: Config.printPreviewEnabled,
  })

  function save() {
    Config.setItem('apiUrl', state.apiUrl)

    alert(t('設定を更新しました。'))
    location.reload()
  }

  const onChangeApiUrl = (apiUrl: string) => {
    setState((state) => ({ ...state, apiUrl }))
  }

  function reset() {
    setState(Config.defaultConfig)
  }

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: width < sm ? 16 : 32,
      }}
    >
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
        }}
      >
        {t('開発設定')}
      </Text>

      <View
        style={[
          {
            marginTop: 24,
          },
        ]}
      >
        <ShadowBox>
          <View>
            <Text style={{ fontWeight: '600' }}>API URL</Text>
            <TextInput
              style={{ marginTop: 8 }}
              selectionColor={Colors.primary}
              placeholder="https://api-table.autoreserve.com"
              autoCorrect={false}
              onChangeText={(text) => onChangeApiUrl(text)}
              value={state.apiUrl}
              multiline={width < sm}
            />
          </View>
          <View
            style={[
              {
                marginTop: 24,
                flex: 1,
              },
              width >= sm && {
                flexDirection: 'row',
              },
            ]}
          >
            {__DEV__ && (
              <Button
                mode="outline"
                onPress={() => onChangeApiUrl('http://api-table.lvh.me:5000')}
                style={{
                  paddingHorizontal: 24,
                }}
              >
                local
              </Button>
            )}
            <Button
              mode="outline"
              onPress={() =>
                onChangeApiUrl('https://api-table.staging.autoreserve.com')
              }
              style={[
                { paddingHorizontal: 24 },
                width < sm
                  ? {
                      marginTop: __DEV__ ? 16 : 0,
                    }
                  : {
                      marginLeft: 12,
                    },
              ]}
            >
              staging
            </Button>

            <Button
              mode="outline"
              onPress={() =>
                onChangeApiUrl('https://api-table.autoreserve.com')
              }
              style={[
                { paddingHorizontal: 24 },
                width < sm
                  ? {
                      marginTop: 16,
                    }
                  : {
                      marginLeft: 12,
                    },
              ]}
            >
              production
            </Button>
          </View>

          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Button
              mode="outline"
              variant="secondary"
              onPress={() => reset()}
              style={{
                flex: 1,
                marginTop: 24,
              }}
            >
              {width < sm ? t('リセット') : t('デフォルト設定にリセット')}
            </Button>

            <Button
              onPress={() => save()}
              style={{
                marginLeft: 24,
                flex: 1,
                marginTop: 20,
                width: 340,
              }}
            >
              {t('更新')}
            </Button>
          </View>
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
