import React, { useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import dayjs from 'dayjs'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import {
  getPaymentStatusLabel,
  Payment,
  PaymentStatusType,
  PaymentStatusTypes,
  useRestaurantPayments,
} from 'models/Payment'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import { getYears, months } from 'components/Sales/SelectPeriodInput'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Trans } from 'react-i18next'

const getPaymentStatusColor = (status?: PaymentStatusType) => {
  switch (status) {
    case PaymentStatusTypes.Paid:
      return Colors.success
    case PaymentStatusTypes.Pending:
    case PaymentStatusTypes.InTransit:
      return Colors.primary
    case PaymentStatusTypes.Canceled:
      return Colors.caution
    case PaymentStatusTypes.Failed:
      return Colors.caution
    default:
      return Colors.black60
  }
}

function PaymentContent({ payment }: { payment: Payment }) {
  const calculateOtherAmount = (payment: Payment | undefined) => {
    if (!payment) return 0

    const {
      deposit_amount,
      total_charge_amount,
      total_refunded_amount,
      application_fee_amount,
      bank_transfer_fee_amount,
      monthly_fee_amount,
      reservation_cancel_fee_amount,
      shop_charge_amount,
      smart_payment_amount,
    } = payment
    return (
      (deposit_amount ?? 0) -
      (total_charge_amount -
        total_refunded_amount -
        application_fee_amount -
        bank_transfer_fee_amount -
        monthly_fee_amount) -
      reservation_cancel_fee_amount -
      shop_charge_amount -
      smart_payment_amount
    )
  }

  const depositAmount = numberWithDelimiter(payment?.deposit_amount ?? 0)

  return (
    <View>
      <View
        style={{
          paddingHorizontal: 32,
        }}
      >
        <Text style={{ fontSize: 14, fontWeight: '600' }}>{t('入金額')}</Text>
        <Text>
          {/* eslint-disable ar-i18n/require-translation-ja */}
          <Trans
            i18nKey={'<0>{{price}}</0><1>円</1>'}
            values={{ price: depositAmount }}
          >
            <Text style={{ fontSize: 28, fontWeight: '600' }}>
              {`${depositAmount}`}
            </Text>
            <Text style={{ fontSize: 14, fontWeight: '600' }}>{'円'}</Text>
          </Trans>
          {/* eslint-enable ar-i18n/require-translation-ja */}
        </Text>
        <Text style={{ fontSize: 14, color: Colors.black60 }}>
          {t('{{start}}から{{end}}までの注文が入金対象になります', {
            start: dayjs(payment?.target_order_dates?.gte).format(
              // eslint-disable-next-line ar-i18n/require-translation-ja
              'YYYY年MM月DD日'
            ),
            end: dayjs(payment?.target_order_dates?.lte).format(
              // eslint-disable-next-line ar-i18n/require-translation-ja
              'YYYY年MM月DD日'
            ),
          })}
        </Text>
      </View>

      <View
        style={{
          marginTop: 32,
          paddingBottom: 32,
          paddingHorizontal: 32,
          borderColor: Colors.border,
          borderBottomWidth: 0.5,
        }}
      >
        <Text style={{ fontSize: 14, fontWeight: '600' }}>{t('入金日')}</Text>
        {payment?.status ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontSize: 24, fontWeight: '600' }}>
              {payment?.arrival_date != null
                ? // eslint-disable-next-line ar-i18n/require-translation-ja
                  dayjs(payment?.arrival_date).format('YYYY年MM月DD日')
                : '-'}
            </Text>
            <View
              style={{
                marginLeft: 16,
                height: 24,
                paddingHorizontal: 8,
                justifyContent: 'center',
                backgroundColor: getPaymentStatusColor(payment?.status),
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  lineHeight: 14,
                  color: 'white',
                }}
              >
                {getPaymentStatusLabel(payment?.status)}
              </Text>
            </View>
          </View>
        ) : (
          <Text style={{ fontSize: 28, fontWeight: '600' }}>{t('未定')}</Text>
        )}
      </View>
      <View
        style={{
          marginTop: 32,
          paddingBottom: 20,
          marginHorizontal: 32,
          borderColor: Colors.border,
          borderBottomWidth: 0.5,
        }}
      >
        <Text style={{ fontSize: 14, fontWeight: '600', marginBottom: 20 }}>
          {t('入金額の詳細')}
        </Text>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: '600' }}>
            {t('売上合計')}
          </Text>
          <Text style={{ fontSize: 18, fontWeight: '600' }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(payment?.total_charge_amount ?? 0),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>
            {t('セルフオーダー売上(オンライン決済)')}
          </Text>
          <Text style={{ fontSize: 18 }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(payment?.table_charge_amount ?? 0),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('オンラインストア売上')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(payment?.shop_charge_amount ?? 0),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('スマート決済')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(payment?.smart_payment_amount ?? 0),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('テイクアウト売上')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(payment?.takeout_charge_amount ?? 0),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('予約キャンセル料')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(
                payment?.reservation_cancel_fee_amount ?? 0
              ),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('返金')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('-{{price}}円', {
              price: numberWithDelimiter(payment?.total_refunded_amount ?? 0),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('決済手数料(税別) ※10%対象')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('-{{price}}円', {
              price: numberWithDelimiter(
                payment?.application_fee_amount_without_tax ?? 0
              ),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('決済手数料消費税')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('-{{price}}円', {
              price: numberWithDelimiter(
                payment?.tax_for_application_fee_amount ?? 0
              ),
            })}
          </Text>
        </View>
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('振込手数料')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('-{{price}}円', {
              price: numberWithDelimiter(
                payment?.bank_transfer_fee_amount ?? 0
              ),
            })}
          </Text>
        </View>
        {!!payment?.monthly_fee_amount && payment?.monthly_fee_amount > 0 && (
          <View
            style={{
              marginVertical: 12,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text style={{ fontSize: 18 }}>{t('Rest 月額費用')}</Text>
            <Text style={{ fontSize: 18 }}>
              {t('-{{price}}円', {
                price: numberWithDelimiter(payment?.monthly_fee_amount ?? 0),
              })}
            </Text>
          </View>
        )}
        <View
          style={{
            marginVertical: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18 }}>{t('その他')}</Text>
          <Text style={{ fontSize: 18 }}>
            {t('{{price}}円', {
              price: numberWithDelimiter(calculateOtherAmount(payment)),
            })}
          </Text>
        </View>
      </View>
      <View
        style={{
          marginTop: 32,
          paddingHorizontal: 32,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 18, fontWeight: '600' }}>{t('入金額')}</Text>
        <Text style={{ fontSize: 18, fontWeight: '600' }}>
          {t('{{price}}円', {
            price: numberWithDelimiter(payment?.deposit_amount ?? 0),
          })}
        </Text>
      </View>
    </View>
  )
}

export default function RestaurantPayments() {
  const today = dayjs().startOf('month')
  const [selectedYear, setSelectedYear] = useState<number>(today.year())
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    undefined
  )

  const selectedValue = useMemo(() => {
    return dayjs(`${selectedYear}-${selectedMonth ?? today.month()}-01`)
  }, [selectedYear, selectedMonth, today])

  const years = useMemo(() => {
    return getYears()
  }, [])

  const monthItems = useMemo(() => {
    const items = [...months] as SelectItem<number | undefined>[]
    items.unshift({
      label: '',
      value: undefined,
    })
    return items
  }, [])

  const { data, isLoading } = useRestaurantPayments(
    selectedValue.format('YYYY-MM-DD')
  )

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: 'rgba(68, 68, 68, 0.06)' }}
      contentContainerStyle={{
        marginHorizontal: 48,
        paddingBottom: 40,
      }}
    >
      <View
        style={{
          paddingTop: 32,
          paddingBottom: 40,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        }}
      >
        <View
          style={{
            width: 120,
            marginRight: 16,
          }}
        >
          <SelectInput
            disabled={isLoading}
            selectedValue={selectedYear}
            setValue={(v) => setSelectedYear(v)}
            items={years}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{
              backgroundColor: 'white',
            }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
        </View>
        <View
          style={{
            width: 100,
          }}
        >
          <SelectInput
            disabled={isLoading}
            selectedValue={selectedMonth}
            setValue={(v) => setSelectedMonth(v)}
            items={monthItems}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{
              backgroundColor: 'white',
            }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
        </View>
      </View>
      <Text style={{ marginTop: 40, fontSize: 22, fontWeight: '600' }}>
        {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
        {selectedValue.format('YYYY年MM月')}
      </Text>
      {isLoading && (
        <ShadowBox
          style={[
            {
              paddingTop: 30,
              paddingBottom: 32,
              paddingHorizontal: 0,
              overflow: 'hidden',
              marginTop: 24,
            },
          ]}
        >
          <Loading />
        </ShadowBox>
      )}
      {!isLoading && data.length === 0 && (
        <ShadowBox
          style={[
            {
              paddingTop: 30,
              paddingBottom: 32,
              paddingHorizontal: 0,
              overflow: 'hidden',
              marginTop: 24,
            },
          ]}
        >
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Text>
              {t('入金額反映まで少々お待ちください（月末頃反映予定）')}
            </Text>
          </View>
        </ShadowBox>
      )}
      {!isLoading &&
        data.length > 0 &&
        data.map((payment, i) => {
          return (
            <ShadowBox
              key={`payment_${i}`}
              style={[
                {
                  paddingTop: 30,
                  paddingBottom: 32,
                  paddingHorizontal: 0,
                  overflow: 'hidden',
                  marginTop: 24,
                },
              ]}
            >
              <PaymentContent payment={payment} />
            </ShadowBox>
          )
        })}
    </ScrollView>
  )
}
