import React, { useMemo, useState } from 'react'
import { View, ScrollView } from 'react-native'
import dayjs from 'dayjs'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { useRestaurantId } from 'modules/useRestaurantId'

import {
  getFormatLabel,
  SelectPeriodInput,
} from 'components/Sales/SelectPeriodInput'
import {
  Row,
  Header,
  HeaderCell,
  TextCell,
  TextCellWithDetail,
} from 'components/Sales'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import {
  getFetchPeriodParams,
  SalesByItem,
  useRestaurantSalesByItem,
} from 'models/Sales'

import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const CSVHeaders = [
  { value: 'name', label: t('商品名') },
  { value: 'category', label: t('カテゴリー') },
  { value: 'unit_price', label: t('商品価格') },
  { value: 'total_quantity', label: t('売上個数') },
  { value: 'total_amount', label: t('総売上') },
  { value: 'total_amount_ratio', label: t('構成比(総売上)') },
]

function convertDataFormat(rows: SalesByItem[]) {
  return rows.map((row) => {
    return {
      name: row?.table_product?.name ?? '',
      category: (row?.table_menu_pages ?? [])
        .map((page: { name: string }) => page.name)
        .join('/'),
      unit_price: numberWithDelimiter(row.unit_price),
      total_quantity: numberWithDelimiter(row.total_quantity),
      total_amount: numberWithDelimiter(row.total_amount),
      total_amount_ratio: (row.total_amount_ratio * 100).toFixed(0) + '%',
    }
  })
}

function SalesByProduct() {
  const restaurantId = useRestaurantId()
  const today = dayjs()

  const [selectedValue, setSelectedValue] = useState({
    start: today,
    end: today,
  })

  const [fetchParams, setFetchParams] = useState(selectedValue)

  const onChangeValues = (values: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => {
    setSelectedValue(values)
  }

  const params = useMemo(() => {
    return {
      completed_at: getFetchPeriodParams(fetchParams),
    }
  }, [fetchParams])

  const { salesByItem } = useRestaurantSalesByItem(restaurantId, params)

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingVertical: 32,
        paddingHorizontal: 48,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            marginRight: 24,
            flex: 1,
          }}
        >
          <SelectPeriodInput
            disabled={salesByItem === undefined}
            style={{
              backgroundColor: 'white',
              borderRadius: 8,
              height: 48,
            }}
            labelStyle={{
              fontSize: 18,
              fontWeight: '300',
            }}
            selectedStartValue={selectedValue.start}
            selectedEndValue={selectedValue.end}
            onChangeValues={onChangeValues}
          />
        </View>
        <Button
          mode="contained"
          variant="secondary"
          disabled={salesByItem === undefined}
          width={160}
          height={48}
          roundness={24}
          onPress={() => {
            setFetchParams(selectedValue)
          }}
        >
          <Text style={{ fontWeight: '600', color: Colors.primary }}>
            {t('表示する')}
          </Text>
        </Button>
      </View>
      <View
        style={{
          marginTop: 32,
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        }}
      />
      <View
        style={{
          marginTop: 38,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
              letterSpacing: -0.5,
              color: Colors.black,
            }}
          >
            {t('全てのメニュー/全てのカテゴリー')}
          </Text>
          <Text
            style={{
              fontSize: 24,
              fontWeight: '600',
              color: Colors.black,
            }}
          >
            {getFormatLabel(fetchParams.start)} ~{' '}
            {getFormatLabel(fetchParams.end)}
          </Text>
        </View>
        <CSVDownloadButton
          data={
            salesByItem !== undefined
              ? convertDataFormat(salesByItem)
              : undefined
          }
          headers={CSVHeaders}
          fileName="sales_by_item"
        />
      </View>
      <ShadowBox
        style={{
          marginTop: 24,
          padding: 0,
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Header>
          <HeaderCell value={t('商品名')} />
          <HeaderCell value={t('カテゴリー')} />
          <HeaderCell value={t('商品価格')} />
          <HeaderCell value={t('売上個数')} />
          <HeaderCell value={t('総売上')} />
        </Header>
        {salesByItem.length === 0 && (
          <View
            style={{
              padding: 24,
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              {t('データがありません')}
            </Text>
          </View>
        )}
        {salesByItem.map((d: SalesByItem, i) => {
          return (
            <Row
              key={d.id}
              style={[
                {
                  paddingVertical: 10,
                },
                i !== salesByItem.length - 1 && {
                  borderBottomWidth: 1,
                  borderBottomColor: Colors.border,
                },
              ]}
            >
              <TextCell
                style={{ paddingRight: 4 }}
                numberOfLines={2}
                value={d?.table_product?.name ?? ''}
              />
              <TextCell
                style={{ paddingRight: 4, fontSize: 18 }}
                numberOfLines={2}
                value={(d?.table_menu_pages ?? [])
                  .map((page: { name: string }) => page.name)
                  .join('/')}
              />
              <TextCell
                value={t('{{price}}円', {
                  price: numberWithDelimiter(d.unit_price),
                })}
              />
              <TextCell
                value={t('{{number}}個', {
                  number: numberWithDelimiter(d.total_quantity),
                })}
              />
              <TextCellWithDetail
                value={t('{{price}}円', {
                  price: numberWithDelimiter(d.total_amount),
                })}
                detailValue={(d.total_amount_ratio * 100).toFixed(0) + '%'}
              />
            </Row>
          )
        })}
      </ShadowBox>
    </ScrollView>
  )
}

export default SalesByProduct
