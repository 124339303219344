import { loadStripe, Stripe } from '@stripe/stripe-js'
import { STRIPE_PUBLISHABLE_API_KEY } from '../../Constants'

let stripePromise: Promise<void | Stripe | null> | null = null

export function getStripe() {
  if (stripePromise) {
    return stripePromise
  }
  stripePromise = loadStripe(STRIPE_PUBLISHABLE_API_KEY).catch((err) => {
    console.warn(err)
  })

  return stripePromise
}
