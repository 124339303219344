import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import React, { useRef, useState } from 'react'
import { TextStyle, StyleProp, ViewStyle, View } from 'react-native'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import range from 'lodash/range'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers'
import { TextField, TextFieldProps } from '@mui/material'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

type MinuteInterval = 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30

type Mode = 'date' | 'time' | 'datetime'

function getFormatDate(date: dayjs.Dayjs) {
  const dow = parseInt(date.format('d'))
  const dowLabel = dayjs.weekdaysShort()[dow]
  // eslint-disable-next-line ar-i18n/require-translation-ja
  return date.format('M月D日') + `(${dowLabel})`
}
export function getFormatLabel(mode: Mode, date: dayjs.Dayjs): string {
  switch (mode) {
    case 'date':
      return getFormatDate(date)
    case 'time':
      return date.format('HH:mm')
    case 'datetime':
      return `${getFormatDate(date)}`
  }
}

export default function SelectDateTimeInput({
  mode,
  minuteInterval,
  disabled,
  dateTime,
  onChangeDateTime,
  labelStyle,
  style,
  customFormatLabel,
  showIndicatorIcon,
  maxDate,
  minDate,
  defaultCalendarMonth,
}: {
  mode: Mode
  minuteInterval?: MinuteInterval
  disabled?: boolean
  dateTime: dayjs.Dayjs | null
  onChangeDateTime: (dateTime: dayjs.Dayjs | null) => void
  labelStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  /** only for native */
  customFormatLabel?: typeof getFormatLabel
  showIndicatorIcon?: boolean
  maxDate?: dayjs.Dayjs
  minDate?: dayjs.Dayjs
  defaultCalendarMonth?: dayjs.Dayjs
}) {
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)

  const timeItems = [
    ...range(0, 24 * 60, minuteInterval).map((minute) => ({
      label:
        Math.floor(minute / 60)
          .toString()
          .padStart(2, '0') +
        ':' +
        (minute % 60).toString().padStart(2, '0'),
      value: minute,
    })),
  ]

  function Input(props: TextFieldProps) {
    return (
      <TextField
        onClick={() => setOpen(true)}
        {...props}
        variant="standard"
        style={{
          width: '100%',
          border: 'none',
          outline: 'none',
          height: '100%',
        }}
        InputProps={{
          style: {
            border: 'none',
            outline: 'none',
            height: '100%',
            padding: '0 16px',
            fontSize: 18,
          },
          ref: inputRef,
          disableUnderline: true,
          ...props.InputProps,
        }}
      />
    )
  }

  const pickerProps = {
    open,
    toolbarTitle: '',
    toolbarFormat:
      dateTime == null
        ? ''
        : customFormatLabel
          ? customFormatLabel(mode, dateTime)
          : getFormatLabel(mode, dateTime),
    onOpen: () => setOpen(true),
    onClose: () => {
      setOpen(false)
      if (inputRef.current?.children[0] instanceof HTMLInputElement) {
        inputRef.current.children[0].blur()
      }
    },
    disabled,
    inputFormat:
      dateTime == null
        ? ''
        : customFormatLabel
          ? customFormatLabel(mode, dateTime)
          : getFormatLabel(mode, dateTime),
    value: dateTime,
    onChange: (
      value: dayjs.Dayjs | null,
      _keyboardInputValue?: string | undefined,
    ) => {
      onChangeDateTime(value)
    },
    renderInput: (props: TextFieldProps) => {
      return <Input placeholder={t('未選択')} {...props} />
    },
    maxDate,
    minDate,
    defaultCalendarMonth,
  }

  return (
    <View
      style={[
        {
          backgroundColor: Colors.field,
          borderRadius: 8,
          height: 48,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          opacity: disabled ? 0.3 : 1,
        },
        style,
      ]}
    >
      {mode === 'time' && (
        <SelectInput
          disabled={disabled}
          labelStyle={labelStyle}
          containerStyle={{ padding: 0 }}
          indicatorIcon={null}
          items={timeItems}
          selectedValue={
            dateTime == null ? null : dateTime.hour() * 60 + dateTime.minute()
          }
          setValue={(value) => {
            const hour = Math.floor(value / 60)
            const minute = value % 60
            const date = dateTime ?? dayjs()
            onChangeDateTime(date.hour(hour).minute(minute))
          }}
        />
      )}
      {mode === 'date' && <MobileDatePicker {...pickerProps} />}
      {mode === 'datetime' && <MobileDateTimePicker {...pickerProps} />}
      {showIndicatorIcon ? (
        <View>
          <FontAwesomeIcon icon={faCaretUp} size={10} color={Colors.black} />
          <FontAwesomeIcon icon={faCaretDown} size={10} color={Colors.black} />
        </View>
      ) : null}
    </View>
  )
}
