import React from 'react'
import { View } from 'react-native'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {
  Header,
  HeaderCell,
  Row,
  Cell,
} from '@hello-ai/for_r_app/src/components/Restaurant/TableBalancingCashDrawer/HistoryTable'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { TableBalancingCashDrawer } from 'models/TableBalancingCashDrawer'
import { useNavigate } from 'react-router'

export function HistoryTable({
  balancingCashDrawers,
  date,
  restaurantId,
}: {
  balancingCashDrawers: TableBalancingCashDrawer[]
  date: dayjs.Dayjs
  restaurantId: number
}) {
  const { width, sm } = useResponsive()
  const navigate = useNavigate()
  const balancingTypeText = {
    open: t('点検'),
    check: t('点検'),
    close: t('締め処理'),
    open_and_close: t('締め処理'),
  }
  const createdAtCellFlex = width < sm ? 1 : 2.5
  const balancingTypeCellFlex = width < sm ? 1 : 1.5
  const businessUserNameCellFlex = width < sm ? 1 : 2.5
  const drawerSnapshotCellFlex = width < sm ? 1.2 : 2
  const cashOverShortCellFlex = width < sm ? 1.2 : 1.3
  const faChevronRightCellFlex = width < sm ? 0.1 : 0.2
  const cellFontSize = width < sm ? 12 : 14

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'white',
        }}
      >
        <Text
          style={{
            fontWeight: '600',
          }}
        >
          {t('点検・締め処理履歴')}
        </Text>
      </View>
      <Header>
        <HeaderCell value={t('日時')} flex={createdAtCellFlex} />
        <HeaderCell value={t('種別')} flex={balancingTypeCellFlex} />
        <HeaderCell value={t('担当者')} flex={businessUserNameCellFlex} />
        <HeaderCell value={t('レジ内現金')} flex={drawerSnapshotCellFlex} />
        <HeaderCell value={t('過不足')} flex={cashOverShortCellFlex} />
        <HeaderCell value="" flex={faChevronRightCellFlex} />
      </Header>
      {balancingCashDrawers.map((balancingCashDrawer) => (
        <View
          key={balancingCashDrawer.id}
          style={{
            borderStyle: 'solid',
            borderBottomWidth: 0.5,
            borderBottomColor: 'rgba(68, 68, 68, 0.16)',
          }}
        >
          <TouchableOpacity
            onPress={() =>
              navigate(
                `/restaurants/${restaurantId}/reports/${date.format(
                  'YYYY-MM-DD'
                )}/balancing_cash_drawers/${balancingCashDrawer.id}`
              )
            }
          >
            <Row>
              <Cell
                typeValue={{
                  value: balancingCashDrawer.created_at,
                  type: 'time',
                }}
                flex={createdAtCellFlex}
                fontSize={cellFontSize}
              />
              <Cell
                typeValue={{
                  value: balancingTypeText[balancingCashDrawer.balancing_type],
                  type: 'text',
                }}
                flex={balancingTypeCellFlex}
                fontSize={cellFontSize}
              />
              <Cell
                typeValue={{
                  value: balancingCashDrawer.restaurant_crew_member?.name ?? '',
                  type: 'text',
                }}
                flex={businessUserNameCellFlex}
                fontSize={cellFontSize}
              />
              <Cell
                typeValue={{
                  value: balancingCashDrawer.cash_amount_in_drawer_snapshot,
                  type: 'amount',
                }}
                flex={drawerSnapshotCellFlex}
                fontSize={cellFontSize}
              />
              <Cell
                typeValue={{
                  value: balancingCashDrawer.cash_over_and_short_amount,
                  type: 'amount',
                }}
                flex={cashOverShortCellFlex}
                fontSize={cellFontSize}
              />
              <View style={{ flex: faChevronRightCellFlex }}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size={12}
                  color={Colors.black}
                />
              </View>
            </Row>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  )
}
