import { Step } from '../../../components/Stepper'
import { t } from '../../../modules/i18n'

export const GOURMET_SITE_ACCOUNT_SETTINGS_STEPPERS: Step[] = [
  {
    stepNumber: 1,
    text: t('ログイン情報の入力'),
  },
  {
    stepNumber: 2,
    text: t('メールアドレスの登録'),
  },
  {
    stepNumber: 3,
    text: t('メールアドレスの認証'),
  },
  {
    stepNumber: 4,
    text: t('設定の確認'),
  },
]

export const IKKYU_SITE_ACCOUNT_SETTINGS_STEPPERS: Step[] = [
  {
    stepNumber: 1,
    text: t('ログイン情報の入力'),
  },
  {
    stepNumber: 2,
    text: t('メールアドレスの登録'),
  },
  {
    stepNumber: 3,
    text: t('設定の確認'),
  },
]
