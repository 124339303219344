import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { toDoubleDigits } from 'modules/number'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export function splitHourAndMinute(num: number) {
  return {
    hour: Math.floor(num / 3600),
    minute: Math.floor((num % 3600) / 60),
  }
}

export function getFormatTime(num: number) {
  const { hour, minute } = splitHourAndMinute(num)
  return `${toDoubleDigits(hour)}:${toDoubleDigits(minute)}`
}

export function toSeconds(hours: number, mins: number) {
  return hours * 3600 + mins * 60
}

export function wdayName(wday: number) {
  if (wday === 7) {
    return t('祝日')
  }

  return dayjs.weekdays()[wday]
}

export function showDayUnlessToday(time: number) {
  return dayjs(time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
    ? dayjs(time).format('HH:mm')
    : dayjs(time).format('M/D HH:mm')
}
