import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import RestaurantCourses from 'pages/Restaurants/RestaurantCourses'
import RestaurantCourseForm from 'pages/Restaurants/RestaurantCourses/Form'
import RestaurantCourseEditOrder from 'pages/Restaurants/RestaurantCourses/EditOrder'

import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('コース'),
    fullPath: `/restaurants/:id/courses`,
    relativePath: `/`,
    exact: true,
    element: <RestaurantCourses />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Show: {
    title: t('コースを作成'),
    fullPath: `/restaurants/:id/courses/new`,
    relativePath: `/new`,
    element: <RestaurantCourseForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('コースを編集'),
    fullPath: `/restaurants/:id/courses/:restaurantCourseId`,
    relativePath: `/:restaurantCourseId`,
    element: <RestaurantCourseForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  EditOrder: {
    title: t('コースの並べ替え'),
    fullPath: `/restaurants/:id/courses/edit_order`,
    relativePath: `/edit_order`,
    element: <RestaurantCourseEditOrder />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function RestaurantCoursesStack() {
  return <Stack routes={routes} />
}
