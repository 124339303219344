import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons'
import { View } from 'react-native'
import React, { useMemo, useState } from 'react'
import SearchRestaurant from './SearchRestaurant'
import { ScrollView } from 'react-native-gesture-handler'
import { StepperList } from 'components/SignUp/Stepper'
import CallPhone from './CallPhone'
import {
  requestVerificationCode,
  verifyCodeForRestaurantOwnership,
} from 'models/RestaurantOwner'
import { useToken } from 'models/Auth'
import VerifyCode from './VerifyCode'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface SearchRestaurantStep {
  type: 'search_restaurant'
  restaurant?: CandidatesRestaurant
}
interface CallPhoneStep {
  type: 'call_phone'
  restaurant: CandidatesRestaurant
}
interface VerifyCodeStep {
  type: 'verify_code'
  restaurant: CandidatesRestaurant
  verification_request_id: string
}
type Step = SearchRestaurantStep | CallPhoneStep | VerifyCodeStep

interface ByPhoneCallProps {
  onPressBack: () => void
  onSubmitEnd: () => void
}

export default function ByPhoneCall({
  onPressBack,
  onSubmitEnd,
}: ByPhoneCallProps) {
  const [step, setStep] = useState<Step>({ type: 'search_restaurant' })
  const token = useToken()

  const component = useMemo(() => {
    switch (step.type) {
      case 'search_restaurant':
        return (
          <SearchRestaurant
            defaultRestaurant={step.restaurant}
            onSelectRestaurant={(restaurant) => {
              setStep({
                type: 'call_phone',
                restaurant,
              })
            }}
          />
        )
      case 'call_phone':
        return (
          <CallPhone
            restaurant={step.restaurant}
            onPressReSelectRestaurant={() => {
              setStep({
                type: 'search_restaurant',
                restaurant: step.restaurant,
              })
            }}
            onPressVerifyCode={async (captchaToken) => {
              if (token == null) return
              const { verification_request_id } = await requestVerificationCode(
                token,
                {
                  verification_request: {
                    restaurant_id: step.restaurant.id,
                  },
                  'g-recaptcha-response': captchaToken,
                }
              )
              if (verification_request_id == null) return
              setStep({
                type: 'verify_code',
                restaurant: step.restaurant,
                verification_request_id,
              })
            }}
          />
        )
      case 'verify_code':
        return (
          <VerifyCode
            restaurant={step.restaurant}
            onPressResendCode={() => {
              setStep({
                type: 'call_phone',
                restaurant: step.restaurant,
              })
            }}
            onPressVerifyCode={async (code) => {
              if (token == null) return
              const { error } = await verifyCodeForRestaurantOwnership(
                token,
                step.verification_request_id,
                {
                  verification_request: {
                    verification_code: code,
                  },
                }
              )
              if (error != null) return
              displayToastInfo(t('店舗アカウントを追加しました'))
              onSubmitEnd()
            }}
          />
        )
    }
  }, [step, token, onSubmitEnd])

  const currentStepNumber = useMemo(() => {
    switch (step.type) {
      case 'search_restaurant':
        return 1
      case 'call_phone':
        return 2
      case 'verify_code':
        return 3
      default:
        return 1
    }
  }, [step])

  return (
    <>
      <ScrollView
        style={{ flex: 1, paddingTop: 48, backgroundColor: Colors.black6 }}
        contentContainerStyle={{
          width: 872,
          borderRadius: 8,
          backgroundColor: Colors.white,
          paddingVertical: 40,
          paddingHorizontal: 24,
          marginHorizontal: 'auto',
          alignItems: 'center',
        }}
      >
        {component}
      </ScrollView>
      <View style={{ position: 'relative', padding: 16 }}>
        <Button
          style={{
            position: 'absolute',
            left: 16,
            top: 0,
            bottom: 0,
            marginVertical: 'auto',
            borderRadius: 24,
            paddingVertical: 12,
            paddingHorizontal: 12,
            zIndex: 10,
          }}
          onPress={onPressBack}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            color={Colors.white}
            style={{ width: 12, height: 20 }}
          />
        </Button>
        <StepperList
          style={{
            borderTopWidth: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          currentStepNumber={currentStepNumber}
          steps={[
            {
              stepNumber: 1,
              text: t('電話番号の検索'),
            },
            {
              stepNumber: 2,
              text: t('音声通話の発信'),
            },
            {
              stepNumber: 3,
              text: t('認証コードの入力'),
            },
          ]}
        />
      </View>
    </>
  )
}
