import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  SupportedLocale,
  supportedLocales,
} from '@hello-ai/ar_shared/src//modules/locale'
import { AlertMethods } from '@hello-ai/ar_shared/src/components/Alert'
import { ReplaceAll } from '@hello-ai/ar_shared/src/types/utils'
import { LocaleValue } from './types'
import { Simplify } from 'type-fest'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useMemo } from 'react'

export function getSortedLocales(
  preferredLocale: SupportedLocale
): SupportedLocale[] {
  const locales = supportedLocales.filter(
    (locale) => locale !== preferredLocale
  )

  return [preferredLocale, ...locales]
}

export function hasIncompleteChanges(
  values: Record<SupportedLocale, string>,
  defaultValues: Record<SupportedLocale, string>
) {
  // 一つ以上の言語で変更があり、かつ全ての言語に対して変更がされていない場合、
  // 翻訳ミスの可能性があるので、保存時に確認する
  const numberOfChanges = Object.entries(values).filter(
    ([locale, value]) => value !== defaultValues[locale as SupportedLocale]
  ).length

  const hasIncompleteChanges =
    numberOfChanges > 0 && numberOfChanges < supportedLocales.length

  return hasIncompleteChanges
}

export function confirmSaveIncompleteChangesAsync(
  alert: AlertMethods['alert']
) {
  return new Promise<boolean>((resolve) => {
    alert(
      t('変更されていない言語があります。この内容で編集内容を保存しますか？'),
      undefined,
      [
        {
          text: t('キャンセル'),
          onPress: () => {
            resolve(false)
          },
          style: 'cancel',
        },
        {
          text: t('保存する'),
          onPress: () => {
            resolve(true)
          },
          isPreferred: true,
        },
      ]
    )
  })
}

export function getLocaleValues<
  T extends string,
  U extends {
    [key in `${T}${Lowercase<ReplaceAll<SupportedLocale, '-', '_'>>}`]?:
      | string
      | null
      | undefined
  },
>(prefix: T, value: U | undefined) {
  return Object.fromEntries(
    supportedLocales.map((locale) => {
      const key = `${prefix}${locale.replaceAll('-', '_').toLowerCase()}`
      return [locale, value?.[key as keyof U] ?? '']
    })
  ) as LocaleValue
}

/**
 * given an object with locale keys, returns normalized state and methods
 * e.g. useLocaleFormState('name_i18n_', restaurant)
 * returns {
 *  defaultValues: { en: 'English', 'zh-CN': '中文' }, // default values from restaurant
 *  state: { en: 'English', 'zh-CN': '中文' }, // current state, can be modified by setState
 *  setState: (state) => void,
 *  reset: () => void, // reset to default values
 * }
 */
export function useLocaleFormState<
  T extends string,
  U extends {
    [key in `${T}${Lowercase<ReplaceAll<SupportedLocale, '-', '_'>>}`]?:
      | string
      | null
      | undefined
  },
>(prefix: T, value: U | undefined) {
  const defaultValues = useMemo(() => {
    return getLocaleValues(prefix, value)
  }, [prefix, value])

  const [state, setState, reset] = useFormState<LocaleValue>(defaultValues)

  return {
    state,
    setState,
    reset,
    defaultValues,
  }
}

/**
 * Convert locale value state to locale params
 * e.g. given prefix 'content_' and state { en: 'English', 'zh-CN': '中文' },
 * returns { content_en: 'English', content_zh_cn: '中文' }
 */
export function toLocaleParams<T extends string>(
  prefix: T,
  state: LocaleValue
) {
  return Object.fromEntries(
    Object.entries(state).map(([locale, value]) => [
      `${prefix}${locale.toLowerCase().replace(/-/g, '_')}`,
      value,
    ])
  ) as Simplify<
    Record<`${T}${Lowercase<ReplaceAll<SupportedLocale, '-', '_'>>}`, string>
  >
}
