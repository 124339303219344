import React from 'react'

import { Text } from '../../Text'
import { t } from '../../../modules/i18n'
import { Colors } from '../../../constants/Colors'
import { StyleProp, TextStyle } from 'react-native'

interface RequiredLabelProps {
  textStyle?: StyleProp<TextStyle>
}

function RequiredLabel({ textStyle }: RequiredLabelProps) {
  return (
    <Text
      style={[
        {
          borderRadius: 2,
          padding: 2,
          fontWeight: '600',
          color: 'white',
          fontSize: 14,
          backgroundColor: Colors.caution,
        },
        textStyle,
      ]}
    >
      {t('必須')}
    </Text>
  )
}

export default RequiredLabel
