import React, { useState } from 'react'
import { View } from 'react-native'

import { Colors } from '../../../../constants/Colors'
import { t } from '../../../../modules/i18n'
import OutboundIndexTitle from './OutboundIndexTitle'
import { Text } from '../../../Text'
import { Checkbox, CheckboxLabel } from '../../../Checkbox'
import { Button } from '../../../Button'

interface AgreementFormProps {
  onPressSubmit: () => void
}

export default function AgreementForm({ onPressSubmit }: AgreementFormProps) {
  const [isChecked, setIsChecked] = useState(false)
  return (
    <View>
      <OutboundIndexTitle />
      <View
        style={{
          backgroundColor: Colors.white,
          paddingVertical: 48,
          paddingHorizontal: 32,
          borderRadius: 8,
          marginBottom: 48,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: '300',
            lineHeight: 27,
            marginBottom: 24,
          }}
        >
          {t(
            'アカウントの連携が完了していないため、連携の初期設定を行います。注意事項をご確認のうえ、設定を行ってください'
          )}
        </Text>
        <Text
          style={{ fontSize: 12, padding: 24, backgroundColor: Colors.bgBlack }}
        >
          {t(
            '・本機能は、他社グルメサイトの仕様変更などの理由により正常に動作しない場合がございます。\n\n・本機能が正常に動作しない場合は、各他社グルメサイトの管理画面をご覧いただき、お客様ご自身で予約登録をしていただくようお願いいたします。\n\n・本機能を利用するためには、各他社グルメサイトの管理画面での設定作業が必要になります。設定が完了していない場合、予約の取り込みを開始できません。'
          )}
        </Text>
        <Checkbox
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          style={{ marginVertical: 24, justifyContent: 'center' }}
          checkboxLabel={
            <CheckboxLabel value={t('上記の内容を確認しました')} />
          }
        />
        <Button
          disabled={!isChecked}
          onPress={onPressSubmit}
          style={{ width: 280, height: 48, alignSelf: 'center' }}
        >
          {t('設定を開始する')}
        </Button>
      </View>
    </View>
  )
}
