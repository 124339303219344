import React from 'react'
import {
  cancelRestaurantReservation,
  useRestaurantReservation,
} from '../../../models/RestaurantReservation'
import Loading from '../../Shared/Loading'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { getFormatTime, toSeconds } from '../../../modules/time'
import { ScrollView, View, Platform } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import Row from '../Row'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { displayToastSuccess } from '../../Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { ReservationRepContainer, ReservationRepWithDate } from '../RepView'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useNavigate } from '../../../modules/navigation/useNavigate'

const ROW_MARGIN = 16
const BUTTON_HEIGHT = 48

export function ReservationWalkinShow({
  restaurantId,
  restaurantReservationId,
}: {
  restaurantId: number
  restaurantReservationId: string
}) {
  const { width, sm } = useResponsive()
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  if (restaurantReservation === undefined) {
    return <Loading />
  }

  const {
    adult_party_size: adultPartySize,
    child_party_size: childPartySize,
    start_at: startAtStr,
    end_at: endAtStr,
    table_seats: tableSeats,
  } = restaurantReservation
  const startAt = dayjs(startAtStr)
  const endAt = dayjs(endAtStr)
  const startTime = toSeconds(startAt.hour(), startAt.minute())
  const endTime = toSeconds(endAt.hour(), endAt.minute())

  const onPressCancel = async () => {
    const { error } = await cancelRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      { charge: false }
    )
    if (error != null) return
    displayToastSuccess(
      t('ウォークインを削除しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?m=chart&date=${dayjs(
          restaurantReservation.start_at
        ).format('YYYY-MM-DD')}`
      )
    } else {
      navigation.goBack()
    }
  }

  const onPressEdit = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/walkins/${restaurantReservationId}/edit`
      )
    } else {
      navigation.navigate('ReservationWalkinsForm', {
        restaurantReservationId,
      })
    }
  }

  const onPressEditSeat = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${restaurantReservationId}/change_seat`
      )
    } else {
      navigation.navigate('ChangeSeatForm', {
        restaurantReservationId,
      })
    }
  }

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        paddingHorizontal: 40,
        paddingVertical: 48,
      }}
    >
      <ReservationRepContainer
        style={{
          marginBottom: 8,
        }}
      >
        <ReservationRepWithDate
          label={t('作成日')}
          date={restaurantReservation.created_at}
        />
        {restaurantReservation.changed_at != null && (
          <ReservationRepWithDate
            label={t('変更日')}
            date={restaurantReservation.changed_at}
          />
        )}
      </ReservationRepContainer>
      <ShadowBox>
        <Row
          icon={faCalendarAlt}
          style={{
            marginTop: 24,
          }}
        >
          {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
          <Text>{startAt.format('YYYY年M月DD日(ddd)')}</Text>
        </Row>
        <Row
          icon={faClock}
          style={{
            marginTop: ROW_MARGIN,
          }}
        >
          <Text>
            {getFormatTime(startTime)}~{getFormatTime(endTime)}
          </Text>
        </Row>
        <Row
          icon={faUserFriends}
          style={{
            marginTop: ROW_MARGIN,
          }}
        >
          <Text>
            {t('{{party_size}} ({{adult_party_size}}、{{child_party_size}})', {
              party_size: t('{{count}}名様', {
                count: adultPartySize + childPartySize,
              }),
              adult_party_size: t('大人{{count}}', { count: adultPartySize }),
              child_party_size: t('お子様{{count}}', { count: childPartySize }),
            })}
          </Text>
        </Row>
        <Row
          icon={faChair}
          style={{
            marginTop: ROW_MARGIN,
          }}
        >
          <Text>
            {(tableSeats.length > 0 &&
              tableSeats.map((tableSeat) => tableSeat.name).join('、')) ||
              t('なし')}
          </Text>
        </Row>
      </ShadowBox>
      <View
        style={{
          marginTop: 32,
          flexDirection: width < sm ? 'column-reverse' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: width < sm ? 10 : undefined,
        }}
      >
        <Button
          onPress={onPressCancel}
          mode="outline"
          variant="danger-secondary"
          height={BUTTON_HEIGHT}
          style={[width < sm && { width: '100%' }, { flex: 1 }]}
        >
          {t('削除する')}
        </Button>
        <Button
          onPress={onPressEditSeat}
          mode="outline"
          variant="primary-secondary"
          height={BUTTON_HEIGHT}
          style={[
            width < sm
              ? { width: '100%' }
              : {
                  marginHorizontal: 16,
                },
            { flex: 1 },
          ]}
        >
          {t('席を変更する')}
        </Button>
        <Button
          onPress={onPressEdit}
          variant="primary"
          height={BUTTON_HEIGHT}
          style={[width < sm && { width: '100%' }, { flex: 1 }]}
        >
          {t('編集する')}
        </Button>
      </View>
    </ScrollView>
  )
}
