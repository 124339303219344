// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/owner/owner_service.proto" (package "auto_reserve.restaurants.owner", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Owner } from "./owner_service";
import type { UpdateOwnerRequest } from "./owner_service";
import type { ChangeUsernameRequest } from "./owner_service";
import type { SignUpResponse } from "./owner_service";
import type { SignUpRequest } from "./owner_service";
import type { GetOwnerByUsernameRequest } from "./owner_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { OwnerResource } from "./owner_resource";
import type { GetOwnerRequest } from "./owner_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.owner.Owner
 */
export interface IOwnerClient {
  /**
   * not require authentication
   *
   * @generated from protobuf rpc: GetOwner(auto_reserve.restaurants.owner.GetOwnerRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  getOwner(
    input: GetOwnerRequest,
    options?: RpcOptions,
  ): UnaryCall<GetOwnerRequest, OwnerResource>;
  /**
   * @generated from protobuf rpc: GetOwnerByUsername(auto_reserve.restaurants.owner.GetOwnerByUsernameRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  getOwnerByUsername(
    input: GetOwnerByUsernameRequest,
    options?: RpcOptions,
  ): UnaryCall<GetOwnerByUsernameRequest, OwnerResource>;
  /**
   * @generated from protobuf rpc: SignUp(auto_reserve.restaurants.owner.SignUpRequest) returns (auto_reserve.restaurants.owner.SignUpResponse);
   */
  signUp(
    input: SignUpRequest,
    options?: RpcOptions,
  ): UnaryCall<SignUpRequest, SignUpResponse>;
  /**
   * @generated from protobuf rpc: ChangeUsername(auto_reserve.restaurants.owner.ChangeUsernameRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  changeUsername(
    input: ChangeUsernameRequest,
    options?: RpcOptions,
  ): UnaryCall<ChangeUsernameRequest, OwnerResource>;
  /**
   * @generated from protobuf rpc: UpdateOwner(auto_reserve.restaurants.owner.UpdateOwnerRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  updateOwner(
    input: UpdateOwnerRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateOwnerRequest, OwnerResource>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.owner.Owner
 */
export class OwnerClient implements IOwnerClient, ServiceInfo {
  typeName = Owner.typeName;
  methods = Owner.methods;
  options = Owner.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * not require authentication
   *
   * @generated from protobuf rpc: GetOwner(auto_reserve.restaurants.owner.GetOwnerRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  getOwner(
    input: GetOwnerRequest,
    options?: RpcOptions,
  ): UnaryCall<GetOwnerRequest, OwnerResource> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetOwnerRequest, OwnerResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetOwnerByUsername(auto_reserve.restaurants.owner.GetOwnerByUsernameRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  getOwnerByUsername(
    input: GetOwnerByUsernameRequest,
    options?: RpcOptions,
  ): UnaryCall<GetOwnerByUsernameRequest, OwnerResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetOwnerByUsernameRequest, OwnerResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SignUp(auto_reserve.restaurants.owner.SignUpRequest) returns (auto_reserve.restaurants.owner.SignUpResponse);
   */
  signUp(
    input: SignUpRequest,
    options?: RpcOptions,
  ): UnaryCall<SignUpRequest, SignUpResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SignUpRequest, SignUpResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ChangeUsername(auto_reserve.restaurants.owner.ChangeUsernameRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  changeUsername(
    input: ChangeUsernameRequest,
    options?: RpcOptions,
  ): UnaryCall<ChangeUsernameRequest, OwnerResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ChangeUsernameRequest, OwnerResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateOwner(auto_reserve.restaurants.owner.UpdateOwnerRequest) returns (auto_reserve.restaurants.owner.OwnerResource);
   */
  updateOwner(
    input: UpdateOwnerRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateOwnerRequest, OwnerResource> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateOwnerRequest, OwnerResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
