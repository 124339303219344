import React from 'react'
import { View } from 'react-native'
import UnContractedView from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/UnContractedView'
import {
  useExecuteHistories,
  useSupportedOutboundSettings,
} from '@hello-ai/for_r_app/src/models/SiteControllerOutboundSettings'
import PromptOutboundView from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/PromptOutboundView'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { ScrollView } from 'react-native-gesture-handler'
import ExecuteHistoryListView from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/ExecuteHistoryListView'
import CourseSettingSync from '@hello-ai/ar_shared/src/components/ForR/SiteController/CourseSetting/CourseSettingSync'
import { updateSiteControllerParsedCourses } from '../../models/SiteControllerCourseSetting'
import ParsedCourseListView, {
  ParsedCourseListViewRef,
} from './ParsedCourseListView'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { LatestScrapingStatusChecks } from '@hello-ai/ar_shared/src/types/ForR/SiteController'
import { fetcher } from '../../modules/swr'
import { useImportMailSettings } from '../../models/ImportMailSettings'
import { useAuthMe } from '../../models/Auth'

const containerStyle = {
  marginTop: 40,
  marginHorizontal: 56,
  paddingBottom: 80,
}

interface OutboundCourseViewProps {
  restaurantId: number
  displayToastInfo: (message: string) => void
  displayToastSuccess: (message: string) => void
}

function OutboundCourseView({
  restaurantId,
  displayToastInfo,
  displayToastSuccess,
}: OutboundCourseViewProps) {
  const token = useToken()

  const executionHistory = useExecuteHistories(restaurantId, {
    event_type_and_actions: [
      {
        event_type: 'parsed_course',
        actions: ['parsed_course_scraping'],
      },
    ],
  })
  const parsedCourseListViewRef = React.useRef<ParsedCourseListViewRef>(null)

  const { actor } = useAuthMe()

  return (
    <ScrollView contentContainerStyle={[containerStyle, { rowGap: 48 }]}>
      <CourseSettingSync
        onPressSync={async () => {
          if (token == null) return
          if (actor.businessUser == null) return
          displayToastInfo(
            t(
              '取得には数十秒ほどかかります。このままお待ちいただくか、後ほどご確認ください。'
            )
          )
          const { error } = await updateSiteControllerParsedCourses(
            token,
            restaurantId,
            actor.businessUser.id
          )
          if (error != null) return
          await new Promise((resolve) => {
            const interval = setInterval(async () => {
              const response = await fetcher<LatestScrapingStatusChecks>([
                token,
                `/restaurants/${restaurantId}/site_controller/latest_scraping_status_checks`,
                {
                  event_type: 'parsed_course',
                },
              ])
              if (response.result) {
                clearInterval(interval)
                resolve(true)
              }
            }, 3000)
          })
          await Promise.all([executionHistory.mutate()])
          parsedCourseListViewRef.current?.refresh()
          displayToastSuccess(t('取得が完了しました'))
        }}
        latestExecutionHistory={executionHistory.data?.[0]}
      />
      <ParsedCourseListView
        ref={parsedCourseListViewRef}
        restaurantId={restaurantId}
      />
      <ExecuteHistoryListView
        histories={executionHistory.data}
        paginationProps={{
          currentPage: executionHistory.pagination.currentPage ?? 1,
          totalPage: executionHistory.pagination.totalPages ?? 1,
          setPage: executionHistory.pagination.setPage,
        }}
      />
    </ScrollView>
  )
}

interface ContractedViewProps extends OutboundCourseViewProps {
  onPressPromptOutboundSetting: () => void
}

function ContractedView({
  restaurantId,
  onPressPromptOutboundSetting,
  ...rest
}: ContractedViewProps) {
  const { isExistOutboundSettings } = useSupportedOutboundSettings(restaurantId)

  if (isExistOutboundSettings == null) return null

  if (!isExistOutboundSettings)
    return (
      <View style={containerStyle}>
        <PromptOutboundView
          onPressOutboundSetting={onPressPromptOutboundSetting}
        />
      </View>
    )

  return <OutboundCourseView restaurantId={restaurantId} {...rest} />
}

interface GourmetSiteCourseSettingViewProps extends ContractedViewProps {}

export default function GourmetSiteCourseSettingView({
  restaurantId,
  ...rest
}: GourmetSiteCourseSettingViewProps) {
  const { importMailSettingsData } = useImportMailSettings(restaurantId)

  if (importMailSettingsData == null) return null

  return (
    <>
      {importMailSettingsData.site_controller_enabled ? (
        <ContractedView restaurantId={restaurantId} {...rest} />
      ) : (
        <View style={containerStyle}>
          <UnContractedView />
        </View>
      )}
    </>
  )
}
