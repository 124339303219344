import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import RestaurantInformation from 'pages/Restaurants/Information'
import RestaurantInformationForm from 'pages/Restaurants/Information/Form'
import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('情報'),
    fullPath: `/restaurants/:id/information`,
    relativePath: `/`,
    exact: true,
    element: <RestaurantInformation />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Edit: {
    title: t('情報を編集'),
    fullPath: `/restaurants/:id/information/edit`,
    relativePath: `/edit`,
    exact: true,
    element: <RestaurantInformationForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function InformationStack() {
  return <Stack routes={routes} />
}
