// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat/table_seat_resource.proto" (package "auto_reserve.restaurants.table_seat", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Int32Value } from "../../../google/protobuf/wrappers";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.TableSeatResource
 */
export interface TableSeatResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category = 5;
   */
  category?: TableSeatResource_CategoryValue; // nullable
  /**
   * @generated from protobuf field: google.protobuf.Int32Value min_party_size = 6;
   */
  minPartySize?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value max_party_size = 7;
   */
  maxPartySize?: Int32Value;
  /**
   * @generated from protobuf field: int32 position = 8;
   */
  position: number;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status = 9;
   */
  smokingStatus?: TableSeatResource_SmokingStatusValue;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.Status status = 10;
   */
  status: TableSeatResource_Status;
  /**
   * @generated from protobuf field: string token = 11;
   */
  token: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue
 */
export interface TableSeatResource_CategoryValue {
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.Category value = 1;
   */
  value: TableSeatResource_Category;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue
 */
export interface TableSeatResource_SmokingStatusValue {
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatus value = 1;
   */
  value: TableSeatResource_SmokingStatus;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat.TableSeatResource.Category
 */
export enum TableSeatResource_Category {
  /**
   * @generated from protobuf enum value: TABLE_SEAT = 0;
   */
  TABLE_SEAT = 0,
  /**
   * @generated from protobuf enum value: COUNTER_SEAT = 1;
   */
  COUNTER_SEAT = 1,
  /**
   * @generated from protobuf enum value: TERRACE = 2;
   */
  TERRACE = 2,
  /**
   * @generated from protobuf enum value: SEMI_PRIVATE_ROOM = 3;
   */
  SEMI_PRIVATE_ROOM = 3,
  /**
   * @generated from protobuf enum value: PRIVATE_ROOM = 4;
   */
  PRIVATE_ROOM = 4,
  /**
   * @generated from protobuf enum value: TATAMI = 5;
   */
  TATAMI = 5,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatus
 */
export enum TableSeatResource_SmokingStatus {
  /**
   * @generated from protobuf enum value: NO_SMOKING = 0;
   */
  NO_SMOKING = 0,
  /**
   * @generated from protobuf enum value: SMOKING = 1;
   */
  SMOKING = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.table_seat.TableSeatResource.Status
 */
export enum TableSeatResource_Status {
  /**
   * @generated from protobuf enum value: PUBLISHED = 0;
   */
  PUBLISHED = 0,
  /**
   * @generated from protobuf enum value: ARCHIVED = 1;
   */
  ARCHIVED = 1,
  /**
   * @generated from protobuf enum value: DELETED = 2;
   */
  DELETED = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatResource$Type extends MessageType<TableSeatResource> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.TableSeatResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
      {
        no: 5,
        name: "category",
        kind: "message",
        T: () => TableSeatResource_CategoryValue,
      },
      { no: 6, name: "min_party_size", kind: "message", T: () => Int32Value },
      { no: 7, name: "max_party_size", kind: "message", T: () => Int32Value },
      { no: 8, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      {
        no: 9,
        name: "smoking_status",
        kind: "message",
        T: () => TableSeatResource_SmokingStatusValue,
      },
      {
        no: 10,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.table_seat.TableSeatResource.Status",
          TableSeatResource_Status,
        ],
      },
      { no: 11, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<TableSeatResource>): TableSeatResource {
    const message = { id: "", name: "", position: 0, status: 0, token: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatResource,
  ): TableSeatResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 3:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 4:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category */ 5:
          message.category = TableSeatResource_CategoryValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.category,
          );
          break;
        case /* google.protobuf.Int32Value min_party_size */ 6:
          message.minPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.minPartySize,
          );
          break;
        case /* google.protobuf.Int32Value max_party_size */ 7:
          message.maxPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.maxPartySize,
          );
          break;
        case /* int32 position */ 8:
          message.position = reader.int32();
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status */ 9:
          message.smokingStatus =
            TableSeatResource_SmokingStatusValue.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.smokingStatus,
            );
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.Status status */ 10:
          message.status = reader.int32();
          break;
        case /* string token */ 11:
          message.token = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* google.protobuf.Timestamp created_at = 3; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 4; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category = 5; */
    if (message.category)
      TableSeatResource_CategoryValue.internalBinaryWrite(
        message.category,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value min_party_size = 6; */
    if (message.minPartySize)
      Int32Value.internalBinaryWrite(
        message.minPartySize,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value max_party_size = 7; */
    if (message.maxPartySize)
      Int32Value.internalBinaryWrite(
        message.maxPartySize,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int32 position = 8; */
    if (message.position !== 0)
      writer.tag(8, WireType.Varint).int32(message.position);
    /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status = 9; */
    if (message.smokingStatus)
      TableSeatResource_SmokingStatusValue.internalBinaryWrite(
        message.smokingStatus,
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat.TableSeatResource.Status status = 10; */
    if (message.status !== 0)
      writer.tag(10, WireType.Varint).int32(message.status);
    /* string token = 11; */
    if (message.token !== "")
      writer.tag(11, WireType.LengthDelimited).string(message.token);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.TableSeatResource
 */
export const TableSeatResource = new TableSeatResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatResource_CategoryValue$Type extends MessageType<TableSeatResource_CategoryValue> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue",
      [
        {
          no: 1,
          name: "value",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.table_seat.TableSeatResource.Category",
            TableSeatResource_Category,
          ],
        },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatResource_CategoryValue>,
  ): TableSeatResource_CategoryValue {
    const message = { value: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatResource_CategoryValue>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatResource_CategoryValue,
  ): TableSeatResource_CategoryValue {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.Category value */ 1:
          message.value = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatResource_CategoryValue,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* auto_reserve.restaurants.table_seat.TableSeatResource.Category value = 1; */
    if (message.value !== 0)
      writer.tag(1, WireType.Varint).int32(message.value);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue
 */
export const TableSeatResource_CategoryValue =
  new TableSeatResource_CategoryValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatResource_SmokingStatusValue$Type extends MessageType<TableSeatResource_SmokingStatusValue> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue",
      [
        {
          no: 1,
          name: "value",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatus",
            TableSeatResource_SmokingStatus,
          ],
        },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatResource_SmokingStatusValue>,
  ): TableSeatResource_SmokingStatusValue {
    const message = { value: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatResource_SmokingStatusValue>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatResource_SmokingStatusValue,
  ): TableSeatResource_SmokingStatusValue {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatus value */ 1:
          message.value = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatResource_SmokingStatusValue,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatus value = 1; */
    if (message.value !== 0)
      writer.tag(1, WireType.Varint).int32(message.value);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue
 */
export const TableSeatResource_SmokingStatusValue =
  new TableSeatResource_SmokingStatusValue$Type();
