import React, { ComponentProps, useMemo } from 'react'
import { StyleProp, View, TextStyle } from 'react-native'
import { hexWithOpacity } from '@hello-ai/ar_shared/src/modules/hexWithOpacity'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { TabBar, TabView } from 'react-native-tab-view'
import ReservedContent from './ReservedContent'
import RequestContent from './RequestContent'
import CanceledContent from './CanceledContent'
import { TableRestaurantSettingWebReservationType } from '@hello-ai/ar_shared/src/types/ForR/TableRestaurantSetting'
import { AddReservationFloatButton } from '../AddReservationFloatButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import Animated from 'react-native-reanimated'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useRestaurantRequestReservationsWithoutBeforeChanged } from '../../../models/RestaurantRequestReservation'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import RequestReservationListView from '../../Restaurant/RequestReservation/ListView'

type ContentType = 'reserved' | 'request' | 'canceled'

interface Route {
  key: ContentType
  title: string
}

interface TabBarItemLabelProps {
  color: string
  label?: string
  labelStyle: StyleProp<TextStyle>
  icon: React.ReactNode
}

const TabBarItemLabel = React.memo(
  ({ color, label, labelStyle, icon }: TabBarItemLabelProps) => {
    return (
      <View
        style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}
      >
        <Animated.Text
          style={[
            {
              backgroundColor: 'transparent',
            },
            labelStyle,
            { color },
          ]}
        >
          {label}
        </Animated.Text>
        {icon}
      </View>
    )
  }
)

function AwaitingApprovalIcon({
  restaurantId,
  date,
  focused,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  focused: boolean
}) {
  const { requestReservations } =
    useRestaurantRequestReservationsWithoutBeforeChanged(restaurantId, {
      date,
      per: 100,
    })

  if (requestReservations == null || requestReservations.length === 0) {
    return null
  }

  return (
    <View
      style={{
        backgroundColor: Colors.caution,
        width: 24,
        height: 24,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        opacity: focused ? 1 : 0.6,
      }}
    >
      <Text
        style={{
          fontSize: 16,
          fontWeight: '600',
          lineHeight: 18,
          letterSpacing: 0,
          textAlign: 'center',
          color: Colors.white,
        }}
      >
        {requestReservations.length}
      </Text>
    </View>
  )
}

export function ListView({
  restaurantId,
  date,
  webReservationType,
  onPressRequestRestaurantReservation,
  onPressRestaurantReservation,
  onPressCustomer,
  onPressAddReservationButton,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  webReservationType: TableRestaurantSettingWebReservationType
  dateString: string
  onPressRequestRestaurantReservation: ComponentProps<
    typeof RequestReservationListView
  >['onPressRequestRestaurantReservation']
  onPressRestaurantReservation: (
    restaurantReservationId: string | number
  ) => void
  onPressCustomer: (customerId: string) => void
  onPressAddReservationButton: () => void
}) {
  const { width, sm } = useResponsive()
  const [index, setIndex] = React.useState(0)
  const routes = useMemo<Route[]>(() => {
    switch (webReservationType) {
      case 'normal':
        return [
          { key: 'reserved', title: t('成立') },
          { key: 'canceled', title: t('キャンセル') },
        ]
      case 'request':
        return [
          { key: 'reserved', title: t('成立') },
          { key: 'request', title: t('承認待ち') },
          { key: 'canceled', title: t('キャンセル') },
        ]
      default:
        return []
    }
  }, [webReservationType])

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors.bgBlack,
      }}
    >
      <TabView
        navigationState={{ index, routes }}
        renderScene={({ route }) => {
          switch (route.key) {
            case 'reserved':
              return (
                <ReservedContent
                  restaurantId={restaurantId}
                  date={date}
                  onPressRestaurantReservation={onPressRestaurantReservation}
                  onPressCustomer={onPressCustomer}
                />
              )
            case 'request':
              return (
                <RequestContent
                  restaurantId={restaurantId}
                  date={date}
                  onPressRequestRestaurantReservation={
                    onPressRequestRestaurantReservation
                  }
                  onPressCustomer={onPressCustomer}
                />
              )
            case 'canceled':
              return (
                <CanceledContent
                  restaurantId={restaurantId}
                  date={date}
                  onPressRestaurantReservation={onPressRestaurantReservation}
                  onPressCustomer={onPressCustomer}
                />
              )
            default:
              return null
          }
        }}
        onIndexChange={setIndex}
        renderTabBar={(props) => (
          <TabBar
            {...props}
            labelStyle={{
              fontSize: width < sm ? 18 : 16,
              fontWeight: '600',
            }}
            activeColor={Colors.primary}
            inactiveColor={
              width < sm ? Colors.black60 : hexWithOpacity(0.5, Colors.primary)
            }
            indicatorStyle={{
              backgroundColor: Colors.primary,
              height: 3,
            }}
            tabStyle={{
              minHeight: 60,
            }}
            style={{
              backgroundColor: 'white',
            }}
            renderLabel={({ route, focused, color }) => {
              return (
                <TabBarItemLabel
                  label={route.title}
                  labelStyle={{
                    fontSize: width < sm ? 18 : 16,
                    fontWeight: '600',
                  }}
                  color={color}
                  icon={
                    route.key === 'request' ? (
                      <AwaitingApprovalIcon
                        restaurantId={restaurantId}
                        date={date}
                        focused={focused}
                      />
                    ) : null
                  }
                />
              )
            }}
          />
        )}
      />
      <AddReservationFloatButton onPress={onPressAddReservationButton} />
    </View>
  )
}
