import React from 'react'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import BusinessUserAccountEdit from 'pages/BusinessUser/Account/index'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  AccountEdit: {
    title: t('アカウント'),
    fullPath: '/business_user/account/edit',
    relativePath: '/account/edit',
    exact: true,
    element: <BusinessUserAccountEdit />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="/" />,
    },
  },
}

export default function BusinessUserRolesStack() {
  return <Stack routes={routes} />
}
