// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/business_user/business_user_service.proto" (package "auto_reserve.restaurants.business_user", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { BusinessUser } from "./business_user_service";
import type { UpdateCurrentBusinessUserPasswordRequest } from "./business_user_service";
import type { UpdateCurrentBusinessUserRequest } from "./business_user_service";
import type { ConfirmChangeEmailRequestResponse } from "./business_user_service";
import type { ConfirmChangeEmailRequestRequest } from "./business_user_service";
import type { CreateChangeEmailRequestResponse } from "./business_user_service";
import type { CreateChangeEmailRequestRequest } from "./business_user_service";
import type { RegisterResponse } from "./business_user_service";
import type { RegisterRequest } from "./business_user_service";
import type { GetInvitationResponse } from "./business_user_service";
import type { GetInvitationRequest } from "./business_user_service";
import type { ResendInvitationResponse } from "./business_user_service";
import type { ResendInvitationRequest } from "./business_user_service";
import type { InviteResponse } from "./business_user_service";
import type { InviteRequest } from "./business_user_service";
import type { Empty } from "../../../google/protobuf/empty";
import type { DestroyBusinessUserRequest } from "./business_user_service";
import type { UpdateBusinessUserRequest } from "./business_user_service";
import type { BusinessUserResource } from "./business_user_resource";
import type { GetBusinessUserRequest } from "./business_user_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { IndexResponse } from "./business_user_service";
import type { IndexRequest } from "./business_user_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.business_user.BusinessUser
 */
export interface IBusinessUserClient {
  /**
   * require current_owner
   *
   * @generated from protobuf rpc: Index(auto_reserve.restaurants.business_user.IndexRequest) returns (auto_reserve.restaurants.business_user.IndexResponse);
   */
  index(
    input: IndexRequest,
    options?: RpcOptions,
  ): UnaryCall<IndexRequest, IndexResponse>;
  /**
   * @generated from protobuf rpc: GetBusinessUser(auto_reserve.restaurants.business_user.GetBusinessUserRequest) returns (auto_reserve.restaurants.business_user.BusinessUserResource);
   */
  getBusinessUser(
    input: GetBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBusinessUserRequest, BusinessUserResource>;
  /**
   * @generated from protobuf rpc: UpdateBusinessUser(auto_reserve.restaurants.business_user.UpdateBusinessUserRequest) returns (auto_reserve.restaurants.business_user.BusinessUserResource);
   */
  updateBusinessUser(
    input: UpdateBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateBusinessUserRequest, BusinessUserResource>;
  /**
   * @generated from protobuf rpc: DestroyBusinessUser(auto_reserve.restaurants.business_user.DestroyBusinessUserRequest) returns (google.protobuf.Empty);
   */
  destroyBusinessUser(
    input: DestroyBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyBusinessUserRequest, Empty>;
  /**
   * @generated from protobuf rpc: Invite(auto_reserve.restaurants.business_user.InviteRequest) returns (auto_reserve.restaurants.business_user.InviteResponse);
   */
  invite(
    input: InviteRequest,
    options?: RpcOptions,
  ): UnaryCall<InviteRequest, InviteResponse>;
  /**
   * @generated from protobuf rpc: ResendInvitation(auto_reserve.restaurants.business_user.ResendInvitationRequest) returns (auto_reserve.restaurants.business_user.ResendInvitationResponse);
   */
  resendInvitation(
    input: ResendInvitationRequest,
    options?: RpcOptions,
  ): UnaryCall<ResendInvitationRequest, ResendInvitationResponse>;
  /**
   * not require authentication
   *
   * @generated from protobuf rpc: GetInvitation(auto_reserve.restaurants.business_user.GetInvitationRequest) returns (auto_reserve.restaurants.business_user.GetInvitationResponse);
   */
  getInvitation(
    input: GetInvitationRequest,
    options?: RpcOptions,
  ): UnaryCall<GetInvitationRequest, GetInvitationResponse>;
  /**
   * @generated from protobuf rpc: Register(auto_reserve.restaurants.business_user.RegisterRequest) returns (auto_reserve.restaurants.business_user.RegisterResponse);
   */
  register(
    input: RegisterRequest,
    options?: RpcOptions,
  ): UnaryCall<RegisterRequest, RegisterResponse>;
  /**
   * require current_business_user
   *
   * @generated from protobuf rpc: CreateChangeEmailRequest(auto_reserve.restaurants.business_user.CreateChangeEmailRequestRequest) returns (auto_reserve.restaurants.business_user.CreateChangeEmailRequestResponse);
   */
  createChangeEmailRequest(
    input: CreateChangeEmailRequestRequest,
    options?: RpcOptions,
  ): UnaryCall<
    CreateChangeEmailRequestRequest,
    CreateChangeEmailRequestResponse
  >;
  /**
   * @generated from protobuf rpc: ConfirmChangeEmailRequest(auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestRequest) returns (auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestResponse);
   */
  confirmChangeEmailRequest(
    input: ConfirmChangeEmailRequestRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ConfirmChangeEmailRequestRequest,
    ConfirmChangeEmailRequestResponse
  >;
  /**
   * @generated from protobuf rpc: UpdateCurrentBusinessUser(auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserRequest) returns (auto_reserve.restaurants.business_user.BusinessUserResource);
   */
  updateCurrentBusinessUser(
    input: UpdateCurrentBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateCurrentBusinessUserRequest, BusinessUserResource>;
  /**
   * @generated from protobuf rpc: UpdateCurrentBusinessUserPassword(auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserPasswordRequest) returns (google.protobuf.Empty);
   */
  updateCurrentBusinessUserPassword(
    input: UpdateCurrentBusinessUserPasswordRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateCurrentBusinessUserPasswordRequest, Empty>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.business_user.BusinessUser
 */
export class BusinessUserClient implements IBusinessUserClient, ServiceInfo {
  typeName = BusinessUser.typeName;
  methods = BusinessUser.methods;
  options = BusinessUser.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * require current_owner
   *
   * @generated from protobuf rpc: Index(auto_reserve.restaurants.business_user.IndexRequest) returns (auto_reserve.restaurants.business_user.IndexResponse);
   */
  index(
    input: IndexRequest,
    options?: RpcOptions,
  ): UnaryCall<IndexRequest, IndexResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<IndexRequest, IndexResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetBusinessUser(auto_reserve.restaurants.business_user.GetBusinessUserRequest) returns (auto_reserve.restaurants.business_user.BusinessUserResource);
   */
  getBusinessUser(
    input: GetBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBusinessUserRequest, BusinessUserResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetBusinessUserRequest, BusinessUserResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateBusinessUser(auto_reserve.restaurants.business_user.UpdateBusinessUserRequest) returns (auto_reserve.restaurants.business_user.BusinessUserResource);
   */
  updateBusinessUser(
    input: UpdateBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateBusinessUserRequest, BusinessUserResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateBusinessUserRequest, BusinessUserResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: DestroyBusinessUser(auto_reserve.restaurants.business_user.DestroyBusinessUserRequest) returns (google.protobuf.Empty);
   */
  destroyBusinessUser(
    input: DestroyBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyBusinessUserRequest, Empty> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyBusinessUserRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Invite(auto_reserve.restaurants.business_user.InviteRequest) returns (auto_reserve.restaurants.business_user.InviteResponse);
   */
  invite(
    input: InviteRequest,
    options?: RpcOptions,
  ): UnaryCall<InviteRequest, InviteResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<InviteRequest, InviteResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ResendInvitation(auto_reserve.restaurants.business_user.ResendInvitationRequest) returns (auto_reserve.restaurants.business_user.ResendInvitationResponse);
   */
  resendInvitation(
    input: ResendInvitationRequest,
    options?: RpcOptions,
  ): UnaryCall<ResendInvitationRequest, ResendInvitationResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ResendInvitationRequest, ResendInvitationResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * not require authentication
   *
   * @generated from protobuf rpc: GetInvitation(auto_reserve.restaurants.business_user.GetInvitationRequest) returns (auto_reserve.restaurants.business_user.GetInvitationResponse);
   */
  getInvitation(
    input: GetInvitationRequest,
    options?: RpcOptions,
  ): UnaryCall<GetInvitationRequest, GetInvitationResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetInvitationRequest, GetInvitationResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Register(auto_reserve.restaurants.business_user.RegisterRequest) returns (auto_reserve.restaurants.business_user.RegisterResponse);
   */
  register(
    input: RegisterRequest,
    options?: RpcOptions,
  ): UnaryCall<RegisterRequest, RegisterResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<RegisterRequest, RegisterResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * require current_business_user
   *
   * @generated from protobuf rpc: CreateChangeEmailRequest(auto_reserve.restaurants.business_user.CreateChangeEmailRequestRequest) returns (auto_reserve.restaurants.business_user.CreateChangeEmailRequestResponse);
   */
  createChangeEmailRequest(
    input: CreateChangeEmailRequestRequest,
    options?: RpcOptions,
  ): UnaryCall<
    CreateChangeEmailRequestRequest,
    CreateChangeEmailRequestResponse
  > {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateChangeEmailRequestRequest,
      CreateChangeEmailRequestResponse
    >("unary", this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ConfirmChangeEmailRequest(auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestRequest) returns (auto_reserve.restaurants.business_user.ConfirmChangeEmailRequestResponse);
   */
  confirmChangeEmailRequest(
    input: ConfirmChangeEmailRequestRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ConfirmChangeEmailRequestRequest,
    ConfirmChangeEmailRequestResponse
  > {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ConfirmChangeEmailRequestRequest,
      ConfirmChangeEmailRequestResponse
    >("unary", this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateCurrentBusinessUser(auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserRequest) returns (auto_reserve.restaurants.business_user.BusinessUserResource);
   */
  updateCurrentBusinessUser(
    input: UpdateCurrentBusinessUserRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateCurrentBusinessUserRequest, BusinessUserResource> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateCurrentBusinessUserRequest,
      BusinessUserResource
    >("unary", this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateCurrentBusinessUserPassword(auto_reserve.restaurants.business_user.UpdateCurrentBusinessUserPasswordRequest) returns (google.protobuf.Empty);
   */
  updateCurrentBusinessUserPassword(
    input: UpdateCurrentBusinessUserPasswordRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateCurrentBusinessUserPasswordRequest, Empty> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateCurrentBusinessUserPasswordRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
