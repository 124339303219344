import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'
import { useToken } from './Auth'

import { BusinessUserClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_service.client'

import {
  GetBusinessUserRequest,
  GetInvitationRequest,
  IndexRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_service'

export const businessUserService = createRpcService(BusinessUserClient)
export const businessUserClient = businessUserService.client

export function useBusinessUserInvitation(params: GetInvitationRequest) {
  const token = useToken()

  const { data, error, mutate } = useSWR(
    swrKey(token, 'BusinessUsers/GetInvitation', params),
    () =>
      businessUserClient.getInvitation(params, rpcOptions({ token })).response
  )

  return {
    data,
    error,
    mutate,
  }
}

export function useBusinessUser(params: GetBusinessUserRequest) {
  const token = useToken()

  const {
    data: businessUser,
    error,
    mutate,
  } = useSWR(
    swrKey(token, 'BusinessUsers/GetBusinessUser', params),
    () =>
      businessUserClient.getBusinessUser(params, rpcOptions({ token })).response
  )

  return {
    businessUser,
    error,
    mutate,
  }
}

export function useOwnerBusinessUsers(
  params: IndexRequest = {
    page: 1,
    perPage: 9999,
  }
) {
  const token = useToken()

  const { data, error, mutate } = useSWR(
    swrKey(token, 'BusinessUsers/Index', params),
    () => businessUserClient.index(params, rpcOptions({ token })).response
  )

  return {
    businessUsers: data?.businessUsers,
    error,
    mutate,
  }
}
