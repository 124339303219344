import React, { useEffect, useState } from 'react'
import { View, ScrollView } from 'react-native'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  SupportedLocale,
  localeData,
} from '@hello-ai/ar_shared/src/modules/locale'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { getLocale } from 'modules/locale'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useNavigate, useParams } from 'react-router-dom'

export default function LanguageScreen() {
  const params = useParams()
  const { id } = params as { id: string }
  const navigation = useNavigate()
  const { width, sm } = useResponsive()
  const locale = getLocale()
  const [isLocale, setIsLocal] = useState<SupportedLocale>(locale)
  const localItems = Object.entries(localeData).map(([value, { label }]) => ({
    value,
    label,
  })) as { value: SupportedLocale; label: string }[]

  useEffect(() => {
    if (isLocale === locale) return
    navigation(`/${isLocale}/restaurants/${id}/other/language`)
    window.location.reload()
  }, [isLocale, locale, id, navigation])

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: Colors.bgBlack,
      }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      <View>
        <ShadowBox style={{ marginTop: 24, padding: width < sm ? 16 : 32 }}>
          <Text style={{ fontSize: 18 }}>
            {t('本サービスで使用する言語を設定できます')}
          </Text>
          <View
            style={{
              marginTop: width < sm ? 24 : 48,
            }}
          >
            <SelectInput
              selectedValue={isLocale}
              setValue={setIsLocal}
              items={localItems}
            />
          </View>
          <Text
            style={{
              marginTop: width < sm ? 24 : 48,
              fontSize: 14,
              color: Colors.black60,
            }}
          >
            {t('※ 設定言語が適用されるのはアプリの機能の文言のみです。')}
            {'\n'}
            {t(
              '※ メニュー・席名など、お客様が入力した情報は自動翻訳されません。'
            )}
            {'\n'}
            {t('※ 言語設定はこの端末のみで反映されます。')}
          </Text>
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
