import React from 'react'
import { View } from 'react-native'
import { Tabs, TabProps, TabContent } from 'components/Shared/Navigation/Tab'
import SalesByPeriod from './SalesByPeriod'

import {
  generatePath,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router'
import SalesDetail from './SalesDetail'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import { HeaderBack } from '../../../../components/Shared/Navigation/Header'
import TakeoutView from 'components/Restaurant/TakeoutView'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { getLocale } from '@hello-ai/ar_shared/src/modules/locale'

const SalesTabs: TabProps[] = [
  {
    title: t('期間別売上'),
    fullPath: '/restaurants/:id/takeout/sales',
    relativePath: '/',
    element: <SalesByPeriod />,
  },
]

const routes: StackRoutes = {
  Index: {
    title: t('売上管理'),
    fullPath: `/restaurants/:id/takeout/sales/details`,
    relativePath: `/details`,
    exact: true,
    element: <SalesDetail />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function RestaurantSales() {
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const locale = getLocale()
  const isTabRoute = SalesTabs.some((tab) => {
    const to = `/${locale}${generatePath(tab.fullPath, params)}`
    return to === location.pathname
  })

  if (!isTabRoute) {
    return <Stack routes={routes} />
  }

  return (
    <TakeoutView>
      <View style={{ flex: 1 }}>
        <Tabs tabs={SalesTabs} />
        <TabContent>
          <Routes>
            {SalesTabs.map((tab) => {
              return (
                <Route
                  key={tab.fullPath}
                  path={tab.relativePath}
                  element={tab.element}
                />
              )
            })}
          </Routes>
        </TabContent>
      </View>
    </TakeoutView>
  )
}
