import React from 'react'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'

import { View } from 'react-native'

export function HeaderLeftButton({
  onPress,
  icon,
}: {
  icon: IconDefinition
  onPress: () => void
}) {
  return (
    <TouchableOpacity onPress={onPress} onPressMinInterval={0}>
      <View style={{ width: 30 }}>
        <FontAwesomeIcon icon={icon} size={22} color={Colors.primary} />
      </View>
    </TouchableOpacity>
  )
}

export function HeaderBackButton({ onPress }: { onPress: () => void }) {
  return <HeaderLeftButton onPress={onPress} icon={faChevronLeft} />
}

export function HeaderForwardButton({ onPress }: { onPress: () => void }) {
  return <HeaderLeftButton onPress={onPress} icon={faChevronRight} />
}

export function HeaderCloseButton({ onPress }: { onPress: () => void }) {
  return <HeaderLeftButton onPress={onPress} icon={faTimes} />
}

export function HeaderButtonEmptyView() {
  return <View style={{ width: 30 }} />
}

export function Header({
  onClose,
  title,
  headerLeft = () => <HeaderCloseButton onPress={onClose} />,
  headerRight = () => <HeaderButtonEmptyView />,
}: {
  headerLeft?: () => React.ReactNode
  headerRight?: () => React.ReactNode
  onClose: () => void
  title: string | React.ReactNode
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: width < sm ? 16 : 24,
        borderBottomWidth: 1,
        borderColor: Colors.border,
        paddingTop: 24,
      }}
    >
      {headerLeft()}
      {typeof title === 'string' ? (
        <Text
          style={{
            fontWeight: '600',
            fontSize: width < sm ? 18 : 22,
            color: Colors.primary,
          }}
        >
          {title}
        </Text>
      ) : (
        title
      )}
      {headerRight()}
    </View>
  )
}
