// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service.proto" (package "auto_reserve.restaurants.restaurant_reservation", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantReservation } from "./restaurant_reservation_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ByCalendarResponse } from "./restaurant_reservation_service";
import type { ByCalendarRequest } from "./restaurant_reservation_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_reservation.RestaurantReservation
 */
export interface IRestaurantReservationClient {
  /**
   * @generated from protobuf rpc: ByCalendar(auto_reserve.restaurants.restaurant_reservation.ByCalendarRequest) returns (auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse);
   */
  byCalendar(
    input: ByCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<ByCalendarRequest, ByCalendarResponse>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_reservation.RestaurantReservation
 */
export class RestaurantReservationClient
  implements IRestaurantReservationClient, ServiceInfo
{
  typeName = RestaurantReservation.typeName;
  methods = RestaurantReservation.methods;
  options = RestaurantReservation.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ByCalendar(auto_reserve.restaurants.restaurant_reservation.ByCalendarRequest) returns (auto_reserve.restaurants.restaurant_reservation.ByCalendarResponse);
   */
  byCalendar(
    input: ByCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<ByCalendarRequest, ByCalendarResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ByCalendarRequest, ByCalendarResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
