import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { DateData } from 'react-native-calendars/src/types'
import { restaurantReservationService } from '../../models/RestaurantReservation'
import ModalCenter from '../Shared/ModalCenter'

import { GetCalendarResponse_BusinessTimeResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_business_time/restaurant_business_time_service'
import { ByCalendarResponse_DateResource_PeriodResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service'
import { DayProps } from 'react-native-calendars/src/calendar/day'
import { restaurantBusinessTimeService } from '../../models/RestaurantBusinessTime'
import {
  CalendarState,
  CalendarStateProvider,
  Day,
  ReservationCalendar,
} from '../Restaurant/Reservation/ReservationCalendarList'

type CalendarDate = {
  businessTimes: GetCalendarResponse_BusinessTimeResource[]
  date: string
  periods: ByCalendarResponse_DateResource_PeriodResource[]
  isHoliday: boolean
}

type CalendarPeriod = {
  gte: string
  lte: string
}

type CalendarPeriods = {
  current: CalendarPeriod
}

const CalendarDatesContext = createContext<Record<string, CalendarDate>>({})

const DayComponent = React.memo(
  ({ date, onPress }: { date?: DateData; onPress?: DayProps['onPress'] }) => {
    const calendarDates = useContext(CalendarDatesContext)
    const { periods, isHoliday, businessTimes } = React.useMemo(
      () =>
        date != null
          ? calendarDates[date.dateString] ?? {
              periods: [],
              isHoliday: false,
              businessTimes: [],
            }
          : {
              periods: [],
              isHoliday: false,
              businessTimes: [],
            },
      [calendarDates, date]
    )

    const isClosed = useMemo(() => {
      if (businessTimes == null) return false
      if (businessTimes.length === 0) return true
      return businessTimes.every((b) => !b.open)
    }, [businessTimes])

    return (
      <Day
        date={date!}
        periods={periods}
        isHoliday={isHoliday}
        isClosed={isClosed}
        onPress={onPress}
      />
    )
  }
)

const useCalendarReservationDate = (
  restaurantId: number,
  calendarPeriod: CalendarPeriod
) => {
  const { data } = restaurantReservationService.useByCalendar({
    restaurantId,
    gte: calendarPeriod.gte,
    lte: calendarPeriod.lte,
  })
  return useMemo(
    () => [calendarPeriod.gte, data?.dates ?? []] as const,
    [data, calendarPeriod]
  )
}

const useCalendarReservationDates = (
  restaurantId: number,
  calendarPeriods: CalendarPeriods
) => {
  const currentData = useCalendarReservationDate(
    restaurantId,
    calendarPeriods.current
  )
  return useMemo(() => {
    return Object.fromEntries(
      [currentData].map(([period, dates]) => [period, dates] as const)
    )
  }, [currentData])
}

const useCalendarBusinessTime = (
  restaurantId: number,
  calendarPeriod: CalendarPeriod
) => {
  const { data } = restaurantBusinessTimeService.useGetCalendar({
    restaurantId,
    gte: calendarPeriod.gte,
    lte: calendarPeriod.lte,
  })
  return useMemo(
    () => [calendarPeriod.gte, data?.dates ?? []] as const,
    [data, calendarPeriod]
  )
}

const useCalendarBusinessTimes = (
  restaurantId: number,
  calendarPeriods: CalendarPeriods
) => {
  const currentData = useCalendarBusinessTime(
    restaurantId,
    calendarPeriods.current
  )
  return useMemo(() => {
    return Object.fromEntries(
      [currentData].map(([period, dates]) => [period, dates] as const)
    )
  }, [currentData])
}

const getStartAndEndOfMonth = (date: dayjs.Dayjs): CalendarPeriod => {
  const startOfMonth = date
    .clone()
    .startOf('month')
    .subtract(1, 'week')
    .format('YYYY-MM-DD')
  const endOfMonth = date
    .clone()
    .endOf('month')
    .add(1, 'week')
    .format('YYYY-MM-DD')
  return { gte: startOfMonth, lte: endOfMonth }
}

export function CalendarModal({
  restaurantId,
  title,
  isModalVisible,
  onClose,
  selectedDate,
  onPress,
}: {
  restaurantId: number
  title: string
  isModalVisible: boolean
  onClose: () => void
  selectedDate: dayjs.Dayjs
  onPress: (d: dayjs.Dayjs) => void
}) {
  const calendarState: CalendarState = useMemo(() => {
    return { selectedDate }
  }, [selectedDate])

  const onDayPress = useCallback(
    (date: DateData) => onPress(dayjs(date.dateString, 'YYYY-MM-DD')),
    [onPress]
  )

  const [calendarPeriodBaseDate, setCalendarPeriodBaseDate] =
    useState(selectedDate)

  const calendarPeriods = useMemo<CalendarPeriods>(
    () => ({
      current: getStartAndEndOfMonth(calendarPeriodBaseDate.clone()),
    }),
    [calendarPeriodBaseDate]
  )

  const reservationDateSets = useCalendarReservationDates(
    restaurantId,
    calendarPeriods
  )
  const businessTimeSets = useCalendarBusinessTimes(
    restaurantId,
    calendarPeriods
  )
  const [calendarDates, isLoading] = useMemo(() => {
    const entries = Object.entries(reservationDateSets)
    const mergedDateSets = entries
      .filter(
        ([period, reservationDateSet]) =>
          reservationDateSet.length > 0 &&
          businessTimeSets[period] != null &&
          businessTimeSets[period].length > 0
      )
      .map(([period, reservationTimeSet]) => {
        const businessTimeSet = businessTimeSets[period]
        return reservationTimeSet.map((reservation, index) => {
          const businessTime = businessTimeSet[index]
          return [
            reservation.date,
            {
              ...reservation,
              businessTimes: businessTime?.businessTimes ?? [],
            },
          ] as const
        })
      })
    return [
      Object.fromEntries(mergedDateSets.flat()),
      entries.length !== mergedDateSets.length,
    ]
  }, [reservationDateSets, businessTimeSets])

  const onVisibleMonthsChange = useCallback(
    (dates: DateData[]) => {
      const date = dates[0]
      if (date != null) {
        setCalendarPeriodBaseDate(dayjs(date.dateString, 'YYYY-MM-DD'))
      }
    },
    [setCalendarPeriodBaseDate]
  )
  useEffect(() => {
    if (!isModalVisible) {
      return
    }
    setCalendarPeriodBaseDate(selectedDate)
  }, [isModalVisible, selectedDate, setCalendarPeriodBaseDate])

  return (
    <CalendarStateProvider value={calendarState}>
      <ModalCenter
        title={title}
        width="100%"
        height="100%"
        isModalVisible={isModalVisible}
        onClose={onClose}
      >
        <CalendarDatesContext.Provider value={calendarDates}>
          <ReservationCalendar
            selectedDate={selectedDate.format('YYYY-MM-DD')}
            selectedPeriods={
              calendarDates[selectedDate.format('YYYY-MM-DD')]?.periods ?? []
            }
            dayComponent={DayComponent}
            onDayPress={onDayPress}
            onVisibleMonthsChange={onVisibleMonthsChange}
            visibleMonth={calendarPeriodBaseDate.format('YYYY-MM')}
            displayLoadingIndicator={isLoading}
            onClose={onClose}
          />
        </CalendarDatesContext.Provider>
      </ModalCenter>
    </CalendarStateProvider>
  )
}
