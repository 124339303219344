// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/business_user/business_user_invitation_resource.proto" (package "auto_reserve.restaurants.business_user", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BusinessUserResource } from "./business_user_resource";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.business_user.BusinessUserInvitationResource
 */
export interface BusinessUserInvitationResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string token = 2;
   */
  token: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.business_user.BusinessUserResource business_user = 5;
   */
  businessUser?: BusinessUserResource;
}
// @generated message type with reflection information, may provide speed optimized methods
class BusinessUserInvitationResource$Type extends MessageType<BusinessUserInvitationResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.business_user.BusinessUserInvitationResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 5,
          name: "business_user",
          kind: "message",
          T: () => BusinessUserResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<BusinessUserInvitationResource>,
  ): BusinessUserInvitationResource {
    const message = { id: "", token: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BusinessUserInvitationResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BusinessUserInvitationResource,
  ): BusinessUserInvitationResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string token */ 2:
          message.token = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 3:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 4:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* auto_reserve.restaurants.business_user.BusinessUserResource business_user */ 5:
          message.businessUser = BusinessUserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.businessUser,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BusinessUserInvitationResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string token = 2; */
    if (message.token !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.token);
    /* google.protobuf.Timestamp created_at = 3; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 4; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.business_user.BusinessUserResource business_user = 5; */
    if (message.businessUser)
      BusinessUserResource.internalBinaryWrite(
        message.businessUser,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.business_user.BusinessUserInvitationResource
 */
export const BusinessUserInvitationResource =
  new BusinessUserInvitationResource$Type();
