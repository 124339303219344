import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import ModalCenter from '../Shared/ModalCenter'
import {
  updateRestaurantDailyReservationNote,
  useRestaurantDailyReservationNote,
} from '@hello-ai/for_r_app/src/models/Restaurant'
import React, { useEffect } from 'react'
import {
  Keyboard,
  TouchableWithoutFeedback,
  View,
  Platform,
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

function Contents({
  salesNote,
  setSalesNote,
  onPress,
}: {
  salesNote: string
  setSalesNote: (text: string) => void
  onPress: () => void
}) {
  const { width, sm } = useResponsive()
  const { bottom } = useSafeAreaInsets()

  return (
    <View style={{ flex: 1 }}>
      <View
        style={[
          {
            padding: 24,
          },
          width > sm && {
            borderColor: Colors.border,
            borderBottomWidth: 1,
          },
        ]}
      >
        <TextInput
          value={salesNote}
          onChangeText={(text) => setSalesNote(text)}
          autoCapitalize="none"
          style={[
            {
              height: 100,
            },
          ]}
          multiline
        />
      </View>
      <View
        style={[
          width <= sm && {
            position: 'absolute',
            borderColor: Colors.border,
            borderTopWidth: 1,
            bottom,
            width: '100%',
          },
        ]}
      >
        <Button
          style={{
            alignSelf: 'center',
            marginVertical: 24,
            height: 48,
            width: 280,
          }}
          onPress={onPress}
        >
          {t('保存する')}
        </Button>
      </View>
    </View>
  )
}
interface EditDailyReservationNoteModalProps {
  restaurantId: number
  date: dayjs.Dayjs
  isModalVisible: boolean
  onClose: () => void
}

export default function EditDailyReservationNoteModal({
  restaurantId,
  date,
  isModalVisible,
  onClose,
}: EditDailyReservationNoteModalProps) {
  const token = useToken()
  const prevIsModalVisible = usePrevious(isModalVisible)

  const { data, mutate } = useRestaurantDailyReservationNote(restaurantId, date)
  const [salesNote, setSalesNote, resetSalesNote] = useFormState(
    data?.content ?? ''
  )

  // モーダルを開いた時に、登録されたSalesNoteを初期値としてセットする
  useEffect(() => {
    if (isModalVisible !== prevIsModalVisible && isModalVisible) {
      resetSalesNote()
    }
  }, [isModalVisible, prevIsModalVisible, resetSalesNote])

  const onPress = async () => {
    const { error } = await updateRestaurantDailyReservationNote(
      token,
      restaurantId,
      date,
      {
        content: salesNote,
      }
    )
    await mutate()

    if (error != null) return
    onClose()
  }

  return (
    <ModalCenter
      title={t('本日のメモを編集')}
      isModalVisible={isModalVisible}
      onClose={onClose}
      width="60%"
      minHeight={330}
    >
      {Platform.OS === 'web' ? (
        <Contents
          salesNote={salesNote}
          setSalesNote={setSalesNote}
          onPress={onPress}
        />
      ) : (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <Contents
            salesNote={salesNote}
            setSalesNote={setSalesNote}
            onPress={onPress}
          />
        </TouchableWithoutFeedback>
      )}
    </ModalCenter>
  )
}
