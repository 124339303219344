import React from 'react'

import {
  RefreshControl as RNRefreshControl,
  RefreshControlProps,
} from 'react-native'
import { Colors } from '../constants/Colors'

export function RefreshControl(props: RefreshControlProps) {
  return <RNRefreshControl tintColor={Colors.primary} {...props} />
}
