// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service.proto" (package "auto_reserve.restaurants.restaurant_course_question", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantCourseQuestionSelectionResource_Status } from "../restaurant_course/restaurant_course_question_resource";
import { StringValue } from "../../../google/protobuf/wrappers";
import { RestaurantCourseQuestionResource_Category } from "../restaurant_course/restaurant_course_question_resource";
import { RestaurantCourseQuestionResource } from "../restaurant_course/restaurant_course_question_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: int32 page = 2;
   */
  page: number;
  /**
   * @generated from protobuf field: int32 per_page = 3;
   */
  perPage: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource restaurant_course_questions = 1;
   */
  restaurantCourseQuestions: RestaurantCourseQuestionResource[];
  /**
   * @generated from protobuf field: int32 total_count = 2;
   */
  totalCount: number;
  /**
   * @generated from protobuf field: int32 total_page_count = 3;
   */
  totalPageCount: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 2;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: string description = 4;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 5;
   */
  category: RestaurantCourseQuestionResource_Category;
  /**
   * @generated from protobuf field: bool is_required = 6;
   */
  isRequired: boolean;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course_question.CreateRequest.QuestionSelectionResource selections = 7;
   */
  selections: CreateRequest_QuestionSelectionResource[];
  /**
   * @generated from protobuf field: string description_i18n_ja = 8 [json_name = "descriptionI18nJa"];
   */
  descriptionI18NJa: string;
  /**
   * @generated from protobuf field: string description_i18n_en = 9 [json_name = "descriptionI18nEn"];
   */
  descriptionI18NEn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_cn = 10 [json_name = "descriptionI18nZhCn"];
   */
  descriptionI18NZhCn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_tw = 11 [json_name = "descriptionI18nZhTw"];
   */
  descriptionI18NZhTw: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_hk = 12 [json_name = "descriptionI18nZhHk"];
   */
  descriptionI18NZhHk: string;
  /**
   * @generated from protobuf field: string description_i18n_ko = 13 [json_name = "descriptionI18nKo"];
   */
  descriptionI18NKo: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.CreateRequest.QuestionSelectionResource
 */
export interface CreateRequest_QuestionSelectionResource {
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: string description_i18n_ja = 3 [json_name = "descriptionI18nJa"];
   */
  descriptionI18NJa: string;
  /**
   * @generated from protobuf field: string description_i18n_en = 4 [json_name = "descriptionI18nEn"];
   */
  descriptionI18NEn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_cn = 5 [json_name = "descriptionI18nZhCn"];
   */
  descriptionI18NZhCn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_tw = 6 [json_name = "descriptionI18nZhTw"];
   */
  descriptionI18NZhTw: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_hk = 7 [json_name = "descriptionI18nZhHk"];
   */
  descriptionI18NZhHk: string;
  /**
   * @generated from protobuf field: string description_i18n_ko = 8 [json_name = "descriptionI18nKo"];
   */
  descriptionI18NKo: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: string description = 4;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 5;
   */
  category: RestaurantCourseQuestionResource_Category;
  /**
   * @generated from protobuf field: bool is_required = 6;
   */
  isRequired: boolean;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course_question.UpdateRequest.QuestionSelectionResource selections = 7;
   */
  selections: UpdateRequest_QuestionSelectionResource[];
  /**
   * @generated from protobuf field: string description_i18n_ja = 8 [json_name = "descriptionI18nJa"];
   */
  descriptionI18NJa: string;
  /**
   * @generated from protobuf field: string description_i18n_en = 9 [json_name = "descriptionI18nEn"];
   */
  descriptionI18NEn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_cn = 10 [json_name = "descriptionI18nZhCn"];
   */
  descriptionI18NZhCn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_tw = 11 [json_name = "descriptionI18nZhTw"];
   */
  descriptionI18NZhTw: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_hk = 12 [json_name = "descriptionI18nZhHk"];
   */
  descriptionI18NZhHk: string;
  /**
   * @generated from protobuf field: string description_i18n_ko = 13 [json_name = "descriptionI18nKo"];
   */
  descriptionI18NKo: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.UpdateRequest.QuestionSelectionResource
 */
export interface UpdateRequest_QuestionSelectionResource {
  /**
   * @generated from protobuf field: google.protobuf.StringValue id = 1;
   */
  id?: StringValue;
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status = 3;
   */
  status: RestaurantCourseQuestionSelectionResource_Status;
  /**
   * @generated from protobuf field: string description_i18n_ja = 4 [json_name = "descriptionI18nJa"];
   */
  descriptionI18NJa: string;
  /**
   * @generated from protobuf field: string description_i18n_en = 5 [json_name = "descriptionI18nEn"];
   */
  descriptionI18NEn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_cn = 6 [json_name = "descriptionI18nZhCn"];
   */
  descriptionI18NZhCn: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_tw = 7 [json_name = "descriptionI18nZhTw"];
   */
  descriptionI18NZhTw: string;
  /**
   * @generated from protobuf field: string description_i18n_zh_hk = 8 [json_name = "descriptionI18nZhHk"];
   */
  descriptionI18NZhHk: string;
  /**
   * @generated from protobuf field: string description_i18n_ko = 9 [json_name = "descriptionI18nKo"];
   */
  descriptionI18NKo: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course_question.ArchiveRequest
 */
export interface ArchiveRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course_question.ListRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: "per_page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { restaurantId: 0, page: 0, perPage: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* int32 page */ 2:
          message.page = reader.int32();
          break;
        case /* int32 per_page */ 3:
          message.perPage = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* int32 page = 2; */
    if (message.page !== 0) writer.tag(2, WireType.Varint).int32(message.page);
    /* int32 per_page = 3; */
    if (message.perPage !== 0)
      writer.tag(3, WireType.Varint).int32(message.perPage);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course_question.ListResponse", [
      {
        no: 1,
        name: "restaurant_course_questions",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantCourseQuestionResource,
      },
      { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: "total_page_count",
        kind: "scalar",
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = {
      restaurantCourseQuestions: [],
      totalCount: 0,
      totalPageCount: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource restaurant_course_questions */ 1:
          message.restaurantCourseQuestions.push(
            RestaurantCourseQuestionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* int32 total_count */ 2:
          message.totalCount = reader.int32();
          break;
        case /* int32 total_page_count */ 3:
          message.totalPageCount = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource restaurant_course_questions = 1; */
    for (let i = 0; i < message.restaurantCourseQuestions.length; i++)
      RestaurantCourseQuestionResource.internalBinaryWrite(
        message.restaurantCourseQuestions[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int32 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int32(message.totalCount);
    /* int32 total_page_count = 3; */
    if (message.totalPageCount !== 0)
      writer.tag(3, WireType.Varint).int32(message.totalPageCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course_question.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course_question.CreateRequest", [
      {
        no: 2,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "description",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: "category",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category",
          RestaurantCourseQuestionResource_Category,
        ],
      },
      { no: 6, name: "is_required", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: "selections",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreateRequest_QuestionSelectionResource,
      },
      {
        no: 8,
        name: "description_i18n_ja",
        kind: "scalar",
        jsonName: "descriptionI18nJa",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: "description_i18n_en",
        kind: "scalar",
        jsonName: "descriptionI18nEn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: "description_i18n_zh_cn",
        kind: "scalar",
        jsonName: "descriptionI18nZhCn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: "description_i18n_zh_tw",
        kind: "scalar",
        jsonName: "descriptionI18nZhTw",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: "description_i18n_zh_hk",
        kind: "scalar",
        jsonName: "descriptionI18nZhHk",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: "description_i18n_ko",
        kind: "scalar",
        jsonName: "descriptionI18nKo",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      name: "",
      description: "",
      category: 0,
      isRequired: false,
      selections: [],
      descriptionI18NJa: "",
      descriptionI18NEn: "",
      descriptionI18NZhCn: "",
      descriptionI18NZhTw: "",
      descriptionI18NZhHk: "",
      descriptionI18NKo: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 2:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        case /* string description */ 4:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category */ 5:
          message.category = reader.int32();
          break;
        case /* bool is_required */ 6:
          message.isRequired = reader.bool();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course_question.CreateRequest.QuestionSelectionResource selections */ 7:
          message.selections.push(
            CreateRequest_QuestionSelectionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string description_i18n_ja = 8 [json_name = "descriptionI18nJa"];*/ 8:
          message.descriptionI18NJa = reader.string();
          break;
        case /* string description_i18n_en = 9 [json_name = "descriptionI18nEn"];*/ 9:
          message.descriptionI18NEn = reader.string();
          break;
        case /* string description_i18n_zh_cn = 10 [json_name = "descriptionI18nZhCn"];*/ 10:
          message.descriptionI18NZhCn = reader.string();
          break;
        case /* string description_i18n_zh_tw = 11 [json_name = "descriptionI18nZhTw"];*/ 11:
          message.descriptionI18NZhTw = reader.string();
          break;
        case /* string description_i18n_zh_hk = 12 [json_name = "descriptionI18nZhHk"];*/ 12:
          message.descriptionI18NZhHk = reader.string();
          break;
        case /* string description_i18n_ko = 13 [json_name = "descriptionI18nKo"];*/ 13:
          message.descriptionI18NKo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 2; */
    if (message.restaurantId !== 0)
      writer.tag(2, WireType.Varint).int64(message.restaurantId);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    /* string description = 4; */
    if (message.description !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 5; */
    if (message.category !== 0)
      writer.tag(5, WireType.Varint).int32(message.category);
    /* bool is_required = 6; */
    if (message.isRequired !== false)
      writer.tag(6, WireType.Varint).bool(message.isRequired);
    /* repeated auto_reserve.restaurants.restaurant_course_question.CreateRequest.QuestionSelectionResource selections = 7; */
    for (let i = 0; i < message.selections.length; i++)
      CreateRequest_QuestionSelectionResource.internalBinaryWrite(
        message.selections[i],
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description_i18n_ja = 8 [json_name = "descriptionI18nJa"]; */
    if (message.descriptionI18NJa !== "")
      writer.tag(8, WireType.LengthDelimited).string(message.descriptionI18NJa);
    /* string description_i18n_en = 9 [json_name = "descriptionI18nEn"]; */
    if (message.descriptionI18NEn !== "")
      writer.tag(9, WireType.LengthDelimited).string(message.descriptionI18NEn);
    /* string description_i18n_zh_cn = 10 [json_name = "descriptionI18nZhCn"]; */
    if (message.descriptionI18NZhCn !== "")
      writer
        .tag(10, WireType.LengthDelimited)
        .string(message.descriptionI18NZhCn);
    /* string description_i18n_zh_tw = 11 [json_name = "descriptionI18nZhTw"]; */
    if (message.descriptionI18NZhTw !== "")
      writer
        .tag(11, WireType.LengthDelimited)
        .string(message.descriptionI18NZhTw);
    /* string description_i18n_zh_hk = 12 [json_name = "descriptionI18nZhHk"]; */
    if (message.descriptionI18NZhHk !== "")
      writer
        .tag(12, WireType.LengthDelimited)
        .string(message.descriptionI18NZhHk);
    /* string description_i18n_ko = 13 [json_name = "descriptionI18nKo"]; */
    if (message.descriptionI18NKo !== "")
      writer
        .tag(13, WireType.LengthDelimited)
        .string(message.descriptionI18NKo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest_QuestionSelectionResource$Type extends MessageType<CreateRequest_QuestionSelectionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course_question.CreateRequest.QuestionSelectionResource",
      [
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: "description_i18n_ja",
          kind: "scalar",
          jsonName: "descriptionI18nJa",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 4,
          name: "description_i18n_en",
          kind: "scalar",
          jsonName: "descriptionI18nEn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 5,
          name: "description_i18n_zh_cn",
          kind: "scalar",
          jsonName: "descriptionI18nZhCn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 6,
          name: "description_i18n_zh_tw",
          kind: "scalar",
          jsonName: "descriptionI18nZhTw",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: "description_i18n_zh_hk",
          kind: "scalar",
          jsonName: "descriptionI18nZhHk",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 8,
          name: "description_i18n_ko",
          kind: "scalar",
          jsonName: "descriptionI18nKo",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateRequest_QuestionSelectionResource>,
  ): CreateRequest_QuestionSelectionResource {
    const message = {
      description: "",
      descriptionI18NJa: "",
      descriptionI18NEn: "",
      descriptionI18NZhCn: "",
      descriptionI18NZhTw: "",
      descriptionI18NZhHk: "",
      descriptionI18NKo: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest_QuestionSelectionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest_QuestionSelectionResource,
  ): CreateRequest_QuestionSelectionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* string description_i18n_ja = 3 [json_name = "descriptionI18nJa"];*/ 3:
          message.descriptionI18NJa = reader.string();
          break;
        case /* string description_i18n_en = 4 [json_name = "descriptionI18nEn"];*/ 4:
          message.descriptionI18NEn = reader.string();
          break;
        case /* string description_i18n_zh_cn = 5 [json_name = "descriptionI18nZhCn"];*/ 5:
          message.descriptionI18NZhCn = reader.string();
          break;
        case /* string description_i18n_zh_tw = 6 [json_name = "descriptionI18nZhTw"];*/ 6:
          message.descriptionI18NZhTw = reader.string();
          break;
        case /* string description_i18n_zh_hk = 7 [json_name = "descriptionI18nZhHk"];*/ 7:
          message.descriptionI18NZhHk = reader.string();
          break;
        case /* string description_i18n_ko = 8 [json_name = "descriptionI18nKo"];*/ 8:
          message.descriptionI18NKo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest_QuestionSelectionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* string description_i18n_ja = 3 [json_name = "descriptionI18nJa"]; */
    if (message.descriptionI18NJa !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.descriptionI18NJa);
    /* string description_i18n_en = 4 [json_name = "descriptionI18nEn"]; */
    if (message.descriptionI18NEn !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.descriptionI18NEn);
    /* string description_i18n_zh_cn = 5 [json_name = "descriptionI18nZhCn"]; */
    if (message.descriptionI18NZhCn !== "")
      writer
        .tag(5, WireType.LengthDelimited)
        .string(message.descriptionI18NZhCn);
    /* string description_i18n_zh_tw = 6 [json_name = "descriptionI18nZhTw"]; */
    if (message.descriptionI18NZhTw !== "")
      writer
        .tag(6, WireType.LengthDelimited)
        .string(message.descriptionI18NZhTw);
    /* string description_i18n_zh_hk = 7 [json_name = "descriptionI18nZhHk"]; */
    if (message.descriptionI18NZhHk !== "")
      writer
        .tag(7, WireType.LengthDelimited)
        .string(message.descriptionI18NZhHk);
    /* string description_i18n_ko = 8 [json_name = "descriptionI18nKo"]; */
    if (message.descriptionI18NKo !== "")
      writer.tag(8, WireType.LengthDelimited).string(message.descriptionI18NKo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.CreateRequest.QuestionSelectionResource
 */
export const CreateRequest_QuestionSelectionResource =
  new CreateRequest_QuestionSelectionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course_question.UpdateRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "description",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: "category",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category",
          RestaurantCourseQuestionResource_Category,
        ],
      },
      { no: 6, name: "is_required", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: "selections",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UpdateRequest_QuestionSelectionResource,
      },
      {
        no: 8,
        name: "description_i18n_ja",
        kind: "scalar",
        jsonName: "descriptionI18nJa",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: "description_i18n_en",
        kind: "scalar",
        jsonName: "descriptionI18nEn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: "description_i18n_zh_cn",
        kind: "scalar",
        jsonName: "descriptionI18nZhCn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: "description_i18n_zh_tw",
        kind: "scalar",
        jsonName: "descriptionI18nZhTw",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: "description_i18n_zh_hk",
        kind: "scalar",
        jsonName: "descriptionI18nZhHk",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: "description_i18n_ko",
        kind: "scalar",
        jsonName: "descriptionI18nKo",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      name: "",
      description: "",
      category: 0,
      isRequired: false,
      selections: [],
      descriptionI18NJa: "",
      descriptionI18NEn: "",
      descriptionI18NZhCn: "",
      descriptionI18NZhTw: "",
      descriptionI18NZhHk: "",
      descriptionI18NKo: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        case /* string description */ 4:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category */ 5:
          message.category = reader.int32();
          break;
        case /* bool is_required */ 6:
          message.isRequired = reader.bool();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course_question.UpdateRequest.QuestionSelectionResource selections */ 7:
          message.selections.push(
            UpdateRequest_QuestionSelectionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* string description_i18n_ja = 8 [json_name = "descriptionI18nJa"];*/ 8:
          message.descriptionI18NJa = reader.string();
          break;
        case /* string description_i18n_en = 9 [json_name = "descriptionI18nEn"];*/ 9:
          message.descriptionI18NEn = reader.string();
          break;
        case /* string description_i18n_zh_cn = 10 [json_name = "descriptionI18nZhCn"];*/ 10:
          message.descriptionI18NZhCn = reader.string();
          break;
        case /* string description_i18n_zh_tw = 11 [json_name = "descriptionI18nZhTw"];*/ 11:
          message.descriptionI18NZhTw = reader.string();
          break;
        case /* string description_i18n_zh_hk = 12 [json_name = "descriptionI18nZhHk"];*/ 12:
          message.descriptionI18NZhHk = reader.string();
          break;
        case /* string description_i18n_ko = 13 [json_name = "descriptionI18nKo"];*/ 13:
          message.descriptionI18NKo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    /* string description = 4; */
    if (message.description !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 5; */
    if (message.category !== 0)
      writer.tag(5, WireType.Varint).int32(message.category);
    /* bool is_required = 6; */
    if (message.isRequired !== false)
      writer.tag(6, WireType.Varint).bool(message.isRequired);
    /* repeated auto_reserve.restaurants.restaurant_course_question.UpdateRequest.QuestionSelectionResource selections = 7; */
    for (let i = 0; i < message.selections.length; i++)
      UpdateRequest_QuestionSelectionResource.internalBinaryWrite(
        message.selections[i],
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description_i18n_ja = 8 [json_name = "descriptionI18nJa"]; */
    if (message.descriptionI18NJa !== "")
      writer.tag(8, WireType.LengthDelimited).string(message.descriptionI18NJa);
    /* string description_i18n_en = 9 [json_name = "descriptionI18nEn"]; */
    if (message.descriptionI18NEn !== "")
      writer.tag(9, WireType.LengthDelimited).string(message.descriptionI18NEn);
    /* string description_i18n_zh_cn = 10 [json_name = "descriptionI18nZhCn"]; */
    if (message.descriptionI18NZhCn !== "")
      writer
        .tag(10, WireType.LengthDelimited)
        .string(message.descriptionI18NZhCn);
    /* string description_i18n_zh_tw = 11 [json_name = "descriptionI18nZhTw"]; */
    if (message.descriptionI18NZhTw !== "")
      writer
        .tag(11, WireType.LengthDelimited)
        .string(message.descriptionI18NZhTw);
    /* string description_i18n_zh_hk = 12 [json_name = "descriptionI18nZhHk"]; */
    if (message.descriptionI18NZhHk !== "")
      writer
        .tag(12, WireType.LengthDelimited)
        .string(message.descriptionI18NZhHk);
    /* string description_i18n_ko = 13 [json_name = "descriptionI18nKo"]; */
    if (message.descriptionI18NKo !== "")
      writer
        .tag(13, WireType.LengthDelimited)
        .string(message.descriptionI18NKo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest_QuestionSelectionResource$Type extends MessageType<UpdateRequest_QuestionSelectionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course_question.UpdateRequest.QuestionSelectionResource",
      [
        { no: 1, name: "id", kind: "message", T: () => StringValue },
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status",
            RestaurantCourseQuestionSelectionResource_Status,
          ],
        },
        {
          no: 4,
          name: "description_i18n_ja",
          kind: "scalar",
          jsonName: "descriptionI18nJa",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 5,
          name: "description_i18n_en",
          kind: "scalar",
          jsonName: "descriptionI18nEn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 6,
          name: "description_i18n_zh_cn",
          kind: "scalar",
          jsonName: "descriptionI18nZhCn",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: "description_i18n_zh_tw",
          kind: "scalar",
          jsonName: "descriptionI18nZhTw",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 8,
          name: "description_i18n_zh_hk",
          kind: "scalar",
          jsonName: "descriptionI18nZhHk",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 9,
          name: "description_i18n_ko",
          kind: "scalar",
          jsonName: "descriptionI18nKo",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateRequest_QuestionSelectionResource>,
  ): UpdateRequest_QuestionSelectionResource {
    const message = {
      description: "",
      status: 0,
      descriptionI18NJa: "",
      descriptionI18NEn: "",
      descriptionI18NZhCn: "",
      descriptionI18NZhTw: "",
      descriptionI18NZhHk: "",
      descriptionI18NKo: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest_QuestionSelectionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest_QuestionSelectionResource,
  ): UpdateRequest_QuestionSelectionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue id */ 1:
          message.id = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.id,
          );
          break;
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status */ 3:
          message.status = reader.int32();
          break;
        case /* string description_i18n_ja = 4 [json_name = "descriptionI18nJa"];*/ 4:
          message.descriptionI18NJa = reader.string();
          break;
        case /* string description_i18n_en = 5 [json_name = "descriptionI18nEn"];*/ 5:
          message.descriptionI18NEn = reader.string();
          break;
        case /* string description_i18n_zh_cn = 6 [json_name = "descriptionI18nZhCn"];*/ 6:
          message.descriptionI18NZhCn = reader.string();
          break;
        case /* string description_i18n_zh_tw = 7 [json_name = "descriptionI18nZhTw"];*/ 7:
          message.descriptionI18NZhTw = reader.string();
          break;
        case /* string description_i18n_zh_hk = 8 [json_name = "descriptionI18nZhHk"];*/ 8:
          message.descriptionI18NZhHk = reader.string();
          break;
        case /* string description_i18n_ko = 9 [json_name = "descriptionI18nKo"];*/ 9:
          message.descriptionI18NKo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest_QuestionSelectionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue id = 1; */
    if (message.id)
      StringValue.internalBinaryWrite(
        message.id,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status = 3; */
    if (message.status !== 0)
      writer.tag(3, WireType.Varint).int32(message.status);
    /* string description_i18n_ja = 4 [json_name = "descriptionI18nJa"]; */
    if (message.descriptionI18NJa !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.descriptionI18NJa);
    /* string description_i18n_en = 5 [json_name = "descriptionI18nEn"]; */
    if (message.descriptionI18NEn !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.descriptionI18NEn);
    /* string description_i18n_zh_cn = 6 [json_name = "descriptionI18nZhCn"]; */
    if (message.descriptionI18NZhCn !== "")
      writer
        .tag(6, WireType.LengthDelimited)
        .string(message.descriptionI18NZhCn);
    /* string description_i18n_zh_tw = 7 [json_name = "descriptionI18nZhTw"]; */
    if (message.descriptionI18NZhTw !== "")
      writer
        .tag(7, WireType.LengthDelimited)
        .string(message.descriptionI18NZhTw);
    /* string description_i18n_zh_hk = 8 [json_name = "descriptionI18nZhHk"]; */
    if (message.descriptionI18NZhHk !== "")
      writer
        .tag(8, WireType.LengthDelimited)
        .string(message.descriptionI18NZhHk);
    /* string description_i18n_ko = 9 [json_name = "descriptionI18nKo"]; */
    if (message.descriptionI18NKo !== "")
      writer.tag(9, WireType.LengthDelimited).string(message.descriptionI18NKo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.UpdateRequest.QuestionSelectionResource
 */
export const UpdateRequest_QuestionSelectionResource =
  new UpdateRequest_QuestionSelectionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ArchiveRequest$Type extends MessageType<ArchiveRequest> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course_question.ArchiveRequest",
      [{ no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }],
    );
  }
  create(value?: PartialMessage<ArchiveRequest>): ArchiveRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ArchiveRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ArchiveRequest,
  ): ArchiveRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ArchiveRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course_question.ArchiveRequest
 */
export const ArchiveRequest = new ArchiveRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.restaurant_course_question.RestaurantCourseQuestion
 */
export const RestaurantCourseQuestion = new ServiceType(
  "auto_reserve.restaurants.restaurant_course_question.RestaurantCourseQuestion",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    {
      name: "Get",
      options: {},
      I: GetRequest,
      O: RestaurantCourseQuestionResource,
    },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: RestaurantCourseQuestionResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: RestaurantCourseQuestionResource,
    },
    { name: "Archive", options: {}, I: ArchiveRequest, O: Empty },
  ],
);
