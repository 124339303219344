import React from 'react'
import { View, Image } from 'react-native'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import numberWithDelimiter from 'modules/numberWithDelimiter'

import { TableUnlimitedOrderPlan as TableUnlimitedOrderPlanModel } from 'models/TableUnlimitedOrderPlan'

import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function TableUnlimitedOrderPlan({
  onPress,
  tableUnlimitedOrderPlan,
  locked,
}: {
  tableUnlimitedOrderPlan: TableUnlimitedOrderPlanModel
  locked: boolean
  onPress: () => void
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={locked}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {tableUnlimitedOrderPlan?.image !== null && (
          <Image
            source={{ uri: tableUnlimitedOrderPlan?.image?.sm }}
            style={{
              width: 80,
              height: 80,
              borderRadius: 4,
              marginRight: 16,
            }}
          />
        )}
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={{ fontWeight: '600' }}>
            {tableUnlimitedOrderPlan.name}
          </Text>
          <Text style={{ marginTop: 8 }}>
            {tableUnlimitedOrderPlan?.table_menus.map((tableMenu, index) => {
              return (
                <Text key={index}>
                  {index === 0 ? '' : ' '}
                  <Text>{tableMenu.name}</Text>
                </Text>
              )
            })}
          </Text>
          <View style={{ flexDirection: 'row', marginTop: 8 }}>
            <Text style={{}}>
              {t('{{price}}円 ({{taxIncluded}})／{{duration}}分', {
                price: numberWithDelimiter(tableUnlimitedOrderPlan.price),
                taxIncluded: tableUnlimitedOrderPlan.tax_included
                  ? t('税込')
                  : t('税抜'),
                duration: tableUnlimitedOrderPlan.duration / 60,
              })}
            </Text>
          </View>
        </View>
      </View>
      {locked && (
        <View>
          <FontAwesomeIcon icon={faLockAlt} color={Colors.black60} size={16} />
        </View>
      )}
      <View
        style={{
          position: 'absolute',
          top: -6,
          right: -14,
        }}
      >
        <Text style={{ fontSize: 14, color: Colors.black60 }}>
          id: {tableUnlimitedOrderPlan.id}
        </Text>
      </View>
    </TouchableOpacity>
  )
}
