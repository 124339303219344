import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { View } from 'react-native'
import {
  rejectionRequestReservation,
  useRestaurantRequestReservation,
} from '@hello-ai/for_r_app/src/models/RestaurantRequestReservation'
import { useRestaurantId } from 'modules/useRestaurantId'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { DetailModalContent } from '@hello-ai/for_r_app/src/components/Reservation/RequestView/DetailModalContent'
import RejectModal from '@hello-ai/for_r_app/src/components/Reservation/RequestView/RejectModal'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

function RequestReservationShowPage() {
  const token = useToken()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { restaurantRequestReservationId } = useParams<{
    restaurantRequestReservationId: string
  }>()
  const { requestReservation: data } = useRestaurantRequestReservation(
    restaurantId,
    parseInt(restaurantRequestReservationId!)
  )

  const isInPossibleRange =
    data != null
      ? data.start_at === data.start_at_min &&
        data.start_at === data.start_at_max
      : false

  const onPressApproval = () => {
    navigate(
      `/restaurants/${restaurantId}/reservations/requests/${restaurantRequestReservationId}/form?skipSteps=${
        isInPossibleRange ? '1,3' : '3'
      }`
    )
  }

  return (
    <View>
      <View>
        <DetailModalContent
          restaurantId={restaurantId}
          data={data}
          isInPossibleRange={isInPossibleRange}
          onPressApproval={onPressApproval}
          onPressRejection={() => setIsModalVisible(true)}
        />
      </View>
      <RejectModal
        isModalVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false)
        }}
        onPressRejection={async ({ cancelReason, otherCancelReason }) => {
          if (data == null || token == null) return
          console.log(cancelReason, otherCancelReason)
          await rejectionRequestReservation(token, restaurantId, data.id, {
            date: dayjs(data.start_at).format('YYYY-MM-DD'),
            cancel_reason:
              cancelReason ||
              t('{{reason}}ため', { reason: otherCancelReason }),
          })
          setIsModalVisible(false)
          navigate(-1)
        }}
      />
    </View>
  )
}

export default RequestReservationShowPage
