import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'

function useRefChange<T>(): [(node: T | null) => void, T | null] {
  const ref = useRef<T | null>(null)
  const [node, setNode] = useState<T | null>(null)

  const setRef = useCallback((node: T | null) => {
    if (ref.current !== node) {
      ref.current = node
      setNode(node)
    }
  }, [])

  return [setRef, node]
}

export interface PopoverContextValue {
  topBias: number
  leftBias: number
}

const PopoverContext = createContext<PopoverContextValue>({
  topBias: 0,
  leftBias: 0,
})
export const PopoverProvider = PopoverContext.Provider

type Args = {
  isOpen: boolean
  placement?: 'bottomStart' | 'bottomEnd'
}

export const usePopover = ({ isOpen, placement = 'bottomStart' }: Args) => {
  const [anchorMeasure, setAnchorMeasure] = useState<{
    x: number
    y: number
    width: number
    height: number
  }>()
  const [contentMeasure, setContentMeasure] = useState<{
    width: number
    height: number
  }>()
  const anchorRef = useRef<View | null>(null)
  const [setContentRef, contentNode] = useRefChange<View>()
  const { topBias, leftBias } = useContext(PopoverContext)

  useEffect(() => {
    if (anchorRef.current) {
      anchorRef.current.measureInWindow((x, y, width, height) => {
        setAnchorMeasure({ x, y, width, height })
      })
    }
  }, [isOpen])

  useEffect(() => {
    let canceled = false

    // そのまま contentNode.measureInWindow を呼ぶと width, height が 0 になるため setTimeout で遅延させる
    setTimeout(() => {
      if (contentNode != null) {
        contentNode.measureInWindow((_, __, width, height) => {
          if (canceled) {
            return
          }
          setContentMeasure({ width, height })
        })
      }
    }, 1)

    return () => {
      canceled = true
    }
  }, [contentNode])

  const popoverStyle = useMemo(():
    | { left: number; top: number }
    | undefined => {
    if (anchorMeasure && contentMeasure) {
      const baseTop = anchorMeasure.y + anchorMeasure.height + 30 + topBias
      if (placement === 'bottomStart') {
        // ▲をanchorの中心に持っていくため、anchorのxにanchorのwidth分を足す
        const baseLeft = anchorMeasure.x + anchorMeasure.width + leftBias
        return {
          left: baseLeft - contentMeasure.width,
          top: baseTop,
        }
      } else if (placement === 'bottomEnd') {
        const baseLeft = anchorMeasure.x - anchorMeasure.width + leftBias
        return {
          left: baseLeft,
          top: baseTop,
        }
      }
    }
  }, [anchorMeasure, contentMeasure, placement, topBias, leftBias])

  // ▲のコンポーネント
  const arrowElement = useMemo((): JSX.Element => {
    const baseChildStyles: StyleProp<ViewStyle> = {
      position: 'absolute',
      width: 20,
      height: 20,
      top: -10,
      bottom: -10,
      transform: [{ rotate: '45deg' }],
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOpacity: 0.2,
      shadowOffset: { width: 0, height: 2 },
      elevation: 4,
      right: placement === 'bottomStart' ? 20 : undefined,
      left: placement === 'bottomEnd' ? 20 : undefined,
    }

    const baseParentStyles: StyleProp<ViewStyle> = {
      width: 21,
      height: 20,
      bottom: -10,
      position: 'absolute',
      top: -6,
      backgroundColor: 'white',
      transform: [{ rotate: '45deg' }],
      zIndex: 10,
      right: placement === 'bottomStart' ? 19 : undefined,
      left: placement === 'bottomEnd' ? 21 : undefined,
    }

    return (
      <>
        <View style={baseChildStyles} />
        <View style={baseParentStyles} />
      </>
    )
  }, [placement])

  return {
    anchorRef,
    setContentRef,
    popoverStyle,
    arrowElement,
  }
}
