import React, { useEffect } from 'react'
import { Alert, ScrollView, StyleProp, View, ViewStyle } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

import { businessUserClient } from 'models/BusinessUser'
import { useAuthMe, useToken } from 'models/Auth'
import { useNavigate } from 'react-router'
import {
  displayToastError,
  displayToastInfo,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function FormSection({
  style,
  title,
  children,
}: {
  style?: StyleProp<ViewStyle>
  title: String
  children?: React.ReactNode
}) {
  return (
    <View style={style}>
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
      <ShadowBox style={{ marginTop: 22 }}>{children}</ShadowBox>
    </View>
  )
}

const formGroupMargin = 28

function validatePassword({
  currentPassword,
  newPassword,
  confirmNewPassword,
}: {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}) {
  // 新しいパスワードの確認が一致しない場合はボタンを押したときにアラートを出すので、ここではチェックしない
  return (
    currentPassword.length >= 6 &&
    newPassword.length >= 6 &&
    confirmNewPassword.length >= 6
  )
}

export default function AccountEdit() {
  const token = useToken()
  const { actor } = useAuthMe()
  const navigate = useNavigate()
  const businessUser =
    actor?.oneofKind === 'businessUser' ? actor.businessUser : undefined

  const [name, setName] = useFormState(businessUser?.name ?? '')
  const [email, setEmail] = useFormState(businessUser?.email ?? '')

  const [currentPassword, setCurrentPassword, resetCurrenntPassword] =
    useFormState('')
  const [newPassword, setNewPassword, resetNewPassword] = useFormState('')
  const [confirmNewPassword, setConfirmNewPassword, resetConfirmNewPassword] =
    useFormState('')

  useEffect(() => {
    if (actor !== undefined && actor.oneofKind !== 'businessUser') {
      displayToastError(t('メンバー以外はこのページから編集できません'))
      navigate('/')
    }
  }, [actor, navigate])

  const onChangeEmail = async () => {
    // useEffectでリダイレクトしているのでinvariantだとエラーになる可能性があるのでreturnする
    if (businessUser === undefined) return
    const { error } = await callRpc(
      businessUserClient.createChangeEmailRequest(
        {
          newEmail: email,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }

    displayToastInfo(t('メールアドレス宛に変更確認のURLを送信しました。'))
  }

  const onSavePassword = async () => {
    if (businessUser === undefined) return
    if (newPassword !== confirmNewPassword) {
      Alert.alert(t('確認のパスワードが一致しません'))
      setCurrentPassword('')
      setConfirmNewPassword('')
      return
    }
    const { error } = await callRpc(
      businessUserClient.updateCurrentBusinessUserPassword(
        {
          password: currentPassword,
          newPassword,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }

    resetCurrenntPassword()
    resetNewPassword()
    resetConfirmNewPassword()

    displayToastInfo(t('パスワードを変更しました'))
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <FormSection title={t('アカウント情報')}>
        <FormGroup formLabel={<FormLabel value={t('名前')} required />}>
          <TextInput
            style={{ flex: 1 }}
            placeholder={t('名前')}
            onChangeText={setName}
            value={name}
          />
        </FormGroup>
        <FormGroup
          formLabel={<FormLabel value={t('メールアドレス')} required />}
          style={{ marginTop: formGroupMargin }}
        >
          <TextInput
            style={{ flex: 1 }}
            placeholder={t('メールアドレス')}
            onChangeText={setEmail}
            value={email}
          />
        </FormGroup>
      </FormSection>
      <Button style={{ marginTop: 48 }} onPress={onChangeEmail}>
        {t('保存する')}
      </Button>
      <FormSection
        title={t('パスワードを変更')}
        style={{
          marginTop: 80,
        }}
      >
        <FormGroup
          formLabel={<FormLabel value={t('現在のパスワード')} required />}
        >
          <TextInput
            value={currentPassword}
            onChangeText={setCurrentPassword}
            clearButtonMode="always"
            placeholder={t('6文字以上')}
            secureTextEntry
          />
        </FormGroup>
        <FormGroup
          formLabel={<FormLabel value={t('新しいパスワード')} required />}
          style={{ marginTop: formGroupMargin }}
        >
          <TextInput
            value={newPassword}
            onChangeText={setNewPassword}
            clearButtonMode="always"
            placeholder={t('6文字以上')}
            secureTextEntry
          />
        </FormGroup>
        <FormGroup
          formLabel={<FormLabel value={t('新しいパスワード(確認)')} required />}
          style={{ marginTop: formGroupMargin }}
        >
          <TextInput
            value={confirmNewPassword}
            onChangeText={setConfirmNewPassword}
            clearButtonMode="always"
            placeholder={t('6文字以上')}
            secureTextEntry
          />
        </FormGroup>
      </FormSection>
      <Button
        style={{ marginTop: 48 }}
        onPress={onSavePassword}
        disabled={
          !validatePassword({
            currentPassword,
            newPassword,
            confirmNewPassword,
          })
        }
      >
        {t('保存する')}
      </Button>
    </ScrollView>
  )
}
