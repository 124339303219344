import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { tableSeatClient, useTableSeat } from 'models/TableSeat'
import { useTableSeatChargePolicies } from 'models/TableSeatChargePolicy'
import { useRestaurantId } from 'modules/useRestaurantId'
import React from 'react'
import { useNavigate, useParams } from 'react-router'

import {
  displayToastError,
  displayToastInfo,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { useToken } from 'models/Auth'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { TableSeatForm } from '@hello-ai/for_r_app/src/components/TableSeats/TableSeatForm'
import {
  ArchiveRequest,
  CreateRequest,
  UpdateRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_service'
import { TableSeatChargePolicyResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_resource'

export default function TableSeatsForm() {
  const restaurantId = useRestaurantId()
  const token = useToken()
  const navigate = useNavigate()

  const { tableSeatId } = useParams<{ tableSeatId?: string }>()

  const params =
    tableSeatId === undefined
      ? undefined
      : {
          restaurantId,
          id: tableSeatId,
        }
  const { tableSeat, mutate } = useTableSeat(params)
  const { tableSeatChargePolicies } = useTableSeatChargePolicies(restaurantId)

  const onCreate = async (params: CreateRequest) => {
    const { error } = await callRpc(
      tableSeatClient.create(params, rpcOptions({ token }))
    )
    if (error != null) {
      onError(error)
      return
    }
    navigate(`/restaurants/${restaurantId}/table_seats`)
    displayToastInfo(t('追加しました'))
  }

  const onUpdate = async (params: UpdateRequest) => {
    if (tableSeatId === undefined) return
    const { error } = await callRpc(
      tableSeatClient.update(params, rpcOptions({ token }))
    )
    if (error != null) {
      onError(error)
      return
    }
    navigate(`/restaurants/${restaurantId}/table_seats`)
    displayToastInfo(t('更新しました'))
    mutate()
  }

  const onAlertArchive = (params: ArchiveRequest) => {
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (tableSeatId === undefined) return
          const { error } = await callRpc(
            tableSeatClient.archive(params, rpcOptions({ token }))
          )
          if (error != null) {
            onError(error)
            return
          }
          navigate(`/restaurants/${restaurantId}/table_seats`)
          displayToastInfo(t('アーカイブしました'))
        },
      },
    ])
  }

  const onPressTableSeatChargePolicy = (
    tableSeatChargePolicy: TableSeatChargePolicyResource
  ) => {
    navigate(
      `/restaurants/${restaurantId}/table_seats/table_seat_charge_policies/${tableSeatChargePolicy.id}/edit`
    )
  }

  return (
    <TableSeatForm
      restaurantId={restaurantId}
      tableSeatId={tableSeatId}
      tableSeat={tableSeat}
      tableSeatChargePolicies={tableSeatChargePolicies}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onArchive={onAlertArchive}
      onPressTableSeatChargePolicy={onPressTableSeatChargePolicy}
      displayToastError={displayToastError}
    />
  )
}
