import React, { ReactNode, useRef, useState } from 'react'
import {
  Linking,
  Platform,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'

import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'

import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'

import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import {
  TableSeatResource,
  TableSeatResource_Category,
  TableSeatResource_SmokingStatus,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'
import {
  ArchiveRequest,
  CreateRequest,
  UpdateRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_service'
import { TableSeatChargePolicyResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_resource'
import { getCategoryName, getSmokingStatusName } from './common'

import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ButtonWithLoading from '../Shared/Button'

type TableSeatFormProps = {
  restaurantId: Restaurant['id']
  tableSeatId: TableSeat['id'] | undefined
  tableSeat: TableSeatResource | undefined
  tableSeatChargePolicies: TableSeatChargePolicyResource[] | undefined
  footerStyle?: StyleProp<ViewStyle>
  onCreate: (params: CreateRequest) => Promise<void>
  onUpdate: (params: UpdateRequest) => Promise<void>
  onArchive: (params: ArchiveRequest) => void
  onPressTableSeatChargePolicy: (
    tableSeatChargePolicy: TableSeatChargePolicyResource
  ) => void
  displayToastError: (message: string) => void
}

const formSpace = 24

function validate({
  name,
  position,
  parsedIntPosition,
  minPartySize,
  maxPartySize,
  parsedIntMinPartySize,
  parsedIntMaxPartySize,
}: {
  name: string
  position: string
  parsedIntPosition: number
  minPartySize: string
  maxPartySize: string
  parsedIntMinPartySize: number
  parsedIntMaxPartySize: number
}) {
  const errorMessages = []

  if (name === '') {
    errorMessages.push(t('席名を入力してください'))
  }
  if (position === '') {
    errorMessages.push(t('位置を入力してください'))
  }
  if (isNaN(parsedIntPosition)) {
    errorMessages.push(t('位置を半角数字で入力してください'))
  }
  if (!!minPartySize && isNaN(parsedIntMinPartySize)) {
    errorMessages.push(t('最小人数を半角数字で入力してください'))
  }
  if (!!maxPartySize && isNaN(parsedIntMaxPartySize)) {
    errorMessages.push(t('最大人数を半角数字で入力してください'))
  }

  return { errorMessages }
}

function Scroll({ children }: { children: ReactNode }) {
  const { width, sm } = useResponsive()
  return Platform.OS === 'web' ? (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        paddingHorizontal: 32,
        paddingVertical: 48,
      }}
    >
      {children}
    </ScrollView>
  ) : (
    <KeyboardAwareScrollView
      contentContainerStyle={{
        paddingHorizontal: width < sm ? 20 : 36,
        paddingVertical: width < sm ? 24 : 32,
      }}
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
    >
      {children}
    </KeyboardAwareScrollView>
  )
}

function TableSeatFooter({
  tableSeatId,
  isUpdating,
  onPressArchive,
  onPressUpdate,
  onPressCreate,
  style,
}: {
  tableSeatId: string | undefined
  isUpdating: boolean
  onPressArchive: () => void
  onPressUpdate: () => void
  onPressCreate: () => void
  style: StyleProp<ViewStyle>
}) {
  const { width, sm } = useResponsive()
  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      {tableSeatId !== undefined && (
        <Button
          mode="outline"
          variant="danger-secondary"
          height={48}
          onPress={onPressArchive}
        >
          <Text style={{ color: Colors.caution }}>
            {width >= sm ? t('アーカイブする') : t('アーカイブ')}
          </Text>
        </Button>
      )}
      <ButtonWithLoading
        loading={isUpdating}
        buttonStyle={{
          flex: 1,
          marginLeft: 8,
          height: 48,
        }}
        textStyle={{
          fontWeight: '600',
          color: 'white',
        }}
        onPress={tableSeatId !== undefined ? onPressUpdate : onPressCreate}
        text={tableSeatId !== undefined ? t('更新する') : t('追加する')}
      />
    </View>
  )
}

const handlePress = () => {
  Linking.openURL(
    'https://help-for-restaurants.autoreserve.com/hc/ja/articles/18189093805721--%E9%87%8D%E8%A6%81-%E5%B8%AD%E6%8E%A5%E7%B6%9A%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3%E3%81%AE%E8%A8%AD%E5%AE%9A'
  )
}

export function TableSeatForm({
  restaurantId,
  tableSeatId,
  tableSeat,
  tableSeatChargePolicies,
  footerStyle,
  onCreate,
  onUpdate,
  onArchive,
  onPressTableSeatChargePolicy,
  displayToastError,
}: TableSeatFormProps) {
  const alertRef = useRef<AlertMethods>(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [name, setName] = useFormState(tableSeat?.name ?? '')
  const [minPartySize, setMinPartySize] = useFormState(
    tableSeat?.minPartySize?.value?.toString() ?? ''
  )
  const [maxPartySize, setMaxPartySize] = useFormState(
    tableSeat?.maxPartySize?.value?.toString() ?? ''
  )
  const [position, setPosition] = useFormState(
    tableSeat?.position?.toString() ?? ''
  )
  const [category, setCategory] = useFormState(
    tableSeat?.category?.value ?? TableSeatResource_Category.TABLE_SEAT
  )
  const [smokingStatus, setSmokingStatus] = useFormState(
    tableSeat?.smokingStatus?.value ??
      TableSeatResource_SmokingStatus.NO_SMOKING
  )

  const [seatConnection, setSeatConnection] = useFormState(true)

  const tableSeatChargePoliciesUsingTableSeatId =
    tableSeatChargePolicies?.filter((tableSeatChargePolicy) => {
      return tableSeatChargePolicy.tableSeats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      )
    }) ?? []

  const parsedIntPosition = parseInt(position)
  const parsedIntMinPartySize = parseInt(minPartySize)
  const parsedIntMaxPartySize = parseInt(maxPartySize)

  const { width, sm } = useResponsive()

  const formParams = {
    position: parsedIntPosition,
    name,
    category:
      category !== null
        ? {
            value: category,
          }
        : undefined,
    smokingStatus:
      smokingStatus !== null
        ? {
            value: smokingStatus,
          }
        : undefined,
    minPartySize:
      minPartySize !== ''
        ? {
            value: parsedIntMinPartySize,
          }
        : undefined,
    maxPartySize:
      maxPartySize !== ''
        ? {
            value: parsedIntMaxPartySize,
          }
        : undefined,
  }

  const onCreate_ = async () => {
    setIsUpdating(true)
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    const { errorMessages } = validate({
      name,
      position,
      parsedIntPosition,
      minPartySize,
      maxPartySize,
      parsedIntMinPartySize,
      parsedIntMaxPartySize,
    })

    if (errorMessages.length > 0) {
      alert(errorMessages[0])
      setIsUpdating(false)
      return
    }

    if (seatConnection && (minPartySize === '' || maxPartySize === '')) {
      displayToastError(
        t('接続パターンを自動で作成する場合は人数の入力が必要です')
      )
      return
    }

    const params: CreateRequest = {
      restaurantId,
      ...formParams,
      isSeatConnectionRequire: seatConnection,
    }

    onCreate(params).finally(() => setIsUpdating(false))
  }

  const onUpdate_ = async () => {
    if (tableSeatId === undefined) return
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    setIsUpdating(true)

    const { errorMessages } = validate({
      name,
      position,
      parsedIntPosition,
      minPartySize,
      maxPartySize,
      parsedIntMinPartySize,
      parsedIntMaxPartySize,
    })

    if (errorMessages.length > 0) {
      alert(errorMessages[0])
      setIsUpdating(false)
      return
    }

    const params: UpdateRequest = {
      restaurantId,
      id: tableSeatId,
      ...formParams,
    }

    onUpdate(params).finally(() => setIsUpdating(false))
  }

  const onArchive_ = async () => {
    if (tableSeatId === undefined) return
    const params: ArchiveRequest = {
      restaurantId,
      id: tableSeatId,
    }

    return onArchive(params)
  }

  return (
    <>
      <Scroll>
        <View>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>
            {t('基本項目')}
          </Text>
          <ShadowBox style={[{ marginTop: width >= sm ? 24 : 16 }]}>
            <FormGroup
              formLabel={<FormLabel value={t('席名')} required />}
              mode={width >= sm ? 'inline' : 'vertical'}
            >
              <TextInput
                onChangeText={setName}
                value={name}
                placeholder={t('席名を記入')}
                style={[
                  width < sm && { marginTop: -12, height: 48, fontSize: 14 },
                ]}
              />
            </FormGroup>
            <FormGroup
              formLabel={
                <View>
                  <FormLabel value={t('表示優先順')} required />
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.black60,
                      marginTop: 4,
                    }}
                  >
                    {t('注文画面での表示順を指定できます')}
                  </Text>
                </View>
              }
              style={{ marginTop: formSpace }}
              mode={width >= sm ? 'inline' : 'vertical'}
            >
              <TextInput
                value={position}
                onChangeText={setPosition}
                autoCapitalize="none"
                keyboardType="number-pad"
                placeholder="1"
                style={[
                  width < sm && { marginTop: -12, height: 48, fontSize: 14 },
                ]}
              />
            </FormGroup>
            {tableSeatId !== undefined && (
              <FormGroup
                style={{
                  marginTop: formSpace,
                }}
                formLabel={
                  <View
                    style={{
                      marginTop: width >= sm ? 28 : 0,
                      marginBottom: width >= sm ? 0 : -12,
                    }}
                  >
                    <FormLabel value={t('席チャージ')} />
                  </View>
                }
                mode={width >= sm ? 'inline-expanded' : 'vertical'}
              >
                {tableSeatChargePoliciesUsingTableSeatId?.map(
                  (tableSeatChargePolicy, index) => (
                    <View
                      key={tableSeatChargePolicy.id}
                      style={[
                        {
                          paddingTop: width >= sm ? 15 : 0,
                          paddingBottom: 15,
                        },
                        tableSeatChargePoliciesUsingTableSeatId.length - 1 !==
                          index && {
                          borderBottomWidth: 0.5,
                          borderBottomColor: Colors.border,
                        },
                      ]}
                    >
                      <TouchableOpacity
                        onPress={() =>
                          onPressTableSeatChargePolicy(tableSeatChargePolicy)
                        }
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View style={{ marginTop: 10 }}>
                          <Text style={{ fontWeight: '600' }}>
                            {tableSeatChargePolicy.description}
                          </Text>
                          <Text
                            style={{
                              fontSize: 16,
                              color: Colors.black60,
                            }}
                          >
                            {tableSeatChargePolicy.tableMenus
                              ?.map((tableMenu) => tableMenu.name)
                              .join(', ') ?? ''}
                          </Text>
                        </View>
                        <FontAwesomeIcon
                          size={12}
                          icon={faAngleRight}
                          color={Colors.black60}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                )}
              </FormGroup>
            )}
          </ShadowBox>
        </View>
        <View
          style={{
            marginTop: 48,
            marginBottom: width >= sm ? 40 : 60,
          }}
        >
          <Text style={{ fontSize: 22, fontWeight: '600' }}>
            {t('ネット予約')}
          </Text>
          <ShadowBox style={[{ marginTop: 24 }]}>
            <FormGroup
              formLabel={<FormLabel value={t('人数')} />}
              mode={width >= sm ? 'inline' : 'vertical'}
            >
              <View
                style={[
                  {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 8,
                  },
                ]}
              >
                <TextInput
                  placeholder={t('最小人数')}
                  value={minPartySize}
                  onChangeText={(text) => setMinPartySize(text)}
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  style={[
                    {
                      width: '45%',
                      marginRight: 10,
                    },
                    width < sm && {
                      marginRight: 0,
                      height: 48,
                      fontSize: 16,
                    },
                  ]}
                />
                <Text>{t('〜')}</Text>
                <TextInput
                  placeholder={t('最大人数')}
                  value={maxPartySize}
                  onChangeText={(text) => setMaxPartySize(text)}
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  style={[
                    {
                      width: '45%',
                      marginLeft: 10,
                    },
                    width < sm && {
                      marginLeft: 0,
                      height: 48,
                      fontSize: 16,
                    },
                  ]}
                />
              </View>
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value={t('席タイプ')} />}
              style={{ marginTop: formSpace }}
              mode={width >= sm ? 'inline' : 'vertical'}
            >
              {width >= sm ? (
                <RadioGroup
                  onChange={(changedCategory) => setCategory(changedCategory)}
                  value={category}
                  mode={'vertical'}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 8,
                    }}
                  >
                    {Object.values(TableSeatResource_Category)
                      .filter(
                        (value): value is TableSeatResource_Category =>
                          typeof value === 'number'
                      )
                      .map((i) => {
                        return (
                          <Radio
                            radioLabel={
                              <RadioLabel value={getCategoryName(i)} />
                            }
                            value={i}
                            key={i}
                            style={{
                              paddingVertical: 8,
                              width: '30%',
                            }}
                          />
                        )
                      })}
                  </View>
                </RadioGroup>
              ) : (
                <RadioGroup
                  onChange={(changedCategory) => setCategory(changedCategory)}
                  value={category}
                  mode={'vertical'}
                >
                  {Object.values(TableSeatResource_Category)
                    .filter(
                      (value): value is TableSeatResource_Category =>
                        typeof value === 'number'
                    )
                    .map((i) => {
                      return (
                        <Radio
                          radioLabel={<RadioLabel value={getCategoryName(i)} />}
                          value={i}
                          key={i}
                        />
                      )
                    })}
                </RadioGroup>
              )}
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value={t('タバコ')} />}
              style={{
                marginTop: formSpace,
                marginBottom: tableSeatId === undefined ? formSpace : 0,
                paddingBottom: tableSeatId === undefined ? formSpace : 0,
                borderBottomWidth: tableSeatId === undefined ? 0.5 : 0,
                borderBottomColor: Colors.border,
              }}
              mode={width >= sm ? 'inline' : 'vertical'}
            >
              {width >= sm ? (
                <RadioGroup
                  onChange={(changedSmokingStatus) =>
                    setSmokingStatus(changedSmokingStatus)
                  }
                  value={smokingStatus}
                  mode={'vertical'}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 8,
                    }}
                  >
                    {Object.values(TableSeatResource_SmokingStatus)
                      .filter(
                        (value): value is TableSeatResource_SmokingStatus =>
                          typeof value === 'number'
                      )
                      .map((i) => {
                        return (
                          <Radio
                            radioLabel={
                              <RadioLabel value={getSmokingStatusName(i)} />
                            }
                            value={i}
                            key={i}
                            style={{
                              paddingVertical: 8,
                              width: '49%',
                            }}
                          />
                        )
                      })}
                  </View>
                </RadioGroup>
              ) : (
                <RadioGroup
                  onChange={(changedSmokingStatus) =>
                    setSmokingStatus(changedSmokingStatus)
                  }
                  value={smokingStatus}
                  mode={'vertical'}
                >
                  {Object.values(TableSeatResource_SmokingStatus)
                    .filter(
                      (value): value is TableSeatResource_SmokingStatus =>
                        typeof value === 'number'
                    )
                    .map((i) => {
                      return (
                        <Radio
                          radioLabel={
                            <RadioLabel value={getSmokingStatusName(i)} />
                          }
                          value={i}
                          key={i}
                        />
                      )
                    })}
                </RadioGroup>
              )}
            </FormGroup>
            {tableSeatId === undefined && (
              <View>
                <View>
                  <Checkbox
                    checkboxLabel={
                      <CheckboxLabel
                        style={{
                          fontWeight: '600',
                        }}
                        value={t('席単体の接続パターンを自動作成する')}
                      />
                    }
                    variant="right"
                    checked={seatConnection}
                    onChange={() => setSeatConnection(!seatConnection)}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: formSpace,
                    }}
                  />
                </View>
                <TouchableOpacity
                  onPress={handlePress}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 145,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExternalLink}
                    size={16}
                    color={Colors.primary}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.primary,
                      marginLeft: 8,
                    }}
                  >
                    {t('接続パターンとは？')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </ShadowBox>
        </View>

        {width >= sm ? (
          <TableSeatFooter
            isUpdating={isUpdating}
            tableSeatId={tableSeatId}
            onPressArchive={onArchive_}
            onPressUpdate={onUpdate_}
            onPressCreate={onCreate_}
            style={{ marginTop: 40 }}
          />
        ) : (
          <View style={{ height: 40 }} />
        )}
      </Scroll>
      {width < sm && (
        <TableSeatFooter
          isUpdating={isUpdating}
          tableSeatId={tableSeatId}
          onPressArchive={onArchive_}
          onPressUpdate={onUpdate_}
          onPressCreate={onCreate_}
          style={[
            {
              position: 'absolute',
              bottom: 0,
              backgroundColor: Colors.white,
              padding: 16,
              width: '100%',
            },
            footerStyle,
          ]}
        />
      )}
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </>
  )
}
