import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInputWithIcon } from '@hello-ai/ar_shared/src/components/TextInputWithIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useTableProductOptionBoxes } from 'models/TableProductOptionBox'
import { useThrottle } from '@hello-ai/for_r_app/src/modules/useThrottle'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useNavigate } from 'react-router-dom'
import TableProductOptionBox from 'components/Restaurant/TableProductOptionBox'
import { updateTableProductOptionStock } from '../../../models/TableProductOption'
import { useToken } from '../../../models/Auth'
import RoundedActionButton from 'components/Shared/RoundedActionButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function TableProductOptionBoxesScreen() {
  const { width, sm } = useResponsive()
  const token = useToken()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useThrottle(keyword)
  const [currentPage, setCurrentPage] = useState(1)

  const searchOptionBoxes = (text: string) => {
    setKeyword(text)
    setCurrentPage(1)
  }
  const { tableProductOptionBoxes, headerData, isLoading, mutate } =
    useTableProductOptionBoxes(restaurantId, {
      page: currentPage,
      keyword: debouncedKeyword,
    })

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      <RoundedActionButton
        onPress={() =>
          navigate(`/restaurants/${restaurantId}/product_option_boxes/new`)
        }
      >
        {t('オプションを追加')}
      </RoundedActionButton>
      <View
        style={[
          { flex: 1, marginTop: 24 },
          width < sm && {
            width: '100%',
            marginBottom: 24,
          },
        ]}
      >
        <TextInputWithIcon
          value={keyword}
          onChangeText={(text) => searchOptionBoxes(text)}
          autoCapitalize="none"
          placeholder={t('オプション名で検索')}
          icon={faSearch}
          style={{ backgroundColor: 'white', width: '100%', height: 48 }}
        />
      </View>
      <ShadowBox
        style={[
          {
            paddingHorizontal: 0,
            paddingVertical: 8,
            marginTop: 24,
          },
        ]}
      >
        {isLoading && (
          <View style={{ padding: 24 }}>
            <Loading />
          </View>
        )}
        {!isLoading && tableProductOptionBoxes.length === 0 && (
          <View
            style={{
              height: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>{t('検索結果がありません')}</Text>
          </View>
        )}
        {!isLoading &&
          tableProductOptionBoxes.map((productOptionBox, i) => (
            <View
              key={productOptionBox.id}
              style={[
                tableProductOptionBoxes.length - 1 !== i && {
                  borderBottomColor: Colors.border,
                  borderBottomWidth: 0.5,
                  borderStyle: 'solid',
                },
              ]}
            >
              <TableProductOptionBox
                key={productOptionBox.id}
                productOptionBox={productOptionBox}
                onPressOptionBox={(tableProductOptionBoxId) => {
                  navigate(
                    `/restaurants/${restaurantId}/product_option_boxes/${tableProductOptionBoxId}/edit`
                  )
                }}
                onChangeInStock={async (productOptionId, inStock) => {
                  if (token == null) return
                  await updateTableProductOptionStock({
                    token,
                    restaurantId,
                    productOptionId,
                    inStock,
                  })
                  await mutate()
                }}
              />
            </View>
          ))}
        <View
          style={{
            borderTopWidth: 0.5,
            borderColor: Colors.border,
            paddingTop: 24,
            marginBottom: 16,
          }}
        >
          <Pagination
            currentPage={currentPage}
            totalPage={headerData?.totalPages ?? 0}
            setPage={(page) => setCurrentPage(page)}
          />
        </View>
      </ShadowBox>
    </ScrollView>
  )
}
