import React, { useState } from 'react'
import { View } from 'react-native'
import { useActiveOutboundSettings } from '../../models/SiteControllerOutboundSettings'
import { faCircleExclamation } from '@fortawesome/pro-duotone-svg-icons/faCircleExclamation'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { GourmetSiteProviderLabel } from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/GourmetSiteProvider'
import SectionTitle from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/SectionTitle'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  deleteParsedSeat,
  useParsedSeats,
} from '../../models/SiteControllerSeatSetting'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'

interface ParsedSeatListViewProps {
  restaurantId: number
}

export default function ParsedSeatListView({
  restaurantId,
}: ParsedSeatListViewProps) {
  const token = useToken()
  const [searchText, setSearchText] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedSeatIds, setCheckedSeatIds] = useState<string[]>([])

  const { activeOutboundSettings } = useActiveOutboundSettings(restaurantId)
  const [selectedSite, setSelectedSite] = useFormState(
    activeOutboundSettings?.[0]?.site ?? 'tabelog'
  )

  const { data, pagination, mutate } = useParsedSeats(
    restaurantId,
    selectedSite,
    {
      name: searchText,
    }
  )

  return (
    <View>
      <SectionTitle
        title={t('他社グルメサイト席一覧')}
        helperText={t('他社グルメサイトから取得した席情報です。')}
      />
      <View style={{ backgroundColor: Colors.white, paddingHorizontal: 16 }}>
        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          {activeOutboundSettings?.map((setting) => (
            <TouchableOpacity
              key={setting.site}
              style={[
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 210,
                  height: 60,
                  borderBottomColor:
                    selectedSite === setting.site ? Colors.primary : 'none',
                  borderBottomWidth: selectedSite === setting.site ? 2 : 0,
                },
              ]}
              onPress={() => {
                setSelectedSite(setting.site)
              }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 27,
                  color: Colors.black60,
                }}
              >
                {GOURMET_SITE_PROVIDER_SOURCE[setting.site].label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={{ flexGrow: 5, position: 'relative', marginVertical: 16 }}>
          <TextInput
            style={{
              backgroundColor: Colors.white,
              borderWidth: 1,
              borderColor: Colors.border,
              borderRadius: 8,
              width: '100%',
              height: 48,
              paddingLeft: 48,
            }}
            value={searchText}
            onChangeText={setSearchText}
          />
          <FontAwesomeIcon
            size={24}
            icon={faSearch}
            color={Colors.black60}
            style={{ position: 'absolute', left: 16, top: 12 }}
          />
        </View>
        <Button
          mode="outline"
          variant="danger"
          style={{
            alignSelf: 'flex-start',
            marginVertical: 16,
            height: 36,
          }}
          textStyle={{
            fontSize: 14,
          }}
          disabled={checkedSeatIds.length === 0}
          onPress={async () => {
            if (checkedSeatIds.length === 0) return
            if (token == null) return
            await Promise.all(
              checkedSeatIds.map((seatId) =>
                deleteParsedSeat(token, restaurantId, seatId)
              )
            )
            await mutate()
          }}
        >
          {t('チェックした席を削除する')}
        </Button>

        <View
          style={{ paddingVertical: 16, flexDirection: 'row', columnGap: 16 }}
        >
          <Checkbox
            checkboxLabel=""
            checked={isCheckedAll}
            onChange={() => {
              if (data == null) return
              setCheckedSeatIds(isCheckedAll ? [] : data.map((item) => item.id))
              setIsCheckedAll(!isCheckedAll)
            }}
          />
          <Text
            style={{ fontSize: 14, fontWeight: '600', lineHeight: 22, flex: 1 }}
          >
            {t('席名')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 90,
            }}
          >
            {t('人数')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 120,
            }}
          >
            {t('時間')}
          </Text>
          <View style={{ width: 24, height: 24 }} />
        </View>
        <View style={{ paddingBottom: 16 }}>
          {data?.length === 0 && (
            <Text
              style={{
                fontSize: 16,
                fontWeight: '300',
                color: Colors.black60,
                paddingVertical: 80,
                textAlign: 'center',
                borderTopWidth: 0.5,
                borderColor: Colors.border,
              }}
            >
              {t('他社の席情報はありません')}
            </Text>
          )}
          <CheckboxGroup
            value={checkedSeatIds}
            onChange={setCheckedSeatIds}
            mode="vertical"
            checkboxContainerStyle={{ marginTop: 0 }}
          >
            {data?.map((item, index) => (
              <View
                key={item.id}
                style={{
                  paddingVertical: 16,
                  borderTopWidth: index % 2 === 0 ? 0.5 : 0,
                  borderColor: Colors.border,
                  flexDirection: 'row',
                }}
              >
                <Checkbox
                  checkboxLabel=""
                  value={item.id}
                  style={{ marginRight: 16 }}
                />
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      columnGap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <GourmetSiteProviderLabel provider={item.site} />
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: '300',
                        color: Colors.black60,
                      }}
                    >
                      {item.site_seat_id}
                    </Text>
                  </View>
                  <Text style={{ fontSize: 16, fontWeight: '300' }}>
                    {item.name}
                  </Text>
                </View>
                <Text style={{ fontSize: 16, fontWeight: '300', width: 100 }}>
                  {t('{{min_party_size}} 〜 {{max_party_size}}', {
                    min_party_size: item.min_party_size,
                    max_party_size: item.max_party_size,
                  })}
                </Text>
                <Text style={{ fontSize: 16, fontWeight: '300', width: 140 }}>
                  {t('{{start_at}} - {{end_at}}', {
                    start_at: item.start_at,
                    end_at: item.end_at,
                  })}
                </Text>
                <View style={{ width: 24, height: 24 }}>
                  {item.sync_status === 'error' && (
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      size={24}
                      color={Colors.caution}
                    />
                  )}
                </View>
              </View>
            ))}
          </CheckboxGroup>
          <Pagination
            currentPage={pagination.currentPage ?? 1}
            totalPage={pagination.totalPages ?? 1}
            setPage={pagination.setPage}
          />
        </View>
      </View>
    </View>
  )
}
