// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course/restaurant_course_service.proto" (package "auto_reserve.restaurants.restaurant_course", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantCourseQuestionResource_Status } from "./restaurant_course_question_resource";
import { RestaurantCourseQuestionSelectionResource_Status } from "./restaurant_course_question_resource";
import { StringValue } from "../../../google/protobuf/wrappers";
import { RestaurantCourseQuestionResource_Category } from "./restaurant_course_question_resource";
import { RestaurantCourseResource_CustomerRegionRestrictionType } from "./restaurant_course_resource";
import { Int32Value } from "../../../google/protobuf/wrappers";
import { RestaurantCourseResource_TaxType } from "./restaurant_course_resource";
import { RestaurantCourseResource } from "./restaurant_course_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseResource restaurant_courses = 1;
   */
  restaurantCourses: RestaurantCourseResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: int64 id = 2;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * @generated from protobuf field: string content = 4;
   */
  content: string;
  /**
   * @generated from protobuf field: float price = 5;
   */
  price: number;
  /**
   * @generated from protobuf field: bool tax_included = 6;
   */
  taxIncluded: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type = 7;
   */
  taxType: RestaurantCourseResource_TaxType;
  /**
   * @generated from protobuf field: repeated string table_menu_ids = 8;
   */
  tableMenuIds: string[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseQuestionResource questions = 9;
   */
  questions: CreateRequest_RestaurantCourseQuestionResource[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies = 10;
   */
  restaurantCourseCancelPolicies: CreateRequest_RestaurantCourseCancelPolicyResource[];
  /**
   * @generated from protobuf field: repeated string question_ids = 11;
   */
  questionIds: string[];
  /**
   * @generated from protobuf field: string title_i18n_ja = 12 [json_name = "titleI18nJa"];
   */
  titleI18NJa: string;
  /**
   * @generated from protobuf field: string title_i18n_en = 13 [json_name = "titleI18nEn"];
   */
  titleI18NEn: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_cn = 14 [json_name = "titleI18nZhCn"];
   */
  titleI18NZhCn: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_tw = 15 [json_name = "titleI18nZhTw"];
   */
  titleI18NZhTw: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_hk = 16 [json_name = "titleI18nZhHk"];
   */
  titleI18NZhHk: string;
  /**
   * @generated from protobuf field: string title_i18n_ko = 17 [json_name = "titleI18nKo"];
   */
  titleI18NKo: string;
  /**
   * @generated from protobuf field: string content_i18n_ja = 18 [json_name = "contentI18nJa"];
   */
  contentI18NJa: string;
  /**
   * @generated from protobuf field: string content_i18n_en = 19 [json_name = "contentI18nEn"];
   */
  contentI18NEn: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_cn = 20 [json_name = "contentI18nZhCn"];
   */
  contentI18NZhCn: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_tw = 21 [json_name = "contentI18nZhTw"];
   */
  contentI18NZhTw: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_hk = 22 [json_name = "contentI18nZhHk"];
   */
  contentI18NZhHk: string;
  /**
   * @generated from protobuf field: string content_i18n_ko = 23 [json_name = "contentI18nKo"];
   */
  contentI18NKo: string;
  /**
   * @generated from protobuf field: string title_i18n_th = 24 [json_name = "titleI18nTh"];
   */
  titleI18NTh: string;
  /**
   * @generated from protobuf field: string content_i18n_th = 25 [json_name = "contentI18nTh"];
   */
  contentI18NTh: string;
  /**
   * @generated from protobuf field: string translation_mode = 26;
   */
  translationMode: string;
  /**
   * @generated from protobuf field: string image = 27;
   */
  image: string;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value staying_time = 28;
   */
  stayingTime?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy = 29;
   */
  restaurantCourseReservationPeriodPolicy?: CreateRequest_RestaurantCourseReservationPeriodPolicyResource;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type = 30;
   */
  customerRegionRestrictionType: RestaurantCourseResource_CustomerRegionRestrictionType;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value min_party_size = 31;
   */
  minPartySize?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value max_party_size = 32;
   */
  maxPartySize?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseStockSettingResource restaurant_course_stock_setting = 33;
   */
  restaurantCourseStockSetting?: CreateRequest_RestaurantCourseStockSettingResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.QuestionSelectionResource
 */
export interface CreateRequest_QuestionSelectionResource {
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseQuestionResource
 */
export interface CreateRequest_RestaurantCourseQuestionResource {
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 3;
   */
  category: RestaurantCourseQuestionResource_Category;
  /**
   * @generated from protobuf field: bool is_required = 4;
   */
  isRequired: boolean;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.CreateRequest.QuestionSelectionResource selections = 5;
   */
  selections: CreateRequest_QuestionSelectionResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseCancelPolicyResource
 */
export interface CreateRequest_RestaurantCourseCancelPolicyResource {
  /**
   * @generated from protobuf field: int32 cancel_percent = 1;
   */
  cancelPercent: number;
  /**
   * @generated from protobuf field: int32 cancel_time = 2;
   */
  cancelTime: number;
  /**
   * @generated from protobuf field: string policy_type = 3;
   */
  policyType: string;
  /**
   * @generated from protobuf field: float cancel_amount = 4;
   */
  cancelAmount: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseReservationPeriodPolicyResource
 */
export interface CreateRequest_RestaurantCourseReservationPeriodPolicyResource {
  /**
   * @generated from protobuf field: google.protobuf.StringValue since_type = 1;
   */
  sinceType?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_day_before = 2;
   */
  sinceDayBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_month_before = 3;
   */
  sinceMonthBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_specific_day = 4;
   */
  sinceSpecificDay?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_specific_time = 5;
   */
  sinceSpecificTime?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.StringValue until_type = 6;
   */
  untilType?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value until_day_before = 7;
   */
  untilDayBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value until_time_before = 8;
   */
  untilTimeBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value until_specific_time = 9;
   */
  untilSpecificTime?: Int32Value;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseStockSettingResource
 */
export interface CreateRequest_RestaurantCourseStockSettingResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int32 quantity = 2;
   */
  quantity: number;
  /**
   * @generated from protobuf field: string stock_type = 3;
   */
  stockType: string;
  /**
   * @generated from protobuf field: bool _destroy = 4;
   */
  Destroy: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: int64 id = 2;
   */
  id: number;
  /**
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * @generated from protobuf field: string content = 4;
   */
  content: string;
  /**
   * @generated from protobuf field: float price = 5;
   */
  price: number;
  /**
   * @generated from protobuf field: bool tax_included = 6;
   */
  taxIncluded: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type = 7;
   */
  taxType: RestaurantCourseResource_TaxType;
  /**
   * @generated from protobuf field: repeated string table_menu_ids = 8;
   */
  tableMenuIds: string[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseQuestionResource questions = 9;
   */
  questions: UpdateRequest_RestaurantCourseQuestionResource[];
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies = 10;
   */
  restaurantCourseCancelPolicies: UpdateRequest_RestaurantCourseCancelPolicyResource[];
  /**
   * @generated from protobuf field: repeated string question_ids = 11;
   */
  questionIds: string[];
  /**
   * @generated from protobuf field: string title_i18n_ja = 12 [json_name = "titleI18nJa"];
   */
  titleI18NJa: string;
  /**
   * @generated from protobuf field: string title_i18n_en = 13 [json_name = "titleI18nEn"];
   */
  titleI18NEn: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_cn = 14 [json_name = "titleI18nZhCn"];
   */
  titleI18NZhCn: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_tw = 15 [json_name = "titleI18nZhTw"];
   */
  titleI18NZhTw: string;
  /**
   * @generated from protobuf field: string title_i18n_zh_hk = 16 [json_name = "titleI18nZhHk"];
   */
  titleI18NZhHk: string;
  /**
   * @generated from protobuf field: string title_i18n_ko = 17 [json_name = "titleI18nKo"];
   */
  titleI18NKo: string;
  /**
   * @generated from protobuf field: string content_i18n_ja = 18 [json_name = "contentI18nJa"];
   */
  contentI18NJa: string;
  /**
   * @generated from protobuf field: string content_i18n_en = 19 [json_name = "contentI18nEn"];
   */
  contentI18NEn: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_cn = 20 [json_name = "contentI18nZhCn"];
   */
  contentI18NZhCn: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_tw = 21 [json_name = "contentI18nZhTw"];
   */
  contentI18NZhTw: string;
  /**
   * @generated from protobuf field: string content_i18n_zh_hk = 22 [json_name = "contentI18nZhHk"];
   */
  contentI18NZhHk: string;
  /**
   * @generated from protobuf field: string content_i18n_ko = 23 [json_name = "contentI18nKo"];
   */
  contentI18NKo: string;
  /**
   * @generated from protobuf field: string title_i18n_th = 24 [json_name = "titleI18nTh"];
   */
  titleI18NTh: string;
  /**
   * @generated from protobuf field: string content_i18n_th = 25 [json_name = "contentI18nTh"];
   */
  contentI18NTh: string;
  /**
   * @generated from protobuf field: string translation_mode = 26;
   */
  translationMode: string;
  /**
   * @generated from protobuf field: string image = 27;
   */
  image: string;
  /**
   * @generated from protobuf field: bool _destroy = 28;
   */
  Destroy: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value staying_time = 29;
   */
  stayingTime?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy = 30;
   */
  restaurantCourseReservationPeriodPolicy?: UpdateRequest_RestaurantCourseReservationPeriodPolicyResource;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type = 31;
   */
  customerRegionRestrictionType: RestaurantCourseResource_CustomerRegionRestrictionType;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value min_party_size = 32;
   */
  minPartySize?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value max_party_size = 33;
   */
  maxPartySize?: Int32Value;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseStockSettingResource restaurant_course_stock_setting = 34;
   */
  restaurantCourseStockSetting?: UpdateRequest_RestaurantCourseStockSettingResource;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.QuestionSelectionResource
 */
export interface UpdateRequest_QuestionSelectionResource {
  /**
   * @generated from protobuf field: google.protobuf.StringValue id = 1;
   */
  id?: StringValue;
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status = 3;
   */
  status: RestaurantCourseQuestionSelectionResource_Status;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseQuestionResource
 */
export interface UpdateRequest_RestaurantCourseQuestionResource {
  /**
   * @generated from protobuf field: google.protobuf.StringValue id = 1;
   */
  id?: StringValue;
  /**
   * @generated from protobuf field: string description = 2;
   */
  description: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 3;
   */
  category: RestaurantCourseQuestionResource_Category;
  /**
   * @generated from protobuf field: bool is_required = 4;
   */
  isRequired: boolean;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status status = 5;
   */
  status: RestaurantCourseQuestionResource_Status;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.QuestionSelectionResource selections = 6;
   */
  selections: UpdateRequest_QuestionSelectionResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseCancelPolicyResource
 */
export interface UpdateRequest_RestaurantCourseCancelPolicyResource {
  /**
   * @generated from protobuf field: google.protobuf.StringValue id = 1;
   */
  id?: StringValue;
  /**
   * @generated from protobuf field: int32 cancel_percent = 2;
   */
  cancelPercent: number;
  /**
   * @generated from protobuf field: int32 cancel_time = 3;
   */
  cancelTime: number;
  /**
   * @generated from protobuf field: bool _destroy = 4;
   */
  Destroy: boolean;
  /**
   * @generated from protobuf field: string policy_type = 5;
   */
  policyType: string;
  /**
   * @generated from protobuf field: float cancel_amount = 6;
   */
  cancelAmount: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseReservationPeriodPolicyResource
 */
export interface UpdateRequest_RestaurantCourseReservationPeriodPolicyResource {
  /**
   * @generated from protobuf field: google.protobuf.StringValue id = 1;
   */
  id?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.StringValue since_type = 2;
   */
  sinceType?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_day_before = 3;
   */
  sinceDayBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_month_before = 4;
   */
  sinceMonthBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_specific_day = 5;
   */
  sinceSpecificDay?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value since_specific_time = 6;
   */
  sinceSpecificTime?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.StringValue until_type = 7;
   */
  untilType?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value until_day_before = 8;
   */
  untilDayBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value until_time_before = 9;
   */
  untilTimeBefore?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value until_specific_time = 10;
   */
  untilSpecificTime?: Int32Value;
  /**
   * @generated from protobuf field: bool _destroy = 11;
   */
  Destroy: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseStockSettingResource
 */
export interface UpdateRequest_RestaurantCourseStockSettingResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int32 quantity = 2;
   */
  quantity: number;
  /**
   * @generated from protobuf field: string stock_type = 3;
   */
  stockType: string;
  /**
   * @generated from protobuf field: bool _destroy = 4;
   */
  Destroy: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.ArchiveRequest
 */
export interface ArchiveRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: int64 id = 2;
   */
  id: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course.ListRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { restaurantId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course.ListResponse", [
      {
        no: 1,
        name: "restaurant_courses",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantCourseResource,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { restaurantCourses: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseResource restaurant_courses */ 1:
          message.restaurantCourses.push(
            RestaurantCourseResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.restaurant_course.RestaurantCourseResource restaurant_courses = 1; */
    for (let i = 0; i < message.restaurantCourses.length; i++)
      RestaurantCourseResource.internalBinaryWrite(
        message.restaurantCourses[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course.GetRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { restaurantId: 0, id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* int64 id */ 2:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* int64 id = 2; */
    if (message.id !== 0) writer.tag(2, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course.CreateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: "price", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
      { no: 6, name: "tax_included", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: "tax_type",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType",
          RestaurantCourseResource_TaxType,
        ],
      },
      {
        no: 8,
        name: "table_menu_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: "questions",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreateRequest_RestaurantCourseQuestionResource,
      },
      {
        no: 10,
        name: "restaurant_course_cancel_policies",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreateRequest_RestaurantCourseCancelPolicyResource,
      },
      {
        no: 11,
        name: "question_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: "title_i18n_ja",
        kind: "scalar",
        jsonName: "titleI18nJa",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: "title_i18n_en",
        kind: "scalar",
        jsonName: "titleI18nEn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 14,
        name: "title_i18n_zh_cn",
        kind: "scalar",
        jsonName: "titleI18nZhCn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 15,
        name: "title_i18n_zh_tw",
        kind: "scalar",
        jsonName: "titleI18nZhTw",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: "title_i18n_zh_hk",
        kind: "scalar",
        jsonName: "titleI18nZhHk",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: "title_i18n_ko",
        kind: "scalar",
        jsonName: "titleI18nKo",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: "content_i18n_ja",
        kind: "scalar",
        jsonName: "contentI18nJa",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 19,
        name: "content_i18n_en",
        kind: "scalar",
        jsonName: "contentI18nEn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 20,
        name: "content_i18n_zh_cn",
        kind: "scalar",
        jsonName: "contentI18nZhCn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 21,
        name: "content_i18n_zh_tw",
        kind: "scalar",
        jsonName: "contentI18nZhTw",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 22,
        name: "content_i18n_zh_hk",
        kind: "scalar",
        jsonName: "contentI18nZhHk",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 23,
        name: "content_i18n_ko",
        kind: "scalar",
        jsonName: "contentI18nKo",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 24,
        name: "title_i18n_th",
        kind: "scalar",
        jsonName: "titleI18nTh",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 25,
        name: "content_i18n_th",
        kind: "scalar",
        jsonName: "contentI18nTh",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 26,
        name: "translation_mode",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 27, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 28, name: "staying_time", kind: "message", T: () => Int32Value },
      {
        no: 29,
        name: "restaurant_course_reservation_period_policy",
        kind: "message",
        T: () => CreateRequest_RestaurantCourseReservationPeriodPolicyResource,
      },
      {
        no: 30,
        name: "customer_region_restriction_type",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType",
          RestaurantCourseResource_CustomerRegionRestrictionType,
        ],
      },
      { no: 31, name: "min_party_size", kind: "message", T: () => Int32Value },
      { no: 32, name: "max_party_size", kind: "message", T: () => Int32Value },
      {
        no: 33,
        name: "restaurant_course_stock_setting",
        kind: "message",
        T: () => CreateRequest_RestaurantCourseStockSettingResource,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      title: "",
      content: "",
      price: 0,
      taxIncluded: false,
      taxType: 0,
      tableMenuIds: [],
      questions: [],
      restaurantCourseCancelPolicies: [],
      questionIds: [],
      titleI18NJa: "",
      titleI18NEn: "",
      titleI18NZhCn: "",
      titleI18NZhTw: "",
      titleI18NZhHk: "",
      titleI18NKo: "",
      contentI18NJa: "",
      contentI18NEn: "",
      contentI18NZhCn: "",
      contentI18NZhTw: "",
      contentI18NZhHk: "",
      contentI18NKo: "",
      titleI18NTh: "",
      contentI18NTh: "",
      translationMode: "",
      image: "",
      customerRegionRestrictionType: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string title */ 3:
          message.title = reader.string();
          break;
        case /* string content */ 4:
          message.content = reader.string();
          break;
        case /* float price */ 5:
          message.price = reader.float();
          break;
        case /* bool tax_included */ 6:
          message.taxIncluded = reader.bool();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type */ 7:
          message.taxType = reader.int32();
          break;
        case /* repeated string table_menu_ids */ 8:
          message.tableMenuIds.push(reader.string());
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseQuestionResource questions */ 9:
          message.questions.push(
            CreateRequest_RestaurantCourseQuestionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies */ 10:
          message.restaurantCourseCancelPolicies.push(
            CreateRequest_RestaurantCourseCancelPolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated string question_ids */ 11:
          message.questionIds.push(reader.string());
          break;
        case /* string title_i18n_ja = 12 [json_name = "titleI18nJa"];*/ 12:
          message.titleI18NJa = reader.string();
          break;
        case /* string title_i18n_en = 13 [json_name = "titleI18nEn"];*/ 13:
          message.titleI18NEn = reader.string();
          break;
        case /* string title_i18n_zh_cn = 14 [json_name = "titleI18nZhCn"];*/ 14:
          message.titleI18NZhCn = reader.string();
          break;
        case /* string title_i18n_zh_tw = 15 [json_name = "titleI18nZhTw"];*/ 15:
          message.titleI18NZhTw = reader.string();
          break;
        case /* string title_i18n_zh_hk = 16 [json_name = "titleI18nZhHk"];*/ 16:
          message.titleI18NZhHk = reader.string();
          break;
        case /* string title_i18n_ko = 17 [json_name = "titleI18nKo"];*/ 17:
          message.titleI18NKo = reader.string();
          break;
        case /* string content_i18n_ja = 18 [json_name = "contentI18nJa"];*/ 18:
          message.contentI18NJa = reader.string();
          break;
        case /* string content_i18n_en = 19 [json_name = "contentI18nEn"];*/ 19:
          message.contentI18NEn = reader.string();
          break;
        case /* string content_i18n_zh_cn = 20 [json_name = "contentI18nZhCn"];*/ 20:
          message.contentI18NZhCn = reader.string();
          break;
        case /* string content_i18n_zh_tw = 21 [json_name = "contentI18nZhTw"];*/ 21:
          message.contentI18NZhTw = reader.string();
          break;
        case /* string content_i18n_zh_hk = 22 [json_name = "contentI18nZhHk"];*/ 22:
          message.contentI18NZhHk = reader.string();
          break;
        case /* string content_i18n_ko = 23 [json_name = "contentI18nKo"];*/ 23:
          message.contentI18NKo = reader.string();
          break;
        case /* string title_i18n_th = 24 [json_name = "titleI18nTh"];*/ 24:
          message.titleI18NTh = reader.string();
          break;
        case /* string content_i18n_th = 25 [json_name = "contentI18nTh"];*/ 25:
          message.contentI18NTh = reader.string();
          break;
        case /* string translation_mode */ 26:
          message.translationMode = reader.string();
          break;
        case /* string image */ 27:
          message.image = reader.string();
          break;
        case /* google.protobuf.Int32Value staying_time */ 28:
          message.stayingTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.stayingTime,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy */ 29:
          message.restaurantCourseReservationPeriodPolicy =
            CreateRequest_RestaurantCourseReservationPeriodPolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantCourseReservationPeriodPolicy,
            );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type */ 30:
          message.customerRegionRestrictionType = reader.int32();
          break;
        case /* google.protobuf.Int32Value min_party_size */ 31:
          message.minPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.minPartySize,
          );
          break;
        case /* google.protobuf.Int32Value max_party_size */ 32:
          message.maxPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.maxPartySize,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseStockSettingResource restaurant_course_stock_setting */ 33:
          message.restaurantCourseStockSetting =
            CreateRequest_RestaurantCourseStockSettingResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantCourseStockSetting,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string title = 3; */
    if (message.title !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.title);
    /* string content = 4; */
    if (message.content !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.content);
    /* float price = 5; */
    if (message.price !== 0) writer.tag(5, WireType.Bit32).float(message.price);
    /* bool tax_included = 6; */
    if (message.taxIncluded !== false)
      writer.tag(6, WireType.Varint).bool(message.taxIncluded);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type = 7; */
    if (message.taxType !== 0)
      writer.tag(7, WireType.Varint).int32(message.taxType);
    /* repeated string table_menu_ids = 8; */
    for (let i = 0; i < message.tableMenuIds.length; i++)
      writer.tag(8, WireType.LengthDelimited).string(message.tableMenuIds[i]);
    /* repeated auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseQuestionResource questions = 9; */
    for (let i = 0; i < message.questions.length; i++)
      CreateRequest_RestaurantCourseQuestionResource.internalBinaryWrite(
        message.questions[i],
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies = 10; */
    for (let i = 0; i < message.restaurantCourseCancelPolicies.length; i++)
      CreateRequest_RestaurantCourseCancelPolicyResource.internalBinaryWrite(
        message.restaurantCourseCancelPolicies[i],
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated string question_ids = 11; */
    for (let i = 0; i < message.questionIds.length; i++)
      writer.tag(11, WireType.LengthDelimited).string(message.questionIds[i]);
    /* string title_i18n_ja = 12 [json_name = "titleI18nJa"]; */
    if (message.titleI18NJa !== "")
      writer.tag(12, WireType.LengthDelimited).string(message.titleI18NJa);
    /* string title_i18n_en = 13 [json_name = "titleI18nEn"]; */
    if (message.titleI18NEn !== "")
      writer.tag(13, WireType.LengthDelimited).string(message.titleI18NEn);
    /* string title_i18n_zh_cn = 14 [json_name = "titleI18nZhCn"]; */
    if (message.titleI18NZhCn !== "")
      writer.tag(14, WireType.LengthDelimited).string(message.titleI18NZhCn);
    /* string title_i18n_zh_tw = 15 [json_name = "titleI18nZhTw"]; */
    if (message.titleI18NZhTw !== "")
      writer.tag(15, WireType.LengthDelimited).string(message.titleI18NZhTw);
    /* string title_i18n_zh_hk = 16 [json_name = "titleI18nZhHk"]; */
    if (message.titleI18NZhHk !== "")
      writer.tag(16, WireType.LengthDelimited).string(message.titleI18NZhHk);
    /* string title_i18n_ko = 17 [json_name = "titleI18nKo"]; */
    if (message.titleI18NKo !== "")
      writer.tag(17, WireType.LengthDelimited).string(message.titleI18NKo);
    /* string content_i18n_ja = 18 [json_name = "contentI18nJa"]; */
    if (message.contentI18NJa !== "")
      writer.tag(18, WireType.LengthDelimited).string(message.contentI18NJa);
    /* string content_i18n_en = 19 [json_name = "contentI18nEn"]; */
    if (message.contentI18NEn !== "")
      writer.tag(19, WireType.LengthDelimited).string(message.contentI18NEn);
    /* string content_i18n_zh_cn = 20 [json_name = "contentI18nZhCn"]; */
    if (message.contentI18NZhCn !== "")
      writer.tag(20, WireType.LengthDelimited).string(message.contentI18NZhCn);
    /* string content_i18n_zh_tw = 21 [json_name = "contentI18nZhTw"]; */
    if (message.contentI18NZhTw !== "")
      writer.tag(21, WireType.LengthDelimited).string(message.contentI18NZhTw);
    /* string content_i18n_zh_hk = 22 [json_name = "contentI18nZhHk"]; */
    if (message.contentI18NZhHk !== "")
      writer.tag(22, WireType.LengthDelimited).string(message.contentI18NZhHk);
    /* string content_i18n_ko = 23 [json_name = "contentI18nKo"]; */
    if (message.contentI18NKo !== "")
      writer.tag(23, WireType.LengthDelimited).string(message.contentI18NKo);
    /* string title_i18n_th = 24 [json_name = "titleI18nTh"]; */
    if (message.titleI18NTh !== "")
      writer.tag(24, WireType.LengthDelimited).string(message.titleI18NTh);
    /* string content_i18n_th = 25 [json_name = "contentI18nTh"]; */
    if (message.contentI18NTh !== "")
      writer.tag(25, WireType.LengthDelimited).string(message.contentI18NTh);
    /* string translation_mode = 26; */
    if (message.translationMode !== "")
      writer.tag(26, WireType.LengthDelimited).string(message.translationMode);
    /* string image = 27; */
    if (message.image !== "")
      writer.tag(27, WireType.LengthDelimited).string(message.image);
    /* google.protobuf.Int32Value staying_time = 28; */
    if (message.stayingTime)
      Int32Value.internalBinaryWrite(
        message.stayingTime,
        writer.tag(28, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy = 29; */
    if (message.restaurantCourseReservationPeriodPolicy)
      CreateRequest_RestaurantCourseReservationPeriodPolicyResource.internalBinaryWrite(
        message.restaurantCourseReservationPeriodPolicy,
        writer.tag(29, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type = 30; */
    if (message.customerRegionRestrictionType !== 0)
      writer
        .tag(30, WireType.Varint)
        .int32(message.customerRegionRestrictionType);
    /* google.protobuf.Int32Value min_party_size = 31; */
    if (message.minPartySize)
      Int32Value.internalBinaryWrite(
        message.minPartySize,
        writer.tag(31, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value max_party_size = 32; */
    if (message.maxPartySize)
      Int32Value.internalBinaryWrite(
        message.maxPartySize,
        writer.tag(32, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseStockSettingResource restaurant_course_stock_setting = 33; */
    if (message.restaurantCourseStockSetting)
      CreateRequest_RestaurantCourseStockSettingResource.internalBinaryWrite(
        message.restaurantCourseStockSetting,
        writer.tag(33, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest_QuestionSelectionResource$Type extends MessageType<CreateRequest_QuestionSelectionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.CreateRequest.QuestionSelectionResource",
      [
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateRequest_QuestionSelectionResource>,
  ): CreateRequest_QuestionSelectionResource {
    const message = { description: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest_QuestionSelectionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest_QuestionSelectionResource,
  ): CreateRequest_QuestionSelectionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string description */ 2:
          message.description = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest_QuestionSelectionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.QuestionSelectionResource
 */
export const CreateRequest_QuestionSelectionResource =
  new CreateRequest_QuestionSelectionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest_RestaurantCourseQuestionResource$Type extends MessageType<CreateRequest_RestaurantCourseQuestionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseQuestionResource",
      [
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: "category",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category",
            RestaurantCourseQuestionResource_Category,
          ],
        },
        {
          no: 4,
          name: "is_required",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 5,
          name: "selections",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => CreateRequest_QuestionSelectionResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateRequest_RestaurantCourseQuestionResource>,
  ): CreateRequest_RestaurantCourseQuestionResource {
    const message = {
      description: "",
      category: 0,
      isRequired: false,
      selections: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest_RestaurantCourseQuestionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest_RestaurantCourseQuestionResource,
  ): CreateRequest_RestaurantCourseQuestionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category */ 3:
          message.category = reader.int32();
          break;
        case /* bool is_required */ 4:
          message.isRequired = reader.bool();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.CreateRequest.QuestionSelectionResource selections */ 5:
          message.selections.push(
            CreateRequest_QuestionSelectionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest_RestaurantCourseQuestionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 3; */
    if (message.category !== 0)
      writer.tag(3, WireType.Varint).int32(message.category);
    /* bool is_required = 4; */
    if (message.isRequired !== false)
      writer.tag(4, WireType.Varint).bool(message.isRequired);
    /* repeated auto_reserve.restaurants.restaurant_course.CreateRequest.QuestionSelectionResource selections = 5; */
    for (let i = 0; i < message.selections.length; i++)
      CreateRequest_QuestionSelectionResource.internalBinaryWrite(
        message.selections[i],
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseQuestionResource
 */
export const CreateRequest_RestaurantCourseQuestionResource =
  new CreateRequest_RestaurantCourseQuestionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest_RestaurantCourseCancelPolicyResource$Type extends MessageType<CreateRequest_RestaurantCourseCancelPolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseCancelPolicyResource",
      [
        {
          no: 1,
          name: "cancel_percent",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 2,
          name: "cancel_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 3,
          name: "policy_type",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 4,
          name: "cancel_amount",
          kind: "scalar",
          T: 2 /*ScalarType.FLOAT*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateRequest_RestaurantCourseCancelPolicyResource>,
  ): CreateRequest_RestaurantCourseCancelPolicyResource {
    const message = {
      cancelPercent: 0,
      cancelTime: 0,
      policyType: "",
      cancelAmount: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest_RestaurantCourseCancelPolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest_RestaurantCourseCancelPolicyResource,
  ): CreateRequest_RestaurantCourseCancelPolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int32 cancel_percent */ 1:
          message.cancelPercent = reader.int32();
          break;
        case /* int32 cancel_time */ 2:
          message.cancelTime = reader.int32();
          break;
        case /* string policy_type */ 3:
          message.policyType = reader.string();
          break;
        case /* float cancel_amount */ 4:
          message.cancelAmount = reader.float();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest_RestaurantCourseCancelPolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 cancel_percent = 1; */
    if (message.cancelPercent !== 0)
      writer.tag(1, WireType.Varint).int32(message.cancelPercent);
    /* int32 cancel_time = 2; */
    if (message.cancelTime !== 0)
      writer.tag(2, WireType.Varint).int32(message.cancelTime);
    /* string policy_type = 3; */
    if (message.policyType !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.policyType);
    /* float cancel_amount = 4; */
    if (message.cancelAmount !== 0)
      writer.tag(4, WireType.Bit32).float(message.cancelAmount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseCancelPolicyResource
 */
export const CreateRequest_RestaurantCourseCancelPolicyResource =
  new CreateRequest_RestaurantCourseCancelPolicyResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest_RestaurantCourseReservationPeriodPolicyResource$Type extends MessageType<CreateRequest_RestaurantCourseReservationPeriodPolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseReservationPeriodPolicyResource",
      [
        { no: 1, name: "since_type", kind: "message", T: () => StringValue },
        {
          no: 2,
          name: "since_day_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 3,
          name: "since_month_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 4,
          name: "since_specific_day",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 5,
          name: "since_specific_time",
          kind: "message",
          T: () => Int32Value,
        },
        { no: 6, name: "until_type", kind: "message", T: () => StringValue },
        {
          no: 7,
          name: "until_day_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 8,
          name: "until_time_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 9,
          name: "until_specific_time",
          kind: "message",
          T: () => Int32Value,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateRequest_RestaurantCourseReservationPeriodPolicyResource>,
  ): CreateRequest_RestaurantCourseReservationPeriodPolicyResource {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest_RestaurantCourseReservationPeriodPolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest_RestaurantCourseReservationPeriodPolicyResource,
  ): CreateRequest_RestaurantCourseReservationPeriodPolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue since_type */ 1:
          message.sinceType = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceType,
          );
          break;
        case /* google.protobuf.Int32Value since_day_before */ 2:
          message.sinceDayBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceDayBefore,
          );
          break;
        case /* google.protobuf.Int32Value since_month_before */ 3:
          message.sinceMonthBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceMonthBefore,
          );
          break;
        case /* google.protobuf.Int32Value since_specific_day */ 4:
          message.sinceSpecificDay = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceSpecificDay,
          );
          break;
        case /* google.protobuf.Int32Value since_specific_time */ 5:
          message.sinceSpecificTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceSpecificTime,
          );
          break;
        case /* google.protobuf.StringValue until_type */ 6:
          message.untilType = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilType,
          );
          break;
        case /* google.protobuf.Int32Value until_day_before */ 7:
          message.untilDayBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilDayBefore,
          );
          break;
        case /* google.protobuf.Int32Value until_time_before */ 8:
          message.untilTimeBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilTimeBefore,
          );
          break;
        case /* google.protobuf.Int32Value until_specific_time */ 9:
          message.untilSpecificTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilSpecificTime,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest_RestaurantCourseReservationPeriodPolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue since_type = 1; */
    if (message.sinceType)
      StringValue.internalBinaryWrite(
        message.sinceType,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_day_before = 2; */
    if (message.sinceDayBefore)
      Int32Value.internalBinaryWrite(
        message.sinceDayBefore,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_month_before = 3; */
    if (message.sinceMonthBefore)
      Int32Value.internalBinaryWrite(
        message.sinceMonthBefore,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_specific_day = 4; */
    if (message.sinceSpecificDay)
      Int32Value.internalBinaryWrite(
        message.sinceSpecificDay,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_specific_time = 5; */
    if (message.sinceSpecificTime)
      Int32Value.internalBinaryWrite(
        message.sinceSpecificTime,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.StringValue until_type = 6; */
    if (message.untilType)
      StringValue.internalBinaryWrite(
        message.untilType,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value until_day_before = 7; */
    if (message.untilDayBefore)
      Int32Value.internalBinaryWrite(
        message.untilDayBefore,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value until_time_before = 8; */
    if (message.untilTimeBefore)
      Int32Value.internalBinaryWrite(
        message.untilTimeBefore,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value until_specific_time = 9; */
    if (message.untilSpecificTime)
      Int32Value.internalBinaryWrite(
        message.untilSpecificTime,
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseReservationPeriodPolicyResource
 */
export const CreateRequest_RestaurantCourseReservationPeriodPolicyResource =
  new CreateRequest_RestaurantCourseReservationPeriodPolicyResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest_RestaurantCourseStockSettingResource$Type extends MessageType<CreateRequest_RestaurantCourseStockSettingResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseStockSettingResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
        {
          no: 3,
          name: "stock_type",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 4, name: "_destroy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<CreateRequest_RestaurantCourseStockSettingResource>,
  ): CreateRequest_RestaurantCourseStockSettingResource {
    const message = { id: "", quantity: 0, stockType: "", Destroy: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest_RestaurantCourseStockSettingResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest_RestaurantCourseStockSettingResource,
  ): CreateRequest_RestaurantCourseStockSettingResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int32 quantity */ 2:
          message.quantity = reader.int32();
          break;
        case /* string stock_type */ 3:
          message.stockType = reader.string();
          break;
        case /* bool _destroy */ 4:
          message.Destroy = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest_RestaurantCourseStockSettingResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int32 quantity = 2; */
    if (message.quantity !== 0)
      writer.tag(2, WireType.Varint).int32(message.quantity);
    /* string stock_type = 3; */
    if (message.stockType !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.stockType);
    /* bool _destroy = 4; */
    if (message.Destroy !== false)
      writer.tag(4, WireType.Varint).bool(message.Destroy);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.CreateRequest.RestaurantCourseStockSettingResource
 */
export const CreateRequest_RestaurantCourseStockSettingResource =
  new CreateRequest_RestaurantCourseStockSettingResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course.UpdateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: "price", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
      { no: 6, name: "tax_included", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: "tax_type",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType",
          RestaurantCourseResource_TaxType,
        ],
      },
      {
        no: 8,
        name: "table_menu_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: "questions",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UpdateRequest_RestaurantCourseQuestionResource,
      },
      {
        no: 10,
        name: "restaurant_course_cancel_policies",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UpdateRequest_RestaurantCourseCancelPolicyResource,
      },
      {
        no: 11,
        name: "question_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: "title_i18n_ja",
        kind: "scalar",
        jsonName: "titleI18nJa",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: "title_i18n_en",
        kind: "scalar",
        jsonName: "titleI18nEn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 14,
        name: "title_i18n_zh_cn",
        kind: "scalar",
        jsonName: "titleI18nZhCn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 15,
        name: "title_i18n_zh_tw",
        kind: "scalar",
        jsonName: "titleI18nZhTw",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: "title_i18n_zh_hk",
        kind: "scalar",
        jsonName: "titleI18nZhHk",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: "title_i18n_ko",
        kind: "scalar",
        jsonName: "titleI18nKo",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: "content_i18n_ja",
        kind: "scalar",
        jsonName: "contentI18nJa",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 19,
        name: "content_i18n_en",
        kind: "scalar",
        jsonName: "contentI18nEn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 20,
        name: "content_i18n_zh_cn",
        kind: "scalar",
        jsonName: "contentI18nZhCn",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 21,
        name: "content_i18n_zh_tw",
        kind: "scalar",
        jsonName: "contentI18nZhTw",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 22,
        name: "content_i18n_zh_hk",
        kind: "scalar",
        jsonName: "contentI18nZhHk",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 23,
        name: "content_i18n_ko",
        kind: "scalar",
        jsonName: "contentI18nKo",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 24,
        name: "title_i18n_th",
        kind: "scalar",
        jsonName: "titleI18nTh",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 25,
        name: "content_i18n_th",
        kind: "scalar",
        jsonName: "contentI18nTh",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 26,
        name: "translation_mode",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 27, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 28, name: "_destroy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      { no: 29, name: "staying_time", kind: "message", T: () => Int32Value },
      {
        no: 30,
        name: "restaurant_course_reservation_period_policy",
        kind: "message",
        T: () => UpdateRequest_RestaurantCourseReservationPeriodPolicyResource,
      },
      {
        no: 31,
        name: "customer_region_restriction_type",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType",
          RestaurantCourseResource_CustomerRegionRestrictionType,
        ],
      },
      { no: 32, name: "min_party_size", kind: "message", T: () => Int32Value },
      { no: 33, name: "max_party_size", kind: "message", T: () => Int32Value },
      {
        no: 34,
        name: "restaurant_course_stock_setting",
        kind: "message",
        T: () => UpdateRequest_RestaurantCourseStockSettingResource,
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      restaurantId: 0,
      id: 0,
      title: "",
      content: "",
      price: 0,
      taxIncluded: false,
      taxType: 0,
      tableMenuIds: [],
      questions: [],
      restaurantCourseCancelPolicies: [],
      questionIds: [],
      titleI18NJa: "",
      titleI18NEn: "",
      titleI18NZhCn: "",
      titleI18NZhTw: "",
      titleI18NZhHk: "",
      titleI18NKo: "",
      contentI18NJa: "",
      contentI18NEn: "",
      contentI18NZhCn: "",
      contentI18NZhTw: "",
      contentI18NZhHk: "",
      contentI18NKo: "",
      titleI18NTh: "",
      contentI18NTh: "",
      translationMode: "",
      image: "",
      Destroy: false,
      customerRegionRestrictionType: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* int64 id */ 2:
          message.id = reader.int64().toNumber();
          break;
        case /* string title */ 3:
          message.title = reader.string();
          break;
        case /* string content */ 4:
          message.content = reader.string();
          break;
        case /* float price */ 5:
          message.price = reader.float();
          break;
        case /* bool tax_included */ 6:
          message.taxIncluded = reader.bool();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type */ 7:
          message.taxType = reader.int32();
          break;
        case /* repeated string table_menu_ids */ 8:
          message.tableMenuIds.push(reader.string());
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseQuestionResource questions */ 9:
          message.questions.push(
            UpdateRequest_RestaurantCourseQuestionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies */ 10:
          message.restaurantCourseCancelPolicies.push(
            UpdateRequest_RestaurantCourseCancelPolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated string question_ids */ 11:
          message.questionIds.push(reader.string());
          break;
        case /* string title_i18n_ja = 12 [json_name = "titleI18nJa"];*/ 12:
          message.titleI18NJa = reader.string();
          break;
        case /* string title_i18n_en = 13 [json_name = "titleI18nEn"];*/ 13:
          message.titleI18NEn = reader.string();
          break;
        case /* string title_i18n_zh_cn = 14 [json_name = "titleI18nZhCn"];*/ 14:
          message.titleI18NZhCn = reader.string();
          break;
        case /* string title_i18n_zh_tw = 15 [json_name = "titleI18nZhTw"];*/ 15:
          message.titleI18NZhTw = reader.string();
          break;
        case /* string title_i18n_zh_hk = 16 [json_name = "titleI18nZhHk"];*/ 16:
          message.titleI18NZhHk = reader.string();
          break;
        case /* string title_i18n_ko = 17 [json_name = "titleI18nKo"];*/ 17:
          message.titleI18NKo = reader.string();
          break;
        case /* string content_i18n_ja = 18 [json_name = "contentI18nJa"];*/ 18:
          message.contentI18NJa = reader.string();
          break;
        case /* string content_i18n_en = 19 [json_name = "contentI18nEn"];*/ 19:
          message.contentI18NEn = reader.string();
          break;
        case /* string content_i18n_zh_cn = 20 [json_name = "contentI18nZhCn"];*/ 20:
          message.contentI18NZhCn = reader.string();
          break;
        case /* string content_i18n_zh_tw = 21 [json_name = "contentI18nZhTw"];*/ 21:
          message.contentI18NZhTw = reader.string();
          break;
        case /* string content_i18n_zh_hk = 22 [json_name = "contentI18nZhHk"];*/ 22:
          message.contentI18NZhHk = reader.string();
          break;
        case /* string content_i18n_ko = 23 [json_name = "contentI18nKo"];*/ 23:
          message.contentI18NKo = reader.string();
          break;
        case /* string title_i18n_th = 24 [json_name = "titleI18nTh"];*/ 24:
          message.titleI18NTh = reader.string();
          break;
        case /* string content_i18n_th = 25 [json_name = "contentI18nTh"];*/ 25:
          message.contentI18NTh = reader.string();
          break;
        case /* string translation_mode */ 26:
          message.translationMode = reader.string();
          break;
        case /* string image */ 27:
          message.image = reader.string();
          break;
        case /* bool _destroy */ 28:
          message.Destroy = reader.bool();
          break;
        case /* google.protobuf.Int32Value staying_time */ 29:
          message.stayingTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.stayingTime,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy */ 30:
          message.restaurantCourseReservationPeriodPolicy =
            UpdateRequest_RestaurantCourseReservationPeriodPolicyResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantCourseReservationPeriodPolicy,
            );
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type */ 31:
          message.customerRegionRestrictionType = reader.int32();
          break;
        case /* google.protobuf.Int32Value min_party_size */ 32:
          message.minPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.minPartySize,
          );
          break;
        case /* google.protobuf.Int32Value max_party_size */ 33:
          message.maxPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.maxPartySize,
          );
          break;
        case /* auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseStockSettingResource restaurant_course_stock_setting */ 34:
          message.restaurantCourseStockSetting =
            UpdateRequest_RestaurantCourseStockSettingResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantCourseStockSetting,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* int64 id = 2; */
    if (message.id !== 0) writer.tag(2, WireType.Varint).int64(message.id);
    /* string title = 3; */
    if (message.title !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.title);
    /* string content = 4; */
    if (message.content !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.content);
    /* float price = 5; */
    if (message.price !== 0) writer.tag(5, WireType.Bit32).float(message.price);
    /* bool tax_included = 6; */
    if (message.taxIncluded !== false)
      writer.tag(6, WireType.Varint).bool(message.taxIncluded);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.TaxType tax_type = 7; */
    if (message.taxType !== 0)
      writer.tag(7, WireType.Varint).int32(message.taxType);
    /* repeated string table_menu_ids = 8; */
    for (let i = 0; i < message.tableMenuIds.length; i++)
      writer.tag(8, WireType.LengthDelimited).string(message.tableMenuIds[i]);
    /* repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseQuestionResource questions = 9; */
    for (let i = 0; i < message.questions.length; i++)
      UpdateRequest_RestaurantCourseQuestionResource.internalBinaryWrite(
        message.questions[i],
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseCancelPolicyResource restaurant_course_cancel_policies = 10; */
    for (let i = 0; i < message.restaurantCourseCancelPolicies.length; i++)
      UpdateRequest_RestaurantCourseCancelPolicyResource.internalBinaryWrite(
        message.restaurantCourseCancelPolicies[i],
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated string question_ids = 11; */
    for (let i = 0; i < message.questionIds.length; i++)
      writer.tag(11, WireType.LengthDelimited).string(message.questionIds[i]);
    /* string title_i18n_ja = 12 [json_name = "titleI18nJa"]; */
    if (message.titleI18NJa !== "")
      writer.tag(12, WireType.LengthDelimited).string(message.titleI18NJa);
    /* string title_i18n_en = 13 [json_name = "titleI18nEn"]; */
    if (message.titleI18NEn !== "")
      writer.tag(13, WireType.LengthDelimited).string(message.titleI18NEn);
    /* string title_i18n_zh_cn = 14 [json_name = "titleI18nZhCn"]; */
    if (message.titleI18NZhCn !== "")
      writer.tag(14, WireType.LengthDelimited).string(message.titleI18NZhCn);
    /* string title_i18n_zh_tw = 15 [json_name = "titleI18nZhTw"]; */
    if (message.titleI18NZhTw !== "")
      writer.tag(15, WireType.LengthDelimited).string(message.titleI18NZhTw);
    /* string title_i18n_zh_hk = 16 [json_name = "titleI18nZhHk"]; */
    if (message.titleI18NZhHk !== "")
      writer.tag(16, WireType.LengthDelimited).string(message.titleI18NZhHk);
    /* string title_i18n_ko = 17 [json_name = "titleI18nKo"]; */
    if (message.titleI18NKo !== "")
      writer.tag(17, WireType.LengthDelimited).string(message.titleI18NKo);
    /* string content_i18n_ja = 18 [json_name = "contentI18nJa"]; */
    if (message.contentI18NJa !== "")
      writer.tag(18, WireType.LengthDelimited).string(message.contentI18NJa);
    /* string content_i18n_en = 19 [json_name = "contentI18nEn"]; */
    if (message.contentI18NEn !== "")
      writer.tag(19, WireType.LengthDelimited).string(message.contentI18NEn);
    /* string content_i18n_zh_cn = 20 [json_name = "contentI18nZhCn"]; */
    if (message.contentI18NZhCn !== "")
      writer.tag(20, WireType.LengthDelimited).string(message.contentI18NZhCn);
    /* string content_i18n_zh_tw = 21 [json_name = "contentI18nZhTw"]; */
    if (message.contentI18NZhTw !== "")
      writer.tag(21, WireType.LengthDelimited).string(message.contentI18NZhTw);
    /* string content_i18n_zh_hk = 22 [json_name = "contentI18nZhHk"]; */
    if (message.contentI18NZhHk !== "")
      writer.tag(22, WireType.LengthDelimited).string(message.contentI18NZhHk);
    /* string content_i18n_ko = 23 [json_name = "contentI18nKo"]; */
    if (message.contentI18NKo !== "")
      writer.tag(23, WireType.LengthDelimited).string(message.contentI18NKo);
    /* string title_i18n_th = 24 [json_name = "titleI18nTh"]; */
    if (message.titleI18NTh !== "")
      writer.tag(24, WireType.LengthDelimited).string(message.titleI18NTh);
    /* string content_i18n_th = 25 [json_name = "contentI18nTh"]; */
    if (message.contentI18NTh !== "")
      writer.tag(25, WireType.LengthDelimited).string(message.contentI18NTh);
    /* string translation_mode = 26; */
    if (message.translationMode !== "")
      writer.tag(26, WireType.LengthDelimited).string(message.translationMode);
    /* string image = 27; */
    if (message.image !== "")
      writer.tag(27, WireType.LengthDelimited).string(message.image);
    /* bool _destroy = 28; */
    if (message.Destroy !== false)
      writer.tag(28, WireType.Varint).bool(message.Destroy);
    /* google.protobuf.Int32Value staying_time = 29; */
    if (message.stayingTime)
      Int32Value.internalBinaryWrite(
        message.stayingTime,
        writer.tag(29, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseReservationPeriodPolicyResource restaurant_course_reservation_period_policy = 30; */
    if (message.restaurantCourseReservationPeriodPolicy)
      UpdateRequest_RestaurantCourseReservationPeriodPolicyResource.internalBinaryWrite(
        message.restaurantCourseReservationPeriodPolicy,
        writer.tag(30, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseResource.CustomerRegionRestrictionType customer_region_restriction_type = 31; */
    if (message.customerRegionRestrictionType !== 0)
      writer
        .tag(31, WireType.Varint)
        .int32(message.customerRegionRestrictionType);
    /* google.protobuf.Int32Value min_party_size = 32; */
    if (message.minPartySize)
      Int32Value.internalBinaryWrite(
        message.minPartySize,
        writer.tag(32, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value max_party_size = 33; */
    if (message.maxPartySize)
      Int32Value.internalBinaryWrite(
        message.maxPartySize,
        writer.tag(33, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseStockSettingResource restaurant_course_stock_setting = 34; */
    if (message.restaurantCourseStockSetting)
      UpdateRequest_RestaurantCourseStockSettingResource.internalBinaryWrite(
        message.restaurantCourseStockSetting,
        writer.tag(34, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest_QuestionSelectionResource$Type extends MessageType<UpdateRequest_QuestionSelectionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.UpdateRequest.QuestionSelectionResource",
      [
        { no: 1, name: "id", kind: "message", T: () => StringValue },
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status",
            RestaurantCourseQuestionSelectionResource_Status,
          ],
        },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateRequest_QuestionSelectionResource>,
  ): UpdateRequest_QuestionSelectionResource {
    const message = { description: "", status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest_QuestionSelectionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest_QuestionSelectionResource,
  ): UpdateRequest_QuestionSelectionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue id */ 1:
          message.id = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.id,
          );
          break;
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status */ 3:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest_QuestionSelectionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue id = 1; */
    if (message.id)
      StringValue.internalBinaryWrite(
        message.id,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionSelectionResource.Status status = 3; */
    if (message.status !== 0)
      writer.tag(3, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.QuestionSelectionResource
 */
export const UpdateRequest_QuestionSelectionResource =
  new UpdateRequest_QuestionSelectionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest_RestaurantCourseQuestionResource$Type extends MessageType<UpdateRequest_RestaurantCourseQuestionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseQuestionResource",
      [
        { no: 1, name: "id", kind: "message", T: () => StringValue },
        {
          no: 2,
          name: "description",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: "category",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category",
            RestaurantCourseQuestionResource_Category,
          ],
        },
        {
          no: 4,
          name: "is_required",
          kind: "scalar",
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 5,
          name: "status",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status",
            RestaurantCourseQuestionResource_Status,
          ],
        },
        {
          no: 6,
          name: "selections",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => UpdateRequest_QuestionSelectionResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateRequest_RestaurantCourseQuestionResource>,
  ): UpdateRequest_RestaurantCourseQuestionResource {
    const message = {
      description: "",
      category: 0,
      isRequired: false,
      status: 0,
      selections: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest_RestaurantCourseQuestionResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest_RestaurantCourseQuestionResource,
  ): UpdateRequest_RestaurantCourseQuestionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue id */ 1:
          message.id = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.id,
          );
          break;
        case /* string description */ 2:
          message.description = reader.string();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category */ 3:
          message.category = reader.int32();
          break;
        case /* bool is_required */ 4:
          message.isRequired = reader.bool();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status status */ 5:
          message.status = reader.int32();
          break;
        case /* repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.QuestionSelectionResource selections */ 6:
          message.selections.push(
            UpdateRequest_QuestionSelectionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest_RestaurantCourseQuestionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue id = 1; */
    if (message.id)
      StringValue.internalBinaryWrite(
        message.id,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string description = 2; */
    if (message.description !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.description);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Category category = 3; */
    if (message.category !== 0)
      writer.tag(3, WireType.Varint).int32(message.category);
    /* bool is_required = 4; */
    if (message.isRequired !== false)
      writer.tag(4, WireType.Varint).bool(message.isRequired);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource.Status status = 5; */
    if (message.status !== 0)
      writer.tag(5, WireType.Varint).int32(message.status);
    /* repeated auto_reserve.restaurants.restaurant_course.UpdateRequest.QuestionSelectionResource selections = 6; */
    for (let i = 0; i < message.selections.length; i++)
      UpdateRequest_QuestionSelectionResource.internalBinaryWrite(
        message.selections[i],
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseQuestionResource
 */
export const UpdateRequest_RestaurantCourseQuestionResource =
  new UpdateRequest_RestaurantCourseQuestionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest_RestaurantCourseCancelPolicyResource$Type extends MessageType<UpdateRequest_RestaurantCourseCancelPolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseCancelPolicyResource",
      [
        { no: 1, name: "id", kind: "message", T: () => StringValue },
        {
          no: 2,
          name: "cancel_percent",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 3,
          name: "cancel_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 4, name: "_destroy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
        {
          no: 5,
          name: "policy_type",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 6,
          name: "cancel_amount",
          kind: "scalar",
          T: 2 /*ScalarType.FLOAT*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateRequest_RestaurantCourseCancelPolicyResource>,
  ): UpdateRequest_RestaurantCourseCancelPolicyResource {
    const message = {
      cancelPercent: 0,
      cancelTime: 0,
      Destroy: false,
      policyType: "",
      cancelAmount: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest_RestaurantCourseCancelPolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest_RestaurantCourseCancelPolicyResource,
  ): UpdateRequest_RestaurantCourseCancelPolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue id */ 1:
          message.id = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.id,
          );
          break;
        case /* int32 cancel_percent */ 2:
          message.cancelPercent = reader.int32();
          break;
        case /* int32 cancel_time */ 3:
          message.cancelTime = reader.int32();
          break;
        case /* bool _destroy */ 4:
          message.Destroy = reader.bool();
          break;
        case /* string policy_type */ 5:
          message.policyType = reader.string();
          break;
        case /* float cancel_amount */ 6:
          message.cancelAmount = reader.float();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest_RestaurantCourseCancelPolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue id = 1; */
    if (message.id)
      StringValue.internalBinaryWrite(
        message.id,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int32 cancel_percent = 2; */
    if (message.cancelPercent !== 0)
      writer.tag(2, WireType.Varint).int32(message.cancelPercent);
    /* int32 cancel_time = 3; */
    if (message.cancelTime !== 0)
      writer.tag(3, WireType.Varint).int32(message.cancelTime);
    /* bool _destroy = 4; */
    if (message.Destroy !== false)
      writer.tag(4, WireType.Varint).bool(message.Destroy);
    /* string policy_type = 5; */
    if (message.policyType !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.policyType);
    /* float cancel_amount = 6; */
    if (message.cancelAmount !== 0)
      writer.tag(6, WireType.Bit32).float(message.cancelAmount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseCancelPolicyResource
 */
export const UpdateRequest_RestaurantCourseCancelPolicyResource =
  new UpdateRequest_RestaurantCourseCancelPolicyResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest_RestaurantCourseReservationPeriodPolicyResource$Type extends MessageType<UpdateRequest_RestaurantCourseReservationPeriodPolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseReservationPeriodPolicyResource",
      [
        { no: 1, name: "id", kind: "message", T: () => StringValue },
        { no: 2, name: "since_type", kind: "message", T: () => StringValue },
        {
          no: 3,
          name: "since_day_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 4,
          name: "since_month_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 5,
          name: "since_specific_day",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 6,
          name: "since_specific_time",
          kind: "message",
          T: () => Int32Value,
        },
        { no: 7, name: "until_type", kind: "message", T: () => StringValue },
        {
          no: 8,
          name: "until_day_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 9,
          name: "until_time_before",
          kind: "message",
          T: () => Int32Value,
        },
        {
          no: 10,
          name: "until_specific_time",
          kind: "message",
          T: () => Int32Value,
        },
        { no: 11, name: "_destroy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateRequest_RestaurantCourseReservationPeriodPolicyResource>,
  ): UpdateRequest_RestaurantCourseReservationPeriodPolicyResource {
    const message = { Destroy: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest_RestaurantCourseReservationPeriodPolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest_RestaurantCourseReservationPeriodPolicyResource,
  ): UpdateRequest_RestaurantCourseReservationPeriodPolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue id */ 1:
          message.id = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.id,
          );
          break;
        case /* google.protobuf.StringValue since_type */ 2:
          message.sinceType = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceType,
          );
          break;
        case /* google.protobuf.Int32Value since_day_before */ 3:
          message.sinceDayBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceDayBefore,
          );
          break;
        case /* google.protobuf.Int32Value since_month_before */ 4:
          message.sinceMonthBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceMonthBefore,
          );
          break;
        case /* google.protobuf.Int32Value since_specific_day */ 5:
          message.sinceSpecificDay = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceSpecificDay,
          );
          break;
        case /* google.protobuf.Int32Value since_specific_time */ 6:
          message.sinceSpecificTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sinceSpecificTime,
          );
          break;
        case /* google.protobuf.StringValue until_type */ 7:
          message.untilType = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilType,
          );
          break;
        case /* google.protobuf.Int32Value until_day_before */ 8:
          message.untilDayBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilDayBefore,
          );
          break;
        case /* google.protobuf.Int32Value until_time_before */ 9:
          message.untilTimeBefore = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilTimeBefore,
          );
          break;
        case /* google.protobuf.Int32Value until_specific_time */ 10:
          message.untilSpecificTime = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.untilSpecificTime,
          );
          break;
        case /* bool _destroy */ 11:
          message.Destroy = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest_RestaurantCourseReservationPeriodPolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue id = 1; */
    if (message.id)
      StringValue.internalBinaryWrite(
        message.id,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.StringValue since_type = 2; */
    if (message.sinceType)
      StringValue.internalBinaryWrite(
        message.sinceType,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_day_before = 3; */
    if (message.sinceDayBefore)
      Int32Value.internalBinaryWrite(
        message.sinceDayBefore,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_month_before = 4; */
    if (message.sinceMonthBefore)
      Int32Value.internalBinaryWrite(
        message.sinceMonthBefore,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_specific_day = 5; */
    if (message.sinceSpecificDay)
      Int32Value.internalBinaryWrite(
        message.sinceSpecificDay,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value since_specific_time = 6; */
    if (message.sinceSpecificTime)
      Int32Value.internalBinaryWrite(
        message.sinceSpecificTime,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.StringValue until_type = 7; */
    if (message.untilType)
      StringValue.internalBinaryWrite(
        message.untilType,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value until_day_before = 8; */
    if (message.untilDayBefore)
      Int32Value.internalBinaryWrite(
        message.untilDayBefore,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value until_time_before = 9; */
    if (message.untilTimeBefore)
      Int32Value.internalBinaryWrite(
        message.untilTimeBefore,
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value until_specific_time = 10; */
    if (message.untilSpecificTime)
      Int32Value.internalBinaryWrite(
        message.untilSpecificTime,
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool _destroy = 11; */
    if (message.Destroy !== false)
      writer.tag(11, WireType.Varint).bool(message.Destroy);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseReservationPeriodPolicyResource
 */
export const UpdateRequest_RestaurantCourseReservationPeriodPolicyResource =
  new UpdateRequest_RestaurantCourseReservationPeriodPolicyResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest_RestaurantCourseStockSettingResource$Type extends MessageType<UpdateRequest_RestaurantCourseStockSettingResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseStockSettingResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
        {
          no: 3,
          name: "stock_type",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 4, name: "_destroy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<UpdateRequest_RestaurantCourseStockSettingResource>,
  ): UpdateRequest_RestaurantCourseStockSettingResource {
    const message = { id: "", quantity: 0, stockType: "", Destroy: false };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest_RestaurantCourseStockSettingResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest_RestaurantCourseStockSettingResource,
  ): UpdateRequest_RestaurantCourseStockSettingResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int32 quantity */ 2:
          message.quantity = reader.int32();
          break;
        case /* string stock_type */ 3:
          message.stockType = reader.string();
          break;
        case /* bool _destroy */ 4:
          message.Destroy = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest_RestaurantCourseStockSettingResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int32 quantity = 2; */
    if (message.quantity !== 0)
      writer.tag(2, WireType.Varint).int32(message.quantity);
    /* string stock_type = 3; */
    if (message.stockType !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.stockType);
    /* bool _destroy = 4; */
    if (message.Destroy !== false)
      writer.tag(4, WireType.Varint).bool(message.Destroy);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.UpdateRequest.RestaurantCourseStockSettingResource
 */
export const UpdateRequest_RestaurantCourseStockSettingResource =
  new UpdateRequest_RestaurantCourseStockSettingResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ArchiveRequest$Type extends MessageType<ArchiveRequest> {
  constructor() {
    super("auto_reserve.restaurants.restaurant_course.ArchiveRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ArchiveRequest>): ArchiveRequest {
    const message = { restaurantId: 0, id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ArchiveRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ArchiveRequest,
  ): ArchiveRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* int64 id */ 2:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ArchiveRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* int64 id = 2; */
    if (message.id !== 0) writer.tag(2, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.ArchiveRequest
 */
export const ArchiveRequest = new ArchiveRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.restaurant_course.RestaurantCourse
 */
export const RestaurantCourse = new ServiceType(
  "auto_reserve.restaurants.restaurant_course.RestaurantCourse",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: RestaurantCourseResource },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: RestaurantCourseResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: RestaurantCourseResource,
    },
    {
      name: "Archive",
      options: {},
      I: ArchiveRequest,
      O: RestaurantCourseResource,
    },
  ],
);
