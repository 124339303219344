import React, { FC } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Colors } from '../../constants/Colors'
import { StepperItem } from './Item'
import { Step } from './Type'
import { useResponsive } from '../../modules/useResponsive'

const stepListPcHeight = 80
const stepListSpHeight = 57

type Props = {
  steps: Step[]
  currentStepNumber: number
  skipStepNumbers?: number[]
  style?: StyleProp<ViewStyle>
}

export function StepperList({
  steps,
  currentStepNumber,
  skipStepNumbers,
  style,
}: Props) {
  const { width, sm } = useResponsive()
  const insets = useSafeAreaInsets()
  return (
    <View
      style={[
        {
          borderTopColor: Colors.border,
          borderTopWidth: 0.5,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          height:
            width < sm ? insets.bottom + stepListSpHeight : stepListPcHeight,
          paddingBottom: width < sm ? insets.bottom : 0,
        },
        style,
      ]}
    >
      {steps.map(({ stepNumber, text }, index) => {
        const completed =
          stepNumber < currentStepNumber ||
          skipStepNumbers?.includes(stepNumber)
        const selected = stepNumber === currentStepNumber
        return (
          <View
            key={`${stepNumber}`}
            style={{
              flexDirection: 'row',
              alignItems: width < sm ? 'flex-start' : 'center',
            }}
          >
            {index > 0 && (
              <View
                style={{
                  minWidth: width < sm ? 14 : 60,
                  height: 2,
                  backgroundColor:
                    width < sm
                      ? Colors.border
                      : selected
                      ? Colors.accent
                      : Colors.border,
                  marginHorizontal: 8,
                  marginTop: width < sm ? 12 : 0,
                }}
              />
            )}
            <StepperItem
              stepNumber={stepNumber}
              state={
                completed ? 'completed' : selected ? 'selected' : 'default'
              }
            >
              {text}
            </StepperItem>
          </View>
        )
      })}
    </View>
  )
}
