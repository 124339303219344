import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { faYenSign } from '@fortawesome/pro-light-svg-icons/faYenSign'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export function Header({ children }: { children: ReactNode }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        paddingVertical: 12,
        borderStyle: 'solid',
        borderBottomWidth: 0.5,
        borderBottomColor: 'rgba(68, 68, 68, 0.16)',
      }}
    >
      {children}
    </View>
  )
}

export function HeaderCell({ flex, value }: { value: string; flex: number }) {
  return (
    <Text
      style={{
        flex,
        fontSize: 12,
        fontWeight: '300',
        color: Colors.black,
      }}
    >
      {value}
    </Text>
  )
}

export function Cell({
  typeValue,
  flex,
  fontSize = 14,
  currencyIcon,
}: {
  typeValue:
    | {
        value: string
        type: 'text' | 'time'
      }
    | {
        value: number
        type: 'amount'
      }
  flex: number
  fontSize: number
  currencyIcon?: IconDefinition
}) {
  const rowValue =
    typeValue.type === 'amount'
      ? numberWithDelimiter(typeValue.value)
      : typeValue.type === 'time'
        ? dayjs(typeValue.value).format(t('M月DD日 (dd) HH:mm'))
        : typeValue.value
  const color =
    typeValue.type === 'amount' && typeValue.value < 0
      ? Colors.caution
      : Colors.black

  return (
    <Text
      style={{
        flex,
        fontSize,
        color,
        textAlignVertical: 'center',
      }}
    >
      {typeValue.type === 'amount' && (
        <FontAwesomeIcon
          icon={currencyIcon || faYenSign}
          size={fontSize - 2}
          color={color}
        />
      )}
      {rowValue}
    </Text>
  )
}

export function Row({ children }: { children: ReactNode }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 16,
        justifyContent: 'flex-start',
      }}
    >
      {children}
    </View>
  )
}
