import TimeAgo, { FormatStyleName, Style } from 'javascript-time-ago'
import ja from 'javascript-time-ago/locale/ja'
import en from 'javascript-time-ago/locale/en'
import ko from 'javascript-time-ago/locale/ko'
import zh from 'javascript-time-ago/locale/zh'
import dayjs from './dayjs'
import { toDoubleDigits } from './number'

import { t } from './i18n'
import { getLocale } from './locale'
import { Platform } from 'react-native'

// javascript-time-agoのi18n対応
// locale data自体は軽いのでこの時点でloadしておく
TimeAgo.addDefaultLocale(ja)
TimeAgo.addLocale(en)
TimeAgo.addLocale(ko)
TimeAgo.addLocale(zh)

export function toHoursAndMinutes(seconds: number) {
  return {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
  }
}

export function getFormatTime(seconds: number) {
  const { hours: hour, minutes: minute } = toHoursAndMinutes(seconds)
  return `${toDoubleDigits(hour)}:${toDoubleDigits(minute)}`
}

export function toSeconds(hours: number, mins: number) {
  return hours * 3600 + mins * 60
}

export function wdayName(wday: number) {
  if (wday === 7) {
    return t('祝日')
  }

  return dayjs.weekdays()[wday]
}

export function showDayUnlessToday(time: string) {
  return dayjs(time).tz().format('YYYY-MM-DD') ===
    dayjs().tz().format('YYYY-MM-DD')
    ? dayjs(time).tz().format('HH:mm')
    : dayjs(time).tz().format('M/D HH:mm')
}

export function round(
  time: dayjs.Dayjs,
  interval: number,
  unit: dayjs.UnitType
) {
  return time
    .startOf(unit)
    .set(unit, Math.round(time.get(unit) / interval) * interval)
}

export function floor(
  time: dayjs.Dayjs,
  interval: number,
  unit: dayjs.UnitType
) {
  return time
    .startOf(unit)
    .set(unit, Math.floor(time.get(unit) / interval) * interval)
}

export function ceil(
  time: dayjs.Dayjs,
  interval: number,
  unit: dayjs.UnitType
) {
  return time
    .startOf(unit)
    .set(unit, Math.ceil(time.get(unit) / interval) * interval)
}

export function timeAgoStr(
  input: Date | number,
  style: FormatStyleName | Style | undefined = 'twitter'
) {
  const timeAgo = new TimeAgo(getLocale())
  return timeAgo.format(input, style)
}

export function getDateTime({
  date,
  time,
  timeZone,
}: {
  date: string
  time: string
  timeZone?: string | undefined
}) {
  // NOTE: dateのstringがYYY-MM-DD+時間で送られてくる事があるので、一度YYYY-MM-DDに変換する
  const formattedDate = dayjs.tz(date, timeZone).format('YYYY-MM-DD')

  let [hour, min] = time.split(':').map(Number)
  const isOver24DateTime = hour >= 24

  if (isOver24DateTime) {
    hour -= 24
  }
  // NOTE: summer timeを考慮する必要があるため一度timezoneのlocaleに変換する
  let day = dayjs.tz(`${formattedDate} ${hour}:${min}`, timeZone)

  if (isOver24DateTime) {
    day = day.add(1, 'day')
  }

  // NOTE: webはdayjsの初期化に問題がないのでそのまま返す
  if (Platform.OS === 'web') {
    return day
  }

  // NOTE: webはdayjsの初期化に問題がないのでそのまま返す
  return day.startOf('date').hour(hour).minute(min)
}

// NOTE: dateTimeだけでは>24:00表記か判定できないのでflagで制御できるようにしている
export function getDateAndTime(
  dateTime?: dayjs.Dayjs,
  { isOver24DateTime }: { isOver24DateTime?: boolean } = {}
): { date?: string; time?: string } {
  if (dateTime == null) {
    return { date: undefined, time: undefined }
  }
  let [hour, minute] = dateTime.format('HH:mm').split(':').map(Number)

  if (isOver24DateTime && hour >= 24) {
    hour += 24
    dateTime = dateTime.add(-1, 'day')
  }

  return {
    date: dateTime.format('YYYY-MM-DD'),
    time: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
  }
}
