import React from 'react'
import { Text } from '../../../Text'
import { TouchableOpacity } from '../../../Touchables'
import { useResponsive } from '../../../../modules/useResponsive'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'

import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { FontAwesomeIcon } from '../../../FontAwesomeIcon'
import { Colors } from '../../../../constants/Colors'

export function HeaderLeftButton({
  onPress,
  icon,
}: {
  icon: IconDefinition
  onPress: () => void
}) {
  return (
    <TouchableOpacity onPress={onPress} onPressMinInterval={0}>
      <View style={{ width: 30 }}>
        <FontAwesomeIcon icon={icon} size={22} color={Colors.primary} />
      </View>
    </TouchableOpacity>
  )
}

export function HeaderBackButton({ onPress }: { onPress: () => void }) {
  return <HeaderLeftButton onPress={onPress} icon={faChevronLeft} />
}

export function HeaderForwardButton({ onPress }: { onPress: () => void }) {
  return <HeaderLeftButton onPress={onPress} icon={faChevronRight} />
}

export function HeaderCloseButton({ onPress }: { onPress: () => void }) {
  return <HeaderLeftButton onPress={onPress} icon={faTimes} />
}

export function HeaderButtonEmptyView() {
  return <View style={{ width: 30 }} />
}

export function Header({
  onClose,
  title,
  headerLeft = () => <HeaderCloseButton onPress={onClose} />,
  headerRight = () => <HeaderButtonEmptyView />,
  paddingTop,
}: {
  headerLeft?: () => React.ReactNode
  headerRight?: () => React.ReactNode
  onClose: () => void
  title: string | React.ReactNode
  paddingTop?: number
}) {
  // SafeAreaViewを使うとモーダルのトランジションがおかしくなるのでinsetsを使う必要がある
  const insets = useSafeAreaInsets()
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: width < sm ? 16 : 24,
        borderBottomWidth: 1,
        borderColor: Colors.border,
        paddingTop: paddingTop ?? Math.max(insets.top + 4, 24),
      }}
    >
      {headerLeft()}
      {typeof title === 'string' ? (
        <Text
          style={{
            fontWeight: '600',
            fontSize: width < sm ? 18 : 22,
            color: Colors.primary,
          }}
        >
          {title}
        </Text>
      ) : (
        title
      )}
      {headerRight()}
    </View>
  )
}

export function HeaderNavigationBackButton() {
  const navigation = useNavigation()
  return (
    <HeaderBackButton
      onPress={() => {
        if (navigation.canGoBack()) navigation.goBack()
      }}
    />
  )
}
