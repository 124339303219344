// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat_connection/table_seat_connection_resource.proto" (package "auto_reserve.restaurants.table_seat_connection", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource
 */
export interface TableSeatConnectionResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int32 max_party_size = 2;
   */
  maxPartySize: number;
  /**
   * @generated from protobuf field: int32 min_party_size = 3;
   */
  minPartySize: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource.TableSeatResource table_seats = 6;
   */
  tableSeats: TableSeatConnectionResource_TableSeatResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource.TableSeatResource
 */
export interface TableSeatConnectionResource_TableSeatResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatConnectionResource$Type extends MessageType<TableSeatConnectionResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "max_party_size",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 3,
          name: "min_party_size",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 5, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 6,
          name: "table_seats",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TableSeatConnectionResource_TableSeatResource,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatConnectionResource>,
  ): TableSeatConnectionResource {
    const message = {
      id: "",
      maxPartySize: 0,
      minPartySize: 0,
      tableSeats: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatConnectionResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatConnectionResource,
  ): TableSeatConnectionResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int32 max_party_size */ 2:
          message.maxPartySize = reader.int32();
          break;
        case /* int32 min_party_size */ 3:
          message.minPartySize = reader.int32();
          break;
        case /* google.protobuf.Timestamp created_at */ 4:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 5:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* repeated auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource.TableSeatResource table_seats */ 6:
          message.tableSeats.push(
            TableSeatConnectionResource_TableSeatResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatConnectionResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int32 max_party_size = 2; */
    if (message.maxPartySize !== 0)
      writer.tag(2, WireType.Varint).int32(message.maxPartySize);
    /* int32 min_party_size = 3; */
    if (message.minPartySize !== 0)
      writer.tag(3, WireType.Varint).int32(message.minPartySize);
    /* google.protobuf.Timestamp created_at = 4; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 5; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource.TableSeatResource table_seats = 6; */
    for (let i = 0; i < message.tableSeats.length; i++)
      TableSeatConnectionResource_TableSeatResource.internalBinaryWrite(
        message.tableSeats[i],
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource
 */
export const TableSeatConnectionResource =
  new TableSeatConnectionResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableSeatConnectionResource_TableSeatResource$Type extends MessageType<TableSeatConnectionResource_TableSeatResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource.TableSeatResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
      ],
    );
  }
  create(
    value?: PartialMessage<TableSeatConnectionResource_TableSeatResource>,
  ): TableSeatConnectionResource_TableSeatResource {
    const message = { id: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<TableSeatConnectionResource_TableSeatResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TableSeatConnectionResource_TableSeatResource,
  ): TableSeatConnectionResource_TableSeatResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 3:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 4:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TableSeatConnectionResource_TableSeatResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* google.protobuf.Timestamp created_at = 3; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 4; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource.TableSeatResource
 */
export const TableSeatConnectionResource_TableSeatResource =
  new TableSeatConnectionResource_TableSeatResource$Type();
