import React from 'react'
import { TextStyle, View } from 'react-native'
import { useSyncFailedReservations } from 'models/GourmetSiteSyncReservations'
import { useRestaurantId } from 'modules/useRestaurantId'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function SyncFailedReservationTitle({
  textStyle,
}: {
  textStyle?: TextStyle
}) {
  const restaurantId = useRestaurantId()
  const { pagination } = useSyncFailedReservations(restaurantId)
  const showBadge = (pagination?.totalCount ?? 0) > 0

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text style={[textStyle]}>{t('連携ステータス')}</Text>
      {showBadge && (
        <View
          style={{
            width: 18,
            height: 18,
            borderRadius: 9,
            backgroundColor: Colors.caution,
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
          }}
        >
          <Text
            style={{ color: Colors.white, fontSize: 10, fontWeight: '600' }}
          >
            {pagination?.totalCount ?? 0}
          </Text>
        </View>
      )}
    </View>
  )
}
