import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import Title from '@hello-ai/ar_shared/src/components/ForR/Shared/Title'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useRestaurantCrewMembers } from '../../../models/RestaurantCrewMembers'
import React from 'react'
import { View, FlatList } from 'react-native'
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  container: {
    padding: width < sm ? 24 : 48,
    backgroundColor: Colors.bgBlack,
    flexGrow: 1,
    rowGap: 16,
  },
  helperText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    width: width < sm ? '100%' : 400,
  },

  tableContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: 24,
    marginBottom: 0,
    paddingBottom: 0,
    borderRadius: 8,
  },
  header: {
    backgroundColor: Colors.white,
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  footer: {
    backgroundColor: Colors.white,
    marginTop: -16,
    paddingVertical: 16,
  },
  headerText: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.black,
    lineHeight: 22,
    flex: 1,
  },
  editButton: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
    paddingHorizontal: 0,
  },
  editButtonText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: Colors.primary,
  },
  row: {
    paddingVertical: 24,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  itemText: {
    fontSize: 18,
    lineHeight: 27,
    color: Colors.black,
  },
}))

interface CrewMemberListProps {
  restaurantId: number
  onClickEdit: () => void
}

export default function CrewMemberList({
  restaurantId,
  onClickEdit,
}: CrewMemberListProps) {
  const style = useResponsiveStyle(responsiveStyle)
  const { restaurantCrewMembers, pagination } =
    useRestaurantCrewMembers(restaurantId)

  return (
    <View style={style.container}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Title
          title={t('担当者一覧')}
          helperText={t(
            '予約や会計時の担当者一覧です。追加は「担当者を編集する」画面からメールアドレス不要で追加することができます。'
          )}
          helperTextStyle={style.helperText}
        />
        <Button mode="text" style={style.editButton} onPress={onClickEdit}>
          <FontAwesomeIcon icon={faGear} color={Colors.primary} size={20} />
          <Text style={style.editButtonText}>{t('担当者を編集する')}</Text>
        </Button>
      </View>
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={style.tableContainer}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          <View style={style.header}>
            <Text style={style.headerText}>担当者</Text>
          </View>
        }
        data={restaurantCrewMembers}
        renderItem={({ item }) => (
          <View style={style.row}>
            <Text style={style.itemText}>{item.name}</Text>
          </View>
        )}
        ListFooterComponent={
          pagination == null ? null : (
            <View style={style.footer}>
              <Pagination
                currentPage={pagination.currentPage}
                totalPage={pagination.totalPages}
                setPage={pagination.setPage}
              />
            </View>
          )
        }
      />
    </View>
  )
}
