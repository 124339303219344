import React, { ComponentProps } from 'react'
import { View } from 'react-native'
import { ExecuteHistory } from '../../../../types/ForR/SiteController'
import { t } from '../../../../modules/i18n'
import { Colors } from '../../../../constants/Colors'
import dayjs from '../../../../modules/dayjs'
import { Pagination } from '../../../Pagination'
import { Text } from '../../../Text'

interface HistoryListViewProps {
  histories?: ExecuteHistory[]
  paginationProps: ComponentProps<typeof Pagination>
}

export default function ExecuteHistoryListView({
  histories,
  paginationProps,
}: HistoryListViewProps) {
  return (
    <View>
      <View
        style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
            color: Colors.black,
          }}
        >
          {t('更新履歴')}
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontWeight: '300',
            marginLeft: 24,
            alignSelf: 'flex-end',
          }}
        >
          {t('最終更新日：{{datetime}}', {
            datetime:
              histories?.[0]?.executed_at == null
                ? '----/--/-- --:--'
                : dayjs(histories?.[0]?.executed_at)
                    .tz()
                    .format('YYYY/MM/DD HH:mm'),
          })}
        </Text>
      </View>
      <View
        style={{
          backgroundColor: Colors.white,
          padding: 16,
          borderRadius: 8,
          borderWidth: 0.5,
          borderColor: Colors.border,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            columnGap: 16,
            borderBottomWidth: 0.5,
            borderColor: Colors.border,
            paddingBottom: 16,
          }}
        >
          <Text
            style={{ fontSize: 14, fontWeight: '600', lineHeight: 22, flex: 1 }}
          >
            {t('更新者')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              flex: 1,
            }}
          >
            {t('内容')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              flex: 1,
            }}
          >
            {t('更新履歴')}
          </Text>
        </View>
        <View
          style={[
            histories?.length !== 0 && {
              marginBottom: 16,
              borderBottomWidth: 0.5,
              borderColor: Colors.border,
            },
          ]}
        >
          {histories?.length === 0 && (
            <Text
              style={{
                fontSize: 16,
                fontWeight: '300',
                color: Colors.black60,
                paddingVertical: 80,
                textAlign: 'center',
              }}
            >
              更新履歴はありません
            </Text>
          )}
          {histories?.map((history) => (
            <View
              key={history.id}
              style={{ flexDirection: 'row', paddingVertical: 20 }}
            >
              <Text style={{ flex: 1 }}>
                {history.business_user?.name ?? '-'}
              </Text>
              <Text style={{ flex: 1 }}>{history.action_translated}</Text>
              <Text style={{ flex: 1 }}>
                {dayjs(history.executed_at).tz().format('YYYY/MM/DD HH:mm')}
              </Text>
            </View>
          ))}
        </View>
        {histories?.length !== 0 && <Pagination {...paginationProps} />}
      </View>
    </View>
  )
}
