import React from 'react'
export function useEffectEvent<T extends (...args: any[]) => any>(fn: T) {
  const ref = React.useRef<T | null>(null)
  React.useLayoutEffect(() => {
    ref.current = fn
  }, [fn])
  return React.useCallback((...args: Parameters<T>): ReturnType<T> => {
    const f = ref.current
    return f!(...args)
  }, [])
}
