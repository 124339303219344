import React from 'react'
import { useParams } from 'react-router-dom'
import { View, ScrollView } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { DetailRowView } from '@hello-ai/for_r_app/src/components/Restaurant/TableBalancingCashDrawer/DetailRowView'
import { useBalancingCashDrawer } from 'models/TableBalancingCashDrawer'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function TableBalancingCashDrawersShow() {
  const { id: restaurantId, balancing_cash_drawer_id: balancingCashDrawerId } =
    useParams<{
      id: string
      date: string
      balancing_cash_drawer_id: string
    }>()
  const { width, sm } = useResponsive()
  const { balancingCashDrawer } = useBalancingCashDrawer(
    restaurantId,
    balancingCashDrawerId
  )
  const isCashDrawerClosed =
    balancingCashDrawer &&
    (balancingCashDrawer.balancing_type === 'close' ||
      balancingCashDrawer.balancing_type === 'open_and_close')
  const screenTitle = isCashDrawerClosed ? t('締め処理') : t('点検')

  if (balancingCashDrawer == null) return <Loading />

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: 'rgba(68, 68, 68, 0.06)' }}
      contentContainerStyle={{
        marginHorizontal: 48,
        marginVertical: 40,
      }}
    >
      <View>
        <Text style={{ fontSize: width < sm ? 18 : 22, fontWeight: '600' }}>
          {screenTitle}
        </Text>
      </View>
      <ShadowBox style={{ marginTop: 16, padding: 0 }}>
        <View
          style={{
            paddingHorizontal: 32,
            paddingVertical: 32,
            borderStyle: 'solid',
            borderBottomWidth: 0.5,
            borderBottomColor: 'rgba(0, 0, 0, 0.16)',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 12,
              alignItems: 'center',
            }}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 18, fontWeight: '600' }}>
                {t('日時')}
              </Text>
            </View>
            <View style={{ flex: 9, justifyContent: 'flex-start' }}>
              <Text>
                {dayjs(balancingCashDrawer.created_at).format(
                  // eslint-disable-next-line ar-i18n/require-translation-ja
                  'M月DD日 (dd) HH:mm'
                )}
              </Text>
            </View>
          </View>
          <View
            style={[
              { flexDirection: 'row', marginTop: 12, alignItems: 'center' },
              !!balancingCashDrawer.memo && { marginBottom: 12 },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 18, fontWeight: '600' }}>
                {t('担当者')}
              </Text>
            </View>
            <View style={{ flex: 9, justifyContent: 'flex-start' }}>
              <Text>
                {balancingCashDrawer.restaurant_crew_member?.name ??
                  t('（削除済みユーザー）')}
              </Text>
            </View>
          </View>
          {!!balancingCashDrawer.memo && (
            <View
              style={{
                flexDirection: 'row',
                marginTop: 12,
                alignItems: 'center',
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 18, fontWeight: '600' }}>
                  {t('メモ')}
                </Text>
              </View>
              <View style={{ flex: 9, justifyContent: 'flex-start' }}>
                <Text>{balancingCashDrawer.memo}</Text>
              </View>
            </View>
          )}
        </View>
        <DetailRowView balancingCashDrawer={balancingCashDrawer} />
      </ShadowBox>
    </ScrollView>
  )
}
