import React from 'react'
import { Modal } from '@hello-ai/ar_shared/src/components/Modal'
import {
  HeaderCloseButton,
  ModalHeader,
} from '@hello-ai/ar_shared/src/components/ModalCenter'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { CalendarContextProvider } from './CalendarContext'
import CalendarHeader from './CalendarHeader'
import { useRestaurantCourses } from '../../../models/RestaurantCourse'
import Calendar from './Calendar'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

interface SupplyCalendarModalProps {
  isVisible: boolean
  onClose: () => void
  restaurantId: number
}

export default function SupplyCalendarModal({
  isVisible,
  onClose,
  restaurantId,
}: SupplyCalendarModalProps) {
  const { restaurantCourses } = useRestaurantCourses(restaurantId)

  if (restaurantCourses == null) return null

  return (
    <Modal
      isVisible={isVisible}
      style={{ backgroundColor: Colors.white, margin: 0 }}
      contentContainerStyle={{
        justifyContent: 'flex-start',
        height: '100%',
      }}
    >
      <ModalHeader
        headerLeft={<HeaderCloseButton onPress={onClose} />}
        style={{ borderBottomWidth: 0.5, borderColor: Colors.border }}
      >
        {t('コース提供可能日を設定')}
      </ModalHeader>
      <CalendarContextProvider
        restaurantId={restaurantId}
        restaurantCourses={restaurantCourses}
      >
        <CalendarHeader />
        <Calendar />
      </CalendarContextProvider>
    </Modal>
  )
}
