import React, { useState } from 'react'
import { EditCustomer } from '@hello-ai/for_r_app/src/components/Customers/Edit'
import { CustomerAttributes } from '@hello-ai/for_r_app/src/components/Customers/Attributes'
import { EditCustomerNoteTemplate } from '@hello-ai/for_r_app/src/components/Customers/EditCustomerNoteTemplate'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router-dom'
import { useCustomer } from '@hello-ai/for_r_app/src/models/Customer'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import ModalCenter from '@hello-ai/for_r_app/src/components/Shared/ModalCenter'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export default function CustomerEditPage() {
  const { customerId } = useParams<{
    customerId: string
  }>()
  const restaurantId = useRestaurantId()
  const { customer } = useCustomer(restaurantId, customerId)

  const [isShowAttributesModal, setIsShowAttributesModal] = useState(false)
  const [isShowNoteTemplateModal, setIsShowNoteTemplateModal] = useState(false)

  if (!customer) {
    return <Loading />
  }

  return (
    <>
      <EditCustomer
        restaurantId={restaurantId}
        customer={customer}
        onPressCustomerAttributes={() => {
          setIsShowNoteTemplateModal(false)
          setIsShowAttributesModal(true)
        }}
        onPressNoteTemplateEdit={() => {
          setIsShowAttributesModal(false)
          setIsShowNoteTemplateModal(true)
        }}
      />
      <ModalCenter
        height="80vh"
        isModalVisible={isShowAttributesModal}
        onClose={() => setIsShowAttributesModal(false)}
        title={t('お客様属性')}
      >
        <CustomerAttributes
          restaurantId={restaurantId}
          onClose={() => setIsShowAttributesModal(false)}
        />
      </ModalCenter>
      <ModalCenter
        height="80vh"
        isModalVisible={isShowNoteTemplateModal}
        onClose={() => setIsShowNoteTemplateModal(false)}
        title={t('テンプレートを編集')}
      >
        <EditCustomerNoteTemplate
          restaurantId={restaurantId}
          onComplete={() => setIsShowNoteTemplateModal(false)}
        />
      </ModalCenter>
    </>
  )
}
