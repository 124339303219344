import React from 'react'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { ReservationData } from '@hello-ai/for_r_app/src/components/Restaurant/Reservation/ListView/Content'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { Platform, StyleProp, ViewStyle } from 'react-native'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange'
import { RestaurantReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  button: {
    width: width >= sm ? 220 : '100%',
    height: width >= sm ? 36 : 48,
  },
  text: {
    fontWeight: '600',
    fontSize: width >= sm ? 14 : 16,
  },
}))

export function SmartPaymentButton({
  onPressSmartPayment,
}: {
  onPressSmartPayment: () => void
}) {
  const style = useResponsiveStyle(responsiveStyle)
  return (
    <Button
      style={style.button}
      textStyle={style.text}
      onPress={onPressSmartPayment}
    >
      {t('スマート決済で会計する')}
    </Button>
  )
}

export default function SmartPaymentAvailableButton({
  restaurantReservation,
  onPressSmartPayment,
  buttonStyle,
}: {
  restaurantReservation: ReservationData
  onPressSmartPayment: () => void
  buttonStyle?: StyleProp<ViewStyle>
}) {
  const style = useResponsiveStyle(responsiveStyle)
  if (restaurantReservation?.smart_payment == null) {
    return null
  }
  switch (restaurantReservation?.smart_payment?.status) {
    case 'paid':
      return (
        <Button mode="outline" style={[style.button, buttonStyle]}>
          <FontAwesomeIcon icon={faCheck} size={16} color={Colors.primary} />
          <Text style={[style.text, { color: Colors.primary, marginLeft: 4 }]}>
            {t('支払い済み(¥{{amount}})', {
              amount: numberWithDelimiter(
                restaurantReservation.smart_payment.amount
              ),
            })}
          </Text>
        </Button>
      )
    case 'canceled':
      return (
        <Button disabled style={[style.button, buttonStyle]}>
          <FontAwesomeIcon
            icon={faMinusCircle}
            size={16}
            color={Colors.white}
          />
          <Text style={[style.text, { color: Colors.white, marginLeft: 4 }]}>
            {t('スマート決済中止')}
          </Text>
        </Button>
      )
    case 'failed':
    case 'require_three_d_secure':
      return (
        <Button
          mode="outline"
          style={[
            style.button,
            { backgroundColor: Colors.white, borderColor: Colors.caution },
            buttonStyle,
          ]}
        >
          <FontAwesomeIcon
            icon={faMinusCircle}
            size={16}
            color={Colors.caution}
          />
          <Text style={[style.text, { marginLeft: 4, color: Colors.caution }]}>
            {t('エラー(¥{{amount}})', {
              amount: numberWithDelimiter(
                restaurantReservation.smart_payment.amount
              ),
            })}
          </Text>
        </Button>
      )
  }

  return <SmartPaymentButton onPressSmartPayment={onPressSmartPayment} />
}

export function RevisionSmartPaymentButton({
  restaurantId,
  restaurantReservation,
}: {
  restaurantId: number
  restaurantReservation: Pick<RestaurantReservation, 'id' | 'smart_payment'>
}) {
  const style = useResponsiveStyle(responsiveStyle)
  const navigation = useNavigation()
  const navigate = useNavigate()
  const onPressRevisionSmartPayment = () => {
    if (restaurantReservation.smart_payment == null) return
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/revision_smart_payment`
      )
    } else {
      navigation.navigate('RevisionSmartPayment', {
        restaurantReservationId: restaurantReservation.id,
      })
    }
  }
  return (
    <Button
      onPress={onPressRevisionSmartPayment}
      mode="text"
      style={{ paddingHorizontal: 0 }}
    >
      <FontAwesomeIcon
        icon={faExchange}
        size={16}
        color={Colors.primary}
        style={{ transform: [{ rotate: '90deg' }], marginRight: 2 }}
      />
      <Text style={[style.text, { color: Colors.primary }]}>
        {t('金額修正')}
      </Text>
    </Button>
  )
}

export function CancelSmartPayment({
  onPressCancel,
}: {
  onPressCancel: () => void
}) {
  return (
    <Button
      mode="text"
      style={{ borderColor: Colors.caution, columnGap: 6 }}
      onPress={onPressCancel}
    >
      <FontAwesomeIcon icon={faMinusCircle} size={16} color={Colors.caution} />
      <Text style={{ color: Colors.caution }}>{t('スマート決済をやめる')}</Text>
    </Button>
  )
}
