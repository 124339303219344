import { faStore } from '@fortawesome/pro-regular-svg-icons'
import { CountryCodes } from '@hello-ai/ar_shared/src/components/CountryModal'
import { View } from 'react-native'
import PhoneNumberInput from 'components/Shared/PhoneNumberInput'
import React, { useMemo, useState } from 'react'
import { useRestaurantOwnershipCandidates } from 'models/RestaurantOwner'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface SearchRestaurantProps {
  defaultRestaurant?: CandidatesRestaurant
  onSelectRestaurant: (restaurant: CandidatesRestaurant) => void
}

export default function SearchRestaurant({
  defaultRestaurant,
  onSelectRestaurant,
}: SearchRestaurantProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultRestaurant?.phone.number != null
      ? `0${defaultRestaurant?.phone.number}`
      : ''
  )
  const [country, setCountry] = useState(
    CountryCodes.find(
      (c) => c.code === (defaultRestaurant?.phone.country ?? 'JP')
    ) ?? CountryCodes[0]
  )
  const query = useMemo(() => {
    if (phoneNumber === '') return undefined
    return {
      phone_number: `${country.dial_code}${phoneNumber.substring(1)}`,
    }
  }, [phoneNumber, country])
  const { data: restaurants, mutate } = useRestaurantOwnershipCandidates(query)

  const loading = query !== undefined && restaurants === undefined

  return (
    <>
      <View>
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
          }}
        >
          {t('店舗の電話番号から検索し、該当する店舗を選択してください')}
        </Text>
        <FormGroup
          formLabel={<FormLabel value={t('電話番号')} />}
          mode="vertical"
          style={{
            width: '100%',
            marginVertical: 40,
            backgroundColor: Colors.white,
          }}
        >
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            country={country}
            setCountry={setCountry}
            onPressEnter={mutate}
          />
        </FormGroup>
      </View>
      <View style={{ width: '100%' }}>
        {loading && <Loading />}
        {loading === false && restaurants?.length === 0 && (
          <View
            style={{
              height: 84,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontWeight: '300',
                color: Colors.black60,
              }}
            >
              {t('店舗が見つかりません')}
            </Text>
          </View>
        )}
        {restaurants?.map((restaurant) => (
          <View
            key={restaurant.id}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingVertical: 20,
              paddingHorizontal: 24,
              borderTopColor: Colors.border,
              borderTopWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <FontAwesomeIcon
                  icon={faStore}
                  color={Colors.primary}
                  style={{
                    width: 24,
                    height: 24,
                    marginRight: 8,
                  }}
                />
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: '300',
                    color: Colors.primary,
                    marginRight: 16,
                  }}
                >
                  {restaurant.name_i18n}
                </Text>
              </View>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: '300',
                  color: Colors.black60,
                }}
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                {restaurant.category != null ? `${restaurant.category} / ` : ''}
                {restaurant.address_short}
              </Text>
            </View>
            <Button
              mode={restaurant.has_owner ? 'text' : 'contained'}
              style={{ width: 120, paddingHorizontal: 16, paddingVertical: 6 }}
              textStyle={{
                fontSize: 14,
                fontWeight: '300',
                lineHeight: 24,
                color: restaurant.has_owner ? Colors.textDisabled : undefined,
              }}
              onPress={() => onSelectRestaurant(restaurant)}
              disabled={restaurant.has_owner}
            >
              {restaurant.has_owner ? t('認証済み') : t('選択して次へ')}
            </Button>
          </View>
        ))}
      </View>
    </>
  )
}
