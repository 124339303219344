import React, { useCallback } from 'react'
import { ModalHeader } from 'components/Shared/Modal'
import SelectRequestMethod, { RequestMethod } from './SelectRequestMethod'
import ByPhoneCall from './ByPhoneCall'
import ByBusinessPermit from './ByBusinessPermit'
import { Modal } from '@hello-ai/ar_shared/src/components/Modal'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface AddRestaurantProps {
  isVisible: boolean
  onClose: () => void
}

export default function AddRestaurant({
  isVisible,
  onClose,
}: AddRestaurantProps) {
  const [method, setMethod] = React.useState<RequestMethod | null>(null)
  const cleanUp = useCallback(() => {
    setMethod(null)
    onClose()
  }, [onClose])

  const component = React.useMemo(() => {
    switch (method) {
      case 'phone_call':
        return (
          <ByPhoneCall
            onPressBack={() => setMethod(null)}
            onSubmitEnd={cleanUp}
          />
        )
      case 'business_permit':
        return (
          <ByBusinessPermit
            onPressBack={() => setMethod(null)}
            onSubmitEnd={cleanUp}
          />
        )
      default:
        return <SelectRequestMethod onSelectMethod={setMethod} />
    }
  }, [method, cleanUp])

  const title = React.useMemo(() => {
    switch (method) {
      case 'phone_call':
        return t('電話番号による認証')
      default:
        return t('店舗アカウントの追加')
    }
  }, [method])

  return (
    <Modal
      isVisible={isVisible}
      style={{
        backgroundColor: Colors.white,
        margin: 0,
      }}
      contentContainerStyle={{
        width: '100%',
        height: '100%',
      }}
    >
      <ModalHeader title={title} onClose={cleanUp} />
      {component}
    </Modal>
  )
}
