import useSWR from '../modules/swr'
import { Methods } from '@hello-ai/api_table/api_table/owners/_owner_id@number/sales_dashboard/_report_type@string'
import { createAspidaSWRParameters } from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { client } from '../modules/api'
import { useAuth } from './Auth'

export type SalesDashboardQuery = Methods['get']['query']
export type SalesDashboardData = Methods['get']['resBody']

export function useRestaurantsSalesDashboard(
  type: string,
  params: SalesDashboardQuery
) {
  const { auth } = useAuth()

  const {
    data: salesData,
    error,
    isValidating,
    mutate,
  } = useSWR(
    ...createAspidaSWRParameters(
      auth != null
        ? client.owners._owner_id(auth.id).sales_dashboard._report_type(type)
        : null,
      { query: params }
    )
  )

  return {
    salesData,
    isValidating,
    error,
    mutate,
  }
}

export function fetchRestaurantsSalesDashboard(
  ownerId: number,
  type: string,
  params: SalesDashboardQuery
) {
  return client.owners
    ._owner_id(ownerId)
    .sales_dashboard._report_type(type)
    .get({
      query: params,
    })
}
