import React from 'react'
import StatusLabel from 'components/GourmetSite/AccountSettings/StatusLabel'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import SyncFailedReservations from 'pages/Restaurants/GourmetSiteSyncFailedReservations/GourmetSiteSyncFailedReservations'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('連携ステータス'),
    fullPath: `/restaurants/:id/gourmet_site/sync_failed_reservations`,
    relativePath: `/`,
    element: <SyncFailedReservations />,
    screenOptions: {
      headerRight: <StatusLabel />,
    },
  },
}

export default function GourmetSiteSyncFailedReservationStack() {
  return <Stack routes={routes} />
}
