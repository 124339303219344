import React, { useRef, useState } from 'react'
import { View, Image, Alert, Platform } from 'react-native'
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Spinner } from '@hello-ai/ar_shared/src/components/Spinner'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  Customer as CustomerModel,
  useCustomerProfile,
} from '../../models/Customer'
import {
  RestaurantCustomerImage as RestaurantCustomerImageModel,
  useRestaurantCustomerImages,
  destroyRestaurantCustomerImage,
} from '../../models/RestaurantCustomerImage'
import ModalCenter from './ModalCenter'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { displayToastSuccess } from './Toast'
import {
  CustomerTypeLabel,
  getCustomerDisplayName,
  getReservationName,
} from '../Customers/Customer'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { Gender } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomerProfile'
import { useRestaurantAttributes } from '../../models/CustomerAttributes'
import {
  isAvailableShowVisitCount,
  useRestaurant,
} from '../../models/Restaurant'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useNavigation } from '../../modules/navigation/useNavigation'
import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { labelColors } from '../../components/Customers/Attributes'

function ImageModal({
  restaurantCustomerImage,
  isModalVisible,
  onClose,
}: {
  restaurantCustomerImage: RestaurantCustomerImageModel | null
  isModalVisible: boolean
  onClose: () => void
}) {
  const token = useToken()
  const alertRef = useRef<AlertMethods>(null)
  const destroy = () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    alert(t('本当に削除しますか？'), undefined, [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (restaurantCustomerImage?.id == null) return
          const { error } = await destroyRestaurantCustomerImage(
            token,
            restaurantCustomerImage.id
          )
          if (error != null) return
          displayToastSuccess(t('画像を削除しました'))
          onClose()
        },
      },
    ])
  }

  if (restaurantCustomerImage == null) return null

  return (
    <ModalCenter
      /* eslint-disable ar-i18n/require-translation-ja */
      title={dayjs(restaurantCustomerImage.created_at).format('YYYY年MM月DD日')}
      /* eslint-enable ar-i18n/require-translation-ja */
      isModalVisible={isModalVisible}
      onClose={onClose}
      width="100%"
      height="100%"
      contentContainerStyle={{ height: '100%' }}
      headerRight={() => (
        <TouchableOpacity
          onPress={() => {
            destroy()
          }}
        >
          <Text
            style={{
              fontSize: 20,
              color: Colors.caution,
            }}
          >
            {t('削除')}
          </Text>
        </TouchableOpacity>
      )}
    >
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100% - 82px)',
        }}
      >
        <Image
          source={{ uri: restaurantCustomerImage.image.xl }}
          style={{
            width: '100%',
            height: '100%',
          }}
          resizeMode="contain"
        />
      </View>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </ModalCenter>
  )
}

export function CustomerLarge({
  restaurantId,
  customer,
  onCustomerUpdated,
}: {
  restaurantId: number
  customer: CustomerModel
  onCustomerUpdated?: () => void
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()

  const [isModalVisible, setModalVisible] = useState(false)
  const [customerImage, setCustomerImage] =
    useState<RestaurantCustomerImageModel | null>(null)
  const [isMoreInfoVisible, setMoreInfoVisible] = useState(false)

  const toggleImageModal = (customerImage: RestaurantCustomerImageModel) => {
    setModalVisible(!isModalVisible)
    setCustomerImage(customerImage)
  }
  const { width, sm } = useResponsive()

  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null || restaurant.reservation_book_plan_type === 'free'
  const { restaurantCustomerImages, mutate } = useRestaurantCustomerImages(
    restaurantId,
    customer.id
  )

  const { profile, mutate: mutateProfile } = useCustomerProfile(
    restaurantId,
    customer.id
  )
  const { data: attributes } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const selectedAttributes = attributes?.filter(
    (i) => i.display_in_list && profile?.custom_attributes?.includes(i.id)
  )

  const showReservationName =
    (customer.last_name != null && customer.last_name !== '') ||
    (customer.first_name != null && customer.first_name !== '')

  const [isAddingImage] = useState(false)

  const handlePress = () => {
    setMoreInfoVisible(!isMoreInfoVisible)
  }

  const infoItems = [
    {
      label: t('性別'),
      value: profile?.gender != null ? Gender[profile.gender] : t('未登録'),
    },
    {
      label: t('誕生日'),
      value: profile?.birthday != null ? `${profile.birthday}` : t('未登録'),
    },
    {
      label: t('結婚記念日'),
      value:
        profile?.wedding_anniversary != null
          ? `${profile.wedding_anniversary}`
          : t('未登録'),
    },
    {
      label: t('その他記念日'),
      value: profile?.anniversaries[0]
        ? profile.anniversaries.map((anniversary) => {
            return `${anniversary.date} ${anniversary.name}\n`
          })
        : t('未登録'),
    },
    {
      label: t('特徴'),
      value: profile?.feature,
    },
    {
      label: t('好きなもの'),
      value: profile?.likes,
    },
    {
      label: t('嫌いなもの'),
      value: profile?.dislikes,
    },
    {
      label: t('喫煙情報'),
      value:
        profile?.smoking === 'cigarette'
          ? t('有')
          : profile?.smoking === 'dont_smoke'
            ? t('無')
            : t('未登録'),
    },
    {
      label: t('領収書名'),
      value: profile?.receipt_title,
    },
    {
      label: t('会社名'),
      value: profile?.company,
    },
    {
      label: t('部署名'),
      value: profile?.section,
    },
    {
      label: t('役職名'),
      value: profile?.post,
    },
    {
      label: t('勤務先住所'),
      value:
        (profile?.company_postal_code != null &&
          profile.company_postal_code !== '') ||
        (profile?.company_address != null && profile.company_address !== '')
          ? `${profile.company_postal_code ?? ''}${
              profile.company_address ?? ''
            }`
          : t('未登録'),
    },
    {
      label: t('自宅住所'),
      value:
        (profile?.home_postal_code != null &&
          profile.home_postal_code !== '') ||
        (profile?.home_address != null && profile.home_address !== '')
          ? `${profile.home_postal_code ?? ''}${profile.home_address ?? ''}`
          : t('未登録'),
    },
    {
      label: t('X'),
      value: profile?.x_url,
    },
    {
      label: t('Facebook'),
      value: profile?.facebook_url,
    },
    {
      label: t('DM可否'),
      value: profile?.opt_in_dm
        ? t('可')
        : profile?.opt_in_dm === false
          ? t('不可')
          : t('未登録'),
    },
    {
      label: t('更新者'),
      value: profile?.updater,
    },
  ]

  const editButton = (
    <TouchableOpacity
      onPress={() => {
        if (Platform.OS === 'web') {
          navigate('./edit')
        } else {
          navigation.navigate('CustomerInformationModal', {
            customer,
            hasUser: customer.user != null,
            onCustomerUpdated: () => {
              onCustomerUpdated?.()
              mutateProfile()
            },
          })
        }
      }}
      style={{
        display: 'flex',
      }}
    >
      <Text
        style={{
          fontSize: 16,
          color: Colors.primary,
        }}
      >
        {t('編集')}
      </Text>
    </TouchableOpacity>
  )

  return (
    <View>
      <ShadowBox
        style={{
          paddingVertical: width < sm ? 16 : 24,
          paddingHorizontal: width < sm ? 16 : 24,
        }}
      >
        <View
          style={[
            width < sm
              ? {
                  paddingBottom: 16,
                }
              : {
                  flexDirection: 'row',
                  paddingBottom: 24,
                },
          ]}
        >
          <View
            style={[
              width < sm
                ? {
                    flex: 1,
                  }
                : {
                    flex: 5,

                    flexDirection: 'row',
                    alignItems: 'center',
                  },
            ]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Image
                  source={{
                    uri: customer.image_url,
                  }}
                  style={{
                    width: width < sm ? 48 : 60,
                    height: width < sm ? 48 : 60,
                    borderRadius: width < sm ? 48 : 60 / 2,
                  }}
                />
                <View
                  style={{
                    flex: 1,
                    marginLeft: width > sm ? 20 : 8,
                    rowGap: width > sm ? 12 : 0,
                  }}
                >
                  <View
                    style={{
                      flexDirection: width > sm ? 'row' : 'column',
                      alignItems: width > sm ? 'center' : 'flex-start',
                      gap: width > sm ? 12 : 0,
                    }}
                  >
                    <Text
                      style={[
                        {
                          flexShrink: 1,
                          fontSize: width < sm ? 18 : 22,
                          lineHeight: width < sm ? 21 : 27,
                          fontWeight: '600',
                        },
                        width < sm && { flexWrap: 'wrap', paddingRight: 40 },
                      ]}
                    >
                      {getCustomerDisplayName(customer)}
                    </Text>
                    {showReservationName && (
                      <Text
                        style={{
                          marginTop: width < sm ? 3 : 7,
                          lineHeight: width < sm ? 21 : 16,
                          fontSize: width < sm ? 14 : 16,
                        }}
                      >
                        {getReservationName(customer)}
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: width < sm ? 14 : 16,
                        color: Colors.secondaryBlack,
                        flexWrap: 'wrap',
                      }}
                    >
                      {customer.username}
                    </Text>
                    {(customer.user != null || customer.favoriting) && (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {customer.user != null && (
                          <CustomerTypeLabel
                            backgroundColor={Colors.primaryBg}
                            color={Colors.primary}
                          >
                            {t('ユーザー')}
                          </CustomerTypeLabel>
                        )}
                        {customer.favoriting && (
                          <CustomerTypeLabel
                            backgroundColor={Colors.accentBg}
                            color={Colors.accent}
                            style={{ marginLeft: 8 }}
                          >
                            {t('フォロー中')}
                          </CustomerTypeLabel>
                        )}
                      </View>
                    )}
                  </View>
                  {isAvailableShowVisitCount(restaurant) && (
                    <View style={{ flexDirection: 'row', columnGap: 12 }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: '600',
                          lineHeight: 24,
                        }}
                      >
                        {t('予約回数')}
                      </Text>
                      <Text
                        style={[
                          { fontWeight: '600' },
                          width < sm
                            ? {
                                fontSize: 14,
                                lineHeight: 21,
                              }
                            : {
                                fontSize: 16,
                                lineHeight: 24,
                              },
                        ]}
                      >
                        {t('{{count}}回', { count: customer.visit_count })}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          />
          {width >= sm ? (
            editButton
          ) : (
            <View style={{ position: 'absolute', right: 0 }}>{editButton}</View>
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            gap: width > sm ? 24 : 8,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              gap: width > sm ? 16 : 8,
            }}
          >
            <View style={{ width: width > sm ? 140 : 97 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {t('電話番号')}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {customer.phone_number_format ?? t('未登録')}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: width > sm ? 16 : 8,
            }}
          >
            <View style={{ width: width > sm ? 140 : 97 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {t('メールアドレス')}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {customer.email ?? t('未登録')}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: width > sm ? 16 : 8,
            }}
          >
            <View style={{ width: width > sm ? 140 : 97 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {t('アレルギー')}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {customer.allergy != null && customer.allergy !== ''
                  ? customer.allergy
                  : t('未登録')}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: width > sm ? 16 : 8,
            }}
          >
            <View style={{ width: width > sm ? 140 : 97 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {t('メモ')}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {customer.restaurant_customer_note?.content == null ||
                customer.restaurant_customer_note.content === ''
                  ? t('未記入')
                  : customer.restaurant_customer_note.content}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: width > sm ? 16 : 8,
            }}
          >
            <View style={{ width: width > sm ? 140 : 97 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: width > sm ? 16 : 14,
                }}
              >
                {t('写真')}
              </Text>
            </View>
            <View
              style={[
                (restaurantCustomerImages?.length ?? 0) > 0 && { height: 128 },
              ]}
            >
              {((restaurantCustomerImages?.length ?? 0) > 0 ||
                isAddingImage) && (
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{ flex: 1, paddingTop: 16 }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    {isAddingImage && (
                      <View
                        style={{
                          width: 168,
                          height: 112,
                          borderRadius: 8,
                          marginRight: 24,
                          borderColor: Colors.border,
                          borderWidth: 0.5,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Spinner size={24} color={Colors.primary} />
                      </View>
                    )}
                    {restaurantCustomerImages?.map((customerImage) => (
                      <View key={customerImage.id}>
                        <TouchableOpacity
                          onPress={() => {
                            toggleImageModal(customerImage)
                          }}
                        >
                          <Image
                            source={{ uri: customerImage.image.sm }}
                            style={{
                              width: 168,
                              height: 112,
                              borderRadius: 8,
                              marginRight: 24,
                            }}
                          />
                        </TouchableOpacity>
                        <Text
                          style={{
                            marginTop: 8,
                            fontSize: 14,
                          }}
                        >
                          {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
                          {dayjs(customerImage.created_at).format(
                            t('YYYY年MM月DD日')
                          )}
                        </Text>
                      </View>
                    ))}
                  </View>
                </ScrollView>
              )}
            </View>
          </View>
          {(!isFreePlan || !restaurant?.reservation_book_available) && (
            <View
              style={{
                flexDirection: 'row',
                gap: width > sm ? 16 : 8,
              }}
            >
              <View style={{ width: width > sm ? 140 : 97 }}>
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: width > sm ? 16 : 14,
                  }}
                >
                  {t('お客様属性')}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                {(selectedAttributes?.length ?? 0) > 0 ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: 8,
                      flexWrap: 'wrap',
                    }}
                  >
                    {selectedAttributes!.map((a) => (
                      <View
                        key={a.id}
                        style={{
                          height: 20,
                          borderRadius: 2,
                          backgroundColor:
                            labelColors[a.label_color].backgroundColor,
                          paddingHorizontal: 8,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            color: labelColors[a.label_color].text,
                            fontWeight: '600',
                          }}
                        >
                          {a.name}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : (
                  <Text
                    style={{
                      fontSize: width > sm ? 16 : 14,
                    }}
                  >
                    {t('未登録')}
                  </Text>
                )}
              </View>
            </View>
          )}
        </View>
        {isMoreInfoVisible && (
          <View
            style={{
              flexDirection: 'column',
              gap: width > sm ? 24 : 8,
              marginTop: 24,
            }}
          >
            {infoItems.map((item) => (
              <View
                key={item.label}
                style={{
                  flexDirection: 'row',
                  gap: width > sm ? 16 : 8,
                }}
              >
                <View style={{ width: width > sm ? 140 : 97 }}>
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: width > sm ? 16 : 14,
                    }}
                  >
                    {item.label}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontSize: width > sm ? 16 : 14,
                    }}
                  >
                    {item.value ?? t('未登録')}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        )}
        <View>
          <TouchableOpacity
            onPress={handlePress}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 24,
              gap: 8,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: Colors.primary,
              }}
            >
              {isMoreInfoVisible ? t('閉じる') : t('顧客情報をもっと見る')}
            </Text>
            <FontAwesomeIcon
              icon={isMoreInfoVisible ? faChevronUp : faChevronDown}
              color={Colors.primary}
              size={16}
            />
          </TouchableOpacity>
        </View>
      </ShadowBox>
      <ImageModal
        restaurantCustomerImage={customerImage}
        isModalVisible={isModalVisible}
        onClose={() => {
          setCustomerImage(null)
          setModalVisible(false)
          mutate()
        }}
      />
    </View>
  )
}
