import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import React, { useState } from 'react'
import { View } from 'react-native'
import * as DocumentPicker from 'expo-document-picker'
import FileItem from './FileItem'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface UploadFileItem {
  file: File
  restaurant?: CandidatesRestaurant
}

interface UploadFileProps {
  onSubmit: (items: Required<UploadFileItem>[]) => void
}

function EmptyFile({
  onSelectFiles,
}: {
  onSelectFiles: (files: File[]) => void
}) {
  return (
    <View
      style={{
        width: '100%',
        paddingVertical: 30,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: Colors.border,
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: '300',
          color: Colors.black60,
        }}
      >
        {t('ファイルがありません')}
      </Text>
      <Text
        style={{
          fontSize: 14,
          fontWeight: '300',
          color: Colors.black60,
          marginVertical: 20,
        }}
      >
        {t('※複数ファイルアップロード可能です')}
      </Text>
      <Button
        mode="outline"
        style={{
          height: 48,
        }}
        textStyle={{
          fontSize: 16,
          fontWeight: '300',
        }}
        onPress={async () => {
          const result = await DocumentPicker.getDocumentAsync({
            multiple: true,
          })
          if (result.output != null) {
            onSelectFiles(Array.from(result.output))
          }
        }}
      >
        {t('ファイルを選択')}
      </Button>
    </View>
  )
}

export default function UploadFile({ onSubmit }: UploadFileProps) {
  const [items, setItems] = useState<UploadFileItem[]>([])
  const isDisableSubmit =
    items.length === 0 || items.some((item) => item.restaurant == null)

  return (
    <>
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
          color: Colors.black,
          marginBottom: 32,
          textAlign: 'center',
        }}
      >
        {t('追加したい店舗の営業許可証をアップロードしてください')}
      </Text>
      <Text
        style={{
          fontSize: 14,
          fontWeight: '300',
          marginBottom: 40,
          color: Colors.black,
          textAlign: 'center',
        }}
      >
        {t('認証までにはおよそ3営業日ほどかかります。')}
      </Text>
      <View
        style={{
          width: '100%',
          rowGap: 40,
        }}
      >
        {items.length === 0 && (
          <EmptyFile
            onSelectFiles={(files) => {
              setItems(files.map((file) => ({ file })))
            }}
          />
        )}
        {items.map((item, index) => (
          <FileItem
            key={index}
            file={item.file}
            selectedRestaurant={item.restaurant}
            onDelete={() => {
              setItems(items.filter((_, i) => i !== index))
            }}
            onSelectRestaurant={(restaurant) => {
              setItems(
                items.map((item, i) =>
                  i === index ? { ...item, restaurant } : item
                )
              )
            }}
          />
        ))}
        {items.length > 0 && (
          <View
            style={{
              flexDirection: 'row',
              columnGap: 16,
              justifyContent: 'center',
            }}
          >
            <Button
              mode="outline"
              style={{ height: 48 }}
              onPress={async () => {
                const result = await DocumentPicker.getDocumentAsync({
                  multiple: true,
                })
                if (result.output != null) {
                  setItems([
                    ...items,
                    ...Array.from(result.output).map((file) => ({ file })),
                  ])
                }
              }}
            >
              {t('さらにファイルを追加')}
            </Button>
            <Button
              style={{ height: 48 }}
              disabled={isDisableSubmit}
              onPress={() => {
                onSubmit(items as Required<UploadFileItem>[])
              }}
            >
              {t('このファイルで認証する')}
            </Button>
          </View>
        )}
      </View>
    </>
  )
}
