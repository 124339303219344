import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { RestaurantCourseClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_service.client'
import axios, {
  setHeader,
  wrapResponse,
  wrapAspidaResponse,
} from '../modules/axios'
import { createRpcService } from '../modules/rpc'
import useSWR from '../modules/swr'
import { client } from '../modules/api'
import { createAspidaSWRParameters } from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { Methods as IndexMethods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/restaurant_courses/index'
import { Methods } from '@hello-ai/api_table/api_table/restaurants/_restaurant_id@number/restaurant_courses/_id@number'

export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantCourse'
export const restaurantCourseService = createRpcService(RestaurantCourseClient)
export const restaurantCourseClient = restaurantCourseService.client

export type RestaurantCourse = IndexMethods['get']['resBody'][number]
export type CreateParams = IndexMethods['post']['reqBody']
export type UpdateParams = Methods['patch']['reqBody']

export function useRestaurantCourses(restaurantId: number) {
  const token = useToken()
  setHeader({ token })
  const { data, mutate, error } = useSWR(
    ...createAspidaSWRParameters(
      client.restaurants._restaurant_id(restaurantId).restaurant_courses
    )
  )
  return {
    restaurantCourses: data,
    mutate,
    error,
  }
}

export function useReservationBookRestaurantCourses(
  restaurantId: number,
  partySize: number,
  reservedAt: string
) {
  const token = useToken()
  setHeader({ token })
  const { data, mutate, error } = useSWR(
    ...createAspidaSWRParameters(
      client.reservation_book.restaurants._restaurant_id(restaurantId)
        .restaurant_courses,
      {
        query: {
          party_size: partySize,
          reserved_at: reservedAt,
        },
      }
    )
  )
  return {
    restaurantCourses: data,
    mutate,
    error,
  }
}

export function useRestaurantCourse(restaurantId: number, id?: number) {
  const token = useToken()
  setHeader({ token })
  const { data, mutate, error } = useSWR(
    ...createAspidaSWRParameters(
      id != null
        ? client.restaurants
            ._restaurant_id(restaurantId)
            .restaurant_courses._id(id)
        : null
    )
  )

  return {
    restaurantCourse: data,
    mutate,
    error,
  }
}

export async function updateRestaurantCoursePosition(
  token: string,
  restaurantId: number,
  id: number,
  to: number
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/restaurant_courses/${id}/positions`,
      {
        position: to,
      }
    )
  )

  return {
    error,
  }
}

export async function createRestaurantCourse(
  token: string,
  restaurantId: number,
  params: CreateParams
) {
  setHeader({ token })
  const { error } = await wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .restaurant_courses.post({ body: params })
  )

  return {
    error,
  }
}

export async function updateRestaurantCourse(
  token: string,
  restaurantId: number,
  id: number,
  params: UpdateParams
) {
  setHeader({ token })
  return await wrapAspidaResponse(
    client.restaurants
      ._restaurant_id(restaurantId)
      .restaurant_courses._id(id)
      .patch({ body: params })
  )
}
