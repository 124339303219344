import React, { FC } from 'react'
import { View, GestureResponderEvent } from 'react-native'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Colors } from '../../constants/Colors'
import { FontAwesomeIcon } from '../FontAwesomeIcon'
import { TouchableOpacity } from '../Touchables'
import { useResponsive } from '../../modules/useResponsive'

type Props = {
  onPressGoBack?: (event: GestureResponderEvent) => void
  isListExist?: boolean
}

export function StepperBackButton({ onPressGoBack, isListExist }: Props) {
  const { width, sm } = useResponsive()
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        position: 'absolute',
        left: 16,
        bottom:
          width < sm
            ? !isListExist
              ? insets.bottom + 18
              : insets.bottom + 75
            : 16,
      }}
    >
      <TouchableOpacity
        style={{
          width: width < sm ? 40 : 48,
          height: width < sm ? 40 : 48,
          borderRadius: width < sm ? 40 / 2 : 48 / 2,
          backgroundColor: Colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowRadius: 4,
          shadowOpacity: 0.4,
          elevation: 4,
        }}
        onPress={onPressGoBack}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          size={width < sm ? 20 : 24}
          color="white"
        />
      </TouchableOpacity>
    </View>
  )
}
