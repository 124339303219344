import React, { useState, useEffect } from 'react'
import { View, Image } from 'react-native'
import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import {
  ToggleGroup,
  ToggleItem,
} from '@hello-ai/ar_shared/src/components/Toggle'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import {
  DEFAULT_TAX_RATE,
  REDUCED_TAX_RATE,
} from '@hello-ai/ar_shared/src/constants/TaxRates'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import dayjs from 'dayjs'

import {
  TakeoutProduct as TakeoutProductModel,
  TakeoutProductPricesPriceType,
  UpdateTakeoutProductFuncType,
} from 'models/TakeoutProduct'

import { TakeoutMenuPage } from 'models/TakeoutMenuPage'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function TakeoutMenuPageText({
  takeoutMenuPage,
}: {
  takeoutMenuPage: TakeoutMenuPage
}) {
  if (takeoutMenuPage.status !== 'published') {
    return null
  }
  return <Text>{takeoutMenuPage.name}</Text>
}

function StockToggle({
  tableProduct,
  onChangeInStock,
}: {
  tableProduct: TakeoutProductModel
  onChangeInStock: UpdateTakeoutProductFuncType
}) {
  const prevTableProduct = usePrevious(tableProduct)

  const [inStock, setInStock] = useState(tableProduct?.in_stock)

  useEffect(() => {
    if (prevTableProduct?.in_stock !== tableProduct?.in_stock) {
      setInStock(tableProduct?.in_stock)
    }
  }, [prevTableProduct?.in_stock, tableProduct?.in_stock])

  return (
    <ToggleGroup
      value={inStock}
      onValueChange={async (value: boolean) => {
        if (value === inStock) {
          return
        }
        setInStock(value)
        onChangeInStock(tableProduct.id, {
          out_of_stock_at: value ? null : dayjs().toISOString(),
        })
      }}
    >
      <ToggleItem
        value
        style={[
          {
            width: 124,
            paddingHorizontal: 0,
          },
        ]}
        textStyle={{ fontSize: 16 }}
        borderColor={Colors.black}
        color="white"
        textColor={Colors.black}
        selectedBorderColor={Colors.black}
        selectedColor={Colors.black}
        selectedTextColor="white"
        text={t('注文可能')}
      />
      <ToggleItem
        value={false}
        style={[
          {
            width: 124,
            paddingHorizontal: 0,
          },
        ]}
        textStyle={{ fontSize: 16 }}
        borderColor={Colors.black}
        color="white"
        textColor={Colors.black}
        selectedBorderColor={Colors.caution}
        selectedColor={Colors.caution}
        selectedTextColor="white"
        text={t('本日売り切れ')}
      />
    </ToggleGroup>
  )
}

function RestoreToggle({
  tableProduct,
  onChangeStatus,
}: {
  tableProduct: TakeoutProductModel
  onChangeStatus: UpdateTakeoutProductFuncType
}) {
  const alertPublish = () => {
    const params = {
      status: 'published',
    }
    Alert.alert(t('本当に公開状態に戻しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => {
          onChangeStatus(tableProduct.id, params)
        },
      },
    ])
  }

  return (
    <Button
      height={48}
      width={124}
      mode="outline"
      variant="danger-secondary"
      onPress={() => alertPublish()}
    >
      <Text style={{ color: Colors.caution, fontWeight: 'normal' }}>
        {t('元に戻す')}
      </Text>
    </Button>
  )
}

function getPriceText(price?: TakeoutProductPricesPriceType) {
  if (price == null) {
    return '-'
  }
  return price.price_type === 'fixed'
    ? t('{{price}}円', { price: numberWithDelimiter(price.price) })
    : 'ASK'
}

function getTaxRateText(price?: TakeoutProductPricesPriceType) {
  if (price == null) {
    return '-'
  }
  return `${price.tax_included ? t('税込') : t('税抜')}${
    price.tax_type === 'default' ? DEFAULT_TAX_RATE : REDUCED_TAX_RATE
  }%`
}

export default function TakeoutProduct({
  onPress,
  takeoutProduct,
  onChangeInStock,
  onChangeStatus,
}: {
  onPress: () => void
  takeoutProduct?: TakeoutProductModel
  onChangeInStock?: UpdateTakeoutProductFuncType
  onChangeStatus?: UpdateTakeoutProductFuncType
}) {
  const { width, sm } = useResponsive()

  if (!takeoutProduct) return null

  return (
    <TouchableOpacity onPress={() => onPress()}>
      <View
        style={[
          width >= sm && {
            flexDirection: 'row',
            alignItems: 'center',
          },
        ]}
      >
        {takeoutProduct.image && (
          <Image
            source={{ uri: takeoutProduct.image.sm }}
            style={{
              width: 80,
              height: 80,
              borderRadius: 4,
              marginRight: 16,
            }}
          />
        )}
        <View
          style={[
            {
              flex: 1,
            },
            width < sm && {
              marginTop: 16,
            },
          ]}
        >
          <Text style={{ fontWeight: '600' }}>{takeoutProduct.name}</Text>
          <Text style={{ marginTop: 8 }}>
            {takeoutProduct?.takeout_menu_pages?.map(
              (takeoutMenuPage, index) => {
                return (
                  <Text key={takeoutMenuPage.id}>
                    {index === 0 ? '' : ' '}
                    <TakeoutMenuPageText takeoutMenuPage={takeoutMenuPage} />
                  </Text>
                )
              }
            )}
          </Text>
          <View style={{ flexDirection: 'row', marginTop: 8 }}>
            <Text>{getPriceText(takeoutProduct?.prices?.takeout)}</Text>
            <View
              style={{
                marginLeft: 8,
                width: 78,
                height: 24,
                backgroundColor: Colors.bgBlack,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  color: Colors.secondaryBlack,
                }}
              >
                {getTaxRateText(takeoutProduct.prices.takeout)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={[
            width < sm
              ? {
                  marginTop: 16,
                }
              : { marginLeft: 'auto' },
          ]}
        >
          {onChangeInStock && (
            <StockToggle
              onChangeInStock={onChangeInStock}
              tableProduct={takeoutProduct}
            />
          )}
          {onChangeStatus && (
            <RestoreToggle
              onChangeStatus={onChangeStatus}
              tableProduct={takeoutProduct}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}
