import useSWR, { mutate } from 'modules/swr'
import { Owner, ownerService } from './Owner'

import { AuthClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/auth/auth_service.client'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { displayToastError } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import DraftOwnerInfo from 'components/SignUp/DraftOwnerInfo'
import axios, { isAxiosError, wrapResponse } from 'modules/axios'
import { getErrorMessage } from 'modules/error'
import { createRpcService } from 'modules/rpc'

export const authService = createRpcService(AuthClient)

export type Token = string
export interface Auth {
  id: Owner['id']
  token: Token
}

export const AUTH_KEY = 'ForRWeb:auth' as const

export function onError(error: unknown) {
  if (isAxiosError(error) && error.response?.status === 401) {
    signOut()
  }

  displayToastError(getErrorMessage(error))
}

export async function signOut() {
  localStorage.removeItem(AUTH_KEY)
  mutate(AUTH_KEY, null, false)
}

export async function signIn(
  email: string,
  password: string,
  ownerId: Owner['id']
) {
  const { response, error } = await authService.signIn(null, {
    email,
    password,
    ownerId,
  })

  const auth =
    response === undefined
      ? undefined
      : {
          id: ownerId,
          token: response.token,
        }

  if (response != null) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth) ?? 'null')
    mutate(AUTH_KEY, auth)
  }

  if (error != null) {
    onError(error)
  }

  return {
    auth,
    error,
  }
}

const authData = JSON.parse(localStorage.getItem(AUTH_KEY) ?? 'null')

export function useAuth() {
  const { data: auth, mutate } = useSWR<Auth | null>(AUTH_KEY, null, {
    fallbackData: authData,
  })

  return {
    auth: auth ?? undefined,
    mutate,
  }
}

export function useToken() {
  const { auth } = useAuth()
  return auth?.token ?? null
}

export function useAuthMe() {
  const { data, error, mutate } = authService.useGetMe({})

  let actor
  if (data?.businessUser !== undefined) {
    actor = {
      oneofKind: 'businessUser' as const,
      businessUser: data?.businessUser,
    }
  } else if (data?.owner !== undefined) {
    actor = {
      oneofKind: 'owner' as const,
      owner: data?.owner,
    } as const
  } else {
    actor = {
      oneofKind: undefined,
    } as const
  }

  return {
    actor,
    error,
    mutate,
  }
}

export const SIGN_UP_CODE_LENGTH = 6 as const

export async function signUp(email: string, password: string) {
  const { response, error } = await wrapResponse(
    axios.post<{ id: string }>('/owner/sign_up/drafts', {
      draft_owner: {
        email,
        password,
        code_length: SIGN_UP_CODE_LENGTH,
      },
    })
  )

  if (error != null) {
    if (isAxiosError(error)) {
      if (error.response?.status !== 422) {
        onError(error)
      }
    }
    return {
      error,
      id: null,
    }
  }

  if (response != null) {
    return {
      error: null,
      id: response.data.id,
    }
  }

  return {
    error,
    id: null,
  }
}

export async function verifyEmailForDraftOwner(
  draftOwnerId: string,
  code: string
) {
  const { error } = await wrapResponse(
    axios.post(`/owner/sign_up/drafts/${draftOwnerId}/email_verification`, {
      draft_owner: {
        email_verification_code: code,
      },
    })
  )

  if (error != null) {
    onError(error)
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export async function sendEmailCodeForDraftOwner(draftOwnerId: string) {
  const { error } = await wrapResponse(
    axios.post(
      `/owner/sign_up/drafts/${draftOwnerId}/email_verification_code_mail`
    )
  )

  if (error != null) {
    onError(error)
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export async function registerPhoneNumberForDraftOwner(
  draftOwnerId: string,
  phonNumber: string
) {
  const { error } = await wrapResponse(
    axios.post(`/owner/sign_up/drafts/${draftOwnerId}/phone_number`, {
      draft_owner: {
        phone_number: phonNumber,
      },
    })
  )

  if (error != null) {
    if (isAxiosError(error)) {
      if (error.response?.status !== 422) {
        onError(error)
      }
    }
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export const TELEPHONE_NUMBER_CODE_LENGTH = 6 as const
export async function sendTelephoneCodeForDraftOwner(
  draftOwnerId: string,
  phoneNumber: string,
  captureToken: string
) {
  const { error } = await wrapResponse(
    axios.post(
      `/owner/sign_up/drafts/${draftOwnerId}/phone_number_verification_code_telephone`,
      {
        phone_number: phoneNumber,
        'g-recaptcha-response': captureToken,
        code_length: TELEPHONE_NUMBER_CODE_LENGTH,
      }
    )
  )

  if (error != null) {
    onError(error)
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export async function verifyPhoneNumberForDraftOwner(
  draftOwnerId: string,
  verifyCode: string
) {
  const { error } = await wrapResponse(
    axios.post(
      `/owner/sign_up/drafts/${draftOwnerId}/phone_number_verification`,
      {
        draft_owner: {
          phone_number_verification_code: verifyCode,
        },
      }
    )
  )

  if (error != null) {
    onError(error)
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export const SMS_PHONE_NUMBER_CODE_LENGTH = 6 as const

export async function sendPhoneNumberCodeForDraftOwner(
  draftOwnerId: string,
  phoneNumber: string,
  recaptureToken: string
) {
  const { error } = await wrapResponse(
    axios.post(
      `/owner/sign_up/drafts/${draftOwnerId}/phone_number_verification_code_message`,
      {
        draft_owner: {
          phone_number: phoneNumber,
        },
        'g-recaptcha-response': recaptureToken,
        code_length: SMS_PHONE_NUMBER_CODE_LENGTH,
      }
    )
  )

  if (error != null) {
    onError(error)
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export async function ownerSignUpFromDraft(draftOwner: {
  id: string
  name: string
  username: string
}) {
  const { error } = await wrapResponse(
    axios.post(`/owner/sign_up`, {
      draft_owner: draftOwner,
    })
  )

  if (error != null) {
    if (isAxiosError(error)) {
      if (error.response?.status !== 422) {
        onError(error)
      }
    }
    return {
      error,
    }
  }

  return {
    error: null,
  }
}

export async function signInFromDraftOwnerInfo(
  username: string,
  { email, password }: DraftOwnerInfo
) {
  const { response, error } = await ownerService.getOwnerByUsername(null, {
    username,
  })

  if (error != null) {
    onError(error)
  }

  if (response == null) {
    onError(new Error(t('ユーザーが見つかりませんでした')))
    return
  }

  await signIn(email, password, response.id)
}
