import { flatten } from 'lodash'
import { RestaurantCrewMember } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCrewMember'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import useSWR, {
  WithHeaderData,
  fetcherWithPaginationHeader,
  swrKey,
} from '../modules/swr'
import useSWRInfinite from '../modules/swr/infinite'

import { useEffect, useMemo, useState } from 'react'
import axios, { setHeader, wrapResponse } from '../modules/axios'

type Status = 'normal' | 'disabled'

interface RestaurantCrewMemberParams {
  only_statuses_with?: Status[]
}

export function useRestaurantCrewMembers(
  restaurantId: number,
  params: RestaurantCrewMemberParams = {}
) {
  const token = useToken()
  const [page, setPage] = useState(1)
  const _params = {
    page,
    per: 20,
    only_statuses_with: ['normal'],
    ...params,
  }

  const { data, error, mutate } = useSWR<
    WithHeaderData<RestaurantCrewMember[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/restaurant_crew_members`,
      _params
    ),
    ([token, url]) => fetcherWithPaginationHeader([token, url, _params])
  )

  return {
    restaurantCrewMembers: data?.data,
    error,
    mutate,
    pagination:
      data?.headerData == null
        ? null
        : {
            ...data.headerData,
            setPage,
          },
  }
}

export function useAllRestaurantCrewMembers(
  restaurantId: number | null,
  params: RestaurantCrewMemberParams = {}
) {
  const token = useToken()
  const _params = {
    per: 99,
    only_statuses_with: ['normal'],
    ...params,
  }

  const { data, error, mutate, size, setSize, isLoading } =
    useSWRInfinite<WithHeaderData<RestaurantCrewMember[]> | null>(
      (index, prevData) => {
        if (restaurantId == null) return null
        if (prevData != null) return null
        return swrKey(
          token,
          `/restaurants/${restaurantId}/restaurant_crew_members`,
          {
            page: index + 1,
            ..._params,
          }
        )
      },
      ([token, url, params]: [token: string, url: string, params: string]) =>
        fetcherWithPaginationHeader([token, url, JSON.parse(params)]),
      { parallel: true, initialSize: 1 }
    )

  useEffect(() => {
    if (data?.length === 1) {
      const lastData = data[data.length - 1]
      if (
        lastData?.headerData.totalPages != null &&
        size < lastData.headerData.totalPages
      ) {
        // すべてのデータを取得する
        setSize(lastData.headerData.totalPages)
      }
    }
  }, [data, setSize, size])

  const crewMembers = useMemo(() => {
    if (data == null || isLoading) return undefined

    if (data.length === size) {
      return flatten(data.map((d) => d?.data)).filter(
        (item) => item != null
      ) as RestaurantCrewMember[]
    }
    return undefined
  }, [data, isLoading, size])

  return {
    restaurantCrewMembers: crewMembers,
    error,
    mutate,
    size,
    setSize,
  }
}

export async function putRestaurantCrewMembers(
  restaurantId: number,
  token: string,
  restaurantCrewMemberId: RestaurantCrewMember['id'],
  params: {
    restaurant_crew_member: Pick<
      RestaurantCrewMember,
      'name' | 'disabled' | 'position'
    >
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.put(
      `/restaurants/${restaurantId}/restaurant_crew_members/${restaurantCrewMemberId}`,
      params
    )
  )
  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

export async function deleteRestaurantCrewMember(
  token: string,
  restaurantId: number,
  restaurantCrewMemberId: RestaurantCrewMember['id']
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.delete(
      `/restaurants/${restaurantId}/restaurant_crew_members/${restaurantCrewMemberId}`
    )
  )
  if (error != null) {
    onError(error)
  }
  return {
    error,
  }
}

export async function postRestaurantCrewMembers(
  restaurantId: number,
  token: string,
  params: {
    restaurant_crew_member: Pick<
      RestaurantCrewMember,
      'name' | 'disabled' | 'position'
    >
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(`/restaurants/${restaurantId}/restaurant_crew_members`, params)
  )
  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}
