import React from 'react'
import { useRestaurantId } from 'modules/useRestaurantId'
import CrewMemberList from '@hello-ai/for_r_app/src/components/Restaurant/CrewMembers/ListView'
import { useNavigate } from 'modules/router/useNavigate'

export default function CrewMembers() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  return (
    <CrewMemberList
      restaurantId={restaurantId}
      onClickEdit={() => {
        navigate(`/restaurants/${restaurantId}/crew_members/edit`)
      }}
    />
  )
}
