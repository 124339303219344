import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'

export type LanguageExpandToggleButtonProps = {
  onPress: () => void
  isExpanded: boolean
  style?: StyleProp<ViewStyle>
  title?: string
}

export function LanguageExpandToggleButton({
  onPress,
  isExpanded,
  style,
  title = t('他の言語を設定'),
}: LanguageExpandToggleButtonProps) {
  return (
    <Button mode="text" onPress={onPress} style={style}>
      {({ textStyle }) => {
        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={textStyle}>{isExpanded ? t('閉じる') : title}</Text>
            <FontAwesomeIcon
              style={{ marginLeft: 8 }}
              icon={isExpanded ? faAngleUp : faAngleDown}
              color={Colors.primary}
              size={14}
            />
          </View>
        )
      }}
    </Button>
  )
}
