import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import React from 'react'
import { View } from 'react-native'
import { FontAwesomeIcon } from '../../../FontAwesomeIcon'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'
import { t } from '../../../../modules/i18n'
import { Button } from '../../../Button'

function StatusSuccessView() {
  return (
    <View
      style={{
        padding: 24,
        backgroundColor: Colors.accentBg,
        borderRadius: 4,
        rowGap: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon icon={faCircleCheck} color={Colors.accent} size={32} />
      <Text
        style={{
          fontSize: 18,
          fontWeight: '600',
          lineHeight: 27,
          color: Colors.accent,
        }}
      >
        {t('連携状態は正常です')}
      </Text>
    </View>
  )
}

function StatusFailedView({
  failedCount,
  onRetry,
}: {
  failedCount: number
  onRetry: () => void
}) {
  return (
    <View
      style={{
        padding: 24,
        backgroundColor: Colors.cautionBg,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 20,
      }}
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        color={Colors.caution}
        size={32}
      />
      <Text
        style={{
          fontSize: 18,
          fontWeight: '600',
          lineHeight: 27,
          color: Colors.caution,
        }}
      >
        {t('連携に失敗した予約データがあります　{{failedCount}}件', {
          failedCount,
        })}
      </Text>
      <Button
        onPress={onRetry}
        textStyle={{
          fontWeight: '300',
          fontSize: 14,
          lineHeight: 24,
        }}
      >
        {t('失敗した予約データを再連携する')}
      </Button>
    </View>
  )
}

export default function StatusView({
  failedCount,
  onRetry,
}: {
  failedCount: number
  onRetry: () => void
}) {
  if (failedCount === 0) {
    return <StatusSuccessView />
  }
  return <StatusFailedView failedCount={failedCount} onRetry={onRetry} />
}
