import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { TableSeat, tableSeatService } from 'models/TableSeat'
import React from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigate } from 'react-router'

import { useToken } from 'models/Auth'
import { onError } from 'modules/swr'
import { useRestaurantId } from 'modules/useRestaurantId'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { TableSeatArchived } from '@hello-ai/for_r_app/src/components/TableSeats/TableSeatArchived'
import { TableSeatResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

export default function TableSeatArchivedScreen() {
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const token = useToken()
  const restaurantId = useRestaurantId()
  const { data } = tableSeatService.useList({
    restaurantId,
    status: TableSeatResource_Status.ARCHIVED,
  })

  const tableSeats = data?.tableSeats ?? []
  const isLoading = tableSeats === null || tableSeats === undefined

  const onAlertPublish = (tableSeatId: TableSeat['id']) => {
    const params = {
      restaurantId,
      id: tableSeatId,
    }
    Alert.alert(t('本当に席を戻しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (tableSeatId === undefined) return
          const { error } = await tableSeatService.publish(token, params)
          if (error != null) {
            onError(error)
            return
          }
          navigate(`/restaurants/${restaurantId}/table_seats`)
          displayToastInfo(t('公開状態に戻しました'))
        },
      },
    ])
  }

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      <View
        style={{
          paddingHorizontal: 0,
          paddingVertical: 0,
          marginTop: 24,
          borderRadius: 8,
          shadowRadius: 4,
          elevation: 4,
          shadowColor: '#000',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 2 },
          backgroundColor: 'white',
        }}
      >
        {isLoading && (
          <View style={{ padding: 24 }}>
            <Loading />
          </View>
        )}
        {!isLoading &&
          tableSeats?.length > 0 &&
          tableSeats.map((tableSeat, index) => {
            return (
              <View
                key={tableSeat.id}
                style={{
                  borderTopWidth: index === 0 ? 0 : 1,
                  borderColor: Colors.border,
                  paddingHorizontal: 24,
                }}
              >
                <TableSeatArchived
                  key={tableSeat.id}
                  tableSeat={tableSeat}
                  onAlertPublish={onAlertPublish}
                />
              </View>
            )
          })}
        {!isLoading && tableSeats.length === 0 && (
          <Text
            style={{
              paddingTop: 16,
              marginBottom: 24,
              paddingHorizontal: 24,
              fontSize: 22,
              fontWeight: '600',
              color: Colors.disabledBlack,
              textAlign: 'center',
            }}
          >
            {t('該当する席はありません')}
          </Text>
        )}
      </View>
    </ScrollView>
  )
}
