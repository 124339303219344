import { flatten } from 'lodash'
import axios, { wrapResponse } from '../modules/axios'
import useSWR, {
  fetcher,
  mutate,
  onError,
  swrKey,
  useStickySWR,
  fetcherWithPaginationHeader,
  WithHeaderData,
} from '../modules/swr'
import { useMemo, useState } from 'react'
import { TableOrder } from '@hello-ai/ar_shared/src/types/ForR/TableOrder'
import { TakeoutOrder } from '@hello-ai/ar_shared/src/types/ForR/TakeoutOrder'

import { Customer } from '@hello-ai/ar_shared/src/types/ForR/Customer'
import {
  RestaurantCustomerProfile,
  UpdateRestaurantCustomerProfile,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomerProfile'
import { UpdateRestaurantCustomerParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomer'
import { ReservationCard } from '@hello-ai/ar_shared/src/types/ForR/ReservationCard'
import useSWRInfinite from '@hello-ai/ar_shared/src/modules/swr/infinite'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
export * from '@hello-ai/ar_shared/src/types/ForR/Customer'

export function useCustomers(
  restaurantId: number,
  params: {
    query?: string
  }
) {
  const token = useToken()

  const { data, size, setSize, error, mutate } = useSWRInfinite<Customer[]>(
    (index, prevData) => {
      if (prevData?.length === 0) return null
      return swrKey(token, `/restaurants/${restaurantId}/customers`, {
        ...params,
        page: index + 1,
      })
    },
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string,
    ]) => fetcher<Customer[]>([token, url, JSON.parse(params)])
  )

  const customers = useMemo(() => data && flatten(data), [data])

  return {
    customers,
    size,
    setSize,
    error,
    mutate,
  }
}

type Params = { [key: string]: string }
interface CustomerSearchParams extends Params {
  dialCode: string
  phoneNumber: string
  reservationLastName: string
  reservationFirstName: string
  lastName: string
  firstName: string
}
/**
 * useCustomersと実行するAPIは同じですが、パラメータが顧客検索高速化用になっています。
 * useCustomersに影響が出ないように別hookとして実装します。
 */
export function useCustomerSearch(
  restaurantId: number,
  page: number,
  per_page?: number
) {
  const token = useToken()

  /**
   * 検索条件をhookの引数として受けると、検索条件が変わった度にuseSWRが実行されてしまうので、
   * stateとして持っておき、setされたタイミングで実行されるようにします。
   */
  const [searchParams, setSearchParams] = useState<CustomerSearchParams>({
    dialCode: '',
    phoneNumber: '',
    reservationFirstName: '',
    reservationLastName: '',
    lastName: '',
    firstName: '',
  })

  /**
   * 検索条件が未入力状態かを判定する変数です
   */
  const isSearchParamsEmpty = Object.keys(searchParams)
    .filter((key) => key !== 'dialCode') // dialCodeには常に値が入るため除外
    .every((key) => searchParams[key] === '')

  const params = isSearchParamsEmpty
    ? {
        page,
        per_page,
      }
    : {
        /**
         * 電話番号を検索する場合は、phoneNumberの1文字目をdialCodeに変換します。
         */
        phone_number: searchParams.phoneNumber.replace(
          /^0/,
          `+${searchParams.dialCode.slice(1)}`
        ),
        reservation_last_name: searchParams.reservationLastName,
        reservation_first_name: searchParams.reservationFirstName,
        last_name: searchParams.lastName,
        first_name: searchParams.firstName,
        is_search: true,
        from_cache: true,
        page,
        per_page,
      }
  const keys = swrKey(token, `/restaurants/${restaurantId}/customers`, params)

  const { data, error, mutate } = useSWR<
    WithHeaderData<Customer[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(keys, ([token, url]) =>
    fetcherWithPaginationHeader<Customer[], typeof params>([token, url, params])
  )

  const customers = useMemo(() => data?.data && flatten(data.data), [data])

  return {
    customers,
    headerData: data ? data.headerData : null,
    setSearchParams,
    error,
    mutate,
  }
}

export function useCustomer(
  restaurantId: number,
  customerId: Customer['id'] | undefined
) {
  const token = useToken()

  const {
    data: customer,
    error,
    mutate,
  } = useSWR<Customer, any, ReturnType<typeof swrKey> | null>(
    customerId != null
      ? swrKey(token, `/restaurants/${restaurantId}/customers/${customerId}`)
      : null,
    fetcher
  )

  return { customer, error, mutate }
}

export function useCustomerProfile(
  restaurantId: number,
  customerId: Customer['id']
) {
  const token = useToken()

  const {
    data: profile,
    error,
    mutate,
  } = useSWR<RestaurantCustomerProfile, any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/customers/${customerId}/restaurant_customer_profiles`
    ),
    fetcher
  )

  return { profile, error, mutate }
}

export async function updateCustomerRestaurantCustomerNote(
  token: string | null,
  restaurantId: number,
  customerId: Customer['id'],
  params: { content?: string }
) {
  const { response, error } = await wrapResponse(
    axios.patch<Customer>(
      `/restaurants/${restaurantId}/customers/${customerId}/restaurant_customer_note`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(token, `/restaurants/${restaurantId}/customers/${customerId}`)
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    customer: response?.data,
    error,
  }
}

export async function updateCustomerRestaurantCustomer(
  token: string | null,
  customerId: string,
  params: UpdateRestaurantCustomerParams
) {
  const { response, error } = await wrapResponse(
    axios.patch<Customer>(`/restaurant_customers/${customerId}`, {
      restaurant_customer: params,
    })
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/restaurant_customers/${customerId}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    customer: response?.data,
    error,
  }
}

export async function updateCustomerRestaurantCustomerProfile(
  token: string | null,
  restaurantId: number,
  customerId: string,
  params: UpdateRestaurantCustomerProfile
) {
  const { response, error } = await wrapResponse(
    axios.put<Customer>(
      `/restaurants/${restaurantId}/customers/${customerId}/restaurant_customer_profiles`,
      {
        customer_profile: params,
      }
    )
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/restaurant_customers/${customerId}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    profile: response?.data,
    error,
  }
}

export function useCustomerReservationCards(
  restaurantId: number,
  customerId: Customer['id'],
  params: {
    page: number
    per_page?: number
  }
) {
  const token = useToken()
  const { data, error, mutate } = useStickySWR<
    WithHeaderData<ReservationCard[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/customers/${customerId}/reservation_cards`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<ReservationCard[], typeof params>([
        token,
        url,
        params,
      ])
  )

  return {
    reservationCards: data?.data,
    headerData: data?.headerData,
    error,
    mutate,
  }
}

export function useCustomerTableOrders(
  restaurantId: number,
  customerId: Customer['id'],
  params: {
    page: number
    per_page?: number
    order_type: 'all' | 'table_order' | null
  }
) {
  const token = useToken()
  const { data, error, mutate } = useStickySWR<
    WithHeaderData<TableOrder[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/customers/${customerId}/table/orders`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<TableOrder[], typeof params>([
        token,
        url,
        params,
      ])
  )

  return {
    tableOrders: data?.data,
    headerData: data?.headerData,
    error,
    mutate,
  }
}

export function useCustomerTakeoutOrders(
  restaurantId: number,
  customerId: Customer['id']
) {
  const token = useToken()
  const {
    data: takeoutOrders,
    error,
    mutate,
  } = useSWR<TakeoutOrder[], any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/customers/${customerId}/takeout/orders`
    ),
    fetcher
  )

  return {
    takeoutOrders,
    error,
    mutate,
  }
}

export function useCustomersBulk(
  restaurantId: number,
  params: {
    ids: string[]
  }
) {
  const token = useToken()

  const {
    data: customers,
    error,
    mutate,
  } = useStickySWR<Customer[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/customers/bulk`, params),
    ([token, url]) => fetcher<Customer[]>([token, url, params])
  )

  return {
    customers,
    error,
    mutate,
  }
}

export async function deleteCustomer(
  restaurantId: number,
  customerId: Customer['id']
) {
  const { response, error } = await wrapResponse(
    axios.delete<Customer>(
      `/restaurants/${restaurantId}/customers/${customerId}`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    customer: response?.data,
    error,
  }
}
