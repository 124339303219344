import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { SyncFailedReservation } from '@hello-ai/ar_shared/src/types/ForR/SiteControllerSeat'
import axios, { setHeader, wrapResponse } from 'modules/axios'

import useSWR, {
  WithHeaderData,
  fetcherWithPaginationHeader,
  swrKey,
} from 'modules/swr'
import { useState } from 'react'
import { useImportMailSettings } from './ImportMailSettings'

export function useSyncFailedReservations(restaurantId: number) {
  const token = useToken()
  const [page, setPage] = useState(1)
  const { importMailSettingsData } = useImportMailSettings(restaurantId)

  const params = {
    page,
    per: 10,
  }

  const { data, error, mutate } = useSWR<
    WithHeaderData<SyncFailedReservation[]> | null,
    any,
    ReturnType<typeof swrKey> | null
  >(
    importMailSettingsData?.site_controller_enabled &&
      importMailSettingsData?.agreed_at != null
      ? swrKey(
          token,
          `/restaurants/${restaurantId}/site_controller/sync_failed_reservations`,
          params
        )
      : null,
    ([token, url]) =>
      fetcherWithPaginationHeader<SyncFailedReservation[], {}>([
        token,
        url,
        params,
      ])
  )

  return {
    data: data?.data,
    error,
    mutate,
    pagination: {
      ...data?.headerData,
      setPage,
    },
  }
}

export async function syncFailedReservations(
  token: string,
  restaurantId: number
) {
  setHeader({ token })

  const { error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/site_controller/sync_failed_reservations/bulk_update`
    )
  )

  if (error != null) {
    onError(error)
  }

  return { error }
}
