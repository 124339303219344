import { Middleware, mutate } from 'swr'

// フォーカスの当たっている画面内のみ全てrevalidateするmiddleware
// アプリのみ実装されている(webでは全てクリアされる)
export async function revalidateAll() {
  await mutate(() => true, undefined, { revalidate: true })
}
export const revalidateAllMiddleware: Middleware = (useSWRNext) => {
  return useSWRNext
}
