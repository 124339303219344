import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import * as ImagePicker from 'expo-image-picker'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'

export function SelectedImage({
  source,
  onRemovePress,
  width = 120,
  height = 120,
  iconSize = 18,
}: {
  source: ImageSourcePropType
  onRemovePress: () => void
  width?: number
  height?: number
  iconSize?: number
}) {
  return (
    <TouchableOpacity style={{ position: 'relative' }}>
      <Image
        source={source}
        style={{
          width,
          height,
        }}
      />
      <TouchableOpacity
        style={{ position: 'absolute', top: -12, right: -12 }}
        onPress={() => onRemovePress()}
      >
        <View
          style={{
            backgroundColor: Colors.secondaryBlack,
            width: 32,
            height: 32,
            borderRadius: 16,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon={faTimes} size={iconSize} color="white" />
        </View>
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const selectImageFromMediaLibrary = async (): Promise<string | null> => {
  const pickerResult: ImagePicker.ImagePickerResult =
    await ImagePicker.launchImageLibraryAsync({
      base64: true,
      allowsEditing: false,
      quality: 1,
    })

  if (pickerResult.canceled) {
    return null
  }

  return pickerResult.assets[0]?.uri ?? null
}

export function SelectImage({
  onSelectImage,
  width = 120,
  height = 120,
  iconSize = 60,
}: {
  onSelectImage: (base64Image: string) => void
  width?: number
  height?: number
  iconSize?: number
}) {
  return (
    <TouchableOpacity
      onPress={async () => {
        const image = await selectImageFromMediaLibrary()
        if (image != null) {
          onSelectImage?.(image)
        }
      }}
      style={{ position: 'relative' }}
    >
      <View
        style={{
          width,
          height,
          backgroundColor: Colors.bgBlack,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faImage}
          size={iconSize}
          color={Colors.secondaryBlack}
        />
      </View>
    </TouchableOpacity>
  )
}
