import { parse } from 'json2csv'
import { saveAs } from 'file-saver'

export function jsonToCSV(
  body: Array<Record<string, unknown>>,
  headers: Array<{
    value: string
    label: string
  }>
): string {
  return parse(body, { fields: headers })
}

export function saveCSVFile(csv: string, filename: string) {
  const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })

  saveAs(blob, filename, {
    autoBom: true,
  })
}
