import React, { useState } from 'react'
import { Switch as RNSwitch, SwitchProps, Platform } from 'react-native'
import { Colors } from '../constants/Colors'

export function Switch(props: SwitchProps) {
  return (
    <RNSwitch
      {...props}
      trackColor={{
        false: Colors.secondaryBlack,
        true: Colors.accent,
      }}
      {...Platform.select({
        ios: {},
        android: {
          thumbColor: 'white',
        },
      })}
    />
  )
}

type AsyncSwitchProps = Omit<SwitchProps, 'onValueChange'> & {
  onValueChange: (value: boolean) => Promise<void>
}

export function AsyncSwitch(props: AsyncSwitchProps) {
  const [updating, setUpdating] = useState(false)

  return (
    <RNSwitch
      {...props}
      disabled={updating || props.disabled}
      trackColor={{
        false: Colors.secondaryBlack,
        true: Colors.accent,
      }}
      onValueChange={(value) => {
        setUpdating(true)
        props.onValueChange(value).finally(() => {
          setUpdating(false)
        })
      }}
      {...Platform.select({
        ios: {},
        android: {
          thumbColor: 'white',
        },
      })}
    />
  )
}
