import { chunk, range } from 'lodash'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

function GridRowItem({
  style,
  children,
}: {
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flex: 1,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
function GridRow({
  horizontalGap,
  style,
  rowItemStyle,
  children,
}: {
  horizontalGap: number
  style?: StyleProp<ViewStyle>
  rowItemStyle?: StyleProp<ViewStyle>
  children: React.ReactNodeArray
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
        style,
      ]}
    >
      {React.Children.map(children, (child, index) => (
        <GridRowItem
          style={[
            { marginLeft: index === 0 ? 0 : horizontalGap },
            rowItemStyle,
          ]}
        >
          {child}
        </GridRowItem>
      ))}
    </View>
  )
}

export function Grid({
  numOfColumns,
  horizontalGap,
  verticalGap,
  style,
  rowContainerStyle,
  rowItemStyle,
  children,
}: {
  numOfColumns: number
  horizontalGap: number
  verticalGap: number
  style?: StyleProp<ViewStyle>
  rowContainerStyle?: StyleProp<ViewStyle>
  rowItemStyle?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  const count = React.Children.count(children)
  const reminder = (numOfColumns - (count % numOfColumns)) % numOfColumns
  const last = range(0, reminder).map(() => null)
  const rows = chunk(
    [...React.Children.toArray(children), ...last],
    numOfColumns
  )
  return (
    <View style={style}>
      {rows.map((row, index) => {
        return (
          <GridRow
            key={index.toString()}
            horizontalGap={horizontalGap}
            style={[
              {
                marginTop: index === 0 ? 0 : verticalGap,
              },
              rowContainerStyle,
            ]}
            rowItemStyle={rowItemStyle}
          >
            {row}
          </GridRow>
        )
      })}
    </View>
  )
}
