import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import useSWR, { swrKey } from '@hello-ai/ar_shared/src/modules/swr'
import {
  TableBalancingCashDrawer,
  TableCloseTypeBalancingCashDrawer,
  TableOpenAndCloseTypeBalancingCashDrawer,
} from '@hello-ai/ar_shared/src/types/ForR/TableBalancingCashDrawer'
import { Restaurant } from './Restaurant'
import { fetcher, SWRConfiguration } from 'modules/swr'
import { useToken } from 'models/Auth'

export * from '@hello-ai/ar_shared/src/types/ForR/TableBalancingCashDrawer'

const DailyFormat = 'YYYY-MM-DD'

export function useBalancingCashDrawersKey(
  restaurantId: Restaurant['id'],
  dateGte: dayjs.Dayjs,
  dateLte: dayjs.Dayjs
) {
  const token = useToken()
  return dateGte == null || dateLte == null
    ? null
    : swrKey(token, `/restaurants/${restaurantId}/balancing_cash_drawers`, {
        date: {
          gte: dateGte.format(DailyFormat),
          lte: dateLte.format(DailyFormat),
        },
      })
}

export function useBalancingCashDrawers(
  restaurantId: Restaurant['id'],
  dateGte: dayjs.Dayjs,
  dateLte: dayjs.Dayjs
) {
  const { data, error, mutate } = useSWR<
    TableBalancingCashDrawer[],
    any,
    ReturnType<typeof useBalancingCashDrawersKey>
  >(
    useBalancingCashDrawersKey(restaurantId, dateGte, dateLte),
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string
    ]) =>
      fetcher<TableBalancingCashDrawer[], string>([
        token,
        url,
        JSON.parse(params),
      ])
  )

  return {
    balancingCashDrawers: data,
    error,
    mutate,
  }
}

export function useBalancingCashDrawer(
  restaurantId: string | undefined,
  balanceCashDrawerId: TableBalancingCashDrawer['id'] | undefined
) {
  const token = useToken()
  const { data, error } = useSWR<
    TableBalancingCashDrawer,
    any,
    ReturnType<typeof swrKey> | null
  >(
    restaurantId == null || balanceCashDrawerId == null
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/balancing_cash_drawers/${balanceCashDrawerId}`
        ),
    fetcher
  )
  return {
    balancingCashDrawer: data,
    error,
  }
}

export function useLastCloseBalancingCashDrawer(
  restaurantId: Restaurant['id'],
  dateLte: dayjs.Dayjs,
  shouldFetch: boolean = true,
  config?: SWRConfiguration
) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    TableCloseTypeBalancingCashDrawer | TableOpenAndCloseTypeBalancingCashDrawer
  >(
    shouldFetch && dateLte != null
      ? swrKey(
          token,
          `/restaurants/${restaurantId}/balancing_cash_drawers/closings/last`,
          {
            date: {
              lte: dateLte.format(DailyFormat),
            },
          }
        )
      : null,
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string
    ]) =>
      fetcher<
        | TableCloseTypeBalancingCashDrawer
        | TableOpenAndCloseTypeBalancingCashDrawer,
        string
      >([token, url, JSON.parse(params)]),
    config
  )

  return {
    balancingCashDrawer: data,
    error,
    mutate,
  }
}
