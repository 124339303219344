import React from 'react'
import { View, ViewStyle, StyleProp } from 'react-native'
import { TextInputProps, TextInput } from './TextInput'
import { Colors } from '../constants/Colors'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from './FontAwesomeIcon'

export function TextInputWithIcon({
  style,
  icon,
  iconSize = 20,
  iconStyle,
  containerStyle,
  ...props
}: {
  icon?: IconDefinition
  iconSize?: number
  iconStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
} & TextInputProps) {
  return (
    <View style={[{ position: 'relative' }, containerStyle]}>
      <TextInput
        style={[
          [
            {
              backgroundColor: Colors.field,
            },
            icon && {
              paddingLeft: 48,
            },
          ],
          style,
        ]}
        {...props}
      />
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          size={iconSize}
          color={Colors.secondaryBlack}
          style={[
            {
              position: 'absolute',
              top: '50%',
              left: 16,
              transform: [{ translateY: -iconSize / 2 }],
            },
            iconStyle,
          ]}
        />
      )}
    </View>
  )
}
