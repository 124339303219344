import React from 'react'
import { ScrollView, View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import LinkOpacity from '../LinkOpacity'
import { useParams, generatePath, useLocation } from 'react-router'

export const TAB_HEIGHT = 64

export interface TabProps {
  title: string
  fullPath: string
  /* eslint-disable react/no-unused-prop-types */
  relativePath: string
  exact?: boolean
  element: React.ReactNode
  /* eslint-enable react/no-unused-prop-types */
}

export function Tab({ title, fullPath }: TabProps) {
  const params = useParams()
  const location = useLocation()
  const to = generatePath(fullPath, params)
  const isCurrentPath = location.pathname === to
  return (
    <LinkOpacity
      style={[
        {
          flex: 1,
          height: TAB_HEIGHT,
          backgroundColor: 'white',
          justifyContent: 'center',
          borderBottomWidth: 4,
          borderBottomColor: isCurrentPath ? Colors.primary : 'white',
        },
      ]}
      to={to}
    >
      <Text
        style={{
          textAlign: 'center',
          fontSize: 18,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
    </LinkOpacity>
  )
}

export function TabContent({ children }: { children: React.ReactNode }) {
  return (
    <ScrollView
      style={{
        flex: 1,
      }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      {children}
    </ScrollView>
  )
}

export function Tabs({ tabs }: { tabs: TabProps[] }) {
  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      {tabs.map((tab) => (
        <Tab key={tab.fullPath} {...tab} />
      ))}
    </View>
  )
}
