import { useEffect } from 'react'
import { usePreventScroll as useReactAriaPreventScroll } from 'react-aria'
import { useResponsive } from '../useResponsive'

function usePreventScrollStandard({
  isDisabled = false,
}: {
  isDisabled?: boolean
}) {
  useEffect(() => {
    if (isDisabled) return
    const originalOverflow = document.body.style.overflow
    const originalPaddingRight = document.body.style.paddingRight

    document.body.style.paddingRight = `${
      window.innerWidth - document.documentElement.clientWidth
    }px`
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.paddingRight = originalPaddingRight
      document.body.style.overflow = originalOverflow
    }
  }, [isDisabled])
}

export function usePreventScroll({
  isDisabled = false,
}: {
  isDisabled?: boolean
}) {
  const { width, md } = useResponsive()

  const shouldUseStandard = width >= md

  // react-aria's usePreventScroll sets overflow: hidden; to html element instead of body on PC,
  // which causes issues like sticky elements jumping or getting hidden when user scrolls down and opens a modal.
  // So we use our own implementation on PC.

  usePreventScrollStandard({ isDisabled: isDisabled || !shouldUseStandard })

  useReactAriaPreventScroll({
    isDisabled: isDisabled || shouldUseStandard,
  })
}
