import { useSyncExternalStore } from 'react'

const supportsDvh = typeof CSS !== 'undefined' && CSS.supports('height: 100dvh')

function getSnapshot() {
  return window.visualViewport?.height ?? window.innerHeight
}
function subscribe(listener: () => void) {
  if (window.visualViewport != null) {
    window.visualViewport.addEventListener('resize', listener)
  } else {
    window.addEventListener('resize', listener)
  }
  return () => {
    if (window.visualViewport != null) {
      window.visualViewport.removeEventListener('resize', listener)
    } else {
      window.removeEventListener('resize', listener)
    }
  }
}

export const useVisualViewportHeight = supportsDvh
  ? () => '100dvh'
  : () => useSyncExternalStore(subscribe, getSnapshot, getSnapshot)
