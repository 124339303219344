import {
  CreateTranslationParams,
  CreateTranslationResponse,
} from '@hello-ai/ar_shared/src/types/ForR/Translation'
import axios, { setHeader, wrapResponse } from '../modules/axios'

export * from '@hello-ai/ar_shared/src/types/ForR/Translation'

export async function createTranslation(
  token: string,
  params: CreateTranslationParams
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<CreateTranslationResponse>(`/translations`, params)
  )

  return {
    data: response?.data,
    error,
  }
}
