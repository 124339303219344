import {
  CreateSharedSeatParams,
  ParsedSeat,
  ParsedSeatWithSharedSeat,
  SharedSeat,
  TableSeat,
  UpdateSharedSeatParams,
} from '@hello-ai/ar_shared/src/types/ForR/SiteControllerSeat'
import axios, { setHeader, wrapResponse } from '../modules/axios'
import Config from '../modules/config'

import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import useSWR, {
  WithHeaderData,
  fetcher,
  fetcherWithPaginationHeader,
  swrKey,
} from '../modules/swr'
import { useState } from 'react'
import { GourmetSiteProvider } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'

export async function updateSiteControllerParsedSeats(
  token: string,
  restaurantId: number,
  userId: string
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(`/restaurants/${restaurantId}/site_controller/parsed_seats`, {
      sandbox: Config.apiUrl.includes('lvh.me'),
      business_user_id: userId,
    })
  )
  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export function useSharedSeats(
  restaurantId: number,
  _params: {
    name: string
    shared_seat_statuses: string[]
    unassociated: true | undefined
  }
) {
  const token = useToken()
  const [page, setPage] = useState(1)

  const params = {
    ..._params,
    page,
    per: 10,
  }

  const { data, error, mutate } = useSWR<
    WithHeaderData<SharedSeat[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/site_controller/shared_seats`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<SharedSeat[], {}>([token, url, params])
  )

  return {
    data: data?.data,
    error,
    mutate,
    pagination: {
      ...data?.headerData,
      setPage,
    },
  }
}

export function useSharedSeat(restaurantId: number, sharedSeatId?: string) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    Omit<SharedSeat, 'parsed_seats'>,
    any,
    ReturnType<typeof swrKey> | null
  >(
    sharedSeatId == null
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/site_controller/shared_seats/${sharedSeatId}`
        ),
    fetcher
  )

  return {
    data,
    error,
    mutate,
  }
}

export function useParsedSeats(
  restaurantId: number,
  site: GourmetSiteProvider,
  _params: { name?: string }
) {
  const token = useToken()
  const [page, setPage] = useState(1)
  const params = {
    ..._params,
    page,
    per: 10,
    site,
  }
  const { data, error, mutate } = useSWR<
    WithHeaderData<ParsedSeat[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/site_controller/parsed_seats`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<ParsedSeat[], {}>([token, url, params])
  )

  return {
    data: data?.data,
    error,
    mutate,
    pagination: {
      ...data?.headerData,
      setPage,
    },
  }
}

export async function deleteParsedSeat(
  token: string,
  restaurantId: number,
  seatId: string
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.delete(
      `/restaurants/${restaurantId}/site_controller/parsed_seats/${seatId}`
    )
  )
  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export async function deleteSharedSeats(
  token: string,
  restaurantId: number,
  seatIds: string[]
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.delete(`/restaurants/${restaurantId}/site_controller/shared_seats`, {
      data: {
        shared_seat_ids: seatIds,
      },
    })
  )
  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export function useNotSharedTableSeats(restaurantId: number) {
  const token = useToken()
  return useSWR<TableSeat[], any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/site_controller/not_shared_table_seats`
    ),
    fetcher
  )
}

export async function createSharedSeats(
  token: string,
  restaurantId: number,
  params: CreateSharedSeatParams
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(`/restaurants/${restaurantId}/site_controller/shared_seats`, {
      restaurant_id: restaurantId,
      ...params,
    })
  )

  if (error != null) {
    onError(error)
  }

  return {
    response,
    error,
  }
}

export async function updateSharedSeat(
  token: string,
  restaurantId: number,
  sharedSeatId: string,
  params: UpdateSharedSeatParams
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/site_controller/shared_seats/${sharedSeatId}`,
      {
        restaurant_id: restaurantId,
        ...params,
      }
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    response,
    error,
  }
}

export function useParsedSeatsWithSharedSeat(
  restaurantId: number,
  sharedSeatId: string,
  _params: {
    name?: string
    selected_status: 'all' | 'select_only' | 'not_select_only'
  }
) {
  const token = useToken()
  const [page, setPage] = useState(1)

  const params = {
    ..._params,
    page,
    per: 10,
  }

  const { data, error, mutate } = useSWR<
    WithHeaderData<ParsedSeatWithSharedSeat[]> | null,
    any,
    ReturnType<typeof swrKey> | null
  >(
    sharedSeatId == null
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/site_controller/shared_seats/${sharedSeatId}/parsed_seats`,
          params
        ),
    ([token, url]) =>
      fetcherWithPaginationHeader<ParsedSeat[], {}>([token, url, params])
  )

  return {
    data: data?.data,
    error,
    mutate,
    pagination: {
      ...data?.headerData,
      setPage,
    },
  }
}
