export interface EdgeInsets {
  top: number
  right: number
  bottom: number
  left: number
}

const safeAreaInsets: EdgeInsets = { top: 0, right: 0, bottom: 0, left: 0 }

export function useSafeAreaInsets(): EdgeInsets {
  return safeAreaInsets
}
