import React, { useEffect } from 'react'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner'
import { FontAwesomeIcon } from './FontAwesomeIcon'

import Reanimated, {
  cancelAnimation,
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import { View } from 'react-native'

type SpinnerProps = {
  size: number
  color: string
}

export function Spinner({ size, color }: SpinnerProps) {
  const rotation = useSharedValue(0)

  useEffect(() => {
    // TODO: react-compiler for react-native-reanimated
    // eslint-disable-next-line react-compiler/react-compiler
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 1800,
        easing: Easing.linear,
      }),
      -1
    )
    return () => cancelAnimation(rotation)
  }, [rotation])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    }
  }, [rotation])

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Reanimated.View style={[animatedStyle]}>
        <FontAwesomeIcon icon={faSpinner} size={size} color={color} />
      </Reanimated.View>
    </View>
  )
}
