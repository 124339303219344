import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Image, View, Platform } from 'react-native'
import {
  Chart,
  ChartMode,
  createChartScrollResponder,
  SelectParams,
} from './Chart'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../models/TableSeat'
import produce from 'immer'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { toSeconds } from '@hello-ai/ar_shared/src/modules/time'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { useRestaurantBusinessTimesByDate } from '../../models/RestaurantBusinessTime'
import { AddReservationFloatButton } from './AddReservationFloatButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { PlanUpgradeModal } from '../Shared/PlanUpgradeModal'
import { useRestaurant } from '../../models/Restaurant'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { inRange } from 'lodash'

export function ChartView({
  restaurantId,
  startTime,
  date,
  dateString,
  onPressAddReservationButton,
  onLoadEnd,
  onReservationAdded,
}: {
  restaurantId: number
  startTime: number
  date: dayjs.Dayjs
  dateString: string
  onPressAddReservationButton: () => void
  onLoadEnd?: () => void
  onReservationAdded?: () => void
}) {
  const { data: restaurant } = useRestaurant(restaurantId)
  const { tableSeats } = useTableSeats(restaurantId, {})
  const ref = useRef<React.ElementRef<typeof Chart>>(null)
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const { setChartRef, reset, chartProps, requestScroll } = useMemo(
    () => createChartScrollResponder(),
    []
  )

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: date.format('YYYY-MM-DD'),
    }
  )

  useEffect(() => {
    setChartRef(ref.current)
    return () => {
      reset()
    }
  }, [reset, setChartRef])

  const prevStartTime = usePrevious(startTime)
  const prevDate = usePrevious(date)

  useEffect(() => {
    if (prevDate !== date || prevStartTime !== startTime) {
      requestScroll(
        (async () => {
          if (startTime != null) return { time: startTime }

          if (
            restaurantBusinessTimes == null ||
            restaurantBusinessTimes.length === 0
          ) {
            const now = dayjs()
            const defaultTime = toSeconds(
              now.hour(),
              Math.floor(now.minute() / 15) * 15
            )
            return { time: defaultTime }
          } else {
            const firstStartTime = restaurantBusinessTimes[0].start_time
            return { time: firstStartTime }
          }
        })()
      )
    }
  }, [
    date,
    prevDate,
    prevStartTime,
    requestScroll,
    restaurantBusinessTimes,
    startTime,
  ])

  const onChangeSelectedSeatIds = (value: Array<TableSeatModel['id']>) => {
    setSelectParams(
      produce((draft) => {
        if (draft != null) {
          draft.selectedSeatIds = value
        }
      })
    )
  }

  const [mode, setMode] = useState<ChartMode>('default')

  const [selectParams, setSelectParams] = useState<SelectParams | undefined>()

  const onPressCancel = () => {
    setMode('default')
    setSelectParams(undefined)
  }

  const onPressBlock = () => {
    if (Platform.OS === 'web') {
      const params = new URLSearchParams()
      if (selectParams?.startTime != null) {
        params.append('startTime', selectParams.startTime.toString())
      }
      if (selectParams?.endTime != null) {
        params.append('endTime', selectParams.endTime.toString())
      }
      if (
        selectParams?.selectedSeatIds != null &&
        selectParams.selectedSeatIds.length > 0
      ) {
        params.append('selectedSeatIds', selectParams.selectedSeatIds.join(','))
      }
      params.append('date', date.format('YYYY-MM-DD'))
      navigate(
        `/restaurants/${restaurantId}/reservations/blocks/new?${params.toString()}`
      )
    } else {
      navigation.navigate('ReservationBlocksForm', {
        startTime: selectParams?.startTime,
        endTime: selectParams?.endTime,
        selectedSeatIds: selectParams?.selectedSeatIds,
        date,
      })
    }
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const onPressWalkin = () => {
    if (Platform.OS === 'web') {
      const params = new URLSearchParams()
      if (selectParams?.startTime != null) {
        params.append('startTime', selectParams.startTime.toString())
      }
      if (selectParams?.endTime != null) {
        params.append('endTime', selectParams.endTime.toString())
      }
      if (
        selectParams?.selectedSeatIds != null &&
        selectParams.selectedSeatIds.length > 0
      ) {
        params.append('selectedSeatIds', selectParams.selectedSeatIds.join(','))
      }
      params.append('date', date.format('YYYY-MM-DD'))
      navigate(
        `/restaurants/${restaurantId}/reservations/walkins/new?${params.toString()}`
      )
    } else {
      navigation.navigate('ReservationWalkinsForm', {
        startTime: selectParams?.startTime,
        endTime: selectParams?.endTime,
        selectedSeatIds: selectParams?.selectedSeatIds,
        date,
      })
    }
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const onPressReserve = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/new?dateString=${dateString}&startTime=${
          selectParams?.startTime
        }&endTime=${
          selectParams?.endTime
        }&selectedSeatIds=${selectParams?.selectedSeatIds?.join(',')}`
      )
    } else {
      navigation.navigate('ReservationForm', {
        dateString,
        startTime: selectParams?.startTime,
        endTime: selectParams?.endTime,
        selectedSeatIds: selectParams?.selectedSeatIds,
      })
    }
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const setEndTime = (endTime: number) => {
    setSelectParams(
      produce((draft) => {
        if (draft != null) {
          draft.endTime = endTime
        }
      })
    )
  }

  const isFreePlan =
    restaurant == null ? true : restaurant.reservation_book_plan_type === 'free'
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <View style={{ flex: 1 }}>
      <Chart
        restaurantId={restaurantId}
        ref={ref}
        scrollViewProps={{
          style: {
            backgroundColor: width < sm ? Colors.bgBlack : Colors.white,
            flex: 1,
          },
          showsVerticalScrollIndicator: false,
        }}
        date={date}
        mode={mode}
        selectParams={selectParams}
        onStartSelectBlock={({ startTime }) => {
          // 該当する営業時間のうち
          const defaultStayingTimes =
            restaurantBusinessTimes?.reduce((prev, cur) => {
              if (
                cur.staying_time != null &&
                inRange(startTime, cur.start_time, cur.end_time)
              ) {
                return [...prev, cur.staying_time]
              }
              return prev
            }, [] as number[]) ?? []
          // 最大の滞在時間 or 2h を初期値に設定する
          const defaultStayingTime =
            defaultStayingTimes.length > 0
              ? Math.max(...defaultStayingTimes)
              : 3600 * 2

          setSelectParams({
            restaurantReservation: undefined,
            startTime,
            endTime: startTime + defaultStayingTime,
            setEndTime,
            partySize: Infinity,
            selectedSeatIds: [],
            onChangeSelectedSeatIds,
          })
          setMode('selectBlock')
        }}
        onLoadEnd={onLoadEnd}
        onReservationAdded={onReservationAdded}
        {...chartProps}
      />
      {mode === 'selectBlock' && (
        <SafeAreaView
          style={[
            {
              borderTopColor: Colors.border,
              borderTopWidth: 0.5,
              paddingHorizontal: width < sm ? 16 : 24,
              paddingBottom: width < sm ? 0 : 12,
            },
          ]}
        >
          <View
            style={
              width < sm
                ? {
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    right: 16,
                    height: 24,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }
                : {
                    paddingBottom: 16,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }
            }
          >
            {selectParams?.selectedSeatIds?.length ?? 0 ? (
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: '600',
                  color: Colors.black,
                }}
              >
                {
                  tableSeats.find(
                    (seat) => seat.id === selectParams?.selectedSeatIds?.[0]
                  )?.name
                }
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: '600',
                  color: Colors.black60,
                }}
              >
                {t('タップして席を選択')}
              </Text>
            )}
            <TouchableOpacity onPress={onPressCancel}>
              <FontAwesomeIcon
                icon={faTimes}
                size={28}
                color={Colors.primary}
              />
            </TouchableOpacity>
          </View>
          <View
            style={[
              {
                flexDirection: 'row',
                columnGap: width < sm ? 8 : 16,
              },
            ]}
          >
            {isFreePlan ? (
              <Button
                mode="outline"
                style={{
                  paddingHorizontal: 0,
                  height: 48,
                  flex: 1,
                }}
                textStyle={width < sm && { fontSize: 16 }}
                onPress={() => setIsModalVisible(true)}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Image
                    style={{ height: 20, width: 20, marginRight: 3 }}
                    source={require('../../assets/images/plan-icon.png')}
                  />
                  <Text
                    style={[
                      { color: Colors.primary },
                      width < sm && { fontSize: 16 },
                    ]}
                  >
                    {t('ブロック')}
                  </Text>
                </View>
              </Button>
            ) : (
              <Button
                mode="outline"
                style={{
                  paddingHorizontal: 0,
                  height: 48,
                  flex: 1,
                }}
                textStyle={width < sm && { fontSize: 16 }}
                onPress={onPressBlock}
              >
                {t('ブロック')}
              </Button>
            )}

            <Button
              mode="outline"
              style={{
                paddingHorizontal: 0,
                height: 48,
                flex: 1,
              }}
              textStyle={width < sm && { fontSize: 16 }}
              onPress={onPressWalkin}
            >
              {t('ウォークイン')}
            </Button>
            <Button
              mode="contained"
              style={{
                paddingHorizontal: 0,
                height: 48,
                flex: 1,
              }}
              textStyle={width < sm && { fontSize: 16 }}
              onPress={onPressReserve}
            >
              {t('予約')}
            </Button>
          </View>
        </SafeAreaView>
      )}
      {mode !== 'selectBlock' && (
        <AddReservationFloatButton onPress={onPressAddReservationButton} />
      )}
      {isModalVisible && (
        <PlanUpgradeModal
          isModalVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </View>
  )
}
