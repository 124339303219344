import config from '@hello-ai/for_r_app/src/modules/config'

export const redirectApp = (path: string) => {
  const url = `${config.nativeUrlScheme}://${path}`
  const win = window.open(url)
  setTimeout(() => {
    if (win?.closed === false && win.document.visibilityState === 'visible') {
      win.location.href =
        'https://itunes.apple.com/jp/app/autoreserve-for-restaurants/id1521972781'
    }
  }, 500)
}
