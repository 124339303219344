import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'
import { useToken } from './Auth'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { BusinessFeatureResource_FeatureKey } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_feature_resource'
import { BusinessUserRolePermissionResource_Level } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_permission_resource'
import {
  BusinessUserRolePermission,
  GetRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_service'
import { BusinessUserRoleClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_service.client'
import invariant from 'tiny-invariant'

export const businessUserRoleService = createRpcService(BusinessUserRoleClient)
export const businessUserRoleClient = businessUserRoleService.client

export const businessFeatures = [
  [
    BusinessFeatureResource_FeatureKey.TABLE_MENUS,
    {
      name: t('メニュー'),
      maxLevel: BusinessUserRolePermissionResource_Level.ALL,
    },
  ],
  [
    BusinessFeatureResource_FeatureKey.TABLE_PAST_ORDERS,
    {
      name: t('過去の注文'),
      maxLevel: BusinessUserRolePermissionResource_Level.ALL,
    },
  ],
  [
    BusinessFeatureResource_FeatureKey.TABLE_REPORTS,
    { name: t('日報'), maxLevel: BusinessUserRolePermissionResource_Level.ALL },
  ],
  [
    BusinessFeatureResource_FeatureKey.TABLE_CUSTOMERS,
    {
      name: t('顧客管理'),
      maxLevel: BusinessUserRolePermissionResource_Level.READ,
    },
  ],
  [
    BusinessFeatureResource_FeatureKey.TABLE_SALES,
    {
      name: t('売上分析'),
      maxLevel: BusinessUserRolePermissionResource_Level.READ,
    },
  ],
] as const

export function getBusinessUserRolePermissionLabel(
  featureKey: BusinessFeatureResource_FeatureKey
) {
  const value = businessFeatures.find(
    ([featureKey_]) => featureKey === featureKey_
  )

  invariant(value !== undefined, `Unknown feature key: ${featureKey}`)

  return value?.[1] ?? ''
}

export function getBusinessUserRolePermission(
  businessUserRolePermissions: BusinessUserRolePermission[],
  featureKey_: BusinessFeatureResource_FeatureKey
) {
  // permissionを拡張して、permission対象の機能を追加したときにいきなり使えなくなるのを防ぐため
  // 指定していない物はデフォで有効扱いとなる
  return (
    businessUserRolePermissions.find(
      ({ featureKey }) => featureKey_ === featureKey
    ) ?? {
      featureKey: featureKey_,
      level: BusinessUserRolePermissionResource_Level.ALL,
    }
  )
}

const permissions = [
  BusinessUserRolePermissionResource_Level.NONE,
  BusinessUserRolePermissionResource_Level.READ,
  BusinessUserRolePermissionResource_Level.ALL,
] as const

export function compareBusinessUserRolePermissionLevels(
  firstLevel: BusinessUserRolePermissionResource_Level,
  secondLevel: BusinessUserRolePermissionResource_Level
) {
  return permissions.indexOf(firstLevel) - permissions.indexOf(secondLevel)
}

export function useBusinessUserRole(params: GetRequest | undefined) {
  const token = useToken()

  const {
    data: businessUserRole,
    error,
    mutate,
  } = useSWR(
    params === undefined
      ? null
      : swrKey(token, 'BusinessUserRoles/Get', params),
    () => {
      invariant(params !== undefined, 'params cannot be undefined')
      return businessUserRoleClient.get(params, rpcOptions({ token })).response
    }
  )

  return {
    businessUserRole,
    error,
    mutate,
  }
}
export function useBusinessUserRoles() {
  const token = useToken()

  const { data, error, mutate } = useSWR(
    swrKey(token, 'BusinessUserRoles/List'),
    () => businessUserRoleClient.list({}, rpcOptions({ token })).response
  )

  return {
    businessUserRoles: data?.businessUserRoles,
    error,
    mutate,
  }
}
