import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import React from 'react'
import { View } from 'react-native'

import {
  BusinessUserResource,
  BusinessUserResource_Role,
  BusinessUserResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'
import { businessUserClient, useOwnerBusinessUsers } from 'models/BusinessUser'

import Title from '@hello-ai/ar_shared/src/components/ForR/Shared/Title'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import FloatingButton from 'components/Shared/FloatingButton'
import { BusinessRolePermissionList } from 'components/Top/BusinessRolePermission'
import { Table } from 'components/Top/Table'
import { useToken } from 'models/Auth'
import { useNavigate } from 'modules/router/useNavigate'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'

export default function BusinessUsers() {
  const token = useToken()
  const navigate = useNavigate()
  const { businessUsers } = useOwnerBusinessUsers()
  const { width, sm } = useResponsive()

  if (businessUsers === undefined) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.bgBlack,
        }}
      >
        <Loading />
      </View>
    )
  }

  const onReinvite = async (businessUserId: BusinessUserResource['id']) => {
    const { error } = await callRpc(
      businessUserClient.resendInvitation(
        {
          id: businessUserId,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }
    displayToastInfo(t('招待メールを再送信しました'))
  }

  return (
    <View
      style={{
        flex: 1,
        flexGrow: 1,
        padding: width < sm ? 24 : 48,
        backgroundColor: Colors.bgBlack,
      }}
    >
      <Title
        title={t('アカウント一覧')}
        helperText={t(
          'アプリにログインできるアカウントと、そのアカウントが持つ役割や権限の一覧です。役割については役割設定画面で設定できます。'
        )}
        helperTextStyle={{
          width: 400,
        }}
      />
      <Table
        headers={[
          { label: t('名前'), key: 'name', hiddenSP: true },
          { label: t('メールアドレス'), key: 'email' },
          { label: t('役割'), key: 'role' },
          { label: t('権限'), key: 'businessUserRole' },
        ]}
        data={businessUsers}
        keyExtractor={(businessUser) => businessUser.id}
        toExtractor={(businessUser) => `/business_users/${businessUser.id}`}
        cellRender={(key, item) => {
          switch (key) {
            case 'name':
              return (
                <Text
                  style={{ fontSize: width < sm ? 18 : 16, fontWeight: '600' }}
                >
                  {item.name}
                </Text>
              )
            case 'email': {
              const isUnregistered =
                item.status === BusinessUserResource_Status.INVITED
              return (
                <>
                  {isUnregistered && (
                    <Text
                      style={{
                        fontSize: 12,
                        color: Colors.caution,
                      }}
                    >
                      {t('招待メールの承認待ちです')}
                    </Text>
                  )}
                  <Text
                    style={[
                      { fontSize: 16 },
                      isUnregistered && {
                        marginTop: 10,
                      },
                    ]}
                  >
                    {item.email}
                  </Text>
                  {isUnregistered && (
                    <Button
                      onPress={async (event) => {
                        event.preventDefault()
                        await onReinvite(item.id)
                      }}
                      mode="outline"
                      variant="secondary"
                      style={{ marginTop: 10, zIndex: 1, position: 'relative' }}
                    >
                      {t('メールを再送信')}
                    </Button>
                  )}
                </>
              )
            }
            case 'role': {
              const roleName =
                item.role === BusinessUserResource_Role.ADMIN
                  ? t('管理者')
                  : item.businessUserRole?.name ?? ''
              return <Text style={{ fontSize: 16 }}>{roleName}</Text>
            }
            case 'businessUserRole': {
              return (
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <BusinessRolePermissionList
                    businessUserRolePermissions={
                      item.businessUserRole?.businessUserRolePermissions ?? []
                    }
                  />
                </View>
              )
            }
            default:
              return null
          }
        }}
      />
      <FloatingButton
        onPress={() => {
          navigate('/business_users/new')
        }}
        style={{
          position: 'absolute',
          bottom: 32,
          right: 32,
        }}
        label={t('追加')}
      />
    </View>
  )
}
