import React from 'react'
import { useNavigate } from 'react-router'
import { useRestaurantId } from 'modules/useRestaurantId'

import {
  useRestaurantInformation,
  useRestaurantMichelinAwards,
  useRestaurantTabelogAawrds,
} from 'models/Restaurant'

import { getSortedLocales } from '@hello-ai/for_r_app/src/components/Translation'
import { useRestaurantCountryLanguage } from '../../../modules/useRestaurantCountryLanguage'
import { RestaurantInformation as RestaurantInformationContent } from '@hello-ai/for_r_app/src/components/Restaurant/Information'
import config from '@hello-ai/for_r_app/src/modules/config'

export default function RestaurantInformation() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const { restaurant } = useRestaurantInformation(restaurantId)
  const { michelinAwards } = useRestaurantMichelinAwards(restaurantId)
  const { tabelogAwards } = useRestaurantTabelogAawrds(restaurantId)

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)

  if (restaurant == null) {
    return null
  }

  return (
    <RestaurantInformationContent
      restaurant={restaurant}
      sortedLocales={sortedLocales}
      michelinAwards={michelinAwards}
      tabelogAwards={tabelogAwards}
      onPressEditButton={() =>
        navigate(`/restaurants/${restaurantId}/information/edit`)
      }
      externalLinks={{
        arWeb: `${config.webBaseUrl}/ja/restaurants/${restaurant.slug}`,
      }}
    />
  )
}
