import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Grid } from '@hello-ai/ar_shared/src/components/Grid'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { TableSeatResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'
import { useToken } from 'models/Auth'
import { TableSeat as TableSeatModel, useTableSeats } from 'models/TableSeat'
import { tableSeatConnectionService } from 'models/TableSeatConnection'
import { onError } from 'modules/swr'
import { useRestaurantId } from 'modules/useRestaurantId'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigate } from 'react-router'

export default function TableSeatConnectionForm() {
  const restaurantId = useRestaurantId()
  const token = useToken()
  const navigate = useNavigate()

  const { tableSeats } = useTableSeats(
    restaurantId,
    TableSeatResource_Status.PUBLISHED
  )

  const [selectedSeatIds, setSelectedSeatIds] = useFormState<
    Array<TableSeatModel['id']>
  >([])

  const [minPartySize, setMinPartySize] = useState('')
  const [maxPartySize, setMaxPartySize] = useState('')

  const { data: { tableSeatConnections } = {}, mutate } =
    tableSeatConnectionService.useList({
      restaurantId,
    })

  if (tableSeats === undefined || tableSeatConnections === undefined) {
    return <Loading />
  }

  const onCreate = async () => {
    const { error } = await tableSeatConnectionService.create(token, {
      restaurantId,
      minPartySize: Number(minPartySize),
      maxPartySize: Number(maxPartySize),
      tableSeatIds: selectedSeatIds,
    })
    if (error != null) {
      onError(error)
      return
    }

    setSelectedSeatIds([])
    setMinPartySize('')
    setMaxPartySize('')
    await mutate()
    navigate(`/restaurants/${restaurantId}/table_seats/table_seat_connections`)
    displayToastInfo(t('追加しました'))
  }

  const toggleSelectedTableSeatIds = (tableSeatId: string) => {
    if (selectedSeatIds.includes(tableSeatId)) {
      setSelectedSeatIds(
        selectedSeatIds.filter(
          (selectedSeatId) => selectedSeatId !== tableSeatId
        )
      )
    } else {
      setSelectedSeatIds([...selectedSeatIds, tableSeatId])
    }
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <View>
        <Text style={{ fontSize: 22, fontWeight: '600' }}>
          {t('接続パターンを追加')}
        </Text>
        <ShadowBox style={[{ marginTop: 24 }]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontWeight: '600',
                }}
              >
                {t('選択中の席')}
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  lineHeight: 24,
                }}
              >
                {selectedSeatIds.length === 0
                  ? t('なし')
                  : tableSeats
                      .filter(({ id }) => selectedSeatIds.includes(id))
                      .map(({ name }) => name)
                      .join(', ')}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextInput
                  placeholder={t('最小人数')}
                  value={minPartySize}
                  onChangeText={(text) => setMinPartySize(text)}
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  style={{ height: 64 }}
                />
                <TextInput
                  placeholder={t('最大人数')}
                  value={maxPartySize}
                  onChangeText={(text) => setMaxPartySize(text)}
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  style={{
                    height: 64,
                    marginLeft: 16,
                  }}
                />
              </View>
              <Button
                mode="outline"
                style={{
                  marginLeft: 20,
                  height: 48,
                }}
                onPress={onCreate}
                disabled={minPartySize === '' || maxPartySize === ''}
              >
                {t('追加する')}
              </Button>
            </View>
          </View>
          <View
            style={{
              marginTop: 32,
            }}
          >
            <Grid numOfColumns={4} horizontalGap={16} verticalGap={16}>
              {tableSeats?.map((tableSeat) => {
                const selected: boolean = selectedSeatIds.includes(tableSeat.id)
                return (
                  <TouchableOpacity
                    key={tableSeat.id}
                    style={{
                      borderWidth: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderColor: selected ? Colors.accent : Colors.border,
                      borderRadius: 8,
                      padding: 16,
                    }}
                    onPress={() => toggleSelectedTableSeatIds(tableSeat.id)}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        flex: 1,
                        paddingRight: 10,
                      }}
                      numberOfLines={2}
                    >
                      {tableSeat.name}
                    </Text>
                  </TouchableOpacity>
                )
              })}
            </Grid>
          </View>
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
