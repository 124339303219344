/** setOpacityの代替 setOpacityはpolishedを使っていたので、簡易的なものに変更 */
export const hexWithOpacity = (opacity: number, hexColor: string) => {
  if (!hexColor.startsWith('#')) {
    console.warn('hex must start with #')
    return hexColor
  }

  if (hexColor.length !== 7) {
    console.warn('hex must be 7 characters long')
    return hexColor
  }

  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  // min0, max1
  opacity = Math.min(1, Math.max(0, opacity))

  return `rgba(${r},${g},${b},${opacity})`
}
