import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import BusinessUserRoles from 'pages/BusinessUserRoles'
import BusinessUserRolesForm from 'pages/BusinessUserRoles/Form'
import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('役割'),
    fullPath: '/business_user_roles',
    relativePath: '/',
    exact: true,
    element: <BusinessUserRoles />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  New: {
    title: t('役割を追加'),
    fullPath: '/business_user_roles/new',
    relativePath: '/new',
    exact: true,
    element: <BusinessUserRolesForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('役割を編集'),
    fullPath: '/business_user_roles/:businessUserRoleId',
    relativePath: '/:businessUserRoleId',
    exact: true,
    element: <BusinessUserRolesForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function BusinessUserRolesStack() {
  return <Stack routes={routes} />
}
