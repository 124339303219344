import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { swrKey } from '@hello-ai/ar_shared/src/modules/swr'
import { RestaurantCourse } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCourse'
import { TableProduct } from '@hello-ai/ar_shared/src/types/ForR/TableProduct'
import { WithHeaderData, fetcherWithPaginationHeader } from '../modules/swr'
import useSWR from 'swr'

export interface RecommendedProductCandidate {
  id: string
  type: string
  name: string | null
  restaurant_course: RestaurantCourse | null
  table_product: TableProduct | null
}

export function useRestaurantRecommendedProductCandidates(
  restaurantId: number,
  params: {
    keyword?: string
    page?: number
    per_page?: number
  }
) {
  const token = useToken()
  return useSWR<
    WithHeaderData<RecommendedProductCandidate[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/recommended_product_candidates`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<
        RecommendedProductCandidate[],
        {
          keyword?: string
          page?: number
          per_page?: number
        }
      >([token, url, params]),
    {
      keepPreviousData: true,
    }
  )
}
