import React, { useState, useReducer, useEffect, useMemo } from 'react'
import { View, ScrollView } from 'react-native'
import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import produce from 'immer'
import { times, camelCase, snakeCase, mapKeys } from 'lodash'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'

import { SelectedImage, SelectImage } from 'components/Shared/SelectImage'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { SelectMenuPages } from 'components/Takeout/SelectMenuPages'
import TakeoutView from 'components/Restaurant/TakeoutView'
import {
  createTakeoutProduct,
  useTakeoutProduct,
  updateTakeoutProduct,
  TakeoutProduct as TakeoutProductModel,
} from 'models/TakeoutProduct'
import { useRestaurantTableUnlimitedOrderPlans } from 'models/TableUnlimitedOrderPlan'

import { useTakeoutMenuPages } from 'models/TakeoutMenuPage'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'

import { useRestaurantId } from 'modules/useRestaurantId'
import { useToken } from 'models/Auth'
import { goBack, history } from 'modules/history'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Trans } from 'react-i18next'

const initProductPrices = (takeoutProduct: any, isCopy?: boolean) => {
  const defaultTakeoutValue = {
    provideMethod: 'takeout',
    price: '',
    taxType: 'reduced',
    enabled: true,
    taxIncluded: true,
  }

  const takeout = camelize(takeoutProduct?.prices?.takeout ?? {})

  return {
    takeout: {
      ...defaultTakeoutValue,
      ...takeout,
      enabled: true,
      id: isCopy ? null : takeout.id,
    },
  }
}

function optionBoxesReducer(state: any, action: any): any {
  return produce(state, (draft: any) => {
    const { payload } = action
    switch (action.type) {
      case 'INITIALIZE':
        draft = payload
        break
      case 'ADD_OPTION_BOX': {
        draft.push(initialOptionBoxState)
        break
      }
      case 'REMOVE_OPTION_BOX': {
        const { index } = payload
        if (draft[index].id != null) {
          draft[index].destroy = '1'
        } else {
          draft.splice(index, 1)
        }
        break
      }
      case 'CHANGE_OPTION_BOX_REQUIRED': {
        const { index, required } = payload
        draft[index].required = required
        break
      }
      case 'CHANGE_OPTION_BOX_NAME': {
        const { index, name } = payload
        draft[index].name = name
        break
      }
      case 'CHANGE_OPTION_BOX_ADDITIONAL_DESCRIPTION': {
        const { index, additionalDescription } = payload
        draft[index].additionalDescription = additionalDescription
        break
      }
      case 'CHANGE_OPTION_BOX_POSITION': {
        const { index, position } = payload
        draft[index].position = position
        break
      }
      case 'CHANGE_OPTION_BOX_TYPE': {
        const { index, type } = payload
        draft[index].type = type
        break
      }
      case 'SET_OPTION_BOX_MIN_QUANTITY': {
        const { index, minQuantity } = payload
        draft[index].minQuantity = minQuantity
        break
      }
      case 'SET_OPTION_BOX_MAX_QUANTITY': {
        const { index, maxQuantity } = payload
        draft[index].maxQuantity = maxQuantity
        break
      }
      case 'ADD_OPTION': {
        const { index } = payload
        draft[index].options.push(initialOptionState)
        break
      }
      case 'REMOVE_OPTION': {
        const { index, optionIndex } = payload

        if (draft[index].options[optionIndex].id != null) {
          draft[index].options[optionIndex].destroy = '1'
        } else {
          draft[index].options.splice(optionIndex, 1)
        }
        const maxQuantity = filterOptions(draft[index].options).length

        // オプションを削除すると、選択可能な最小・最大個数が変化するので、超えないように調整する
        if (draft[index].minQuantity > maxQuantity) {
          draft[index].minQuantity = maxQuantity
        }

        if (draft[index].maxQuantity > maxQuantity) {
          draft[index].maxQuantity = maxQuantity
        }

        break
      }
      case 'CHANGE_OPTION_NAME': {
        const { index, optionIndex, name } = payload
        draft[index].options[optionIndex].name = name
        break
      }
      case 'CHANGE_OPTION_DESCRIPTION': {
        const { index, optionIndex, description } = payload
        draft[index].options[optionIndex].description = description
        break
      }
      case 'SET_NEW_IMAGE': {
        const { index, optionIndex, image } = payload
        draft[index].options[optionIndex].newImage = image
        break
      }
      case 'CHANGE_REMOVE_IMAGE': {
        const { index, optionIndex, removeImage } = payload
        draft[index].options[optionIndex].removeImage = removeImage
        break
      }
      case 'CHANGE_OPTION_PRICE': {
        const { index, optionIndex, price } = payload
        draft[index].options[optionIndex].price = price
        break
      }
      case 'CHANGE_OPTION_PRICE_CALCULATION': {
        const { index, optionIndex, value } = payload
        draft[index].options[optionIndex].isPlusPrice = value

        break
      }
      case 'CHANGE_OPTION_POSITION': {
        const { index, optionIndex, position } = payload
        draft[index].options[optionIndex].position = position
        break
      }
    }
    return draft
  })
}

function productPricesReducer(state: any, action: any): any {
  return produce(state, (draft: any) => {
    const { payload } = action
    switch (action.type) {
      case 'INITIALIZE':
        draft = initProductPrices(payload.data, payload.isCopy)
        break
      case 'CHANGE_PRODUCT_PRICE': {
        const { provideMethod, price } = payload
        draft[provideMethod].price = price
        break
      }
      case 'CHANGE_PRODUCT_PRICE_TYPE': {
        const { provideMethod, priceType } = payload
        draft[provideMethod].priceType = priceType
        break
      }
      case 'CHANGE_PRODUCT_PRICE_TAX_TYPE': {
        const { provideMethod, taxType } = payload
        draft[provideMethod].taxType = taxType
        break
      }
      case 'CHANGE_PRODUCT_PRICE_TAX_INCLUDED': {
        const { provideMethod, taxIncluded } = payload
        draft[provideMethod].taxIncluded = taxIncluded
        break
      }
    }
    return draft
  })
}

const initialOptionState = {
  id: null,
  name: '',
  price: '',
  isPlusPrice: true,
  position: '',
  destroy: null,
}

const initialOptionBoxState = {
  id: null,
  required: false,
  name: '',
  position: '',
  type: 'radio',
  minQuantity: 1,
  maxQuantity: 1,
  destroy: null,
  options: [initialOptionState],
}

function camelize(obj: any) {
  return mapKeys(obj, (value, key) => camelCase(key))
}

function snakeize(obj: any) {
  return mapKeys(obj, (value, key) => snakeCase(key))
}

function filterOptions(options: any[]) {
  return options.filter(({ destroy }) => destroy !== '1')
}

function ProductPriceTaxIncludedField({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: (isChecked: boolean) => void
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={[
        {
          marginTop: 20,
        },
        width >= sm && {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      ]}
    >
      <Text style={{ flex: 1 }}>{t('税込・税抜設定')}</Text>
      <View
        style={[
          { flex: 1 },
          width < sm && {
            marginTop: 16,
          },
        ]}
      >
        <SegmentedControl
          buttons={[
            { label: t('税込'), value: true },
            { label: t('税抜'), value: false },
          ].map(({ label, value }) => {
            return {
              text: label,
              selected: checked === value,
              onPress: () => onChange(value),
            }
          })}
        />
      </View>
    </View>
  )
}

function TakeoutProductFormScreenContent({
  takeoutProduct,
  isCopy,
  isEdit,
}: {
  takeoutProduct?: TakeoutProductModel
  isCopy: boolean
  isEdit: boolean
}) {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const token = useToken()
  const takeoutProductId = takeoutProduct?.id

  // fetch all menu page
  const { takeoutMenuPages, isLoading: isLoadingMenuPages } =
    useTakeoutMenuPages({
      restaurantId,
      params: {
        per_page: 9999,
      },
    })

  const { isLoading: isLoadingUnlimitedPlans } =
    useRestaurantTableUnlimitedOrderPlans(restaurantId)

  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [removeImage, setRemoveImage] = useState(false)
  const [name, setName] = useFormState(
    isCopy
      ? t('{{text}}のコピー', { text: takeoutProduct?.name })
      : takeoutProduct?.name ?? ''
  )
  const [cost] = useFormState(String(takeoutProduct?.cost ?? ''))
  const [description, setDescription] = useFormState(
    takeoutProduct?.description ?? ''
  )
  const [displaySize, setDisplaySize] = useFormState(
    takeoutProduct?.display_size ?? 'default'
  )

  const [menuPages, setMenuPages] = useFormState(
    takeoutProduct?.takeout_menu_pages
      ? takeoutProduct?.takeout_menu_pages.map((page) => page.id)
      : []
  )

  const initialState = useMemo(() => {
    if (!takeoutProduct) {
      return []
    }

    return takeoutProduct.takeout_product_option_boxes.map((optionBox) => {
      return {
        id: isCopy ? null : optionBox.id,
        name: optionBox.name,
        cost: cost.length > 0 ? Number(cost) : null,
        additionalDescription: optionBox.additional_description,
        position: optionBox.position.toString(),
        required: optionBox.required,
        minQuantity: optionBox.min_quantity,
        maxQuantity: optionBox.max_quantity,
        type:
          optionBox.min_quantity === 1 && optionBox.max_quantity === 1
            ? 'radio'
            : 'check',
        destroy: null,
        options: optionBox.takeout_product_options.map((option) => {
          return {
            id: isCopy ? null : option.id,
            image: option.image,
            newImage: null,
            removeImage: false,
            name: option.name,
            description: option.description,
            position: option.position.toString(),
            price: Math.abs(
              option.prices?.takeout?.price_delta ?? 0
            ).toString(),
            isPlusPrice: (option.prices?.takeout?.price_delta ?? 0) >= 0,
            destroy: null,
          }
        }),
      }
    })
  }, [takeoutProduct, isCopy, cost])

  const [optionBoxes, optionBoxesDispatch] = useReducer(
    optionBoxesReducer,
    initialState
  )

  const [productPrices, productPricesDispatch] = useReducer(
    productPricesReducer,
    takeoutProduct as any,
    (takeoutProduct) => initProductPrices(takeoutProduct, isCopy) as any
  ) as any

  useEffect(() => {
    productPricesDispatch({
      // TODO: 操作済みの場合は初期化しない方がいいかも
      type: 'INITIALIZE',
      payload: { data: takeoutProduct, isCopy },
    })
  }, [takeoutProduct, isCopy])

  useEffect(() => {
    optionBoxesDispatch({
      type: 'INITIALIZE',
      payload: initialState,
    })
  }, [initialState])

  const alertRemoveOption = (index: number, optionIndex: number) => {
    if (filterOptions(optionBoxes[index].options).length === 1) {
      Alert.alert(t('選択肢は必ず一つ存在する必要があります'))
      return
    }
    optionBoxesDispatch({
      type: 'REMOVE_OPTION',
      payload: {
        index,
        optionIndex,
      },
    })
  }

  const alertRemoveOptionBox = (index: number) => {
    Alert.alert(t('本当に削除しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () =>
          optionBoxesDispatch({
            type: 'REMOVE_OPTION_BOX',
            payload: {
              index,
            },
          }),
      },
    ])
  }

  const onPress = async () => {
    if (name === '') {
      Alert.alert(t('商品名を入力してください'))
      return
    }

    if (
      (productPrices.takeout.enabled as boolean) &&
      productPrices.takeout.price === ''
    ) {
      Alert.alert(t('テイクアウトの料金を入力してください'))
      return
    }

    const optionBoxesValid = optionBoxes.every((optionBox: any) => {
      if (optionBox.name === '') {
        return false
      }
      if (optionBox.position === '') {
        return false
      }
      if (filterOptions(optionBox.options).length === 0) {
        return false
      }
      return filterOptions(optionBox.options).every((option) => {
        if (option.name === '') {
          return false
        }
        if (option.price === '') {
          return false
        }
        return option.position !== ''
      })
    }) as boolean

    if (!optionBoxesValid) {
      Alert.alert(t('オプションの全ての項目を埋めてください'))
      return
    }

    const params: any = {
      name,
      description,
      display_size: displaySize,
      takeout_product_prices: [
        {
          ...snakeize(productPrices.takeout),
          price: productPrices.takeout.price ?? 0,
        },
      ],
      takeout_menu_page_ids: menuPages,
      takeout_product_option_boxes: optionBoxes.map((optionBox: any) => {
        return {
          id: optionBox.id,
          name: optionBox.name,
          additional_description: optionBox.additionalDescription,
          required: optionBox.required,
          min_quantity: optionBox.type === 'radio' ? 1 : optionBox.minQuantity,
          max_quantity: optionBox.type === 'radio' ? 1 : optionBox.maxQuantity,
          position: optionBox.position,
          _destroy: optionBox.destroy,
          takeout_product_options: optionBox.options.map((option: any) => {
            const optionParams = {
              id: option.id,
              name: option.name,
              description: option.description,
              price_delta:
                Number(option.price) * (option.isPlusPrice != null ? 1 : -1),
              position: option.position,
              _destroy: option.destroy,
            } as any

            if (option.newImage != null) {
              optionParams.image = option.newImage
            } else {
              if (
                option.removeImage != null &&
                (option.removeImage as boolean)
              ) {
                optionParams.image = null
              }
            }

            return optionParams
          }),
        }
      }),
    }

    if (selectedImage != null) {
      params.image = selectedImage
    } else {
      if (removeImage) {
        params.image = null
      }
    }

    if (isEdit) {
      if (token == null) {
        return
      }
      if (takeoutProductId == null) {
        return
      }
      await updateTakeoutProduct({
        token,
        takeoutProductId,
        params,
      })
      goBack('..')
      displayToastInfo(t('更新しました'))
    } else {
      if (token == null) {
        return
      }
      await createTakeoutProduct({ token, restaurantId, params })

      navigate(`/restaurants/${restaurantId}/takeout/products`)
      displayToastInfo(t('追加しました'))
    }
  }

  const alertArchive = () => {
    const params = {
      status: 'archived',
    }
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (token == null || takeoutProductId == null) {
            return
          }
          await updateTakeoutProduct({
            token,
            takeoutProductId,
            params,
          })
          goBack('..')
        },
      },
    ])
  }

  const alertPublish = () => {
    const params = {
      status: 'published',
    }
    Alert.alert(t('本当に公開状態に戻しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (token == null || takeoutProductId == null) {
            return
          }
          await updateTakeoutProduct({
            token,
            takeoutProductId,
            params,
          })
          goBack('..')
        },
      },
    ])
  }

  if (isLoadingMenuPages || isLoadingUnlimitedPlans) {
    return <Loading />
  }

  return (
    <ScrollView
      contentContainerStyle={[
        width < sm
          ? {
              paddingHorizontal: 16,
              paddingVertical: 20,
            }
          : {
              paddingHorizontal: 36,

              paddingVertical: 40,
            },
      ]}
      style={{ backgroundColor: Colors.bgBlack }}
    >
      <View style={{}}>
        <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('商品')}</Text>
        <ShadowBox
          style={[
            {
              marginTop: 24,
              padding: 32,
            },
          ]}
        >
          <View style={[width >= sm && { flexDirection: 'row' }]}>
            <View style={{ alignSelf: 'flex-start' }}>
              {(takeoutProduct?.image && !removeImage) ??
              selectedImage != null ? (
                <SelectedImage
                  source={{
                    uri: selectedImage ?? takeoutProduct?.image?.sm,
                  }}
                  onRemovePress={() => {
                    setSelectedImage(null)
                    setRemoveImage(true)
                  }}
                />
              ) : (
                <SelectImage
                  onSelectImage={(image) => {
                    setSelectedImage(image)
                    setRemoveImage(false)
                  }}
                />
              )}
            </View>
            <View
              style={[
                { flex: 1 },
                width < sm
                  ? {
                      marginTop: 16,
                    }
                  : {
                      marginLeft: 24,
                    },
              ]}
            >
              <TextInput
                placeholder={t('商品名')}
                value={name}
                onChangeText={(text) => setName(text)}
                autoCapitalize="none"
              />
              <TextInput
                placeholder={t('商品説明')}
                value={description}
                onChangeText={(text) => setDescription(text)}
                autoCapitalize="none"
                style={{ marginTop: 24 }}
                multiline
              />

              <View
                style={[
                  {
                    marginTop: 24,
                  },
                  width >= sm && {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={{ flex: 1 }}>{t('画像の大きさ')}</Text>
                <View
                  style={[
                    { flex: 1 },
                    width < sm && {
                      marginTop: 16,
                    },
                  ]}
                >
                  <SegmentedControl
                    buttons={[
                      {
                        text: t('標準'),
                        selected: displaySize === 'default',
                        onPress: () => {
                          setDisplaySize('default')
                        },
                      },
                      {
                        text: t('大きく表示'),
                        selected: displaySize === 'large',
                        onPress: () => {
                          setDisplaySize('large')
                        },
                      },
                    ]}
                  />
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              paddingTop: 32,
              marginTop: 32,
              borderTopWidth: 0.5,
              borderTopColor: Colors.border,
            }}
          >
            <Text style={{ fontWeight: '600' }}>{t('カテゴリー')}</Text>
            {isLoadingMenuPages ? (
              <Loading />
            ) : (
              <SelectMenuPages
                style={{ marginTop: 20 }}
                menuPages={takeoutMenuPages ?? []}
                menuPageIds={menuPages}
                setMenuPages={setMenuPages}
              />
            )}
          </View>
        </ShadowBox>
      </View>
      <View style={{ marginTop: 40 }}>
        <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('価格')}</Text>
        <ShadowBox
          style={[
            {
              marginTop: 24,
              padding: 32,
            },
          ]}
        >
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontWeight: '600' }}>{t('販売価格')}</Text>
            </View>
            <View
              style={[
                {
                  marginTop: 20,
                },
                width >= sm && {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              ]}
            >
              <Text style={{ flex: 1 }}>{t('税種別')}</Text>
              <View
                style={[
                  { flex: 1 },
                  width < sm && {
                    marginTop: 16,
                  },
                ]}
              >
                <SegmentedControl
                  buttons={[
                    {
                      text: t('標準税率(10%)'),
                      selected: productPrices.takeout.taxType === 'default',
                      onPress: () => {
                        productPricesDispatch({
                          type: 'CHANGE_PRODUCT_PRICE_TAX_TYPE',
                          payload: {
                            provideMethod: 'takeout',
                            taxType: 'default',
                          },
                        })
                      },
                    },
                    {
                      text: t('軽減税率(8%)'),
                      selected: productPrices.takeout.taxType === 'reduced',
                      onPress: () => {
                        productPricesDispatch({
                          type: 'CHANGE_PRODUCT_PRICE_TAX_TYPE',
                          payload: {
                            provideMethod: 'takeout',
                            taxType: 'reduced',
                          },
                        })
                      },
                    },
                  ]}
                />
              </View>
            </View>

            <ProductPriceTaxIncludedField
              checked={productPrices.takeout.taxIncluded}
              onChange={(value) => {
                productPricesDispatch({
                  type: 'CHANGE_PRODUCT_PRICE_TAX_INCLUDED',
                  payload: {
                    provideMethod: 'takeout',
                    taxIncluded: value,
                  },
                })
              }}
            />

            <View
              style={{
                marginTop: 20,
                flex: 1,
              }}
            >
              <TextInput
                style={[
                  width >= sm && {
                    marginLeft: '50%',
                    width: '50%',
                  },
                ]}
                placeholder={t('料金')}
                value={`${productPrices.takeout.price as number}`}
                onChangeText={(text) =>
                  productPricesDispatch({
                    type: 'CHANGE_PRODUCT_PRICE',
                    payload: { provideMethod: 'takeout', price: text },
                  })
                }
                autoCapitalize="none"
                keyboardType="number-pad"
              />
            </View>
            {/* <View
              style={{
                paddingTop: 32,
                marginTop: 32,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>{t('商品原価')}</Text>
              <TextInput
                style={[
                  width >= sm && {
                    width: '50%',
                  },
                  width < sm && {
                    width: '60%',
                  },
                ]}
                placeholder={t('価格')}
                value={`${cost}`}
                onChangeText={(text) => setCost(text)}
                autoCapitalize="none"
                keyboardType="number-pad"
              />
            </View> */}
          </View>
        </ShadowBox>
      </View>
      <View style={{ marginTop: 40 }}>
        <Text style={{ fontSize: 22, fontWeight: '600' }}>
          {t('オプション')}
        </Text>
        {optionBoxes.map((optionBox: any, index: number) => {
          if (optionBox.destroy === '1') return null
          // optionBoxのindexは削除済のものも含むため外側でfilterしない
          return (
            <ShadowBox
              key={`optionBox${index}`}
              style={[
                {
                  marginTop: 24,
                  padding: 32,
                },
              ]}
            >
              <View
                style={[
                  {
                    marginTop: 24,
                  },
                  width >= sm && {
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <TextInput
                  placeholder={t('オプション名')}
                  value={optionBox.name}
                  onChangeText={(text) =>
                    optionBoxesDispatch({
                      type: 'CHANGE_OPTION_BOX_NAME',
                      payload: { index, name: text },
                    })
                  }
                  autoCapitalize="none"
                  style={{ flex: 1 }}
                />

                <TextInput
                  placeholder={t('オプション順番')}
                  value={optionBox.position}
                  onChangeText={(text) =>
                    optionBoxesDispatch({
                      type: 'CHANGE_OPTION_BOX_POSITION',
                      payload: { index, position: text },
                    })
                  }
                  autoCapitalize="none"
                  style={[
                    width < sm && {
                      marginTop: 24,
                    },
                    width >= sm && { marginLeft: 16, width: 170 },
                  ]}
                  keyboardType="number-pad"
                />
              </View>
              <TextInput
                placeholder={t('オプション説明')}
                value={optionBox.additionalDescription}
                onChangeText={(text) =>
                  optionBoxesDispatch({
                    type: 'CHANGE_OPTION_BOX_ADDITIONAL_DESCRIPTION',
                    payload: { index, additionalDescription: text },
                  })
                }
                autoCapitalize="none"
                style={{ marginTop: 24 }}
              />
              <View style={{ marginTop: 24, flexDirection: 'row' }}>
                <TouchableOpacity
                  style={{
                    borderWidth: optionBox.type === 'radio' ? 2 : 1,
                    borderColor:
                      optionBox.type === 'radio'
                        ? Colors.accent
                        : Colors.border,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 52,
                    flex: 1,
                    marginRight: 10,
                    borderRadius: 4,
                  }}
                  onPress={() =>
                    optionBoxesDispatch({
                      type: 'CHANGE_OPTION_BOX_TYPE',
                      payload: { index, type: 'radio' },
                    })
                  }
                >
                  <Text
                    style={[
                      optionBox.type === 'radio' && { fontWeight: '600' },
                    ]}
                  >
                    {t('単一選択')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    borderWidth: optionBox.type === 'check' ? 2 : 1,
                    borderColor:
                      optionBox.type === 'check'
                        ? Colors.accent
                        : Colors.border,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 52,
                    flex: 1,
                    marginLeft: 10,
                    borderRadius: 4,
                  }}
                  onPress={() =>
                    optionBoxesDispatch({
                      type: 'CHANGE_OPTION_BOX_TYPE',
                      payload: { index, type: 'check' },
                    })
                  }
                >
                  <Text
                    style={[
                      optionBox.type === 'check' && { fontWeight: '600' },
                    ]}
                  >
                    {t('複数選択')}
                  </Text>
                </TouchableOpacity>
              </View>
              {optionBox.type === 'check' && (
                <View
                  style={[
                    {
                      marginTop: 24,
                    },
                    width >= sm && {
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <Text style={{ fontWeight: '600', marginRight: 16 }}>
                    {t('選択する個数')}
                  </Text>
                  <View
                    style={[
                      {
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                      width < sm && {
                        marginTop: 16,
                      },
                    ]}
                  >
                    {/* eslint-disable ar-i18n/require-translation-ja */}
                    <Trans i18nKey="<0></0><1>個から</1><2></2><3>個</3>">
                      <SelectInput
                        selectedValue={optionBox.minQuantity}
                        setValue={(value) =>
                          optionBoxesDispatch({
                            type: 'SET_OPTION_BOX_MIN_QUANTITY',
                            payload: { index, minQuantity: value },
                          })
                        }
                        items={times(
                          filterOptions(optionBox.options).length + 1,
                          (i) => {
                            return {
                              label: i.toString(),
                              value: i,
                            }
                          }
                        )}
                      />
                      <Text style={{ marginHorizontal: 8 }}>{'個から'}</Text>
                      <SelectInput
                        selectedValue={optionBox.maxQuantity}
                        setValue={(value) =>
                          optionBoxesDispatch({
                            type: 'SET_OPTION_BOX_MAX_QUANTITY',
                            payload: { index, maxQuantity: value },
                          })
                        }
                        items={times(
                          filterOptions(optionBox.options).length + 1,
                          (i) => {
                            return {
                              label: i.toString(),
                              value: i,
                            }
                          }
                        )}
                      />
                      <Text style={{ marginLeft: 8 }}>{'個'}</Text>
                    </Trans>
                    {/* eslint-enable ar-i18n/require-translation-ja */}
                  </View>
                </View>
              )}
              {optionBox.options.map((option: any, optionIndex: number) => {
                if (option.destroy === '1') return null
                // optionIndexを保持するため外側でfilterしない
                return (
                  <View
                    style={[
                      {
                        marginTop: 24,
                      },

                      width >= sm && {
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                    ]}
                    key={`option${optionIndex}`}
                  >
                    <View
                      style={{
                        alignSelf: 'flex-start',
                      }}
                    >
                      {option.newImage != null ||
                      (option.image != null && option.removeImage == null) ? (
                        <SelectedImage
                          source={{
                            uri: option.newImage ?? option.image.sm,
                          }}
                          onRemovePress={() => {
                            optionBoxesDispatch({
                              type: 'SET_NEW_IMAGE',
                              payload: {
                                index,
                                optionIndex,
                                image: null,
                              },
                            })

                            optionBoxesDispatch({
                              type: 'CHANGE_REMOVE_IMAGE',
                              payload: {
                                index,
                                optionIndex,
                                removeImage: true,
                              },
                            })
                          }}
                        />
                      ) : (
                        <SelectImage
                          onSelectImage={(image) => {
                            optionBoxesDispatch({
                              type: 'SET_NEW_IMAGE',
                              payload: {
                                index,
                                optionIndex,
                                image,
                              },
                            })

                            optionBoxesDispatch({
                              type: 'CHANGE_REMOVE_IMAGE',
                              payload: {
                                index,
                                optionIndex,
                                removeImage: false,
                              },
                            })
                          }}
                        />
                      )}
                    </View>
                    <View
                      style={[
                        { flex: 1 },
                        width < sm
                          ? {
                              marginTop: 16,
                            }
                          : {
                              marginLeft: 20,
                            },
                      ]}
                    >
                      <TextInput
                        placeholder={t('選択肢名')}
                        value={option.name}
                        onChangeText={(text) =>
                          optionBoxesDispatch({
                            type: 'CHANGE_OPTION_NAME',
                            payload: {
                              index,
                              optionIndex,
                              name: text,
                            },
                          })
                        }
                        autoCapitalize="none"
                      />
                      <View
                        style={[
                          {
                            marginTop: 20,
                          },
                          width >= sm && {
                            flexDirection: 'row',
                            alignItems: 'center',
                          },
                        ]}
                      >
                        <View
                          style={[
                            {
                              flexDirection: 'row',
                              alignItems: 'center',
                            },
                            width >= sm && {
                              flex: 3,
                            },
                          ]}
                        >
                          <View
                            style={[
                              {
                                flex: width < sm ? 0.5 : 0.8,
                                marginRight: width < sm ? 10 : 20,
                              },
                            ]}
                          >
                            <SegmentedControl
                              buttons={[
                                { label: t('＋円'), value: true },
                                { label: t('ー円'), value: false },
                              ].map(({ label, value }) => {
                                return {
                                  text: label,
                                  selected: value === option.isPlusPrice,
                                  onPress: () => {
                                    optionBoxesDispatch({
                                      type: 'CHANGE_OPTION_PRICE_CALCULATION',
                                      payload: {
                                        index,
                                        optionIndex,
                                        value,
                                      },
                                    })
                                  },
                                }
                              })}
                            />
                          </View>
                          <TextInput
                            placeholder={t('選択肢価格')}
                            value={option.price}
                            onChangeText={(text) =>
                              optionBoxesDispatch({
                                type: 'CHANGE_OPTION_PRICE',
                                payload: {
                                  index,
                                  optionIndex,
                                  price: text,
                                },
                              })
                            }
                            autoCapitalize="none"
                            keyboardType="number-pad"
                            style={{ flex: width < sm ? 1 : 2 }}
                          />
                        </View>
                        <TextInput
                          placeholder={t('選択肢順番')}
                          value={option.position}
                          onChangeText={(text) =>
                            optionBoxesDispatch({
                              type: 'CHANGE_OPTION_POSITION',
                              payload: {
                                index,
                                optionIndex,
                                position: text,
                              },
                            })
                          }
                          autoCapitalize="none"
                          style={[
                            { flex: 1 },
                            width < sm
                              ? {
                                  marginTop: 20,
                                }
                              : { marginLeft: 20 },
                          ]}
                          keyboardType="number-pad"
                        />
                      </View>
                      <TextInput
                        placeholder={t('選択肢説明')}
                        value={option.description}
                        onChangeText={(text) =>
                          optionBoxesDispatch({
                            type: 'CHANGE_OPTION_DESCRIPTION',
                            payload: {
                              index,
                              optionIndex,
                              description: text,
                            },
                          })
                        }
                        autoCapitalize="none"
                        style={{
                          marginTop: 24,
                          flex: 1,
                        }}
                      />
                    </View>
                    {width < sm ? (
                      <Button
                        height={48}
                        style={{
                          marginTop: 20,
                          borderColor: Colors.border,
                          backgroundColor: 'transparent',
                        }}
                        onPress={() => {
                          alertRemoveOption(index, optionIndex)
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.black,
                            fontWeight: 'normal',
                          }}
                        >
                          {t('選択肢を削除する')}
                        </Text>
                      </Button>
                    ) : (
                      <TouchableOpacity
                        style={[{ marginLeft: 20 }]}
                        onPress={() => alertRemoveOption(index, optionIndex)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size={24}
                          color={Colors.secondaryBlack}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                )
              })}

              <Button
                height={48}
                style={{
                  marginTop: 40,
                  borderColor: Colors.primary,
                  backgroundColor: 'transparent',
                }}
                onPress={() =>
                  optionBoxesDispatch({
                    type: 'ADD_OPTION',
                    payload: { index },
                  })
                }
              >
                <Text
                  style={{
                    color: Colors.primary,
                    fontWeight: 'normal',
                  }}
                >
                  {t('選択肢を追加')}
                </Text>
              </Button>
              <View
                style={{
                  borderTopWidth: 1,
                  borderColor: Colors.border,
                  marginTop: 40,
                  paddingTop: 24,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <TouchableOpacity onPress={() => alertRemoveOptionBox(index)}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    size={24}
                    color={Colors.secondaryBlack}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    borderLeftWidth: 1,
                    borderColor: Colors.border,
                    paddingLeft: 24,
                    marginLeft: 24,
                  }}
                >
                  <Text style={{ marginRight: 8, marginTop: 2 }}>
                    {t('必須')}
                  </Text>
                  <Switch
                    onValueChange={() =>
                      optionBoxesDispatch({
                        type: 'CHANGE_OPTION_BOX_REQUIRED',
                        payload: {
                          index,
                          required: !(optionBox.required as boolean),
                        },
                      })
                    }
                    value={optionBox.required}
                  />
                </View>
              </View>
            </ShadowBox>
          )
        })}
        <Button
          height={48}
          mode="outline"
          variant="primary"
          style={{
            marginTop: 32,
            backgroundColor: 'transparent',
          }}
          onPress={() =>
            optionBoxesDispatch({
              type: 'ADD_OPTION_BOX',
            })
          }
        >
          {t('オプションを追加')}
        </Button>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 40,
        }}
      >
        {isEdit && (
          <Button
            height={48}
            mode="outline"
            variant="danger-secondary"
            style={{
              flex: 1,
              marginRight: 20,
            }}
            onPress={
              takeoutProduct?.status === 'archived'
                ? () => alertPublish()
                : () => alertArchive()
            }
          >
            {takeoutProduct?.status === 'archived'
              ? t('アーカイブから戻す')
              : t('アーカイブする')}
          </Button>
        )}
        {isEdit && (
          <Button
            height={48}
            mode="outline"
            variant="primary"
            style={{
              flex: 1,
              marginRight: 20,
              backgroundColor: 'transparent',
            }}
            onPress={() =>
              history.push(
                `/restaurants/${restaurantId}/takeout/products/new?copy_takeout_product_id=${takeoutProductId}`
              )
            }
          >
            {t('コピーする')}
          </Button>
        )}
        <Button
          height={48}
          style={{
            flex: 1,
          }}
          onPress={onPress}
        >
          {isEdit ? t('更新する') : t('追加する')}
        </Button>
      </View>
    </ScrollView>
  )
}

export default function TakeoutProductFormScreen() {
  const { takeout_product_id: takeoutProductId } = useParams<{
    takeout_product_id: string
  }>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const copyTakeoutProductId = queryParams.get('copy_takeout_product_id')
  const { takeoutProduct, isLoading } = useTakeoutProduct({
    takeoutProductId: takeoutProductId ?? copyTakeoutProductId ?? undefined,
  })

  const isCopy = copyTakeoutProductId != null
  const isEdit = takeoutProductId != null && !isCopy

  if (isLoading) {
    return <Loading />
  }

  return (
    <TakeoutView>
      <TakeoutProductFormScreenContent
        takeoutProduct={takeoutProduct}
        isCopy={isCopy}
        isEdit={isEdit}
      />
    </TakeoutView>
  )
}
