import React, { useLayoutEffect, useState } from 'react'
import { View } from 'react-native'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { signIn, useToken } from 'models/Auth'
import { useNavigate, useLocation } from 'react-router-dom'
import LinkText from 'components/Shared/LinkText'

import { useParams } from 'react-router'
import invariant from 'tiny-invariant'

import { ownerService } from 'models/Owner'
import SignInHeader from 'components/Shared/SignInHeader'
import { Helmet } from 'react-helmet-async'
import { redirectApp } from 'modules/redirectApp'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Trans } from 'react-i18next'

const formLabelWidth = 162
const formLabelMarginRight = 24
const inputWidth = 420
const inputHeight = 64

export default function SignInEmailAndPassword() {
  const { width, sm } = useResponsive()
  const token = useToken()

  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from

  const { id } = useParams<{ id: string }>()

  const queryParams = new URLSearchParams(location.search)
  const defalutEmail = queryParams.get('email') ?? ''

  const [email, setEmail] = useState(defalutEmail)
  const [password, setPassword] = useState('')

  const { data } = ownerService.useGetOwner({ id: Number(id) })

  const disabled = data === undefined || email === '' || password === ''

  const onSubmit = async () => {
    if (disabled) return

    invariant(data !== undefined, 'data cannot be undefined')

    const { error } = await signIn(email, password, data.id)
    if (error != null) {
      return
    }
    navigate(from ?? '/', { replace: true })
  }

  useLayoutEffect(() => {
    if (token != null) {
      navigate(from ?? '/', { replace: true })
    }
  }, [navigate, from, token])

  if (data === undefined) {
    return <Loading />
  }

  return (
    <View
      style={{
        flexGrow: 1,
      }}
    >
      <Helmet>
        <meta
          name="apple-itunes-app"
          content={`app-id=1521972781, app-argument=/owners/${id}/sign_in`}
        />
      </Helmet>
      <SignInHeader />
      <View
        style={[
          {
            zIndex: 1,
            alignItems: 'center',
            marginTop: 80,
            justifyContent: 'center',
            padding: width < sm ? 24 : 16,
          },
          width < sm && {
            width: '100%',
          },
        ]}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
          }}
        >
          {t('{{name}} にログインする', { name: data.name })}
        </Text>
        <View
          style={{
            marginTop: 40,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{ width: '100%', justifyContent: 'center' }}
            formLabel={<FormLabel value={t('メールアドレス')} />}
            formLabelContainerStyle={{
              width: formLabelWidth,
              marginRight: formLabelMarginRight,
            }}
            containerStyle={{
              width: width < sm ? '100%' : inputWidth,
            }}
          >
            <TextInput
              value={email}
              onChangeText={setEmail}
              onSubmitEditing={onSubmit}
              clearButtonMode="always"
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
              style={{
                height: inputHeight,
              }}
              placeholder="information@hello.ai"
            />
          </FormGroup>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{ marginTop: 24, width: '100%', justifyContent: 'center' }}
            formLabel={<FormLabel value={t('パスワード')} />}
            formLabelContainerStyle={{
              width: formLabelWidth,
              marginRight: formLabelMarginRight,
            }}
            containerStyle={{
              width: width < sm ? '100%' : inputWidth,
            }}
          >
            <TextInput
              value={password}
              onChangeText={setPassword}
              onSubmitEditing={onSubmit}
              clearButtonMode="always"
              placeholder={t('6文字以上')}
              secureTextEntry
              style={{
                height: inputHeight,
              }}
            />
          </FormGroup>
          <Button
            disabled={disabled}
            onPress={onSubmit}
            style={{
              marginTop: 48,
              width: width < sm ? '100%' : 278,
              height: 48,
            }}
          >
            {t('ログイン')}
          </Button>
          <LinkText
            to={`/owners/${id}/reset_password`}
            style={{ marginTop: 24, color: Colors.primary, fontSize: 16 }}
          >
            {t('パスワードを忘れた方はこちら')}
          </LinkText>
          <TouchableOpacity
            onPress={() => {
              redirectApp(`owners/${id}/sign_in`)
            }}
          >
            <Text
              style={{ marginTop: 24, color: Colors.primary, fontSize: 16 }}
            >
              {t('アプリでログインする')}
            </Text>
          </TouchableOpacity>
          {/* eslint-disable ar-i18n/require-translation-ja */}
          <Trans
            i18nKey={
              '<0>メールアドレスをお忘れの場合は、<1>サポート</1>へお問い合わせください</0>'
            }
          >
            <Text
              style={{
                marginTop: 16,
                fontSize: 14,
              }}
            >
              {'メールアドレスをお忘れの場合は、'}
              <LinkText
                to="mailto:cs@hello.ai"
                style={{
                  color: Colors.primary,
                }}
              >
                {'サポート'}
              </LinkText>
              {'へお問い合わせください'}
            </Text>
          </Trans>
          {/* eslint-enable ar-i18n/require-translation-ja */}
        </View>
      </View>
    </View>
  )
}
