import React from 'react'
import { useParams } from 'react-router-dom'
import {
  createTableMenu,
  useTableMenu,
  updateTableMenu,
} from 'models/TableMenu'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { useRestaurantId } from 'modules/useRestaurantId'
import TableMenuFormContent from 'components/Menus/TableMenuFormContent'
import { history } from '../../../modules/history'

export default function TableMenuFormScreen() {
  const restaurantId = useRestaurantId()
  const { table_menu_id: tableMenuId } = useParams<{ table_menu_id?: string }>()
  const { tableMenu, isLoading } = useTableMenu({
    tableMenuId,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <TableMenuFormContent
      isOwner={false}
      tableMenu={tableMenu}
      createMenu={async ({ token, params }) => {
        await createTableMenu({
          token,
          params,
          restaurantId,
        })
      }}
      updateMenu={async ({ token, params }) => {
        if (tableMenuId === undefined) {
          return
        }
        await updateTableMenu({
          token,
          params,
          tableMenuId,
        })
      }}
      onPressMenuPage={(id) => {
        history.push(`/restaurants/${restaurantId}/menu_pages/${id}/edit`)
      }}
      onPressUnlimitedOrderPlan={(id) => {
        history.push(
          `/restaurants/${restaurantId}/unlimited_order_plans/${id}/edit`
        )
      }}
    />
  )
}
