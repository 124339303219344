// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course/restaurant_course_cancel_policy_resource.proto" (package "auto_reserve.restaurants.restaurant_course", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseCancelPolicyResource
 */
export interface RestaurantCourseCancelPolicyResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int32 cancel_percent = 2;
   */
  cancelPercent: number;
  /**
   * @generated from protobuf field: int32 cancel_time = 3;
   */
  cancelTime: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string cancel_time_str = 6;
   */
  cancelTimeStr: string;
  /**
   * @generated from protobuf field: string policy_type = 7;
   */
  policyType: string;
  /**
   * @generated from protobuf field: int32 cancel_amount = 8;
   */
  cancelAmount: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseCancelPolicyResource$Type extends MessageType<RestaurantCourseCancelPolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseCancelPolicyResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "cancel_percent",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 3,
          name: "cancel_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 5, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 6,
          name: "cancel_time_str",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: "policy_type",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 8,
          name: "cancel_amount",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseCancelPolicyResource>,
  ): RestaurantCourseCancelPolicyResource {
    const message = {
      id: "",
      cancelPercent: 0,
      cancelTime: 0,
      cancelTimeStr: "",
      policyType: "",
      cancelAmount: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseCancelPolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseCancelPolicyResource,
  ): RestaurantCourseCancelPolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int32 cancel_percent */ 2:
          message.cancelPercent = reader.int32();
          break;
        case /* int32 cancel_time */ 3:
          message.cancelTime = reader.int32();
          break;
        case /* google.protobuf.Timestamp created_at */ 4:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 5:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string cancel_time_str */ 6:
          message.cancelTimeStr = reader.string();
          break;
        case /* string policy_type */ 7:
          message.policyType = reader.string();
          break;
        case /* int32 cancel_amount */ 8:
          message.cancelAmount = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseCancelPolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int32 cancel_percent = 2; */
    if (message.cancelPercent !== 0)
      writer.tag(2, WireType.Varint).int32(message.cancelPercent);
    /* int32 cancel_time = 3; */
    if (message.cancelTime !== 0)
      writer.tag(3, WireType.Varint).int32(message.cancelTime);
    /* google.protobuf.Timestamp created_at = 4; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 5; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string cancel_time_str = 6; */
    if (message.cancelTimeStr !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.cancelTimeStr);
    /* string policy_type = 7; */
    if (message.policyType !== "")
      writer.tag(7, WireType.LengthDelimited).string(message.policyType);
    /* int32 cancel_amount = 8; */
    if (message.cancelAmount !== 0)
      writer.tag(8, WireType.Varint).int32(message.cancelAmount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseCancelPolicyResource
 */
export const RestaurantCourseCancelPolicyResource =
  new RestaurantCourseCancelPolicyResource$Type();
