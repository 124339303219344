import { useState } from 'react'
import { useThrottle } from './useThrottle'

export const useSearchAndPagination = () => {
  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useThrottle(keyword)
  const [currentPage, setCurrentPage] = useState(1)
  const onChangeSearchText = (text: string) => {
    setKeyword(text)
    setCurrentPage(1)
  }

  const onChangeCurrentPage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    keyword: debouncedKeyword,
    currentPage,
    onChangeSearchText,
    onChangeCurrentPage,
  }
}
