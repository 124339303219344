import React, { useRef } from 'react'
import {
  View,
  Pressable,
  PressableProps,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native'
import { createLinkComponent } from './Link'
import { InjectedProps } from './types'
import { mergeRefs } from '../../modules/mergeRefs'

export const PressableLink = createLinkComponent<View, PressableProps>(
  Pressable
)

type LinkOpacityProps = React.ComponentProps<typeof Pressable> & InjectedProps

export const LinkOpacity = React.forwardRef<View, LinkOpacityProps>(
  ({ onPress, to, children, style, ...props }: LinkOpacityProps, hostRef) => {
    const ref = useRef<View>(null)
    return (
      <PressableLink
        ref={mergeRefs([ref, hostRef])}
        to={to}
        onPress={onPress}
        style={(state) => {
          const { hovered, pressed } = state as unknown as {
            hovered: boolean
            pressed: boolean
          }

          return [
            Platform.OS === 'web' && {
              cursor: to == null ? 'auto' : 'pointer',
              userSelect: 'auto',
            },
            {
              textDecorationLine: 'none',
            },
            hovered && {
              opacity: 0.7,
              textDecorationLine: 'none',
            },
            Platform.OS !== 'web' &&
              pressed && {
                opacity: 0.3,
              },
            style,
          ] as StyleProp<ViewStyle>
        }}
        {...props}
      >
        {children}
      </PressableLink>
    )
  }
)
