import React from 'react'
import { View } from 'react-native'
import { TableMenu as TableMenuModel } from 'models/TableMenu'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'

export default function TableMenu({
  tableMenu,
  locked,
  onPress,
}: {
  tableMenu: TableMenuModel
  locked: boolean
  onPress: () => void
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={locked}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View>
        <Text style={{ fontWeight: '600' }}>{tableMenu.name}</Text>
        <Text style={{ marginTop: 6 }}>{tableMenu.time_range_description}</Text>
      </View>
      {locked && (
        <FontAwesomeIcon icon={faLockAlt} color={Colors.black60} size={16} />
      )}
    </TouchableOpacity>
  )
}
