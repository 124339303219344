import React, { useState } from 'react'

import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { useRestaurantId } from 'modules/useRestaurantId'

import { useToken } from 'models/Auth'
import {
  useRestaurantInformation,
  updateRestaurant,
  RestaurantMichelinAwardStatus,
  useRestaurantMichelinAwards,
  createRestaurantMichelinAwardsAddRequest,
  useRestaurantTabelogAawrds,
  createRestaurantTabelogAwardsRequest,
  RestaurantTabelogAwardStatus,
  createRestaurantImages,
  destroyRestaurantImages,
} from 'models/Restaurant'
import { createInformationChangeRequest } from 'models/RestaurantInformationChangeRequest'
import { UpdateRestaurantParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantUpdateParams'
import RestaurantInformationFormContent from '@hello-ai/for_r_app/src/components/Restaurant/Information/Form'
import { DateSelectInput } from 'components/Shared/DateSelectInput'
import dayjs from 'dayjs'

import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { confirmAsync } from '@hello-ai/ar_shared/src/modules/alert'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { createTranslation } from '../../../models/Translation'
import { getSortedLocales } from '@hello-ai/for_r_app/src/components/Translation'
import { useRestaurantCountryLanguage } from '../../../modules/useRestaurantCountryLanguage'

export default function RestaurantInformationForm() {
  const token = useToken()
  const restaurantId = useRestaurantId()
  const { restaurant, mutate } = useRestaurantInformation(restaurantId)

  const { michelinAwards, mutate: michelinMutate } =
    useRestaurantMichelinAwards(restaurantId)
  const { tabelogAwards, mutate: tabelogMutate } =
    useRestaurantTabelogAawrds(restaurantId)

  // オープン日
  const [openAt, setOpenAt] = useFormState(restaurant?.open_at)

  const [isAddingAtmosphereImage, setIsAddingAtmosphereImage] =
    useState<boolean>(false)
  const [isAddingMealImage, setIsAddingMealImage] = useState<boolean>(false)

  const handleChangeRequest = async ({
    informationChangeRequestParams,
  }: {
    informationChangeRequestParams: {
      changed_name?: string
      changed_yomi?: string
      changed_name_en?: string
      changed_name_ko?: string
      changed_name_zh_cn?: string
      changed_name_zh_tw?: string
      changed_name_zh_hk?: string
      changed_phone_number?: string
      changed_address?: string
    }
  }) => {
    if (token === null) return

    {
      const { error: informationChangeError } =
        await createInformationChangeRequest(
          token,
          restaurantId,
          informationChangeRequestParams
        )
      if (informationChangeError != null) return
      mutate()
    }

    displayToastInfo(t('変更をリクエストしました'))
  }

  const update = async ({ params }: { params: UpdateRestaurantParams }) => {
    if (token === null) return

    const { error } = await updateRestaurant(token, restaurantId, params)

    if (error != null) return

    mutate()

    displayToastInfo(t('変更しました'))
  }

  const createMichelinAwardAddRequest = async ({
    awards,
  }: {
    awards: Omit<RestaurantMichelinAwardStatus, 'status'>[]
  }) => {
    if (token === null) return

    const { error } = await createRestaurantMichelinAwardsAddRequest(
      token,
      restaurantId,
      {
        michelin_awards: awards,
      }
    )

    if (error != null) return

    michelinMutate()

    displayToastInfo(t('変更をリクエストしました'))
  }

  const createTabelogAwardRequest = async (
    awards: Omit<RestaurantTabelogAwardStatus, 'status'>[]
  ) => {
    if (token == null) return

    const { error } = await createRestaurantTabelogAwardsRequest(
      token,
      restaurantId,
      { tabelog_awards: awards }
    )

    if (error != null) return

    tabelogMutate()
    displayToastInfo(t('変更をリクエストしました'))
  }

  const createImage = async ({
    image,
    category,
  }: {
    image: string
    category: 'meal' | 'atmosphere'
  }) => {
    if (token == null) return

    if (category === 'atmosphere') {
      setIsAddingAtmosphereImage(true)
    } else {
      setIsAddingMealImage(true)
    }
    const { error } = await createRestaurantImages(token, restaurantId, {
      image,
      category,
    })
    if (category === 'atmosphere') {
      setIsAddingAtmosphereImage(false)
    } else {
      setIsAddingMealImage(false)
    }

    if (error != null) return

    mutate()
    displayToastInfo(t('変更しました'))
  }

  const destroyImage = async ({
    restaurantImageId,
  }: {
    restaurantImageId: number
  }) => {
    if (!(await confirmAsync(t('写真を削除します')))) {
      return
    }
    if (token == null) return

    const { error } = await destroyRestaurantImages(
      token,
      restaurantId,
      restaurantImageId
    )

    if (error != null) return

    mutate()
    displayToastInfo(t('変更しました'))
  }

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)
  if (restaurant == null) {
    return null
  }

  return (
    <RestaurantInformationFormContent
      isApp={false}
      restaurant={restaurant}
      sortedLocales={sortedLocales}
      openAt={openAt}
      michelinAwards={michelinAwards}
      tabelogAwards={tabelogAwards}
      openDateSelectInput={
        <DateSelectInput
          // eslint-disable-next-line ar-i18n/require-translation-ja
          format={'YYYY年M月D日'}
          initialDate={openAt == null ? null : dayjs(openAt)}
          setDate={(date) => setOpenAt(date.format('YYYY-MM-DD'))}
          disableDayAfterToday
        />
      }
      isAddingAtmosphereImage={isAddingAtmosphereImage}
      isAddingMealImage={isAddingMealImage}
      onChangeRequest={handleChangeRequest}
      onMichelinAwardAddRequest={createMichelinAwardAddRequest}
      onTabelogAwardAddRequest={createTabelogAwardRequest}
      onUpdate={update}
      onCreateImage={createImage}
      onDestroyImage={destroyImage}
      createTranslation={(params) => {
        return createTranslation(token!, params)
      }}
    />
  )
}
