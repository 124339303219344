import React, { useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faWalking } from '@fortawesome/pro-solid-svg-icons/faWalking'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { useRestaurantId } from 'modules/useRestaurantId'

import {
  Card,
  Cell,
  Row,
  Header,
  HeaderCell,
  HeaderText,
  TextCell,
  FixedCell,
  SalesChart,
  VariationIcon,
  smallCellTextStyle as cellTextStyle,
} from 'components/Sales'
import { useComponentSize } from 'modules/useComponentSize'
import numberWithDelimiter from 'modules/numberWithDelimiter'

import {
  SalesDashboardData,
  useTakeoutRestaurantSalesDashboard,
} from 'models/TakeoutSales'
import { getDateFormat, ValueType, WrappedSelectedInput } from './SalesByPeriod'

import { useLocation } from 'react-router-dom'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

dayjs.extend(utc)

interface Segment {
  name: string
  icon: IconDefinition
}

const segments: Record<string, Segment> = {
  takeout: {
    name: t('テイクアウト'),
    icon: faWalking,
  },
}

function generateChartData(
  data: SalesDashboardData['data'],
  valueType: ValueType
) {
  const newData = [...data]
  newData.sort((a, b) => (a.date > b.date ? 1 : a.date === b.date ? 0 : -1))
  const labels = newData.map((d) => dayjs(d.date).format('DD'))
  if (valueType === 'amount') {
    const grossSales = newData.map((d, i) => ({
      x: labels[i],
      y: d.amount,
      name: t('総売上'),
    }))
    const netSales = newData.map((d, i) => ({
      x: labels[i],
      y: d.amount - d.application_fee_amount,
      name: t('純売上'),
    }))

    const datasets = [
      {
        data: grossSales,
        color: '#AB9355',
      },
      {
        data: netSales,
        color: '#009688',
      },
    ]
    return {
      datasets,
      legends: [t('総売上'), t('純売上')],
    }
  }
  return { datasets: [], legends: [] }
}

function SalesDetail() {
  const restaurantId = useRestaurantId()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const startDate = queryParams.get('start_date')

  const [valueType, setValueType] = useState<ValueType>('amount')
  const [startOfMonth, endOfMonth] = useMemo(() => {
    const startOfMonth = dayjs(startDate ?? new Date())
    const endOfMonth = startOfMonth.endOf('month')
    return [startOfMonth, endOfMonth]
  }, [startDate])

  const { salesData } = useTakeoutRestaurantSalesDashboard(
    restaurantId,
    'daily',
    {
      completed_at: {
        gte: startOfMonth.toISOString(),
        lte: endOfMonth.toISOString(),
      },
      segments: null,
    }
  )

  const [size, isReady, onLayout] = useComponentSize()

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        marginTop: 41,
        marginLeft: 48,
        marginRight: 48,
        paddingBottom: 100,
      }}
    >
      <View onLayout={onLayout}>
        {isReady && salesData !== undefined ? (
          <>
            <Text
              style={{ fontSize: 24, fontWeight: '600', color: Colors.black }}
            >
              {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
              {endOfMonth.format('YYYY年M月')}
            </Text>

            {/* Cards */}
            <View
              style={{
                marginTop: 32,
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                margin: -40,
              }}
            >
              {salesData.segment_totals.map((segment) => {
                const { name, icon } = segments[segment.id]
                return (
                  <Card
                    key={segment.id}
                    margin={40}
                    title={name}
                    height={120}
                    icon={icon}
                    totalAmount={segment.amount}
                  />
                )
              })}
            </View>
            <View
              style={{
                marginTop: 36,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <View style={{ marginRight: 10 }}>
                <Text style={{ fontSize: 18, fontWeight: '600' }}>
                  {t('表示する値')}
                </Text>
              </View>
              <View
                style={{
                  minWidth: 80,
                }}
              >
                <WrappedSelectedInput
                  selectedValue={valueType}
                  setValue={setValueType}
                  items={[
                    {
                      label: t('金額'),
                      value: 'amount',
                    },
                  ]}
                />
              </View>
            </View>
            {/* Chart */}
            <View style={{ marginTop: 18 }}>
              <SalesChart
                totalAmount={salesData?.total?.amount || 0}
                startDate={startOfMonth}
                endDate={endOfMonth}
                data={generateChartData(salesData.data, valueType)}
                valueType={valueType}
                width={size.width}
                height={370}
              />

              {/* Table */}
              <ShadowBox
                style={[
                  {
                    marginTop: 36,
                    padding: 0,
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                ]}
              >
                <Header>
                  <HeaderCell value={t('日付')} isLead />
                  <HeaderCell value={t('総売上')} />
                  <HeaderCell value={t('純売上')} />
                  <HeaderCell value={t('手数料')} />
                  <FixedCell width={60} height={55}>
                    <HeaderText value={t('前日比')} />
                  </FixedCell>
                </Header>
                <Row>
                  <Cell isLead height={24} />
                </Row>
                {salesData.data.map((row, i) => {
                  const date = getDateFormat(row.date, 'daily')
                  const gross = numberWithDelimiter(row.amount || 0)
                  const net = numberWithDelimiter(
                    row.amount - row.application_fee_amount || 0
                  )
                  const fee = numberWithDelimiter(
                    row.application_fee_amount || 0
                  )

                  return (
                    <Row key={i}>
                      <TextCell style={cellTextStyle} value={date} isLead />
                      <TextCell
                        style={cellTextStyle}
                        value={t('{{price}}円', { price: gross })}
                      />
                      <TextCell
                        style={cellTextStyle}
                        value={t('{{price}}円', { price: net })}
                      />
                      <TextCell
                        style={cellTextStyle}
                        value={t('{{price}}円', { price: fee })}
                      />
                      <FixedCell width={60} height={55}>
                        <View style={{ marginLeft: 10 }}>
                          <VariationIcon
                            amountDelta={row.amount_delta}
                            size={20}
                          />
                        </View>
                      </FixedCell>
                    </Row>
                  )
                })}
              </ShadowBox>
            </View>
          </>
        ) : (
          <Loading />
        )}
      </View>
    </ScrollView>
  )
}

export default SalesDetail
