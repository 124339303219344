import React, { useState } from 'react'
import { View, ScrollView } from 'react-native'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInputWithIcon } from '@hello-ai/ar_shared/src/components/TextInputWithIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useThrottle } from '@hello-ai/for_r_app/src/modules/useThrottle'
import {
  useRestaurantTakeoutProducts,
  updateTakeoutProduct,
} from 'models/TakeoutProduct'
import TakeoutProduct from 'components/Restaurant/TakeoutProduct'
import TakeoutView from 'components/Restaurant/TakeoutView'

import { useRestaurantId } from 'modules/useRestaurantId'
import { useToken } from 'models/Auth'
import { history } from 'modules/history'
import RoundedActionButton from 'components/Shared/RoundedActionButton'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function TakeoutProductsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const token = useToken()

  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useThrottle(keyword)
  const [productsCurrentPage, setProductsCurrentPage] = useState(1)
  const { takeoutProducts, headerData, mutate } = useRestaurantTakeoutProducts({
    restaurantId,
    params: {
      page: productsCurrentPage,
      keyword: debouncedKeyword,
    },
  })

  const searchProducts = (text: string) => {
    setKeyword(text)
    setProductsCurrentPage(1)
  }

  const isLoading = takeoutProducts == null || headerData == null

  return (
    <TakeoutView>
      <ScrollView
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
      >
        <View style={{ flexDirection: 'row' }}>
          <RoundedActionButton
            onPress={() =>
              history.push(`/restaurants/${restaurantId}/takeout/products/new`)
            }
          >
            {t('商品を追加')}
          </RoundedActionButton>
        </View>
        <View style={{ marginTop: 40 }}>
          <TextInputWithIcon
            value={keyword}
            onChangeText={(text) => searchProducts(text)}
            autoCapitalize="none"
            placeholder={t('商品名で検索')}
            icon={faSearch}
            style={{ backgroundColor: 'white' }}
          />
          <ShadowBox
            style={[
              {
                paddingHorizontal: 0,
                paddingVertical: 8,
                marginTop: 24,
              },
            ]}
          >
            {isLoading && (
              <View style={{ padding: 24 }}>
                <Loading />
              </View>
            )}
            {!isLoading &&
              takeoutProducts != null &&
              takeoutProducts?.length > 0 &&
              takeoutProducts.map((takeoutProduct, index) => {
                return (
                  <View
                    key={takeoutProduct.id}
                    style={{
                      borderTopWidth: index === 0 ? 0 : 0.5,
                      borderColor: Colors.border,
                      paddingTop: 16,
                      marginBottom: 16,
                      paddingHorizontal: 24,
                    }}
                  >
                    <TakeoutProduct
                      onChangeInStock={async (takeoutProductId, params) => {
                        const { takeoutProduct } = await updateTakeoutProduct({
                          token: token ?? '',
                          takeoutProductId,
                          params,
                        })
                        if (takeoutProduct == null || headerData == null) {
                          return
                        }
                        const newTakeoutProducts = takeoutProducts.map(
                          (product) => {
                            if (product.id === takeoutProduct.id) {
                              return takeoutProduct
                            }
                            return product
                          }
                        )
                        mutate({
                          takeoutProducts: newTakeoutProducts,
                          headerData,
                        })
                      }}
                      takeoutProduct={takeoutProduct}
                      onPress={() =>
                        history.push(
                          `/restaurants/${restaurantId}/takeout/products/${takeoutProduct.id}/edit`
                        )
                      }
                    />
                  </View>
                )
              })}
            {!isLoading && takeoutProducts?.length === 0 && (
              <Text
                style={{
                  paddingTop: 16,
                  marginBottom: 24,
                  paddingHorizontal: 24,
                  fontSize: 22,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当する商品はありません')}
              </Text>
            )}
            <View
              style={{
                borderTopWidth: 0.5,
                borderColor: Colors.border,
                paddingTop: 24,
                marginBottom: 16,
              }}
            >
              <Pagination
                currentPage={productsCurrentPage}
                totalPage={headerData?.totalPages ?? 0}
                setPage={(page) => setProductsCurrentPage(page)}
              />
            </View>
          </ShadowBox>
        </View>
      </ScrollView>
    </TakeoutView>
  )
}
