import React, { useMemo } from 'react'
import { View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs, { Dayjs } from '@hello-ai/ar_shared/src/modules/dayjs'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useCalendarContext } from './CalendarContext'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

interface DayComponentProps {
  date: Dayjs
}

export default function DayComponent({ date }: DayComponentProps) {
  const { width, sm } = useResponsive()
  const { visibleMonth, unpublishedDays, onPressDay } = useCalendarContext()
  const height = 102
  const textColor = useMemo(() => {
    switch (date.day()) {
      case 0:
        return Colors.caution
      case 6:
        return '#2C67C0'
      default:
        return Colors.black
    }
  }, [date])

  if (visibleMonth.month() !== date.month()) return <View style={{ flex: 1 }} />

  const isDisabled = date.isBefore(dayjs(), 'days')

  if (isDisabled) {
    return (
      <View
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingVertical: 8,
          flex: 1,
          height,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: '600',
            color: textColor,
            opacity: 0.4,
          }}
        >
          {date.date()}
        </Text>
      </View>
    )
  }

  const isSelected = !unpublishedDays.some((day) => day.isSame(date, 'date'))

  return (
    <TouchableOpacity
      onPress={() => {
        onPressDay(date)
      }}
      style={[
        isSelected && {
          backgroundColor: Colors.accent10,
        },
        {
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 8,
          rowGap: 16,
          flex: 1,
          height,
        },
      ]}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: '600',
          color: textColor,
          opacity: isSelected ? 1 : 0.5,
        }}
      >
        {date.date()}
      </Text>
      {width >= sm ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            backgroundColor: isSelected ? Colors.accent : Colors.white,
            borderColor: isSelected ? undefined : Colors.border,
            borderWidth: isSelected ? 0 : 1,
            paddingHorizontal: 22,
            paddingVertical: 10,
          }}
        >
          <FontAwesomeIcon
            icon={isSelected ? faCheck : faTimes}
            size={16}
            color={isSelected ? Colors.white : Colors.primary}
          />
          <Text
            style={{
              color: isSelected ? Colors.white : Colors.primary,
              fontSize: 14,
              fontWeight: '300',
            }}
          >
            {isSelected ? t('提供する') : t('提供しない')}
          </Text>
        </View>
      ) : (
        <View
          style={{
            width: 18,
            height: 18,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: isSelected ? Colors.accent : Colors.white,
            borderRadius: 4,
            borderColor: isSelected ? undefined : Colors.border,
            borderWidth: isSelected ? 0 : 4,
          }}
        >
          {isSelected && (
            <FontAwesomeIcon
              icon={faCheck}
              size={12}
              color={Colors.white}
              style={{ alignSelf: 'center' }}
            />
          )}
        </View>
      )}
    </TouchableOpacity>
  )
}
