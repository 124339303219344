import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import {
  FormDivider,
  FormGroup,
  FormLabel,
} from '@hello-ai/ar_shared/src/components/Form'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import {
  lineHeightMultiplier,
  Text,
} from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import {
  RestaurantCourseQuestionResource,
  RestaurantCourseQuestionResource_Category,
  RestaurantCourseQuestionResource_Status,
  RestaurantCourseQuestionSelectionResource,
  RestaurantCourseQuestionSelectionResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_question_resource'
import {
  ArchiveRequest,
  CreateRequest,
  UpdateRequest,
  UpdateRequest_QuestionSelectionResource,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service'
import { StringValue } from '@hello-ai/proto/src/gen/google/protobuf/wrappers'
import produce from 'immer'
import React from 'react'
import { View } from 'react-native'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import {
  localeData,
  SupportedLocale,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'
import { LanguageExpandToggleButton } from '../Shared/LanguageExpandToggleButton'

type RestaurantCourseQuestionFormProps = {
  restaurantId: Restaurant['id']
  restaurantCourseQuestionId: RestaurantCourseQuestionResource['id'] | undefined
  restaurantCourseQuestion: RestaurantCourseQuestionResource | undefined
  onCreate: (params: CreateRequest) => void
  onCreateError: (errors: string[]) => void
  onUpdate: (params: UpdateRequest) => void
  onUpdateError: (errors: string[]) => void
  onArchive: (params: ArchiveRequest) => void
}

interface FormQuestion {
  id?: StringValue
  name: string
  descriptionI18NJa: string
  descriptionI18NEn: string
  descriptionI18NZhCn: string
  descriptionI18NZhTw: string
  descriptionI18NZhHk: string
  descriptionI18NKo: string
  category: RestaurantCourseQuestionResource_Category
  isRequired: boolean
  status: RestaurantCourseQuestionResource_Status
  selections: Array<
    Omit<UpdateRequest_QuestionSelectionResource, 'description'>
  >
}

type CourseQuestionDescriptionType = keyof RestaurantCourseQuestionResource &
  `descriptionI18N${string}` // descriptionI18NJaなどにヒットする

type SelectionDescriptionType =
  keyof RestaurantCourseQuestionSelectionResource & `descriptionI18N${string}` // descriptionI18NJaなどにヒットする

export function RestaurantCourseQuestionForm({
  restaurantId,
  restaurantCourseQuestionId,
  restaurantCourseQuestion,
  onCreate,
  onCreateError: _onCreateError,
  onUpdate,
  onUpdateError: _onUpdateError,
  onArchive,
}: RestaurantCourseQuestionFormProps) {
  const { width, sm, md } = useResponsive()

  const [question, setQuestion] = useFormState<FormQuestion>(() => {
    if (restaurantCourseQuestion) {
      return {
        id: { value: restaurantCourseQuestion.id },
        name: restaurantCourseQuestion.name,
        descriptionI18NJa: restaurantCourseQuestion.descriptionI18NJa,
        descriptionI18NEn: restaurantCourseQuestion.descriptionI18NEn,
        descriptionI18NKo: restaurantCourseQuestion.descriptionI18NKo,
        descriptionI18NZhCn: restaurantCourseQuestion.descriptionI18NZhCn,
        descriptionI18NZhHk: restaurantCourseQuestion.descriptionI18NZhHk,
        descriptionI18NZhTw: restaurantCourseQuestion.descriptionI18NZhTw,
        category: restaurantCourseQuestion.category,
        isRequired: restaurantCourseQuestion.isRequired,
        status: restaurantCourseQuestion.status,
        selections: restaurantCourseQuestion.selections.map((selection) => ({
          id: { value: selection.id },
          descriptionI18NJa: selection.descriptionI18NJa,
          descriptionI18NEn: selection.descriptionI18NEn,
          descriptionI18NKo: selection.descriptionI18NKo,
          descriptionI18NZhCn: selection.descriptionI18NZhCn,
          descriptionI18NZhHk: selection.descriptionI18NZhHk,
          descriptionI18NZhTw: selection.descriptionI18NZhTw,
          status: selection.status,
        })),
      }
    }

    return {
      name: '',
      descriptionI18NJa: '',
      descriptionI18NEn: '',
      descriptionI18NKo: '',
      descriptionI18NZhCn: '',
      descriptionI18NZhHk: '',
      descriptionI18NZhTw: '',
      category: RestaurantCourseQuestionResource_Category.TEXT,
      isRequired: false,
      status: RestaurantCourseQuestionResource_Status.PUBLISHED,
      selections: [],
    }
  })

  const onChangeQuestionName = (value: string) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.name = value
      })
    )
  }

  const onChangeQuestionCategory = (
    value: RestaurantCourseQuestionResource_Category
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.category = value
      })
    )
  }

  // 海外言語がある場合は開いておく
  const [isQuestionDescriptionExpanded, setIsQuestionDescriptionExpanded] =
    useFormState(Boolean(restaurantCourseQuestion?.descriptionI18NEn))

  const onChangeQuestionDescription = <T extends CourseQuestionDescriptionType>(
    type: T,
    value: RestaurantCourseQuestionResource[T]
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft[type] = value
      })
    )
  }

  const onChangeQuestionIsRequired = (
    value: RestaurantCourseQuestionResource['isRequired']
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.isRequired = value
      })
    )
  }

  // 海外言語がある場合は開いておく
  const [isSelectionDescriptionExpanded, setIsSelectionDescriptionExpanded] =
    useFormState(
      question.selections.map((selection) =>
        Boolean(selection?.descriptionI18NEn)
      )
    )

  const onChangeSelectionDescription = <T extends SelectionDescriptionType>(
    selectionIndex: number,
    type: T,
    value: RestaurantCourseQuestionSelectionResource[T]
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.selections[selectionIndex][type] = value
      })
    )
  }

  const onChangeSelectionArchive = (selectionIndex: number) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        if (draft.selections[selectionIndex].id !== undefined) {
          draft.selections[selectionIndex].status =
            RestaurantCourseQuestionSelectionResource_Status.ARCHIVED
        } else {
          draft.selections.splice(selectionIndex, 1)
        }
      })
    )
  }

  const addSelectionForm = () => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.selections.push({
          descriptionI18NJa: '',
          descriptionI18NEn: '',
          descriptionI18NKo: '',
          descriptionI18NZhCn: '',
          descriptionI18NZhHk: '',
          descriptionI18NZhTw: '',
          status: RestaurantCourseQuestionSelectionResource_Status.PUBLISHED,
        })
      })
    )
  }

  const onCreate_ = async () => {
    const params: CreateRequest = {
      restaurantId,
      name: question.name,
      description: question.descriptionI18NJa,
      descriptionI18NJa: question.descriptionI18NJa,
      descriptionI18NEn: question.descriptionI18NEn,
      descriptionI18NKo: question.descriptionI18NKo,
      descriptionI18NZhCn: question.descriptionI18NZhCn,
      descriptionI18NZhHk: question.descriptionI18NZhHk,
      descriptionI18NZhTw: question.descriptionI18NZhTw,
      category: question.category,
      isRequired: question.isRequired,
      selections: question.selections.map((selection) => ({
        description: selection.descriptionI18NJa,
        ...selection,
      })),
    }

    return onCreate(params)
  }

  const onUpdate_ = async () => {
    if (restaurantCourseQuestionId === undefined) return

    const params: UpdateRequest = {
      id: restaurantCourseQuestionId,
      name: question.name,
      description: question.descriptionI18NJa,
      descriptionI18NJa: question.descriptionI18NJa,
      descriptionI18NEn: question.descriptionI18NEn,
      descriptionI18NKo: question.descriptionI18NKo,
      descriptionI18NZhCn: question.descriptionI18NZhCn,
      descriptionI18NZhHk: question.descriptionI18NZhHk,
      descriptionI18NZhTw: question.descriptionI18NZhTw,
      category: question.category,
      isRequired: question.isRequired,
      selections: question.selections.map((selection) => ({
        description: selection.descriptionI18NJa,
        ...selection,
      })),
    }
    return onUpdate(params)
  }

  const onArchive_ = async () => {
    if (restaurantCourseQuestionId === undefined) return

    const params: ArchiveRequest = {
      id: restaurantCourseQuestionId,
    }
    return onArchive(params)
  }

  return (
    <View>
      <ShadowBox>
        <FormGroup
          mode="inline-expanded"
          formLabel={
            <View
              style={{
                marginTop: (64 - 18 * lineHeightMultiplier) / 2,
              }}
            >
              <FormLabel required value={t('質問タイトル')} />
            </View>
          }
        >
          <TextInput
            value={question.name}
            onChangeText={onChangeQuestionName}
          />
          <Text
            style={{
              marginTop: 8,
              color: Colors.black60,
            }}
          >
            {t('お客様には表示されません')}
          </Text>
        </FormGroup>
        <FormGroup
          mode={width < sm ? 'vertical' : 'inline'}
          formLabel={<FormLabel value={t('質問形式')} />}
          style={{ marginTop: 24 }}
        >
          <SegmentedControl
            buttons={[
              { label: t('テキスト'), value: 0 },
              { label: t('チェックボックス'), value: 1 },
              { label: t('ラジオボタン'), value: 2 },
            ].map(({ label, value }) => {
              return {
                text: label,
                selected: value === question.category,
                onPress: () => onChangeQuestionCategory(value),
              }
            })}
          />
        </FormGroup>
        <FormGroup
          mode={'vertical'}
          formLabel={<FormLabel value={t('お客様への質問文')} required />}
          style={{
            marginTop: 24,
          }}
          containerStyle={{
            marginTop: 8,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: Colors.black60,
            }}
          >
            日本語の情報を入力して登録すると、自動で翻訳して他の言語も登録します。ただし編集時には自動翻訳はされません。
          </Text>
          {supportedLocales
            .slice(
              0,
              isQuestionDescriptionExpanded ? supportedLocales.length : 1
            )
            .map((locale: SupportedLocale) => {
              const descriptionType = ('descriptionI18N' +
                locale
                  .split(/-/)
                  .map(
                    (value) =>
                      value[0].toUpperCase() + value.slice(1).toLowerCase()
                  )
                  .join('')) as CourseQuestionDescriptionType

              return (
                <FormGroup
                  key={locale}
                  formLabel={
                    <View
                      style={{
                        paddingLeft: 24,
                      }}
                    >
                      <FormLabel value={localeData[locale].label} />
                    </View>
                  }
                  mode={width >= md ? 'inline' : 'vertical'}
                  style={{
                    marginTop: 24,
                  }}
                >
                  <TextInput
                    multiline
                    style={{ height: 96, marginTop: 24 }}
                    onChangeText={(text) =>
                      onChangeQuestionDescription(descriptionType, text)
                    }
                    value={question[descriptionType]}
                  />
                </FormGroup>
              )
            })}
          <LanguageExpandToggleButton
            isExpanded={isQuestionDescriptionExpanded}
            onPress={() => {
              setIsQuestionDescriptionExpanded((value) => !value)
            }}
            style={{ marginTop: 24 }}
          />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: 24,
            }}
          >
            <Text style={{ marginRight: 12 }}>{t('回答を必須にする')}</Text>
            <Switch
              onValueChange={() =>
                onChangeQuestionIsRequired(!question.isRequired)
              }
              value={question.isRequired}
            />
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              {question.category !== 0 && (
                <View>
                  {question.selections.map((selection, selectionIndex) => {
                    if (
                      selection.status ===
                      RestaurantCourseQuestionSelectionResource_Status.ARCHIVED
                    )
                      return null
                    return (
                      <React.Fragment key={selectionIndex}>
                        <FormDivider style={{ marginTop: 24 }} />
                        <FormGroup
                          mode={'vertical'}
                          formLabel={
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormLabel
                                value={t('回答 {{text}}', {
                                  text: selectionIndex + 1,
                                })}
                              />
                              <TouchableOpacity
                                style={[{ marginLeft: 24 }]}
                                onPress={() =>
                                  onChangeSelectionArchive(selectionIndex)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  size={24}
                                  color={Colors.secondaryBlack}
                                />
                              </TouchableOpacity>
                            </View>
                          }
                          style={{ marginTop: 24 }}
                        >
                          {supportedLocales
                            .slice(
                              0,
                              isSelectionDescriptionExpanded[selectionIndex]
                                ? supportedLocales.length
                                : 1
                            )
                            .map((locale: SupportedLocale) => {
                              const selectionDescriptionType =
                                ('descriptionI18N' +
                                  locale
                                    .split(/-/)
                                    .map(
                                      (value) =>
                                        value[0].toUpperCase() +
                                        value.slice(1).toLowerCase()
                                    )
                                    .join('')) as SelectionDescriptionType
                              return (
                                <FormGroup
                                  key={locale}
                                  formLabel={
                                    <View
                                      style={{
                                        paddingLeft: 24,
                                      }}
                                    >
                                      <FormLabel
                                        value={localeData[locale].label}
                                      />
                                    </View>
                                  }
                                  mode={width >= md ? 'inline' : 'vertical'}
                                  style={{
                                    marginTop: 24,
                                  }}
                                >
                                  <TextInput
                                    onChangeText={(text) =>
                                      onChangeSelectionDescription(
                                        selectionIndex,
                                        selectionDescriptionType,
                                        text
                                      )
                                    }
                                    value={selection[selectionDescriptionType]}
                                  />
                                </FormGroup>
                              )
                            })}
                          <LanguageExpandToggleButton
                            isExpanded={
                              isSelectionDescriptionExpanded[selectionIndex]
                            }
                            onPress={() => {
                              setIsSelectionDescriptionExpanded((value) =>
                                produce(value, (draft) => {
                                  draft[selectionIndex] = !value[selectionIndex]
                                })
                              )
                            }}
                            style={{ marginTop: 24 }}
                          />
                        </FormGroup>
                      </React.Fragment>
                    )
                  })}
                  <Button
                    style={{
                      marginTop: 24,
                    }}
                    mode="outline"
                    onPress={() => addSelectionForm()}
                  >
                    {t('回答を追加する')}
                  </Button>
                </View>
              )}
            </View>
          </View>
        </FormGroup>
      </ShadowBox>
      <View
        style={{
          marginTop: 48,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {restaurantCourseQuestionId !== undefined && (
          <Button
            onPress={onArchive_}
            mode="outline"
            style={{
              width: 280,
              height: 56,
            }}
            variant="danger-secondary"
          >
            {t('アーカイブする')}
          </Button>
        )}
        <Button
          onPress={restaurantCourseQuestionId == null ? onCreate_ : onUpdate_}
          style={{
            marginLeft: 20,
            width: 280,
            height: 56,
          }}
        >
          {t('保存する')}
        </Button>
      </View>
    </View>
  )
}
