import { signOut } from 'models/Auth'
import React from 'react'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faMoneyBillAlt } from '@fortawesome/pro-solid-svg-icons/faMoneyBillAlt'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'

import Restaurants from '../../pages/Top/Restaurants'
import DevSettings from '../../pages/Top/DevSettings'
import Config from '@hello-ai/for_r_app/src/modules/config'
import { useCurrentOwner } from 'models/Owner'
import {
  Drawer,
  DrawerRouteKey,
  DrawerSection,
  DrawerSectionKey,
} from 'components/Shared/Navigation/Drawer'
import { DrawerHeader } from 'components/Shared/Navigation/DrawerHeader'
import Icon from 'components/Restaurant/Icon'

import BusinessUsersStack from 'navigation/BusinessUsers/Stack'
import BusinessUserRolesStack from 'navigation/BusinessUserRoles/Stack'
import SettingsPaymentStack from 'navigation/Settings/PaymentStack'
import { View } from 'react-native'
import LinkOpacity from 'components/Shared/LinkOpacity'

import SettingsAccount from 'pages/Settings/Account'

import RestaurantPayments from 'pages/Restaurants/Payments'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  faAddressCard,
  faCalendarAlt,
  faChartLine,
  faClipboardList,
  faCreditCard,
  faUserFriends,
  faUserTie,
} from '@fortawesome/pro-solid-svg-icons'
import Reports from 'pages/Reports'
import Sales from 'pages/Sales/Sales'

export function useTopRoutePaths() {
  const drawerRoutes = useDrawerRoutes()
  return Object.values(drawerRoutes).flatMap(({ routes }) =>
    Object.values(routes).flatMap(({ routes }) => routes)
  )
}

function useDrawerRoutes() {
  const drawerRoutes: Record<DrawerSectionKey, DrawerSection> = {
    Root: {
      title: '',
      routes: {
        Restaurants: {
          title: t('店舗一覧'),
          routes: [
            {
              fullPath: '/',
              relativePath: '/',
              exact: true,
              title: t('店舗一覧'),
              element: <Restaurants />,
            },
          ],
        },
        Accounts: {
          title: t('アカウント一覧'),
          routes: [
            {
              title: t('アカウント一覧'),
              fullPath: '/business_users',
              relativePath: '/business_users',
              exact: false,
              element: <BusinessUsersStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        Roles: {
          title: t('役割一覧'),
          routes: [
            {
              title: t('役割'),
              fullPath: '/business_user_roles',
              relativePath: '/business_user_roles',
              exact: false,
              element: <BusinessUserRolesStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        Owner: {
          title: t('オーナー情報'),
          routes: [
            {
              title: t('オーナー情報'),
              fullPath: '/settings/account',
              relativePath: '/settings/account',
              exact: true,
              element: <SettingsAccount />,
            },
          ],
        },
        Payments: {
          title: t('入金履歴'),
          routes: [
            {
              title: t('入金履歴'),
              exact: true,
              fullPath: '/payments',
              relativePath: '/payments',
              element: <RestaurantPayments />,
            },
          ],
        },
        PaymentMethod: {
          title: t('支払い方法'),
          routes: [
            {
              title: t('支払い方法'),
              fullPath: '/settings/payment',
              relativePath: '/settings/payment',
              exact: false,
              element: <SettingsPaymentStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        Reports: {
          title: t('日報・月報'),
          routes: [
            {
              title: t('日報・月報'),
              fullPath: '/reports',
              relativePath: '/reports',
              exact: false,
              element: <Reports />,
              screenOptions: {
                headerShown: true,
              },
            },
          ],
        },
        Sales: {
          title: t('売上分析'),
          routes: [
            {
              title: t('売上分析'),
              fullPath: '/sales',
              relativePath: '/sales',
              exact: false,
              element: <Sales />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        ...(Config.isDevOnly && {
          DevSettings: {
            title: t('開発者設定'),
            routes: [
              {
                title: t('開発者設定'),
                fullPath: '/dev_settings',
                relativePath: '/dev_settings',
                exact: true,
                element: <DevSettings />,
              },
            ],
          },
        }),
      },
    },
  }

  return drawerRoutes
}

const drawerFooterHeight = 64
const drawerFooterIconSize = 32
export function DrawerFooter({
  title,
  to,
  onClick,
}: {
  title: string
  to: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}) {
  return (
    <LinkOpacity
      to={to}
      onClick={onClick}
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: drawerFooterHeight,
        paddingHorizontal: 20,
      }}
    >
      <View
        style={{
          width: drawerFooterIconSize,
          height: drawerFooterIconSize,
          borderRadius: drawerFooterIconSize / 2,
          backgroundColor: Colors.bgBlack,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          size={12}
          color={Colors.disabledBlack}
        />
      </View>
      <Text
        style={{
          marginLeft: 12,
          color: Colors.secondaryBlack,
        }}
      >
        {title}
      </Text>
    </LinkOpacity>
  )
}

export default function TopDrawer() {
  const { width, md } = useResponsive()
  const drawerRoutes = useDrawerRoutes()
  const { owner } = useCurrentOwner()

  const renderDrawerItemIcon = ({
    routeKey,
    focused,
  }: {
    routeKey: DrawerRouteKey
    focused: boolean
  }) => {
    let icon: IconDefinition | null = null
    switch (routeKey) {
      case 'Restaurants':
        icon = faCalendarAlt
        break
      case 'Accounts':
        icon = faAddressCard
        break
      case 'Roles':
        icon = faUserFriends
        break
      case 'Owner':
        icon = faUserTie
        break
      case 'Payments':
        icon = faMoneyBillAlt
        break
      case 'PaymentMethod':
        icon = faCreditCard
        break
      case 'DevSettings':
        icon = faDesktop
        break
      case 'Reports':
        icon = faClipboardList
        break
      case 'Sales':
        icon = faChartLine
        break
    }

    if (icon === null) return null

    return (
      <FontAwesomeIcon
        icon={icon}
        size={16}
        color={focused ? Colors.primary : Colors.black40}
      />
    )
  }

  if (owner === undefined) return null

  return (
    <Drawer
      drawerRoutes={drawerRoutes}
      drawerHeader={
        <DrawerHeader
          title={owner.name}
          iconLeft={
            <Icon fallbackText={owner.name} width={width < md ? 36 : 40} />
          }
          onPressSignOut={() => signOut()}
        />
      }
      renderDrawerItemIcon={renderDrawerItemIcon}
    />
  )
}
