import React, { useEffect, useMemo } from 'react'
import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import { useToken, onError } from 'models/Auth'
import { To, useLocation } from 'react-router'

import { AppProvider } from 'components/Shared/AppProvider'
import { Pages } from 'pages'

import './App.css'
import {
  setToastRef,
  Toast,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import * as Sentry from '@sentry/react'
import { history } from 'modules/history'
import { initAuthConfig } from '@hello-ai/ar_shared/src/modules/auth'
import { Navigate } from 'components/Shared/Navigate'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { Helmet } from 'react-helmet-async'
import { getLocale } from 'modules/locale'
import { useNavigate } from 'modules/router/useNavigate'
import { MUIDatePickerProvider } from '@hello-ai/for_r_app/src/components/Shared/MUIDatePickerProvider'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://3c63cc124ebf41f79b3e13ef9fb3d688@o184288.ingest.sentry.io/5730718',
    debug: false,
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
  })
}

initAuthConfig({
  useToken,
  onError,
})

interface PrivateRouteProps {
  children: React.ReactElement | null
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const token = useToken()
  const location = useLocation()

  return token != null ? (
    children
  ) : (
    <Navigate to={'/sign_in'} state={{ from: location }} />
  )
}

export function Redirect({ to }: { to: To }) {
  const location = useLocation()

  const state = useMemo(() => {
    return {
      redirectedFrom: location.pathname + location.search,
      ...(location.state as any),
    }
  }, [location.pathname, location.search, location.state])

  return <Navigate to={to} state={state} replace />
}

function RedirectLocale() {
  const location = useLocation()
  // <Redirect />コンポネントが内部で呼び出している<Navigate />が言語pathを含んだURLに自動で変換するので
  // locationにredirectするだけで/restaurants/:slugから/ja/restaurants/:slugなどにredirectできる
  return <Redirect to={location} />
}

function LocaleRoutes({
  children,
  location,
}: {
  children?: React.ReactNode
  location?: string | Partial<Location>
}) {
  return (
    <Routes location={location}>
      {supportedLocales.map((locale) => {
        return (
          <Route key={locale} path={`/${locale.toLowerCase()}`}>
            {children}
          </Route>
        )
      })}
      <Route path="*" element={<RedirectLocale />} />
    </Routes>
  )
}

function AppRoutes() {
  const token = useToken()
  const location = useLocation()
  const prevLocation = usePrevious(location)

  const navigate = useNavigate()
  useEffect(() => {
    // 初回のみ実行
    if (prevLocation === undefined && location !== prevLocation) {
      // リロード後にもlocation stateがpersistすると、モーダルのURLの状態でリロードした時にブラウザバックで前後に戻るとおかしくなるので
      // リセットする
      if (history.action !== 'REPLACE') {
        navigate(location.pathname + location.search + location.hash, {
          replace: true,
        })
      }
    }
  }, [
    location,
    location.hash,
    location.pathname,
    location.search,
    navigate,
    prevLocation,
  ])

  return (
    <>
      <Helmet>
        <html lang={getLocale()} />
      </Helmet>
      <LocaleRoutes location={location}>
        <Route path="sign_in/*" element={<Pages.SignIn />} />
        <Route path="sign_up/*" element={<Pages.SignUp />} />
        <Route path="owners/:id/*" element={<Pages.Owners />} />
        <Route
          path="business_users/:id/registration"
          element={<Pages.BusinessUsersRegistration />}
        />
        <Route
          path="business_users/:id/reset_password_requests/:reset_password_token/confirm"
          element={<Pages.BusinessUsersResetPasswordRequestsConfirm />}
        />
        <Route
          path="restaurants/:id/reservations/:type/print"
          element={<Pages.RestaurantReservationsPrint />}
        />
        <Route
          path="restaurants/:id/*"
          element={
            <PrivateRoute>
              <Pages.Restaurants />
            </PrivateRoute>
          }
        />
        <Route
          path="business_user/*"
          element={
            <PrivateRoute>
              <Pages.BusinessUser />
            </PrivateRoute>
          }
        />
        <Route
          path="restaurant_groups/menus/*"
          element={
            <PrivateRoute>
              <Pages.RestaurantMenuGroups />
            </PrivateRoute>
          }
        />
        {token == null && (
          // Allow DevSettings access for API URL adjustments without login or local server.
          <Route path="dev_settings" element={<Pages.DevSettings />} />
        )}
        <Route
          path=""
          element={
            <PrivateRoute>
              <Pages.Top />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Pages.Top />
            </PrivateRoute>
          }
        />
        <Route element={<Pages.NotFound />} />
      </LocaleRoutes>
    </>
  )
}

function Container() {
  return (
    // https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
    // @ts-expect-error
    <HistoryRouter history={history}>
      <Routes>
        <Route path="*" element={<AppRoutes />} />
      </Routes>
    </HistoryRouter>
  )
}

export default function App() {
  return (
    <AppProvider>
      <MUIDatePickerProvider>
        <SafeAreaProvider>
          <Container />
        </SafeAreaProvider>
        <Toast
          ref={(ref) => {
            setToastRef(ref)
          }}
        />
      </MUIDatePickerProvider>
    </AppProvider>
  )
}
