import { View, ScrollView } from 'react-native'
import React, { FC } from 'react'
import { t } from '../../../../modules/i18n'

import { GOURMET_SITE_ACCOUNT_SETTINGS_STEPPERS } from '../../../../constants/ForR/GourmetSiteSetting/StepForm'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { Colors } from '../../../../constants/Colors'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare'
import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons/faCircleChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Button } from '../../../Button'
import { Step, Stepper } from '../../../Stepper'
import { Text } from '../../../Text'
import { GourmetSiteProvider } from '../../../../types/ForR/GourmetSiteSetting'

type Props = {
  site: GourmetSiteProvider
  steps: Step[]
  currentStepNumber: number
  sourceLabel: string
  mailAddress?: string
  handleOnOpenAdminUrl: () => void
  onPressNext: () => void
  onPressGoBack: () => void
}

const HowToRegisterEmailTexts: Record<GourmetSiteProvider, string> = {
  gnavi: t(
    '2.「ネット予約管理画面」＞「ネット予約受付設定」＞「予約通知の設定」に上部メールアドレスを追加してください。'
  ),
  hotpepper: t(
    '2.スマートフォンから「設定」＞「ネット予約設定」＞「共通設定」＞「メール通知設定」にアクセスし、上部メールアドレスを追加してください。'
  ),
  tabelog: t(
    '2.「ネット予約管理画面」＞「ネット予約受付設定」＞「予約通知の設定」に上部メールアドレスを追加してください。'
  ),
  tabelog_note: t(
    '2.「食べログノート」>「設定」>「予約受付設定」>「ネット予約通知の設定」に上部メールアドレスを追加してください。'
  ),
  ikkyu: t(
    '2.「ネット予約管理画面」＞「ネット予約受付設定」＞「予約通知の設定」に上部メールアドレスを追加してください。'
  ),
}

export function RegisterEmailStepForm({
  site,
  steps,
  currentStepNumber,
  sourceLabel,
  mailAddress,
  handleOnOpenAdminUrl,
  onPressNext,
  onPressGoBack,
}: Props) {
  return (
    <Stepper
      steps={steps}
      currentStepNumber={currentStepNumber}
      errors={[]}
      isDisabledNextPress={false}
      onPressNext={onPressNext}
      onPressGoBack={onPressGoBack}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: '#fff' }}
        contentContainerStyle={{
          flex: 1,
          paddingHorizontal: '5%',
          paddingVertical: 40,
        }}
      >
        <View>
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
            }}
          >
            {t('次に、連携専用メールアドレスの予約通知登録を行います')}
          </Text>
          <Text style={{ marginTop: 40 }}>
            {t('1.以下の連携専用メールアドレスをコピーしてください。')}
          </Text>
          <View
            style={{
              alignItems: 'center',
              marginTop: 64,
              backgroundColor: Colors.primaryBg,
              padding: 24,
              alignSelf: 'center',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                columnGap: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} size={24} />
              <Text
                style={{ fontSize: 22, fontWeight: '600', lineHeight: 33 }}
                selectable
              >
                {mailAddress ?? t('※※※※※※※※※※＠※※※※※※※※※※.com')}
              </Text>
            </View>
          </View>
          <View style={{ alignItems: 'center', marginTop: 40 }}>
            <Button
              style={{
                minWidth: 256,
                height: 48,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 8,
              }}
              onPress={handleOnOpenAdminUrl}
            >
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                size={16}
                color={Colors.white}
              />
              <Text
                style={{
                  color: Colors.white,
                  fontSize: 16,
                  fontWeight: '600',
                }}
              >
                {t('{{provider}}の店舗管理画面を開く', {
                  provider: sourceLabel,
                })}
              </Text>
            </Button>
            <Text
              style={{ marginTop: 12, fontSize: 12, color: Colors.black60 }}
            >
              {t('※規定のブラウザが開きます')}
            </Text>
          </View>
          <View style={{ rowGap: 32, marginTop: 48 }}>
            <Text
              style={{
                fontSize: 18,
                fontWeight: '300',
                lineHeight: 27,
              }}
            >
              {HowToRegisterEmailTexts[site]}
            </Text>
            {site === 'hotpepper' && (
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: '300',
                  lineHeight: 27,
                }}
              >
                {t(
                  '3.登録後、メール通知設定から該当メールアドレスの通知メールを「ON」に切り替えてください。'
                )}
              </Text>
            )}
            <Text
              style={{
                fontSize: 18,
                fontWeight: '300',
                lineHeight: 27,
              }}
            >
              <Text>
                {site === 'hotpepper'
                  ? t('4.完了後、この画面に戻り右下の')
                  : t('3.完了後、この画面に戻り右下の')}
              </Text>
              <FontAwesomeIcon
                icon={faCircleChevronRight}
                size={24}
                color={Colors.primary}
              />
              <Text>{t('を押して次へ進んでください。')}</Text>
            </Text>
          </View>
        </View>
      </ScrollView>
    </Stepper>
  )
}
