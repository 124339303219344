import parsePhoneNumber, {
  isPossiblePhoneNumber,
  parse,
  format,
  ParsedNumber,
  CountryCode,
} from 'libphonenumber-js'
import CountryCodes from '../assets/data/CountryCodes.json'

const defaultDialCode = '+81' // JP

export function getDefaultDialCode() {
  return defaultDialCode
}

export function getPhoneNumber(phoneNumber: string, dialCode: string) {
  return parsePhoneNumber(phoneNumber, {
    defaultCallingCode: dialCode.replace(/^\+/, ''),
  })?.number as string | undefined
}

export function isPossibleParsePhoneNumber(phoneNumber: string): boolean {
  return isPossiblePhoneNumber(phoneNumber)
}

export function getParsedPhoneNumber(phoneNumber: string): ParsedNumber {
  return parse(phoneNumber, { extended: true })
}

export function formatPhoneNumberByCountryCode(
  phoneNumber: string,
  dialCode: string
) {
  return format(phoneNumber, getCountryCode(dialCode), 'E.164')
}

function getCountryCode(dialCode: string): CountryCode {
  return CountryCodes.find((countryCode) => countryCode.dial_code === dialCode)
    ?.code as unknown as CountryCode
}
