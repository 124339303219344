import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Header } from '../../Shared/Header'
import { Modal } from '../../Shared/Modal'
import SelectRestaurantCrewMember from '../../Shared/SelectRestaurantCrewMember'
import React, { useState } from 'react'
import { Dimensions, View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

interface SelectCasherModalProps {
  restaurantId: number
  defaultRestaurantCrewMemberId: string | null
  onPressSubmit: (restaurantCrewMemberId: string) => void
  onPressCancel: () => void
}

export default function SelectCasherModal({
  restaurantId,
  defaultRestaurantCrewMemberId,
  onPressSubmit,
  onPressCancel,
}: SelectCasherModalProps) {
  const { width, sm } = useResponsive()
  const [selectedRestaurantCrewMemberId, setSelectedRestaurantCrewMemberId] =
    useState<string | null>(defaultRestaurantCrewMemberId)

  return (
    <Modal
      isVisible
      onBackdropPress={onPressCancel}
      style={{
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        margin: 0,
        backgroundColor: Colors.white,
      }}
      contentContainerStyle={{
        flex: 1,
      }}
    >
      <Header title={t('会計の担当者を選択')} onClose={onPressCancel} />
      <View
        style={{
          marginTop: width >= sm ? 56 : 26,
          rowGap: 40,
          alignItems: 'center',
          paddingHorizontal: 40,
        }}
      >
        <Text
          style={{
            fontWeight: '600',
            fontSize: 18,
            lineHeight: 27,
            textAlign: 'center',
          }}
        >
          {t('会計の担当者を記録します。あなたの名前を選択してください')}
        </Text>
        <SelectRestaurantCrewMember
          restaurantId={restaurantId}
          style={{
            width: width >= sm ? 562 : undefined,
          }}
          restaurantCrewMemberId={selectedRestaurantCrewMemberId}
          setRestaurantCrewMemberId={setSelectedRestaurantCrewMemberId}
        />
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 16,
          paddingBottom: 32,
          borderTopWidth: 0.5,
          borderTopColor: Colors.border,
        }}
      >
        <Button
          width={280}
          height={48}
          style={{
            alignSelf: 'center',
          }}
          disabled={selectedRestaurantCrewMemberId === null}
          onPress={() => {
            if (selectedRestaurantCrewMemberId === null) return
            onPressSubmit(selectedRestaurantCrewMemberId)
          }}
        >
          {t('登録して予約に追加する')}
        </Button>
      </View>
    </Modal>
  )
}
