import { AuthClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/auth/auth_service.client'

import { createRpcService } from '../modules/rpc'
import useSWR from '../modules/swr'
import { Owner } from './Owner'

export const authService = createRpcService(AuthClient)
export const authClient = authService.client

export function useAuthMe() {
  const { data, error, mutate } = authService.useGetMe({})

  let actor
  if (data?.businessUser !== undefined) {
    actor = {
      oneofKind: 'businessUser' as const,
      businessUser: data?.businessUser,
    } as const
  } else if (data?.owner !== undefined) {
    actor = {
      oneofKind: 'owner' as const,
      owner: data?.owner,
    } as const
  } else {
    actor = {
      oneofKind: undefined,
    } as const
  }

  return {
    actor,
    error,
    mutate,
  }
}

export interface Auth {
  id: Owner['id']
  token: string
}
export const AUTH_KEY = 'ForRWeb:auth' as const
const authData = JSON.parse(localStorage.getItem(AUTH_KEY) ?? 'null')

export function useAuth() {
  const { data: auth, mutate } = useSWR<Auth | null>(AUTH_KEY, null, {
    fallbackData: authData,
  })

  return {
    auth: auth ?? undefined,
    mutate,
  }
}
