import React from 'react'
import { ReservationWalkinShow } from '@hello-ai/for_r_app/src/components/Reservation/Walkins/Show'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router'

export default function ReservationWalkinShowPage() {
  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()
  const restaurantId = useRestaurantId()
  return (
    <ReservationWalkinShow
      restaurantId={restaurantId}
      restaurantReservationId={restaurantReservationId!}
    />
  )
}
