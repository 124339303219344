// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat_connection/table_seat_connection_service.proto" (package "auto_reserve.restaurants.table_seat_connection", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TableSeatConnectionResource } from "./table_seat_connection_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource table_seat_connections = 1;
   */
  tableSeatConnections: TableSeatConnectionResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int64 restaurant_id = 2;
   */
  restaurantId: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: int32 min_party_size = 2;
   */
  minPartySize: number;
  /**
   * @generated from protobuf field: int32 max_party_size = 3;
   */
  maxPartySize: number;
  /**
   * @generated from protobuf field: repeated string table_seat_ids = 4;
   */
  tableSeatIds: string[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int64 restaurant_id = 2;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: int32 min_party_size = 3;
   */
  minPartySize: number;
  /**
   * @generated from protobuf field: int32 max_party_size = 4;
   */
  maxPartySize: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat_connection.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_connection.ListRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { restaurantId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_connection.ListResponse", [
      {
        no: 1,
        name: "table_seat_connections",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TableSeatConnectionResource,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { tableSeatConnections: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource table_seat_connections */ 1:
          message.tableSeatConnections.push(
            TableSeatConnectionResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.table_seat_connection.TableSeatConnectionResource table_seat_connections = 1; */
    for (let i = 0; i < message.tableSeatConnections.length; i++)
      TableSeatConnectionResource.internalBinaryWrite(
        message.tableSeatConnections[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_connection.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "", restaurantId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int64 restaurant_id */ 2:
          message.restaurantId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int64 restaurant_id = 2; */
    if (message.restaurantId !== 0)
      writer.tag(2, WireType.Varint).int64(message.restaurantId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_connection.CreateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "min_party_size",
        kind: "scalar",
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: "max_party_size",
        kind: "scalar",
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: "table_seat_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      minPartySize: 0,
      maxPartySize: 0,
      tableSeatIds: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* int32 min_party_size */ 2:
          message.minPartySize = reader.int32();
          break;
        case /* int32 max_party_size */ 3:
          message.maxPartySize = reader.int32();
          break;
        case /* repeated string table_seat_ids */ 4:
          message.tableSeatIds.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* int32 min_party_size = 2; */
    if (message.minPartySize !== 0)
      writer.tag(2, WireType.Varint).int32(message.minPartySize);
    /* int32 max_party_size = 3; */
    if (message.maxPartySize !== 0)
      writer.tag(3, WireType.Varint).int32(message.maxPartySize);
    /* repeated string table_seat_ids = 4; */
    for (let i = 0; i < message.tableSeatIds.length; i++)
      writer.tag(4, WireType.LengthDelimited).string(message.tableSeatIds[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_connection.UpdateRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: "min_party_size",
        kind: "scalar",
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: "max_party_size",
        kind: "scalar",
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      restaurantId: 0,
      minPartySize: 0,
      maxPartySize: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int64 restaurant_id */ 2:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* int32 min_party_size */ 3:
          message.minPartySize = reader.int32();
          break;
        case /* int32 max_party_size */ 4:
          message.maxPartySize = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int64 restaurant_id = 2; */
    if (message.restaurantId !== 0)
      writer.tag(2, WireType.Varint).int64(message.restaurantId);
    /* int32 min_party_size = 3; */
    if (message.minPartySize !== 0)
      writer.tag(3, WireType.Varint).int32(message.minPartySize);
    /* int32 max_party_size = 4; */
    if (message.maxPartySize !== 0)
      writer.tag(4, WireType.Varint).int32(message.maxPartySize);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat_connection.DestroyRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat_connection.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.table_seat_connection.TableSeatConnection
 */
export const TableSeatConnection = new ServiceType(
  "auto_reserve.restaurants.table_seat_connection.TableSeatConnection",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: TableSeatConnectionResource },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: TableSeatConnectionResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: TableSeatConnectionResource,
    },
    { name: "Destroy", options: {}, I: DestroyRequest, O: Empty },
  ],
);
