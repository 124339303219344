import { Alert } from '../components/Alert'

import { t } from './i18n'

export function confirmAsync(title: string, message?: string) {
  return new Promise<boolean>((resolve) => {
    Alert.alert(title, message, [
      {
        text: t('いいえ'),
        onPress: () => {
          resolve(false)
        },
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => {
          resolve(true)
        },
      },
    ])
  })
}
