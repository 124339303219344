import { mapValues } from 'lodash'
import { withErrorBoundary } from 'components/Shared/ErrorBoundary'

import SignIn from 'navigation/SignIn/Stack'
import SignUp from 'navigation/SignUp/Stack'
import Owners from 'navigation/Owners/Stack'
import Top from 'navigation/Top/Drawer'
import Restaurants from 'navigation/Restaurants/Drawer'
import RestaurantReservationsPrint from 'pages/Restaurants/RestaurantReservations/Print'
import BusinessUsers from 'pages/BusinessUsers'
import BusinessUsersRegistration from 'pages/BusinessUsers/Registration'
import BusinessUsersResetPasswordRequestsConfirm from './BusinessUsers/ResetPasswordRequests/Confirm'
import BusinessUserRoles from 'pages/BusinessUserRoles'
import RestaurantMenuGroups from 'pages/Restaurants/MenuGroups'

import BusinessUser from 'navigation/BusinessUser/Stack'
import ServerError from 'pages/Error/ServerError'
import NotFound from 'pages/Error/NotFound'
import DevSettings from './Top/DevSettings'

export const Pages = mapValues(
  {
    DevSettings,
    SignIn,
    SignUp,
    Owners,
    Top,
    Restaurants,
    RestaurantReservationsPrint,
    BusinessUsers,
    BusinessUsersRegistration,
    BusinessUsersResetPasswordRequestsConfirm,
    BusinessUserRoles,
    BusinessUser,
    RestaurantMenuGroups,
    ServerError,
    NotFound,
  },
  (Page) => withErrorBoundary(Page)
)
