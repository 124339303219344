// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/table_seat/table_seat_service.proto" (package "auto_reserve.restaurants.table_seat", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Int32Value } from "../../../google/protobuf/wrappers";
import { TableSeatResource_SmokingStatusValue } from "./table_seat_resource";
import { TableSeatResource_CategoryValue } from "./table_seat_resource";
import { TableSeatResource } from "./table_seat_resource";
import { TableSeatResource_Status } from "./table_seat_resource";
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.Status status = 2;
   */
  status: TableSeatResource_Status;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.table_seat.TableSeatResource table_seats = 1;
   */
  tableSeats: TableSeatResource[];
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category = 4;
   */
  category?: TableSeatResource_CategoryValue;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status = 5;
   */
  smokingStatus?: TableSeatResource_SmokingStatusValue;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value min_party_size = 6;
   */
  minPartySize?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value max_party_size = 7;
   */
  maxPartySize?: Int32Value;
  /**
   * @generated from protobuf field: int32 position = 8;
   */
  position: number;
  /**
   * @generated from protobuf field: bool is_seat_connection_require = 9;
   */
  isSeatConnectionRequire: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category = 4;
   */
  category?: TableSeatResource_CategoryValue;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status = 5;
   */
  smokingStatus?: TableSeatResource_SmokingStatusValue;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value min_party_size = 6;
   */
  minPartySize?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.Int32Value max_party_size = 7;
   */
  maxPartySize?: Int32Value;
  /**
   * @generated from protobuf field: int32 position = 8;
   */
  position: number;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.ArchiveRequest
 */
export interface ArchiveRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.PublishRequest
 */
export interface PublishRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.DeleteRequest
 */
export interface DeleteRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string id = 2;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.table_seat.BulkDeleteRequest
 */
export interface BulkDeleteRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: repeated string ids = 2;
   */
  ids: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.ListRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.restaurants.table_seat.TableSeatResource.Status",
          TableSeatResource_Status,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { restaurantId: 0, status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.Status status */ 2:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* auto_reserve.restaurants.table_seat.TableSeatResource.Status status = 2; */
    if (message.status !== 0)
      writer.tag(2, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.ListResponse", [
      {
        no: 1,
        name: "table_seats",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TableSeatResource,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { tableSeats: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.restaurants.table_seat.TableSeatResource table_seats */ 1:
          message.tableSeats.push(
            TableSeatResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.restaurants.table_seat.TableSeatResource table_seats = 1; */
    for (let i = 0; i < message.tableSeats.length; i++)
      TableSeatResource.internalBinaryWrite(
        message.tableSeats[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.GetRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { restaurantId: 0, id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.CreateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "category",
        kind: "message",
        T: () => TableSeatResource_CategoryValue,
      },
      {
        no: 5,
        name: "smoking_status",
        kind: "message",
        T: () => TableSeatResource_SmokingStatusValue,
      },
      { no: 6, name: "min_party_size", kind: "message", T: () => Int32Value },
      { no: 7, name: "max_party_size", kind: "message", T: () => Int32Value },
      { no: 8, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      {
        no: 9,
        name: "is_seat_connection_require",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      name: "",
      position: 0,
      isSeatConnectionRequire: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category */ 4:
          message.category = TableSeatResource_CategoryValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.category,
          );
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status */ 5:
          message.smokingStatus =
            TableSeatResource_SmokingStatusValue.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.smokingStatus,
            );
          break;
        case /* google.protobuf.Int32Value min_party_size */ 6:
          message.minPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.minPartySize,
          );
          break;
        case /* google.protobuf.Int32Value max_party_size */ 7:
          message.maxPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.maxPartySize,
          );
          break;
        case /* int32 position */ 8:
          message.position = reader.int32();
          break;
        case /* bool is_seat_connection_require */ 9:
          message.isSeatConnectionRequire = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category = 4; */
    if (message.category)
      TableSeatResource_CategoryValue.internalBinaryWrite(
        message.category,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status = 5; */
    if (message.smokingStatus)
      TableSeatResource_SmokingStatusValue.internalBinaryWrite(
        message.smokingStatus,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value min_party_size = 6; */
    if (message.minPartySize)
      Int32Value.internalBinaryWrite(
        message.minPartySize,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value max_party_size = 7; */
    if (message.maxPartySize)
      Int32Value.internalBinaryWrite(
        message.maxPartySize,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int32 position = 8; */
    if (message.position !== 0)
      writer.tag(8, WireType.Varint).int32(message.position);
    /* bool is_seat_connection_require = 9; */
    if (message.isSeatConnectionRequire !== false)
      writer.tag(9, WireType.Varint).bool(message.isSeatConnectionRequire);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.UpdateRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "category",
        kind: "message",
        T: () => TableSeatResource_CategoryValue,
      },
      {
        no: 5,
        name: "smoking_status",
        kind: "message",
        T: () => TableSeatResource_SmokingStatusValue,
      },
      { no: 6, name: "min_party_size", kind: "message", T: () => Int32Value },
      { no: 7, name: "max_party_size", kind: "message", T: () => Int32Value },
      { no: 8, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = { restaurantId: 0, id: "", name: "", position: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category */ 4:
          message.category = TableSeatResource_CategoryValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.category,
          );
          break;
        case /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status */ 5:
          message.smokingStatus =
            TableSeatResource_SmokingStatusValue.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.smokingStatus,
            );
          break;
        case /* google.protobuf.Int32Value min_party_size */ 6:
          message.minPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.minPartySize,
          );
          break;
        case /* google.protobuf.Int32Value max_party_size */ 7:
          message.maxPartySize = Int32Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.maxPartySize,
          );
          break;
        case /* int32 position */ 8:
          message.position = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.restaurants.table_seat.TableSeatResource.CategoryValue category = 4; */
    if (message.category)
      TableSeatResource_CategoryValue.internalBinaryWrite(
        message.category,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.restaurants.table_seat.TableSeatResource.SmokingStatusValue smoking_status = 5; */
    if (message.smokingStatus)
      TableSeatResource_SmokingStatusValue.internalBinaryWrite(
        message.smokingStatus,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value min_party_size = 6; */
    if (message.minPartySize)
      Int32Value.internalBinaryWrite(
        message.minPartySize,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Int32Value max_party_size = 7; */
    if (message.maxPartySize)
      Int32Value.internalBinaryWrite(
        message.maxPartySize,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int32 position = 8; */
    if (message.position !== 0)
      writer.tag(8, WireType.Varint).int32(message.position);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ArchiveRequest$Type extends MessageType<ArchiveRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.ArchiveRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ArchiveRequest>): ArchiveRequest {
    const message = { restaurantId: 0, id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ArchiveRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ArchiveRequest,
  ): ArchiveRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ArchiveRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.ArchiveRequest
 */
export const ArchiveRequest = new ArchiveRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishRequest$Type extends MessageType<PublishRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.PublishRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<PublishRequest>): PublishRequest {
    const message = { restaurantId: 0, id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<PublishRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: PublishRequest,
  ): PublishRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: PublishRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.PublishRequest
 */
export const PublishRequest = new PublishRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRequest$Type extends MessageType<DeleteRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.DeleteRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DeleteRequest>): DeleteRequest {
    const message = { restaurantId: 0, id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DeleteRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DeleteRequest,
  ): DeleteRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DeleteRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string id = 2; */
    if (message.id !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.DeleteRequest
 */
export const DeleteRequest = new DeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkDeleteRequest$Type extends MessageType<BulkDeleteRequest> {
  constructor() {
    super("auto_reserve.restaurants.table_seat.BulkDeleteRequest", [
      {
        no: 1,
        name: "restaurant_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<BulkDeleteRequest>): BulkDeleteRequest {
    const message = { restaurantId: 0, ids: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BulkDeleteRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BulkDeleteRequest,
  ): BulkDeleteRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* repeated string ids */ 2:
          message.ids.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BulkDeleteRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* repeated string ids = 2; */
    for (let i = 0; i < message.ids.length; i++)
      writer.tag(2, WireType.LengthDelimited).string(message.ids[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.table_seat.BulkDeleteRequest
 */
export const BulkDeleteRequest = new BulkDeleteRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.restaurants.table_seat.TableSeat
 */
export const TableSeat = new ServiceType(
  "auto_reserve.restaurants.table_seat.TableSeat",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: TableSeatResource },
    { name: "Create", options: {}, I: CreateRequest, O: TableSeatResource },
    { name: "Update", options: {}, I: UpdateRequest, O: TableSeatResource },
    { name: "Archive", options: {}, I: ArchiveRequest, O: TableSeatResource },
    { name: "Publish", options: {}, I: PublishRequest, O: TableSeatResource },
    { name: "Delete", options: {}, I: DeleteRequest, O: TableSeatResource },
    { name: "BulkDelete", options: {}, I: BulkDeleteRequest, O: ListResponse },
  ],
);
