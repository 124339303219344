import React from 'react'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'

export default function OutboundIndexTitle() {
  return (
    <Text
      style={{
        fontSize: 22,
        fontWeight: '600',
        lineHeight: 33,
        color: Colors.black,
        marginBottom: 16,
      }}
    >
      連携設定
    </Text>
  )
}
