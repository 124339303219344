import { onError } from 'modules/swr'
import axios, { wrapResponse, setHeader } from 'modules/axios'
import { Restaurant } from './Restaurant'

export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantInformationChangeRequest'

export async function createInformationChangeRequest(
  token: string,
  restaurantId: number,
  params: {
    changed_name?: string
    changed_name_ja?: string
    changed_name_en?: string
    changed_name_ko?: string
    changed_name_zh_cn?: string
    changed_name_zh_tw?: string
    changed_name_zh_hk?: string
    changed_phone_number?: string
    changed_address?: string
    changed_open_at?: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<Restaurant>(
      `/restaurants/${restaurantId}/information_change_requests`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    restaurant: response?.data,
    error,
  }
}
