import React, { useContext, useMemo } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { Text } from '../Text'
import { Colors } from '../../constants/Colors'
import { TouchableOpacity } from '../Touchables'

type RadioMode = 'inline' | 'vertical'

type RadioContextValue<T> = {
  value: T
  onChange: (value: any) => void
}

const RadioContext = React.createContext<RadioContextValue<any> | undefined>(
  undefined
)

function useRadioProps<T>({
  value,
  selected,
  onChange,
}:
  | {
      value?: undefined
      selected?: boolean
      onChange: (value: boolean) => void
    }
  | {
      value: T
      selected?: undefined
      onChange?: undefined
    }) {
  const context = useContext(RadioContext) as RadioContextValue<T> | undefined

  if (context === undefined && onChange === undefined) {
    throw new Error(
      'You must wrap Radio with RadioGroup or provide onChange prop to Radio'
    )
  }

  const onPress = () => {
    if (onChange !== undefined) {
      onChange(true)
      return
    }

    if (context !== undefined) {
      context.onChange(value)
    }
  }

  function getSelected() {
    if (onChange !== undefined) {
      return selected
    }

    if (context !== undefined) {
      return context.value === value
    }
  }

  return {
    onPress,
    selected: getSelected(),
  }
}

export function RadioIcon({ selected }: { selected?: boolean }) {
  return (
    <View
      style={[
        {
          height: 24,
          width: 24,
          justifyContent: 'center',
          alignItems: 'center',
        },
      ]}
    >
      <View
        style={{
          width: 20,
          height: 20,
          borderWidth: 2,
          borderRadius: 20 / 2,
          borderColor: selected ? Colors.accent : Colors.black60,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {selected && (
          <View
            style={{
              width: 10,
              height: 10,
              borderRadius: 10 / 2,
              backgroundColor: Colors.accent,
            }}
          />
        )}
      </View>
    </View>
  )
}

export function RadioLabel({
  value,
  style,
}: {
  value: string
  style?: StyleProp<TextStyle>
}) {
  return <Text style={style}>{value}</Text>
}

// NOTE: RadioButtonはContext無しでも使える
export function RadioButton({
  disabled,
  selected,
  radioLabel,
  radioLabelContainerStyle,
  style,
  onPress,
}: {
  disabled?: boolean
  radioLabel?: React.ReactNode
  radioLabelContainerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  selected?: boolean
  onPress?: () => void
}) {
  return (
    <TouchableOpacity
      onPressMinInterval={0}
      disabled={disabled}
      onPress={onPress}
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
        disabled && {
          opacity: 0.4,
        },
        style,
      ]}
    >
      <RadioIcon selected={selected} />
      {Boolean(radioLabel) && (
        <View
          style={[
            {
              marginLeft: 4,
            },
            radioLabelContainerStyle,
          ]}
        >
          {radioLabel}
        </View>
      )}
    </TouchableOpacity>
  )
}

export function Radio<T>({
  value,
  selected,
  onChange,
  disabled,
  radioLabel,
  radioLabelContainerStyle,
  style,
}: {
  value: T
  disabled?: boolean
  radioLabel?: React.ReactNode
  radioLabelContainerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
} & (
  | {
      value?: undefined
      selected?: boolean
      onChange: (value: boolean) => void
    }
  | {
      value: T
      selected?: undefined
      onChange?: undefined
    }
)) {
  const { selected: radioSelected, onPress } = useRadioProps<T>(
    onChange !== undefined
      ? {
          selected,
          onChange,
        }
      : { value }
  )

  return (
    <RadioButton
      style={style}
      selected={radioSelected}
      onPress={onPress}
      disabled={disabled}
      radioLabel={radioLabel}
      radioLabelContainerStyle={radioLabelContainerStyle}
    />
  )
}

export function RadioGroup<T>({
  mode = 'inline',
  value,
  onChange,
  children,
  radioContainerStyle,
  style,
}: {
  mode?: RadioMode
  value: T
  onChange: (value: T) => void
  children: React.ReactNode
  radioContainerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
}) {
  const radioContextValue = useMemo(() => {
    return {
      value,
      onChange,
    }
  }, [onChange, value])

  return (
    <RadioContext.Provider value={radioContextValue}>
      <View
        style={[
          mode === 'inline' && {
            flexDirection: 'row',
          },
          style,
        ]}
      >
        {React.Children.map(children, (child, index) => {
          return (
            <View
              style={[
                mode === 'inline' && {
                  marginLeft: index === 0 ? 0 : 16,
                },
                mode === 'vertical' && {
                  marginTop: index === 0 ? 0 : 16,
                },
                radioContainerStyle,
              ]}
            >
              {child}
            </View>
          )
        })}
      </View>
    </RadioContext.Provider>
  )
}
