import React, { useEffect, useState } from 'react'
import { FlatList, ScrollView, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { restaurantCourseQuestionService } from 'models/RestaurantCourseQuestion'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useNavigation } from 'components/Shared/Navigation/Screen'
import { useNavigate } from 'react-router'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function RestaurantCourseQuestionsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)

  const { data } = restaurantCourseQuestionService.useList({
    restaurantId,
    page,
    perPage: 20,
  })

  useEffect(() => {
    navigation.setOptions({
      title: t('お客様への質問'),
    })
  }, [navigation])

  if (data === undefined) {
    return <Loading />
  }

  const { restaurantCourseQuestions, totalPageCount } = data

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        padding: width < sm ? 16 : 32,
        backgroundColor: Colors.bgBlack,
      }}
    >
      <Button
        mode="contained"
        variant="secondary"
        style={{
          borderRadius: 24,
          height: 48,
          borderColor: Colors.border,
          borderWidth: 1,
        }}
        textStyle={{
          fontSize: 14,
          fontWeight: '600',
        }}
        onPress={() =>
          navigate(`/restaurants/${restaurantId}/course_questions/new`)
        }
      >
        {t('お客様への質問を追加')}
      </Button>
      <ShadowBox style={{ marginTop: 40, padding: 0 }}>
        <FlatList
          data={restaurantCourseQuestions}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() =>
                navigate(
                  `/restaurants/${restaurantId}/course_questions/${item.id}`
                )
              }
              style={[
                { paddingHorizontal: 32, paddingVertical: 36 },
                index > 0 && {
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                },
              ]}
            >
              <Text
                style={{
                  fontWeight: '600',
                }}
              >
                {item.name}
              </Text>
            </TouchableOpacity>
          )}
        />
        <View
          style={{
            borderTopWidth: 0.5,
            borderTopColor: Colors.border,
            paddingVertical: 32,
          }}
        >
          <Pagination
            currentPage={page}
            totalPage={totalPageCount}
            setPage={(page) => setPage(page)}
          />
        </View>
      </ShadowBox>
    </ScrollView>
  )
}
