import React, { useState } from 'react'
import { AspectImage } from '@hello-ai/ar_shared/src/components/AspectImage'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  Restaurant as RestaurantModel,
  useOwnerRestaurants,
} from 'models/Restaurant'
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native'
import LinkOpacity from 'components/Shared/LinkOpacity'

import noImage from 'assets/images/no_image_restaurant.png'

import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import EmptyRestaurant from 'assets/images/empty_restaurant.png'
import FloatingButton from 'components/Shared/FloatingButton'
import AddRestaurant from 'components/Restaurant/AddRestaurant'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function Box({
  style,
  children,
}: {
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          elevation: 4,
          shadowColor: '#000',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 1 },
          shadowRadius: 4,
          borderRadius: 8,
          backgroundColor: 'white',
          overflow: 'hidden',
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

const restaurantImageSize = 80
function Restaurant({ restaurant }: { restaurant: RestaurantModel }) {
  return (
    <LinkOpacity
      to={restaurant.is_owned ? `/restaurants/${restaurant.id}` : null}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        backgroundColor: 'white',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <AspectImage
          style={{
            width: restaurantImageSize,
            height: restaurantImageSize,
            aspectRatio: 1,
          }}
          source={{
            uri: restaurant.logo?.md ?? noImage,
          }}
          resizeMode="cover"
        />

        <Text
          style={{
            marginLeft: 16,
            lineHeight: 24,
            fontSize: 18,
            fontWeight: '600',
          }}
        >
          {restaurant.name}
        </Text>
      </View>
      {restaurant.is_owned ? (
        <FontAwesomeIcon
          style={{ marginLeft: 16 }}
          icon={faAngleRight}
          size={20}
        />
      ) : (
        <Text style={{ color: Colors.black60, fontSize: 16 }}>
          {t('申請中')}
        </Text>
      )}
    </LinkOpacity>
  )
}

const roundCountHeight = 36
const roundCountMinWidth = 36
function RoundCount({ count }: { count: number }) {
  return (
    <View
      style={{
        borderRadius: 8,
        height: roundCountHeight,
        minWidth: roundCountMinWidth,
        paddingHorizontal: 8,
        backgroundColor: Colors.bgBlack,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        style={{
          fontSize: 18,
          color: Colors.secondaryBlack,
          fontWeight: '600',
        }}
      >
        {count}
      </Text>
    </View>
  )
}

function Header({ title, count }: { title: string; count: number }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
      <RoundCount count={count} />
    </View>
  )
}

export default function Restaurants() {
  const { width, sm } = useResponsive()
  const { data } = useOwnerRestaurants()
  const [isVisibleAddRestaurant, setIsVisibleAddRestaurant] = useState(false)

  const onPressAddRestaurant = () => {
    setIsVisibleAddRestaurant(true)
  }

  if (data == null)
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.bgBlack,
        }}
      >
        <Loading />
      </View>
    )

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[
          {
            flexGrow: 1,
            backgroundColor: Colors.bgBlack,
          },

          width < sm
            ? {
                paddingHorizontal: 16,
                paddingVertical: 20,
              }
            : {
                paddingHorizontal: 48,
                paddingVertical: 40,
              },
        ]}
      >
        <Header title={t('店舗一覧')} count={data.restaurants.length} />
        <Box style={{ marginTop: 24 }}>
          {data.restaurants.length === 0 && (
            <View
              style={{
                alignItems: 'center',
                paddingHorizontal: 20,
                paddingVertical: 40,
              }}
            >
              <View style={{ alignItems: 'center', marginBottom: 40 }}>
                <Image
                  source={{ uri: EmptyRestaurant }}
                  style={{ width: 240, height: 180 }}
                />
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: '600',
                    textAlign: 'center',
                    color: Colors.black60,
                    marginVertical: 16,
                  }}
                >
                  {t('店舗がありません')}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '300',
                    textAlign: 'center',
                    marginBottom: 40,
                    color: Colors.black60,
                  }}
                >
                  {t('運営している店舗を追加して、便利なサービスを利用しよう')}
                </Text>
                <Button
                  style={{
                    paddingVertical: 12,
                    paddingHorizontal: 24,
                    width: 400,
                    height: 48,
                  }}
                  onPress={onPressAddRestaurant}
                >
                  {t('店舗アカウントを追加する')}
                </Button>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  flex: 1,
                  marginBottom: 40,
                }}
              >
                <View
                  style={{ height: 1, backgroundColor: Colors.border, flex: 1 }}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    paddingHorizontal: 8,
                  }}
                  numberOfLines={1}
                >
                  {t('追加することでできること')}
                </Text>
                <View
                  style={{ height: 1, backgroundColor: Colors.border, flex: 1 }}
                />
              </View>
              <View style={{ flexDirection: 'row', columnGap: 32 }}>
                {[
                  {
                    title: t('店舗情報'),
                    image: require('assets/images/methods/restaurant_info.svg'),
                    description: t(
                      'AutoReserveの店舗ページに表示される情報の更新'
                    ),
                  },
                  {
                    title: t('予約台帳'),
                    image: require('assets/images/methods/reserve_management.svg'),
                    description: t(
                      'AutoReserveでのネット予約受付を含む予約管理'
                    ),
                  },
                  {
                    title: t('POSレジ・セルフオーダー'),
                    image: require('assets/images/methods/restaurant_info.svg'),
                    description: t(
                      'ハンディーやユーザーから受ける注文管理や売上管理'
                    ),
                  },
                  {
                    title: t('テイクアウト'),
                    image: require('assets/images/methods/restaurant_info.svg'),
                    description: t('ユーザーからオンラインで注文・決済を受付'),
                  },
                ].map(({ title, image, description }, key) => (
                  <View key={key} style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: '600',
                        textAlign: 'center',
                      }}
                    >
                      {title}
                    </Text>
                    <Image
                      source={{ uri: image }}
                      style={{
                        width: 180,
                        height: 150,
                        marginVertical: 16,
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: '300',
                        color: Colors.black,
                      }}
                    >
                      {description}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          )}
          {data.restaurants.map((restaurant, index) => (
            <View
              key={restaurant.id.toString()}
              style={[
                index > 0 && {
                  borderTopColor: Colors.border,
                  borderTopWidth: 0.5,
                },
              ]}
            >
              <Restaurant restaurant={restaurant} />
            </View>
          ))}
        </Box>
      </ScrollView>
      <FloatingButton
        style={{ position: 'fixed', right: 32, bottom: 32 }}
        onPress={onPressAddRestaurant}
      />
      <AddRestaurant
        isVisible={isVisibleAddRestaurant}
        onClose={() => setIsVisibleAddRestaurant(false)}
      />
    </>
  )
}
