import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import React, { ComponentProps } from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface FloatingButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  /**
   * @default '店舗追加'
   */
  label?: string
}

export default function FloatingButton({
  style,
  label = t('店舗追加'),
  ...rest
}: FloatingButtonProps) {
  return (
    <Button
      roundness={60}
      style={[
        {
          width: 96,
          height: 96,
          paddingHorizontal: 0,
        },
        style,
      ]}
      {...rest}
    >
      <Image
        source={{ uri: require('assets/images/plus.svg') }}
        style={{ width: 32, height: 32, marginBottom: 12 }}
      />
      <Text
        style={[
          {
            fontSize: 12,
            fontWeight: '600',
            color: Colors.white,
            lineHeight: 14,
            textAlign: 'center',
          },
          rest.textStyle,
        ]}
      >
        {label}
      </Text>
    </Button>
  )
}
