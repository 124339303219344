import React from 'react'
import { useSyncFailedReservations } from 'models/GourmetSiteSyncReservations'
import { View } from 'react-native'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function GourmetSiteSettingIcon() {
  const { width, md } = useResponsive()
  const restaurantId = useRestaurantId()
  const { pagination } = useSyncFailedReservations(restaurantId)

  const showAlert = (pagination?.totalCount ?? 0) > 0

  return (
    <View
      style={[
        { position: 'relative' },
        showAlert && {
          paddingRight: 0,
        },
      ]}
    >
      <FontAwesomeIcon
        icon={faLink}
        color={Colors.primary}
        size={width < md ? 16 : 20}
      />
      {showAlert && (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={showAlert ? Colors.caution : Colors.primary}
          size={20}
          style={{
            position: 'absolute',
            top: -10,
            right: -16,
          }}
        />
      )}
    </View>
  )
}
