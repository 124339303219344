import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { TakeoutMenuPage } from 'models/TakeoutMenuPage'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

function TakeoutMenuPageParentCheckBox({
  takeoutMenuPage,
  selectedTakeoutMenuPageIds,
  onToggle,
}: {
  takeoutMenuPage: TakeoutMenuPage
  selectedTakeoutMenuPageIds: string[]
  onToggle: (id: string) => void
}) {
  const { width, sm } = useResponsive()
  const childrenTakeoutMenuPageIds =
    takeoutMenuPage.takeout_menu_pages != null
      ? takeoutMenuPage?.takeout_menu_pages.map((childPage) => childPage.id)
      : []

  const isParentChecked = selectedTakeoutMenuPageIds.includes(
    takeoutMenuPage.id
  )
  const hasCheckedChildren = childrenTakeoutMenuPageIds.some(
    (childTakeoutMenuPageId) =>
      selectedTakeoutMenuPageIds.includes(childTakeoutMenuPageId)
  )

  // 親か子供どちらかがチェックされていたら親はcheckedで表示する
  const isParentCheckedForView = isParentChecked || hasCheckedChildren
  const prevIsParentCheckedForView = usePrevious(isParentCheckedForView)

  return (
    <View
      style={{
        marginTop: 20,
      }}
    >
      <Checkbox
        checked={isParentCheckedForView}
        onChange={(value) => {
          if (prevIsParentCheckedForView !== value) {
            onToggle(takeoutMenuPage.id)
          }
        }}
        checkboxLabel={<CheckboxLabel value={takeoutMenuPage.name} />}
      />
      <View
        style={{
          marginTop: 10,
          marginLeft: 16,
        }}
      >
        <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
          {takeoutMenuPage?.takeout_menu_pages?.map((childTakeoutMenuPage) => {
            const checked = selectedTakeoutMenuPageIds.includes(
              childTakeoutMenuPage.id
            )
            return (
              <View key={childTakeoutMenuPage.id} style={{}}>
                <TakeoutMenuPageCheckbox
                  takeoutMenuPage={childTakeoutMenuPage}
                  checked={checked}
                  disabled={!isParentCheckedForView}
                  onToggle={() => onToggle(childTakeoutMenuPage.id)}
                />
              </View>
            )
          })}
        </Wrap>
      </View>
    </View>
  )
}

function TakeoutMenuPageCheckbox({
  takeoutMenuPage,
  checked,
  onToggle,
  disabled = false,
}: {
  takeoutMenuPage?: TakeoutMenuPage
  checked: boolean
  onToggle: () => void
  disabled?: boolean
}) {
  const prevChecked = usePrevious(checked)

  return (
    <Checkbox
      checked={checked}
      onChange={(value) => {
        if (prevChecked !== value) {
          onToggle()
        }
      }}
      disabled={disabled}
      checkboxLabel={<CheckboxLabel value={takeoutMenuPage?.name ?? ''} />}
    />
  )
}

export function SelectMenuPages({
  menuPages,
  menuPageIds,
  setMenuPages,
  style,
}: {
  menuPages: TakeoutMenuPage[]
  menuPageIds: Array<TakeoutMenuPage['id']>
  setMenuPages: (menuPages: Array<TakeoutMenuPage['id']>) => void
  style?: StyleProp<ViewStyle>
}) {
  const { width, sm } = useResponsive()

  const checkOrUncheckMenuPage = (takeoutMenuPageId: string) => {
    const parent = menuPages.find(
      (item) => item.takeout_menu_pages != null && item.id === takeoutMenuPageId
    )

    const parentFromChild = menuPages.find((item) => {
      return item?.takeout_menu_pages
        ?.map((menuPage) => menuPage.id)
        .includes(takeoutMenuPageId)
    })

    const childrenIds = parent
      ? parent.takeout_menu_pages.map((menuPage) => menuPage.id)
      : []

    if (menuPageIds.includes(takeoutMenuPageId)) {
      let removeIds = [takeoutMenuPageId]
      // 親カテゴリがはずされる場合は、小カテゴリもすべて外す
      if (parent?.takeout_menu_pages != null) {
        removeIds = removeIds.concat(childrenIds)
      }

      // 親が選択されていない かつ 子カテゴリのチェックを外す場合、親にチェックを入れる
      const addParentIds = []
      if (parentFromChild && !menuPageIds.includes(parentFromChild.id)) {
        addParentIds.push(parentFromChild.id)
      }

      const removedMenuPages = menuPageIds.filter(
        (menuPage) => !removeIds.includes(menuPage)
      )

      setMenuPages(removedMenuPages.concat(addParentIds))
    } else {
      const hasCheckedChildren = childrenIds.some((childId) =>
        menuPageIds.includes(childId)
      )

      // 実際には選択されていない親カテゴリがcheckedされたとき、子カテゴリも全て外す
      // 親である かつ 実際は選択されていない かつ 子が選択されているものがある
      if (hasCheckedChildren) {
        setMenuPages(
          menuPageIds.filter((menuPage) => !childrenIds.includes(menuPage))
        )
        return
      }

      setMenuPages([...menuPageIds, takeoutMenuPageId])
    }
  }

  return (
    <View style={style}>
      <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
        {menuPages
          .filter(
            (item) =>
              item.takeout_menu_pages == null &&
              !item.parent_takeout_menu_page != null
          )
          .map((item) => {
            const checked = menuPageIds.includes(item.id)
            return (
              <View key={item.id}>
                <TakeoutMenuPageCheckbox
                  takeoutMenuPage={item}
                  checked={checked}
                  onToggle={() => checkOrUncheckMenuPage(item.id)}
                />
              </View>
            )
          })}
      </Wrap>
      <View>
        {menuPages
          .filter((item) => item.takeout_menu_pages != null)
          .map((item) => {
            return (
              <TakeoutMenuPageParentCheckBox
                key={item.id}
                takeoutMenuPage={item}
                selectedTakeoutMenuPageIds={menuPageIds}
                onToggle={checkOrUncheckMenuPage}
              />
            )
          })}
      </View>
    </View>
  )
}
