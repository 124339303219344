// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service.proto" (package "auto_reserve.restaurants.restaurant_course_question", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantCourseQuestion } from "./restaurant_course_question_service";
import type { Empty } from "../../../google/protobuf/empty";
import type { ArchiveRequest } from "./restaurant_course_question_service";
import type { UpdateRequest } from "./restaurant_course_question_service";
import type { CreateRequest } from "./restaurant_course_question_service";
import type { RestaurantCourseQuestionResource } from "../restaurant_course/restaurant_course_question_resource";
import type { GetRequest } from "./restaurant_course_question_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./restaurant_course_question_service";
import type { ListRequest } from "./restaurant_course_question_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_course_question.RestaurantCourseQuestion
 */
export interface IRestaurantCourseQuestionClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.restaurant_course_question.ListRequest) returns (auto_reserve.restaurants.restaurant_course_question.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.restaurant_course_question.GetRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantCourseQuestionResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.restaurant_course_question.CreateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantCourseQuestionResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.restaurant_course_question.UpdateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantCourseQuestionResource>;
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.restaurant_course_question.ArchiveRequest) returns (google.protobuf.Empty);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, Empty>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_course_question.RestaurantCourseQuestion
 */
export class RestaurantCourseQuestionClient
  implements IRestaurantCourseQuestionClient, ServiceInfo
{
  typeName = RestaurantCourseQuestion.typeName;
  methods = RestaurantCourseQuestion.methods;
  options = RestaurantCourseQuestion.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.restaurants.restaurant_course_question.ListRequest) returns (auto_reserve.restaurants.restaurant_course_question.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.restaurants.restaurant_course_question.GetRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantCourseQuestionResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, RestaurantCourseQuestionResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.restaurants.restaurant_course_question.CreateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantCourseQuestionResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, RestaurantCourseQuestionResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.restaurants.restaurant_course_question.UpdateRequest) returns (auto_reserve.restaurants.restaurant_course.RestaurantCourseQuestionResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantCourseQuestionResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, RestaurantCourseQuestionResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Archive(auto_reserve.restaurants.restaurant_course_question.ArchiveRequest) returns (google.protobuf.Empty);
   */
  archive(
    input: ArchiveRequest,
    options?: RpcOptions,
  ): UnaryCall<ArchiveRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ArchiveRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
