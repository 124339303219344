import { TableProductOptionBox as TableProductOptionBoxModel } from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'
import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import React, { useEffect, useRef, useState } from 'react'
import { Animated, Easing, Image, View } from 'react-native'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import {
  TableProductOption as TableProductOptionModel,
  UpdateProductOptionStockFuncType,
  ChangeTableProductOptionBoxStatus,
} from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'
import { productOptionBoxTypeLabels } from '@hello-ai/ar_shared/src/constants/ForR/Restaurant/TableProductOption'
import { StockToggle } from '@hello-ai/for_r_app/src/components/Restaurant/TableProductOption'
import { goBack } from 'modules/history'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function RestoreButton({
  tableProductOptionBoxId,
  onChangeStatus,
}: {
  tableProductOptionBoxId: string
  onChangeStatus: ChangeTableProductOptionBoxStatus
}) {
  const alertPublish = () => {
    const params = {
      status: 'published',
    }
    Alert.alert(t('本当に公開状態に戻しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => {
          onChangeStatus({ tableProductOptionBoxId, params })
          goBack('..')
        },
      },
    ])
  }

  return (
    <Button
      mode="outline"
      variant="danger"
      style={{
        borderColor: Colors.caution,
        backgroundColor: 'transparent',
        height: 48,
        width: 124,
      }}
      onPress={() => alertPublish()}
    >
      {t('元に戻す')}
    </Button>
  )
}

function TableProductOption({
  productOption,
  onChangeInStock,
}: {
  productOption: TableProductOptionModel
  onChangeInStock?: UpdateProductOptionStockFuncType
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        height: width < sm ? 60 : 88,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 16,
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        {productOption.image != null && (
          <Image
            source={{ uri: productOption.image.sm }}
            style={{
              width: 80,
              height: width < sm ? 60 : 88,
              borderRadius: 4,
              marginRight: 16,
            }}
          />
        )}
        <View style={{ justifyContent: 'space-between', marginVertical: 12 }}>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
            }}
          >
            {productOption.name}
          </Text>
          <Text
            style={{
              marginTop: 6,
              fontWeight: '300',
            }}
          >
            {productOption.price_delta >= 0
              ? `+${productOption.price_delta}`
              : productOption.price_delta}
          </Text>
        </View>
      </View>
      {onChangeInStock && (
        <StockToggle
          productOptionId={productOption.id}
          productOptionInStock={productOption.in_stock}
          onChangeInStock={onChangeInStock}
        />
      )}
    </View>
  )
}

function TableProductOptionBox({
  productOptionBox,
  onPressOptionBox,
  onChangeInStock,
  onChangeStatus,
}: {
  productOptionBox: TableProductOptionBoxModel
  onPressOptionBox?: (optionBoxId: string) => void
  onChangeInStock?: UpdateProductOptionStockFuncType
  onChangeStatus?: ChangeTableProductOptionBoxStatus
}) {
  const { width, sm } = useResponsive()
  const [isExpand, setIsExpand] = useState(false)
  const animatedController = useRef(
    new Animated.Value(isExpand ? 1 : 0)
  ).current

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'],
  })
  useEffect(() => {
    if (isExpand) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true,
      }).start()
    }
  }, [animatedController, isExpand])

  const optionBoxType =
    productOptionBox.min_quantity === 1 && productOptionBox.max_quantity === 1
      ? 'radio'
      : 'check'
  // @ts-ignore
  return (
    <>
      <TouchableOpacity
        onPress={() => {
          if (onPressOptionBox) {
            return onPressOptionBox(productOptionBox.id)
          } else {
            return setIsExpand((prev) => !prev)
          }
        }}
        style={[
          {
            height: width < sm ? 60 : 88,
            flexDirection: 'row',
            alignItems: 'center',
          },
          isExpand && {
            borderBottomWidth: 0.5,
            borderBottomColor: Colors.border,
            borderStyle: 'solid',
          },
        ]}
      >
        <Animated.View
          style={{
            transform: [
              {
                rotateZ: arrowAngle,
              },
            ],
          }}
        >
          <TouchableOpacity
            style={{ padding: 10 }}
            onPress={() => {
              setIsExpand((prev) => !prev)
            }}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              size={12}
              style={{ marginLeft: 30, marginRight: 16 }}
            />
          </TouchableOpacity>
        </Animated.View>
        <View>
          <Text style={{ fontWeight: '600' }}>{productOptionBox.name}</Text>
          <Text
            style={{
              fontSize: 16,
              lineHeight: 24,
            }}
          >
            {productOptionBoxTypeLabels[optionBoxType]}
          </Text>
        </View>
        {onChangeStatus && (
          <View
            style={{
              flex: 1,
              marginRight: 16,
              alignItems: 'flex-end',
            }}
          >
            <RestoreButton
              tableProductOptionBoxId={productOptionBox.id}
              onChangeStatus={onChangeStatus}
            />
          </View>
        )}
      </TouchableOpacity>
      <Animated.View
        // @ts-ignore
        style={{
          display: isExpand ? 'flex' : 'none',
        }}
      >
        {isExpand && (
          <View
            style={{
              marginLeft: 48,
            }}
          >
            {productOptionBox.table_product_options.map((productOption, i) => (
              <View
                key={productOption.id}
                style={[
                  {
                    paddingVertical: 8,
                  },
                  productOptionBox.table_product_options.length - 1 !== i && {
                    borderBottomColor: Colors.border,
                    borderBottomWidth: 0.5,
                    borderStyle: 'solid',
                  },
                ]}
              >
                <TableProductOption
                  productOption={productOption}
                  onChangeInStock={onChangeInStock}
                />
              </View>
            ))}
          </View>
        )}
      </Animated.View>
    </>
  )
}

export default TableProductOptionBox
