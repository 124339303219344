import useSWR, {
  swrKey,
  fetcher,
  fetcherWithPaginationHeader,
  WithHeaderData,
} from '../modules/swr'
import {
  AuthenticationForSite,
  GourmetSiteProvider,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import {
  OutboundSettingData,
  isExistOutboundSetting,
} from '@hello-ai/ar_shared/src/types/ForR/OutboundSetting'
import axios, { isAxiosError, setHeader, wrapResponse } from '../modules/axios'
import {
  ExecuteHistory,
  ExecuteHistoryParam,
} from '@hello-ai/ar_shared/src/types/ForR/SiteController'
import { useMemo, useState } from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { isEmpty } from 'lodash-es'

export function useOutboundSettings(
  restaurantId: number,
  siteProvider: GourmetSiteProvider
) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    Partial<OutboundSettingData>,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/site_controller/outbound_settings/${siteProvider}/edit`
    ),
    fetcher
  )

  return {
    outboundSettingsData: data,
    error,
    mutate,
  }
}

export function useSupportedOutboundSettings(restaurantId: number) {
  const { outboundSettingsData: tabelogOutboundSettingData } =
    useOutboundSettings(restaurantId, 'tabelog')
  const { outboundSettingsData: tabelogNoteOutboudSettingData } =
    useOutboundSettings(restaurantId, 'tabelog_note')
  const { outboundSettingsData: ikkyuOutboundSettingData } =
    useOutboundSettings(restaurantId, 'ikkyu')
  const { outboundSettingsData: hotperpperOutboundSettingData } =
    useOutboundSettings(restaurantId, 'hotpepper')
  const { outboundSettingsData: gnaviOutboundSettingData } =
    useOutboundSettings(restaurantId, 'gnavi')

  const tabelogOrTabelogNoteOutboundSettingData = useMemo(() => {
    // NOTE: 食べログノートを優先して表示
    if (!isEmpty(tabelogNoteOutboudSettingData))
      return {
        ...tabelogNoteOutboudSettingData,
        site: 'tabelog_note' as GourmetSiteProvider,
      }
    if (!isEmpty(tabelogOutboundSettingData))
      return {
        ...tabelogOutboundSettingData,
        site: 'tabelog' as GourmetSiteProvider,
      }
    // どちらも設定されていない場合はtableogとして設定できるようにする
    return {
      site: 'tabelog' as GourmetSiteProvider,
    }
  }, [tabelogOutboundSettingData, tabelogNoteOutboudSettingData])

  const supportedOutboundSettings = useMemo(() => {
    return [
      tabelogOrTabelogNoteOutboundSettingData,
      {
        ...ikkyuOutboundSettingData,
        site: 'ikkyu' as GourmetSiteProvider,
      },
      {
        ...hotperpperOutboundSettingData,
        site: 'hotpepper' as GourmetSiteProvider,
      },
      {
        ...gnaviOutboundSettingData,
        site: 'gnavi' as GourmetSiteProvider,
      },
    ]
  }, [
    tabelogOrTabelogNoteOutboundSettingData,
    ikkyuOutboundSettingData,
    hotperpperOutboundSettingData,
    gnaviOutboundSettingData,
  ])

  /**
   * 一つでもアカウント連携が完了しているかどうか
   */
  const isExistOutboundSettings = useMemo(() => {
    if (supportedOutboundSettings == null) return null
    return supportedOutboundSettings.some((setting) => {
      return isExistOutboundSetting(setting)
    })
  }, [supportedOutboundSettings])

  return {
    supportedOutboundSettings,
    isExistOutboundSettings,
  }
}

/**
 * 連携済みかつ、有効なアカウント設定のみを取得
 * @param restaurantId
 * @returns
 */
export function useActiveOutboundSettings(restaurantId: number) {
  const { supportedOutboundSettings } =
    useSupportedOutboundSettings(restaurantId)

  const activeOutboundSettings = useMemo(() => {
    if (supportedOutboundSettings == null) return null
    return supportedOutboundSettings.filter((setting) => setting.enabled)
  }, [supportedOutboundSettings])

  return {
    activeOutboundSettings,
  }
}

export async function postImportMailNoteAgreement({
  restaurantId,
  token,
}: {
  restaurantId: number
  token: string
}) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post(
      `/restaurants/${restaurantId}/import_mail_settings/notes_agreement`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
  }
}

interface TabelogOutboundSettingsParam {
  site: 'tabelog'
  enabled: boolean
  signin_id: string
  password: string
}
interface TabelogNoteOutboundSettingsParam {
  site: 'tabelog_note'
  enabled: boolean
  signin_id: string
  password: string
}
interface IkkyuOutboundSettingsParam {
  site: 'ikkyu'
  enabled: boolean
  site_restaurant_id: string
  signin_id: string
  password: string
}

interface HotpepperOutboundSettingParam {
  site: 'hotpepper'
  enabled: boolean
  signin_id: string
  password: string
}
interface GnaviOutboundSettingParam {
  site: 'gnavi'
  enabled: boolean
  signin_id: string
  password: string
}

export type CreateOutboundSettingsParam =
  | TabelogOutboundSettingsParam
  | TabelogNoteOutboundSettingsParam
  | IkkyuOutboundSettingsParam
  | HotpepperOutboundSettingParam
  | GnaviOutboundSettingParam

export async function createOutboundSettings(
  token: string,
  restaurantId: number,
  param: CreateOutboundSettingsParam
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(
      `/restaurants/${restaurantId}/site_controller/outbound_settings`,
      param
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export async function updateOutboundSettings(
  token: string,
  restaurantId: number,
  id: string,
  param: Partial<CreateOutboundSettingsParam>
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/site_controller/outbound_settings/${id}`,
      param
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export async function authenticateGourmetSite(
  token: string,
  restaurantId: number,
  source: GourmetSiteProvider,
  userId: string
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(
      `/restaurants/${restaurantId}/site_controller/authentications/${source}`,
      {
        business_user_id: userId,
      }
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export function useExecuteHistories(
  restaurantId: number,
  _params: {
    event_type_and_actions: ExecuteHistoryParam[]
  }
) {
  const token = useToken()
  const [page, setPage] = useState(1)
  const params = {
    ..._params,
    page,
    per: 20,
  }
  const { data, error, mutate } = useSWR<
    WithHeaderData<ExecuteHistory[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/site_controller/execute_histories`,
      params
    ),
    ([token, url, _]: [string | null, string, unknown]) =>
      fetcherWithPaginationHeader<
        ExecuteHistory[],
        {
          event_type_and_actions: ExecuteHistoryParam[]
        }
      >([token, url, params])
  )

  return {
    data: data?.data,
    error,
    mutate,
    pagination: {
      ...data?.headerData,
      setPage,
    },
  }
}

export async function signInCheck(
  token: string,
  restaurantId: number,
  params: AuthenticationForSite
) {
  setHeader({ token })
  const { error, response } = await wrapResponse<{
    result: GourmetSiteProvider
  }>(
    axios.get(`/restaurants/${restaurantId}/site_controller/authentications`, {
      params,
    })
  )

  if (error != null) {
    if (isAxiosError(error) && error.response?.status === 401) {
      onError(new Error(t('ログインに失敗しました')))
      return {
        error,
      }
    }
    onError(error)
  }

  return {
    error,
    response,
  }
}
