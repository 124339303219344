import React from 'react'
import { View, ScrollView } from 'react-native'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { updateTableMenuPage, useTableMenuPages } from 'models/TableMenuPage'
import TableMenuPage from 'components/Restaurant/TableMenuPage'
import { useToken } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'

import { history } from 'modules/history'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export default function TableProductsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const token = useToken()

  const { tableMenuPages, isLoading } = useTableMenuPages({
    restaurantId,
    params: {
      status: 'archived',
    },
  })

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      <View style={{ marginTop: 20 }}>
        <ShadowBox
          style={[
            {
              paddingHorizontal: 0,
              paddingVertical: 8,
              marginTop: 24,
            },
          ]}
        >
          {isLoading && (
            <View style={{ padding: 24 }}>
              <Loading />
            </View>
          )}
          {!isLoading &&
            tableMenuPages?.length > 0 &&
            tableMenuPages.map((tableMenuPage, index) => {
              return (
                <View
                  key={tableMenuPage.id}
                  style={{
                    borderTopWidth: index === 0 ? 0 : 0.5,
                    borderColor: Colors.border,
                  }}
                >
                  <TableMenuPage
                    locked
                    isArchived
                    tableMenuPage={tableMenuPage}
                    onPress={(tableMenuPageId: string) =>
                      history.push(
                        `/restaurants/${restaurantId}/menu_pages/${tableMenuPageId}/edit`
                      )
                    }
                    onChangeStatus={async ({ tableMenuPageId, params }) => {
                      await updateTableMenuPage({
                        token: token ?? '',
                        tableMenuPageId,
                        params,
                      })
                    }}
                  />
                </View>
              )
            })}
          {!isLoading && tableMenuPages.length === 0 && (
            <Text
              style={{
                paddingTop: 16,
                marginBottom: 24,
                paddingHorizontal: 24,
                fontSize: 22,
                fontWeight: '600',
                color: Colors.disabledBlack,
                textAlign: 'center',
              }}
            >
              {t('該当するカテゴリーはありません')}
            </Text>
          )}
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
