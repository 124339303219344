import { AspectImage } from '@hello-ai/ar_shared/src/components/AspectImage'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import logo from 'assets/images/logo.png'
import {
  businessUserClient,
  useBusinessUserInvitation,
} from 'models/BusinessUser'
import { callRpc } from 'modules/rpc'
import { onError } from 'modules/swr'
import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import LinkText from 'components/Shared/LinkText'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { BusinessUserResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'
import { signIn } from 'models/Auth'
import invariant from 'tiny-invariant'

type RegistrationSteps = 'REGISTER' | 'DONE'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const formWidth = 600
const submitButtonHeight = 48

function RegistrationLayout({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  return (
    <View
      style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      <View
        style={{
          alignItems: 'center',
          width: formWidth,
        }}
      >
        <AspectImage
          style={{
            width: 160,
            aspectRatio: 1995 / 768,
          }}
          source={{ uri: logo }}
          resizeMode="contain"
        />
        <Text style={{ marginTop: 12, fontSize: 22, fontWeight: '600' }}>
          {title}
        </Text>
        {children}
      </View>
    </View>
  )
}

function RegistrationDone({ invitationToken }: { invitationToken: string }) {
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from
  const url = from ?? '/'

  const { data: invitation } = useBusinessUserInvitation({
    token: invitationToken,
  })

  if (invitation === undefined) {
    return <Loading />
  }

  const ownerName = invitation.owner?.name

  invariant(ownerName !== undefined, 'ownerName cannot be undefined')

  return (
    <RegistrationLayout title={t('メンバーに参加しました')}>
      <Text>{t('{{name}}に参加しました', { name: ownerName })}</Text>
      <LinkText
        style={{
          marginTop: 32,
          color: Colors.primary,
        }}
        to={url}
      >
        {t('ホームへ')}
      </LinkText>
    </RegistrationLayout>
  )
}

export function Registration({
  id,
  invitationToken,
  onSubmitted,
}: {
  id: string
  invitationToken: string
  onSubmitted: () => void
}) {
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  const { data: invitation } = useBusinessUserInvitation({
    token: invitationToken,
  })

  const disabled = password.length < 6 || name === ''

  useEffect(() => {
    if (
      invitation?.invitation?.businessUser?.status ===
      BusinessUserResource_Status.REGISTERED
    ) {
      onSubmitted()
    }
  }, [invitation?.invitation?.businessUser?.status, onSubmitted])

  if (invitation === undefined) {
    return <Loading />
  }

  const ownerName = invitation.owner?.name
  const businessUserEmail = invitation.invitation?.businessUser?.email

  invariant(ownerName !== undefined, 'ownerName cannot be undefined')
  invariant(
    businessUserEmail !== undefined,
    'businessUserEmail cannot be undefined'
  )

  const onSubmit = async () => {
    if (disabled) return
    invariant(invitation.owner !== undefined, 'owner cannot be undefined')

    {
      const { error } = await callRpc(
        businessUserClient.register({
          id,
          invitationToken,
          password,
          name,
        })
      )

      if (error != null) {
        onError(error)
        return
      }
    }

    {
      const { error } = await signIn(
        businessUserEmail,
        password,
        invitation.owner.id
      )
      if (error != null) {
        onError(error)
        return
      }
    }

    displayToastInfo(t('登録しました'))
    onSubmitted?.()
  }

  return (
    <RegistrationLayout title={t('メンバーに参加する')}>
      <Text>
        {t(
          '{{name}}の管理者が、あなた({{email}})をメンバーに招待しました。 以下の情報を入力して、{{name}}に参加してください。',
          {
            name: ownerName,
            email: businessUserEmail,
          }
        )}
      </Text>
      <FormGroup
        style={{
          marginTop: 40,
          width: '100%',
        }}
        formLabel={<FormLabel value={t('名前')} required />}
      >
        <TextInput
          value={name}
          onChangeText={setName}
          placeholder={t('田中　太郎')}
        />
      </FormGroup>
      <FormGroup
        style={{
          marginTop: 28,
          width: '100%',
        }}
        formLabel={<FormLabel value={t('パスワード')} required />}
      >
        <TextInput
          value={password}
          onChangeText={setPassword}
          onSubmitEditing={onSubmit}
          clearButtonMode="always"
          placeholder={t('6文字以上')}
          secureTextEntry
        />
      </FormGroup>
      <Button
        disabled={disabled}
        onPress={onSubmit}
        style={{
          marginTop: 48,
          width: '100%',
          height: submitButtonHeight,
        }}
      >
        {t('参加する')}
      </Button>
    </RegistrationLayout>
  )
}

export default function BusinessUsersRegistration() {
  const query = useQuery()
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from

  const invitationToken = query.get('invitation_token')

  const [registartionType, setRegistrationType] =
    useState<RegistrationSteps>('REGISTER')

  useEffect(() => {
    if (invitationToken === null || invitationToken === '') {
      navigate(from ?? '/')
    }
  }, [navigate, invitationToken, from])

  const onSubmitted = useCallback(() => {
    setRegistrationType('DONE')
  }, [])

  if (invitationToken == null || invitationToken === '') return null

  return registartionType === 'REGISTER' ? (
    <Registration
      id={id!}
      invitationToken={invitationToken}
      onSubmitted={onSubmitted}
    />
  ) : (
    <RegistrationDone invitationToken={invitationToken} />
  )
}
