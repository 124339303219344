import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import dayjs, { Dayjs } from '@hello-ai/ar_shared/src/modules/dayjs'
import invariant from 'invariant'
import { RestaurantCourse } from '../../../models/RestaurantCourse'
import {
  useRestaurantCourseBusinessTime,
  createRestaurantCourseBusinessTime,
  deleteRestaurantCourseBusinessTime,
} from '../../../models/RestaurantCourseBusinessTime'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'

interface CalendarContextProps {
  visibleMonth: dayjs.Dayjs
  setVisibleMonth: (month: dayjs.Dayjs) => void
  unpublishedDays: Dayjs[]
  onPressDay: (day: Dayjs) => void
  onPressAllInMonth: (newState: boolean) => void
  restaurantCourses: RestaurantCourse[]
  selectedRestaurantCourse: RestaurantCourse
  setSelectedRestaurantCourse: (restaurantCourse: RestaurantCourse) => void
  onPressWeek: (dayOfWeek: number, nextState: boolean) => void
}
export const CalendarContext = createContext<CalendarContextProps | undefined>(
  undefined
)

export const useCalendarContext = () => {
  const value = useContext(CalendarContext)
  invariant(
    value !== undefined,
    'useCalendarContext() must be used inside <CalendarProvider />'
  )
  return value
}

interface CalendarContextProviderProps {
  restaurantId: number
  restaurantCourses: RestaurantCourse[]
  children: React.ReactNode
}

const REQUEST_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'

export function CalendarContextProvider({
  restaurantId,
  restaurantCourses,
  children,
}: CalendarContextProviderProps) {
  const [visibleMonth, setVisibleMonth] = useState(dayjs().tz())
  const [selectedRestaurantCourse, setSelectedRestaurantCourse] = useState(
    restaurantCourses[0]
  )

  const { restaurantCourseBusinessTime, mutate } =
    useRestaurantCourseBusinessTime(restaurantId, selectedRestaurantCourse?.id)
  const token = useToken()

  const unpublishedDays = useMemo(() => {
    if (restaurantCourseBusinessTime == null) return []
    const unpublishedDays = restaurantCourseBusinessTime.map((d) =>
      dayjs(d.start_at)
    )
    return Array.from(unpublishedDays)
  }, [restaurantCourseBusinessTime])

  const createUnpublishedDays = useCallback(
    async (unpublishedDays: Dayjs[]) => {
      if (token == null) return
      const { error } = await createRestaurantCourseBusinessTime(
        token,
        restaurantId,
        selectedRestaurantCourse.id,
        {
          restaurant_course_business_times: unpublishedDays.map((d) => ({
            start_at: d.startOf('day').format(REQUEST_DATE_FORMAT),
            end_at: d.endOf('day').format(REQUEST_DATE_FORMAT),
            status: 'unpublished',
          })),
        }
      )
      if (error != null) {
        onError(error)
        return
      }
      await mutate()
    },
    [token, restaurantId, selectedRestaurantCourse, mutate]
  )

  const deleteUnpublishedDays = useCallback(
    async (businessTimeIds: string[]) => {
      if (token == null) return
      const { error } = await deleteRestaurantCourseBusinessTime(
        token,
        restaurantId,
        selectedRestaurantCourse.id,
        {
          restaurant_course_business_times: businessTimeIds.map((id) => ({
            id,
          })),
        }
      )
      if (error != null) {
        onError(error)
        return
      }
      await mutate()
    },
    [token, restaurantId, selectedRestaurantCourse, mutate]
  )

  const onPressDay = useCallback(
    async (day: Dayjs) => {
      const targetBusinessTimes = restaurantCourseBusinessTime?.filter((d) =>
        dayjs(d.start_at).isSame(day, 'day')
      )
      if (targetBusinessTimes != null && targetBusinessTimes.length > 0) {
        await deleteUnpublishedDays(targetBusinessTimes.map((d) => d.id))
      } else {
        await createUnpublishedDays([day])
      }
    },
    [restaurantCourseBusinessTime, createUnpublishedDays, deleteUnpublishedDays]
  )

  const onPressWeek = useCallback(
    async (dayOfWeek: number, nextState: boolean) => {
      //  dayOfWeekに該当する日付を取得
      const candidateDays = Array.from({ length: visibleMonth.daysInMonth() })
        .map((_, i) => visibleMonth.date(i + 1))
        .filter((d) => d.day() === dayOfWeek)
      if (!nextState) {
        await createUnpublishedDays(candidateDays)
      } else {
        const sameBusinessTimes = restaurantCourseBusinessTime?.filter((d) =>
          candidateDays.some((day) => day.isSame(dayjs(d.start_at), 'day'))
        )
        if (sameBusinessTimes != null && sameBusinessTimes.length > 0) {
          await deleteUnpublishedDays(sameBusinessTimes.map((d) => d.id))
        }
      }
    },
    [
      restaurantCourseBusinessTime,
      visibleMonth,
      createUnpublishedDays,
      deleteUnpublishedDays,
    ]
  )

  const onPressAllInMonth = useCallback(
    async (newState: boolean) => {
      if (newState) {
        const businessTimes = restaurantCourseBusinessTime?.filter((d) => {
          const day = dayjs(d.start_at)
          return day.isSame(visibleMonth, 'month')
        })
        if (businessTimes == null) return
        await deleteUnpublishedDays(businessTimes.map((d) => d.id))
      } else {
        const candidateDays = Array.from({
          length: visibleMonth.daysInMonth(),
        }).map((_, i) => visibleMonth.date(i + 1))
        await createUnpublishedDays(candidateDays)
      }
    },
    [
      visibleMonth,
      restaurantCourseBusinessTime,
      createUnpublishedDays,
      deleteUnpublishedDays,
    ]
  )

  return (
    <CalendarContext.Provider
      value={{
        visibleMonth,
        setVisibleMonth,
        unpublishedDays,
        onPressDay,
        onPressAllInMonth,
        restaurantCourses,
        selectedRestaurantCourse,
        setSelectedRestaurantCourse,
        onPressWeek,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}
