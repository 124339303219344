import aspida from '@aspida/axios'
import apiTable from './api_table/$api'
import type { AxiosInstance } from 'axios'
export type { AspidaResponse, HttpStatusOk } from 'aspida'

export type { ApiInstance } from './api_table/$api'

export function createApiTableClient(client: AxiosInstance) {
  return apiTable(aspida(client))
}
