import useSWR, { SWRHook } from 'swr'
import { useStickyResult } from './useStickyResult'

// https://github.com/vercel/swr/issues/192#issuecomment-568944696
export const useStickySWR = ((...args: Parameters<SWRHook>) => {
  const { data, isValidating, error, ...rest } = useSWR(...args)

  const stickyData = useStickyResult(data)

  return {
    ...rest,
    isValidating,
    error,
    data: stickyData,
  }
}) as SWRHook
