import axios, { setHeader, wrapResponse } from '../modules/axios'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import useSWR, {
  WithHeaderData,
  fetcherWithPaginationHeader,
  swrKey,
} from '../modules/swr'
import { useState } from 'react'
import { ParsedCourse } from '@hello-ai/ar_shared/src/types/ForR/SiteControllerCourse'
import Config from '../modules/config'
import { GourmetSiteProvider } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'

export async function updateSiteControllerParsedCourses(
  token: string,
  restaurantId: number,
  userId: string
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(`/restaurants/${restaurantId}/site_controller/parsed_courses`, {
      sandbox: Config.apiUrl.includes('lvh.me'),
      business_user_id: userId,
    })
  )
  if (error != null) {
    onError(error)
  }

  return {
    error,
    response,
  }
}

export function useSiteControllerParsedCourses(
  restaurantId: number,
  site: GourmetSiteProvider,
  param?: {
    name: string
  }
) {
  const token = useToken()
  const [page, setPage] = useState(1)
  const params = {
    page,
    per: 10,
    site,
    ...param,
  }
  const { data, error, mutate } = useSWR<
    WithHeaderData<ParsedCourse[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/site_controller/parsed_courses`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<ParsedCourse[], typeof params>([
        token,
        url,
        params,
      ])
  )

  return {
    data: data?.data,
    error,
    mutate,
    pagination: {
      ...data?.headerData,
      setPage,
    },
  }
}
