import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import React from 'react'
import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import { TableSeatResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'
import { getCategoryName } from './common'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export function TableSeatArchived({
  tableSeat,
  onAlertPublish,
}: {
  tableSeat: TableSeatResource | undefined
  onAlertPublish: (tableSeatId: TableSeat['id']) => void
}) {
  if (!tableSeat) {
    return null
  }
  const tableSeatId = tableSeat.id

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: 96,
          padding: 8,
        }}
      >
        <Text
          style={{
            fontWeight: '600',
          }}
        >
          {tableSeat.name}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 8,
          }}
        >
          {tableSeat.minPartySize && tableSeat.maxPartySize && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faUsers}
                size={14}
                color={Colors.secondaryBlack}
                style={{ marginRight: 4 }}
              />
              <Text style={{ fontSize: 14, color: Colors.secondaryBlack }}>
                {tableSeat.minPartySize.value}~{tableSeat.maxPartySize.value}
              </Text>
            </View>
          )}
          {tableSeat.category && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 8,
              }}
            >
              <FontAwesomeIcon
                icon={faChair}
                size={14}
                color={Colors.secondaryBlack}
                style={{ marginRight: 4 }}
              />
              <Text style={{ fontSize: 14, color: Colors.secondaryBlack }}>
                {getCategoryName(tableSeat.category.value)}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          marginLeft: 'auto',
          marginTop: 23,
        }}
      >
        <Button
          height={48}
          width={124}
          mode="outline"
          variant="danger-secondary"
          onPress={() => onAlertPublish(tableSeatId)}
        >
          <Text style={{ color: Colors.caution, fontWeight: 'normal' }}>
            {t('元に戻す')}
          </Text>
        </Button>
      </View>
    </View>
  )
}
