import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { useNavigation } from 'components/Shared/Navigation/Screen'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useGourmetSource } from 'modules/useGourmetSiteSource'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import GourmetSiteAccountSettingDetailView from '@hello-ai/for_r_app/src/components/GourmetSiteAccount/GourmetSiteAccountSettingDetailView'
import { View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function GourmetSiteAccountSettingDetail() {
  const restaurantId = useRestaurantId()
  const source = useGourmetSource()
  const navigate = useNavigate()
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({
      title: t('{{provider}}の連携設定', {
        provider: GOURMET_SITE_PROVIDER_SOURCE[source].authenticateLabel,
      }),
    })
  }, [navigation, source])

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <GourmetSiteAccountSettingDetailView
        restaurantId={restaurantId}
        source={source}
        onPressEditLoginInfo={(outboundSetting) => {
          navigate(
            `/restaurants/${restaurantId}/gourmet_site/account_settings/${source}/edit_login_info/${outboundSetting.id}`
          )
        }}
        onMailNoteAgreed={() => {
          navigate(
            `/restaurants/${restaurantId}/gourmet_site/account_settings/${source}/init_form`
          )
        }}
      />
    </View>
  )
}
