import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, {
  fetcher,
  mutate,
  onError,
  swrKey,
  useStickySWR,
} from 'modules/swr'

import { useToken } from './Auth'

import { TakeoutMenu } from '@hello-ai/ar_shared/src/types/ForR/TakeoutMenu'
export * from '@hello-ai/ar_shared/src/types/ForR/TakeoutMenu'

export function useRestaurantTakeoutMenus({
  restaurantId,
}: {
  restaurantId: number
}) {
  const token = useToken()
  const {
    data: takeoutMenus,
    error,
    mutate,
  } = useSWR<TakeoutMenu[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/takeout/restaurants/${restaurantId}/menus`),
    fetcher
  )

  return {
    takeoutMenus,
    isLoading: takeoutMenus == null,
    error,
    mutate,
  }
}

export function useTakeoutMenu({ takeoutMenuId }: { takeoutMenuId?: string }) {
  const token = useToken()
  const {
    data: takeoutMenu,
    error,
    mutate,
  } = useSWR<TakeoutMenu, any, ReturnType<typeof swrKey> | null>(
    takeoutMenuId != null
      ? swrKey(token, `/takeout/menus/${takeoutMenuId}`)
      : null,
    fetcher
  )

  if (takeoutMenuId == null) {
    return {
      tableMenu: null,
      isLoading: false,
      mutate,
    }
  }

  return {
    takeoutMenu,
    isLoading: takeoutMenu == null,
    error,
    mutate,
  }
}

export async function createTakeoutMenu({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse<TakeoutMenu>(
    axios.post(`/takeout/restaurants/${restaurantId}/menus`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/takeout/menus/${response?.data?.id}`, params),
      response.data,
      false
    )
  }

  return {
    takeoutMenu: response?.data,
  }
}

export async function updateTakeoutMenu({
  token,
  takeoutMenuId,
  params,
}: {
  token: string
  takeoutMenuId: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(`/takeout/menus/${takeoutMenuId}`, params)
  )
  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/takeout/menus/${takeoutMenuId}`),
      response.data,
      false
    )
  }

  return {
    takeoutMenu: response?.data,
  }
}

export type OrderMenusMenu = Pick<
  TakeoutMenu,
  'id' | 'name' | 'time_range_description'
>

export function useTakeoutMenuSearch(
  takeoutMenuId: TakeoutMenu['id'] | null,
  params: {
    keyword: string
  }
) {
  const token = useToken()
  const {
    data: takeoutMenu,
    error,
    mutate,
  } = useStickySWR<TakeoutMenu, any, ReturnType<typeof swrKey> | null>(
    takeoutMenuId == null || params.keyword === ''
      ? null
      : swrKey(token, `/takeout/menus/${takeoutMenuId}/search`, params),
    ([token, url]) => fetcher<TakeoutMenu>([token, url, params])
  )

  return {
    takeoutMenu,
    isLoading: takeoutMenu == null,
    error,
    mutate,
  }
}
