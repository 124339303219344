import React, { useCallback, useMemo } from 'react'
import { View, ViewProps } from 'react-native'

import { compact } from 'lodash'
import { useLocation, useNavigate } from 'react-router'

import { InjectedProps, To } from './types'
import { useConfig } from '../Config'

type LocationState = unknown

type LinkOnPressHandler = (
  event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent
) => void

function normalize(to: To, location: Partial<Location>) {
  if (to == null) {
    return { href: '#', state: undefined }
  }

  if (typeof to === 'string') {
    return { href: to, state: undefined }
  }

  let pathname: string | undefined = ''
  let search: string | undefined = ''
  let hash: string | undefined = ''
  let state: LocationState

  if (typeof to === 'function') {
    ;({ pathname, search, hash, state } = to(location))
  } else if (to != null) {
    ;({ pathname, search, hash, state } = to)
  }

  return {
    href: compact([pathname, search, hash]).join(''),
    state,
  }
}

function isSameOrigin(url: string) {
  return (
    new URL(document.baseURI).origin === new URL(url, document.baseURI).origin
  )
}

function _useNavigate() {
  const navigate = useNavigate()

  return useCallback(
    (href: string, state: LocationState, isStatic?: boolean) => {
      if ((isStatic != null && isStatic) || !isSameOrigin(href)) {
        window.location.href = href
      } else {
        navigate(href, { state })
      }
    },
    [navigate]
  )
}

function useLinkProps({
  to,
  rel,
  target,
  isStatic = false,
  onPress,
}: {
  to: To
  rel?: string
  target?: string
  isStatic: boolean
  onPress?: LinkOnPressHandler
}) {
  const location = useLocation()
  const navigate = _useNavigate()

  const config = useConfig()

  const resolveHref = config.Link?.resolveHref ?? ((href: string) => href)

  const { href: href_, state } = normalize(to, location)

  const href = resolveHref(href_)

  const handlePress: LinkOnPressHandler = useCallback(
    (event) => {
      // onPress?.(event)
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return
      }
      if (target != null && target !== '_self') {
        return
      }

      if (event.defaultPrevented) {
        return
      }

      event.preventDefault()
      navigate(href, state, isStatic)
    },
    [href, isStatic, navigate, state, target]
  )

  const hrefAttrs = useMemo(
    () => ({
      rel,
      target: typeof target === 'string' ? target.replace(/^_/, '') : target,
    }),
    [rel, target]
  )

  return {
    accessibilityRole: 'link',
    href,
    hrefAttrs,
    onPress: handlePress,
  }
}

export function createLinkComponent<T, P extends {}>(
  Component:
    | React.ComponentType<React.PropsWithChildren<P>>
    | React.ForwardRefExoticComponent<P & React.RefAttributes<T>>
) {
  return React.forwardRef<T, P & InjectedProps>(
    (
      {
        to,
        rel,
        target,
        isStatic = false,
        onPress,
        ...props
      }: P & InjectedProps,
      ref
    ) => {
      const linkProps = useLinkProps({
        to,
        rel,
        target,
        isStatic,
        onPress,
      })

      return <Component ref={ref} {...linkProps} {...(props as P)} />
    }
  )
}

export const Link = createLinkComponent<View, ViewProps>(View)
