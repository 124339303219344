import React from 'react'

import { StyleProp, View, ViewStyle } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'
import { useRestaurantCurrency } from '../../modules/useRestaurantCurrency'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { TableOrderItem as TableOrderItemModel } from '@hello-ai/ar_shared/src/types/ForR/TableOrderItem'
import { TableProductOptionSmall } from './TableProductOption'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TableOrderItemPlan as TableOrderItemPlanModel } from '@hello-ai/ar_shared/src/types/ForR/TableOrderItemPlan'
import {
  CanceledLabel,
  CustomerOrderHistoryProps,
  CustomerOrderHistoryTotal,
} from './CustomerOrderHistory'
import { Restaurant } from '../../models/Restaurant'

const chevronWidth = 10
const tHeadMargin = 12

export function Row({
  onPress,
  disabled,
  children,
  style,
}: {
  onPress?: React.ComponentProps<typeof TouchableOpacity>['onPress']
  disabled?: boolean
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <TouchableOpacity
      style={[{ flexDirection: 'row' }, style]}
      onPress={onPress}
      disabled={disabled}
    >
      {children}
    </TouchableOpacity>
  )
}

function Cell({
  isLead = false,
  flex,
  children,
}: {
  isLead?: boolean
  flex?: number
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        flex != null && {
          flex,
        },
        !isLead && {
          marginLeft: tHeadMargin,
        },
      ]}
    >
      {children}
    </View>
  )
}

function FixedCell({
  isLead,
  width,
  alignItems,
  justifyContent,
  children,
}: {
  isLead?: boolean
  width: number
  alignItems?: ViewStyle['alignItems']
  justifyContent?: ViewStyle['justifyContent']
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          width,
          alignItems,
          justifyContent,
        },
        !isLead && {
          marginLeft: tHeadMargin,
        },
      ]}
    >
      {children}
    </View>
  )
}

export function IconEmptyCell() {
  return <FixedCell width={chevronWidth} />
}

function TableOrderItemPlan({
  tableOrderItemPlan,
  restaurant,
}: {
  tableOrderItemPlan: TableOrderItemPlanModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const { formatCurrency } = useRestaurantCurrency(restaurant)
  return (
    <Row>
      <Cell isLead flex={1}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 12, fontWeight: '600' }}>
            {dayjs(tableOrderItemPlan.created_at).format('HH:mm')}
          </Text>
          <Text
            style={{
              marginLeft: 16,
              fontSize: 12,
            }}
          >
            {tableOrderItemPlan.creator?.name}
          </Text>
        </View>
        <View
          style={{
            marginLeft: 24,
            marginTop: 16,
            paddingLeft: 16,
            borderLeftWidth: 2,
            borderLeftColor: Colors.border,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {tableOrderItemPlan.status === 'canceled' && (
              <View style={{ marginRight: 12 }}>
                <CanceledLabel />
              </View>
            )}
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                flex: 1,
              }}
            >
              {tableOrderItemPlan.name}
            </Text>
          </View>
          <View
            style={{
              marginTop: 16,
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Text
              style={{ fontSize: 12, fontWeight: '600', color: Colors.black }}
            >
              {formatCurrency(tableOrderItemPlan.amount)}
            </Text>
          </View>
          <View style={{ marginRight: 0, marginLeft: 'auto' }}>
            <Text
              style={{ fontSize: 12, fontWeight: '600', color: Colors.black60 }}
            >
              {t('×{{amount}}個', { amount: tableOrderItemPlan.quantity })}
            </Text>
          </View>
        </View>
      </Cell>
    </Row>
  )
}

function TableOrderItem({
  tableOrderItem,
  restaurant,
}: {
  tableOrderItem: TableOrderItemModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const tableProduct = tableOrderItem.table_product
  const { formatCurrency } = useRestaurantCurrency(restaurant)

  const disabled = tableOrderItem.status === 'canceled'

  return (
    <Row disabled={disabled}>
      <Cell isLead flex={1}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 12, fontWeight: '600' }}>
            {tableOrderItem.ordered_at == null
              ? ''
              : dayjs(tableOrderItem.ordered_at).format('HH:mm')}
          </Text>
          <Text
            style={{
              marginLeft: 16,
              fontSize: 12,
            }}
          >
            {tableOrderItem.creator.name}
          </Text>
        </View>
        <View
          style={{
            marginLeft: 24,
            marginTop: 16,
            paddingLeft: 16,
            borderLeftWidth: 2,
            borderLeftColor: Colors.border,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {tableOrderItem.status === 'canceled' && (
              <View style={{ marginRight: 4 }}>
                <CanceledLabel />
              </View>
            )}
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                flex: 1,
              }}
            >
              {tableProduct.name}
            </Text>
          </View>
          <View
            style={{
              marginTop: 16,
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Text
              style={{ fontSize: 12, fontWeight: '600', color: Colors.black }}
            >
              {formatCurrency(tableOrderItem.amount)}
            </Text>

            <Text
              style={{
                fontSize: 12,
                color: Colors.black60,
              }}
            >
              ({tableOrderItem.tax_included ? t('税込') : t('税抜')}
              {'/'}
              {tableOrderItem.tax_type === 'default' && '10%'}
              {tableOrderItem.tax_type === 'reduced' && '8%'})
            </Text>
          </View>
          {tableOrderItem.table_product_options.length > 0 && (
            <View style={{ flexDirection: 'row', marginTop: 12 }}>
              <View style={{ marginTop: 20, flex: 1, gap: 20 }}>
                {tableOrderItem.table_product_options.map((option, index) => {
                  return (
                    <View
                      key={option.id}
                      style={{ marginTop: index === 0 ? -1 : 12 }}
                    >
                      <TableProductOptionSmall
                        tableProductOption={option}
                        restaurant={restaurant}
                      />
                    </View>
                  )
                })}
              </View>
            </View>
          )}
          <View style={{ marginRight: 0, marginLeft: 'auto' }}>
            <Text
              style={{ fontSize: 12, fontWeight: '600', color: Colors.black60 }}
            >
              {t('x{{quantity}}個', {
                quantity: numberWithDelimiter(tableOrderItem.quantity),
              })}
            </Text>
          </View>
        </View>
      </Cell>
    </Row>
  )
}

export const CustomerOrderHistorySmall = React.memo(
  function TableOrderHistorySmall({
    tableOrder,
    restaurant,
  }: CustomerOrderHistoryProps) {
    const tableOrderItems = tableOrder.table_order_items

    return (
      <View
        style={{
          marginTop: 12,
          borderTopWidth: 0.5,
          borderTopColor: Colors.border,
        }}
      >
        {tableOrder.table_order_item_plans.length > 0 && (
          <View
            style={{
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
              paddingBottom: 12,
              marginTop: 12,
              gap: 24,
            }}
          >
            {tableOrder.table_order_item_plans.map(
              (tableOrderItemPlan, index) => {
                return (
                  <View key={`tableOrderItemPlan${index}`}>
                    <TableOrderItemPlan
                      tableOrderItemPlan={tableOrderItemPlan}
                      restaurant={restaurant}
                    />
                  </View>
                )
              }
            )}
          </View>
        )}
        {tableOrderItems != null && (
          <View>
            {tableOrderItems.map((tableOrderItem) => {
              return (
                <View
                  key={`tableOrderItem${tableOrderItem.id}`}
                  style={{ marginTop: 12 }}
                >
                  <TableOrderItem
                    tableOrderItem={tableOrderItem}
                    restaurant={restaurant}
                  />
                </View>
              )
            })}
          </View>
        )}
        <CustomerOrderHistoryTotal
          tableOrder={tableOrder}
          restaurant={restaurant}
        />
      </View>
    )
  }
)
