import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useConfig } from '../Config'

export interface ModalProps {
  isVisible: boolean
  onBackButtonPress?: () => void
  onBackdropPress?: () => void
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
  contentContainerStyle?: StyleProp<ViewStyle>
}

export function Modal<P extends ModalProps>(props: P) {
  const { ModalComponent: Modal } = useConfig()
  const { isVisible } = props

  return <Modal {...props} />
}
