import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import Customers from 'pages/Customers'
import Customer from 'pages/Customers/Show'
import CustomerEdit from 'pages/Customers/Edit'
import AddNewCustomer from 'pages/Customers/New'
import CustomersSendMessages from 'pages/Customers/SendMessages'
import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('顧客'),
    fullPath: '/restaurants/:id/customers',
    relativePath: '/',
    exact: true,
    element: <Customers />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Show: {
    title: t('顧客詳細'),
    fullPath: '/restaurants/:id/customers/:customerId',
    relativePath: '/:customerId',
    element: <Customer />,
  },
  Edit: {
    title: t('顧客編集'),
    fullPath: '/restaurants/:id/customers/:customerId/edit',
    relativePath: '/:customerId/edit',
    element: <CustomerEdit />,
  },
  New: {
    title: t('新規顧客登録'),
    fullPath: '/restaurants/:id/customers/new',
    relativePath: '/new',
    element: <AddNewCustomer />,
  },
  SendMessages: {
    title: t('メッセージ配信'),
    fullPath: '/restaurants/:id/customers/send_messages',
    relativePath: '/send_messages',
    exact: true,
    element: <CustomersSendMessages />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function CustomersStack() {
  return <Stack routes={routes} />
}
