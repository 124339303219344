import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons/faCalendarDays'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { ScrollView } from 'react-native-gesture-handler'
import { ReservationCoursesAndMemo } from '../ReservationDetails'
import { getCustomerDisplayName } from '../../Customers/Customer'
import {
  getParsedPhoneNumber,
  isPossibleParsePhoneNumber,
} from '../../../modules/phoneNumber'
import { ReservationRepContainer, ReservationRepWithDate } from '../RepView'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import Loading from '../../Shared/Loading'

function Row({
  mode = 'inline',
  style,
  icon,
  children,
}: {
  mode?: 'inline' | 'inline-expanded'
  icon?: IconDefinition
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: mode === 'inline' ? 'center' : 'flex-start',
          paddingVertical: 8,
        },
        style,
      ]}
    >
      <View style={{ width: 18, marginRight: 14 }}>
        {icon && (
          <FontAwesomeIcon icon={icon} size={18} color={Colors.black50} />
        )}
      </View>
      {children}
    </View>
  )
}

export function DetailModalContent({
  restaurantId,
  data,
  isInPossibleRange,
  onPressApproval,
  onPressRejection,
}: {
  restaurantId: number
  data: RestaurantRequestReservation | undefined
  isInPossibleRange: boolean
  onPressApproval: () => void
  onPressRejection: () => void
}) {
  if (data == null) return <Loading />

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <ScrollView contentContainerStyle={{ padding: 24 }}>
        <ReservationRepContainer
          style={{
            marginLeft: 0,
          }}
        >
          <ReservationRepWithDate
            label={t('リクエスト日')}
            date={data.created_at}
          />
        </ReservationRepContainer>
        <Text style={{ paddingBottom: 24, fontWeight: '600', fontSize: 22 }}>
          {t('リクエスト予約の受付可否を選んでください。')}
        </Text>
        <Text style={{ fontWeight: '600' }}>{t('リクエスト内容')}</Text>
        <Row icon={faCalendarDays}>
          <Text>{`${dayjs(data.start_at).format(
            // eslint-disable-next-line ar-i18n/require-translation-ja
            'YYYY年MM月DD日（dd）HH:mm'
          )}${
            !isInPossibleRange
              ? t('（{{start_at}}〜{{end_at}}で予約可能）', {
                  start_at: dayjs(data.start_at_min).format('HH:mm'),
                  end_at: dayjs(data.start_at_max).format('HH:mm'),
                })
              : ''
          }`}</Text>
        </Row>
        <Row icon={faUserFriends}>
          <Text>
            {t('{{party_size}} ({{adult_party_size}}、{{child_party_size}})', {
              party_size: t('{{count}}名様', { count: data.party_size }),
              adult_party_size: t('大人{{count}}', {
                count: data.adult_party_size,
              }),
              child_party_size: t('お子様{{count}}', {
                count: data.child_party_size,
              }),
            })}
          </Text>
        </Row>
        <Row icon={faChair}>
          <Text>{data.restaurant_seat?.name}</Text>
        </Row>
        <ReservationCoursesAndMemo
          restaurantId={restaurantId}
          reservationCourses={data.reservation_request_courses.map(
            (course) => ({
              partySize: course.party_size,
              restaurantCourse: course.restaurant_course,
              restaurantCourseQuestionAnswers:
                course.restaurant_course_question_answers.map((answer) => ({
                  text: answer.text,
                  restaurantCourseQuestion: {
                    description: answer.restaurant_course_question.description,
                  },
                  restaurantCourseQuestionSelections:
                    answer.restaurant_course_question_selections.map((s) => ({
                      description: s.description,
                    })),
                })),
            })
          )}
          allergy={data.allergy}
          memo={data.memo ?? ''}
        />

        <Text style={{ fontWeight: '600' }}>{t('予約者情報')}</Text>
        <Row icon={faAddressCard}>
          <Text>
            {data.customer != null
              ? t('{{name}}様', { name: getCustomerDisplayName(data.customer) })
              : ''}
          </Text>
        </Row>
        <Row icon={faPhone}>
          <Text>
            {isPossibleParsePhoneNumber(data.phone_number)
              ? getParsedPhoneNumber(data.phone_number).phone.replaceAll(
                  '-',
                  ''
                )
              : ''}
          </Text>
        </Row>
        <Row icon={faEnvelope}>
          <Text>{data.customer?.email}</Text>
        </Row>
        <Row icon={faFrown}>
          <Text>{data.customer?.allergy ?? t('アレルギーはありません。')}</Text>
        </Row>
      </ScrollView>
      <View>
        <Divider />
        <View
          style={{
            paddingVertical: 24,
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Button
            mode="outline"
            variant="danger"
            style={{ marginRight: 16 }}
            onPress={onPressRejection}
          >
            {t('拒否する')}
          </Button>
          <Button onPress={onPressApproval}>{t('承認する')}</Button>
        </View>
      </View>
    </View>
  )
}
