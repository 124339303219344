import React, { ComponentProps, useMemo, useState } from 'react'
import flatMap from 'lodash/flatMap'
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook'
import {
  Customer,
  ReservationCoursesAndMemo,
  ReservationDetails,
} from './ReservationDetails'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { confirmAsync } from '@hello-ai/ar_shared/src/modules/alert'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import Loading from '../Shared/Loading'
import { displayToastSuccess } from '../Shared/Toast'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { toSeconds } from '../../modules/time'
import {
  RestaurantReservationWithSiteController,
  cancelRestaurantReservation,
  isSiteControllerReservation,
  useRestaurantReservation,
  useRestaurantReservations,
} from '../../models/RestaurantReservation'
import { ScrollView, View, Platform } from 'react-native'
import ModalCenter from '../Shared/ModalCenter'
import {
  ReservationRep,
  ReservationRepContainer,
  ReservationRepWithDate,
} from './RepView'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GOURMET_SITE_PROVIDER_TEXT_STYLE,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { RestaurantReservationCourse } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservationCourse'
import { useRestaurantAttributes } from '../../models/CustomerAttributes'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useRestaurant } from '../../models/Restaurant'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useNavigate } from '../../modules/navigation/useNavigate'

const buttonHeight = 48

const getReservationCourses = (
  reservationCourses: RestaurantReservationCourse[]
): ComponentProps<typeof ReservationCoursesAndMemo>['reservationCourses'] =>
  flatMap(
    reservationCourses,
    ({
      party_size: partySize,
      restaurant_course: restaurantCourse,
      restaurant_course_question_answers: restaurantCourseQuestionAnswers,
    }) => {
      if (partySize === 0) return []
      return {
        partySize,
        restaurantCourse,
        restaurantCourseQuestionAnswers: restaurantCourseQuestionAnswers.map(
          (restaurantCourseQuestionAnswer) => {
            return {
              text: restaurantCourseQuestionAnswer.text,
              restaurantCourseQuestion: {
                description:
                  restaurantCourseQuestionAnswer.restaurant_course_question
                    .description,
              },
              restaurantCourseQuestionSelections:
                restaurantCourseQuestionAnswer.restaurant_course_question_selections.map(
                  (selection) => {
                    return {
                      description: selection.description,
                    }
                  }
                ),
            }
          }
        ),
      }
    }
  )

const getReservationCoursesFromSiteController = (
  restaurantReservation: RestaurantReservationWithSiteController
): ComponentProps<typeof ReservationCoursesAndMemo>['reservationCourses'] => {
  const parsedCourse =
    restaurantReservation.reservation_site_controller_parsed_course
      .site_controller_parsed_course
  return [
    {
      partySize:
        restaurantReservation.reservation_site_controller_parsed_course
          .course_party_size,
      restaurantCourse: {
        id: parsedCourse.id,
        title: parsedCourse.name,
        price: parsedCourse.price,
      },
      restaurantCourseQuestionAnswers:
        restaurantReservation.site_controller_reservation_restaurant_memos.map(
          (memo) => ({
            text: memo.content,
            restaurantCourseQuestion: {
              description: memo.title,
            },
            restaurantCourseQuestionSelections: [],
          })
        ),
    },
  ]
}

export function ReservationsShow({
  restaurantId,
  restaurantReservationId,
}: {
  restaurantId: number
  restaurantReservationId: string
}) {
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )
  const { data: restaurant } = useRestaurant(restaurantId)

  const isFreePlan =
    restaurant == null || restaurant.reservation_book_plan_type === 'free'

  const { data: attributesData } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const attributes = useMemo(
    () => attributesData?.filter((e) => e.display_in_list) ?? [],
    [attributesData]
  )

  const { width, sm } = useResponsive()

  const [isModalVisible, setModalVisible] = useState(false)
  const [charge, setCharge] = useState('false')

  const { mutate } = useRestaurantReservations(
    restaurantId,
    {
      date: dayjs(restaurantReservation?.start_at).format('YYYY-MM-DD'),
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
    }
  )

  if (restaurantReservation === undefined) {
    return <Loading />
  }

  const {
    reservation_child_details,
    adult_party_size: adultPartySize,
    child_party_size: childPartySize,
    start_at: startAtStr,
    end_at: endAtStr,
    table_seats: tableSeats,
    customers,
    reservation_courses,
    allergy,
    memo,
    status: reservationStatus,
    visit_status: visitStatus,
  } = restaurantReservation
  const startAt = dayjs(startAtStr)
  const endAt = dayjs(endAtStr)
  const startTime = toSeconds(startAt.hour(), startAt.minute())
  const endTime = toSeconds(endAt.hour(), endAt.minute())
  const tableSeatIds = tableSeats.map(({ id }) => id)

  const onPressCancel = async () => {
    if (restaurantReservation.cancel_fee > 0) {
      setModalVisible(true)
      return
    }

    if (!(await confirmAsync(t('予約をキャンセルしますか？')))) return

    const { error } = await cancelRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      {
        charge: false,
      }
    )
    if (error != null) return

    await mutate()
    displayToastSuccess(
      t('予約をキャンセルしました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }

  const onPressConfirmCancel = async () => {
    let params
    if (charge === 'true') {
      params = {
        charge: true,
        refund_reservation_fee: false,
      }
    } else if (charge === 'false') {
      params = {
        charge: false,
        refund_reservation_fee: false,
      }
    } else {
      params = {
        charge: false,
        refund_reservation_fee: true,
      }
    }
    const { error } = await cancelRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      params
    )
    if (error != null) return
    displayToastSuccess(
      t('予約をキャンセルしました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )

    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }

  const onPressEdit = () => {
    if (Platform.OS === 'web') {
      navigate('./edit')
    } else {
      navigation.navigate('ReservationsForm', {
        restaurantReservationId,
      })
    }
  }

  const onPressEditSeat = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/${restaurantReservationId}/change_seat`
      )
    } else {
      navigation.navigate('ChangeSeatForm', {
        restaurantReservationId,
      })
    }
  }

  const isSiteControllerReserved = isSiteControllerReservation(
    restaurantReservation
  )

  return (
    <>
      {isSiteControllerReserved && (
        <Text
          style={[
            GOURMET_SITE_PROVIDER_TEXT_STYLE[
              restaurantReservation.reservation_site_controller_parsed_course
                .site_controller_parsed_course.site
            ],
            {
              fontSize: 12,
              fontWeight: '600',
              textAlign: 'center',
              paddingVertical: 4,
            },
          ]}
        >
          {t('{{provider}}からの予約です', {
            provider:
              GOURMET_SITE_PROVIDER_SOURCE[
                restaurantReservation.reservation_site_controller_parsed_course
                  .site_controller_parsed_course.site
              ].label,
          })}
        </Text>
      )}
      {reservationStatus === 'canceled' && <ReservationCanceledHeader />}
      <ScrollView
        style={{ flex: 1, backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          paddingHorizontal: width < sm ? 16 : 40,
          paddingVertical: width < sm ? 24 : 48,
        }}
      >
        <ReservationRepContainer
          style={{
            marginBottom: 8,
          }}
        >
          {restaurantReservation.restaurant_crew_member && (
            <ReservationRep
              label={t('受付担当者')}
              name={restaurantReservation.restaurant_crew_member.name}
            />
          )}
          <ReservationRepWithDate
            label={t('受付日')}
            date={restaurantReservation.created_at}
          />
          {reservationStatus !== 'canceled' &&
            restaurantReservation.changed_at != null && (
              <ReservationRepWithDate
                label={t('変更日')}
                date={restaurantReservation.changed_at}
              />
            )}
          {reservationStatus === 'canceled' &&
            restaurantReservation.canceled_at != null && (
              <ReservationRepWithDate
                label={t('キャンセル日')}
                date={restaurantReservation.canceled_at}
              />
            )}
        </ReservationRepContainer>
        <ShadowBox>
          <ReservationDetails
            restaurantId={restaurantId}
            date={startAt}
            startTime={startTime}
            endTime={endTime}
            adultPartySize={adultPartySize}
            childPartySize={childPartySize}
            reservationChildDetails={reservation_child_details}
            tableSeatIds={tableSeatIds}
            restaurantReservationId={restaurantReservationId}
            reservationStatus={reservationStatus}
            visitStatus={visitStatus}
          />
          {customers.length > 0 &&
            customers.map((customer) => {
              return (
                <View
                  key={customer.id}
                  style={{
                    borderTopWidth: 1,
                    borderColor: Colors.border,
                    marginTop: 24,
                    paddingTop: 24,
                  }}
                >
                  <Customer
                    customer={customer}
                    attributes={attributes}
                    restaurant={restaurant}
                    right={
                      <TouchableOpacity
                        onPress={() => {
                          if (Platform.OS === 'web') {
                            navigate(
                              `/restaurants/${restaurantId}/customers/${customer.id}`
                            )
                          } else {
                            navigation.navigate('Customer', {
                              customerId: customer.id,
                            })
                          }
                        }}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faAddressBook}
                          size={20}
                          style={{ marginRight: 4 }}
                          color={Colors.primary}
                        />
                        <Text
                          style={{
                            marginLeft: 8,
                            fontSize: 16,
                            color: Colors.primary,
                          }}
                        >
                          {t('顧客情報を見る')}
                        </Text>
                      </TouchableOpacity>
                    }
                  />
                </View>
              )
            })}
          <View
            style={{
              borderTopWidth: 1,
              borderColor: Colors.border,
              marginTop: 24,
              paddingTop: 24,
            }}
          >
            {isSiteControllerReserved && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  columnGap: 4,
                  marginBottom: 16,
                }}
              >
                <Text
                  style={[
                    GOURMET_SITE_PROVIDER_TEXT_STYLE[
                      restaurantReservation
                        .reservation_site_controller_parsed_course
                        .site_controller_parsed_course.site
                    ],
                    {
                      fontSize: 10,
                      fontWeight: '600',
                      textAlign: 'center',
                      paddingVertical: 2,
                      paddingHorizontal: 6,
                    },
                  ]}
                >
                  {
                    GOURMET_SITE_PROVIDER_SOURCE[
                      restaurantReservation
                        .reservation_site_controller_parsed_course
                        .site_controller_parsed_course.site
                    ].label
                  }
                </Text>
              </View>
            )}
            <ReservationCoursesAndMemo
              restaurantId={restaurantId}
              reservationCourses={
                isSiteControllerReserved
                  ? getReservationCoursesFromSiteController(
                      restaurantReservation
                    )
                  : getReservationCourses(reservation_courses)
              }
              allergy={allergy}
              memo={memo}
            />
          </View>
        </ShadowBox>
        <View
          style={{
            marginTop: 32,
            flexDirection: width > sm ? 'row' : 'column-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          {reservationStatus !== 'canceled' && (
            <Button
              style={{ flex: 1, width: width <= sm ? '100%' : undefined }}
              onPress={onPressCancel}
              mode="outline"
              variant="danger-secondary"
              height={buttonHeight}
            >
              {t('予約をキャンセルする')}
            </Button>
          )}
          {reservationStatus !== 'canceled' && (
            <Button
              onPress={onPressEditSeat}
              style={{ flex: 1, width: width <= sm ? '100%' : undefined }}
              mode="outline"
              variant="primary-secondary"
              height={buttonHeight}
            >
              {t('席を変更する')}
            </Button>
          )}
          <Button
            onPress={onPressEdit}
            style={{ flex: 1, width: width <= sm ? '100%' : undefined }}
            height={buttonHeight}
          >
            {t('予約を変更する')}
          </Button>
        </View>

        <ModalCenter
          title={t('予約をキャンセル')}
          onClose={() => {
            setModalVisible(false)
          }}
          isModalVisible={isModalVisible}
        >
          <View
            style={{
              padding: 24,
            }}
          >
            <Text>
              {t(
                'キャンセル料をお客様から徴収するか選択してください。徴収する場合は、お客様のクレジットカードにて決済されます。'
              )}
            </Text>
          </View>
          <RadioGroup
            onChange={(value) => setCharge(value)}
            value={charge}
            mode="vertical"
          >
            <Radio
              value={'true'}
              radioLabel={
                <RadioLabel
                  style={[
                    charge === 'true' && {
                      fontWeight: '600',
                    },
                  ]}
                  value={t('キャンセル料を徴収する')}
                />
              }
              style={{
                padding: 20,
                paddingVertical: 12,
              }}
              radioLabelContainerStyle={[
                {
                  paddingHorizontal: 6,
                },
              ]}
            />
            <Radio
              value={'false'}
              radioLabel={
                <RadioLabel
                  style={[
                    charge === 'false' && {
                      fontWeight: '600',
                    },
                  ]}
                  value={t('キャンセル料を徴収しない')}
                />
              }
              style={{
                padding: 20,
                paddingVertical: 12,
              }}
              radioLabelContainerStyle={[
                {
                  paddingHorizontal: 6,
                },
              ]}
            />
            <Radio
              value={'free'}
              radioLabel={
                <RadioLabel
                  style={[
                    charge === 'free' && {
                      fontWeight: '600',
                    },
                  ]}
                  value={t('キャンセル料・予約手数料を徴収しない')}
                />
              }
              style={{
                padding: 20,
                paddingTop: 12,
              }}
              radioLabelContainerStyle={[
                {
                  paddingHorizontal: 6,
                },
              ]}
            />
            <View
              style={{
                paddingBottom: 24,
                paddingHorizontal: 24,
              }}
            >
              <Text
                style={{
                  fontWeight: '300',
                  fontSize: 14,
                  lineHeight: 21,
                  color: 'rgba(68, 68, 68, 0.6)',
                }}
              >
                {t('お店都合でのキャンセル時にのみご利用ください')}
              </Text>
            </View>
          </RadioGroup>

          <View
            style={{
              borderColor: Colors.border,
              borderTopWidth: 0.5,
              flexDirection: 'row',
              justifyContent: 'center',
              paddingVertical: 24,
            }}
          >
            <Button
              mode="outline"
              variant="danger-secondary"
              height={48}
              onPress={onPressConfirmCancel}
            >
              {t('予約のキャンセルを確定する')}
            </Button>
          </View>
        </ModalCenter>
      </ScrollView>
    </>
  )
}

const ReservationCanceledHeader = React.memo(
  function ReservationCanceledHeader() {
    return (
      <View
        style={{
          backgroundColor: Colors.black60,
          paddingVertical: 4,
          paddingHorizontal: 16,
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontWeight: '600',
            fontSize: 12,
            color: Colors.white,
          }}
        >
          キャンセルされた予約です
        </Text>
      </View>
    )
  }
)
