import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import React from 'react'
import SignIn from 'pages/SignIn/index'
import SignInSendLinks from 'pages/SignIn/SendLinks'

const routes: StackRoutes = {
  Index: {
    title: '',
    fullPath: '/sign_in',
    relativePath: '/',
    exact: true,
    element: <SignIn />,
    screenOptions: {
      headerShown: false,
    },
  },
  SendLinks: {
    title: '',
    fullPath: '/sign_in/send_links',
    relativePath: '/send_links',
    exact: true,
    element: <SignInSendLinks />,
    screenOptions: {
      headerShown: false,
    },
  },
}

export default function SignInStack() {
  return <Stack routes={routes} />
}
