import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { wdayName } from '@hello-ai/ar_shared/src/modules/time'
import { ByCalendarResponse_DateResource_PeriodResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service'
import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

interface ReservationCalendarSummaryProps {
  periods: ByCalendarResponse_DateResource_PeriodResource[]
  selectedDate: string
  visibleMonth: string
  onPress: () => void
}

export function ReservationCalendarSummary({
  periods,
  selectedDate,
  visibleMonth,
  onPress,
}: ReservationCalendarSummaryProps) {
  const isSelectedDateInVisibleMonth = dayjs(selectedDate).isSame(
    dayjs(visibleMonth),
    'month'
  )
  if (!isSelectedDateInVisibleMonth) {
    return (
      <View style={styles.container}>
        <View style={styles.innerEmptyContainer}>
          <Text style={styles.text}>空席情報を表示します</Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.emptyDate}>日付を選択してください</Text>
          <View style={[styles.button, { opacity: 0.4 }]}>
            <Text style={styles.buttonText}>詳細を表示</Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView horizontal style={styles.innerContainer}>
        {periods.map((period, index) => (
          <View
            key={index}
            style={[
              styles.section,
              index !== 0 && styles.sectionBorder,
              !period.vacant && styles.redBackground,
            ]}
          >
            <Text
              style={[styles.sectionTitle, !period.vacant && styles.redText]}
            >
              {period.name}
            </Text>
            <Text
              style={[styles.sectionSubtitle, !period.vacant && styles.redText]}
            >
              {period.description}
            </Text>
          </View>
        ))}
      </ScrollView>
      <View style={styles.footer}>
        <View style={styles.dateContainer}>
          <Text style={styles.date}>
            {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
            {dayjs(selectedDate).format('YYYY年M月D日')}
          </Text>
          <Text style={styles.day}>{wdayName(dayjs(selectedDate).day())}</Text>
        </View>
        <View style={styles.button}>
          <TouchableOpacity onPress={onPress}>
            <Text style={styles.buttonText}>詳細を表示</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white,
    shadowColor: Colors.darkOverlay,
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 16,
    borderRadius: 8,
    elevation: 3,
  },
  innerContainer: {
    flexDirection: 'row',
    padding: 16,
    maxHeight: 87,
  },
  innerEmptyContainer: {
    flexDirection: 'row',
    padding: 16,
    height: 87,
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 8,
    width: 99,
  },
  sectionBorder: {
    borderWidth: 0.5,
    // borderStyle: 'dashed' は "Unsupported dashed / dotted border style" というエラーが出る
    borderColor: Colors.border,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  sectionTitle: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '600',
    color: Colors.black,
  },
  sectionSubtitle: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '300',
    color: Colors.black60,
  },
  redBackground: {
    backgroundColor: Colors.cautionBg,
  },
  redText: {
    color: Colors.caution,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderTopWidth: 0.5,
    borderColor: Colors.border,
  },
  dateContainer: {
    flexDirection: 'column',
  },
  date: {
    fontSize: 18,
    lineHeight: 27,
    fontWeight: '600',
    color: Colors.black,
  },
  emptyDate: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '600',
    color: Colors.black60,
  },
  day: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '300',
    color: Colors.black60,
  },
  text: {
    fontWeight: '300',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black60,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    backgroundColor: Colors.primary,
    borderRadius: 4,
  },
  buttonText: {
    width: 80,
    height: 24,
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    color: Colors.white,
  },
})
