import React, { useState } from 'react'
import type { ReactNode } from 'react'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from 'modules/dayjs'
import { ThemeProvider } from '@material-ui/styles'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import type { DatePickerProps as MuiDatePickerProps } from '@material-ui/pickers'
import DateDayjsAdapter from '@date-io/dayjs'
import { createTheme } from '@material-ui/core'

class localizedUtils extends DateDayjsAdapter {
  getDatePickerHeaderText(date: dayjs.Dayjs): string {
    // eslint-disable-next-line ar-i18n/require-translation-ja
    return date.format('M月D日（dd）')
  }

  getCalendarHeaderText(date: dayjs.Dayjs): string {
    // eslint-disable-next-line ar-i18n/require-translation-ja
    return date.format('YYYY年 MM月')
  }
}

const customDatePickerTheme = createTheme({
  palette: {
    primary: { main: Colors.primary },
  },
  overrides: {
    MuiInput: {
      root: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: 'white',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: { color: 'white' },
      toolbarBtnSelected: { color: 'white' },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        backgroundColor: Colors.primaryBg,
        borderRadius: 16,
        color: Colors.primary,
        display: 'flex',
        alignItems: 'center',
        width: 162,
        height: 34,

        '&>p': {
          top: 5,
        },
      },
    },
  } as any,
})

export default function DatePicker(
  props: MuiDatePickerProps & {
    triggerElement?: (props: { onPress: () => void }) => ReactNode
  }
) {
  const { triggerElement, ...muiProps } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      {triggerElement?.({
        onPress: () => {
          setOpen(true)
        },
      })}
      <ThemeProvider theme={customDatePickerTheme}>
        <MuiPickersUtilsProvider utils={localizedUtils} libInstance={dayjs}>
          <MuiDatePicker
            okLabel={t('確定する')}
            cancelLabel={t('キャンセル')}
            variant={'dialog'}
            {...muiProps}
            {...(triggerElement
              ? {
                  style: {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    visibility: 'hidden',
                  },
                  open,
                  onClose: () => {
                    setOpen(false)
                  },
                }
              : {})}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  )
}
