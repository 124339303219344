import React from 'react'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GOURMET_SITE_PROVIDER_TEXT_STYLE,
  GourmetSiteProvider,
} from '../../../../types/ForR/GourmetSiteSetting'
import { Colors } from '../../../../constants/Colors'
import { Text } from '../../../../components/Text'

export function GourmetSiteProviderLabel({
  provider,
}: {
  provider: GourmetSiteProvider
}) {
  return (
    <Text
      style={[
        {
          fontSize: 10,
          color: Colors.white,
          borderRadius: 2,
          paddingVertical: 2,
          width: 130,
          lineHeight: 10,
          fontWeight: '600',
          textAlign: 'center',
          height: 14,
        },
        GOURMET_SITE_PROVIDER_TEXT_STYLE[provider],
      ]}
    >
      {GOURMET_SITE_PROVIDER_SOURCE[provider].label}
    </Text>
  )
}
