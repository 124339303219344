import React, { useEffect, useMemo, useRef } from 'react'

import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'

import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'
import { Chart, createChartScrollResponder } from '../../Reservations/Chart'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { ReservationFormStepProps } from './types'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Stepper } from '@hello-ai/ar_shared/src/components/Stepper'
import {
  RestaurantBusinessTime as RestaurantBusinessTimeModel,
  useRestaurantBusinessTimesByDate,
} from '../../../models/RestaurantBusinessTime'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'
import { displayToastError } from '../../Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export function validate({
  partySize,
  selectedSeatIds,
  endTime,
  tableSeats,
  restaurantBusinessTimes,
}: {
  partySize: number
  selectedSeatIds: Array<TableSeatModel['id']>
  endTime: number
  tableSeats: TableSeatModel[]
  restaurantBusinessTimes: RestaurantBusinessTimeModel[] | undefined
}) {
  const disabled = false // 席選択なしでも進めるようにする
  const errors: string[] = []

  if (tableSeats != null) {
    const selectedTableSeats = tableSeats.filter((tableSeat) =>
      selectedSeatIds.includes(tableSeat.id)
    )

    let maxPartySize = 0

    for (const tableSeat of selectedTableSeats) {
      if (tableSeat.max_party_size != null) {
        maxPartySize += tableSeat.max_party_size
      }
    }

    if (partySize > maxPartySize) {
      // 詰めれば座れるなどの理由で予約可能にしたい場合があり得るのでdisabledにはしない
      errors.push(
        t('あと{{text}}人分の席を選択してください', {
          text: partySize - maxPartySize,
        })
      )
    }
  }

  if (restaurantBusinessTimes != null) {
    const businessTimesWithinOpeningHours = restaurantBusinessTimes.filter(
      (restaurantBusinessTime) =>
        endTime >= restaurantBusinessTime.start_time &&
        endTime <= restaurantBusinessTime.end_time
    )

    if (businessTimesWithinOpeningHours.length === 0) {
      errors.push(t('営業時間外です'))
    }
    const businessTimesPastLastOrder = businessTimesWithinOpeningHours.filter(
      (restaurantBusinessTime) => {
        if (restaurantBusinessTime.last_order_time == null) return false
        return endTime > restaurantBusinessTime.last_order_time
      }
    )

    if (businessTimesPastLastOrder.length > 0) {
      errors.push(t('ラストオーダーの時間を超えています'))
    }
  }

  return { disabled, errors }
}

const defaultSeatDuration = 2 * 3600 // 2h

export function getDefaultDuration({
  startTime,
  restaurantBusinessTimes,
}: {
  startTime: number
  restaurantBusinessTimes: RestaurantBusinessTimeModel[] | undefined
}) {
  if (restaurantBusinessTimes == null) return defaultSeatDuration

  const businessTimeWithinOpeningHours = restaurantBusinessTimes.find(
    (restaurantBusinessTime) =>
      startTime >= restaurantBusinessTime.start_time &&
      startTime <= restaurantBusinessTime.end_time &&
      restaurantBusinessTime.staying_time != null
  )

  if (businessTimeWithinOpeningHours != null) {
    return businessTimeWithinOpeningHours.staying_time ?? defaultSeatDuration
  }

  return defaultSeatDuration
}

export function SelectSeats({
  restaurantId,
  currentStep,
  skipSteps,
  restaurantReservation,
  state,
  onPressGoBack,
  onPressNext,
}: ReservationFormStepProps & { restaurantId: number }) {
  const { tableSeats } = useTableSeats(restaurantId, {})
  const {
    dateString,
    startTime,
    endTime: defaultEndTime,
    adultPartySize,
    childPartySize,
  } = state
  const date = dayjs(dateString)
  const { width, sm } = useResponsive()
  const partySize = adultPartySize + childPartySize
  const [selectedSeatIds, setSelectedSeatIds] = useFormState<
    Array<TableSeatModel['id']>
  >(state.selectedSeatIds)

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: state.dateString,
    }
  )
  const { restaurantReservations } = useRestaurantReservations(restaurantId, {
    date: state.dateString,
  })

  const [endTime, setEndTime] = useFormState(
    defaultEndTime ||
      startTime + getDefaultDuration({ startTime, restaurantBusinessTimes })
  )

  const ref = useRef<React.ElementRef<typeof Chart>>(null)

  const { setChartRef, reset, chartProps, requestScroll } = useMemo(
    () => createChartScrollResponder(),
    []
  )

  useEffect(() => {
    setChartRef(ref.current)
    return () => {
      reset()
    }
  }, [reset, setChartRef])

  useEffect(() => {
    requestScroll({ time: startTime })
  }, [requestScroll, startTime])

  const { disabled, errors } = useMemo(
    () =>
      validate({
        partySize,
        selectedSeatIds,
        endTime,
        tableSeats,
        restaurantBusinessTimes,
      }),
    [endTime, partySize, restaurantBusinessTimes, selectedSeatIds, tableSeats]
  )

  useEffect(() => {
    // NOTE: 日付変更時に席が他予約とブッキングしている場合は初期選択から外す
    if (restaurantReservations == null) return
    const startAt = dayjs(dateString).startOf('day').add(startTime, 'seconds')
    const initialSeatIds = [...state.selectedSeatIds]
    restaurantReservations.forEach((reservation) => {
      if (reservation.id === restaurantReservation?.id) return null
      if (!dayjs(reservation.start_at).isSame(startAt)) return false // 開始時刻が同じでないと席交換の対象にならないため外す

      reservation.table_seats.forEach((seat) => {
        const findIndex = initialSeatIds.indexOf(seat.id)
        if (findIndex !== -1) {
          initialSeatIds.splice(findIndex, 1)
        }
      })
    })
    if (initialSeatIds.length !== state.selectedSeatIds.length) {
      setSelectedSeatIds(initialSeatIds)
    }
  }, [
    restaurantReservations,
    dateString,
    startTime,
    state.selectedSeatIds,
    setSelectedSeatIds,
    restaurantReservation,
  ])

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  return (
    <Stepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={() =>
        onPressNext({
          endTime,
          selectedSeatIds,
        })
      }
      isDisabledNextPress={disabled}
    >
      <Chart
        restaurantId={restaurantId}
        ref={ref}
        date={date}
        mode="selectSeats"
        selectParams={{
          restaurantReservation,
          startTime,
          endTime,
          setEndTime,
          partySize,
          selectedSeatIds,
          onChangeSelectedSeatIds: setSelectedSeatIds,
        }}
        scrollViewProps={{
          style: {
            backgroundColor: width < sm ? Colors.bgLightBlack : Colors.white,
            flex: 1,
          },
        }}
        {...chartProps}
      />
    </Stepper>
  )
}
