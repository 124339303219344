import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useRestaurantId } from 'modules/useRestaurantId'
import React from 'react'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { useNavigate } from 'modules/router/useNavigate'
import CrewMembersEditView from '@hello-ai/for_r_app/src/components/Restaurant/CrewMembers/EditView'

export default function CrewMembersEdit() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  return (
    <CrewMembersEditView
      restaurantId={restaurantId}
      onSuccessfulSubmit={() => {
        displayToastSuccess(t('更新しました'))
        navigate(`/restaurants/${restaurantId}/crew_members`)
      }}
    />
  )
}
