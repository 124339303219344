import React from 'react'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import ContractsScreen from 'pages/Other'

import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  index: {
    title: t('契約状況'),
    fullPath: `/restaurants/:id/other`,
    relativePath: `/`,
    exact: true,
    element: <ContractsScreen />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
}

export default function OtherStack() {
  return <Stack routes={routes} />
}
