import React, { useState } from 'react'
import { View } from 'react-native'
import { t } from '../../../../modules/i18n'
import { Text } from '../../../../components/Text'
import {
  AuthenticationForSite,
  GOURMET_SITE_PROVIDER_SOURCE,
} from '../../../../types/ForR/GourmetSiteSetting'
import {
  FormGroup,
  FormLabel,
  FormLabelWithHelper,
} from '../../../../components/Form'
import { TextInput } from '../../../../components/TextInput'

import { Step, Stepper } from '../../../../components/Stepper'
import { KeyboardAwareScrollView } from '../../../../components/KeyboardAwareScrollView'
import { Loading } from '../../../../components/Loading'

interface ProviderLoginFormProps {
  provider: keyof typeof GOURMET_SITE_PROVIDER_SOURCE
  steps: Step[]
  currentStepNumber: number
  defaultLoginId?: string
  onPressNext: (params: AuthenticationForSite) => Promise<void>
  headerHeight?: number
}

function LoadingOverlay() {
  return (
    <View
      style={[
        { flex: 1 },
        {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255,  255, 255,  0.8)',
        },
      ]}
    >
      <Loading />
    </View>
  )
}

function getDescription(provider: keyof typeof GOURMET_SITE_PROVIDER_SOURCE) {
  switch (provider) {
    case 'ikkyu':
      return t(
        '{{provider_name}}で登録されているアカウント情報と同じ店舗ID・オペレーターID・パスワードを入力してください。\n違うアカウント情報を入力した場合は連携エラーとなります。',
        {
          provider_name:
            GOURMET_SITE_PROVIDER_SOURCE[provider].authenticateLabel,
        }
      )
    case 'hotpepper':
      return t(
        '{{provider_name}}で登録されているアカウント情報と同じAirIDまたはメールアドレス・パスワードを入力してください。違うアカウント情報を入力した場合は連携エラーとなります。',
        {
          provider_name:
            GOURMET_SITE_PROVIDER_SOURCE[provider].authenticateLabel,
        }
      )
    default:
      return t(
        '{{provider_name}}の店舗管理画面で登録されているアカウント情報と同じログインID・パスワードを入力してください。\n違うアカウント情報を入力した場合は連携エラーとなります。',
        {
          provider_name:
            GOURMET_SITE_PROVIDER_SOURCE[provider].authenticateLabel,
        }
      )
  }
}

function getLoginIdLabel(provider: keyof typeof GOURMET_SITE_PROVIDER_SOURCE) {
  switch (provider) {
    case 'ikkyu':
      return t('オペレーターID')
    case 'hotpepper':
      return t('AirIDまたは\nメールアドレス')
    default:
      return t('ログインID')
  }
}

export default function ProviderLoginForm({
  provider,
  steps,
  currentStepNumber,
  defaultLoginId,
  onPressNext,
  headerHeight,
}: ProviderLoginFormProps) {
  const [loginId, setLoginId] = useState(defaultLoginId ?? '')
  const [password, setPassword] = useState('')
  /**
   * 一休向けの店舗ID
   */
  const [siteRestaurantId, setSiteRestaurantId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const isDisabledNextPress = loginId === '' || password === '' || isLoading

  return (
    <Stepper
      steps={steps}
      currentStepNumber={currentStepNumber}
      errors={[]}
      isDisabledNextPress={isDisabledNextPress}
      onPressNext={async () => {
        try {
          setIsLoading(true)
          switch (provider) {
            case 'tabelog':
            case 'tabelog_note': {
              await onPressNext({
                site: 'tabelog_note',
                signin_id: loginId,
                password,
              })
              break
            }
            case 'ikkyu':
              await onPressNext({
                site: 'ikkyu',
                signin_id: loginId,
                site_restaurant_id: siteRestaurantId,
                password,
              })
              break
            case 'hotpepper':
              await onPressNext({
                site: 'hotpepper',
                signin_id: loginId,
                password,
              })
              break
            case 'gnavi':
              await onPressNext({
                site: 'gnavi',
                signin_id: loginId,
                password,
              })
              break
          }
        } finally {
          setIsLoading(false)
        }
      }}
    >
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: '#fff' }}
        contentContainerStyle={{
          flex: 1,
          paddingHorizontal: '5%',
          paddingVertical: 40,
        }}
        extraScrollHeight={headerHeight}
      >
        <View>
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
              lineHeight: 33,
              marginBottom: 40,
            }}
          >
            {t('ログイン情報を入力します')}
          </Text>
          <Text>{getDescription(provider)}</Text>
          <View style={{ marginTop: 64, rowGap: 24, paddingHorizontal: 82 }}>
            {provider === 'ikkyu' && (
              <FormGroup
                formLabel={
                  <FormLabelWithHelper
                    FormLabelProps={{
                      value: t('店舗ID'),
                    }}
                    helperText={t(
                      '{{provider_name}}の管理画面にログインする際の設定中の店舗IDです',
                      {
                        provider_name:
                          GOURMET_SITE_PROVIDER_SOURCE[provider]
                            .authenticateLabel,
                      }
                    )}
                  />
                }
              >
                <TextInput
                  value={siteRestaurantId}
                  onChangeText={setSiteRestaurantId}
                  placeholder="1234567890"
                />
              </FormGroup>
            )}
            <FormGroup
              formLabel={
                <FormLabelWithHelper
                  FormLabelProps={{
                    value: getLoginIdLabel(provider),
                  }}
                  helperText={t(
                    '{{provider_name}}の管理画面にログインする際の設定中のIDです',
                    {
                      provider_name:
                        GOURMET_SITE_PROVIDER_SOURCE[provider]
                          .authenticateLabel,
                    }
                  )}
                />
              }
            >
              <TextInput
                value={loginId}
                onChangeText={setLoginId}
                placeholder="1234567890"
              />
            </FormGroup>
            <FormGroup formLabel={<FormLabel value={t('パスワード')} />}>
              <TextInput
                value={password}
                onChangeText={setPassword}
                placeholder="※※※※※※※"
                secureTextEntry
              />
            </FormGroup>
          </View>
        </View>
      </KeyboardAwareScrollView>
      {isLoading && <LoadingOverlay />}
    </Stepper>
  )
}
