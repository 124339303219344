import React from 'react'
import { Customer } from '@hello-ai/for_r_app/src/components/Customers/Show'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useParams } from 'react-router-dom'

export default function CustomerPage() {
  const restaurantId = useRestaurantId()
  const { customerId } = useParams<{
    customerId: string
  }>()
  return <Customer restaurantId={restaurantId} customerId={customerId!} />
}
