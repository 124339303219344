import React from 'react'
import { OutboundSettingData } from '../../../../types/ForR/OutboundSetting'
import { View } from 'react-native'
import { Text } from '../../../../components/Text'
import { FormGroup, FormLabel } from '../../../../components/Form'
import { Switch } from '../../../../components/Switch'
import { Button } from '../../../../components/Button'
import { GOURMET_SITE_PROVIDER_SOURCE } from '../../../../types/ForR/GourmetSiteSetting'
import { Colors } from '../../../../constants/Colors'
import OutboundIndexTitle from './OutboundIndexTitle'
import { t } from '../../../../modules/i18n'

interface OutboundSettingProps {
  outboundSetting: OutboundSettingData
  onChangeEnabled: (enabled: boolean) => void
  onPressEditLoginInfo: () => void
}

export default function OutboundSettingView({
  outboundSetting,
  onChangeEnabled,
  onPressEditLoginInfo,
}: OutboundSettingProps) {
  return (
    <View>
      <OutboundIndexTitle />
      <View
        style={{
          backgroundColor: Colors.white,
          paddingVertical: 48,
          paddingHorizontal: 32,
          borderRadius: 8,
          rowGap: 32,
        }}
      >
        <FormGroup
          mode="inline"
          formLabel={
            <FormLabel
              value={t('連携ステータス')}
              labelStyle={{ fontSize: 18 }}
            />
          }
        >
          <Text
            style={[
              {
                padding: 8,
                borderRadius: 2,
                fontSize: 14,
                fontWeight: '600',
                alignSelf: 'flex-start',
              },
              outboundSetting.enabled
                ? {
                    color: Colors.success,
                    backgroundColor: Colors.accentBg,
                  }
                : {
                    color: Colors.caution,
                    backgroundColor: Colors.cautionBg,
                  },
            ]}
          >
            {outboundSetting.enabled ? t('連携中') : t('連携停止中')}
          </Text>
        </FormGroup>
        <FormGroup
          mode="inline"
          formLabel={
            <FormLabel
              value={t('連携停止する')}
              labelStyle={{ fontSize: 18 }}
            />
          }
        >
          <Switch
            value={outboundSetting.enabled}
            onValueChange={onChangeEnabled}
          />
          <Text
            style={{
              fontSize: 14,
              fontWeight: '300',
              color: Colors.black60,
              marginTop: 8,
            }}
          >
            {t(
              '連携初期設定を保持した状態で一時的に連携を停止することができます'
            )}
          </Text>
        </FormGroup>
        <View
          style={{
            backgroundColor: Colors.black6,
            borderRadius: 4,
            paddingHorizontal: 16,
          }}
        >
          <View
            style={{
              paddingVertical: 16,
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              style={{ fontSize: 14, fontWeight: '600', color: Colors.black }}
            >
              {t('設定中のログイン情報')}
            </Text>
            <Button
              mode="outline"
              style={{
                paddingHorizontal: 16,
                paddingVertical: 6,
                alignSelf: 'flex-start',
              }}
              onPress={onPressEditLoginInfo}
            >
              {t('設定中のログイン情報を編集する')}
            </Button>
          </View>
          <View style={{ paddingVertical: 24, rowGap: 20 }}>
            <FormGroup
              formLabel={<FormLabel value={t('他社グルメサイト名')} />}
            >
              <Text style={{ fontSize: 18, fontWeight: '300' }}>
                {
                  GOURMET_SITE_PROVIDER_SOURCE[outboundSetting.site]
                    .authenticateLabel
                }
              </Text>
            </FormGroup>
            <FormGroup formLabel={<FormLabel value={t('ログインID')} />}>
              <Text style={{ fontSize: 18, fontWeight: '300' }}>
                {outboundSetting.signin_id}
              </Text>
            </FormGroup>
          </View>
        </View>
      </View>
    </View>
  )
}
