import { TableMenuPage as TableMenuPageModel } from 'models/TableMenuPage'
import { View, StyleProp, ViewStyle } from 'react-native'
import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import React from 'react'

import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { goBack } from 'modules/history'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

type ChangeTableMenuPageStatus = ({
  tableMenuPageId,
  params,
}: {
  tableMenuPageId: string
  params: { status: string }
}) => Promise<void>

function RestoreButton({
  tableMenuPageId,
  onChangeStatus,
}: {
  tableMenuPageId: string
  onChangeStatus: ChangeTableMenuPageStatus
}) {
  const alertPublish = () => {
    const params = {
      status: 'published',
    }
    Alert.alert(t('本当に公開状態に戻しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          await onChangeStatus({ tableMenuPageId, params })
          goBack('..')
        },
      },
    ])
  }

  return (
    <Button
      mode="outline"
      variant="danger"
      style={{
        borderColor: Colors.caution,
        backgroundColor: 'transparent',
        height: 48,
        width: 124,
      }}
      onPress={() => alertPublish()}
    >
      {t('元に戻す')}
    </Button>
  )
}

function TableMenuPageListItem({
  onPress,
  disabled,
  style,
  isArchived,
  children,
}: {
  onPress: () => void
  disabled: boolean
  style?: StyleProp<ViewStyle>
  isArchived?: boolean
  children: React.ReactNode
}) {
  if (isArchived) {
    return <View style={style}>{children}</View>
  }
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled} style={style}>
      {children}
    </TouchableOpacity>
  )
}

export default function TableMenuPage({
  tableMenuPage,
  locked,
  onPress,
  onChangeStatus,
  isArchived,
}: {
  tableMenuPage: TableMenuPageModel
  locked: boolean
  onPress: (tableMenuPageId: string) => void
  onChangeStatus?: ChangeTableMenuPageStatus
  isArchived?: boolean
}) {
  return (
    <View
      style={{
        position: 'relative',
      }}
    >
      <TableMenuPageListItem
        onPress={() => onPress(tableMenuPage.id)}
        disabled={locked && tableMenuPage.group_managed}
        style={{
          padding: 24,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        isArchived={isArchived}
      >
        <View>
          <Text style={{ fontWeight: '600' }}>{tableMenuPage.name}</Text>
          {tableMenuPage.table_menus &&
            tableMenuPage.table_menus.length > 0 && (
              <View
                style={{
                  marginTop: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {tableMenuPage.table_menus.map((tableMenu, index) => {
                  return (
                    <Text
                      key={tableMenu.id}
                      style={{ marginLeft: index === 0 ? 0 : 4 }}
                    >
                      {tableMenu.name}
                    </Text>
                  )
                })}
              </View>
            )}
        </View>
        {locked && tableMenuPage.group_managed && (
          <FontAwesomeIcon icon={faLockAlt} color={Colors.black60} size={16} />
        )}
        {!tableMenuPage.group_managed && onChangeStatus && (
          <RestoreButton
            tableMenuPageId={tableMenuPage.id}
            onChangeStatus={onChangeStatus}
          />
        )}
      </TableMenuPageListItem>
      {tableMenuPage?.table_menu_pages?.map((childTableMenuPage) => {
        return (
          <TouchableOpacity
            key={childTableMenuPage.id}
            disabled={locked && childTableMenuPage.group_managed}
            onPress={() => onPress(childTableMenuPage.id)}
            style={{
              borderTopWidth: 0.5,
              borderColor: Colors.border,
              paddingVertical: 24,
              marginLeft: 48,
              paddingRight: 24,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <View>
              <Text style={{ fontWeight: '600' }}>
                {childTableMenuPage.name}
              </Text>
              {childTableMenuPage.table_menus &&
                childTableMenuPage.table_menus.length > 0 && (
                  <View
                    style={{
                      marginTop: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {childTableMenuPage.table_menus.map((tableMenu, index) => {
                      return (
                        <Text
                          key={tableMenu.id}
                          style={{ marginLeft: index === 0 ? 0 : 4 }}
                        >
                          {tableMenu.name}
                        </Text>
                      )
                    })}
                  </View>
                )}
            </View>
            {locked && childTableMenuPage.group_managed && (
              <FontAwesomeIcon
                icon={faLockAlt}
                color={Colors.black60}
                size={16}
              />
            )}
            {!childTableMenuPage.group_managed && onChangeStatus && (
              <RestoreButton
                tableMenuPageId={childTableMenuPage.id}
                onChangeStatus={onChangeStatus}
              />
            )}
            {onChangeStatus === undefined && (
              <View
                style={{
                  position: 'absolute',
                  top: 4,
                  right: 16,
                }}
              >
                <Text style={{ fontSize: 14, color: Colors.black60 }}>
                  id: {childTableMenuPage.id}
                </Text>
              </View>
            )}
          </TouchableOpacity>
        )
      })}
      {onChangeStatus === undefined && (
        <View
          style={{
            position: 'absolute',
            top: 4,
            right: 16,
          }}
        >
          <Text style={{ fontSize: 14, color: Colors.black60 }}>
            id: {tableMenuPage.id}
          </Text>
        </View>
      )}
    </View>
  )
}
