/* eslint-disable ar-i18n/require-translation-ja */
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'

import { times } from 'lodash'
import React, { useState } from 'react'
import { View } from 'react-native'
import { RestaurantTabelogAwardStatus } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { getTabelogAwardLabel } from './common'
import { onError } from '@hello-ai/ar_shared/src/modules/auth'

const latestYear = (() => {
  const now = new Date()
  return now.getMonth() >= 4 ? now.getFullYear() : now.getFullYear() - 1
})()

const Years = (() => {
  const diffYears = latestYear - 2007
  return times(diffYears + 1, (i) => {
    return {
      label: `${latestYear - i}年`,
      value: latestYear - i,
    }
  })
})()

export function TabelogAddForm({
  requestTabelogAwards,
}: {
  requestTabelogAwards: (
    awards: Omit<RestaurantTabelogAwardStatus, 'status'>[]
  ) => Promise<void>
}) {
  const [loading, setLoading] = useState(false)
  const [draftTabelogAwards, setDraftTabelogAwards] = useState<
    Partial<Omit<RestaurantTabelogAwardStatus, 'status'>>[]
  >([
    {
      year: undefined,
      category: undefined,
    },
  ])

  return (
    <>
      {draftTabelogAwards.map((award, index) => (
        <View
          key={index}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <FormGroup
            formLabel={<FormLabel value="受賞年度" />}
            style={{ marginTop: 24, flex: 1 }}
            containerStyle={{ flex: 1, flexBasis: 'auto' }}
          >
            <SelectInput
              selectedValue={award.year}
              setValue={(value) => {
                setDraftTabelogAwards((prev) => {
                  prev[index].year = value
                  return [...prev]
                })
              }}
              items={[
                { label: '選択してください', value: undefined },
                ...Years,
              ]}
            />
          </FormGroup>
          <FormGroup
            formLabel={<FormLabel value="ランク" />}
            style={{ marginTop: 24, marginLeft: 24, flex: 1 }}
            containerStyle={{ flex: 1, flexBasis: 'auto' }}
          >
            <SelectInput
              selectedValue={award.category}
              setValue={(value) => {
                setDraftTabelogAwards((prev) => {
                  prev[index].category = value
                  return [...prev]
                })
              }}
              items={[
                { label: '選択してください', value: undefined },
                ...(
                  [
                    'bronze',
                    'silver',
                    'gold',
                  ] as RestaurantTabelogAwardStatus['category'][]
                ).map((status) => ({
                  value: status,
                  label: getTabelogAwardLabel(status),
                })),
              ]}
            />
          </FormGroup>
        </View>
      ))}
      <Button
        mode="contained"
        height={44}
        style={{ marginTop: 24, width: '100%' }}
        disabled={loading}
        loading={loading}
        onPress={async () => {
          setLoading(true)
          if (
            draftTabelogAwards.some(
              (award) => award.year == null || award.category == null
            )
          ) {
            onError(new Error('未選択の項目があります'))
            setLoading(false)
            return
          }
          await requestTabelogAwards(
            draftTabelogAwards as Omit<RestaurantTabelogAwardStatus, 'status'>[]
          ).finally(() => {
            setLoading(false)
          })
        }}
      >
        追加を申請する
      </Button>
    </>
  )
}
