import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, { fetcher, onError, swrKey, mutate } from '../modules/swr'
import { useToken } from './Auth'
import { TableMenu } from './TableMenu'
import { AxiosError } from 'axios'

import {
  RestaurantTableProducts,
  TableProduct,
  FailedRowMessage,
} from '@hello-ai/ar_shared/src/types/ForR/TableProduct'
export * from '@hello-ai/ar_shared/src/types/ForR/TableProduct'

async function customFetcher<T = any, P extends {} = {}>([token, url, params]: [
  token: string | null,
  url: string,
  params?: P
]) {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  if (response.data != null) {
    const currentPage = response.headers['x-current-page'] ?? '0'
    const totalPages = response.headers['x-total-pages'] ?? '0'
    const totalCount = response.headers['x-total-count'] ?? '0'
    return {
      tableProducts: response.data,
      headerData: {
        currentPage: Number(currentPage),
        totalPages: Number(totalPages),
        totalCount: Number(totalCount),
      },
    }
  }
  return null
}

export function useRestaurantTableProducts({
  restaurantId,
  params,
}: {
  restaurantId: number
  params?: {
    page: number
    keyword?: string
    status?: string
    in_stock?: boolean
  }
}) {
  const token = useToken()
  const keys = swrKey(token, `/restaurants/${restaurantId}/products`, params)
  const { data, error, mutate } = useSWR<
    RestaurantTableProducts | null,
    any,
    ReturnType<typeof swrKey>
  >(
    keys,
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string
    ]) => customFetcher([token, url, JSON.parse(params)])
  )

  return {
    tableProducts: data ? data.tableProducts : null,
    headerData: data ? data.headerData : null,
    isLoading: data == null && error == null,
    mutate,
  }
}

export function useTableProduct({
  tableProductId,
}: {
  tableProductId?: string
}) {
  const token = useToken()
  const {
    data: tableProduct,
    error,
    mutate,
  } = useSWR<TableProduct, any, ReturnType<typeof swrKey> | null>(
    tableProductId != null
      ? swrKey(token, `/products/${tableProductId}`)
      : null,
    fetcher
  )

  if (tableProductId == null) {
    return { tableProduct, isLoading: false, mutate }
  }

  return {
    tableProduct,
    isLoading: tableProduct == null && error == null,
    mutate,
  }
}

export type UpdateTableProductFuncType = (
  tableProductId: string,
  params?: any
) => void

interface UpdateTableProductStockParams {
  restaurant_id?: number
  out_of_stock_at?: string | null
}

export type UpdateTableProductStockFuncType = (
  tableProductId: string,
  params: UpdateTableProductStockParams
) => void

export async function updateTableProduct({
  token,
  tableProductId,
  params,
}: {
  token: string
  tableProductId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<TableProduct>(`/products/${tableProductId}`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(swrKey(token, `/products/${tableProductId}`), response.data, false)
  }

  return {
    tableProduct: response?.data,
  }
}

export async function createTableProduct({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableProduct>(`/restaurants/${restaurantId}/products`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/products/${response?.data?.id}`),
      response.data,
      false
    )
  }

  return {
    tableProduct: response?.data,
  }
}

export async function updateTableProductStock({
  token,
  tableProductId,
  params,
}: {
  token: string
  tableProductId: string
  params: UpdateTableProductStockParams
}) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post(`/products/${tableProductId}/product_stock`, params)
  )

  if (error != null) {
    onError(error)
  }
  return { error }
}

export function useMenuRestaurantGroupProducts(restaurantGroupId: string) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    TableProduct[],
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/menu_restaurant_groups/${restaurantGroupId}/products`),
    fetcher
  )

  return {
    data: data ?? [],
    isLoading: data == null && error == null,
    error,
    mutate,
  }
}

export async function createMenuRestaurantGroupProduct({
  restaurantGroupId,
  token,
  params,
}: {
  restaurantGroupId: string
  token: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableMenu>(
      `/menu_restaurant_groups/${restaurantGroupId}/products`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/menu_restaurant_groups/${restaurantGroupId}/products/${response?.data?.id}`,
        params
      ),
      response.data,
      false
    )
  }

  return {
    tableProduct: response?.data,
  }
}

export async function importCSV({
  restaurantId,
  file,
}: {
  restaurantId: number
  file: File
}) {
  const formData = new FormData()
  formData.append('csv', file)

  const { response, error } = await wrapResponse<{
    successful_products: TableProduct[]
    failed_rows: FailedRowMessage[]
    error?: string
  }>(
    axios.post(`/restaurants/${restaurantId}/products/import_csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  )

  if (error !== undefined) {
    const { response: errorResponse } = error as AxiosError
    if (errorResponse?.status === 422) {
      return {
        successfulProducts: [],
        failedRows: [],
        // @ts-expect-error
        error: errorResponse?.data.error ?? undefined,
      }
    }

    return {
      // @ts-expect-error
      successfulProducts: errorResponse?.data?.successful_products ?? [],
      // @ts-expect-error
      failedRows: errorResponse?.data?.failed_rows ?? [],
      error: undefined,
    }
  }

  return {
    successfulProducts: response?.data?.successful_products ?? [],
    failedRows: response?.data?.failed_rows ?? [],
  }
}
