import React, { ComponentProps, useState } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { Text } from './Text'
import { Colors } from '../constants/Colors'
import { useResponsive } from '../modules/useResponsive'

import { t } from '../modules/i18n'
import { TouchableOpacity } from './Touchables'
import { FontAwesomeIcon } from './FontAwesomeIcon'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { Popover } from './Popover'

export function FormDivider({ style }: { style?: StyleProp<ViewStyle> }) {
  return (
    <View
      style={[
        {
          height: 1,
          backgroundColor: Colors.border,
          width: '100%',
        },
        style,
      ]}
    />
  )
}

export function FormLabel({
  mode = 'default',
  value,
  required = false,
  icon,
  labelStyle,
  rightIcon,
}: {
  mode?: 'default' | 'secondary'
  value: string
  required?: boolean
  icon?: React.ReactNode
  labelStyle?: StyleProp<TextStyle>
  rightIcon?: React.ReactNode
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {icon}
      <Text
        style={[
          mode === 'default' && {
            fontWeight: '600',
          },
          icon != null && {
            marginLeft: 8,
          },
          labelStyle,
        ]}
      >
        {value}
      </Text>
      {required && (
        <View
          style={{
            marginLeft: 6,
            paddingHorizontal: 3,
            paddingVertical: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.caution,
            borderRadius: 2,
          }}
        >
          <Text
            style={[
              {
                color: 'white',
                fontSize: 10,
                fontWeight: '600',
              },
            ]}
          >
            {t('必須')}
          </Text>
        </View>
      )}
      {rightIcon}
    </View>
  )
}

export function FormGroup({
  mode = 'inline',
  style,
  formLabel,
  formLabelContainerStyle = {},
  containerStyle = {},
  children,
}: {
  mode?: 'inline' | 'inline-expanded' | 'vertical'
  style?: StyleProp<ViewStyle>
  formLabel?: React.ReactNode
  formLabelContainerStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  const { width, md } = useResponsive()
  return (
    <View
      style={[
        (mode === 'inline' || mode === 'inline-expanded') && {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        style,
      ]}
    >
      <View
        style={[
          mode === 'inline' && {
            justifyContent: 'center',
          },
          mode !== 'vertical' && {
            flex: 1,
          },
          formLabelContainerStyle,
        ]}
      >
        {formLabel}
      </View>
      <View
        style={[
          mode === 'vertical' && {
            marginTop: width >= md ? 24 : 16,
          },
          mode !== 'vertical' && {
            flex: 3,
          },
          containerStyle,
        ]}
      >
        {children}
      </View>
    </View>
  )
}

export function FormLabelWithHelper({
  helperText,
  FormLabelProps,
  style,
}: {
  helperText: string
  FormLabelProps: ComponentProps<typeof FormLabel>
  style?: StyleProp<ViewStyle>
}) {
  const [isOpenPop, setIsOpenPop] = useState(false)

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 8,
        },
        style,
      ]}
    >
      <FormLabel {...FormLabelProps} />
      <Popover
        isOpen={isOpenPop}
        onClose={() => setIsOpenPop(false)}
        placement="bottomEnd"
        anchor={
          <TouchableOpacity onPress={() => setIsOpenPop(true)}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size={24}
              color={Colors.primary}
            />
          </TouchableOpacity>
        }
      >
        <Text
          style={{
            width: 400,
            fontWeight: '300',
            color: Colors.black,
            padding: 24,
            backgroundColor: Colors.white,
          }}
        >
          {helperText}
        </Text>
      </Popover>
    </View>
  )
}
