import React from 'react'
import { GestureResponderEvent, StyleProp, View, ViewStyle } from 'react-native'

import { TouchableOpacity } from './Touchables'
import { Text } from './Text'
import { Colors } from '../constants/Colors'

export function SegmentedControl({
  buttons,
  style,
  disabled,
}: {
  buttons: Array<{
    onPress: (event: GestureResponderEvent) => void
    selected: boolean
    text: string
  }>
  style?: StyleProp<ViewStyle>
  disabled?: boolean
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          borderWidth: 1,
          borderColor: Colors.accent,
          borderRadius: 8,
          opacity: disabled ? 0.6 : 1.0,
          overflow: 'hidden',
        },
        style,
      ]}
    >
      {buttons.map((button, index) => {
        return (
          <TouchableOpacity
            key={index}
            disabled={disabled}
            style={{
              flex: 1,
              height: 48,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: button.selected ? Colors.accent : 'transparent',
              borderLeftWidth: index === 0 ? 0 : 1,
              borderColor: Colors.accent,
            }}
            onPress={button.onPress}
          >
            <Text
              style={{
                color: button.selected ? 'white' : Colors.black,
                fontWeight: button.selected ? 'bold' : 'normal',
              }}
            >
              {button.text}
            </Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}
