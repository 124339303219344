import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigate } from 'react-router'
import { headerHeight } from './Header'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { RestaurantSwitcherModal } from 'components/Restaurant/RestaurantSwitcherModal'
import useClickOutside from 'modules/useClickOutside'

export function DrawerHeader({
  title,
  isRestaurant,
  isAdmin,
  iconLeft,
  iconRight,
  onPressSignOut,
}: {
  title: string
  isRestaurant?: boolean
  isAdmin?: boolean
  iconLeft: React.ReactNode
  iconRight?: React.ReactNode
  onPressSignOut: () => void
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const navigate = useNavigate()
  const drawerHeaderRef = React.useRef<View>(null)
  useClickOutside(
    drawerHeaderRef as unknown as React.RefObject<HTMLDivElement>,
    () => {
      if (!isMenuVisible) return
      setIsMenuVisible(false)
    }
  )

  return (
    <View style={{ zIndex: 10 }} ref={drawerHeaderRef}>
      <TouchableOpacity
        onPress={() => {
          setIsMenuVisible((prevIsMenuVisible) => !prevIsMenuVisible)
        }}
        onPressMinInterval={0}
      >
        <View
          style={{
            height: headerHeight,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottomWidth: 0.5,
            borderColor: Colors.border,
            paddingHorizontal: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {iconLeft}
            <Text
              numberOfLines={2}
              style={{
                flex: 1,
                marginLeft: 12,
                color: Colors.black,
                fontWeight: '600',
              }}
              selectable={false}
            >
              {title}
            </Text>
          </View>
          {iconRight}
        </View>
      </TouchableOpacity>
      <View
        style={{
          width: 200,
          position: 'absolute',
          bottom:
            isRestaurant && isAdmin
              ? -160
              : isRestaurant || isAdmin
                ? -120
                : -80,
          backgroundColor: Colors.white,
          borderRadius: 8,
          marginHorizontal: 8,
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.1,
          shadowRadius: 2,
          transition: 'opacity 0.15s',
          opacity: isMenuVisible ? 1 : 0,
          // for animation we need the element to exist even if it's not visible
          // so we use pointerEvents to prevent it from being interacted with
          pointerEvents: isMenuVisible ? 'auto' : 'none',
        }}
      >
        {isRestaurant && (
          <>
            <TouchableOpacity
              style={{
                padding: 12,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onPress={() => {
                setIsModalVisible(true)
              }}
            >
              <Text style={{ fontSize: 14, color: Colors.primary }}>
                {t('レストラン切り替え')}
              </Text>
              <FontAwesomeIcon
                icon={faAngleRight}
                size={12}
                color={Colors.primary}
              />
            </TouchableOpacity>
            <View
              style={{
                marginHorizontal: 12,
                height: 1,
                backgroundColor: Colors.border,
              }}
            />
          </>
        )}
        {isAdmin && (
          <>
            <TouchableOpacity
              style={{
                padding: 12,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onPress={() => {
                setIsMenuVisible(false)
                navigate('/')
              }}
            >
              <Text style={{ fontSize: 14, color: Colors.primary }}>
                {t('Web管理画面へ')}
              </Text>
              <FontAwesomeIcon
                icon={faAngleRight}
                size={12}
                color={Colors.primary}
              />
            </TouchableOpacity>
            <View
              style={{
                marginHorizontal: 12,
                height: 1,
                backgroundColor: Colors.border,
              }}
            />
          </>
        )}
        <TouchableOpacity
          style={{
            padding: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onPress={() => {
            setIsMenuVisible(false)
            navigate('/business_user/account/edit')
          }}
        >
          <Text style={{ fontSize: 14, color: Colors.primary }}>
            {t('あなたのアカウント')}
          </Text>
          <FontAwesomeIcon
            icon={faAngleRight}
            size={12}
            color={Colors.primary}
          />
        </TouchableOpacity>
        <View
          style={{
            marginHorizontal: 12,
            height: 1,
            backgroundColor: Colors.border,
          }}
        />
        <TouchableOpacity
          style={{
            padding: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onPress={() => onPressSignOut()}
        >
          <Text style={{ fontSize: 14, color: Colors.caution }}>
            {t('ログアウト')}
          </Text>
        </TouchableOpacity>
      </View>
      <RestaurantSwitcherModal
        isModalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}
      />
    </View>
  )
}
