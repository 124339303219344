import React from 'react'
import { View } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { Trans } from 'react-i18next'

export default function GroupCautionMessage() {
  return (
    <View
      style={{
        flex: 1,
        padding: 20,
        alignItems: 'center',
        backgroundColor: Colors.cautionBg,
        borderRadius: 5,
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          marginRight: 20,
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={18}
          color="#E84733"
        />
      </View>
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        {/* eslint-disable ar-i18n/require-translation-ja */}
        <Trans
          i18nKey={
            '<0>グループ管理されています。</0><1><0></0>のついたメニューやカテゴリーは編集できません。</1>'
          }
        >
          <Text
            style={{
              lineHeight: 24,
            }}
          >
            {'グループ管理されています。'}
          </Text>
          <Text
            style={{
              lineHeight: 24,
            }}
          >
            <FontAwesomeIcon
              icon={faLockAlt}
              size={16}
              color={Colors.black60}
            />
            {'のついたメニューやカテゴリーは編集できません。'}
          </Text>
        </Trans>
        {/* eslint-enable ar-i18n/require-translation-ja */}
      </View>
    </View>
  )
}
