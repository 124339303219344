import { Navigation } from './types'

// web では useNavigation は使わないので無を返す
export const useNavigation: () => Navigation = () => ({
  navigate: () => {},
  replace: () => {},
  goBack: () => {},
  canGoBack: () => false,
  pop: () => {},
  setOptions: () => {},
})
