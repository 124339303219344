import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { View } from 'react-native'
import VerifyCodeInput from 'components/Shared/VerifyCodeInput'
import React, { useState, useMemo } from 'react'
import { StepItem } from '../Stepper'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { SMS_PHONE_NUMBER_CODE_LENGTH } from 'models/Auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface VerifySMSCodeFormProps {
  dialCode: string
  phoneNumber: string
  onPressBack: () => void
  onPressSubmit: (code: string) => void
  onPressReSendCode: () => void
}

export default function VerifySMSCodeForm({
  dialCode,
  phoneNumber,
  onPressBack,
  onPressSubmit,
  onPressReSendCode,
}: VerifySMSCodeFormProps) {
  const { width, sm } = useResponsive()

  const [codes, setCodes] = useState<(number | null)[]>(
    Array.from({ length: SMS_PHONE_NUMBER_CODE_LENGTH })
  )

  const isDisabledSubmitButton = useMemo(() => {
    return codes.some((code) => code == null)
  }, [codes])

  const _onPressSubmit = async () => {
    if (isDisabledSubmitButton) return
    onPressSubmit(codes.join(''))
  }

  return (
    <>
      <StepItem
        stepNumber={2}
        state="selected"
        labelTextStyle={{
          fontSize: 22,
          fontWeight: '600',
          lineHeight: 33,
        }}
        hiddenCircle={width < sm}
      >
        {t('電話番号を認証してください')}
      </StepItem>
      <View
        style={{
          marginTop: width < sm ? 32 : 40,
          flexDirection: width < sm ? 'column' : 'row',
          columnGap: 8,
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            color={Colors.black40}
            size={20}
            style={{ marginRight: 8 }}
          />
          <Text>{dialCode}</Text>
          <Text
            style={{
              fontSize: width < sm ? 18 : 22,
              fontWeight: '600',
              lineHeight: width < sm ? 27 : 32,
              marginLeft: 8,
            }}
          >
            {phoneNumber}
          </Text>
        </View>
        <Button
          variant="primary"
          mode="text"
          onPress={onPressBack}
          style={[
            { paddingHorizontal: 0, marginLeft: 8, height: 'auto' },
            width < sm ? { marginTop: 8 } : { marginLeft: 8 },
          ]}
          textStyle={{
            fontSize: width < sm ? 14 : 16,
            fontWeight: '300',
            lineHeight: width < sm ? 18 : 24,
          }}
        >
          {t('修正する')}
        </Button>
      </View>
      <Text
        style={{
          fontSize: width < sm ? 14 : 18,
          fontWeight: '300',
          lineHeight: width < sm ? 21 : 28,
          textAlign: 'center',
        }}
      >
        {t(
          'こちらの電話番号宛にSMSで認証用コードを送りました。認証コード({{number}}桁)を入力して電話番号を認証してください',
          { number: SMS_PHONE_NUMBER_CODE_LENGTH }
        )}
      </Text>
      <View
        style={{
          marginTop: width < sm ? 32 : 40,
          paddingVertical: 24,
          paddingHorizontal: width < sm ? 4 : 130,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: Colors.border,
          borderWidth: 1,
          borderRadius: 8,
        }}
      >
        <VerifyCodeInput
          codeLength={SMS_PHONE_NUMBER_CODE_LENGTH}
          codes={codes}
          setCodes={setCodes}
          onSubmitLatestEditing={_onPressSubmit}
        />
        <Button
          style={{
            marginVertical: width < sm ? 24 : 40,
            width: width < sm ? '100%' : 232,
          }}
          textStyle={{
            fontSize: width < sm ? 14 : 16,
            fontWeight: '600',
            lineHeight: width < sm ? 21 : 24,
          }}
          disabled={isDisabledSubmitButton}
          onPress={_onPressSubmit}
          variant="primary"
          mode="contained"
        >
          {t('認証して次へ')}
        </Button>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <View
            style={{ borderColor: Colors.border, borderTopWidth: 1, flex: 1 }}
          />
          <Text
            style={{ paddingHorizontal: 8, fontSize: 14, fontWeight: '300' }}
          >
            {t('認証コードが届きませんか？')}
          </Text>
          <View
            style={{ borderColor: Colors.border, borderTopWidth: 1, flex: 1 }}
          />
        </View>
        <Text
          style={{
            fontSize: 12,
            fontWeight: '300',
            lineHeight: 18,
            color: Colors.black60,
            marginVertical: 8,
          }}
        >
          {t('※再送できるのは1分に1回です')}
        </Text>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="primary"
            mode="text"
            style={{
              height: 'auto',
              paddingVertical: 8,
              paddingHorizontal: 16,
            }}
            textStyle={{
              fontSize: width < sm ? 14 : 16,
              fontWeight: '300',
              lineHeight: width < sm ? 24 : 18,
            }}
            onPress={onPressReSendCode}
          >
            {t('前の画面に戻ってやり直す')}
          </Button>
        </View>
      </View>
    </>
  )
}
