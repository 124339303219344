import React from 'react'
import * as DocumentPicker from 'expo-document-picker'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

interface SelectDocumentResult {
  name: string
  size: number | undefined
  uri: string
  lastModified?: number
  file?: File
  output?: FileList | null
}

const selectDocumentFromMediaLibrary = async (
  options?: DocumentPicker.DocumentPickerOptions
): Promise<SelectDocumentResult | null> => {
  const pickerResult = await DocumentPicker.getDocumentAsync(options)

  if (pickerResult.canceled) {
    return null
  }

  return {
    name: pickerResult.assets[0].name,
    size: pickerResult.assets[0].size,
    uri: pickerResult.assets[0].uri,
    lastModified: pickerResult.assets[0].lastModified,
    file: pickerResult.assets[0].file,
    output: pickerResult.output,
  }
}

export function SelectDocument({
  options,
  children,
  onPress,
}: {
  options?: DocumentPicker.DocumentPickerOptions
  children: React.ReactNode
  onPress: (result: SelectDocumentResult) => void
}) {
  return (
    <Button
      mode="outline"
      height={48}
      onPress={async () => {
        const result = await selectDocumentFromMediaLibrary(options)
        if (result === null) {
          return
        }
        onPress(result)
      }}
    >
      {children}
    </Button>
  )
}
