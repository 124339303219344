// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_resource.proto" (package "auto_reserve.restaurants.restaurant_reservation_block", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource
 */
export interface RestaurantReservationBlockResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: int32 start_time = 4;
   */
  startTime: number;
  /**
   * @generated from protobuf field: int32 end_time = 5;
   */
  endTime: number;
  /**
   * @generated from protobuf field: repeated int32 wdays = 6;
   */
  wdays: number[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 8;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource.TableSeatResource table_seats = 11;
   */
  tableSeats: RestaurantReservationBlockResource_TableSeatResource[];
  /**
   * @generated from protobuf field: bool is_repeat = 12;
   */
  isRepeat: boolean;
}
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource.TableSeatResource
 */
export interface RestaurantReservationBlockResource_TableSeatResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantReservationBlockResource$Type extends MessageType<RestaurantReservationBlockResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "start_date", kind: "message", T: () => Timestamp },
        { no: 3, name: "end_date", kind: "message", T: () => Timestamp },
        {
          no: 4,
          name: "start_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 5, name: "end_time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
        {
          no: 6,
          name: "wdays",
          kind: "scalar",
          repeat: 1 /*RepeatType.PACKED*/,
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 7, name: "created_at", kind: "message", T: () => Timestamp },
        { no: 8, name: "updated_at", kind: "message", T: () => Timestamp },
        {
          no: 11,
          name: "table_seats",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => RestaurantReservationBlockResource_TableSeatResource,
        },
        { no: 12, name: "is_repeat", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantReservationBlockResource>,
  ): RestaurantReservationBlockResource {
    const message = {
      id: "",
      startTime: 0,
      endTime: 0,
      wdays: [],
      tableSeats: [],
      isRepeat: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantReservationBlockResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantReservationBlockResource,
  ): RestaurantReservationBlockResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* google.protobuf.Timestamp start_date */ 2:
          message.startDate = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.startDate,
          );
          break;
        case /* google.protobuf.Timestamp end_date */ 3:
          message.endDate = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.endDate,
          );
          break;
        case /* int32 start_time */ 4:
          message.startTime = reader.int32();
          break;
        case /* int32 end_time */ 5:
          message.endTime = reader.int32();
          break;
        case /* repeated int32 wdays */ 6:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.wdays.push(reader.int32());
          else message.wdays.push(reader.int32());
          break;
        case /* google.protobuf.Timestamp created_at */ 7:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 8:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* repeated auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource.TableSeatResource table_seats */ 11:
          message.tableSeats.push(
            RestaurantReservationBlockResource_TableSeatResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* bool is_repeat */ 12:
          message.isRepeat = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantReservationBlockResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* google.protobuf.Timestamp start_date = 2; */
    if (message.startDate)
      Timestamp.internalBinaryWrite(
        message.startDate,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp end_date = 3; */
    if (message.endDate)
      Timestamp.internalBinaryWrite(
        message.endDate,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int32 start_time = 4; */
    if (message.startTime !== 0)
      writer.tag(4, WireType.Varint).int32(message.startTime);
    /* int32 end_time = 5; */
    if (message.endTime !== 0)
      writer.tag(5, WireType.Varint).int32(message.endTime);
    /* repeated int32 wdays = 6; */
    if (message.wdays.length) {
      writer.tag(6, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.wdays.length; i++)
        writer.int32(message.wdays[i]);
      writer.join();
    }
    /* google.protobuf.Timestamp created_at = 7; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 8; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource.TableSeatResource table_seats = 11; */
    for (let i = 0; i < message.tableSeats.length; i++)
      RestaurantReservationBlockResource_TableSeatResource.internalBinaryWrite(
        message.tableSeats[i],
        writer.tag(11, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool is_repeat = 12; */
    if (message.isRepeat !== false)
      writer.tag(12, WireType.Varint).bool(message.isRepeat);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource
 */
export const RestaurantReservationBlockResource =
  new RestaurantReservationBlockResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantReservationBlockResource_TableSeatResource$Type extends MessageType<RestaurantReservationBlockResource_TableSeatResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource.TableSeatResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantReservationBlockResource_TableSeatResource>,
  ): RestaurantReservationBlockResource_TableSeatResource {
    const message = { id: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantReservationBlockResource_TableSeatResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantReservationBlockResource_TableSeatResource,
  ): RestaurantReservationBlockResource_TableSeatResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantReservationBlockResource_TableSeatResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_reservation_block.RestaurantReservationBlockResource.TableSeatResource
 */
export const RestaurantReservationBlockResource_TableSeatResource =
  new RestaurantReservationBlockResource_TableSeatResource$Type();
