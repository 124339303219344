import { isAxiosError } from '../axios'
import type { Revalidator, RevalidatorOptions } from 'swr'

import { PublicConfiguration } from 'swr/_internal'

export function onErrorRetry<Data = any, Error = any>(
  error: unknown,
  __: string,
  config: Readonly<PublicConfiguration<Data, Error>>,
  revalidate: Revalidator,
  opts: Required<RevalidatorOptions>
): void {
  if (isAxiosError(error)) {
    const status = error.response?.status
    // 400系はリトライしない
    if (status !== undefined && status !== 408 && status < 500) return
  }

  if (document.visibilityState === 'hidden') {
    return
  }

  if (
    typeof config.errorRetryCount === 'number' &&
    opts.retryCount > config.errorRetryCount
  ) {
    return
  }

  // exponential backoff
  const count = Math.min(opts.retryCount, 8)
  const timeout =
    ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval
  setTimeout(() => {
    revalidate(opts)
  }, timeout)
}
