import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'

import {
  updateRestaurantCustomerNoteTemplate,
  useRestaurantCustomerNoteTemplate,
} from '../../models/Restaurant'
import React, { useEffect, useState } from 'react'
import { KeyboardAvoidingView, View, Platform } from 'react-native'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export function EditCustomerNoteTemplate({
  restaurantId,
  onComplete,
}: {
  restaurantId: number
  onComplete?: () => void
}) {
  const navigation = useNavigation()
  const token = useToken()
  const { data } = useRestaurantCustomerNoteTemplate(restaurantId)
  const [templateContent, setTemplateContent] = useState<string>('')

  // モーダルを開いた時に、登録されたTemplateNoteを初期値としてセットする
  useEffect(() => {
    setTemplateContent(data?.content ?? '')
  }, [data?.content])

  const onPress = async () => {
    const { error } = await updateRestaurantCustomerNoteTemplate(
      token,
      restaurantId,
      {
        content: templateContent,
      },
    )

    if (error != null) return

    if (Platform.OS === 'web') {
      // Web の場合はモーダル表示
      // onComplete でモーダルを閉じる
    } else {
      navigation.goBack()
    }
    onComplete?.()
  }

  return (
    <KeyboardAvoidingView
      behavior={'padding'}
      keyboardVerticalOffset={70}
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          padding: 24,
          backgroundColor: Colors.white,
          flexGrow: 1,
        }}
      >
        <TextInput
          value={templateContent}
          onChangeText={(text) => setTemplateContent(text)}
          autoCapitalize="none"
          style={[
            {
              height: 100,
            },
          ]}
          multiline
        />
      </View>
      <View
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: -4,
          },
          shadowOpacity: 0.15,
          shadowRadius: 8,
          elevation: 2,
          paddingVertical: 24,
          paddingHorizontal: 16,
          backgroundColor: Colors.white,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onPress={onPress} width={260} height={48}>
          保存する
        </Button>
      </View>
    </KeyboardAvoidingView>
  )
}
