import React from 'react'
import { View, ScrollView } from 'react-native'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'

import ModalCenter from 'components/Shared/ModalCenter'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInputWithIcon } from '@hello-ai/ar_shared/src/components/TextInputWithIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { TableProductOptionBox } from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'
import { FetchProductOptionBoxesResult } from 'models/TableProductOptionBox'
import { useSearchAndPagination } from '@hello-ai/for_r_app/src/modules/useSearchAndPagination'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface TableProductOptionBoxSearchModalProps
  extends FetchProductOptionBoxesResult,
    ReturnType<typeof useSearchAndPagination> {
  title: string
  isModalVisible: boolean
  onClose: () => void
  onPress?: (tableProductOptionBox: TableProductOptionBox) => void
}

export default function TableProductOptionBoxSearchModal({
  title,
  isModalVisible,
  onClose,
  onPress,
  tableProductOptionBoxes,
  headerData,
  isLoading,
  onChangeSearchText,
  currentPage,
  onChangeCurrentPage,
}: TableProductOptionBoxSearchModalProps) {
  const { width, sm } = useResponsive()

  return (
    <ModalCenter
      title={title}
      isModalVisible={isModalVisible}
      onClose={onClose}
      width="60%"
    >
      <View>
        <View
          style={{
            margin: 16,
            height: 48,
          }}
        >
          <TextInputWithIcon
            onChangeText={(text) => onChangeSearchText(text)}
            autoCapitalize="none"
            placeholder={t('オプション名で検索')}
            icon={faSearch}
            style={[
              width < sm && {
                fontSize: 12,
                paddingLeft: 40,
                height: '100%',
                paddingVertical: 16,
              },
              {
                color: Colors.black,
                paddingTop: 12,
                paddingBottom: 12,
              },
            ]}
          />
        </View>
        <ScrollView style={{ marginTop: 24, maxHeight: '400px' }}>
          {isLoading && (
            <View style={{ padding: 24 }}>
              <Loading />
            </View>
          )}
          {!isLoading && tableProductOptionBoxes.length === 0 && (
            <View
              style={{
                height: 100,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>{t('検索結果がありません')}</Text>
            </View>
          )}
          {(tableProductOptionBoxes ?? []).map((productOptionBox, i) => {
            return (
              <TouchableOpacity
                key={productOptionBox.id}
                style={[
                  {
                    padding: 16,
                  },
                  tableProductOptionBoxes.length - 1 !== i && {
                    borderBottomColor: Colors.border,
                    borderBottomWidth: 0.5,
                    borderStyle: 'solid',
                  },
                ]}
                onPress={() => {
                  onPress && onPress(productOptionBox)
                  onClose()
                }}
              >
                <Text>{productOptionBox.name}</Text>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
        <View
          style={{
            borderTopWidth: 0.5,
            borderColor: Colors.border,
            paddingTop: 24,
            marginBottom: 16,
          }}
        >
          <Pagination
            currentPage={currentPage}
            totalPage={headerData?.totalPages ?? 0}
            setPage={(page) => onChangeCurrentPage(page)}
          />
        </View>
      </View>
    </ModalCenter>
  )
}
