import React from 'react'
import { View, Linking, Image, TouchableOpacity } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import ModalCenter from './ModalCenter'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import Config from '../../modules/config'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Modal } from './Modal'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export function PlanUpgradeModal({
  isModalVisible,
  onClose,
}: {
  isModalVisible: boolean
  onClose: () => void
}) {
  const { width, sm } = useResponsive()
  return width < sm ? (
    <Modal
      style={[
        width < sm && {
          margin: 0,
        },
      ]}
      isVisible={isModalVisible}
      onBackdropPress={onClose}
    >
      <View
        style={[
          {
            backgroundColor: 'white',
            alignSelf: 'center',
            maxWidth: 300,
            borderRadius: 8,
            overflow: 'hidden',
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
          }}
        >
          <Image
            style={{ height: 16, width: 16, marginRight: 4 }}
            source={require('../../assets/images/plan-icon.png')}
          />
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 21,
            }}
          >
            プランをアップグレードしてください
          </Text>
        </View>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 21,
            color: Colors.black,
            margin: 16,
          }}
        >
          この機能は、スタンダードプラン以上でご利用いただけます。ご利用を希望の方は、お問い合わせフォームからご連絡ください。
        </Text>
        <View style={{ height: 1, backgroundColor: Colors.border }} />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginVertical: 14,
          }}
        >
          <TouchableOpacity onPress={onClose}>
            <Text
              style={{
                fontSize: 12,
                lineHeight: 18,
                fontWeight: '600',
                color: Colors.black60,
              }}
            >
              キャンセル
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(`${Config.webBaseUrl}/for_restaurants/contact`)
            }
          >
            <Text
              style={{
                fontSize: 12,
                lineHeight: 18,
                fontWeight: '600',
                color: Colors.primary,
              }}
            >
              お問い合わせへ
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  ) : (
    <ModalCenter
      title={
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image
            style={{ height: 16, width: 16, marginRight: 6 }}
            source={require('../../assets/images/plan-icon.png')}
          />
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
              lineHeight: 27,
              color: Colors.primary,
            }}
          >
            プランをアップグレードしてください
          </Text>
        </View>
      }
      width="90%"
      minHeight={280}
      isModalVisible={isModalVisible}
      onClose={onClose}
    >
      <Text
        style={{
          fontSize: 18,
          lineHeight: 27,
          color: Colors.black,
          margin: 24,
        }}
      >
        この機能は、スタンダードプラン以上でご利用いただけます。ご利用を希望の方は、お問い合わせフォームからご連絡ください。
      </Text>
      <View style={{ height: 1, backgroundColor: Colors.border }} />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          marginVertical: 24,
        }}
      >
        <Button
          textStyle={{
            fontSize: 18,
            lineHeight: 24,
            color: Colors.primary,
          }}
          style={[
            {
              height: 48,
              paddingHorizontal: 24,
              backgroundColor: Colors.white,
              marginRight: 8,
            },
          ]}
          onPress={onClose}
        >
          キャンセル
        </Button>
        <Button
          textStyle={{
            fontSize: 18,
            lineHeight: 24,
            color: Colors.white,
          }}
          style={[
            {
              height: 48,
              paddingHorizontal: 24,
              backgroundColor: Colors.primary,
              marginLeft: 8,
            },
          ]}
          onPress={() =>
            Linking.openURL(`${Config.webBaseUrl}/for_restaurants/contact`)
          }
        >
          お問い合わせへ
        </Button>
      </View>
    </ModalCenter>
  )
}
