import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

// 均等なspaceを開けて折り返す
// 折り返した時に先頭が揃うように
export function Wrap({
  horizontalGap,
  verticalGap,
  style,
  children,
}: {
  horizontalGap: number
  verticalGap: number
  style?: StyleProp<ViewStyle>
  children: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          marginTop: -verticalGap,
          marginLeft: -horizontalGap,
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
        style,
      ]}
    >
      {React.Children.map(children, (child, index) => (
        <View
          key={index.toString()}
          style={{
            marginTop: verticalGap,
            marginLeft: horizontalGap,
          }}
        >
          {child}
        </View>
      ))}
    </View>
  )
}
