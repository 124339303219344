import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import useSWR, {
  WithHeaderData,
  fetcherWithPaginationHeader,
  mutate,
  swrKey,
  fetcher,
} from '../modules/swr'
import type { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useMemo, useState } from 'react'
import axios, { setHeader, wrapResponse } from '../modules/axios'

export function useRestaurantRequestReservations(
  restaurantId: number,
  { date, per }: { date: dayjs.Dayjs; per?: number },
) {
  const token = useToken()
  const [page, setPage] = useState(1)
  const _params = {
    date: date.format('YYYY-MM-DD'),
  }
  const params = per == null ? _params : { ..._params, per, page }

  const { data, error, mutate, isLoading } = useSWR<
    WithHeaderData<RestaurantRequestReservation[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/reservation_book/restaurants/${restaurantId}/reservation_requests`,
      params,
    ),
    ([token, url]) => fetcherWithPaginationHeader([token, url, params]),
  )

  return {
    requestReservations: data?.data,
    error,
    mutate,
    isLoading,

    pagination:
      data?.headerData == null
        ? null
        : {
            ...data.headerData,
            setPage,
          },
  }
}

export function useRestaurantRequestReservation(
  restaurantId: number,
  id?: number,
) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    RestaurantRequestReservation,
    any,
    ReturnType<typeof swrKey> | null
  >(
    id == null
      ? null
      : swrKey(
          token,
          `/reservation_book/restaurants/${restaurantId}/reservation_requests/${id}`,
        ),
    fetcher,
  )

  return {
    requestReservation: data,
    error,
    mutate,
  }
}

export async function createReservationFromRequest(
  token: string | null,
  restaurantId: number,
  requestReservationId: number,
  params: {
    start_at: string
    end_at: string
    table_seat_ids: string[]
  },
) {
  const { response, error } = await wrapResponse(
    axios.post(
      `/reservation_book/reservation_requests/${requestReservationId}/reservations`,
      params,
    ),
  )
  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservation_requests`,
        { date: dayjs(params.start_at).format('YYYY-MM-DD') },
      ),
    )
  }
  if (error != null) {
    onError(error)
  }

  return {
    requestReservation: response?.data,
    error,
  }
}

export async function rejectionRequestReservation(
  token: string,
  restaurantId: number,
  requestReservationId: number,
  params: {
    date: string
    cancel_reason: string
  },
) {
  const { response, error } = await wrapResponse(
    axios.post(
      `/reservation_book/reservation_requests/${requestReservationId}/cancellations`,
      {
        cancel_reason: params.cancel_reason,
      },
    ),
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservation_requests`,
        { date: params.date },
      ),
    )
  }
  if (error != null) {
    onError(error)
  }
}

export async function approveReservationChangeRequest(
  token: string | null,
  reservationChangeRequestId: number,
  params: {
    table_seat_ids: string[]
    end_at: string | null
  },
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(
      `/reservation_book/reservation_change_requests/${reservationChangeRequestId}/approvals`,
      params,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    response,
    error,
  }
}

export async function rejectionReservationChangeRequest(
  token: string | null,
  reservationChangeRequestId: number,
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(
      `/reservation_book/reservation_change_requests/${reservationChangeRequestId}/rejections`,
    ),
  )

  if (error != null) {
    onError(error)
  }

  return {
    response,
    error,
  }
}

export function useRestaurantRequestReservationsWithoutBeforeChanged(
  restaurantId: number,
  { date, per }: { date: dayjs.Dayjs; per?: number },
) {
  const { requestReservations, ...rest } = useRestaurantRequestReservations(
    restaurantId,
    { date, per },
  )

  const requestReservationsWithoutBeforeChanged = useMemo(() => {
    return requestReservations?.filter((requestReservation) => {
      if (requestReservation?.reservation?.reservation_change_request != null) {
        const fromReservationDate = dayjs(
          requestReservation.reservation.reservation_change_request
            .from_reserved_at,
        ).format('YYYY-MM-DD')
        const toReservationDate = dayjs(
          requestReservation.reservation.reservation_change_request
            .to_reserved_at,
        ).format('YYYY-MM-DD')
        // 同日の変更の場合は表示する
        if (fromReservationDate === toReservationDate) return true
        // 変更前日付と表示日付が同じものを除外する
        return date.format('YYYY-MM-DD') !== fromReservationDate
      }
      return true
    })
  }, [requestReservations, date])

  return {
    requestReservations: requestReservationsWithoutBeforeChanged,
    ...rest,
  }
}
