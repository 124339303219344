// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_business_time/restaurant_business_time_service.proto" (package "auto_reserve.restaurants.restaurant_business_time", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantBusinessTime } from "./restaurant_business_time_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetCalendarResponse } from "./restaurant_business_time_service";
import type { GetCalendarRequest } from "./restaurant_business_time_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_business_time.RestaurantBusinessTime
 */
export interface IRestaurantBusinessTimeClient {
  /**
   * @generated from protobuf rpc: GetCalendar(auto_reserve.restaurants.restaurant_business_time.GetCalendarRequest) returns (auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse);
   */
  getCalendar(
    input: GetCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCalendarRequest, GetCalendarResponse>;
}
/**
 * @generated from protobuf service auto_reserve.restaurants.restaurant_business_time.RestaurantBusinessTime
 */
export class RestaurantBusinessTimeClient
  implements IRestaurantBusinessTimeClient, ServiceInfo
{
  typeName = RestaurantBusinessTime.typeName;
  methods = RestaurantBusinessTime.methods;
  options = RestaurantBusinessTime.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetCalendar(auto_reserve.restaurants.restaurant_business_time.GetCalendarRequest) returns (auto_reserve.restaurants.restaurant_business_time.GetCalendarResponse);
   */
  getCalendar(
    input: GetCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCalendarRequest, GetCalendarResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetCalendarRequest, GetCalendarResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
