import React from 'react'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useNavigate } from 'react-router'
import {
  displayToastInfo,
  displayToastSuccess,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import GourmetSiteSeatSettingView from '@hello-ai/for_r_app/src/components/GourmetSiteSiteSeat/GourmetSiteSeatSettingView'
import { View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function GourmetSiteSeatSetting() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  if (restaurantId == null) return null

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <GourmetSiteSeatSettingView
        restaurantId={restaurantId}
        onPressOutboundSetting={() => {
          navigate(`/restaurants/${restaurantId}/gourmet_site/account_settings`)
        }}
        displayToastInfo={displayToastInfo}
        displayToastSuccess={displayToastSuccess}
        onPressAddSharedSeat={() => {
          navigate(
            `/restaurants/${restaurantId}/gourmet_site/shared_seat_settings/create`
          )
        }}
        onPressEditSharedSeat={(sharedSeatId) => {
          navigate(
            `/restaurants/${restaurantId}/gourmet_site/shared_seat_settings/edit/${sharedSeatId}`
          )
        }}
      />
    </View>
  )
}
