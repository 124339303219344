import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, { fetcher, swrKey, onError, mutate } from 'modules/swr'

import {
  ImportMailSettingsIndex,
  ImportMailAddress,
  LastImportedMail,
} from '@hello-ai/ar_shared/src/types/ForR/ImportMailSettings'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'

export function useImportMailSettings(restaurantId: number) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    ImportMailSettingsIndex,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/import_mail_settings`), fetcher)

  return {
    importMailSettingsData: data,
    error,
    mutate,
  }
}

export function useImportMailAddress(restaurantId: number) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    ImportMailAddress,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/import_mail_settings/import_mail_address`
    ),
    fetcher
  )

  return {
    importMailAddressData: data,
    error,
    mutate,
  }
}

export function useLastImportedMail(restaurantId: number, source: string) {
  const token = useToken()
  const params = {
    source,
  }
  const { data, error, mutate } = useSWR<
    LastImportedMail,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/import_mail_settings/last_imported_mail`,
      params
    ),
    ([token, url]) => fetcher<LastImportedMail>([token, url, params])
  )

  return {
    lastImportedMailData: data,
    error,
    mutate,
  }
}

export async function postImportMailNoteAgreement({
  restaurantId,
  token,
}: {
  restaurantId: number
  token: string
}) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post(
      `/restaurants/${restaurantId}/import_mail_settings/notes_agreement`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
  }
}

export async function createImportMailSettings({
  restaurantId,
  token,
  source,
}: {
  restaurantId: number
  token: string
  source: string
}) {
  setHeader({ token })
  const params = { source }
  const { response, error } = await wrapResponse(
    axios.post(`/restaurants/${restaurantId}/import_mail_settings`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(swrKey(token, `/restaurants/${restaurantId}/import_mail_settings`))
  }

  return {
    error,
  }
}
