import React, { FC } from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Text } from '../Text'
import { Colors } from '../../constants/Colors'
import { hexWithOpacity } from '../../modules/hexWithOpacity'
import { useResponsive } from '../../modules/useResponsive'

type Props = {
  errors: string[]
  isListExist?: Boolean
}

export function StepperErrors({ errors, isListExist }: Props) {
  const { width, sm } = useResponsive()
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        position: 'absolute',
        bottom:
          width < sm
            ? !isListExist
              ? insets.bottom + 75
              : insets.bottom + 132
            : 124,
        right: 16,
        backgroundColor: hexWithOpacity(0.9, Colors.caution),
        padding: 16,
        borderRadius: 4,
        shadowRadius: 4,
        elevation: 4,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        paddingTop: 12,
      }}
    >
      {errors.map((errorMessage, index) => {
        return (
          <Text key={index} style={{ color: 'white', marginTop: 4 }}>
            {errorMessage}
          </Text>
        )
      })}
    </View>
  )
}
