import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import {
  HeaderBack,
  HeaderRightButton,
} from 'components/Shared/Navigation/Header'
import React from 'react'
import { generatePath } from 'react-router'
import { useParams } from 'react-router-dom'
import { history } from 'modules/history'
import TableSeats from 'pages/Restaurants/TableSeats'
import TableSeatForm from 'pages/Restaurants/TableSeats/Form'
import TableSeatChargePolicies from 'pages/Restaurants/TableSeats/TableSeatChargePolicies'
import TableSeatChargePolicyForm from 'pages/Restaurants/TableSeats/TableSeatChargePolicies/Form'
import TableSeatConnections from 'pages/Restaurants/TableSeats/TableSeatConnections'
import TableSeatConnectionForm from 'pages/Restaurants/TableSeats/TableSeatConnections/Form'
import TableSeatArchived from 'pages/Restaurants/TableSeats/Archived'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { faArchive } from '@fortawesome/pro-solid-svg-icons'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function TableSeatRightHeaderButton() {
  const params = useParams<{ id: string }>()
  const { width, sm } = useResponsive()
  return (
    <HeaderRightButton
      icon={
        <FontAwesomeIcon icon={faArchive} size={18} color={Colors.primary} />
      }
      onPress={() => {
        history.push(
          generatePath(`/restaurants/:id/table_seats/archived`, {
            id: params.id!,
          })
        )
      }}
      label={width < sm ? null : t('アーカイブ')}
    />
  )
}

function TableSeatChargePolicyRightHeaderButton() {
  const params = useParams<{ id: string }>()
  return (
    <HeaderRightButton
      onPress={() => {
        history.push(
          generatePath(
            `/restaurants/:id/table_seats/table_seat_charge_policies/new`,
            {
              id: params.id!,
            }
          )
        )
      }}
      label={t('席チャージを追加')}
    />
  )
}

function TableSeatConnectionRightHeaderButton() {
  const params = useParams<{ id: string }>()
  return (
    <HeaderRightButton
      onPress={() => {
        history.push(
          generatePath(
            `/restaurants/:id/table_seats/table_seat_connections/new`,
            {
              id: params.id!,
            }
          )
        )
      }}
      label={t('接続パターンを追加')}
    />
  )
}

const routes: StackRoutes = {
  Index: {
    title: t('席'),
    fullPath: `/restaurants/:id/table_seats`,
    relativePath: `/`,
    exact: true,
    element: <TableSeats />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
      headerRight: <TableSeatRightHeaderButton />,
    },
  },
  Edit: {
    title: t('席を編集'),
    fullPath: `/restaurants/:id/table_seats/:tableSeatId/edit`,
    relativePath: `/:tableSeatId/edit`,
    element: <TableSeatForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  New: {
    title: t('席を追加'),
    fullPath: `/restaurants/:id/table_seats/new`,
    relativePath: `/new`,
    element: <TableSeatForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  ChargePolicies: {
    title: t('席チャージ一覧'),
    fullPath: `/restaurants/:id/table_seats/table_seat_charge_policies`,
    relativePath: `/table_seat_charge_policies`,
    element: <TableSeatChargePolicies />,
    exact: true,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
      headerRight: <TableSeatChargePolicyRightHeaderButton />,
    },
  },
  ChargePoliciesNew: {
    title: t('席チャージを追加'),
    fullPath: `/restaurants/:id/table_seats/table_seat_charge_policies/new`,
    relativePath: `/table_seat_charge_policies/new`,
    element: <TableSeatChargePolicyForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  ChargePoliciesEdit: {
    title: t('席チャージを編集'),
    fullPath: `/restaurants/:id/table_seats/table_seat_charge_policies/:tableSeatChargePolicyId/edit`,
    relativePath: `/table_seat_charge_policies/:tableSeatChargePolicyId/edit`,
    element: <TableSeatChargePolicyForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Connections: {
    title: t('接続パターン一覧'),
    fullPath: `/restaurants/:id/table_seats/table_seat_connections`,
    relativePath: `/table_seat_connections`,
    element: <TableSeatConnections />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
      headerRight: <TableSeatConnectionRightHeaderButton />,
    },
  },
  ConnectionsNew: {
    title: t('接続パターンを追加'),
    fullPath: `/restaurants/:id/table_seats/table_seat_connections/new`,
    relativePath: `/table_seat_connections/new`,
    element: <TableSeatConnectionForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Archived: {
    title: t('アーカイブした席'),
    fullPath: `/restaurants/:id/table_seats/archived`,
    relativePath: `/archived`,
    element: <TableSeatArchived />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function TableSeatsStack() {
  return <Stack routes={routes} />
}
