import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, { fetcher, onError, swrKey, mutate } from '../modules/swr'
import { useToken } from './Auth'

import {
  TakeoutProduct,
  RestaurantTakeoutProducts,
} from '@hello-ai/ar_shared/src/types/ForR/TakeoutProduct'
export * from '@hello-ai/ar_shared/src/types/ForR/TakeoutProduct'

async function customFetcher<T = any, P extends {} = {}>([token, url, params]: [
  token: string | null,
  url: string,
  params?: P
]) {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  if (response.data != null) {
    const currentPage = response?.headers['x-current-page'] ?? '0'
    const totalPages = response?.headers['x-total-pages'] ?? '0'
    const totalCount = response?.headers['x-total-count'] ?? '0'
    return {
      takeoutProducts: response.data,
      headerData: {
        currentPage: Number(currentPage),
        totalPages: Number(totalPages),
        totalCount: Number(totalCount),
      },
    }
  }
  return null
}

// export interface TakeoutProduct {
//   id: string
//   status: TakeoutProductStatus
//   kind: string // FIXME union typeにしたほうがいいかも
//   name: string
//   description: string
//   in_stock: boolean
//   order_required?: boolean
//   option_select_required?: boolean
//   likes_count?: number
//   cost?: number
//   created_at: string
//   out_of_stock_at?: string
//   display_size: TakeoutProductDisplaySize
//   prices: TakeoutProductPrices
//   takeout_product_option_boxes: TakeoutProductOptionBox[]
//   takeout_menu_pages?: TakeoutMenuPage[]
//   image?: {
//     xl: string
//     lg: string
//     md: string
//     sm: string
//   }

//   /** @deprecated prices[provideMethod].priceなどを使う */
//   price: number
//   /** @deprecated prices[provideMethod].price_typeを使う */
//   price_type: PriceType
// }

// interface RestaurantTakeoutProducts {
//   takeoutProducts: TakeoutProduct[]
//   headerData: {
//     currentPage: number
//     totalPages: number
//     totalCount: number
//   }
// }

export function useRestaurantTakeoutProducts({
  restaurantId,
  params,
}: {
  restaurantId: number
  params?: {
    page: number
    keyword?: string
    status?: string
  }
}) {
  const token = useToken()
  const keys = swrKey(
    token,
    `/takeout/restaurants/${restaurantId}/products`,
    params
  )
  const { data, error, mutate } = useSWR<
    RestaurantTakeoutProducts | null,
    any,
    ReturnType<typeof swrKey>
  >(
    keys,
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string
    ]) => customFetcher([token, url, JSON.parse(params)])
  )

  return {
    takeoutProducts: data ? data.takeoutProducts : null,
    headerData: data ? data.headerData : null,
    error,
    mutate,
  }
}

export function useTakeoutProduct({
  takeoutProductId,
}: {
  takeoutProductId?: string
}) {
  const token = useToken()
  const { data: takeoutProduct, mutate } = useSWR<
    TakeoutProduct,
    any,
    ReturnType<typeof swrKey> | null
  >(
    takeoutProductId != null
      ? swrKey(token, `/takeout/products/${takeoutProductId}`)
      : null,
    fetcher
  )

  if (takeoutProductId == null) {
    return { takeoutProduct, isLoading: false, mutate }
  }

  return {
    takeoutProduct,
    isLoading: takeoutProduct == null,
    mutate,
  }
}

export type UpdateTakeoutProductFuncType = (
  takeoutProductId: string,
  params?: any
) => void

export async function updateTakeoutProduct({
  token,
  takeoutProductId,
  params,
}: {
  token: string
  takeoutProductId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<TakeoutProduct>(`/takeout/products/${takeoutProductId}`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/takeout/products/${takeoutProductId}`),
      response.data,
      false
    )
  }

  return {
    takeoutProduct: response?.data,
  }
}

export async function createTakeoutProduct({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse<TakeoutProduct>(
    axios.post<TakeoutProduct>(
      `/takeout/restaurants/${restaurantId}/products`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/takeout/products/${response?.data?.id}`),
      response.data,
      false
    )
  }

  return {
    takeoutProduct: response?.data,
  }
}
