import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  GourmetSiteProvider,
  GOURMET_SITE_PROVIDER_SOURCE,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { useParams } from 'react-router'

export function useGourmetSource(): GourmetSiteProvider {
  const { source } = useParams<{ source: GourmetSiteProvider }>()
  if (source == null) throw new Error(t('連携元が見つかりません'))

  if (Object.keys(GOURMET_SITE_PROVIDER_SOURCE).includes(source)) {
    return source
  }
  throw new Error(t('連携元が見つかりません'))
}
