import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { View } from 'react-native'
import React, { useMemo, useState } from 'react'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import VerifyCodeInput from 'components/Shared/VerifyCodeInput'
import DraftOwnerInfo from './DraftOwnerInfo'
import {
  SIGN_UP_CODE_LENGTH,
  sendEmailCodeForDraftOwner,
  verifyEmailForDraftOwner,
} from 'models/Auth'
import { StepItem } from './Stepper'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

interface VerifyEmailFormProps {
  draftOwnerInfo: DraftOwnerInfo
  onPressBack: () => void
  onPressNext: () => void
}

export default function VerifyEmailForm({
  draftOwnerInfo,
  onPressBack,
  onPressNext,
}: VerifyEmailFormProps) {
  const { width, sm } = useResponsive()

  const [codes, setCodes] = useState<(number | null)[]>(
    Array.from({ length: SIGN_UP_CODE_LENGTH })
  )

  const isDisabledSubmitButton = useMemo(() => {
    return codes.some((code) => code == null)
  }, [codes])

  const onPressSubmit = async () => {
    if (isDisabledSubmitButton) return

    const { error } = await verifyEmailForDraftOwner(
      draftOwnerInfo.draftOwnerId,
      codes.join('')
    )
    if (error != null) return
    onPressNext()
  }

  const onPressReSendCode = async () => {
    await sendEmailCodeForDraftOwner(draftOwnerInfo.draftOwnerId)
  }

  return (
    <View
      style={[
        { width: 600, marginTop: 80, alignItems: 'center' },
        width < sm && { width: '100%', paddingHorizontal: 16, marginTop: 40 },
      ]}
    >
      <StepItem
        stepNumber={1}
        state="selected"
        labelTextStyle={{
          fontSize: 22,
          fontWeight: '600',
          lineHeight: 33,
          marginLeft: 8,
        }}
        hiddenCircle={width < sm}
      >
        {t('メールアドレス認証をしてください')}
      </StepItem>
      <View
        style={{
          flexDirection: width < sm ? 'column' : 'row',
          columnGap: 8,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: width < sm ? 32 : 40,
          marginBottom: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            color={Colors.black40}
            size={24}
            style={{ marginRight: 8 }}
          />
          <Text
            style={{
              fontSize: width < sm ? 18 : 22,
              fontWeight: '600',
              lineHeight: width < sm ? 27 : 32,
            }}
          >
            {draftOwnerInfo.email}
          </Text>
        </View>
        <Button
          variant="primary"
          mode="text"
          onPress={onPressBack}
          style={[
            { paddingHorizontal: 0, marginLeft: 8, height: 'auto' },
            width < sm ? { marginTop: 8 } : { marginLeft: 8 },
          ]}
          textStyle={{
            fontSize: width < sm ? 14 : 16,
            fontWeight: '300',
            lineHeight: width < sm ? 18 : 24,
          }}
        >
          {t('修正する')}
        </Button>
      </View>
      <Text
        style={{
          textAlign: 'center',
          fontWeight: '300',
          fontSize: width < sm ? 14 : 18,
          lineHeight: width < sm ? 21 : 27,
        }}
      >
        {t(
          '本人確認のため、こちらのメールアドレスに認証用コードを送りました。認証コード({{SIGN_UP_CODE_LENGTH}}桁)を入力してメールアドレスを認証してください。',
          {
            SIGN_UP_CODE_LENGTH,
          }
        )}
      </Text>

      <View
        style={{
          marginTop: width < sm ? 32 : 40,
          paddingVertical: 24,
          paddingHorizontal: width < sm ? 4 : 130,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: Colors.border,
          borderWidth: 1,
          borderRadius: 8,
        }}
      >
        <VerifyCodeInput
          codeLength={SIGN_UP_CODE_LENGTH}
          codes={codes}
          setCodes={setCodes}
          onSubmitLatestEditing={onPressSubmit}
        />
        <Button
          style={{
            marginVertical: width < sm ? 24 : 40,
            height: 48,
            width: 232,
          }}
          textStyle={{
            fontSize: width < sm ? 14 : 16,
            fontWeight: '600',
            lineHeight: width < sm ? 21 : 24,
          }}
          disabled={isDisabledSubmitButton}
          variant="primary"
          mode="contained"
          onPress={onPressSubmit}
        >
          {t('認証して次へ')}
        </Button>
        <View
          style={{
            marginBottom: 8,
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <View
            style={{ borderColor: Colors.border, borderTopWidth: 1, flex: 1 }}
          />
          <Text
            style={{ paddingHorizontal: 8, fontSize: 14, fontWeight: '300' }}
          >
            {t('認証コードが届きませんか？')}
          </Text>
          <View
            style={{ borderColor: Colors.border, borderTopWidth: 1, flex: 1 }}
          />
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="primary"
            mode="text"
            onPress={onPressReSendCode}
            textStyle={{
              fontSize: width < sm ? 14 : 16,
              fontWeight: '300',
              lineHeight: width < sm ? 24 : 18,
            }}
          >
            {t('認証コードを再送する')}
          </Button>
        </View>
      </View>
    </View>
  )
}
