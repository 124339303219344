import { GourmetSiteProvider } from './GourmetSiteSetting'

export interface OutboundUpdateHistory {
  name: string
  action: 'update' | 'create'
  created_at: string
}

export interface OutboundSettingData {
  id: string
  restaurant_id: number
  site: GourmetSiteProvider
  site_restaurant_id: string
  enabled: boolean
  signin_id: string
  set_at: string
  mail_last_received_at: string
  histories: OutboundUpdateHistory[]
}

export const isExistOutboundSetting = (
  data?: Partial<OutboundSettingData>
): data is OutboundSettingData => {
  if (data == null) return false
  return data?.id != null
}
