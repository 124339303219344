import React, { FC, ReactNode } from 'react'
import { View } from 'react-native'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'

import { Colors } from '../../constants/Colors'
import { Text } from '../Text'
import { FontAwesomeIcon } from '../FontAwesomeIcon'
import { useResponsive } from '../../modules/useResponsive'

const stepCircleDiameter = 24

type StepState = 'default' | 'completed' | 'selected'

type Props = {
  stepNumber: number
  state: StepState
  children: ReactNode
}

export function StepperItem({ stepNumber, state, children }: Props) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        flexDirection: width < sm ? 'column' : 'row',
        alignItems: 'center',
        width: width < sm ? 70 : 'auto',
      }}
    >
      <View
        style={[
          {
            width: stepCircleDiameter,
            height: stepCircleDiameter,
            borderRadius: stepCircleDiameter / 2,
            borderWidth: 1,
            borderColor: Colors.black20,
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
          },
          state === 'completed' && {
            borderColor: Colors.accent,
          },
          state === 'selected' && {
            borderColor: Colors.accent,
            backgroundColor: Colors.accent,
          },
        ]}
      >
        {state === 'completed' ? (
          <FontAwesomeIcon icon={faCheck} size={10} color={Colors.accent} />
        ) : (
          <Text
            style={[
              {
                color: Colors.secondaryBlack,
                fontSize: 14,
                fontWeight: '600',
              },
              state === 'selected' && {
                color: 'white',
              },
            ]}
          >
            {stepNumber}
          </Text>
        )}
      </View>
      <Text
        style={[
          {
            fontSize: 12,
            marginLeft: width < sm ? 0 : 8,
            fontWeight: width < sm ? '300' : '600',
          },
        ]}
      >
        {children}
      </Text>
    </View>
  )
}
