import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import React from 'react'
import { ScrollView, View, ViewStyle, useWindowDimensions } from 'react-native'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { To } from 'components/Shared/Link'
import LinkOpacity from 'components/Shared/LinkOpacity'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import {
  createResponsiveSheet,
  useResponsive,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'

const chevronWidth = 12

export function Cell({
  flex,
  children,
}: {
  flex?: number
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flex,
        },
      ]}
    >
      {children}
    </View>
  )
}

export function FixedCell({
  width,
  alignItems,
  justifyContent,
  children,
}: {
  width: number
  alignItems?: ViewStyle['alignItems']
  justifyContent?: ViewStyle['justifyContent']
  children?: React.ReactNode
}) {
  return (
    <View
      style={{
        width,
        alignItems,
        justifyContent,
      }}
    >
      {children}
    </View>
  )
}

export function IconCell() {
  return (
    <FixedCell
      width={chevronWidth}
      alignItems="flex-end"
      justifyContent="center"
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        size={12}
        color={Colors.secondaryBlack}
      />
    </FixedCell>
  )
}

export function IconEmptyCell() {
  return <FixedCell width={chevronWidth} />
}

interface PCTableProps<D extends { [key: string]: any }, K> {
  headers: { label: string; key: K; hiddenSP?: boolean }[]
  data: D[]
  cellRender: (key: K, row: D) => React.ReactNode
  keyExtractor: (item: D) => React.Key
  toExtractor: (item: D) => To
}

const RowMinHeight = 80

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  container: {
    padding: 0,
    marginTop: 16,
  },
  header: {
    flexDirection: 'row',
    paddingVertical: 16,
    paddingHorizontal: 24,
    columnGap: 16,
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.border,
  },
  headerText: {
    width: width < sm ? 100 : 'auto',
    fontSize: width < sm ? 14 : 16,
    fontWeight: '600',
  },
  rowStyle: {
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
    minHeight: width < sm ? 'auto' : RowMinHeight,
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: 18,
    padding: width < sm ? 16 : 24,
  },
}))

export function Table<D extends { [key: string]: any }, K extends string>(
  props: PCTableProps<D, K>
) {
  const height = useWindowDimensions().height
  const style = useResponsiveStyle(responsiveStyle)
  const { width, sm } = useResponsive()
  const isSP = width <= sm
  return (
    <ShadowBox style={style.container}>
      {!isSP && (
        <View style={style.header}>
          {props.headers.map((header) => (
            <Cell flex={1} key={header.key}>
              <Text style={style.headerText}>{header.label}</Text>
            </Cell>
          ))}
        </View>
      )}

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          maxHeight: width < sm ? height - 212 : height - 266,
        }}
      >
        {props.data.map((item) => {
          return (
            <LinkOpacity
              key={props.keyExtractor(item)}
              to={props.toExtractor(item)}
              style={style.rowStyle}
            >
              {!isSP &&
                props.headers.map(({ key }) => (
                  <Cell flex={1} key={key}>
                    {props.cellRender(key, item)}
                  </Cell>
                ))}
              {isSP && (
                <>
                  <View style={{ flex: 1, rowGap: 20 }}>
                    {props.headers.map(({ key, label, hiddenSP }) => (
                      <View
                        key={key}
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          columnGap: 8,
                          width: '100%',
                        }}
                      >
                        {!hiddenSP && (
                          <Cell>
                            <Text style={style.headerText}>{label}</Text>
                          </Cell>
                        )}
                        <Cell flex={1}>{props.cellRender(key, item)}</Cell>
                      </View>
                    ))}
                  </View>
                  <View style={{ width: 16, marginLeft: 14 }}>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size={16}
                      color={Colors.secondaryBlack}
                    />
                  </View>
                </>
              )}
            </LinkOpacity>
          )
        })}
      </ScrollView>
    </ShadowBox>
  )
}
