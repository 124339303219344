import React from 'react'
import {
  Image,
  Alert,
  ImageSourcePropType,
  StyleProp,
  ViewStyle,
  StyleSheet,
} from 'react-native'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import { MediaTypeOptions } from 'expo-image-picker/src/ImagePicker.types'
import { TouchableOpacity } from './Touchables'
import { FontAwesomeIcon } from './FontAwesomeIcon'
import { Colors } from '../constants/Colors'
import { t } from '../../src/modules/i18n'
import { Loading } from './Loading'

const selectImageDefaultMaxWidth = 1600

export const selectImageFromMediaLibrary = async (
  options: {
    maxWidth?: number
  } = {}
): Promise<string | null> => {
  const permissionResult =
    await ImagePicker.requestMediaLibraryPermissionsAsync()

  if (permissionResult.granted === false) {
    Alert.alert(t('写真ライブラリへの使用を許可してください'))
    return null
  }

  const pickerResult = await ImagePicker.launchImageLibraryAsync({
    allowsEditing: false,
    quality: 1,
    mediaTypes: MediaTypeOptions.Images,
  })

  if (pickerResult.canceled) {
    return null
  }

  const manipResult = await ImageManipulator.manipulateAsync(
    pickerResult.assets[0]?.uri,
    [
      {
        resize: {
          width: Math.min(
            options.maxWidth ?? selectImageDefaultMaxWidth,
            pickerResult.assets[0]?.width
          ),
        },
      },
    ],
    { compress: 1, base64: true }
  )

  return `data:image/jpg;base64,${manipResult.base64}`
}

export const launchCameraAsync = async () => {
  const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
  if (permissionResult.granted === false) {
    Alert.alert(t('カメラの使用を許可してください'))
    return null
  }

  const cameraResult = await ImagePicker.launchCameraAsync({
    allowsEditing: false,
    quality: 1,
    mediaTypes: MediaTypeOptions.Images,
    base64: true,
  })

  if (cameraResult.canceled === true) {
    return null
  }

  return `data:image/jpg:base64,${cameraResult.assets[0].base64}`
}

const styles = StyleSheet.create({
  image: {
    position: 'relative',
    backgroundColor: Colors.bgBlack,
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    backgroundColor: Colors.secondaryBlack,
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export function SelectImage({
  isLoading,
  onSelectImage,
  width = 120,
  height = 120,
  iconSize = 60,
}: {
  isLoading?: boolean
  onSelectImage?: (base64Image: string) => void
  width?: number
  height?: number
  iconSize?: number
}) {
  return onSelectImage ? (
    <TouchableOpacity
      onPress={async () => {
        const image = await selectImageFromMediaLibrary()
        if (image != null) {
          onSelectImage(image)
        }
      }}
      style={[styles.image, { width, height }]}
    >
      <FontAwesomeIcon
        icon={faImage}
        size={iconSize}
        color={Colors.secondaryBlack}
      />
      {isLoading && (
        <Loading
          style={{
            position: 'absolute',
            bottom: 6,
          }}
        />
      )}
    </TouchableOpacity>
  ) : (
    <FontAwesomeIcon
      icon={faImage}
      size={iconSize}
      color={Colors.secondaryBlack}
    />
  )
}

export function SelectedImage({
  source,
  onRemovePress,
  width = 120,
  height = 120,
  iconSize = 18,
  style,
}: {
  source: ImageSourcePropType
  onRemovePress?: () => void
  width?: number
  height?: number
  iconSize?: number
  style?: StyleProp<ViewStyle>
}) {
  return onRemovePress ? (
    <TouchableOpacity style={[{ position: 'relative' }, style]}>
      <Image
        source={source}
        style={{
          width,
          height,
        }}
      />

      <TouchableOpacity
        style={styles.crossButton}
        onPress={() => onRemovePress()}
      >
        <FontAwesomeIcon icon={faTimes} size={iconSize} color="white" />
      </TouchableOpacity>
    </TouchableOpacity>
  ) : (
    <Image
      source={source}
      style={{
        width,
        height,
      }}
    />
  )
}
