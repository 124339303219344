import React, { ComponentProps, useState } from 'react'
import { View, StyleProp, TextStyle } from 'react-native'
import { SharedSeat } from '../../../../types/ForR/SiteControllerSeat'
import { Colors } from '../../../../constants/Colors'
import { t } from '../../../../modules/i18n'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { GourmetSiteProviderLabel } from '../Shared/GourmetSiteProvider'
import SectionTitle from '../Shared/SectionTitle'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { Pagination } from '../../../Pagination'
import { TextInput } from '../../../TextInput'
import { FontAwesomeIcon } from '../../../FontAwesomeIcon'
import { Button } from '../../../Button'
import { Checkbox, CheckboxGroup } from '../../../Checkbox'
import { Text } from '../../../Text'
import { SelectInput } from '../../../SelectInput'
import { confirmAsync } from '../../../../modules/alert'

interface SharedSeatListViewProps {
  data?: SharedSeat[]
  searchText: string
  setSearchText: (text: string) => void
  filter: string
  setFilter: (filter: string) => void
  paginationProps: ComponentProps<typeof Pagination>
  onPressDeleteCheckedSeats: (checkedSeatIds: string[]) => void
  onPressEditSharedSeat: (sharedSeatId: SharedSeat['id']) => void
  onPressAddSharedSeat: () => void
}

const FILTERS = [
  { label: t('すべて'), value: 'all' },
  { label: t('連携中'), value: 'enabled' },
  { label: t('連携停止'), value: 'stop' },
  { label: t('未連携あり'), value: 'unassociated' },
]

export type FilterValue = (typeof FILTERS)[number]['value']

const STATUS_LABELS: Record<SharedSeat['status'], string> = {
  enabled: t('連携中'),
  stop: t('連携停止中'),
  error: t('連携エラー'),
}
const STATUS_STYLES: Record<SharedSeat['status'], StyleProp<TextStyle>> = {
  enabled: {
    color: Colors.success,
    backgroundColor: Colors.accentBg,
  },
  stop: {
    color: Colors.caution,
    backgroundColor: Colors.cautionBg,
  },
  error: {
    color: Colors.caution,
    backgroundColor: Colors.cautionBg,
  },
}

export default function SharedSeatListView({
  data,
  searchText,
  setSearchText,
  filter,
  setFilter,
  paginationProps,
  onPressDeleteCheckedSeats,
  onPressEditSharedSeat,
  onPressAddSharedSeat,
}: SharedSeatListViewProps) {
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedSeatIds, setCheckedSeatIds] = useState<string[]>([])

  return (
    <View>
      <SectionTitle
        title={t('連携中の席一覧')}
        helperText={t(
          '当サービスで作成された席のうち、他社グルメサイトと連携済みの席一覧です。'
        )}
      />

      <View style={{ backgroundColor: Colors.white, padding: 16 }}>
        <View style={{ flexDirection: 'row', columnGap: 16, marginBottom: 32 }}>
          <View style={{ position: 'relative', flexGrow: 1 }}>
            <TextInput
              style={{
                backgroundColor: Colors.white,
                borderWidth: 1,
                borderColor: Colors.border,
                borderRadius: 8,
                width: '100%',
                height: 48,
                paddingLeft: 48,
              }}
              value={searchText}
              onChangeText={setSearchText}
            />
            <FontAwesomeIcon
              size={24}
              icon={faSearch}
              color={Colors.black60}
              style={{ position: 'absolute', left: 16, top: 12 }}
            />
          </View>
          <SelectInput
            style={{
              width: 160,
            }}
            containerStyle={{
              flex: 0,
              minWidth: 160,
            }}
            items={FILTERS}
            setValue={setFilter}
            selectedValue={filter}
          />
        </View>
        <Button
          mode="outline"
          variant="danger"
          style={{
            alignSelf: 'flex-start',
            height: 36,
          }}
          textStyle={{
            fontSize: 14,
          }}
          disabled={checkedSeatIds.length === 0}
          onPress={async () => {
            if (checkedSeatIds.length === 0) return
            if ((await confirmAsync(t('削除してもよろしいですか？'))) === false)
              return
            onPressDeleteCheckedSeats(checkedSeatIds)
          }}
        >
          {t('チェックした席連携を削除する')}
        </Button>

        <View
          style={{ paddingVertical: 16, flexDirection: 'row', columnGap: 16 }}
        >
          <Checkbox
            checkboxLabel=""
            checked={isCheckedAll}
            onChange={() => {
              setCheckedSeatIds(
                isCheckedAll ? [] : data?.map((item) => item.id) ?? []
              )
              setIsCheckedAll(!isCheckedAll)
            }}
          />
          <Text
            style={{ fontSize: 14, fontWeight: '600', lineHeight: 22, flex: 1 }}
          >
            {t('連携中の席名')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 90,
            }}
          >
            {t('ステータス')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 150,
            }}
          >
            {t('設定')}
          </Text>
        </View>
        <View>
          {data?.length === 0 && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                rowGap: 16,
                paddingVertical: 24,
                borderTopWidth: 0.5,
                borderColor: Colors.border,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: '600',
                  color: Colors.black60,
                }}
              >
                {t('連携中の席がありません')}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: '300',
                  color: Colors.black60,
                }}
              >
                {t(
                  '当サービスで作成された席と他社グルメサイトの席を連携設定することで\n予約管理を一括で管理することができます'
                )}
              </Text>
              <Button mode="outline" onPress={onPressAddSharedSeat}>
                {t('連携設定を追加する')}
              </Button>
            </View>
          )}
          <CheckboxGroup
            mode="vertical"
            value={checkedSeatIds}
            onChange={setCheckedSeatIds}
          >
            {data?.map((item, index) => (
              <View
                key={item.id}
                style={{
                  paddingVertical: 16,
                  borderTopWidth: index % 2 === 0 ? 0.5 : 0,
                  borderColor: Colors.border,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    columnGap: 16,
                    alignItems: 'center',
                  }}
                >
                  <Checkbox checkboxLabel="" value={item.id} />
                  <Text
                    style={{ flexGrow: 4, fontSize: 18, fontWeight: '600' }}
                  >
                    {item.table_seat.name}
                  </Text>
                  <View style={{ width: 86 }}>
                    <Text
                      style={[
                        STATUS_STYLES[item.status],
                        {
                          padding: 8,
                          fontWeight: '600',
                          fontSize: 14,
                          lineHeight: 22,
                          alignSelf: 'flex-start',
                          borderRadius: 2,
                        },
                      ]}
                    >
                      {STATUS_LABELS[item.status]}
                    </Text>
                  </View>
                  <Button
                    onPress={() => {
                      onPressEditSharedSeat(item.id)
                    }}
                  >
                    {t('連携席の設定')}
                  </Button>
                </View>
                <View
                  style={{
                    marginTop: 8,
                    backgroundColor: Colors.bgBlack,
                    padding: 8,
                    borderRadius: 4,
                    rowGap: 10,
                    marginLeft: 40,
                  }}
                >
                  {item.parsed_seats.map((parsedSeat) => {
                    return (
                      <View
                        key={parsedSeat.id}
                        style={{
                          flexDirection: 'row',
                          columnGap: 8,
                          alignItems: 'center',
                        }}
                      >
                        <GourmetSiteProviderLabel provider={parsedSeat.site} />
                        <Text style={{ fontSize: 14, fontWeight: '300' }}>
                          {parsedSeat.name}
                        </Text>
                        {parsedSeat.sync_status === 'error' && (
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            size={16}
                            color={Colors.caution}
                          />
                        )}
                      </View>
                    )
                  })}
                </View>
              </View>
            ))}
          </CheckboxGroup>
          <Pagination {...paginationProps} />
        </View>
      </View>
    </View>
  )
}
