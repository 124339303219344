import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'

import RestaurantCourseQuestions from 'pages/Restaurants/RestaurantCourseQuestions'
import RestaurantCourseQuestionsForm from 'pages/Restaurants/RestaurantCourseQuestions/Form'

import React from 'react'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const routes: StackRoutes = {
  Index: {
    title: t('コース'),
    fullPath: `/restaurants/:id/course_questions`,
    relativePath: `/`,
    exact: true,
    element: <RestaurantCourseQuestions />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Show: {
    title: t('お客様への質問を作成'),
    fullPath: `/restaurants/:id/course_questions/new`,
    relativePath: `/new`,
    element: <RestaurantCourseQuestionsForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('お客様への質問を編集'),
    fullPath: `/restaurants/:id/course_questions/:restaurantCourseQuestionId`,
    relativePath: `/:restaurantCourseQuestionId`,
    element: <RestaurantCourseQuestionsForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function RestaurantCourseQuestionsStack() {
  return <Stack routes={routes} />
}
