import React from 'react'
import { ReservationsShow } from '@hello-ai/for_r_app/src/components/Reservation'
import { useParams } from 'react-router-dom'

function ReservationShowPage() {
  const { id: restaurantId, reservationId } = useParams<{
    id: string
    reservationId: string
  }>()
  return (
    <ReservationsShow
      restaurantId={parseInt(restaurantId!)}
      restaurantReservationId={reservationId!}
    />
  )
}

export default ReservationShowPage
