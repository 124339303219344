import React from 'react'
import { useParams } from 'react-router-dom'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useRestaurantTableMenus } from 'models/TableMenu'
import {
  createTableUnlimitedOrderPlan,
  updateTableUnlimitedOrderPlan,
  useTableUnlimitedOrderPlan,
} from 'models/TableUnlimitedOrderPlan'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import TableUnlimitedOrderPlanFormContent from 'components/Menus/TableUnlimitedOrderPlanFormContent'
import { history } from 'modules/history'

export default function TableUnlimitedOrderPlanForm() {
  const restaurantId = useRestaurantId()
  const { table_unlimited_order_plan_id: tableUnlimitedOrderPlanId } =
    useParams<{
      table_unlimited_order_plan_id?: string
    }>()

  const { tableUnlimitedOrderPlan, isLoading } = useTableUnlimitedOrderPlan({
    tableUnlimitedOrderPlanId,
  })

  const { tableMenus, isLoading: isLoadingTableMenus } =
    useRestaurantTableMenus({ restaurantId })

  if (isLoading) {
    return <Loading />
  }

  return (
    <TableUnlimitedOrderPlanFormContent
      tableUnlimitedOrderPlan={tableUnlimitedOrderPlan}
      tableMenus={tableMenus ?? []}
      isLoadingTableMenus={isLoadingTableMenus}
      createUnlimitedOrderPlan={async ({ token, params }) => {
        await createTableUnlimitedOrderPlan({
          restaurantId,
          token,
          params,
        })
      }}
      updateUnlimitedOrderPlan={async ({ token, params }) => {
        if (tableUnlimitedOrderPlanId === undefined) {
          return
        }
        await updateTableUnlimitedOrderPlan({
          tableUnlimitedOrderPlanId,
          token,
          params,
        })
      }}
      onPressProduct={(productId: string) => {
        history.push(`/restaurants/${restaurantId}/products/${productId}/edit`)
      }}
    />
  )
}
