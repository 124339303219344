import React from 'react'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { View } from 'react-native'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function Title({
  icon,
  children,
}: {
  icon: React.ReactNode
  children: string
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {icon}
      <Text
        style={{
          marginLeft: 8,
          fontWeight: '600',
        }}
      >
        {children}
      </Text>
    </View>
  )
}

export function DateQuestionTitle() {
  return (
    <Title
      icon={
        <FontAwesomeIcon
          icon={faCalendarAlt}
          size={16}
          color={Colors.black50}
        />
      }
    >
      {t('お日にちはいつですか？')}
    </Title>
  )
}

export function DateTimeQuestionTitle() {
  return (
    <Title
      icon={<FontAwesomeIcon icon={faClock} size={16} color={Colors.black50} />}
    >
      {t('お時間は何時からですか？')}
    </Title>
  )
}

export function PartySizeQuestionTitle() {
  return (
    <Title
      icon={
        <FontAwesomeIcon
          icon={faUserFriends}
          size={16}
          color={Colors.black50}
        />
      }
    >
      {t('人数は何名様ですか？')}
    </Title>
  )
}
