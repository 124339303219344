// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/restaurants/restaurant_course/restaurant_course_reservation_period_policy_resource.proto" (package "auto_reserve.restaurants.restaurant_course", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource
 */
export interface RestaurantCourseReservationPeriodPolicyResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: int64 restaurant_course_id = 2;
   */
  restaurantCourseId: number;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.SinceType since_type = 3;
   */
  sinceType: RestaurantCourseReservationPeriodPolicyResource_SinceType;
  /**
   * @generated from protobuf field: int32 since_day_before = 4;
   */
  sinceDayBefore: number;
  /**
   * @generated from protobuf field: int32 since_month_before = 5;
   */
  sinceMonthBefore: number;
  /**
   * @generated from protobuf field: int32 since_specific_day = 6;
   */
  sinceSpecificDay: number;
  /**
   * @generated from protobuf field: int32 since_specific_time = 7;
   */
  sinceSpecificTime: number;
  /**
   * @generated from protobuf field: auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.UntilType until_type = 8;
   */
  untilType: RestaurantCourseReservationPeriodPolicyResource_UntilType;
  /**
   * @generated from protobuf field: int32 until_day_before = 9;
   */
  untilDayBefore: number;
  /**
   * @generated from protobuf field: int32 until_time_before = 10;
   */
  untilTimeBefore: number;
  /**
   * @generated from protobuf field: int32 until_specific_time = 11;
   */
  untilSpecificTime: number;
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.SinceType
 */
export enum RestaurantCourseReservationPeriodPolicyResource_SinceType {
  /**
   * @generated from protobuf enum value: EACH_DAY = 0;
   */
  EACH_DAY = 0,
  /**
   * @generated from protobuf enum value: BULK = 1;
   */
  BULK = 1,
}
/**
 * @generated from protobuf enum auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.UntilType
 */
export enum RestaurantCourseReservationPeriodPolicyResource_UntilType {
  /**
   * @generated from protobuf enum value: OFFSET = 0;
   */
  OFFSET = 0,
  /**
   * @generated from protobuf enum value: SPECIFIC = 1;
   */
  SPECIFIC = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantCourseReservationPeriodPolicyResource$Type extends MessageType<RestaurantCourseReservationPeriodPolicyResource> {
  constructor() {
    super(
      "auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "restaurant_course_id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 3,
          name: "since_type",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.SinceType",
            RestaurantCourseReservationPeriodPolicyResource_SinceType,
          ],
        },
        {
          no: 4,
          name: "since_day_before",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 5,
          name: "since_month_before",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 6,
          name: "since_specific_day",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 7,
          name: "since_specific_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 8,
          name: "until_type",
          kind: "enum",
          T: () => [
            "auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.UntilType",
            RestaurantCourseReservationPeriodPolicyResource_UntilType,
          ],
        },
        {
          no: 9,
          name: "until_day_before",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 10,
          name: "until_time_before",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 11,
          name: "until_specific_time",
          kind: "scalar",
          T: 5 /*ScalarType.INT32*/,
        },
      ],
    );
  }
  create(
    value?: PartialMessage<RestaurantCourseReservationPeriodPolicyResource>,
  ): RestaurantCourseReservationPeriodPolicyResource {
    const message = {
      id: "",
      restaurantCourseId: 0,
      sinceType: 0,
      sinceDayBefore: 0,
      sinceMonthBefore: 0,
      sinceSpecificDay: 0,
      sinceSpecificTime: 0,
      untilType: 0,
      untilDayBefore: 0,
      untilTimeBefore: 0,
      untilSpecificTime: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantCourseReservationPeriodPolicyResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantCourseReservationPeriodPolicyResource,
  ): RestaurantCourseReservationPeriodPolicyResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* int64 restaurant_course_id */ 2:
          message.restaurantCourseId = reader.int64().toNumber();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.SinceType since_type */ 3:
          message.sinceType = reader.int32();
          break;
        case /* int32 since_day_before */ 4:
          message.sinceDayBefore = reader.int32();
          break;
        case /* int32 since_month_before */ 5:
          message.sinceMonthBefore = reader.int32();
          break;
        case /* int32 since_specific_day */ 6:
          message.sinceSpecificDay = reader.int32();
          break;
        case /* int32 since_specific_time */ 7:
          message.sinceSpecificTime = reader.int32();
          break;
        case /* auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.UntilType until_type */ 8:
          message.untilType = reader.int32();
          break;
        case /* int32 until_day_before */ 9:
          message.untilDayBefore = reader.int32();
          break;
        case /* int32 until_time_before */ 10:
          message.untilTimeBefore = reader.int32();
          break;
        case /* int32 until_specific_time */ 11:
          message.untilSpecificTime = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantCourseReservationPeriodPolicyResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* int64 restaurant_course_id = 2; */
    if (message.restaurantCourseId !== 0)
      writer.tag(2, WireType.Varint).int64(message.restaurantCourseId);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.SinceType since_type = 3; */
    if (message.sinceType !== 0)
      writer.tag(3, WireType.Varint).int32(message.sinceType);
    /* int32 since_day_before = 4; */
    if (message.sinceDayBefore !== 0)
      writer.tag(4, WireType.Varint).int32(message.sinceDayBefore);
    /* int32 since_month_before = 5; */
    if (message.sinceMonthBefore !== 0)
      writer.tag(5, WireType.Varint).int32(message.sinceMonthBefore);
    /* int32 since_specific_day = 6; */
    if (message.sinceSpecificDay !== 0)
      writer.tag(6, WireType.Varint).int32(message.sinceSpecificDay);
    /* int32 since_specific_time = 7; */
    if (message.sinceSpecificTime !== 0)
      writer.tag(7, WireType.Varint).int32(message.sinceSpecificTime);
    /* auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource.UntilType until_type = 8; */
    if (message.untilType !== 0)
      writer.tag(8, WireType.Varint).int32(message.untilType);
    /* int32 until_day_before = 9; */
    if (message.untilDayBefore !== 0)
      writer.tag(9, WireType.Varint).int32(message.untilDayBefore);
    /* int32 until_time_before = 10; */
    if (message.untilTimeBefore !== 0)
      writer.tag(10, WireType.Varint).int32(message.untilTimeBefore);
    /* int32 until_specific_time = 11; */
    if (message.untilSpecificTime !== 0)
      writer.tag(11, WireType.Varint).int32(message.untilSpecificTime);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.restaurants.restaurant_course.RestaurantCourseReservationPeriodPolicyResource
 */
export const RestaurantCourseReservationPeriodPolicyResource =
  new RestaurantCourseReservationPeriodPolicyResource$Type();
