import React, { useState } from 'react'
import { ViewProps } from 'react-native'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'
import { faDiagramCells } from '@fortawesome/pro-regular-svg-icons/faDiagramCells'
import Reanimated, {
  AnimateProps,
  FlipInEasyY,
  FlipOutEasyY,
} from 'react-native-reanimated'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { HeaderRightButton } from '../Shared/HeaderRightButton'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export type HeaderViewModeType = 'chart' | 'list'

export function HeaderViewModeButton({
  viewMode,
  onPress,
}: {
  viewMode: HeaderViewModeType
  onPress: () => void
}) {
  const [entering, setEntering] = useState<
    AnimateProps<ViewProps>['entering'] | undefined
  >(undefined)
  const { width, sm } = useResponsive()

  return (
    <HeaderRightButton
      style={{
        paddingRight: 0,
      }}
      icon={
        <Reanimated.View
          key={viewMode}
          entering={entering}
          exiting={FlipOutEasyY}
        >
          <FontAwesomeIcon
            icon={viewMode === 'list' ? faList : faDiagramCells}
            color={Colors.primary}
            size={width < sm ? 21 : 32}
          />
        </Reanimated.View>
      }
      onPress={() => {
        setEntering(() => FlipInEasyY)
        onPress()
      }}
    />
  )
}
