import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Colors } from '../../constants/Colors'

export type $RemoveChildren<T extends React.ComponentType<any>> = Omit<
  React.ComponentPropsWithoutRef<T>,
  'children'
>

export type Props = $RemoveChildren<typeof View> & {
  leftInset?: boolean
  horizontalInset?: boolean
  bold?: boolean
  style?: StyleProp<ViewStyle>
}

/* from https://github.com/callstack/react-native-paper/blob/main/src/components/Divider.tsx */

export function Divider({
  leftInset,
  horizontalInset = false,
  style,
  bold = false,
  ...rest
}: Props) {
  return (
    <View
      {...rest}
      style={[
        { height: 0.5, backgroundColor: Colors.border },
        leftInset && styles.v3LeftInset,
        horizontalInset && styles.horizontalInset,
        bold && styles.bold,
        style,
      ]}
    />
  )
}

const styles = StyleSheet.create({
  leftInset: {
    marginLeft: 72,
  },
  v3LeftInset: {
    marginLeft: 16,
  },
  horizontalInset: {
    marginLeft: 16,
    marginRight: 16,
  },
  bold: {
    height: 1,
  },
})
