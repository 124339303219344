import React, { ComponentProps, useState } from 'react'
import { Button } from './Button'

export default function AsyncButton({
  onPress,
  ...rest
}: Omit<ComponentProps<typeof Button>, 'loading'>) {
  const [loading, setLoading] = useState(false)
  return (
    <Button
      {...rest}
      disabled={rest.disabled || loading}
      loading={loading}
      onPress={async (event) => {
        try {
          if (loading || onPress == null) return
          setLoading(true)
          await onPress(event)
        } finally {
          setLoading(false)
        }
      }}
    />
  )
}
