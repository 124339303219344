import React from 'react'
import { Picker } from '@react-native-picker/picker'
import { TextStyle } from 'react-native'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'

import { FontAwesomeIcon } from '../FontAwesomeIcon'

import { Colors } from '../../constants/Colors'
import { SelectInputProps } from './types'
import { SelectInputContent } from './SelectInputContent'

export * from './types'

const defaultIndicatorIcon = () => {
  return (
    <>
      <FontAwesomeIcon icon={faCaretUp} size={10} color={Colors.black} />
      <FontAwesomeIcon icon={faCaretDown} size={10} color={Colors.black} />
    </>
  )
}

export function SelectInput<T>({
  disabled = false,
  style,
  containerStyle,
  labelStyle,
  selectedValue,
  setValue,
  items,
  indicatorIcon = defaultIndicatorIcon(),
  error = false,
  errorText,
}: SelectInputProps<T>) {
  const itemIndex = items.findIndex((item) => item.value === selectedValue)

  return (
    <SelectInputContent
      disabled={disabled}
      containerStyle={[
        {
          position: 'relative',
        },
        containerStyle,
      ]}
      style={style}
      labelStyle={labelStyle}
      indicatorIcon={indicatorIcon}
      error={error}
      errorText={errorText}
      label={itemIndex >= 0 ? items[itemIndex].label : ''}
    >
      <Picker
        enabled={!disabled}
        style={[
          {
            position: 'absolute',
            flex: 1,
            height: 48,
            width: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
          },
          style as TextStyle,
        ]}
        selectedValue={itemIndex}
        onValueChange={(_, index: number) => setValue(items[index].value)}
      >
        {items.map((item, index) => {
          return <Picker.Item key={index} label={item.label} value={index} />
        })}
      </Picker>
    </SelectInputContent>
  )
}
